import { Bounty } from "../../../types/Bounty";

const showWhenWhitelistIsInUse = (bounty: Bounty, walletAccountId: string) => {
  return ["ApprovalByWhitelist", "WhitelistWithApprovals"].includes(bounty.claimant_approval)
    ? !!bounty.claimants_whitelist?.includes(walletAccountId)
    : true;
};

const calcTotalAmount = (amount: string, fee: string) => {
  return (parseInt(amount) + parseInt(fee)).toString();
};

export { showWhenWhitelistIsInUse, calcTotalAmount };
