import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { AdditionalInfoFormData } from "../../../AdditionalInfo";
import { useStoreActions, useStoreState } from "../../../../../../../hooks";
import { AllowedPeriod, ContestOrHackathon } from "../../../../../../../types/BountyCreate";
import { MomentDurationConstructor, formatting } from "../../../../../../../common/formatting";
import { FormControl, FormRow } from "../../../../../../general/Form/FormElements";
import { ValidationLayout } from "../../../../../../Layout";
import { NewSelect } from "../../../../../../general/Select";
import { defTimeCommitments } from "../../../../../../../constants";
import { Input } from "../../../../../../general/Input";

type FormData = {
  timeCommitment: string;
} & AdditionalInfoFormData;

const AllowedCreateClaimToPeriod = () => {
  const methods = useFormContext<FormData>();
  const { register, control, formState, setValue, getValues } = methods;
  const { errors, isSubmitted } = formState;

  const allowed_create_claim_to = useStoreState(
    (state) =>
      (state.newBounty.multitasking as ContestOrHackathon)?.ContestOrHackathon
        .allowed_create_claim_to
  );
  const contestOrHackathonTimeCommitment = useStoreState(
    (state) => state.newBountyForm.contestOrHackathonTimeCommitment
  );

  const setAllowedCreateClaimToPeriod = useStoreActions(
    (actions) => actions.newBounty.setAllowedCreateClaimToPeriod
  );

  const setContestOrHackathonTimeCommitment = useStoreActions(
    (actions) => actions.newBountyForm.setContestOrHackathonTimeCommitment
  );

  const periodDefValue = (allowed_create_claim_to as AllowedPeriod)?.Period?.period
    ? formatting.formatDurationToHuman(
        (allowed_create_claim_to as AllowedPeriod).Period.period,
        contestOrHackathonTimeCommitment ? contestOrHackathonTimeCommitment.toLowerCase() : ""
      )
    : "";

  const isPeriodDisabled = useMemo(() => {
    return !Boolean(contestOrHackathonTimeCommitment);
  }, [contestOrHackathonTimeCommitment]);

  const handleTimeCommitmentChange = (selected: { name: string; value: string }) => {
    if (getValues("multitasking.ContestOrHackathon.allowed_create_claim_to.Period.period")) {
      const unit = selected.value.toLowerCase() as MomentDurationConstructor;
      const duration = formatting.formatDurationToNanoSec(
        getValues("multitasking.ContestOrHackathon.allowed_create_claim_to.Period.period"),
        unit
      );
      setAllowedCreateClaimToPeriod({
        Period: { period: duration.toString() }
      });
    }
    setContestOrHackathonTimeCommitment(selected.value);
  };

  const handlePeriodChange = (value: string) => {
    if (contestOrHackathonTimeCommitment) {
      const unit = contestOrHackathonTimeCommitment.toLowerCase() as MomentDurationConstructor;
      const duration = formatting.formatDurationToNanoSec(value, unit);
      setAllowedCreateClaimToPeriod({
        Period: { period: duration.toString() }
      });
    }
  };

  return (
    <FormRow>
      <div className="flex gap-4 items-end">
        <input
          type="hidden"
          defaultValue={contestOrHackathonTimeCommitment}
          {...register("timeCommitment", {
            required: {
              message: "Required field",
              value: !contestOrHackathonTimeCommitment
            }
          })}
        />
        <ValidationLayout
          {...{
            validate: () => {
              return {
                valid: !errors.timeCommitment,
                started: isSubmitted,
                message: errors.timeCommitment?.message
              };
            }
          }}>
          <NewSelect
            {...{
              customStyles: {
                container: "px-[20px] py-0",
                optionsList: { container: "text-sm" }
              },
              placeholder: "Time commitment",
              selected: {
                name: contestOrHackathonTimeCommitment,
                value: contestOrHackathonTimeCommitment
              },
              defaultOptionsList: defTimeCommitments,
              defaultOption: {
                name: "",
                value: ""
              },
              onSelect: (selected: { name: string; value: string }) => {
                setValue("timeCommitment", selected.value);
                handleTimeCommitmentChange(selected);
              }
            }}
          />
        </ValidationLayout>
        <FormControl
          name="multitasking.ContestOrHackathon.allowed_create_claim_to.Period.period"
          control={control}
          defaultValue={periodDefValue}
          rules={{
            required: {
              message: "Required field",
              value: !periodDefValue
            }
          }}
          render={({ field: { name, value, onChange } }) => {
            return (
              <Input
                {...{
                  customStyles: {
                    input: "px-[20px] py-[15px]"
                  },
                  placeholder: "Duration",
                  value: value,
                  name: name,
                  validation: {
                    valid: !(errors?.multitasking as any)?.ContestOrHackathon
                      ?.allowed_create_claim_to?.Period.period,
                    message: (errors?.multitasking as any)?.ContestOrHackathon
                      ?.allowed_create_claim_to?.Period.period?.message,
                    started: isSubmitted
                  },
                  onChange: (value: string) => {
                    onChange(value);
                    setValue(
                      "multitasking.ContestOrHackathon.allowed_create_claim_to.Period.period",
                      value,
                      {
                        shouldValidate: true
                      }
                    );
                    handlePeriodChange(value);
                  },
                  disabled: isPeriodDisabled
                }}
                {...{ name, value }}
              />
            );
          }}
        />
      </div>
    </FormRow>
  );
};

export default AllowedCreateClaimToPeriod;
