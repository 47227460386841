import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Layout, Section } from "../../components/Layout";
import { SignUpForm } from "../../components/Form";

import { useStoreActions, useStoreState } from "../../hooks";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

export type SignUpFormData = {
  name: string;
  email: string;
  password: string;
};

function SignUp() {
  const navigate = useNavigate();
  const methods = useForm<SignUpFormData>({ mode: "onSubmit" });

  const { clearUserSignUpError, userSignUpAsync } = useStoreActions(
    (actions) => actions.auth
  );
  const { isAuthenticated, userSignUpLoading, signUpError, refferalLinkCode } =
    useStoreState((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      return navigate("/verify-code");
    }

    const timer = setTimeout(() => {
      clearUserSignUpError();
    }, 10000);

    return () => clearTimeout(timer);
  });

  const onSubmit: SubmitHandler<SignUpFormData> = async (form) => {
    await userSignUpAsync({ ...form, refferalLinkCode });
  };

  return (
    <Layout>
      <div className="p-3" />
      <Section
        {...{
          id: "sign-up_section",
        }}
      >
        <FormProvider {...methods}>
          <SignUpForm
            {...{
              onSubmit: onSubmit,
              submitDisabled: userSignUpLoading,
              errorMessage: signUpError,
            }}
          />
        </FormProvider>
      </Section>
    </Layout>
  );
}

export default SignUp;
