import React from "react";
import ReactDOM from "react-dom/client";
import { StoreProvider } from "easy-peasy";
import App from "./containers/App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import InitializeProvider from "./providers/InitializeProvider";
import store from "./store/store";
import history from "history/browser";
import { router } from "./containers/App/router";

export type BrowserRouter = typeof router;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <InitializeProvider history={history} router={router}>
        <App />
      </InitializeProvider>
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
