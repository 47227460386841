import Api from "../services/Api";
import { useQuery } from "@tanstack/react-query";
import { Bounty } from "../types/Bounty";

const useBountyDetails = (props: { bountyId: string | undefined; accountId?: string }) => {
  const { bountyId, accountId } = props;

  const fetchData = Api.getBounty;
  const { data, error, isFetching, isInitialLoading, isLoading, status } = useQuery(
    ["bounty"],
    () => {
      return fetchData({ id: bountyId ?? "", accountId }).then((res: Bounty) => {
        return res;
      });
    },
    {}
  );

  return {
    data,
    error,
    status,
    isLoading,
    isInitialLoading,
    isFetching
  };
};

export default useBountyDetails;
