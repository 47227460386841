import classNames from "classnames";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

const TableHeaderCell = (props: { className?: string; children: ReactNode }) => {
  const { className, children } = props;
  return (
    <th
      className={twMerge(
        classNames(
          "font-poppins font-normal uppercase p-4 pl-8 pt-0 pb-3 text-neutral-500 text-[10px] text-left",
          className
        )
      )}>
      {children}
    </th>
  );
};

export default TableHeaderCell;
