import { useFormContext } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { Input } from "../../../general/Input";
import { Area, ValidationLayout } from "../../../Layout";
import { FormRow } from "../../../general/Form/FormElements";
import FormControl from "../../../general/Form/FormElements/FormControl";
import UploadAttachments from "./components/UploadAttachments";
import { DetailsFormData } from "./Details";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { useEffect, useState } from "react";
import classNames from "classnames";
import ImportBounty from "./components/ImportBounty";
import { SelectArrow } from "../../../general/SVGIcon";

// Register plugins if required
// MdEditor.use(YOUR_PLUGINS_HERE);

// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);

function CustomDetails() {
  const methods = useFormContext<DetailsFormData>();
  const { control, register, formState, setValue } = methods;
  const { errors, isSubmitted } = formState;
  const enabledBountyImport = useStoreState((state) => state.app.config?.enabled_bounty_import);
  const actions = useStoreActions((actions) => actions);
  const {
    newBounty: {
      metadata: { setTitle, setDescription, setAcceptanceCriteria, setContactDetails }
    }
  } = actions;

  const [showImport, setShowImport] = useState(false);
  const { title, description, acceptance_criteria, contact_details } = useStoreState(
    (state) => state.newBounty.metadata
  );

  const handleTitleChange = (value: string) => {
    setTitle(value);
  };

  const handleDescriptionChange = (text: string) => {
    setDescription(text);
  };

  const handleShowImport = () => {
    setShowImport(!showImport);
  };

  useEffect(() => {
    if (contact_details?.contact === "" || contact_details?.contact_type === "") {
      setContactDetails(undefined);
    }
  }, [contact_details]);

  useEffect(() => {
    if (acceptance_criteria === "") {
      setAcceptanceCriteria(undefined);
    }
  }, [acceptance_criteria]);

  return (
    <div className="mt-3.5">
      <Area {...{ customStyles: { borderContainer: "border border-neutral-700" } }}>
        <div className="p-6">
          {enabledBountyImport && (
            <FormRow>
              <div className="flex justify-end">
                <button
                  className="flex items-center gap-1 text-[12px] text-primary-900 outline-none"
                  onClick={handleShowImport}>
                  Import from Github
                  <SelectArrow
                    className={classNames("w-3 h-3", {
                      "rotate-180": showImport
                    })}
                    {...{ color: "hsl(var(--color-primary-900))" }}
                  />
                </button>
              </div>
            </FormRow>
          )}
          {showImport && <ImportBounty />}
          <FormRow>
            <FormControl
              name="title"
              control={control}
              rules={{ required: "Required" }}
              defaultValue={title}
              render={({ field: { name, value, onChange } }) => {
                return (
                  <Input
                    {...{
                      customStyles: {
                        input: "px-[20px] py-[15px]"
                      },
                      placeholder: "Name of your bounty",
                      label: { text: "Title" },
                      value: value,
                      name: name,
                      validation: {
                        valid: !errors.title,
                        message: errors.title?.message,
                        started: isSubmitted
                      },
                      onChange: (value: string) => {
                        onChange(value);
                        handleTitleChange(value);
                      }
                    }}
                    {...{ name, value }}
                  />
                );
              }}
            />
          </FormRow>
          <FormRow>
            <ValidationLayout
              {...{
                validate: () => {
                  return {
                    valid: !errors.description,
                    started: isSubmitted,
                    message: errors.description?.message
                  };
                }
              }}>
              <div data-color-mode="dark">
                <FormControl
                  name="description"
                  control={control}
                  defaultValue={description}
                  rules={{ required: "Required" }}
                  render={({ field: { name, value, onChange } }) => {
                    return (
                      <MdEditor
                        className="resize-y overflow-auto"
                        markdownClass="scrollbar"
                        placeholder="Description"
                        style={{ height: "264px" }}
                        defaultValue={description}
                        view={{ menu: true, md: true, html: false }}
                        canView={{
                          menu: true,
                          md: true,
                          html: false,
                          fullScreen: false,
                          hideMenu: true,
                          both: false
                        }}
                        renderHTML={(text) => mdParser.render(text)}
                        onChange={({ text }) => {
                          onChange(text);
                          handleDescriptionChange(text);
                          setValue("description", text, {
                            shouldValidate: true
                          });
                        }}
                        {...{ name, value }}
                      />
                    );
                  }}></FormControl>
              </div>
            </ValidationLayout>
          </FormRow>
          <FormRow>
            <UploadAttachments />
          </FormRow>
        </div>
      </Area>
    </div>
  );
}

export default CustomDetails;
