import { ReactNode } from "react";

const NumberedListItem = (props: {
  number: string;
  children: ReactNode | ReactNode[];
}) => {
  const { children, number } = props;
  return (
    <li className="ml-8 mb-2">
      <span className="pr-4">{number}.</span> {children}
    </li>
  );
};

export default NumberedListItem;
