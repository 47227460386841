import { useContext, useEffect } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import { CompanyMetadata, IndividualMetadata } from "../../../../../../types/Auth";
import CheckBox from "../../../../../general/CheckBox";
import { Area } from "../../../../../Layout";
import InvoiceFormData from "../../../../InvoiceFormData";
import PostpaidSubscriberWhitelistedContext from "../../../general/context/PostpaidSubscriberWhitelistedContext";

type InvoiceForm = { metadata: CompanyMetadata | IndividualMetadata };

const Invoice = () => {
  const methods = useFormContext<InvoiceForm>();
  const generateInvoice = useStoreState((state) => state.newBountyForm.generateInvoice);
  const user = useStoreState((state) => state.auth.user);
  const setGenerateInvoice = useStoreActions((actions) => actions.newBountyForm.setGenerateInvoice);
  const { isPostpaidSubscriberWhitelisted } = useContext(PostpaidSubscriberWhitelistedContext);

  useEffect(() => {
    if (isPostpaidSubscriberWhitelisted && !generateInvoice) {
      setGenerateInvoice(true);
    }
  }, [isPostpaidSubscriberWhitelisted, generateInvoice]);

  useEffect(() => {
    if (generateInvoice && !user?.metadata?.profileType) {
      methods.setError("metadata", { message: "Required" });
    }
    return () => methods.clearErrors("metadata");
  }, [user, generateInvoice]);

  return (
    <div className="mt-3.5">
      <Area {...{ customStyles: { borderContainer: "border border-neutral-700" } }}>
        <div className="px-6 mt-6 text-neutral-400">
          <CheckBox
            {...{
              active: generateInvoice,
              onClick: () => setGenerateInvoice(!generateInvoice),
              text: `Generate invoice on successful claim`
            }}
          />
        </div>
        {generateInvoice && (
          <div className="p-4">
            <Area>
              <div className="p-4">
                <>
                  <FormProvider {...methods}>
                    <InvoiceFormData metadata={user?.metadata} useVat={true} />
                  </FormProvider>
                </>
              </div>
            </Area>
          </div>
        )}
        <div className="pb-3.5 px-6">
          <p className="text-sm text-neutral-500">
            Require the bounty recipient to automatically generate an invoice upon successful
            payout.
          </p>
        </div>
      </Area>
    </div>
  );
};

export default Invoice;
