import { NavLink } from "react-router-dom";

interface LinkProps {
  link?: string;
  imageSrc?: string;
  target?: string;
  rel?: string;
  style?: string;
  disabled?: boolean;
}

const defaultProps = {
  link: "",
  imageSrc: "",
  target: "_blank",
  rel: "noreferrer",
  style: "",
  disabled: false
};

export default function LogoLink(props: LinkProps) {
  const { link, imageSrc, target, rel, style, disabled } = {
    ...defaultProps,
    ...props
  };

  if (!disabled) {
    return (
      <NavLink to={link} target={target} rel={rel} className="w-fit">
        <img className={style} src={imageSrc} alt="" />
      </NavLink>
    );
  } else {
    return <img className={style} src={imageSrc} alt="" />;
  }
}
