import { Bounty } from "../../../../types/Bounty";
import { DarkButton } from "../../../general/Button";
import Modal from "../../../general/Modal";
import Amount from "./Amount";

export default function ApproveBountyForm(props: {
  show: boolean;
  bounty: Bounty;
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  const { show, bounty, handleClose, handleSubmit } = props;

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit bg-[#2E2E2E]" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                onClick: () => handleSubmit(),
                text: "Approve"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins py-2.5">
        <div className="py-2.5 text-neutral-200">
          You are about to approve this Hero`s work for your bounty and agree to release the reward!
        </div>
        <div className="bg-neutral-700 mx-[-1.25em] my-4">
          <div className="p-[1.25em]">
            <Amount bounty={bounty} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
