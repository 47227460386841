import { ReactNode } from "react";
import ErrorMessage from "../Bounty/general/ErrorMessage";
import ErrorVerifyTokenMessage from "../Bounty/general/ErrorMessage/ErrorVerifyTokenMessage";
import Disclaimer from "../general/Disclaimer";

import { Header, Footer } from "./index";
import { ScrollRestoration } from "react-router-dom";

interface LayoutProps {
  children?: ReactNode | ReactNode[];
}

export default function Layout(props: LayoutProps) {
  const { children } = props;
  return (
    <div className="relative min-h-[100vh] pt-16 w-full flex flex-col justify-between">
      <Disclaimer />
      <div>
        <Header />
        {children ? <main className="my-4 grid">{children}</main> : null}
      </div>
      <Footer />
      <ErrorMessage />
      <ErrorVerifyTokenMessage />
      <ScrollRestoration />
    </div>
  );
}
