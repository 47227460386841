import classNames from "classnames";
import { useContext, useState } from "react";
import { DEV_MODE } from "../../../constants";
import { useStoreActions } from "../../../hooks";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import utils from "../../../services/utils";
import { Bounty, NextActionModel } from "../../../types/Bounty";
import IconButton from "../../general/Button/IconButton";
import { TrashIcon } from "../../general/SVGIcon";
import CancelBountyForm from "../Dialogues/CancelBountyForm";
import NextActionsContext from "../general/context/NextActionsContext";
import mixpanel from "mixpanel-browser";
import { FinalExecutionOutcomeForm } from "../Dialogues";
import { providers } from "near-api-js";

const CancelBountyAction = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  const [showForm, setShowForm] = useState(false);
  const { walletAccountId } = useWalletAccountId();
  const context = useContext(NextActionsContext);
  const nextActions = context as Partial<NextActionModel>[] | null;
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<providers.FinalExecutionOutcome | null>(
    null
  );
  const cancelBountyV2 = useStoreActions((actions) => actions.nearApi.cancelBountyV2);
  const [loading, setLoading] = useState(false);

  const isHidden = () => {
    /** Conditions */
    const action = nextActions?.find((item) => item.accountId === walletAccountId);
    return !action?.nextActions?.bountyCancel;
  };

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, null, walletAccountId);
    return a.checkAccess([!a.isBountyOwner()]);
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    if (!DEV_MODE) {
      mixpanel.track("Cancel Bounty", {
        source: window.location.hostname
      });
    }
    setLoading(true);
    const result = await cancelBountyV2({
      id: bounty.id
    });
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
    }
  };

  return !isHidden() ? (
    <>
      <IconButton
        {...{
          disabled: isDisabled(),
          icon: (
            <TrashIcon
              className={classNames({
                "hover:stroke-neutral-400": isDisabled(),
                "hover:stroke-primary-900": !isDisabled()
              })}
            />
          ),
          onClick: () => handleButtonClick()
        }}
      />
      <CancelBountyForm
        show={showForm}
        loading={loading}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  ) : null;
};

export default CancelBountyAction;
