import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Layout, Section } from "../../components/Layout";
import { SignInForm } from "../../components/Form";

import { useStoreActions, useStoreState } from "../../hooks";
import { urlDefSearchParams } from "../../store/models/BountyListModel";

function SignIn() {
  const navigate = useNavigate();
  const { userSignInAsync, clearUserSignInError } = useStoreActions((actions) => actions.auth);
  const setCallbackUrl = useStoreActions((actions) => actions.auth.setCallbackUrl);
  const { isAuthenticated, isVerified, signInError } = useStoreState((state) => state.auth);
  const callbackUrl = useStoreState((state) => state.auth.callbackUrl);
  const onSubmit = async (form: { email: string; password: string }) => {
    await userSignInAsync(form);
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (isVerified) {
        const navigateTo = callbackUrl ? callbackUrl : `/bounties?${urlDefSearchParams()}`;
        setCallbackUrl(null);
        return navigate(navigateTo, { replace: true });
      } else {
        return navigate("/verify-code", { replace: true });
      }
    }

    const timer = setTimeout(() => {
      clearUserSignInError();
    }, 10000);

    return () => clearTimeout(timer);
  });

  return (
    <Layout>
      <div className="p-3" />
      <Section
        {...{
          id: "sign-in_section"
        }}>
        <SignInForm
          {...{
            onSubmit,
            submitDisabled: false,
            errorMessage: signInError
          }}
        />
      </Section>
    </Layout>
  );
}

export default SignIn;
