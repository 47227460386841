import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import defaultUserAvatarImage from "../../../assets/images/default_avatar.jpg";
import { base64ImageUrl } from "../../../utils";

const HunterAvatar = (props: {
  picture?: any;
  className?: string;
  original?: boolean;
  publicProfile?: boolean;
}) => {
  const { className } = props;

  return (
    <div className="w-fit">
      <img
        {...{
          src: props.picture ? base64ImageUrl(props.picture.data, props.picture.contentType) : defaultUserAvatarImage,
          className: twMerge(
            classNames("w-[100px] aspect-square rounded-full object-cover mt-[-50%]", className)
          )
        }}
        alt=""
      />
    </div>
  );
};

export default HunterAvatar;
