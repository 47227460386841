import classNames from "classnames";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type AreaStyles = {
  borderContainer?: string;
  contentContainer?: string;
};

interface AreaProps {
  children: ReactNode[] | ReactNode;
  customStyles?: AreaStyles;
}

const buildStyles = (customStyles: AreaStyles | undefined) => ({
  borderContainer: twMerge(
    classNames(
      "w-full h-full border-sm border-neutral-700 rounded-[1rem]",
      customStyles?.borderContainer
    )
  ),
  contentContainer: twMerge(
    classNames("w-full h-full bg-neutral-830 rounded-[1rem]"),
    customStyles?.contentContainer
  )
});

const Area = function (props: AreaProps) {
  const { children, customStyles } = props;
  const styles = buildStyles(customStyles);

  return (
    <div className={styles.borderContainer}>
      <div className={styles.contentContainer}>{children ? children : null}</div>
    </div>
  );
};

export default Area;
