import { useState, useContext } from "react";
import { Bounty, NextActionModel } from "../../../../types/Bounty";
import { Claim } from "../../../../types/Claim";
import ExtendDeadlineForm from "../Dialogues/ExtendDeadlineForm";
import { useStoreActions } from "../../../../hooks";
import EditIconButton from "./general/EditIconButton";
import NextActionsContext from "../../../Bounty/general/context/NextActionsContext";
import useWalletAccountId from "../../../../hooks/useWalletAccountId";
import utils from "../../../../services/utils";
import { FinalExecutionOutcomeForm } from "../../../Bounty/Dialogues";
import { providers } from "near-api-js";

const ExtendDeadline = (props: { bounty: Bounty; claim: Claim; refetchActions: () => void }) => {
  const { bounty, claim, refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<providers.FinalExecutionOutcome | null>(
    null
  );
  const extendClaimDeadlineV2 = useStoreActions((actions) => actions.nearApi.extendClaimDeadlineV2);
  const context = useContext(NextActionsContext);
  const nextActions = context as Partial<NextActionModel>[] | null;
  const { walletAccountId } = useWalletAccountId();
  const [loading, setLoading] = useState(false);

  const isHidden = () => {
    const actions = nextActions?.find((item) => item.accountId === walletAccountId)?.forClaims;
    return !actions?.find((action) => action.nextActions?.extendClaimDeadline);
  };

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, null, walletAccountId);
    return a.checkAccess([!a.isBountyOwner(), !a.isReviewer()]);
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async (deadline: string) => {
    setLoading(true);
    const newDeadline = (parseInt(claim.deadline ?? "0") + parseInt(deadline)).toString();
    const result = await extendClaimDeadlineV2({
      id: bounty.id,
      receiver_id: claim.owner,
      deadline: newDeadline,
      claim_number: claim.claim_number
    });
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
    }
  };

  return !isHidden() ? (
    <>
      <EditIconButton
        {...{
          customStyles: {
            button: "rounded-full p-1 bg-neutral-100",
            icon: "w-[14px] h-[14px] stroke-neutral-700 hover:stroke-neutral-700"
          }
        }}
        handleSubmit={() => handleButtonClick()}
        isDisabled={isDisabled()}
      />
      <ExtendDeadlineForm
        show={showForm}
        loading={loading}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
        bounty={bounty}
        claim={claim}
      />
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  ) : null;
};

export default ExtendDeadline;
