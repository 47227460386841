import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchIcon } from "../../general/SVGIcon";
import { urlDefSearchParams } from "../../../store/models/BountyListModel";
import useBountiesSearchParams from "../../../hooks/useBountiesSearchParams";

type SearchFormData = {
  searchText: string;
};

const SearchForm = () => {
  const { searchParams, setQuery } = useBountiesSearchParams({});
  const form = useForm<SearchFormData>();
  const { register, handleSubmit, reset } = form;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (typeof searchParams.text === "undefined") {
      reset();
    }
  }, [searchParams.text, reset]);

  const onSubmit = (data: { searchText: string }) => {
    setQuery({ text: data.searchText });
    if (!location?.pathname?.includes("/bounties")) {
      navigate(`/bounties?${urlDefSearchParams()}`);
    }
  };
  const handleInputBlur = (e: any) => {
    if (e.target.value && e.target.value !== searchParams.text) {
      setQuery({ text: e.target.value });
    } else if (!e.target.value && searchParams.text) {
      setQuery({ text: undefined });
    }
  };

  return (
    <div className="font-poppins flex items-center border rounded-3xl border-neutral-700 text-sm text-neutral-300 min-w-fit">
      <form className="flex items-center mx-2.5" onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("searchText")}
          defaultValue={searchParams.text ?? ""}
          className="px-2 py-2 bg-transparent border-0 outline-none placeholder-neutral-300"
          placeholder="Search bounties"
          onBlur={(e) => handleInputBlur(e)}
        />
        <button className="outline-none border-0 mx-2" type="submit">
          <SearchIcon className="stroke-neutral-300" />
        </button>
      </form>
    </div>
  );
};

export default SearchForm;
