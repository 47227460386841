import { useQuery } from "@tanstack/react-query";
import { BotApi } from "../services/BotApi";
import { AxiosError } from "axios";
import { ServerError } from "../services/Api";

const useBountyFromGithub = (props: { id?: string }) => {
  const { id } = props;

  const fetchData = BotApi.getBountyFromGithub;
  const { data, error, isError, isFetching, status } = useQuery(
    ["items", id],
    () => {
      return fetchData({
        id
      }).then((res) => {
        return res.data as {
          tokenId: string;
          amount: string;
          msg: string;
        };
      });
    },
    {
      retry: false,
      onError: (error: AxiosError) => {
        console.log("error", error?.response?.data);
        return (error?.response?.data as ServerError).message;
      }
    }
  );

  return {
    data,
    error,
    status,
    isError,
    isFetching
  };
};

export default useBountyFromGithub;
