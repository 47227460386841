import classNames from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

type AreaStyles = {
  wrapper?: string;
  text?: string;
};

const SubtaskItem = (props: {
  description: string;
  percents: string;
  children?: React.ReactNode | React.ReactNode[];
  customStyles?: AreaStyles;
}) => {
  const { description, percents, children, customStyles } = props;

  const buildStyles = (customStyles: AreaStyles | undefined) => ({
    wrapper: twMerge(
      classNames(
        "mb-2.5 flex p-3.5 items-center justify-between rounded-[10px] bg-neutral-800",
        customStyles?.wrapper
      )
    ),
    text: twMerge(classNames("px-2 text-sm", customStyles?.text)),
    percents: twMerge(classNames("text-primary-900", customStyles?.text))
  });

  const styles = buildStyles(customStyles);

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>{description}</p>
      <div className="flex items-center gap-4">
        <span className={styles.percents}>{percents}%</span>
        {children}
      </div>
    </div>
  );
};

export default SubtaskItem;
