import { Dispatch, SetStateAction, useState } from "react";
import Avatar from "../../general/Avatar";
import ChooseFileButton from "../../general/Button/ChooseFileButton";
import CoverImage from "../../general/CoverImage";
import { WarningIcon } from "../../general/SVGIcon";

type CommonFormProps = {
  avatarImage?: File;
  coverImage?: File;
  onSetAvatarImage: Dispatch<SetStateAction<File | undefined>>;
  onSetCoverImage: Dispatch<SetStateAction<File | undefined>>;
};

const CommonForm = (props: CommonFormProps) => {
  const { avatarImage, coverImage, onSetAvatarImage, onSetCoverImage } = props;
  const [fileError, setFileError] = useState({ profileImage: false, coverImage: false });
  const previewLink = (file: File) => {
    return URL.createObjectURL(file);
  };

  const onAvatarImageChange = async (file: File) => {
    if (file?.size > 2_000_000) {
      // 2MB
      console.log("Error: file is too big");
      return setFileError({...fileError, profileImage: true});
    }
    setFileError({...fileError, profileImage: false});
    onSetAvatarImage(file);
  };

  const onCoverImageChange = async (file: File) => {
    if (file?.size > 2_000_000) {
      // 2MB
      console.log("Error: file is too big");
      return setFileError({...fileError, coverImage: true});
    }
    setFileError({...fileError, coverImage: false});
    onSetCoverImage(file);
  };

  return (
    <div className="flex flex-col gap-4 my-4">
      <ChooseFileButton onClick={onAvatarImageChange} accept="image/*">
        <div className="flex flex-row items-center justify-start gap-4">
          <Avatar
            {...{
              picture: avatarImage && previewLink(avatarImage),
              className: "w-[52px] aspect-square rounded-full mt-0"
            }}
          />
          <div>
            <p className="text-neutral-300 text-sm">{"Add profile picture"}</p>
            <p className={`${fileError.profileImage ? "text-secondary-900 flex items-center gap-1" : "block text-neutral-300"} text-xs`}>{fileError.profileImage && <WarningIcon className='stroke-secondary-900' />} File size max 2MB</p>
          </div>
        </div>
      </ChooseFileButton>
      <ChooseFileButton onClick={onCoverImageChange} accept="image/*">
        <div className="relative rounded-[1.25rem] overflow-hidden">
          <CoverImage {...{ picture: coverImage && previewLink(coverImage) }} />
          <div className="absolute top-0 w-full h-full">
            <div className="h-full flex items-center justify-center">
              <p className="text-neutral-50 text-sm rounded-full bg-neutral-800/50 px-[1em] py-[0.5em]">
                {"Add cover image"} <br />
                <span className={`${fileError.coverImage ? "flex items-center gap-1 text-secondary-900" : "block"} text-[10px]`}>{fileError.coverImage && <WarningIcon className='stroke-secondary-900' />} {"File size max 2MB"}</span>
              </p>
            </div>
          </div>
        </div>
      </ChooseFileButton>
    </div>
  );
};

export default CommonForm;
