import { setupModal } from "@near-wallet-selector/modal-ui";
import "@near-wallet-selector/modal-ui/styles.css";
import { useStoreState } from "../../hooks";
import { LightButton } from "../general/Button";
import { WalletIcon2 } from "../general/SVGIcon";

const WalletSelector = () => {
  const walletSelector = useStoreState((state) => state.nearApi.walletSelector);
  const contractId = useStoreState((state) => state.app.config?.bounty_contract_id);

  const handleShowWalletSelector = () => {
    if (walletSelector && contractId) {
      const modal = setupModal(walletSelector, {
        contractId
      });
      modal.show();
    }
  };

  return (
    <LightButton
      {...{
        customStyles: {
          button: "border-primary-900 rounded-full bg-transparent active:bg-transparent"
        },
        onClick: () => handleShowWalletSelector()
      }}>
      <div className="flex gap-4">
        <WalletIcon2 className="stroke-primary-900" />
        <span className="text-primary-900">Connect wallet</span>
      </div>
    </LightButton>
  );
};

export default WalletSelector;
