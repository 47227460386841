import { useEffect } from "react";
import { idOS } from "@idos-network/idos-sdk";
import { useStoreState } from "../../hooks";
import useWalletAccountId from "../../hooks/useWalletAccountId";

const KYCVerificationIdOS = () => {
  const { walletAccountId } = useWalletAccountId();
  const walletSelector = useStoreState((state) => state.nearApi.walletSelector);

  console.log(walletAccountId, walletSelector);

  useEffect(() => {
    (async () => {
      if (walletAccountId && walletSelector) {
        localStorage.setItem("idos-human-id", walletAccountId);
        const idos = await idOS.init({
          nodeUrl: "https://nodes.staging.idos.network",
          container: "#idos_container"
        });
        const wallet = await walletSelector.wallet();
        await idos.auth.setNearSigner(wallet, walletAccountId);
        await idos.crypto.init();
        await idos.grants.init({ type: "near", walletAccountId, wallet });
        const currentUser = await idos.auth.currentUser();
        const idosWallets = await idos.data.list("wallets");
        console.log(currentUser);
        console.log(idosWallets);
        // const attribute = await idos.data.create("attributes", {
        //   attribute_key: "foo",
        //   value: "bar"
        // });
        // console.log(attribute);
      }
    })();
  }, [walletAccountId, walletSelector]);

  return <div id="idos_container"></div>;
};

export default KYCVerificationIdOS;
