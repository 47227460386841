const BigClockIcon = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="35" cy="35" r="34" stroke="#F8F8F8" strokeWidth="2" />
      <rect x="33.3044" y="13" width="2" height="23.9474" rx="1" fill="#F8F8F8" />
      <rect
        x="33"
        y="36.6172"
        width="2"
        height="17.7698"
        rx="1"
        transform="rotate(-64.1771 33 36.6172)"
        fill="#F8F8F8"
      />
    </svg>
  );
};

export default BigClockIcon;
