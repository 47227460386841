import { useState } from "react";
import { ApproveBountyForm } from "../Dialogues";
import { useStoreActions } from "../../../hooks";
import { Bounty } from "../../../types/Bounty";
import ActionButton from "./general/ActionButton";

export default function SeveralApproveAction(props: { bounty: Bounty }) {
  const { bounty } = props;
  const [showForm, setShowForm] = useState(false);
  const setSeveralApprove = useStoreActions((actions) => actions.nearApi.setSeveralApprove);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    await setSeveralApprove([
      {
        id: bounty.id
      }
    ]);
  };

  return (
    <>
      <ActionButton disabled={false} text={"Approve All"} handleClick={handleButtonClick} />
      <ApproveBountyForm
        bounty={bounty}
        show={showForm}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
    </>
  );
}
