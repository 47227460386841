import { useState, useEffect } from "react";

interface TimerProps {
  initSecs: number;
  onEnd: () => void;
}

function Timer(props: TimerProps) {
  const { initSecs, onEnd } = props;
  const [timerSecs, setTimerSecs] = useState(initSecs);

  useEffect(() => {
    timerSecs === 0 && onEnd();
    timerSecs > 0 && setTimeout(() => setTimerSecs(timerSecs - 1), 1000);
  }, [timerSecs, onEnd]);

  return <p className="text-neutral-50 font-bold text-3xl">{timerSecs}</p>;
}

export default Timer;
