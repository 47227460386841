interface ItemProps {
  imageId: number,
  title: string,
  bgImageClass: string
}

function Item(props: ItemProps) {
  const { imageId, title, bgImageClass } = props;

  const styles = {
    container: `
      w-full min-h-[440px]
      md:max-w-[257px] lg:max-w-[343px]
      md:min-h-[332px] lg:min-h-[440px]
    `,
    background: `
      ${bgImageClass} bg-center bg-no-repeat bg-contain
    `,
    title: {
      container: `
        flex items-center justify-center
        py-[10%]
      `,
      subContainer: `
         w-[80%]
        flex flex-row space-x-6 justify-center items-start
      `,
      number: `
        text-primary-900 text-xl font-bold leading-7
      `,
      text: `
        w-[85%]
        text-transparent text-base font-medium font-poppins
        bg-benefits-section__item-text-gradient bg-clip-text bg-cover bg-no-repeat
        md:text-sm lg:text-base
      `,
    }
  }

  return (
    <div className={styles.container + styles.background}>
      <div className={styles.title.container}>
        <div className={styles.title.subContainer}>
          <h6 className={styles.title.number}>{`0${imageId}`}</h6>
          <p className={styles.title.text}>{title}</p>
        </div>
      </div>
    </div>
  )
}

export {
  Item,
  type ItemProps,
}
