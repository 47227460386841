import { keyStores } from "near-api-js";
import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;

const keyStore = new keyStores.BrowserLocalStorageKeyStore();
const APP_NETWORK = process.env.REACT_APP_NETWORK || "testnet";

const testnet = {
  keyStore,
  networkId: "testnet",
  nodeUrl: "https://rpc.testnet.near.org",
  walletUrl: "https://wallet.testnet.near.org",
  helperUrl: "https://helper.testnet.near.org",
  explorerUrl: "https://explorer.testnet.near.org",
  isTestnet: true,
};

const mainnet = {
  keyStore,
  networkId: "mainnet",
  nodeUrl: "https://rpc.mainnet.near.org",
  walletUrl: "https://wallet.near.org",
  helperUrl: "https://helper.mainnet.near.org",
  explorerUrl: "https://explorer.near.org",
  isTestnet: false,
};

const configs = {
  testnet,
  mainnet,
};

const getNearConfig = (network) => {
  const config = configs[network];
  return {
    ...config,
  };
};

export const nearConfig = getNearConfig(APP_NETWORK);
