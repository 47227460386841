import { twMerge } from "tailwind-merge";

type CustomItemStyles = {
  container?: string;
  text?: string;
};

interface CustomItemProps {
  name?: string;
  value?: string;
  id: number;
  onClick?: (value: string | null) => void;
  onMouseOver?: (value: string | null) => void;
  onKeyDown?: (value: string | null) => void;
  customStyles?: CustomItemStyles;
  highlighted?: boolean;
}

export const buildStyles = (
  customStyles?: CustomItemStyles | undefined,
  highlighted: boolean = false
) => ({
  container: twMerge(`
   px-[2rem] py-[1rem]
   ${highlighted ? "bg-neutral-820" : "bg-transparent"}
   hover:cursor-pointer
   text-neutral-400 font-poppins
   ${customStyles?.container ? customStyles.container : ""}
  `)
});

export default function CustomItem(props: CustomItemProps) {
  const { name, value, id, onClick, onMouseOver, onKeyDown, customStyles, highlighted } = props;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    event.preventDefault();
    if (!handleKeyDown) {
      return;
    }
    onKeyDown && onKeyDown(event.key);
  };

  const handleClick = (event: React.MouseEvent) => {
    const name = (event.target as HTMLLIElement).getAttribute("data-name");

    onClick && onClick(name);
  };

  const handleMouseOver = (event: React.MouseEvent) => {
    const name = (event.target as HTMLLIElement).getAttribute("data-name");

    onMouseOver && onMouseOver(name);
  };

  const styles = buildStyles(customStyles, highlighted);

  return (
    <li
      {...{
        className: styles.container,
        key: id,
        onClick: handleClick,
        onMouseOver: handleMouseOver,
        onKeyDown: handleKeyDown,
        "data-name": name
      }}>
      {value}
    </li>
  );
}
