import axios, { AxiosError, RawAxiosRequestHeaders } from "axios";

const BOT_API_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:5000/api-bot" : "/api-bot";

type ServerError = {
  error: boolean;
  message: string;
};

type Result<T = any> = {
  failure: ServerError | null;
  success: T;
  status?: number | undefined;
};

const axiosInstance = axios.create({
  withCredentials: false,
  baseURL: BOT_API_URL
});

axiosInstance.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=UTF-8"
};

export const httpRequest = async (
  method: "post" | "put" | "get" | "delete",
  path: string,
  data = {},
  headers?: RawAxiosRequestHeaders
) => {
  if (headers) {
    const common = axiosInstance.defaults.headers.common;
    axiosInstance.defaults.headers.common = {
      ...common,
      ...headers
    };
  }
  return await axiosInstance[method](`${path}`, data);
};

export const post = async (
  path: string,
  data = {},
  headers?: RawAxiosRequestHeaders
): Promise<any> => {
  return httpRequest("post", path, data, headers);
};

const del = async (path: string, data = {}): Promise<any> => {
  return httpRequest("delete", path, data);
};

const put = async (path: string, data = {}, headers = {}): Promise<any> => {
  return httpRequest("put", path, data, headers);
};

const get = async (path: string) => {
  return httpRequest("get", path, undefined, undefined);
};

const getBountyFromGithub = async ({ id }: { id?: string }) => {
  return await get(`/github-issue/${id}`);
};

export const BotApi = {
  getBountyFromGithub
};
