import { useState, useRef, useEffect } from "react";
import { SelectArrow } from "../../SVGIcon";
import { buildStyles as buildItemStyles } from "../Item";
import OptionList, { OptionListStyles } from "../OptionList";
import { Input } from "../../../general/Input";
import { findIdByValue, findIdsByValues } from "../../../../utils";
import { useOutsideClick } from "../../../../hooks";
import Tag from "../../../general/Tag";
import { scroll } from "../MultiSelect";
import { twMerge } from "tailwind-merge";

export type Option = {
  name: string;
  value: string;
};

const compare = (element: Option, item: Option) => {
  return element.name === item.name;
};

interface NewMultiSelectProps {
  placeholder?: string;
  defaultOption: Option;
  defaultOptionsList: Option[];
  selected: Option;
  onSelect: (value: Option) => void;
  multiSelected: Option[];
  onMultiSelect: (value: Option[]) => void;
  customStyles?: NewMultiSelectStyles;
  disabled?: boolean;
  showCheckbox?: boolean;
}

type NewMultiSelectStyles = {
  container?: string;
  selectedItem?: string;
  optionsList?: OptionListStyles;
};

const buildStyles = (
  customStyles: NewMultiSelectStyles | undefined,
  showOptionList: boolean,
  disabled: boolean = false
) => ({
  container: twMerge(`
    m-width-[50px]
    relative
    ${customStyles?.container ? customStyles.container : ""}
    ${disabled ? "!cursor-not-allowed" : ""}
  `),
  selectedItem: twMerge(`
    ${buildItemStyles().container}
    py-[15px]
    flex flex-row gap-2 items-center justify-between
    rounded-[10px]
    ${customStyles?.selectedItem ? customStyles.selectedItem : ""}
    ${disabled ? "!cursor-not-allowed" : ""}
  `)
});

function NewMultiSelect(props: NewMultiSelectProps) {
  const {
    placeholder,
    defaultOption,
    defaultOptionsList,
    selected,
    multiSelected,
    onSelect,
    onMultiSelect,
    customStyles,
    disabled,
    showCheckbox = false
  } = props;
  const [showOptionList, setShowOptionList] = useState(false);
  const [optionsList, setOptionsList] = useState<Option[]>(defaultOptionsList);
  const [filterInputValue, setFilterInputValue] = useState(selected.value);
  const [highlighted, setHighlighted] = useState(showCheckbox ? -1 : 0);
  const styles = buildStyles(customStyles, showOptionList, disabled);
  const optionListRef = useRef<HTMLUListElement>(null);
  const onKeyDown = (key: string) => {
    if (key === "ArrowUp") {
      scroll(key, optionsList.length, highlighted, optionListRef);
      const newHighlightedId = highlighted ? highlighted - 1 : 0;
      setHighlighted(newHighlightedId);
      setShowOptionList(true);
    } else if (key === "ArrowDown") {
      scroll(key, optionsList.length, highlighted, optionListRef);
      const newHighlightedId =
        highlighted < optionsList.length - 1 ? highlighted + 1 : optionsList.length - 1;
      setHighlighted(newHighlightedId);
      setShowOptionList(true);
    } else if (key === "Enter") {
      const safeSelected = optionsList[highlighted];
      if(safeSelected) {
        processSelect(safeSelected, defaultOption.value, defaultOptionsList, multiSelected);
      }
    }
  };

  const onOptionClick = (selectedName: string) => {
    const selectedOption = optionsList.filter((opt: Option) => opt.name === selectedName)[0];
    processSelect(selectedOption, defaultOption.value, defaultOptionsList, multiSelected);
  };

  const onMouseOver = (highlightedName: string) => {
    const highlightedOption =
      optionsList.find((option: Option) => option.name === highlightedName) || optionsList[0];
    const highlightedId = findIdByValue<Option>(optionsList, highlightedOption, compare) || 0;
    setHighlighted(highlightedId);
  };
  const processSelect = (
      selectedOption: Option | undefined,
      filterInputValue: string,
      optionsList: Option[],
      multiSelectedOptions: Option[] = []
  ) => {
    selectedOption && onSelect && onSelect(selectedOption);

    const alreadySelected = multiSelectedOptions.find(
        (sel: Option) => sel.name === selectedOption?.name
    );

    selectedOption &&
    onMultiSelect &&
    !alreadySelected &&
    onMultiSelect(multiSelectedOptions.concat(selectedOption));
    selectedOption &&
    onMultiSelect &&
    alreadySelected &&
    onMultiSelect(
        multiSelectedOptions.filter((option: Option) => option.name !== selectedOption.name)
    );

    setOptionsList(optionsList);
    setFilterInputValue(filterInputValue);
  };

  const onFilterInputChange = (value: string) => {
    const filteredOptions = defaultOptionsList.filter((option) =>
      option.value.toLowerCase().match(value.toLowerCase())
    );

    processSelect(undefined, value, filteredOptions, multiSelected);
    setShowOptionList(true);
    value === "" && setHighlighted(0);
  };

  const handleListDisplay = () => {
    !disabled && setShowOptionList(!showOptionList);
  };

  const handleClickOutside = () => {
    setShowOptionList(false);
  };
  const outsideClickRef = useOutsideClick<HTMLDivElement>(handleClickOutside);

  // useEffect(() => {
  //   const input = document.querySelectorAll('input');
  //
  //   if(input?.length > 0) {
  //     for(let i= 0; i < input.length; i++){
  //       input[i].setAttribute('size', String(input[i].getAttribute('placeholder')?.length));
  //     }
  //   }
  // }, [])

  return (
    <div ref={outsideClickRef} className={styles.container}>
      <div
        className={showOptionList ? styles.selectedItem + "active" : styles.selectedItem}
        onClick={handleListDisplay}>
        <div className="flex gap-2 inline-block">
          <Input
            {...{
              customStyles: {
                input: `bg-transparent outline-none rounded-none !p-0 placeholder-neutral-500`
              },
              placeholder,
              name: "tags",
              value: filterInputValue,
              validation: { valid: false, started: false },
              onChange: onFilterInputChange,
              autocomplete: "off",
              onKeyDown,
              disabled
            }}
          />
        </div>
        <div className="min-w-3">
          <SelectArrow {...{ position: showOptionList ? "up" : "down", className: 'stroke-neutral-500' }} />
        </div>
      </div>
      {showOptionList && (
        <OptionList
          {...{
            refer: optionListRef,
            customStyles: {
              ...(customStyles?.optionsList || {}),
              item: showCheckbox ? "!bg-neutral-900" : "",
              contentContainer: showCheckbox
                ? "bg-neutral-900 border-[1px] border-neutral-700 rounded-xl mt-3"
                : "",
              container: "rounded-xl"
            },
            optionsList,
            onOptionClick,
            onMouseOver: !showCheckbox ? onMouseOver : () => {},
            onKeyDown,
            highlightedId: highlighted,
            highlightedIds: findIdsByValues(defaultOptionsList, multiSelected, compare),
            showCheckbox
          }}
        />
      )}
      {!showCheckbox && (
        <div className="flex flex-wrap gap-2 mt-5">
          {multiSelected.map((tag: Option) => (
            <Tag
              key={tag.name}
              customStyles={{
                container: "h-10 bg-neutral-830",
                text: "text-primary-900"
              }}
              {...{
                ...tag,
                onClose: (name: string) => {
                  onMultiSelect(multiSelected.filter((tag: Option) => tag.name !== name));
                }
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default NewMultiSelect;
