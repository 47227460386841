import { ReactNode } from "react";
import { RedCircleIcon, SelectArrow } from "../SVGIcon";

const Accordion = (props: { title: string; alert?: boolean; children?: ReactNode }) => {
  const { title = "", alert = false, children } = props;
  return (
    <div className="flex items-center justify-center">
      <div className="w-full mx-auto space-y-2">
        <details className="p-4 rounded-lg">
          <summary className="flex justify-between items-center py-2 text-lg cursor-pointer  outline-none">
            <span className="flex gap-2 items-center">
              {alert && <RedCircleIcon />}
              {title}
            </span>
            <SelectArrow />
          </summary>
          {children ? <div className="">{children} </div> : null}
        </details>
      </div>
    </div>
  );
};

export default Accordion;
