import { WalletSelector } from "@near-wallet-selector/core";
import { ModalHideReason } from "@near-wallet-selector/modal-ui/src/lib/modal.types";
import store from "../../store/store";
const onhideModalHandler = async (
  event: { hideReason: ModalHideReason },
  walletSelector: WalletSelector | null
) => {
  if (event.hideReason === "wallet-navigation") {
    const wallet = await walletSelector?.wallet();
    if (wallet) {
      if (["meteor-wallet", "here-wallet"].includes(wallet.id)) {
        const storeActions = store.getActions();
        storeActions.nearApi.setWalletSelector(walletSelector);
      }
    }
  }
};

export { onhideModalHandler };
