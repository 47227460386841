import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useStoreActions } from "../../../../../hooks";
import { DarkButton } from "../../../../general/Button";
import { Input, TextArea } from "../../../../general/Input";
import { ValidationLayout } from "../../../../Layout";
import Modal from "../../../../general/Modal";

type WhitelistData = {
  name: string;
  accounts: string;
};

export default function ViewWhitelistForm(props: {
  id: string | number;
  show: boolean;
  mode: "view" | "edit";
  handleClose: () => void;
  handleSubmit?: (value: WhitelistData) => void;
}) {
  const { show, handleClose, id, mode = "view", handleSubmit } = props;
  const methods = useForm<WhitelistData>({ shouldUnregister: true });
  const { errors, isSubmitted, isValid } = methods.formState;
  const getAccountWhitelistById = useStoreActions(
    (actions) => actions.accountWhitelists.getAccountWhitelistById
  );
  const [name, setName] = useState("");
  const [accounts, setAccounts] = useState("");

  const loadWhitelist = async (id: string | number) => {
    const result = await getAccountWhitelistById({ id });
    if (result) {
      methods.setValue("name", result.name, { shouldValidate: true });
      methods.setValue("accounts", result.accounts, { shouldValidate: true });
      setName(result.name);
      setAccounts(result.accounts);
    }
  };

  useEffect(() => {
    if (show && id) {
      loadWhitelist(id);
    }
  }, [show, id]);

  const handleChangeName = (value: string) => {
    methods.setValue("name", value, { shouldValidate: true });
    setName(value);
  };

  const handleChangeAccounts = (value: string) => {
    methods.setValue("accounts", value, { shouldValidate: true });
    setAccounts(value);
  };

  const onSubmit: SubmitHandler<WhitelistData> = (data) => {
    if (mode === "view") {
      handleClose();
    }
    if (isValid && handleSubmit) {
      handleSubmit(data);
    }
  };

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                onClick: () => methods.handleSubmit(onSubmit)(),
                text: mode === "edit" ? "Save" : "Close",
                type: "submit"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins my-2.5">
        <div className="text-lg text-white">{mode === "view" ? "View" : "Edit"} whitelist</div>
        <div className="py-2.5 text-neutral-200">
          {mode === "edit" && (
            <input
              type="hidden"
              defaultValue={name}
              {...methods.register("name", { required: "Required field" })}
            />
          )}
          <ValidationLayout
            {...{
              validate: () => {
                return {
                  valid: !errors.name,
                  started: isSubmitted,
                  message: errors.name?.message
                };
              }
            }}>
            <Input
              {...{
                placeholder: "Whitelist name",
                value: name,
                onChange: (value: string) => handleChangeName(value),
                disabled: mode === "view"
              }}
            />
          </ValidationLayout>
        </div>
        <div>
          {mode === "edit" && (
            <input
              type="hidden"
              defaultValue={accounts}
              {...methods.register("accounts", { required: "Required field" })}
            />
          )}
          <ValidationLayout
            {...{
              validate: () => {
                return {
                  valid: !errors.accounts,
                  started: isSubmitted,
                  message: errors.accounts?.message
                };
              }
            }}>
            <TextArea
              {...{
                placeholder: "Accounts",
                value: accounts,
                onChange: (value: string) => handleChangeAccounts(value),
                disabled: mode === "view"
              }}
            />
          </ValidationLayout>
          <p className="text-[10px] text-neutral-700">Comma separated values</p>
        </div>
      </div>
    </Modal>
  );
}
