import { useState } from "react";
import IconButton from "../../../general/Button/IconButton";
import { EyeIcon } from "../../../general/SVGIcon";
import ViewWhitelistForm from "../Dialogues/ViewWhitelistForm";

const ViewWhitelistAction = (props: { id: string | number }) => {
  const { id } = props;
  const [showForm, setShowForm] = useState(false);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  return (
    <div>
      <IconButton {...{ icon: <EyeIcon />, onClick: () => handleButtonClick() }} />
      <ViewWhitelistForm id={id} show={showForm} handleClose={handleButtonClick} mode={"view"} />
    </div>
  );
};

export default ViewWhitelistAction;
