import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import useWalletAccountId from "../../../../../../hooks/useWalletAccountId";
import { BountyCreate } from "../../../../../../types";
import { DarkButton } from "../../../../../general/Button";
import IconButton from "../../../../../general/Button/IconButton";
import { Input } from "../../../../../general/Input";
import { FormControl, FormRow } from "../../../../../general/Form/FormElements";
import { TrashIcon } from "../../../../../general/SVGIcon";

export type ReviewersFormData = {
  reviewers: BountyCreate.ReviewersParams;
  reviewer: string;
};

const BountyReviewers = () => {
  const methods = useFormContext<ReviewersFormData>();
  const { control, formState, setValue, setError } = methods;

  const { errors, isSubmitted } = formState;
  const actions = useStoreActions((actions) => actions);
  const { walletAccountId } = useWalletAccountId();

  const { reviewers } = useStoreState((state) => state.newBounty);
  const {
    nearApi: { checkAccountV2 },
    newBounty: { setMoreReviewers, deleteReviewer }
  } = actions;

  const [reviewer, setReviewer] = useState("");

  const checkReviewerAccount = async () => {
    try {
      const account = await checkAccountV2(reviewer);
      if (account) {
        await account.state();
      }
    } catch (error) {
      setError("reviewer", {
        message: "account does not exist",
        type: "onCheck"
      });
    }
  };

  const handleAddReviewer = async (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    await checkReviewerAccount();
    if (!errors.reviewer) {
      setMoreReviewers(reviewer);
      setValue("reviewer", "", { shouldValidate: true });
      setReviewer("");
    }
  };

  const handleRemoveReviewer = (e: React.MouseEvent<Element, MouseEvent>, index: number) => {
    e.preventDefault();
    deleteReviewer(index);
  };

  return (
    <FormRow>
      {(reviewers as BountyCreate.MoreReviewers)?.MoreReviewers?.more_reviewers?.length ? (
        <FormRow className="pb-2.5">
          {(reviewers as BountyCreate.MoreReviewers)?.MoreReviewers?.more_reviewers?.map(
            (item, index) => (
              <div
                key={item}
                className="mb-2.5 flex p-3.5 items-center justify-between rounded-[10px] bg-neutral-800">
                <div className="text-neutral-200">{item}</div>
                <IconButton
                  {...{
                    icon: <TrashIcon className="w-4 h-4 hover:stroke-primary-900" />,
                    onClick: (e: React.MouseEvent<Element, MouseEvent>) =>
                      handleRemoveReviewer(e, index)
                  }}
                />
              </div>
            )
          )}
        </FormRow>
      ) : (
        <FormRow className="font-poppins text-sm text-neutral-400">
          Reviewers of a bounty will be able to act on a bounty`s applicants, i.e., Accepting and
          Approving applicants but will not have full control over the bounty.
        </FormRow>
      )}
      <FormRow>
        <FormControl
          name="reviewer"
          control={control}
          rules={{
            validate: {
              walletAccount: (value: string) =>
                value !== walletAccountId || "You can't use your own wallet account"
            }
          }}
          defaultValue={reviewer}
          render={({ field: { name, value } }) => {
            return (
              <Input
                {...{
                  customStyles: {
                    input: "px-[20px] py-[15px]"
                  },
                  placeholder: "Reviewer1.near",
                  value: reviewer,
                  name: "reviewer",
                  validation: {
                    valid: !errors.reviewer,
                    message: errors.reviewer?.message,
                    started: isSubmitted
                  },
                  onChange: (value: string) => {
                    setValue("reviewer", value, {
                      shouldValidate: true
                    });
                    setReviewer(value);
                  }
                }}
                {...{ name, value }}
              />
            );
          }}
        />
      </FormRow>
      <FormRow className="flex justify-end">
        <DarkButton
          {...{
            customStyles: {
              button: "border border-neutral-700 text-white max-w-[156px]"
            },
            disabled: !reviewer,
            text: "Add reviewer",
            onClick: (e: React.MouseEvent<Element, MouseEvent>) => handleAddReviewer(e)
          }}
        />
      </FormRow>
    </FormRow>
  );
};

export default BountyReviewers;
