import utils from "../../../../services/utils";
import { getTokenData } from "../../general/helpers";
import Amount from "./Amount";

const TokenAmount = (props: { token: string; amount: string; className?: string }) => {
  const { token, amount, className } = props;

  return (
    <Amount
      symbol={getTokenData(token)?.symbol ?? ""}
      amount={utils.formatToken(amount, getTokenData(token)?.decimals || 0)}
      className={className}
    />
  );
};

export default TokenAmount;
