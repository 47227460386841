import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Subtask } from "../../../../../../../../types/BountyCreate";
import Modal from "../../../../../../../general/Modal";
import { FormControl, FormRow } from "../../../../../../../general/Form/FormElements";
import { ValidationLayout } from "../../../../../../../Layout";
import { Input, TextArea } from "../../../../../../../general/Input";
import { DarkButton } from "../../../../../../../general/Button";

type FormData = Subtask;

const AddTaskActionForm = (props: {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (value: FormData) => void;
}) => {
  const { show, handleClose, handleSubmit } = props;
  const [description, setDescription] = useState("");
  const [percent, setPercent] = useState("0");
  const methods = useForm<FormData>({
    mode: "onChange"
  });
  const { errors, isValid, isSubmitted } = methods.formState;

  const handleChangeDescription = (value: string) => {
    methods.setValue("subtask_description", value, { shouldValidate: true });
    setDescription(value);
  };

  const handleChangePercent = (value: string) => {
    if (value) {
      methods.setValue("subtask_percent", parseInt(value), { shouldValidate: true });
      setPercent(value);
    }
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (isValid) {
      handleSubmit(data);
      methods.reset({ subtask_description: "", subtask_percent: 0 });
    }
  };

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose
      }}>
      <div className="font-poppins">
        <div className="text-neutral-50 py-2.5">Add new subtask.</div>
        <div className="px-4 -bg-neutral-700 mx-[-1.25em]">
          <FormRow>
            <input
              type="hidden"
              defaultValue={description}
              {...methods.register("subtask_description", { required: "Required field" })}
            />
            <ValidationLayout
              {...{
                validate: () => {
                  return {
                    valid: !errors.subtask_description,
                    started: isSubmitted,
                    message: errors.subtask_description?.message
                  };
                }
              }}>
              <TextArea
                {...{
                  placeholder: "Subtask description",
                  onChange: (value: string) => handleChangeDescription(value)
                }}
              />
            </ValidationLayout>
          </FormRow>
          <FormRow>
            <FormControl
              name="subtask_percent"
              control={methods.control}
              defaultValue={percent}
              rules={{
                required: "Required field",
                validate: {
                  nonZero: (value: string) => parseInt(value) > 0 || "Non-zero value"
                }
              }}
              render={({ field: { name, value, onChange } }) => {
                return (
                  <Input
                    {...{
                      customStyles: {
                        input: "px-[20px] py-[15px]"
                      },
                      placeholder: "Percents",
                      label: { text: "Percents, %" },
                      value: value,
                      name: name,
                      validation: {
                        valid: !errors.subtask_percent,
                        message: errors.subtask_percent?.message,
                        started: isSubmitted
                      },
                      onChange: (value: string) => {
                        onChange(value);
                        handleChangePercent(value);
                      }
                    }}
                    {...{ name, value }}
                  />
                );
              }}
            />
          </FormRow>
        </div>
        <div className="py-2.5 flex justify-end">
          <DarkButton
            {...{
              customStyles: { button: "max-w-[96px]" },
              onClick: () => methods.handleSubmit(onSubmit)(),
              text: "Add",
              type: "submit"
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddTaskActionForm;
