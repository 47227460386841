import { useForm } from "react-hook-form";
import { DefermentOfKYC } from "../../../../types/Kyc";
import { DarkButton } from "../../../general/Button";
import CheckBox from "../../../general/CheckBox";
import Modal from "../../../general/Modal";
import { Bounty } from "../../../../types/Bounty";
import { Claim } from "../../../../types/Claim";
import moment from "moment";
import utils from "../../../../services/utils";
import { Area } from "../../../Layout";
import { InfoIcon } from "../../../general/SVGIcon";

type FormData = {
  kyc_postpone: undefined | DefermentOfKYC;
};

export default function AcceptHeroForm(props: {
  bounty: Bounty;
  claim: Claim;
  show: boolean;
  handleClose: () => void;
  handleSubmit: (value?: DefermentOfKYC) => void;
  isClaimerWhitelisted: boolean;
  useKYC: boolean;
  canPostponeKYC: boolean;
}) {
  const {
    bounty,
    claim,
    show,
    handleClose,
    handleSubmit,
    canPostponeKYC,
    useKYC = false,
    isClaimerWhitelisted
  } = props;
  const methods = useForm<FormData>({
    mode: "onChange"
  });
  const { register, setValue, getValues, watch } = methods;

  const isCorrectDeadline = () => {
    if (bounty.deadline === "WithoutDeadline") {
      return true;
    }
    if (bounty.deadline === "DueDate") {
      const due_date = moment(bounty.due_date).valueOf();
      const curr = moment()
        .add(parseInt(claim.deadline ?? "") / 1000000, "ms")
        .valueOf();
      return due_date >= curr;
    }
    if (bounty.deadline === "MaxDeadline") {
      const bountyDeadline = utils.getNumberDeadline(bounty) ?? 0;
      const currentDeadline = claim.deadline ? parseInt(claim.deadline) : 0;
      return bountyDeadline >= currentDeadline;
    }
    return false;
  };

  const isDisabled = () => {
    if (!useKYC) {
      return false;
    }
    return !isClaimerWhitelisted && !watch("kyc_postpone");
  };

  const handleKycPostponeClick = (value: DefermentOfKYC) => {
    if (getValues("kyc_postpone") === value) {
      setValue("kyc_postpone", undefined, { shouldValidate: true });
    } else {
      setValue("kyc_postpone", value, { shouldValidate: true });
    }
  };

  const handleSubmitClick = () => {
    const value = getValues("kyc_postpone");
    if (value) {
      handleSubmit(value);
    } else {
      handleSubmit();
    }
  };

  return (
    <Modal
      {...{
        customStyles: { content: "min-h-[300px] h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                disabled: isDisabled() || !isCorrectDeadline(),
                onClick: () => handleSubmitClick(),
                text: "Accept"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white mb-2.5">Accept application</div>
        {useKYC && !isClaimerWhitelisted ? (
          <div className="my-2.5">
            <h2 className="text-white">This applicant has not completed KYC verification</h2>
            {canPostponeKYC && (
              <>
                <div className="py-2.5">
                  <p className="text-sm text-neutral-400">
                    If you want to accept the applicant, you need to skip or postpone KYC:
                  </p>
                  <div className="flex flex-wrap gap-4 py-2.5">
                    <input type="hidden" {...register("kyc_postpone")} />
                    <CheckBox
                      {...{
                        text: "Skip KYC",
                        active: watch("kyc_postpone") === "AllowedToSkipKyc",
                        onClick: () => handleKycPostponeClick("AllowedToSkipKyc")
                      }}
                    />
                    <CheckBox
                      {...{
                        text: "Postpone Before Deadline",
                        active: watch("kyc_postpone") === "BeforeDeadline",
                        onClick: () => handleKycPostponeClick("BeforeDeadline")
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            {isCorrectDeadline() ? (
              <div className="py-2.5 text-neutral-200">
                Are you sure you want to accept the Hero?
              </div>
            ) : (
              <>
                <Area>
                  <div className="flex items-center">
                    <div className="pl-6 text-neutral-400">
                      <InfoIcon />
                    </div>
                    <div className="py-5 px-6">
                      <p className="text-sm text-neutral-400">
                        At the moment, the claimed duration for completing the claim by the
                        applicant exceeds the established bounty deadline.
                      </p>
                    </div>
                  </div>
                </Area>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}
