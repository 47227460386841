import { createContext } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { useStoreState } from "../../hooks";
import { WalletSelectorContextProvider } from "../../providers/WalletSelectorContext";
import { AllowCreateBountyContextProvider } from "../../providers/AllowCreateBountyContext";

const MIXPANEL_TOKEN = "fdc62c05d0b7af073fd100ef0f221869";

export const MixpanelContext = createContext({ token: "" });

function App() {
  const { authLoading } = useStoreState((state) => state.auth);
  // checking authentication
  if (authLoading) {
    return <div className="content">Checking Authentication...</div>;
  } else {
    return (
      <WalletSelectorContextProvider>
        <AllowCreateBountyContextProvider>
          <MixpanelContext.Provider value={{ token: MIXPANEL_TOKEN }}>
            <div className="font-spacegrotesk bg-neutral-900">
              <RouterProvider router={router} />
            </div>
          </MixpanelContext.Provider>
        </AllowCreateBountyContextProvider>
      </WalletSelectorContextProvider>
    );
  }
}

export default App;
