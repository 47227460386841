export const fileToBase64 = (readerResult: string) => {
  if (!readerResult) {
    return "";
  }

  return readerResult.replace("data:", "").replace(/^.+,/, "");
};

export const findIdByValue = <T>(
  array: Array<T>,
  item: T,
  compare: (element: T, item: T) => boolean
): number | undefined => {
  if (array?.length === 0) {
    return undefined;
  }

  const results = array.map((element, index) => {
    if (!compare(element, item)) {
      return undefined;
    }
    return index;
  });
  const filteredResults = results.filter((element) => typeof element === "number")[0];
  return filteredResults;
};

export const findIdsByValues = <T>(
  full: Array<T>,
  part: Array<T>,
  compare: (element: T, item: T) => boolean
): number[] => {
  if (full?.length === 0 || part?.length === 0) {
    return [];
  }

  const results = part.map((element) => findIdByValue(full, element, compare));
  const filteredResults = (results.flat().filter((element) => typeof element === "number") ||
    []) as number[];

  return filteredResults;
};

export type ImageData = {
  contentType: "string";
  data: {
    type: Buffer;
    data: Uint16Array;
  };
  height: number;
  width: number;
};

export type VersionedImage = {
  original?: ImageData;
  small?: ImageData;
};

export const base64ImageUrl = (base64Image: string, contentType: string | null) => {
  return contentType
    ? `data:${contentType};base64,${base64Image}`
    : `data:image/jpeg;base64,${base64Image}`;
};

export const imageDataToBase64Image = (imageData: ImageData): string => {
  const buffer = Buffer.from(imageData.data.data);
  return buffer.toString("base64");
};

export const downloadPDFFile = (file: File) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([file], { type: "application/pdf" }));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", file.name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadCSVFile = (file: File) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([file], { type: "text/csv" }));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", file.name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadZipFile = (file: File) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([file], { type: "application/zip" }));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", file.name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadFile = (file: File, fileType?: string) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([file], { type: fileType }));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", file.name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
