const BigCheckIcon = () => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 36.75L31.25 45L45 25.75M67 34C67 38.3336 66.1464 42.6248 64.488 46.6286C62.8296 50.6323 60.3989 54.2702 57.3345 57.3345C54.2702 60.3989 50.6323 62.8296 46.6286 64.488C42.6248 66.1464 38.3336 67 34 67C29.6664 67 25.3752 66.1464 21.3714 64.488C17.3677 62.8296 13.7298 60.3989 10.6655 57.3345C7.60114 54.2702 5.17038 50.6323 3.51198 46.6286C1.85357 42.6248 1 38.3336 1 34C1 25.2479 4.47678 16.8542 10.6655 10.6655C16.8542 4.47678 25.2479 1 34 1C42.7521 1 51.1458 4.47678 57.3345 10.6655C63.5232 16.8542 67 25.2479 67 34Z"
        stroke="#F8F8F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BigCheckIcon;
