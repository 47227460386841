import classNames from "classnames";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import Mark from "../general/Mark";

interface ListItemProps {
  customStyle?: string;
  children: ReactNode[] | ReactNode;
  id?: number;
  needAction?: boolean;
}

export const buildStyles = (customStyle: string | undefined) => ({
  contentContainer: twMerge(`
    flex flex-col items-center space-y-4
    p-navbar-li
    text-neutral-100 uppercase
    lg:flex-row lg:space-y-0
    lg:p-0 lg:px-navbar-li
    justify-center 
    ${customStyle ? customStyle : ""}
  `)
});

export default function ListItem(props: ListItemProps) {
  const { customStyle, children, id, needAction = false } = props;

  const styles = buildStyles(customStyle);

  return (
    <li key={id} className={styles.contentContainer}>
      {children}
      {needAction && (
        <Mark
          {...{
            text: "!",
            customStyles: {
              container: classNames(
                "flex items-center text-neutral-800 bg-secondary-900 w-4 h-4  rounded-[50%]"
              )
            }
          }}
        />
      )}
    </li>
  );
}
