import { useState } from "react";
import { twMerge } from "tailwind-merge";
import Separator from "../Separator";
import { SelectArrow } from "../SVGIcon";
import Item, { buildStyles as buildItemStyles } from "./Item";

import "./styles.css";

export type Option = {
  name: string;
  value: string;
};

interface SelectProps {
  defaultOption: Option;
  optionsList: Option[];
  selected?: Option;
  onSelect?: (value: Option) => void;
}

type SelectStyles = {
  container?: string;
  selectedItem?: string;
  optionsList?: string;
};

const buildStyles = (customStyles: SelectStyles | undefined, showOptionList: boolean) => ({
  container: twMerge(`
    m-width-[250px]
    relative
    ${customStyles?.container ? customStyles.container : ""}
  `),
  selectedItem: twMerge(`
    ${buildItemStyles().container}
    flex flex-row justify-between items-center
    rounded-[10px]
    ${showOptionList ? "rounded-b-none" : ""}
    ${customStyles?.selectedItem ? customStyles.selectedItem : ""}
  `),
  optionsList: twMerge(`
    options-list
    max-h-[275px]
    w-full
    rounded-b-[10px]
    mr-[-0.5em]
    ${customStyles?.optionsList ? customStyles.optionsList : ""}
  `)
});

function Select(props: SelectProps) {
  const { defaultOption, optionsList, selected, onSelect } = props;
  const [showOptionList, setShowOptionList] = useState(false);

  const styles = buildStyles(undefined, showOptionList);

  const handleListDisplay = () => {
    setShowOptionList(!showOptionList);
  };

  const handleOptionClick = (name: string) => {
    const selectedName = name;
    const selectedOption = optionsList.filter((opt: Option) => opt.name === selectedName)[0];
    if (onSelect) {
      onSelect(selectedOption);
    }
    setShowOptionList(false);
  };

  return (
    <div className={styles.container}>
      <div
        className={showOptionList ? styles.selectedItem + "active" : styles.selectedItem}
        onClick={handleListDisplay}>
        {selected?.value || defaultOption.value}
        <SelectArrow {...{ position: showOptionList ? "up" : "down" }} />
      </div>
      {showOptionList && (
        <div
          className="absolute w-full z-50
">
          <div className="relative">
            <ul className={styles.optionsList}>
              {optionsList.map((option: Option, id: number) => {
                return (
                  <>
                    <Separator />
                    <Item
                      {...{
                        ...option,
                        id,
                        onClick: handleOptionClick,
                        customStyles: {
                          container: id === optionsList.length - 1 ? `rounded-b-[10px]` : ""
                        }
                      }}
                    />
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Select;
