import classNames from "classnames";
import { twMerge } from "tailwind-merge";

const PencilIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(classNames("w-6 h-6 stroke-neutral-400", className))}>
      <path
        d="M2.02121 11.2345L1.07471 13.6C1.04743 13.6682 1.04075 13.7428 1.0555 13.8147C1.07025 13.8866 1.10578 13.9526 1.15769 14.0045C1.20959 14.0565 1.27559 14.092 1.3475 14.1067C1.4194 14.1215 1.49406 14.1148 1.56221 14.0875L3.92846 13.141C4.30568 12.9903 4.64836 12.7644 4.93571 12.4773L13.125 4.28728C13.4233 3.98891 13.5909 3.58424 13.5909 3.16228C13.5909 2.74032 13.4233 2.33565 13.125 2.03728C12.8266 1.73891 12.4219 1.57129 12 1.57129C11.578 1.57129 11.1733 1.73891 10.875 2.03728L2.68496 10.2273C2.39779 10.5146 2.17198 10.8573 2.02121 11.2345Z"
        fill="#949494"
      />
    </svg>
  );
};

export default PencilIcon;
