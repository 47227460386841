import moment from "moment";
import { useMemo } from "react";
import AllowedCreateClaimToDate from "./AllowedCreateClaimToDate";
import AllowedCreateClaimToPeriod from "./AllowedCreateClaimToPeriod";
import MinAmountToStartForm from "./MinAmountToStart";
import SuccessfulClaimsForResult from "./SuccessfulClaimsForResult";
import { useStoreActions, useStoreState } from "../../../../../../../hooks";
import { ContestOrHackathon } from "../../../../../../../types/BountyCreate";
import { formatting } from "../../../../../../../common/formatting";
import { FormRow } from "../../../../../../general/Form/FormElements";
import { Area } from "../../../../../../Layout";
import CheckBox from "../../../../../../general/CheckBox";
import CreatingManyClaimsOption from "../CreatingManyClaimsOption";

const ContestOrHackathonForm = () => {
  const multitasking = useStoreState((state) => state.newBounty.multitasking as ContestOrHackathon);
  const setStartConditions = useStoreActions((actions) => actions.newBounty.setStartConditions);
  const setAllowedCreateClaim = useStoreActions(
    (actions) => actions.newBounty.setAllowedCreateClaim
  );

  const allowedCreateClaim = useMemo(() => {
    if (multitasking?.ContestOrHackathon?.allowed_create_claim_to) {
      if ("Date" in { ...multitasking.ContestOrHackathon.allowed_create_claim_to }) {
        return "Date";
      }
      if ("Period" in { ...multitasking.ContestOrHackathon.allowed_create_claim_to }) {
        return "Period";
      }
    }
  }, [multitasking?.ContestOrHackathon.allowed_create_claim_to]);

  const startConditions = useMemo(() => {
    if (multitasking?.ContestOrHackathon?.start_conditions) {
      if (multitasking?.ContestOrHackathon?.start_conditions === "ManuallyStart") {
        return "ManuallyStart";
      }
      if ("MinAmountToStart" in { ...multitasking?.ContestOrHackathon?.start_conditions }) {
        return "MinAmountToStart";
      }
    }
  }, [multitasking?.ContestOrHackathon.start_conditions]);

  const handleCheckStartConditions = (value: string) => {
    if (value === "MinAmountToStart") {
      if (
        typeof multitasking?.ContestOrHackathon?.start_conditions === "object" &&
        "MinAmountToStart" in { ...multitasking.ContestOrHackathon.start_conditions }
      ) {
        setStartConditions(undefined);
      } else {
        setStartConditions({ MinAmountToStart: { amount: 0 } });
      }
    }
    if (value === "ManuallyStart") {
      if (
        typeof multitasking?.ContestOrHackathon?.start_conditions === "string" &&
        multitasking?.ContestOrHackathon?.start_conditions === "ManuallyStart"
      ) {
        setStartConditions(undefined);
      } else {
        setStartConditions("ManuallyStart");
      }
    }
  };

  const handleClickAllowedCreateClaim = (value: string) => {
    if (value === "Date") {
      if ("Date" in { ...multitasking?.ContestOrHackathon?.allowed_create_claim_to }) {
        setAllowedCreateClaim(undefined);
      } else {
        const timeStampInNano = formatting.formatDayToNanoSec(
          moment(new Date()).utc(false).toDate()
        );
        setAllowedCreateClaim({ Date: { date: timeStampInNano.toString() } });
      }
    }
    if (value === "Period") {
      if ("Period" in { ...multitasking?.ContestOrHackathon?.allowed_create_claim_to }) {
        setAllowedCreateClaim(undefined);
      } else {
        setAllowedCreateClaim({ Period: { period: "" } });
      }
    }
  };

  return (
    <FormRow>
      <div className="flex items-center gap-1 text-[12px] text-primary-900 outline-none py-2.5">
        Advanced settings
      </div>
      <Area
        {...{
          customStyles: {
            borderContainer: "border border-neutral-700 px-4 py-2.5"
          }
        }}>
        <FormRow>
          <h6>Allowed Create Claim To</h6>
          <FormRow className="flex gap-6">
            <CheckBox
              {...{
                text: "Date",
                active: allowedCreateClaim === "Date",
                onClick: () => handleClickAllowedCreateClaim("Date")
              }}
            />
            <CheckBox
              {...{
                text: "Period after start",
                active: allowedCreateClaim === "Period",
                onClick: () => handleClickAllowedCreateClaim("Period")
              }}
            />
          </FormRow>
          {allowedCreateClaim === "Date" && <AllowedCreateClaimToDate />}
          {allowedCreateClaim === "Period" && <AllowedCreateClaimToPeriod />}
        </FormRow>
        <FormRow>
          <h6>Start conditions</h6>
          <FormRow className="flex gap-6">
            <CheckBox
              {...{
                text: "Manually Start",
                active: startConditions === "ManuallyStart",
                onClick: () => handleCheckStartConditions("ManuallyStart")
              }}
            />
            <CheckBox
              {...{
                text: "Min number of members to start",
                active: startConditions === "MinAmountToStart",
                onClick: () => handleCheckStartConditions("MinAmountToStart")
              }}
            />
          </FormRow>
          {startConditions === "MinAmountToStart" && <MinAmountToStartForm />}
        </FormRow>
        <FormRow>
          <h6>Number Successful claims for result (Optional)</h6>
          <SuccessfulClaimsForResult />
        </FormRow>
        <CreatingManyClaimsOption />
      </Area>
    </FormRow>
  );
};

export default ContestOrHackathonForm;
