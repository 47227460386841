import { useInfiniteQuery } from "@tanstack/react-query";
import Api from "../services/Api";
import { useQueryParams, StringParam } from "use-query-params";
import {
  encodeDelimitedArray,
  encodeString,
  decodeDelimitedArray,
  decodeQueryParams,
  decodeString
} from "serialize-query-params";
import { createSearchParams } from "react-router-dom";
import { stringify } from "query-string";
import { HuntersListItem } from "../types/Hunter";

export type Response = {
  has_more: boolean;
  has_prev: boolean;
  item_count: number;
  page: number;
  page_count: number;
  hunters: HuntersListItem[];
};

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
const CommaArrayParam = {
  encode: (array: string[]): string | null | undefined => {
    return array?.length ? encodeDelimitedArray(array, ",") : undefined;
  },

  decode: (
    arrayStr: string | (string | null)[] | null | undefined
  ): (string | null)[] | null | undefined => {
    const decoded = decodeDelimitedArray(arrayStr, ",");
    return typeof decoded === "undefined" ? [] : decoded;
  }
};

const CustomStringParam = {
  encode: (
    value: string | (string | null)[] | null | undefined
  ): string | (string | null)[] | null | undefined => {
    return encodeString(value) ? encodeString(value) : undefined;
  },
  decode: (
    input: string | (string | null)[] | null | undefined
  ): string | (string | null)[] | null | undefined => {
    return decodeString(input) ? decodeString(input) : [];
  }
};

const useInfiniteHuntersSearchParams = (props: { limit?: number }) => {
  const { limit = 4 } = props;
  const [query, setQuery] = useQueryParams({
    skills: CustomStringParam,
    sort: CustomStringParam,
    kyc: StringParam,
    completedProfiles: StringParam
  });

  const decodedQueryParams = decodeQueryParams(
    {
      skills: CommaArrayParam,
      sort: CommaArrayParam,
      kyc: StringParam,
      completedProfiles: StringParam
    },
    query
  );

  const fetchData = Api.getHunters;
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isError,
    isInitialLoading,
    isFetchingNextPage,
    status
  } = useInfiniteQuery(
    ["items", query, limit],
    ({ pageParam = 1 }) => {
      return fetchData({
        page: pageParam,
        limit,
        searchParams: query
      }).then((res: Response) => {
        return res;
      });
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.page < lastPage.page_count ? lastPage.page + 1 : undefined;
        return nextPage;
      }
    }
  );

  const getHuntersUrlParams = () =>
    createSearchParams(
      stringify(decodedQueryParams, {
        arrayFormat: "separator",
        arrayFormatSeparator: ",",
        skipNull: true
      })
    );

  const urlDefSearchParams = () =>
    createSearchParams(
      stringify(
        { sort: "Completed Bounties" },
        {
          arrayFormat: "separator",
          arrayFormatSeparator: ",",
          skipNull: true
        }
      )
    );

  const getHuntersUrlParamsOrDefault = () => {
    return getHuntersUrlParams().toString().length > 0
      ? getHuntersUrlParams()
      : urlDefSearchParams();
  };

  return {
    data,
    error,
    status,
    isError,
    isFetching,
    isInitialLoading,
    searchParams: decodedQueryParams,
    setQuery,
    getHuntersUrlParams,
    getHuntersUrlParamsOrDefault,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  };
};

export default useInfiniteHuntersSearchParams;
