import { action, computed, thunk, thunkOn } from "easy-peasy";
import { Auth } from "../../types";
import { CompanyMetadata, IndividualMetadata, Model } from "../../types/Auth";
import { differenceInMilliseconds } from "date-fns";
import { urlDefSearchParams } from "./BountyListModel";
import moment from "moment";

const initState: Model = {
  token: null, // manage the access token
  refferalLinkCode: null,
  expiredAt: 0, // manage expiry time of the access token
  user: null, // manage the user details
  isAuthenticated: false, // consider as a authentication flag
  isVerified: false,
  isTokenExpired: computed((state) => {
    return differenceInMilliseconds(new Date(state.expiredAt), new Date()) <= 0;
  }),
  authCount: 0,
  authLoading: false, // to indicate that the auth API is in progress
  userSignInLoading: false, // to indicate that the user signin API is in progress
  userSignUpLoading: false,
  verifyCodeLoading: false,

  signInError: null, // manage the error of the user signin API
  signUpError: null,
  verifyCodeError: null,
  verifyTokenFailure: false,
  callbackUrl: null
};

const fields: Auth.Model = {
  ...initState
};

const actions: Auth.Actions = {
  setToken: action((state, payload) => {
    state.token = payload;
  }),
  setExpiredAt: action((state, payload) => {
    state.expiredAt = payload;
  }),
  setVerified: action((state, payload) => {
    state.isVerified = payload;
  }),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setRefferalLinkCode: action((state, payload) => {
    state.refferalLinkCode = payload;
  }),
  setUserMetadata: action((state, payload) => {
    if (state.user) {
      if (payload.profileType === "Individual") {
        state.user.metadata = {
          ...(state.user?.metadata || {}),
          ...payload
        } as IndividualMetadata;
      }
      if (payload.profileType === "Company") {
        state.user.metadata = {
          ...(state.user?.metadata || {}),
          ...payload
        } as CompanyMetadata;
      }
    }
  }),
  setAvatar: action((state, payload) => {
    if (state.user) {
      state.user.avatar = payload;
    }
  }),
  setCoverImage: action((state, payload) => {
    if (state.user) {
      state.user.coverImage = payload;
    }
  }),
  verifyTokenStarted: action((state, payload) => {
    const { silentAuth } = payload;
    state.authLoading = true;
    if (!silentAuth) {
      state.token = initState.token;
      state.expiredAt = initState.expiredAt;
      state.user = initState.user;
      state.isAuthenticated = initState.isAuthenticated;
      state.userSignInLoading = initState.userSignInLoading;
      state.signInError = initState.signInError;
      state.verifyTokenFailure = initState.verifyTokenFailure;
    }
    //state.authCount += 1;
  }),
  verifyTokenEnd: action((state) => {
    state.authLoading = false;
  }),

  userSignInStarted: action((state) => {
    state.userSignInLoading = true;
  }),
  userSignUpStarted: action((state) => {
    state.userSignUpLoading = true;
  }),
  verifyCodeStarted: action((state) => {
    state.verifyCodeLoading = true;
  }),
  userSignInFailure: action((state, payload = "Something went wrong. Please try again later.") => {
    state.signInError = payload;
    state.userSignInLoading = false;
  }),
  userSignUpFailure: action((state, payload = "Something went wrong. Please try again later.") => {
    state.signUpError = payload;
    state.userSignUpLoading = false;
  }),
  verifyCodeFailure: action((state, payload = "Something went wrong. Please try again later.") => {
    state.verifyCodeError = payload;
    state.verifyCodeLoading = false;
  }),

  clearUserSignInError: action((state) => {
    state.signInError = initState.signInError;
  }),
  clearUserSignUpError: action((state) => {
    state.signUpError = initState.signUpError;
  }),

  verifyUserSuccess: action((state, payload) => {
    const { token, expiredAt } = payload;
    state.token = token;
    state.expiredAt = expiredAt;
    state.isAuthenticated = true;
    state.authLoading = false;
    state.authCount = 0;

    state.userSignInLoading = false;
    state.userSignUpLoading = false;
    state.verifyCodeLoading = false;

    state.signInError = null;
    state.signUpError = null;
    state.verifyCodeError = null;
  }),
  userSignOut: action((state) => {
    state.isAuthenticated = initState.isAuthenticated;
    state.isVerified = initState.isVerified;
    state.authLoading = false;
    state.token = initState.token;
    state.expiredAt = initState.expiredAt;
    state.authCount = 0;
    state.user = null;
  }),
  setVerifyTokenFailure: action((state, payload) => {
    state.verifyTokenFailure = payload;
  }),
  setCallbackUrl: action((state, payload) => {
    state.callbackUrl = payload;
  })
};

const thunks: Auth.Thunks = {
  verifyRefferalLinkCodeAsync: thunk(async (actions, payload, helpers) => {
    const { refferalLinkCode } = payload;
    const { setRefferalLinkCode } = actions;
    const verifyRefferalLinkCodeService =
      helpers.injections.authService.verifyRefferalLinkCodeService;

    const result = await verifyRefferalLinkCodeService(refferalLinkCode);
    if (result.failure) {
      setRefferalLinkCode(null);
    }

    if (result.success) {
      setRefferalLinkCode(refferalLinkCode);
    }
  }),
  verifyTokenAsync: thunk(async (actions, payload = { silentAuth: false }, helpers) => {
    const {
      verifyTokenStarted,
      verifyTokenEnd,
      userSignOut,
      verifyUserSuccess,
      setToken,
      setExpiredAt,
      setUser,
      setVerifyTokenFailure
    } = actions;
    const storeState = helpers.getStoreState();
    const storeACtions = helpers.getStoreActions();
    const walletSelector = storeState.nearApi.walletSelector;
    const token = storeState.auth.token;
    const expiredAt = storeState.auth.expiredAt;
    try {
      const verifyTokenService = helpers.injections.authService.verifyTokenService;
      const isTokenExpired = () => {
        return moment().isAfter(moment(expiredAt));
      };

      if (!token || isTokenExpired()) {
        verifyTokenStarted(payload);

        const result = await verifyTokenService();

        if (result.failure) {
          verifyTokenEnd();
          if (result.failure?.response?.status !== 401) {
            setVerifyTokenFailure(true);
          }
          console.log("token fail");
        }

        if (result.success) {
          if (result.success.status === 204) {
            userSignOut();
          }
          if (result.success.status === 200) {
            verifyTokenEnd();
            console.log("token succ");
            setExpiredAt(result.success.data.expiredAt);
            setToken(result.success.data.token);
            setUser(result.success.data.user);
            if (walletSelector) {
              storeACtions.nearApi.setWalletSelector(walletSelector);
            }
            verifyUserSuccess(result.success.data);
          } else {
            verifyTokenEnd();
          }
        }
      } else {
        setExpiredAt(expiredAt);
        setToken(token);
      }
    } catch (error) {
      userSignOut();
    }
  }),
  verifyCodeAsync: thunk(async (actions, payload = { type: "phone", code: 0 }, helpers) => {
    const { type, code } = payload;
    const { verifyCodeStarted, verifyCodeFailure, verifyUserSuccess, setUser } = actions;
    const profileConfirmPhone = helpers.injections.authService.profileConfirmPhone;
    const profileConfirmEmail = helpers.injections.authService.profileConfirmEmail;
    let result = null;

    verifyCodeStarted();
    switch (type) {
      case "phone":
        result = await profileConfirmPhone(code);
        break;
      case "email":
        result = await profileConfirmEmail(code);
        break;
    }

    if (result.failure) {
      verifyCodeFailure(result.failure.response?.message);
    }

    if (result.success) {
      console.log("verifyCodeAsync setUser");
      setUser(result.success.data);
      verifyUserSuccess(result.success.data);
    }
    return result;
  }),
  userSignUpAsync: thunk(async (actions, payload, helpers) => {
    const { email, password, name, rememberMe, refferalLinkCode } = payload;
    const {
      userSignUpStarted,
      userSignUpFailure,
      verifyUserSuccess,
      setToken,
      setUser,
      setExpiredAt
    } = actions;
    const userSignUpService = helpers.injections.authService.userSignUpService;

    userSignUpStarted();

    const result = await userSignUpService({
      email,
      password,
      name,
      rememberMe,
      verificationCode: refferalLinkCode
    });

    if (result.failure) {
      userSignUpFailure(result.failure.response?.message);
    }

    if (result.success) {
      const { data } = result.success;
      verifyUserSuccess(data);
      setExpiredAt(data.expiredAt);
      setToken(data.token);
      setUser(data.user);

      return helpers.getState();
    }
  }),
  userSignInAsync: thunk(async (actions, payload, helpers) => {
    const { email, password } = payload;
    const {
      userSignInStarted,
      userSignInFailure,
      verifyUserSuccess,
      setToken,
      setExpiredAt,
      setUser
    } = actions;
    const userSignInService = helpers.injections.authService.userSignInService;

    userSignInStarted();

    const result = await userSignInService(email, password);
    if (result.failure) {
      userSignInFailure(result.failure.response?.message);
    }

    if (result.success) {
      const { data } = result.success;

      verifyUserSuccess(data);
      setExpiredAt(data.expiredAt);
      setToken(data.token);
      setUser(data.user);
    }
  }),
  sendForgotPassword: thunk(async (actions, payload, helpers) => {
    const { email } = payload;
    const forgotPassword = helpers.injections.authService.forgotPassword;

    const result = await forgotPassword(email);
    if (result.failure) {
      const message: string = result.failure.response?.message || result.failure.error.message;
      return { error: message };
    } else {
      return { success: true };
    }
  }),
  userResetPassword: thunk(async (_, payload, helpers) => {
    const resetPassword = helpers.injections.authService.resetPassword;

    const result = await resetPassword(payload);
    if (result.failure) {
      const message = result.failure.response?.message || result.failure.error.message;
      return { error: message };
    } else {
      return { success: true };
    }
  }),
  userSignOutAsync: thunk(async (actions, _, helpers) => {
    const { userSignOut, setToken, setExpiredAt, clearUserSignInError, clearUserSignUpError } =
      actions;
    const dropWalletSelector = helpers.getStoreActions().nearApi.dropWalletSelector;

    const userSignOutService = helpers.injections.authService.userSignOutService;

    userSignOut();
    setToken(null);
    setExpiredAt(0);
    clearUserSignUpError();
    clearUserSignInError();
    await dropWalletSelector();
    await userSignOutService();
  }),
  userUpdateProfile: thunk(async (actions, payload, helpers) => {
    const { newUser, avatarImage, coverImage } = payload;
    const { setUser } = actions;
    const storeActions = helpers.getStoreActions();
    const updateAvatarImageMultipart =
      helpers.injections.profileService.Api.updateAvatarImageMultipart;
    const updateCoverImageMultipart =
      helpers.injections.profileService.Api.updateCoverImageMultipart;
    const updateProfile = helpers.injections.profileService.Api.updateProfile;
    const {
      error: { setError }
    } = storeActions;
    if (avatarImage) {
      const avatarResponse = await updateAvatarImageMultipart(avatarImage);
      if (!avatarResponse.success) {
        setError(
          avatarResponse.failure.error.response.data.message ?? avatarResponse.failure.error.message
        );
      }
    }
    if (coverImage) {
      const coverImageResponse = await updateCoverImageMultipart(coverImage);
      if (!coverImageResponse.success) {
        setError(
          coverImageResponse.failure.error.response.data.message ??
            coverImageResponse.failure.error.message
        );
      }
    }
    const profileResponse = await updateProfile(newUser);
    if (!profileResponse.success) {
      setError(
        profileResponse.failure.error.response.data.message ?? profileResponse.failure.error.message
      );
    } else {
      console.log("userUpdateProfile setUser");
      setUser(profileResponse.success.data);
    }
  }),
  getAvatar: thunk(async (actions, payload, helpers) => {
    const getAvatarImage = helpers.injections.profileService.getAvatarImage;
    const userSignOut = actions.userSignOut;
    const result = await getAvatarImage(payload);
    if (result && result.status) {
      if ([401].includes(result?.status)) {
        userSignOut();
      }
    }
    return result;
  }),
  getCoverImage: thunk(async (actions, payload, helpers) => {
    const getCoverImage = helpers.injections.profileService.getCoverImage;
    const userSignOut = actions.userSignOut;
    const result = await getCoverImage(payload);
    if (result && result.status) {
      if ([401].includes(result?.status)) {
        userSignOut();
      }
    }
    return result;
  }),
  loginWithGithubAsync: thunk(async (actions, payload, helpers) => {
    const {
      setUser,
      setToken,
      setExpiredAt,
      userSignInStarted,
      userSignInFailure,
      verifyUserSuccess,
      setCallbackUrl
    } = actions;
    const postAuthCallback = helpers.injections.authService.postAuthGithubCallback;
    userSignInStarted();
    const { code, state, router, callbackUrl } = payload;
    const response = await postAuthCallback({ code, state });
    if (response.success) {
      verifyUserSuccess(response.success.data);
      setExpiredAt(response.success.data.expiredAt);
      setToken(response.success.data.token);
      if (response.success.data.newUser) {
        router.navigate("/profile/edit", { replace: true });
      }
      if (response.success.data?.user) {
        setUser(response.success.data.user);
        const navigateTo = callbackUrl ? callbackUrl : `/bounties?${urlDefSearchParams()}`;
        setCallbackUrl(null);
        router.navigate(navigateTo, { replace: true });
      }
    } else {
      userSignInFailure(response.failure?.response?.data?.message);
      router.navigate("/sign-in", { replace: true });
    }
  }),
  loginWithGoogleAsync: thunk(async (actions, payload, helpers) => {
    const {
      setUser,
      setToken,
      setExpiredAt,
      userSignInStarted,
      userSignInFailure,
      verifyUserSuccess,
      setCallbackUrl
    } = actions;
    const postAuthCallback = helpers.injections.authService.postAuthGoogleCallback;
    userSignInStarted();
    const { code, state, router, callbackUrl } = payload;
    const response = await postAuthCallback({ code, state });
    if (response.success) {
      verifyUserSuccess(response.success.data);
      setExpiredAt(response.success.data.expiredAt);
      setToken(response.success.data.token);
      if (response.success.data.newUser) {
        router.navigate("/profile/edit", { replace: true });
      }
      if (response.success.data?.user) {
        setUser(response.success.data.user);
        const navigateTo = callbackUrl ? callbackUrl : `/bounties?${urlDefSearchParams()}`;
        setCallbackUrl(null);
        router.navigate(navigateTo, { replace: true });
      }
    } else {
      userSignInFailure(response.failure?.response?.data?.message);
      router.navigate("/sign-in", { replace: true });
    }
  }),
  loginWithTwitterAsync: thunk(async (actions, payload, helpers) => {
    const {
      setUser,
      setToken,
      setExpiredAt,
      userSignInStarted,
      userSignInFailure,
      verifyUserSuccess,
      setCallbackUrl
    } = actions;
    const postAuthCallback = helpers.injections.authService.postAuthTwitterCallback;
    userSignInStarted();
    const { oauth_token, oauth_verifier, router, callbackUrl } = payload;
    const response = await postAuthCallback({ oauth_token, oauth_verifier });
    if (response.success) {
      verifyUserSuccess(response.success.data);
      setExpiredAt(response.success.data.expiredAt);
      setToken(response.success.data.token);
      if (response.success.data.newUser) {
        router.navigate("/profile/edit", { replace: true });
      }
      if (response.success.data?.user) {
        setUser(response.success.data.user);
        const navigateTo = callbackUrl ? callbackUrl : `/bounties?${urlDefSearchParams()}`;
        setCallbackUrl(null);
        router.navigate(navigateTo, { replace: true });
      }
    } else {
      userSignInFailure(response.failure?.response?.data?.message);
      router.navigate("/sign-in", { replace: true });
    }
  }),
  verifyFractalCode: thunk(async (actions, payload, helpers) => {
    const { code, state, router } = payload;
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    try {
      const storeState = helpers.getStoreState();
      const postFractalOauthdata = helpers.injections.authService.postFractalOauthdata;
      const userData = storeState.auth.user;
      const walletSelector = storeState.nearApi.walletSelector;
      if (walletSelector && userData) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const walletAccountId = accounts[0].accountId;
        postFractalOauthdata(code, state, walletAccountId, userData);
        router.navigate(`/bounties?${urlDefSearchParams()}`, { replace: true });
      }
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        setError(error.message);
      }
      router.navigate("/kyc", { replace: true });
    }
  }),
  onSetToken: thunkOn(
    (_, actions) => actions.auth.setToken,
    (_, target, helpers) => {
      // const setAuthToken = helpers.injections.authService.setAuthToken;
      // setAuthToken(target.payload);
    }
  ),
  onSetUser: thunkOn(
    (_, actions) => actions.auth.setUser,
    (_, target, helpers) => {
      const { payload } = target;
      const actions = helpers.getStoreActions();
      const setVerified = actions.auth.setVerified;
      const isVerified = [payload?.isEmailVerified, payload?.isPhoneVerified].some(
        (item) => item === true
      );
      setVerified(isVerified);
    }
  ),
  onSetTokenExpired: thunkOn(
    (_, actions) => actions.auth.setExpiredAt,
    (actions, target, helpers) => {
      const storeACtions = helpers.getStoreActions();
      const {
        auth: { verifyTokenAsync }
      } = storeACtions;
      if (target.payload !== 0) {
        console.log("expired at", new Date(target.payload));
        const timerMiliSecs = differenceInMilliseconds(new Date(target.payload), new Date());
        console.log(`run next verify in ${timerMiliSecs / 1000} secs`);
        const verifyTokenTimer = setTimeout(() => {
          console.log("onSetTokenExpired->verifyTokenAsync");
          verifyTokenAsync({ silentAuth: true });
        }, timerMiliSecs);
        return () => {
          clearTimeout(verifyTokenTimer);
        };
      }
    }
  )
};

const auth: Auth.Model & Auth.Actions & Auth.Thunks = {
  ...fields,
  ...actions,
  ...thunks
};

export default auth;
