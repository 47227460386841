import { GradientBorder, Section, InfiniteLooper } from "../../Layout";
import Item from "./Item";

import item_1_image from "../../../assets/images/contributers-section__item-1.png";
import item_2_image from "../../../assets/images/contributers-section__item-2.png";
import item_3_image from "../../../assets/images/contributers-section__item-3.png";
import item_4_image from "../../../assets/images/contributers-section__item-4.png";
import item_5_image from "../../../assets/images/contributers-section__item-5.png";
import item_6_image from "../../../assets/images/contributers-section__item-6.png";
import item_7_image from "../../../assets/images/contributers-section__item-7.png";

const items = [
  {
    imageSrc: item_1_image
  },
  {
    imageSrc: item_2_image
  },
  {
    imageSrc: item_3_image
  },
  {
    imageSrc: item_4_image
  },
  {
    imageSrc: item_5_image
  },
  {
    imageSrc: item_6_image
  },
  {
    imageSrc: item_7_image
  },
  {
    imageSrc: item_1_image
  },
  {
    imageSrc: item_2_image
  },
  {
    imageSrc: item_3_image
  },
  {
    imageSrc: item_4_image
  },
  {
    imageSrc: item_5_image
  },
  {
    imageSrc: item_6_image
  },
  {
    imageSrc: item_7_image
  },
  {
    imageSrc: item_1_image
  },
  {
    imageSrc: item_2_image
  },
  {
    imageSrc: item_3_image
  },
  {
    imageSrc: item_4_image
  },
  {
    imageSrc: item_5_image
  },
  {
    imageSrc: item_6_image
  },
  {
    imageSrc: item_7_image
  }
];

const styles = {
  container: `
    w-full
  `,
  borderContainer: `
   before:bg-numbers-section__border
   before:rounded-0 before:bg-cover
   before:p-[var(--border-width)_0_var(--border-width)_0]
  `,
  innderGradientContainer: `
   before:bg-numbers-section__inner-gradient
   before:rounded-[32px] before:bg-cover
   before:!p-[0_30%_0_30%]
   before:z-20
   md:before:z-20
  `,
  contentContainer: `
    min-w-[95%]
    flex flex-row items-center justify-around gap-[2em]
    pl-[2em]
    py-8
    md:py-6
    md:mx-1
    lg:py-8
  `,
  backgroundContainer: `
    h-full w-full
  `,
  solidBorder: `
    h-full w-full
    flex flex-row
    bg-contributers-section bg-[size:100%_100%] bg-center bg-no-repeat
    border-sm
    md:border-md border-neutral-500
    md:rounded-[32px]
  `
};

export default function ContributersSection() {
  return (
    <Section {...{ title: "Connect with great projects" }}>
      <div className={styles.container + "flex md:hidden"}>
        <GradientBorder {...{ customStyles: styles.innderGradientContainer }}>
          <GradientBorder {...{ customStyles: styles.borderContainer }}>
            <div className="w-full py-[var(--border-width)] box">
              <InfiniteLooper {...{ direction: "left", speed: 35 }}>
                <div className={styles.backgroundContainer}>
                  <div className={styles.contentContainer}>
                    {items.map((item, index) => (
                      <Item key={`img-${index}`} {...item} />
                    ))}
                  </div>
                </div>
              </InfiniteLooper>
            </div>
          </GradientBorder>
        </GradientBorder>
      </div>
      <div className={styles.container + `hidden md:flex`}>
        <div className={styles.solidBorder}>
          <GradientBorder {...{ customStyles: styles.innderGradientContainer }}>
            <InfiniteLooper {...{ direction: "left", speed: 35 }}>
              <div className={styles.contentContainer}>
                {items.map((item, index) => (
                  <Item key={`img-${index}`} {...item} />
                ))}
              </div>
            </InfiniteLooper>
          </GradientBorder>
        </div>
      </div>
    </Section>
  );
}
