import { useStoreState } from "../../../hooks";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import { UserWallet } from "../../../types/Auth";

const WalletAccount = (props: { wallet: UserWallet; walletAccountId?: string }) => {
  const { wallet } = props;
  const { walletAccountId } = useWalletAccountId();
  const isSignedIn = useStoreState((state) => state.nearApi.isSignedIn);

  return (
    <div className="font-poppins flex items-center gap-4 text-md justify-between">
      <span>{wallet.walletId} </span>
      {isSignedIn && walletAccountId ? (
        <>
          {walletAccountId !== wallet.walletId ? (
            <span className="text-neutral-700">Not connected</span>
          ) : (
            <span className="text-primary-900">Active</span>
          )}
        </>
      ) : (
        <span className="text-neutral-700">Not connected</span>
      )}
    </div>
  );
};

export default WalletAccount;
