import { FinalExecutionOutcome } from "@near-wallet-selector/core";
import { get } from "./newAuth";
import * as qs from "qs";

const getFtBalance = async ({ accountId, tokenId }: { accountId: string; tokenId: string }) => {
  const query = qs.stringify(
    {
      accountId,
      tokenId
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );

  const response = await get(`/near/ft-balance?${query}`);
  return response.success ? response.success.data.result : null;
};

const getFtStorageBalanceBounds = async ({ tokenId }: { tokenId: string }) => {
  const query = qs.stringify(
    {
      tokenId
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );

  const response = await get(`/near/storage-balance-bounds?${query}`);
  return response.success ? response.success.data.result : null;
};

const getFtStorageBalance = async ({
  accountId,
  tokenId
}: {
  accountId: string;
  tokenId: string;
}) => {
  const query = qs.stringify(
    {
      accountId,
      tokenId
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );

  const response = await get(`/near/storage-balance?${query}`);
  return response.success ? response.success.data.result : null;
};

const getDaoPolicy = async ({ daoId }: { daoId: string }) => {
  const query = qs.stringify(
    {
      daoId
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );

  const response = await get(`/near/dao-policy?${query}`);
  return response.success ? response.success.data.result : null;
};

const getOwnerWhitelisted = async ({ accountId }: { accountId: string }) => {
  const query = qs.stringify(
    {
      accountId
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );

  const response = await get(`/near/check-if-owner-whitelisted?${query}`);
  return response.success ? response.success.data.result : null;
};

const getPostpaidSubscriberWhitelisted = async ({ accountId }: { accountId: string }) => {
  const query = qs.stringify(
    {
      accountId
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );

  const response = await get(`/near/check-if-postpaid-subscriber-whitelisted?${query}`);
  return response.success ? response.success.data.result : null;
};

const getTxStatus = async ({
  txHash,
  accountId
}: {
  txHash: string;
  accountId: string;
}): Promise<FinalExecutionOutcome> => {
  const query = qs.stringify(
    {
      txHash,
      accountId
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );

  const response = await get(`/near/tx-status?${query}`);
  return response.success ? response.success.data.result : null;
};

const getWhitelisted = async ({ accountId }: { accountId: string }) => {
  const query = qs.stringify(
    {
      accountId
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );

  const response = await get(`/kyc/check-if-whitelisted?${query}`);
  return response.success ? response.success.data.isWhitelisted : null;
};

export {
  getFtBalance,
  getFtStorageBalanceBounds,
  getFtStorageBalance,
  getDaoPolicy,
  getOwnerWhitelisted,
  getPostpaidSubscriberWhitelisted,
  getTxStatus,
  getWhitelisted
};
