import { useEffect, useState } from "react";
import { useStoreActions } from ".";
import useWalletAccountId from "./useWalletAccountId";

const usePostpaidSubscriberWhitelisted = () => {
  const { walletAccountId: accountId } = useWalletAccountId();
  const [isPostpaidSubscriberWhitelisted, setIsPostpaidSubscriberWhitelisted] = useState(false);
  const checkIsPostpaidSubscriberWhitelisted = useStoreActions(
    (actions) => actions.nearApi.checkPostpaidSubscriberWhitelisted
  );
  useEffect(() => {
    (async () => {
      if (accountId) {
        const checkSubscriber = await checkIsPostpaidSubscriberWhitelisted({ accountId });
        setIsPostpaidSubscriberWhitelisted(checkSubscriber);
      }
    })();
  }, [accountId]);

  return { isPostpaidSubscriberWhitelisted };
};

export default usePostpaidSubscriberWhitelisted;
