import classNames from "classnames";

const RocketIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("w-6 h-6 stroke-neutral-200", className)}
    >
      <path
        d="M14.59 13.37C14.7982 14.2511 14.8044 15.1678 14.608 16.0516C14.4116 16.9354 14.0177 17.7633 13.4559 18.4732C12.8941 19.1832 12.1789 19.7568 11.364 20.1511C10.549 20.5454 9.65534 20.7501 8.75 20.75V15.95M14.59 13.37C16.4999 11.9788 18.0536 10.1555 19.1242 8.04908C20.1947 5.94267 20.7519 3.61287 20.75 1.25C18.3873 1.2483 16.0577 1.80549 13.9515 2.87607C11.8452 3.94665 10.0221 5.50026 8.631 7.41M14.59 13.37C12.8519 14.6413 10.8603 15.5214 8.75 15.95M8.75 15.95C8.647 15.971 8.543 15.991 8.439 16.01C7.53179 15.2906 6.71038 14.4692 5.991 13.562C6.00989 13.4578 6.02889 13.3538 6.05 13.25M8.631 7.41C7.74986 7.2016 6.83298 7.19532 5.94907 7.39164C5.06516 7.58797 4.23714 7.98179 3.52707 8.54362C2.817 9.10544 2.2433 9.82068 1.84895 10.6357C1.4546 11.4508 1.24984 12.3446 1.25 13.25H6.05M8.631 7.41C7.35988 9.14782 6.47875 11.14 6.05 13.25M3.811 15.64C3.15318 16.1292 2.64173 16.789 2.33202 17.548C2.02232 18.307 1.92618 19.1363 2.054 19.946C2.86383 20.0737 3.69316 19.9774 4.45218 19.6675C5.21119 19.3577 5.87095 18.846 6.36 18.188M15.5 8C15.5 8.39783 15.342 8.77936 15.0607 9.06066C14.7794 9.34197 14.3978 9.5 14 9.5C13.6022 9.5 13.2206 9.34197 12.9393 9.06066C12.658 8.77936 12.5 8.39783 12.5 8C12.5 7.60218 12.658 7.22065 12.9393 6.93934C13.2206 6.65804 13.6022 6.5 14 6.5C14.3978 6.5 14.7794 6.65804 15.0607 6.93934C15.342 7.22065 15.5 7.60218 15.5 8Z"
        stroke="stroke-inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RocketIcon;
