import { useState } from "react";
import { format } from "date-fns";
import { useStoreState } from "../../../hooks";
import { Area } from "../../Layout";
import { CheckCircleIcon, ClockIcon } from "../../general/SVGIcon";
import { txViewUrl } from "../../Bounty/general/helpers";
import useUserTransactions from "../../../hooks/useUserTransactions";
import { getHunterMethodDescription } from "./helpers";
import FlagIcon from "../../general/SVGIcon/flagIcon";

const HunterTransactions = (props: { userId: string }) => {
  const { userId } = props;
  const walletSelector = useStoreState((state) => state.nearApi.walletSelector);
  const { data } = useUserTransactions({ userId });
  const [showAll, setShowAll] = useState(false);

  const dataForDisplay = showAll ? data : data.slice(0, 5);

  return dataForDisplay ? (
    <Area
      {...{
        customStyles: {
          contentContainer:
            "bg-transparent text-neutral-50 text-sm break-words overflow-auto scrollbar"
        }
      }}>
      <h2 className="text-sm text-neutral-400 mb-4">Activity</h2>
      {dataForDisplay.map((tx) => (
        <div key={tx.hash} className="mb-2.5">
          <Area
            {...{
              customStyles: {
                contentContainer: "p-4 text-sm text-neutral-100"
              }
            }}>
            <div className="flex justify-between">
              <span className="flex items-center gap-2">
                {tx.methodName === "bounty_done" && (
                  <CheckCircleIcon className="stroke-neutral-100" />
                )}
                {tx.methodName === "start_competition" && <ClockIcon />}
                {tx.methodName === "bounty_claim" && <FlagIcon />}
                <a href={txViewUrl(tx.hash, walletSelector)} target="_blank" rel="noreferrer">
                  {getHunterMethodDescription(tx)}
                </a>
              </span>
              <span>{format(new Date(tx.txDate), "do MMM, yyyy hh:mm a")}</span>
            </div>
          </Area>
        </div>
      ))}
      {data.length > 5 && (
        <div className="flex items-center justify-end py-2.5">
          <button
            className="text-sm text-neutral-400 outline-none"
            onClick={() => setShowAll(!showAll)}>
            {showAll ? "Show less" : "Show all"}
          </button>
        </div>
      )}
    </Area>
  ) : null;
};

export default HunterTransactions;
