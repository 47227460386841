import Amount from ".";
import useCurrencyDecimalPrecision from "../../../hooks/useCurrencyDecimalPrecision";
import { ClaimStatus } from "../../../types/Claim";

const CurrencyAmount = (props: {
  currency: string;
  amount: string;
  className?: string;
  claimStatus?: ClaimStatus;
}) => {
  const { currency, amount, claimStatus, className } = props;
  const { currencyDecimalPrecision } = useCurrencyDecimalPrecision({
    currency,
    amount
  });

  return (
    <Amount
      symbol={currency}
      amount={currencyDecimalPrecision ?? ""}
      claimStatus={claimStatus}
      className={className}
    />
  );
};

export default CurrencyAmount;
