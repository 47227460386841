import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useStoreState } from "../../../../hooks";
import { DarkButton, LightButton } from "../../../general/Button";
import { InvoiceFormData } from "../../../Form";
import { Input, TextArea } from "../../../general/Input";
import { Area, ValidationLayout } from "../../../Layout";
import Modal from "../../../general/Modal";
import { CompanyMetadata, IndividualMetadata } from "../../../../types/Auth";
import { FormRow } from "../../../general/Form/FormElements";
import UploadAttachments from "../general/UploadAttachments";
import { Bounty } from "../../../../types/Bounty";

type FormData = {
  attachments?: string[];
  linkToWork?: string;
  description: string;
  metadata?: CompanyMetadata | IndividualMetadata;
};

export default function DoneBountyForm(props: {
  invoice: boolean;
  show: boolean;
  bounty: Bounty;
  loading: boolean;
  handleClose: () => void;
  handleSubmit: (value: FormData) => void;
}) {
  const { show, handleClose, handleSubmit, bounty, loading, invoice = false } = props;
  const user = useStoreState((state) => state.auth.user);
  const methods = useForm<FormData>({
    mode: "onChange"
  });
  const { errors, isValid, isSubmitted } = methods.formState;
  const [activeTab, setActiveTab] = useState(0);
  const [description, setDescription] = useState("");
  const [linkToWork, setWorkToLink] = useState<string | undefined>();
  const [attachments, setAttachments] = useState<string[] | undefined>();

  const handleChangeDescription = (value: string) => {
    methods.setValue("description", value, { shouldValidate: true });
    setDescription(value);
  };

  const handleChangeLinkToWork = (value: string) => {
    const link = Boolean(value) ? value : undefined;
    methods.setValue("linkToWork", link);
    setWorkToLink(link);
  };

  const handleSetAttachments = (value: string[] | undefined) => {
    methods.setValue("attachments", value?.length ? value : undefined);
    setAttachments(value);
  };

  const nextTab: SubmitHandler<FormData> = () => {
    if (isValid) {
      setActiveTab(activeTab + 1);
    }
  };

  const prevTab = () => {
    setActiveTab(activeTab - 1);
  };

  useEffect(() => {
    if (invoice && !user?.metadata?.profileType) {
      methods.setError("metadata", { message: "Required" });
    }
    return () => methods.clearErrors("metadata");
  }, [user, invoice]);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (isValid) {
      handleSubmit(data);
    }
  };

  return (
    <Modal
      {...{
        customStyles: { modal: "overflow-auto", content: "max-w-[416px] h-fit w-full" },
        open: show,
        onClose: handleClose
      }}>
      <div className="font-poppins">
        {activeTab === 0 && (
          <>
            <div className="text-neutral-50 py-2.5">Submit your work to the funder for review!</div>
            <p className="text-sm text-neutral-400">
              You are submitting your work to the funder. Once your work got approved by the funder,
              the bounty rewards will be released!
            </p>
            <FormRow>
              <input type="hidden" {...methods.register("linkToWork")} />
              <Input
                {...{
                  label: { text: "Link to your work (Optional)" },
                  onChange: (value) => handleChangeLinkToWork(value)
                }}
              />
            </FormRow>
            <FormRow>
              <input type="hidden" {...methods.register("description", { required: "Required" })} />
              <ValidationLayout
                {...{
                  validate: () => {
                    return {
                      valid: !errors.description,
                      started: isSubmitted,
                      message: errors.description?.message
                    };
                  }
                }}>
                <TextArea
                  {...{
                    label: { text: "Description" },
                    value: description,
                    rows: 5,
                    onChange: (value: string) => handleChangeDescription(value)
                  }}
                />
              </ValidationLayout>
            </FormRow>
            <FormRow>
              <UploadAttachments onSetAttachments={handleSetAttachments} bountyId={bounty.id} />
            </FormRow>
            <div className="py-2.5 flex justify-end">
              {!invoice ? (
                <DarkButton
                  {...{
                    customStyles: { button: "max-w-[146px]" },
                    onClick: () => methods.handleSubmit(onSubmit)(),
                    disabled: loading,
                    text: !loading ? "Submit work" : "Submitting...",
                    type: "submit"
                  }}
                />
              ) : (
                <DarkButton
                  {...{
                    customStyles: { button: "max-w-[146px]" },
                    onClick: () => methods.handleSubmit(nextTab)(),
                    text: "Next",
                    type: "submit"
                  }}
                />
              )}
            </div>
          </>
        )}
        {activeTab === 1 && (
          <>
            <h2 className="text-neutral-50 py-2.5">Add invoice details</h2>
            <p className="text-sm text-neutral-400">
              An automatically generated invoice will be sent once your work got approved and funds
              are sent to you
            </p>
            <FormRow className="my-2.5">
              <FormRow>
                <Area
                  {...{
                    customStyles: {
                      borderContainer: "border border-sm border-neutral-700"
                    }
                  }}>
                  <div className="px-4 py-2.5">
                    <FormProvider {...methods}>
                      <InvoiceFormData metadata={user?.metadata} useVat={true} />
                    </FormProvider>
                  </div>
                </Area>
              </FormRow>
              <div className="py-2.5 flex justify-between">
                <DarkButton
                  {...{
                    customStyles: { button: "max-w-[146px]" },
                    onClick: () => prevTab(),
                    text: "Back"
                  }}
                />
                <LightButton
                  {...{
                    disabled: loading,
                    customStyles: { button: "max-w-[146px]" },
                    onClick: () => methods.handleSubmit(onSubmit)(),
                    text: !loading ? "Submit work" : "Submitting...",
                    type: "submit"
                  }}
                />
              </div>
            </FormRow>
          </>
        )}
      </div>
    </Modal>
  );
}
