import { useStoreRehydrated } from "easy-peasy";
import { ReactElement, useEffect, useState } from "react";
import { BrowserHistory } from "history";
import { useStoreActions } from "../hooks";
import { BrowserRouter } from "..";

interface InitializeProps {
  history: BrowserHistory;
  router: BrowserRouter;
  children: ReactElement;
}

function InitializeProvider({ history, router, children }: InitializeProps) {
  const [loading, setLoading] = useState(false);
  const { onInit } = useStoreActions((actions) => actions.app);

  const isRehydrated = useStoreRehydrated();

  useEffect(() => {
    if (isRehydrated && !loading) onInit({ history, setLoading, router });
  }, [isRehydrated, loading]);

  return loading && isRehydrated ? children : null;
}

export default InitializeProvider;
