import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type CustomItemStyles = {
  container?: string;
  text?: string;
};

interface CustomItemProps {
  name?: string;
  value?: string;
  id: number;
  onClick?: (value: string) => void;
  onMouseOver?: (value: string) => void;
  onKeyDown?: (value: string) => void;
  customStyles?: CustomItemStyles;
  highlighted?: boolean;
}

export const buildStyles = (
  customStyles?: CustomItemStyles | undefined,
  highlighted: boolean = false
) => ({
  container: twMerge(
    classNames(
      `
   px-[20px] py-[15px]
   hover:cursor-pointer
   text-neutral-400 font-poppins
   ${customStyles?.container ? customStyles.container : ""}
  `,
      {
        "bg-neutral-820 text-primary-900": highlighted,
        "bg-neutral-800 text-neutral-400": !highlighted
      }
    )
  )
});

export default function CustomItem(props: CustomItemProps) {
  const { name, value, id, onClick, onMouseOver, onKeyDown, customStyles, highlighted } = props;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    event.preventDefault();
    if (!handleKeyDown) {
      return;
    }
    onKeyDown && onKeyDown(event.key);
  };

  const handleClick = (event: React.MouseEvent) => {
    const name = (event.target as HTMLLIElement).getAttribute("data-name");

    if (onClick && name) {
      onClick(name);
    }
  };

  const handleMouseOver = (event: React.MouseEvent) => {
    const name = (event.target as HTMLLIElement).getAttribute("data-name");

    if (onMouseOver && name) {
      onMouseOver(name);
    }
  };

  const styles = buildStyles(customStyles, highlighted);

  return (
    <li
      {...{
        className: styles.container,
        key: id,
        onClick: handleClick,
        onMouseOver: handleMouseOver,
        onKeyDown: handleKeyDown,
        "data-name": name
      }}>
      {value}
    </li>
  );
}
