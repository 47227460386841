const BigRunButton = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="35" cy="35" r="34" stroke="#F8F8F8" strokeWidth="2" />
      <path
        d="M48.3309 34.4182C48.5336 34.5252 48.7026 34.6819 48.8203 34.8721C48.938 35.0622 49 35.2789 49 35.4996C49 35.7204 48.938 35.937 48.8203 36.1272C48.7026 36.3174 48.5336 36.4741 48.3309 36.5811L28.9288 46.8446C28.7311 46.9491 28.5081 47.0026 28.282 46.9999C28.0559 46.9972 27.8345 46.9383 27.6396 46.829C27.4448 46.7198 27.2833 46.564 27.1711 46.377C27.0589 46.1901 26.9999 45.9785 27 45.7632V25.2361C27 24.2931 28.0631 23.6997 28.9288 24.158L48.3309 34.4182Z"
        stroke="#F8F8F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BigRunButton;
