import type { ReactNode } from "react";
import React, { useContext, useMemo } from "react";
import { useWalletSelector } from "./WalletSelectorContext";
import { useStoreState } from "../hooks";
import useBountyOwnerWhitelisted from "../hooks/useBountyOwnerWhitelisted";
import usePostpaidSubscriberWhitelisted from "../hooks/usePostpaidSubscriberWhitelisted";

interface AllowCreateBountyContextValue {
  isAllowedCreateBounty: boolean;
}

const AllowCreateBountyContext = React.createContext<AllowCreateBountyContextValue | null>(null);

export const AllowCreateBountyContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { selector } = useWalletSelector();
  const use_owners_whitelist = useStoreState((state) => state.app.config?.use_owners_whitelist);
  const { isOwnerWhitelisted } = useBountyOwnerWhitelisted();
  const { isPostpaidSubscriberWhitelisted } = usePostpaidSubscriberWhitelisted();

  const isAllowedCreateBounty = () => {
    if (selector?.isSignedIn()) {
      if (!use_owners_whitelist) {
        return true;
      } else {
        if (isOwnerWhitelisted || isPostpaidSubscriberWhitelisted) {
          return true;
        } else {
          return false;
        }
      }
    } else if (!use_owners_whitelist) {
      return true;
    }
    return false;
  };

  const allowCreateBountyContextValue = useMemo<AllowCreateBountyContextValue>(
    () => ({
      isAllowedCreateBounty: isAllowedCreateBounty()
    }),
    [use_owners_whitelist, isOwnerWhitelisted, isPostpaidSubscriberWhitelisted]
  );

  return (
    <AllowCreateBountyContext.Provider value={allowCreateBountyContextValue}>
      {children}
    </AllowCreateBountyContext.Provider>
  );
};

export function useAllowCreateBounty() {
  const context = useContext(AllowCreateBountyContext);

  if (!context) {
    throw new Error("useAllowCreateBounty must be used within a AllowCreateBountyContextProvider");
  }

  return context;
}
