import classNames from "classnames";
import { twMerge } from "tailwind-merge";

interface TProps {
  color?: string;
  position?: keyof PathD;
  className?: string;
}

type PathD = {
  [key: string]: string;
  up: string;
  down: string;
};

const defaultProps = {
  color: "hsl(var(--color-neutral-700))",
  position: "down"
};

export default function SelectArrow(props: TProps) {
  const { color, position, className } = { ...defaultProps, ...props };

  const pathD = {
    up: "M0.999512 8.00049L7.49951 1.00049L13.9995 8.00049",
    down: "M14 1L7.5 8L1 1"
  } as PathD;

  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(classNames(className))}>
      <path
        d={pathD[position]}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
