import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { ChevronLeftIcon } from "../../general/SVGIcon";
import Chat from "./Chat";
import React from "react";

const ChatForm = (props: { onClose: () => void; claim: Claim; bounty: Bounty, chatRef?: React.RefObject<any> }) => {
  const { onClose, claim, bounty, chatRef } = props;

  return (
    <div ref={chatRef} className="font-poppins flex flex-col h-full">
      <div className="px-4 bg-neutral-820">
        <div className="flex items-center justify-between py-4">
          <div>
            <button className="hover:text-primary-900" onClick={onClose}>
              <ChevronLeftIcon className="rotate-180 stroke-neutral-600 hover:stroke-neutral-200" />
            </button>
          </div>
          <div className="text-md">Chat</div>
          <div className="h-6 w-6" />
        </div>
      </div>
      <div className="flex w-full h-full max-h-[calc(100vh-64px)]">
        <Chat bounty={bounty} bountyClaim={claim} />
      </div>
    </div>
  );
};

export default ChatForm;
