import { useContext } from "react";
import mixpanel from "mixpanel-browser";
import { MixpanelContext } from "../App";
import HuntersList from "../../components/Hunters/HuntersList";
import SearchParamsFilterHunters from "../../components/Hunters/FilterHunters";
import ScrollToTopButton from "../../components/general/Button/ScrollToTopButton";
import useInfiniteHuntersSearchParams from "../../hooks/useInfiniteHuntersSearchParams";

const BrowseHunters = () => {
  const context = useContext(MixpanelContext);
  mixpanel.init(context.token, {
    debug: false
  });

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isInitialLoading,
    status,
    searchParams,
    setQuery
  } = useInfiniteHuntersSearchParams({ limit: 16 });

  if (status === "error") return <h4>Ups!, {`${error}`}</h4>;

  return (
    <div className="font-poppins">
      <div className="flex justify-center my-8">
        <h1 className="text-4xl font-bold">Hunter overview</h1>
      </div>
      <div className="mb-2">
        <SearchParamsFilterHunters query={searchParams} setQuery={setQuery} />
      </div>
      {isInitialLoading ? (
        <div className="flex items-center justify-center">
          <span className="dot-pulse" />
        </div>
      ) : (
        <>
          <HuntersList data={data} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} />
        </>
      )}
      <ScrollToTopButton />
    </div>
  );
};

export default BrowseHunters;
