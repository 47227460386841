import { Layout } from "../../components/Layout";
import Chapter from "../../components/general/Text/Chapter";
import CircleListItem from "../../components/general/Text/CircleListItem";
import List from "../../components/general/Text/List";
import NumberedListItem from "../../components/general/Text/NumberListItem";
import Paragraph from "../../components/general/Text/Paragraph";

export const TermsOfUse = () => {
  return (
    <Layout>
      <div className="max-w-3xl mx-auto w-full font-poppins text-neutral-100">
        <h1 className="text-center text-3xl font-bold py-12">Terms of Use</h1>
        <Paragraph>
          <div className="text-sm font-bold mb-4">HEROES Terms of Use</div>
          <div className="text-sm font-normal mb-4">Last updated: April 27, 2023</div>
        </Paragraph>
        <Paragraph>
          IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS
          ACTION RIGHTS AS DETAILED IN SECTION 25. PLEASE READ THE AGREEMENT CAREFULLY.
        </Paragraph>
        <Chapter number="1" title="ACCEPTANCE OF TERMS">
          <Paragraph>
            HEROES, is provided by a Swiss based limited entity (“HEROES”) and is a blockchain-based
            incentivization toolkit for Open Source Software allowing Users to interact with HEROES.
            “Tasks” means any service a Site participant requests or performs. Site participants may
            consist of (a) those who post Tasks to HEROES, sponsor virtual hackathon events in which
            multiple Hunters may participate (“Hackathons”) or manage a service dao (“Tribes”) (such
            participant, individually a “Poster,” collectively the “Posters”); (b) those who fulfill
            Tasks (individually a “Hunter” or collectively the “Hunters”); (c) those who interact
            with the HEROES site but do not post or fulfill Tasks (a “Visitor”). The HEROES website
            (https://heroes.build/, the “Site”) serves as a registration portal, product offering,
            and an overview of the HEROES services. The site also includes text, images, audio, code
            and other materials or third party information. The Site allows Posters to fund and post
            Tasks for Hunters to resolve, allows Posters to sponsor Hackathons, allows Hunters to
            resolve Tasks and participate in Hackathons, and allows Buyers and Sellers to buy and
            sell each others services (collectively, the “Service Offerings”). The Site and any
            other features, tools, materials, or services offered from time to time by HEROES,
            including without limitation the Service Offerings, is referred to here as the
            “Service.” Please read these Terms of Use (the “Terms” or “Terms of Use”) carefully
            before using the Service. By using or otherwise accessing the Services, or clicking to
            accept or agree to these Terms where that option is made available, you (x) accept and
            agree to these Terms, (y) consent to the collection, use, disclosure and other handling
            of information as described in our Privacy Policy and (z) any additional terms, rules
            and conditions of participation issued by HEROES from time to time. IF YOU DO NOT AGREE
            TO THE TERMS, THEN YOU MAY NOT ACCESS OR USE THE SERVICES.
          </Paragraph>
        </Chapter>
        <Chapter number="2" title="MODIFICATIONS OF TERMS OF USE">
          <Paragraph>
            Except for Section 25, providing for binding arbitration and waiver of class action
            rights, HEROES reserves the right, at its sole discretion, to modify or replace the
            Terms of Use at any time. The most current version of these Terms will be posted on our
            Site. You shall be responsible for reviewing and becoming familiar with any such
            modifications. Use of the Services by you after any modification to the Terms
            constitutes your acceptance of the Terms of Use as modified.
          </Paragraph>
        </Chapter>
        <Chapter number="3" title="ELIGIBILITY">
          <Paragraph>
            You represent and warrant that you: (a) are of legal age to form a binding contract; (b)
            have not previously been suspended or removed from using our Services; and (c) have full
            power and authority to enter into this agreement and in doing so will not violate any
            other agreement to which you are a party. If you are registering to use the Services on
            behalf of a legal entity, you further represent and warrant that (x) such legal entity
            is duly organized and validly existing under the applicable laws of the jurisdiction of
            its organization, and (y) you are duly authorized by such legal entity to act on its
            behalf.
          </Paragraph>
        </Chapter>
        <Chapter number="4" title="ROLE OF HEROES">
          <Paragraph>
            You expressly acknowledge, agree, and understand that: the Site is merely a platform
            through which Posters, Hunters, Buyers and Sellers conduct transactions. Unless
            participating on the Site as a Poster or a Seller, HEROES is not involved in the
            transactions, and has no control over the quality, safety, or legality of Tasks or
            consideration for Tasks, the ability of Hunters to perform Tasks to Posters’
            satisfaction, or the ability of Posters to pay for Tasks. You are not an employee of
            HEROES, and HEROES does not, in any way, screen, supervise, direct, or control Hunters
            or services performed for or delivered to Posters (“Hunter Services”). HEROES will not
            have any liability or obligations under or related to Service Contracts (defined below)
            for any acts or omissions by you or other Users. HEROES has no control over Hunters or
            the Hunter Services offered or rendered by Hunters and HEROES makes no representations
            as to the reliability, capability, or qualifications of any Hunter or the quality,
            security, or legality of any Hunter Services, and HEROES disclaims any and all liability
            relating thereto. HEROES has no control over Buyers and Sellers and HEROES makes no
            representations as to the quality or legality of any UGC created or sold by Sellers, and
            HEROES disclaims any and all liability relating thereto. Use of the Site is at your own
            risk. Because HEROES is not a party to the transactions between Posters and Hunters or
            between Buyers and Sellers, HEROES is not responsible for (i) resolving any disputes
            between participants related to any Tasks or (ii) any tax liabilities that may arise in
            a transaction between participants.
          </Paragraph>
        </Chapter>
        <Chapter number="5" title="HEROES POSTERS">
          <Paragraph>
            As a Poster, you agree that: (a) you will interact with Hunters in a professional and
            courteous manner, and accurately describe your Task; (b) you may only use the Site for
            business, commercial, or research purposes; (c) you may not have Hunters perform Tasks
            through venues other than the Site; (d) payment will be issued and remitted to Hunters
            upon your approval of Task; (e) payment is not refundable; (f) you will not reject Tasks
            performed by Hunters without good cause; and (g) you will not post a Task that violates
            any part of Section 14 of the Terms.
          </Paragraph>
        </Chapter>
        <Chapter number="6" title="HEROES HUNTERS">
          <Paragraph>
            As a Hunter, you agree that: (a) you will interact with Posters in a professional and
            courteous manner, and provide reasonably requested information in connection with your
            performance of Tasks; (b) you will perform Tasks in a competent and workmanlike manner;
            (c) you will supply complete, accurate, non-malicious information for all Tasks you
            perform; (d) you will not perform Tasks through venues other than the Site; (e) you will
            not submit anything to Poster that violates Section 14 of the Terms; and (f) Posters may
            reject Tasks you perform for good cause and currently there is no way for a Hunter to
            dispute any rejection of a submission made through the Site, meaning you may not be paid
            if a Poster rejects your submission.
          </Paragraph>
        </Chapter>
        <Chapter number="7" title="HEROES FEES AND PAYMENT">
          <Paragraph>
            Unless otherwise agreed between you and HEROES in a separate order form, which is
            obtainable by contacting us directly, all Tasks on the Site are subject to a ten (10)
            percent listing fee, calculated as 10% of the total Task value (the “Listing Fee”)
            payable to HEROES. The Listing Fee shall be paid by the Poster, and shall be due upon a
            Poster submitting a Task to the Site. The Listing Fee is refundable to the Poster solely
            upon the satisfaction of either of the following limited circumstances:
            <List>
              <NumberedListItem number="1">
                The Task has not been filled by a Hunter prior to HEROES’s receipt of a written
                request for a refund from a Poster. The Poster must submit a refund request to
                HEROES within 90 days from the date of posting such Task in order to be eligible for
                a refund.
              </NumberedListItem>
              <NumberedListItem number="2">
                The Poster is dissatisfied by work submitted by a Hunter and submits a request for
                review within 30 days of completion of the Task by a Hunter. In these instances, the
                Poster shall submit a written request for review to HEROES, who will in its sole
                discretion determine whether or not the Poster is eligible for a refund of the
                Transaction Fee.
              </NumberedListItem>
            </List>
          </Paragraph>
        </Chapter>
        <Chapter number="8" title="WORK PRODUCT AND WORK FOR HIRE">
          <Paragraph>
            Hunter will perform the Hunter Services in a professional and workmanlike manner and
            will timely deliver any agreed upon tangible or intangible results or deliverables,
            including, but not limited to, configurations, computer programs, software,
            applications, or other information, and any intellectual property developed in
            connection therewith (“Work Product”). Where applicable, the Work Product must be
            submitted to Poster in open source format.
          </Paragraph>
          <Paragraph>
            Any Work Product from Tasks performed as a Hunter is a “work made for hire” for the
            benefit of the Poster. Upon Hunter`s receipt of full payment from Poster, the Work
            Product, including without limitation all patent rights, copyright rights, mask work
            rights, moral rights, rights of publicity, trademark, trade dress and service mark
            rights, goodwill, trade secret rights and other intellectual property rights as may now
            exist or hereafter come into existence, and all applications therefore and
            registrations, renewals and extensions thereof, under the laws of any state, country,
            territory or other jurisdiction (“Intellectual Property Rights”) in the Work Product,
            will be the sole and exclusive property of Poster, and Poster will be deemed to be the
            author thereof. If Hunter has any Intellectual Property Rights to the Work Product that
            are not owned by Poster upon Hunter’s receipt of payment from Client, Hunter hereby
            automatically irrevocably assigns to Client all right, title and interest worldwide in
            and to such Intellectual Property Rights. Except as set forth above, Hunter retains no
            rights to use, and will not challenge the validity of Poster’s ownership in, such
            Intellectual Property Rights. Hunter hereby waives any moral rights, rights of
            paternity, integrity, disclosure and withdrawal or inalienable rights under applicable
            law in and to the Work Product.
          </Paragraph>
          <Paragraph>
            Hunter agrees that it will not incorporate into Work Product or otherwise supply Poster
            any deliverable for which the use or distribution of the code will create (or purport to
            create) obligations for Poster to grant any rights or immunities under Poster’s
            intellectual property to a third-party, including without limitation any obligation that
            the Work Product or Poster software combined with, derived from, or distributed with
            such Work Product (a) be disclosed or distributed in source code form, (b) be licensed
            for the purpose of making derivative works, or (c) be redistributable at no charge.
          </Paragraph>
        </Chapter>
        <Chapter number="9" title="INDEPENDENT CONTRACTORS">
          <Paragraph>
            Hunters perform Tasks for Posters in their personal capacity as an independent
            contractor and not as an employee of a Poster or HEROES. As a Hunter, you agree that:
            (a) you are responsible for and will comply with all applicable laws and registration
            requirements, including those applicable to independent contractors and maximum working
            hours regulations; (b) these Terms do not create an association, joint venture,
            partnership, franchise, or employer/employee relationship between you and Poster, or you
            and HEROES; (iii) you will not represent yourself as an employee or agent of a Poster or
            HEROES; (iv) you will not be entitled to any of the benefits that a Poster or HEROES may
            make available to its employees, such as vacation pay, sick leave, and insurance
            programs, including group health insurance or retirement benefits; and (v) you are not
            eligible to recover worker`s compensation benefits in the event of injury. As an
            independent contractor, Hunter is free at all times to provide Hunter Services to
            persons or businesses other than Poster, including any competitor of Poster. Hunter does
            not have authority to enter into written or oral (whether implied or express) contracts
            on behalf of Poster or HEROES. As a Poster, you will not engage a Hunter in any way that
            may jeopardise that Hunter`s status as an independent contractor performing Tasks for
            you. Posters may not require an exclusive relationship.
          </Paragraph>
        </Chapter>
        <Chapter number="10" title="HEROES CONTRACTS">
          <Paragraph>
            As used herein, a “Service Contract” means, as applicable, the unilateral contractual
            provisions created by Poster that govern the Hunter Services to be performed by Hunter
            for Poster for a particular Task posted to the Site and which will typically include a
            description of the Task or Tasks to be completed, the amount and type of currency as
            compensation, and the deadline. You acknowledge and agree that HEROES is not a party to
            any Service Contracts, and that the formation of a Service Contract between any Posters
            and Hunters will not, under any circumstance, create an employment or other service
            relationship between HEROES and Poster or HEROES and Hunter.
          </Paragraph>
        </Chapter>
        <Chapter number="11" title="PAYMENT">
          <Paragraph>
            In order to use Site Services, each User must provide its NEAR wallet address. The Site
            and the Site Services operate with NEAR fungible compatible tokens. Payment to Hunters
            will occur upon Poster’s acceptance of Hunter’s Work Product. Acceptance of a Hunter’s
            Work Product is at the sole discretion of the Poster.
          </Paragraph>
        </Chapter>
        <Chapter number="12" title="RESTRICTIONS ON USE">
          <Paragraph>
            Unless otherwise expressly authorised in these Terms of Use or on the Site, you may not
            take any action to interfere with the Site or any other user’s use of the Site or
            decompile, reverse engineer or disassemble any content or other products or processes
            accessible through the Site, nor insert any code or product or manipulate the content in
            any way that affects any User’s experience. While using the Site you are required to
            comply with all applicable statutes, orders, regulations, rules, and other laws. In
            addition, we expect users of the Website to respect the rights and dignity of others.
            Your use of the Site is conditioned on your compliance with the rules of conduct set
            forth in this Section.
          </Paragraph>
          <Paragraph>
            You may not use, or cause or encourage others to use, HEROES for any illegal, harmful,
            fraudulent, infringing, or objectionable activities. Here is a non-exhaustive list of
            prohibited activities:
          </Paragraph>
          <List>
            <CircleListItem>
              Collecting personally identifiable information (e.g., do not ask Users for their email
              address or phone number)
            </CircleListItem>
            <CircleListItem>
              Using HEROES to try to generate “referred” site visits or click-through traffic
            </CircleListItem>
            <CircleListItem>
              Disrupting, manipulating, or degrading the operation of any website, product, or
              service
            </CircleListItem>
            <CircleListItem>Phishing, spamming, or pharming</CircleListItem>
            <CircleListItem>
              Unsolicited contacting of Users or other abusive behaviour
            </CircleListItem>
            <CircleListItem>
              Advertising or marketing activities, including Tasks requiring registration at another
              website or group
            </CircleListItem>
            <CircleListItem>
              Tasks intended to promote a site, service or opinion (e.g., don`t ask Hunters to write
              fake news or reviews)
            </CircleListItem>
            <CircleListItem>
              Infringing or misappropriating the rights of others, including Tasks that require
              violating the terms of any website, product, or service
            </CircleListItem>
            <CircleListItem>
              Posting or transmitting any content that is illegal, fraudulent or otherwise
              objectionable, including content that constitutes child pornography, relates to
              bestiality, or depicts non-consensual sex acts
            </CircleListItem>
            <CircleListItem>
              Disrupting, manipulating, or impairing the operation of the HEROES Site (e.g., do not
              try to “game” search results for Tasks)
            </CircleListItem>
            <CircleListItem>Scraping data or content from the HEROES Site</CircleListItem>
            <CircleListItem>
              Creating a security risk for HEROES or any HEROES User, including posting Tasks that
              require downloading software that contains harmful content or malicious code
            </CircleListItem>
            <CircleListItem>
              Performing or requesting Tasks through venues other than the HEROES Site
            </CircleListItem>
          </List>
        </Chapter>
        <Chapter number="13" title="WARRANTY DISCLAIMER">
          <Paragraph>
            You expressly understand and agree that your use of the Service is at your sole risk.
            The Service is provided on an “AS IS” and “as available” basis, without warranties of
            any kind, either express or implied, including, without limitation, implied warranties
            of merchantability, fitness for a particular purpose or non-infringement. You release
            HEROES from all liability for content you acquired or failed to acquire through the
            Service.
          </Paragraph>
        </Chapter>
        <Chapter number="14" title="SOPHISTICATION AND RISK OF CRYPTOGRAPHIC SYSTEMS">
          <Paragraph>
            By utilising the Service or platform in any way, you represent that you understand the
            inherent risks associated with cryptographic systems; and warrant that you have an
            understanding of the usage and intricacies of public/private key cryptography, native
            cryptographic tokens, like NEAR Protocol (NEAR) and smart contract based tokens such as
            those that follow the NEAR Token Standard
            https://docs.near.org/develop/relevant-contracts/ft.
          </Paragraph>
        </Chapter>
        <Chapter number="15" title="PLATFORM SECURITY">
          <Paragraph>
            HEROES is an early stage smart-contract platform. You acknowledge that NEAR applications
            are code subject to flaws and acknowledge that you are solely responsible for evaluating
            any available code provided by the Services. You further expressly acknowledge and
            represent that applications can be written maliciously or negligently, that HEROES
            cannot be held liable for your interaction with such applications. These warnings and
            others later provided by HEROES in no way evidence or represent an on-going duty to
            alert you to all of the potential risks of utilising the Service.
          </Paragraph>
        </Chapter>
        <Chapter number="16" title="RISK OF ERRORS, BUGS, AND DOWNTIME">
          <Paragraph>
            You acknowledge and accept that the Services (i) may contain bugs, errors and defects,
            (ii) may function improperly or be subject to periods of downtime and unavailability,
            (iii) may result in total or partial loss or corruption of data used in the Services,
            and (iv) may be modified at any time, including through the release of subsequent
            versions, all with or without notice.
          </Paragraph>
        </Chapter>
        <Chapter number="17" title="INDEMNITY">
          <Paragraph>
            You agree, to the fullest extent permitted by applicable law, to release and to
            indemnify, defend and hold harmless HEROES and its parents, subsidiaries, affiliates and
            agencies, as well as the officers, directors, employees, shareholders and
            representatives of any of the foregoing entities, from and against any and all losses,
            liabilities, expenses, damages, costs (including attorneys’ fees and court costs) claims
            or actions of any kind whatsoever arising or resulting from: (a) your use of the
            Service, (b) your violation of these Terms of Use, (c) any of your acts or omissions
            that implicate publicity rights, defamation, invasion of privacy, confidentiality,
            intellectual property rights or other property rights, (d) your UGC, (e) any
            misrepresentation by you and (f) any disputes or issues between you and any third party.
            HEROES reserves the right, at its own expense, to assume exclusive defence and control
            of any matter otherwise subject to indemnification by you and, in such case, you agree
            to cooperate with HEROES in the defence of such matter.
          </Paragraph>
        </Chapter>
        <Chapter number="18" title="LIMITATION ON LIABILITY">
          <Paragraph>
            YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THE SITE
            AND SERVICE. YOU ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU SEND OR RECEIVE DURING
            YOUR USE OF THE SITE AND SERVICE MAY NOT BE SECURE AND MAY BE INTERCEPTED OR LATER
            ACQUIRED BY UNAUTHORISED PARTIES. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SITE
            AND SERVICE IS AT YOUR OWN RISK. RECOGNIZING SUCH, YOU UNDERSTAND AND AGREE THAT, TO THE
            FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER HEROES NOR ITS PARENTS,
            SUBSIDIARIES, AFFILIATES AND AGENCIES, AS WELL AS THE OFFICERS, DIRECTORS, EMPLOYEES,
            SHAREHOLDERS OR REPRESENTATIVES OF ANY OF THE FOREGOING ENTITIES, OR ITS SUPPLIERS OR
            LICENSORS WILL BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR OTHER DAMAGES OF ANY KIND, INCLUDING WITHOUT
            LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER TANGIBLE OR
            INTANGIBLE LOSSES OR ANY OTHER DAMAGES BASED ON CONTRACT, TORT (INCLUDING, IN
            JURISDICTIONS WHERE PERMITTED, NEGLIGENCE AND GROSS NEGLIGENCE), STRICT LIABILITY OR ANY
            OTHER THEORY (EVEN IF HEROES HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
            RESULTING FROM THE SITE OR SERVICE; THE USE OR THE INABILITY TO USE THE SITE OR SERVICE;
            UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; STATEMENTS OR
            CONDUCT OF ANY THIRD PARTY ON THE SITE OR SERVICE; ANY ACTIONS WE TAKE OR FAIL TO TAKE
            AS A RESULT OF COMMUNICATIONS YOU SEND TO US; HUMAN ERRORS; TECHNICAL MALFUNCTIONS;
            FAILURES, INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES; OMISSIONS, INTERRUPTIONS,
            LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR SOFTWARE
            (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO NOT PERMIT PARTICIPATION IN THE SERVICE);
            ANY INJURY OR DAMAGE TO COMPUTER EQUIPMENT; INABILITY TO FULLY ACCESS THE SITE OR
            SERVICE OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR UNAUTHORIZED ACCESS TO,
            IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED LATE OR INCORRECTLY OR IS
            INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR ANY COMBINATION THEREOF;
            OR ANY OTHER MATTER RELATING TO THE SITE OR SERVICE.
          </Paragraph>
          <Paragraph>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
            EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
            ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          </Paragraph>
        </Chapter>
        <Chapter number="19" title="LICENCES">
          <Chapter number="19.1" title="Site Licence" className="text-md">
            <Paragraph>
              Subject to and conditioned on your compliance with these Terms of Use, HEROES grants
              you a limited, non-exclusive, non-transferable, non-sublicensable licence to access
              and make personal and non-commercial use of the Service. This licence does not include
              any resale or commercial use of the Service or any derivative use of the Service. All
              rights not expressly granted to you in these Terms of Use are reserved and retained by
              HEROES or its licensors. The HEROES Service may not be reproduced, duplicated, copied,
              sold, resold, visited, or otherwise exploited for any commercial purpose without
              express written consent of HEROES. You may not frame or utilise framing techniques to
              enclose any trademark, logo, or other proprietary information (including images, text,
              page layout, or form) of HEROES without express written consent. You may not misuse
              the Services. You may use the Services only as permitted by law. The licences granted
              by HEROES terminate if you do not comply with these Terms of Use. HEROES retains all
              right, title, and interest in and to all Intellectual Property Rights in and to the
              Site and the Site Services.
            </Paragraph>
          </Chapter>
          <Chapter number="19.2" title=" User Content Licence" className="text-md">
            <Paragraph>
              When you post or submit any data, feedback, content, text, photographs, images, or
              other information to any part of the Site or provide to HEROES (referred to as “User
              Content”), you represent and warrant that you have the right, power, and authority to
              post that User Content and grant the licences specified below. You further represent
              and warrant that by posting or providing such User Content you will not violate
              third-party rights of any kind, including, without limitation, any Intellectual
              Property Rights, rights of publicity, or privacy rights. To the extent your User
              Content may be copyrightable, you represent, warrant, and covenant that you are the
              owner of all the copyright rights to such User Content and that HEROES may exercise
              the rights to your User Content granted under the Terms of Use without any liability
              or obligation for any payment.
            </Paragraph>
            <Paragraph>
              You retain all ownership rights in any User Content you post on HEROES. To the extent
              permitted by applicable law, you also grant to HEROES and our successors and
              affiliates a royalty-free, sub-licensable, transferable, perpetual, irrevocable,
              non-exclusive, worldwide licence to use, reproduce, modify, publish, list information
              regarding, edit, translate, distribute, publicly perform, publicly display, and make
              derivative works of all such User Content and your name, voice, and/or likeness as
              contained in your User Content, in whole or in part, and in any form, media, or
              technology, whether now known or hereafter developed, for use in connection with the
              Site and HEROES (and our successors’ and affiliates’) business, including, without
              limitation, for promoting and redistributing part or all of the Site (and derivative
              works thereof) in any media formats and through any media channels. You also hereby
              grant each User a non-exclusive licence to access your User Content through the Site
              and to use, reproduce, distribute, display, and perform such User Content to the
              extent permitted through the normal functionality of the Site and subject to all
              applicable confidentiality and other provisions of this Agreement, our Privacy Policy,
              and applicable law.
            </Paragraph>
            <Paragraph>
              The licences to User Content granted by you in this Agreement will terminate within a
              commercially reasonable time after you remove or delete your User Content from the
              Site, except that you grant HEROES and our successors and affiliates the irrevocable
              and perpetual licence to retain and use, but not publicly display or distribute,
              server or archival copies of all User Content that you have removed or deleted to the
              extent permitted by applicable law.
            </Paragraph>
          </Chapter>
        </Chapter>
        <Chapter number="20" title="TERMINATION AND SUSPENSION">
          <Paragraph>
            HEROES may terminate or suspend all or part of the Service without prior notice or
            liability if you breach any of the terms or conditions of the Terms. We may, in our sole
            discretion and without liability to you, with or without prior notice and at any time,
            modify or discontinue, temporarily or permanently, any portion of our Services.
          </Paragraph>
          <Paragraph>
            The following provisions of the Terms survive any termination of these Terms: INDEMNITY;
            WARRANTY DISCLAIMERS; LIMITATION ON LIABILITY; OUR PROPRIETARY RIGHTS; TERMINATION AND
            SUSPENSION; NO THIRD PARTY BENEFICIARIES; BINDING ARBITRATION AND CLASS ACTION WAIVER;
            GENERAL INFORMATION.
          </Paragraph>
        </Chapter>
        <Chapter number="21" title="NO THIRD PARTY BENEFICIARIES">
          <Paragraph>
            You agree that, except as otherwise expressly provided in these Terms, there shall be no
            third party beneficiaries to the Terms. The Terms of Use will not be construed as
            creating or implying any relationship of agency, franchise, partnership, or joint
            venture between Users and HEROES, except and solely to the extent expressly stated in
            this Agreement.
          </Paragraph>
        </Chapter>
        <Chapter number="22" title="NOTICE AND PROCEDURE FOR MAKING COPYRIGHT INFRINGEMENT CLAIMS">
          <Paragraph>
            If you believe that your copyright or the copyright of a person on whose behalf you are
            authorized to act has been infringed, please provide HEROES a written notice containing
            the following information:
          </Paragraph>
          <List>
            <CircleListItem>
              an electronic or physical signature of the person authorized to act on behalf of the
              owner of the copyright or other intellectual property interest;
            </CircleListItem>
            <CircleListItem>
              a description of the copyrighted work or other intellectual property that you claim
              has been infringed;
            </CircleListItem>
            <CircleListItem>
              a description of where the material that you claim is infringing is located on the
              Service.
            </CircleListItem>
            <Paragraph>
              <span>HEROES can be reached at:</span>
              <span>Email: legal@nearweek.com</span>
              <span>Subject Line: Copyright Notification</span>
            </Paragraph>
          </List>
        </Chapter>
        <Chapter number="23" title="BINDING ARBITRATION AND CLASS ACTION WAIVER">
          <Paragraph>
            PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS,
            INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT
          </Paragraph>
          <Chapter number="23.1" title="Initial Dispute Resolution" className="text-sm">
            <Paragraph>
              The parties shall use their best efforts to engage directly to settle any dispute,
              claim, question, or disagreement and engage in good faith negotiations which shall be
              a condition to either party initiating a lawsuit or arbitration.
            </Paragraph>
          </Chapter>
          <Chapter number="23.2" title="Binding Arbitration" className="text-sm">
            <Paragraph>
              If the parties do not reach an agreed upon solution within a period of 30 days from
              the time informal dispute resolution under the Initial Dispute Resolution provision
              begins, then either party may initiate binding arbitration as the sole means to
              resolve claims, subject to the terms set forth below. Specifically, all claims arising
              out of or relating to these Terms (including their formation, performance and breach),
              the parties’ relationship with each other and/or your use of the Service shall be
              finally settled by binding arbitration administered by the relevant Swiss Arbitration
              Association in accordance with the provisions of its Commercial Arbitration Rules and
              the supplementary procedures for consumer related disputes of the Swiss Arbitration
              Association (the “ASA”), excluding any rules or procedures governing or permitting
              class actions. The arbitrator, and not any federal, state or local court or agency,
              shall have exclusive authority to resolve all disputes arising out of or relating to
              the interpretation, applicability, enforceability or formation of these Terms,
              including, but not limited to any claim that all or any part of these Terms are void
              or voidable, or whether a claim is subject to arbitration. The arbitrator shall be
              empowered to grant whatever relief would be available in a court under law or in
              equity. The arbitrator’s award shall be written, and binding on the parties and may be
              entered as a judgement in any court of competent jurisdiction. The parties understand
              that, absent this mandatory provision, they would have the right to sue in court and
              have a jury trial. They further understand that, in some instances, the costs of
              arbitration could exceed the costs of litigation and the right to discovery may be
              more limited in arbitration than in court.
            </Paragraph>
          </Chapter>
          <Chapter number="23.3" title="Location" className="text-sm">
            <Paragraph>
              Binding arbitration shall take place in Switzerland. You agree to submit to the
              personal jurisdiction of any federal or state court in Switzerland County,
              Switzerland, in order to compel arbitration, to stay proceedings pending arbitration,
              or to confirm, modify, vacate or enter judgement on the award entered by the
              arbitrator.
            </Paragraph>
          </Chapter>
          <Chapter number="23.4" title="Class Action Waiver" className="text-sm">
            <Paragraph>
              The parties further agree that any arbitration or other permitted action shall be
              conducted in their individual capacities only and not as a class action or other
              representative action, and the parties expressly waive their right to file a class
              action or seek relief on a class basis. YOU AND HEROES AGREE THAT EACH MAY BRING
              CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
              PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any
              court or arbitrator determines that the class action waiver set forth in this
              paragraph is void or unenforceable for any reason or that an arbitration can proceed
              on a class basis, then the arbitration provision set forth above shall be deemed null
              and void in its entirety and the parties shall be deemed to have not agreed to
              arbitrate disputes.
            </Paragraph>
          </Chapter>
          <Chapter
            number="23.5"
            title="Exception - Litigation of Intellectual Property and Small Claims Court Claims"
            className="text-sm">
            <Paragraph>
              Notwithstanding the parties` decision to resolve all disputes through arbitration,
              either party may bring an action in state or federal court to protect its intellectual
              property rights (“intellectual property rights” means patents, copyrights, moral
              rights, trademarks, and trade secrets, but not privacy or publicity rights). Either
              party may also seek relief in a small claims court for disputes or claims within the
              scope of that court`s jurisdiction.
            </Paragraph>
          </Chapter>
          <Chapter number="23.6" title="30-Day Right to Opt Out" className="text-sm">
            <Paragraph>
              You have the right to opt-out and not be bound by the arbitration and class action
              waiver provisions set forth above by sending written notice of your decision to
              opt-out to the following address: NEARWEEK AG, Aabachstrasse 4, 6300, Zug, and via
              email at legal@nearweek.com, with subject line “HEROES LEGAL OPT OUT”. The notice must
              be sent within 30 days of your first use of the Service otherwise you shall be bound
              to arbitrate disputes in accordance with the terms of those paragraphs. If you opt-out
              of these arbitration provisions, HEROES also will not be bound by them.
            </Paragraph>
          </Chapter>
          <Chapter number="23.7" title="Changes to this Section" className="text-sm">
            <Paragraph>
              HEROES will provide 60-days` notice of any changes to this Section. Changes will
              become effective on the 60th day, and will apply prospectively only to any claims
              arising after the 60th day.. You further agree to accept service of process by mail,
              and hereby waive any and all jurisdictional and venue defences otherwise available.
              The Terms and the relationship between you and HEROES shall be governed by the laws of
              Switzerland.
            </Paragraph>
          </Chapter>
        </Chapter>
        <Chapter number="24" title="RELEASE">
          <Paragraph>
            In addition to the recognition that HEROES is not a party to any Service Contract
            between Users, you hereby release HEROES, our affiliates, and our respective officers,
            directors, agents, subsidiaries, joint ventures, and employees from claims, demands, and
            damages (actual and consequential) of every kind and nature, known and unknown, arising
            out of or in any way connected with any dispute you have with another User, whether it
            be at law or in equity. This release includes, for example and without limitation, any
            disputes regarding the performance, functions, and quality of the work provided to the
            HEROES Poster and requests for refunds based upon disputes.
          </Paragraph>
          <Paragraph>
            TO THE EXTENT APPLICABLE, YOU HEREBY WAIVE THE PROTECTIONS OF CALIFORNIA CIVIL CODE §
            1542 (AND ANY ANALOGOUS LAW IN ANY OTHER APPLICABLE JURISDICTION) WHICH SAYS: “A GENERAL
            RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST
            IN HIS OR HER FAVOUR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER
            MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
          </Paragraph>
        </Chapter>
        <Chapter number="25" title="GENERAL INFORMATION">
          <Chapter number="25.1" title="Entire Agreement" className="text-sm">
            <Paragraph>
              These Terms (and any additional terms, rules and conditions of participation that
              HEROES may post on the Service) constitute the entire agreement between you and HEROES
              with respect to the Service and supersedes any prior agreements, oral or written,
              between you and HEROES. In the event of a conflict between these Terms and the
              additional terms, rules and conditions of participation, the latter will prevail over
              the Terms to the extent of the conflict.
            </Paragraph>
          </Chapter>
          <Chapter number="25.2" title="Waiver and Severability of Terms" className="text-sm">
            <Paragraph>
              The failure of HEROES to exercise or enforce any right or provision of the Terms shall
              not constitute a waiver of such right or provision. If any provision of the Terms is
              found by an arbitrator or court of competent jurisdiction to be invalid, the parties
              nevertheless agree that the arbitrator or court should endeavor to give effect to the
              parties` intentions as reflected in the provision, and the other provisions of the
              Terms remain in full force and effect.
            </Paragraph>
          </Chapter>
          <Chapter number="25.3" title="Statute of Limitations" className="text-sm">
            <Paragraph>
              You agree that regardless of any statute or law to the contrary, any claim or cause of
              action arising out of or related to the use of the Service or the Terms must be filed
              within one (1) year after such claim or cause of action arose or be forever barred.
            </Paragraph>
          </Chapter>
          <Chapter number="25.4" title="Communications" className="text-sm">
            <Paragraph>
              Users with questions, complaints or claims with respect to the Service may contact us
              at legal@nearweek.com.
            </Paragraph>
          </Chapter>
        </Chapter>
        <Chapter number="26" title="INTERNATIONAL USERS">
          <Paragraph>
            The Site is controlled, operated and administered by HEROES from its offices within
            Switzerland, and is not intended to subject HEROES to the laws or jurisdiction of any
            state, country or territory other than that of Switzerland. HEROES does not represent or
            warrant that the Site or any part thereof is appropriate or available for use in any
            particular jurisdiction other than Switzerland. Those who choose to access the Site do
            so on their own initiative and at their own risk, and are responsible for complying with
            all local statutes, orders, regulations, rules, and other laws. You are also subject to
            Swiss export controls and are responsible for any violations of such controls, including
            without limitation any Swiss embargoes or other federal rules and regulations
            restricting exports. HEROES may limit the Site’s availability, in whole or in part, to
            any person, geographic area or jurisdiction we choose, at any time and in our sole
            discretion.
          </Paragraph>
        </Chapter>
        <Chapter number="27" title="ADDITIONAL SERVICES">
          <Chapter number="27.1" title="General" className="text-sm">
            <Paragraph>
              If you enter into any order form for additional services (the “Additional Services”)
              from HEROES (including without limitation Hackathons and Tribes), you agree to be
              subject to the terms of such order form and these Terms, including this Section 27.
            </Paragraph>
          </Chapter>
          <Chapter number="27.2" title="Payment" className="text-sm">
            <Paragraph>
              You agree to pay HEROES the fees specified in the order form (the “Additional Fees”).
              Unless otherwise specified therein, all Additional Fees will be due and payable within
              30 days of your receipt of an invoice. Failure to pay the Additional Fees by their due
              dates may result in the loss of some or all of the Services at HEROES`s sole
              discretion. Additional Fees are non-refundable.
            </Paragraph>
          </Chapter>
          <Chapter number="27.3" title="Licence to Your Materials" className="text-sm">
            <Paragraph>
              You hereby grant to HEROES a non-exclusive, non-transferable licence to use the logos,
              trademarks, service marks, trade dress and other protectable source or business
              identifiers, and any documents, information and other materials owned or licensed by
              you and provided by you to HEROES for use in connection with the Additional Services.
              Except as specifically set forth above, neither party will acquire any right, title or
              interest in the other party’s trademarks, service marks, trade secrets, logos,
              commercial symbols, copyrights, patents and any other intellectual property rights by
              virtue of the provisions hereunder.
            </Paragraph>
          </Chapter>
          <Chapter number="27.4" title="Publicity" className="text-sm">
            <Paragraph>
              You agree not to issue any press release or otherwise make any public announcement
              related to the Additional Services or the transactions contemplated in the order forms
              without the prior written approval of HEROES.
            </Paragraph>
          </Chapter>
        </Chapter>
      </div>
    </Layout>
  );
};
