import classNames from "classnames";

const LoginButton = (props: {
  className?: string;
  handleButtonClick: () => void;
  icon: React.ReactNode;
}) => {
  const { className, handleButtonClick, icon } = props;
  return (
    <button
      className={classNames(
        "w-full flex justify-center items-center select-none p-2 border border-neutral-600 rounded-3xl text-neutral-400 bg-neutral-200",
        className
      )}
      onClick={handleButtonClick}>
      {icon}
    </button>
  );
};

export default LoginButton;
