import { ClaimantApproval } from "../types/BountyCreate";

export const DEV_MODE = process.env.NODE_ENV === "development" || false;

export const CODE_TYPE = "email";

export enum bountyStatus {
  "New" = "New",
  "Claimed" = "Claimed",
  "ManyClaimed" = "ManyClaimed",
  "AwaitingClaims" = "AwaitingClaims",
  "PartiallyCompleted" = "PartiallyCompleted",
  "Completed" = "Completed",
  "Canceled" = "Canceled"
}

export type BountyStatus = keyof typeof bountyStatus;

export enum claimStatuses {
  "New",
  "ReadyToStart",
  "InProgress",
  "ApprovedCandidate",
  "Competes",
  "Disputed",
  "Expired",
  "Approved",
  "Rejected",
  "NotCompleted",
  "NotHired",
  "CompetitionLost",
  "Completed",
  "Canceled"
}

export type ClaimStatus = keyof typeof claimStatuses;

export const defExperienceLevels: Array<{ name: string; value: string }> = [
  { name: "Beginner", value: "Beginner" },
  { name: "Intermediate", value: "Intermediate" },
  { name: "Advanced", value: "Advanced" },
  { name: "AnyExperience", value: "Any" }
];

export const defTimeCommitments: Array<{ name: string; value: string }> = [
  { name: "Hours", value: "Hours" },
  { name: "Days", value: "Days" },
  { name: "Weeks", value: "Weeks" },
  { name: "Months", value: "Months" }
];

export const defContactTypes: Array<{ name: string; value: string }> = [
  { name: "Discord", value: "Discord" },
  { name: "Telegram", value: "Telegram" },
  { name: "Twitter", value: "Twitter" },
  { name: "Email", value: "Email" }
];

export type TClaimerApprovalDefValues = {
  name: string;
  value: string;
  defFieldValue: ClaimantApproval;
  formField: any;
  required?: boolean;
};

export const claimerApprovalDefValues: TClaimerApprovalDefValues[] = [
  {
    name: "MultipleClaims",
    value: "Manual Approval",
    defFieldValue: "MultipleClaims",
    formField: "claimant_approval"
  },
  {
    name: "ApprovalByWhitelist",
    value: "Whitelist Auto-Approval",
    defFieldValue: {
      ApprovalByWhitelist: { claimants_whitelist: [] }
    },
    formField: "claimant_approval.ApprovalByWhitelist.claimants_whitelist",
    required: true
  },
  {
    name: "WhitelistWithApprovals",
    value: "Whitelist Approval",
    defFieldValue: {
      WhitelistWithApprovals: { claimants_whitelist: [] }
    },
    formField: "claimant_approval.WhitelistWithApprovals.claimants_whitelist",
    required: true
  },
  {
    name: "WithoutApproval",
    value: "Auto-Approval",
    defFieldValue: "WithoutApproval",
    formField: "claimant_approval"
  }
];
