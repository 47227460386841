import classNames from "classnames";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import utils from "../../../../../../services/utils";
import { Input } from "../../../../../general/Input";
import { ValidationLayout } from "../../../../../Layout";
import Separator from "../../../../../general/Separator";
import { FormControl, FormRow } from "../../../../../general/Form/FormElements";
import { PaymentFormData } from "../../Payment";
import { NewSelect } from "../../../../../general/Select";

const OffContractForm = () => {
  const methods = useFormContext<PaymentFormData>();
  const {
    control,
    register,
    unregister,
    setValue,
    formState: { errors, isSubmitted }
  } = methods;

  const { offContractPayment } = useStoreState((state) => state.newBountyForm);

  const currency = useStoreState(
    (state) => state.newBounty.postpaid?.PaymentOutsideContract.currency
  );

  const currencies = useStoreState((state) => state.app.currencies);

  const mapCurrencies = () => {
    return (
      currencies?.map((currency) => {
        return { name: currency.symbol, value: currency.symbol };
      }) ?? [
        { name: "USD", value: "USD" },
        { name: "EUR", value: "EUR" }
      ]
    );
  };

  const actions = useStoreActions((actions) => actions);

  const {
    newBounty: { setOffContractCurrency },
    newBountyForm: { setOffContractPaymentPayment }
  } = actions;

  const handleTokenAccountChange = async (selected: { name: string; value: string }) => {
    setValue("offContractPayment.currency", selected.name);
    setOffContractCurrency(selected.name);
  };

  useEffect(() => {
    return () => {
      unregister("offContractPayment");
      unregister("offContractPayment.payment");
    };
  }, [unregister]);

  const handlePaymentChange = (value: string) => {
    const formattedValue = value.replace(",", ".");
    setValue("offContractPayment.payment", formattedValue);
    setOffContractPaymentPayment(formattedValue);
  };

  return (
    <>
      <div className="flex max-w-fit">
        <div className={classNames("flex items-center gap-2 flex-nowrap")}>
          <div className="flex gap-4 flex-nowrap items-end">
            <div>
              <input
                type="hidden"
                defaultValue={currency}
                {...register("offContractPayment.currency", {
                  required: "Required field"
                })}
              />
              <ValidationLayout
                {...{
                  validate: () => {
                    return {
                      valid: !errors.offContractPayment?.currency,
                      started: isSubmitted,
                      message: errors?.offContractPayment?.currency?.message
                    };
                  }
                }}>
                <NewSelect
                  {...{
                    placeholder: "Select token",
                    customStyles: {
                      container: "px-[20px] py-0",
                      optionsList: { container: "text-sm" }
                    },
                    selected: {
                      name: currency || "",
                      value: currency || ""
                    },
                    defaultOptionsList: mapCurrencies(),
                    defaultOption: {
                      name: currency || "",
                      value: currency || ""
                    },
                    onSelect: (selected: { name: string; value: string }) => {
                      handleTokenAccountChange(selected);
                    }
                  }}
                />
              </ValidationLayout>
            </div>
            <div>
              <FormControl
                name="offContractPayment.payment"
                control={control}
                defaultValue={offContractPayment?.payment ?? ""}
                rules={{
                  required: "Required field",
                  validate: {
                    minAmount: (value: number) => value > 0 || "is not equal to 0"
                  }
                }}
                render={({ field: { name, value, onChange } }) => {
                  return (
                    <Input
                      {...{
                        id: "input-amount",
                        customStyles: {
                          input: "px-[20px] py-[15px] text-right"
                        },
                        name: name,
                        value: value,
                        validation: {
                          valid: !errors.offContractPayment?.payment,
                          message: errors.offContractPayment?.payment?.message,
                          started: isSubmitted
                        },
                        onChange: (value: string) => {
                          handlePaymentChange(value);
                          onChange(value);
                        },
                        disabled: !currency
                      }}
                      {...{ name, value }}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <Separator customStyles={{ container: "py-4" }} />
      </div>
      <FormRow>
        <div className="flex justify-between">
          <div className="text-sm text-neutral-200">Total</div>
          <div className="text-sm text-neutral-50 uppercase">
            {utils.formatWithCommas(offContractPayment?.payment ?? "")} {currency}
          </div>
        </div>
      </FormRow>
    </>
  );
};

export default OffContractForm;
