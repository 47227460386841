import classNames from "classnames";
import { useState, useRef } from "react";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import { DarkButton } from "../../../../../general/Button";
import { TextArea } from "../../../../../general/Input";
import { FormRow } from "../../../../../general/Form/FormElements";
import { LinkIcon } from "../../../../../general/SVGIcon";
import CheckBox from "../../../../../general/CheckBox";

export default function UploadAttachments() {
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const addAttachments = useStoreState((state) => state.newBountyForm.addAttachments);
  const attachments = useStoreState((state) => state.newBounty.metadata.attachments);

  const actions = useStoreActions((actions) => actions);
  const {
    app: { uploadAttachments },
    newBounty: {
      metadata: { setAttachments }
    },
    newBountyForm: { setAddAttachments }
  } = actions;

  const handleUploadClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const newFiles = [...files];
    newFiles.push(e.target.files[0]);
    setFiles(newFiles);
  };

  const handleRemoveFile = (e: React.MouseEvent<Element, MouseEvent>, name: string) => {
    e.preventDefault();
    const newFiles = files.filter((delFile) => delFile.name !== name);
    setFiles(newFiles);
  };

  const handleImportResources = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    files.forEach((file) => data.append("files", file));

    const uploadedFiles = await uploadAttachments(data);
    if (uploadedFiles) {
      setAttachments(uploadedFiles.map((f: { url: string }) => f.url));
    }
    setLoading(false);
  };

  return (
    <div>
      <FormRow>
        <CheckBox
          {...{
            text: "Add attachments",
            active: addAttachments,
            onClick: () => setAddAttachments(!addAttachments)
          }}
        />
      </FormRow>
      {addAttachments && (
        <div>
          <input
            className="hidden"
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div className="my-2.5">
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                onClick: (e: React.MouseEvent<Element, MouseEvent>) => handleUploadClick(e),
                text: "Attach file"
              }}
            />
          </div>
          {files.length > 0 ? (
            <div className="border border-dashed border-gray-800 rounded-2xl p-4 text-white text-center my-4">
              {Array.from(files).map((file) => (
                <div key={file.name} className="my-2.5 flex items-center justify-between">
                  <span className="flex items-center text-sm text-neutral-700">
                    <LinkIcon className="w-[12px] h-[12px] mx-1" /> {file.name}
                  </span>
                  <button
                    className="border rounded-md px-2 border-neutral-700 hover:text-primary-900"
                    onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                      handleRemoveFile(e, file.name)
                    }>
                    x
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="border border-dashed border-gray-800 rounded-sm p-14 text-neutral-500 text-sm text-center my-4">
              Attach files to upload
            </div>
          )}
          <div className="flex justify-end px-4">
            <DarkButton
              {...{
                customStyles: {
                  text: classNames({
                    "text-white": Boolean(files.length)
                  }),
                  button: "border-neutral-700 py-[0.76em] hover:shadow-white"
                },
                text: loading ? "Loading..." : "Upload",
                disabled: !Boolean(files.length),
                onClick: (e) => handleImportResources(e)
              }}
            />
          </div>
        </div>
      )}
      {attachments && (
        <div className="flex items-end">
          <TextArea
            {...{
              customStyles: {
                input: "px-[20px] py-[15px]"
              },
              value: attachments.join("\r\n"),
              placeholder: "Link to files or documents",
              label: { text: "Resources" }
            }}
          />
        </div>
      )}
    </div>
  );
}
