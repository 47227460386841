import { useState } from "react";
import { useStoreActions } from "../../../hooks";
import { Bounty } from "../../../types/Bounty";
import { DarkButton } from "../../general/Button";
import Modal from "../../general/Modal";
import ActionButton from "./general/ActionButton";
import { FinalExecutionOutcomeForm } from "../Dialogues";
import { providers } from "near-api-js";

export default function StartCompetitionAction(props: {
  bounty: Bounty;
  refetchActions: () => void;
}) {
  const { bounty, refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<providers.FinalExecutionOutcome | null>(
    null
  );
  const startСompetition = useStoreActions((actions) => actions.nearApi.startСompetition);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const result = await startСompetition({ id: bounty.id });
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
      refetchActions && refetchActions();
    }
  };

  return (
    <>
      <ActionButton text="Start contest" disabled={false} handleClick={handleButtonClick} />
      <Modal
        {...{
          customStyles: { content: "h-fit" },
          open: showForm,
          onClose: handleButtonClick,
          okButton: () => {
            return (
              <DarkButton
                {...{
                  disabled: loading,
                  customStyles: { button: "max-w-[146px]" },
                  onClick: () => handleSubmitForm(),
                  text: !loading ? "Start" : "Submitting..."
                }}
              />
            );
          }
        }}>
        <div className="font-poppins text-white my-8">
          <div className="py-2.5">Start contest</div>
          <div className="py-2.5 ">Are you sure you want to start the contest?</div>
        </div>
      </Modal>
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  );
}
