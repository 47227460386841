import { Bounty } from "../../../../types/Bounty";
import { DarkButton } from "../../../general/Button";
import Modal from "../../../general/Modal";

const WithdrawForm = (props: {
  bounty?: Bounty;
  show: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}) => {
  const { show, handleClose, handleSubmit } = props;

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                onClick: () => handleSubmit(),
                text: "Confirm"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white">Withdraw</div>
        <div className="py-2.5 text-neutral-200">Are you sure you want to withdraw funds?</div>
      </div>
    </Modal>
  );
};

export default WithdrawForm;
