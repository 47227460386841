import { useState } from "react";
import { useStoreActions } from "../../../hooks";
import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { DefermentOfKYC } from "../../../types/Kyc";
import ActionButton from "./general/ActionButton";
import AcceptSelectedHeroesForm from "../Dialogues/AcceptHeroForm/AcceptSelectedHeroesForm";
import MaxAllowanceMessage from "../Dialogues/MaxAllowanceMessage";

export default function AcceptSelectedAction(props: {
  claim: Claim[];
  bounty: Bounty;
  text?: string;
}) {
  const { claim, bounty, text = "Accept" } = props;
  const [showForm, setShowForm] = useState(false);
  const setAcceptClaimant = useStoreActions((actions) => actions.nearApi.setAcceptClaimant);
  const [showMaxAllowance, setShowMaxAllowance] = useState<boolean>(false);

  const canPostponeKYC = () => {
    return !!(
      bounty?.reviewers !== "ValidatorsDao" &&
      bounty.kyc_config?.kyc_verification_method === "DuringClaimApproval"
    );
  };

  const handleButtonClick = () => {
    if (claim.length <= 20) {
      setShowForm(!showForm);
    } else {
      setShowMaxAllowance(true);
    }
  };

  const handleSubmitForm = async (value?: DefermentOfKYC) => {
    await setAcceptClaimant(
      claim.map((item) => {
        return {
          id: bounty.id,
          receiver_id: item.owner,
          token: bounty.token,
          kyc_postponed: value,
          claim_number: item.claim_number
        };
      })
    );
  };

  const handleCloseMaxAllowance = () => {
    setShowMaxAllowance(false);
  };

  return (
    <>
      <ActionButton disabled={false} handleClick={handleButtonClick} text={text} />
      <AcceptSelectedHeroesForm
        bounty={bounty}
        show={showForm}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
        isClaimerWhitelisted={true}
        useKYC={bounty.kyc_config?.kyc_required ?? false}
        canPostponeKYC={canPostponeKYC()}
      />
      <MaxAllowanceMessage
        show={showMaxAllowance}
        handleClose={handleCloseMaxAllowance}
        count={"20"}
      />
    </>
  );
}
