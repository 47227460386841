import classNames from "classnames";

const FlagIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("", className)}>
      <path
        d="M2.75 2.76379V3.88879M2.75 3.88879L4.8275 3.36904C6.3908 2.97833 8.04231 3.15976 9.4835 3.88054L9.5645 3.92104C10.9766 4.62702 12.5914 4.81571 14.1283 4.45429L16.4608 3.90529C16.1772 6.52245 16.1785 9.16265 16.4645 11.7795L14.129 12.3285C12.5921 12.6904 10.9769 12.5019 9.5645 11.796L9.4835 11.7555C8.04231 11.0348 6.3908 10.8533 4.8275 11.244L2.75 11.7638M2.75 3.88879V11.7638M2.75 16.2638V11.7638"
        stroke="stroke-inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FlagIcon;
