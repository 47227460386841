import classNames from "classnames";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type MarkStyles = {
  container?: string;
  text?: string;
};

interface MarkProps {
  customStyles?: MarkStyles;
  text: string | ReactNode;
  children?: React.ReactNode;
}

const buildStyles = (customStyles: MarkStyles | undefined) => ({
  container: twMerge(classNames("w-fit rounded-full px-1.5", customStyles?.container)),
  text: twMerge(classNames("text-sm uppercase text-center font-poppins", customStyles?.text))
});

const Mark = function (props: MarkProps) {
  const { customStyles, text, children } = props;
  const styles = buildStyles(customStyles);

  return (
    <div className={styles.container}>
      <p className={styles.text}>{text}</p>
      {children}
    </div>
  );
};

export default Mark;
