import classNames from "classnames";
import { Bounty } from "../../../../../types/Bounty";
import CurrencyAmount from "../../general/CurrencyAmount";
import TokenAmount from "../../general/TokenAmount";

const SimpleBountyAmount = ({ bounty, className }: { bounty: Bounty; className?: string }) => {
  return (
    <div>
      {bounty?.token && (
        <TokenAmount
          token={bounty.token}
          amount={bounty.amount}
          className={classNames("text-neutral-50", className)}
        />
      )}
      {bounty?.currency && (
        <CurrencyAmount
          currency={bounty.currency}
          amount={bounty.amount}
          className={classNames("text-neutral-50", className)}
        />
      )}
    </div>
  );
};

export default SimpleBountyAmount;
