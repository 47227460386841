import { Bounty } from "../../../../../types/Bounty";
import CurrencyAmount from "../../general/CurrencyAmount";
import TokenAmount from "../../general/TokenAmount";

const MultitaskingTotalAmount = ({ bounty }: { bounty: Bounty }) => {
  return (
    <div className="text-success">
      {bounty?.token && (
        <TokenAmount
          token={bounty.token}
          amount={bounty.multitasking?.amount_per_slot ?? "0"}
          className="text-success"
        />
      )}
      {bounty?.currency && (
        <CurrencyAmount
          currency={bounty.currency}
          amount={bounty.multitasking?.amount_per_slot ?? "0"}
          className="text-success"
        />
      )}
    </div>
  );
};

export default MultitaskingTotalAmount;
