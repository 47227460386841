import { useState, useEffect, RefObject, MouseEvent } from "react";
import {
  format,
  subMonths,
  addMonths,
  subYears,
  addYears,
  isSameDay,
  isSameMonth,
  getDaysInMonth,
  getDay,
  setMonth,
  isPast,
  isFuture,
  isAfter
} from "date-fns";
import SvgIcon from "./SvgIcon";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export type SimpleDatepickerStyles = {
  datePicker?: string;
  date?: string;
};

interface ExpandedDatepicker {
  customStyles?: SimpleDatepickerStyles;
  refer?: RefObject<HTMLDivElement> | null;
  date: Date;
  onChange: (value: Date) => void;
  onClose: () => void;
  allowToday: boolean;
  allowPastDays: boolean;
  allowFutureDays: boolean;
  maxDate?: Date;
}

type DatepickerType = "date" | "month" | "year";

const SimpleDatePicker = (props: ExpandedDatepicker) => {
  const {
    refer,
    date,
    onChange,
    onClose,
    allowToday = true,
    allowPastDays,
    allowFutureDays,
    maxDate,
    customStyles
  } = props;
  const [dayCount, setDayCount] = useState<Array<number>>([]);
  const [blankDays, setBlankDays] = useState<Array<number>>([]);
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState(date || new Date());
  const [type, setType] = useState<DatepickerType>("date");
  const DAYS = ["S", "M", "T", "W", "T", "F", "S"];

  const decrement = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    switch (type) {
      case "date":
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      case "month":
        setDatepickerHeaderDate((prev) => subYears(prev, 1));
        break;
    }
  };

  const increment = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    switch (type) {
      case "date":
        setDatepickerHeaderDate((prev) => addMonths(prev, 1));
        break;
      case "month":
        setDatepickerHeaderDate((prev) => addYears(prev, 1));
        break;
    }
  };

  const isToday = (day: number) => {
    const todayDate = new Date();
    const dayDate = new Date(
      datepickerHeaderDate.getFullYear(),
      datepickerHeaderDate.getMonth(),
      day
    );
    return !allowToday && isSameDay(dayDate, todayDate);
  };

  const isPastDay = (day: number) => {
    const dayDate = new Date(
      datepickerHeaderDate.getFullYear(),
      datepickerHeaderDate.getMonth(),
      day
    );
    return !allowPastDays && isPast(dayDate);
  };

  const isFutureDay = (day: number) => {
    const dayDate = new Date(
      datepickerHeaderDate.getFullYear(),
      datepickerHeaderDate.getMonth(),
      day
    );
    return !allowFutureDays && isFuture(dayDate);
  };

  const isSelectedDay = (day: number) => {
    const dayDate = new Date(
      datepickerHeaderDate.getFullYear(),
      datepickerHeaderDate.getMonth(),
      day
    );
    return isSameDay(dayDate, date);
  };

  const isAfterMaxDay = (day: number) => {
    const dayDate = new Date(
      datepickerHeaderDate.getFullYear(),
      datepickerHeaderDate.getMonth(),
      day
    );
    return maxDate ? isAfter(dayDate, maxDate) : false;
  };

  const setDateValue = (day: number) => () => {
    if (!isToday(day) && !isPastDay(day) && !isAfterMaxDay(day)) {
      const selectedDate = new Date(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        day
      );

      onChange(selectedDate);
      onClose();
    }
  };

  const getDayCount = (date: Date) => {
    const daysInMonth = getDaysInMonth(date);

    // find where to start calendar day of week
    const dayOfWeek = getDay(new Date(date.getFullYear(), date.getMonth(), 1));
    const blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    setBlankDays(blankdaysArray);
    setDayCount(daysArray);
  };

  const isSelectedMonth = (month: number) =>
    isSameMonth(new Date(new Date(date).getFullYear(), month, new Date(date).getDay()), date);

  const setMonthValue = (month: number) => {
    return (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const newDate = setMonth(datepickerHeaderDate, month);
      setDatepickerHeaderDate(newDate);
      setType("date");
    };
  };

  const showMonthPicker = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setType("month");
  };

  const showYearPicker = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setType("date");
  };

  useEffect(() => {
    getDayCount(datepickerHeaderDate);
  }, [datepickerHeaderDate]);

  return (
    <div
      className={twMerge(
        classNames(
          "bg-neutral-800 mt-14 rounded-lg shadow p-4 absolute top-0 left-0 z-10 w-[17rem] text-sm",
          customStyles?.datePicker
        )
      )}
      ref={refer}>
      <div className="flex justify-between items-center mb-2">
        <div>
          <button
            type="button"
            className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-neutral-900 p-1 rounded-full"
            onClick={decrement}>
            <SvgIcon {...{ name: "rightArrow" }} />
          </button>
        </div>
        {type === "date" && (
          <div
            className="w-full flex flex-row justify-around p-1 text-lg font-bold text-neutral-200 cursor-pointer hover:bg-neutral-900 rounded-lg"
            onClick={showMonthPicker}>
            <p className="text-center">{format(datepickerHeaderDate, "MMMM")}</p>
            <p className="text-center">{format(datepickerHeaderDate, "yyyy")}</p>
          </div>
        )}
        {type === "month" && (
          <div
            onClick={showYearPicker}
            className="flex-grow p-1 text-lg font-bold text-neutral-200 cursor-pointer hover:bg-neutral-900 rounded-lg">
            <p className="text-center">{format(datepickerHeaderDate, "yyyy")}</p>
          </div>
        )}
        <div>
          <button
            type="button"
            className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-neutral-900 p-1 rounded-full"
            onClick={increment}>
            <SvgIcon {...{ name: "leftArrow" }} />
          </button>
        </div>
      </div>
      {type === "date" && (
        <>
          <div className="flex flex-wrap mb-3 -mx-1">
            {DAYS.map((day, index) => (
              <div key={index} style={{ width: "14.26%" }} className="px-1">
                <div className="text-neutral-600 font-medium text-center text-inherit">{day}</div>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap -mx-1">
            {blankDays.map((_, index) => (
              <div
                key={index}
                style={{ width: "14.26%" }}
                className="text-center border p-1 border-transparent text-inherit"></div>
            ))}
            {dayCount.map((day, index) => (
              <div key={index} style={{ width: "14.26%" }} className="px-1 mb-1">
                <div
                  onClick={setDateValue(day)}
                  className={classNames(
                    "text-center text-inherit rounded-full leading-loose transition ease-in-out duration-100 hover:bg-neutral-900",
                    {
                      "text-neutral-700 hover:bg-transparent":
                        (!isToday(day) && isPastDay(day)) || isFutureDay(day) || isAfterMaxDay(day),
                      "cursor-default": isToday(day) || isPastDay(day) || isAfterMaxDay(day)
                    },
                    {
                      "bg-neutral-500 text-white cursor-none hover:bg-neutral-500": isToday(day)
                    },
                    {
                      "bg-primary-900 text-neutral-900 hover:bg-primary-900": isSelectedDay(day)
                    },
                    {
                      "text-neutral-200 cursor-pointer": allowFutureDays || allowPastDays
                    }
                  )}>
                  {day}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {type === "month" && (
        <div className="flex flex-wrap -mx-1">
          {Array(12)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                {...{
                  onClick: setMonthValue(index),
                  style: { width: "25%" }
                }}>
                <div
                  className={twMerge(
                    classNames(
                      "cursor-pointer p-5 font-semibold text-center text-inherit rounded-lg",
                      {
                        "bg-primary-900 text-neutral-900": isSelectedMonth(index),
                        "text-neutral-200 hover:bg-neutral-900": !isSelectedMonth(index)
                      }
                    )
                  )}>
                  {format(
                    new Date(
                      datepickerHeaderDate.getFullYear(),
                      index,
                      datepickerHeaderDate.getDate()
                    ),
                    "MMM"
                  )}
                </div>
              </div>
            ))}
        </div>
      )}{" "}
    </div>
  );
};

export default SimpleDatePicker;
