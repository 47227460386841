import { useMemo } from "react";
import { useStoreState } from "../../../hooks";
import { KycInfo } from "../../../types/Kyc";
import Info from "./Info";
import CompletedIcon from "../../general/SVGIcon/completedIcon";
import { UserWallet } from "../../../types/Auth";
import WalletAccount from "./WalletAccount";

const MetadataInfo = (props: {
  metadata: Array<{
    label: string;
    value?: string;
    type?: "link";
  }>;
  wallets?: UserWallet[];
  skills?: Array<string>;
  location?: { address1?: string; address2?: string; country?: string };
  walletAccount?: string;
  vat?: { vatRate?: number; vatNumber?: string };
  kycInfo?: KycInfo;
}) => {
  const { metadata = [], skills = [], location, walletAccount, vat, kycInfo, wallets = [] } = props;
  const countries = useStoreState((state) => state.app.countries);

  const mapCountry = useMemo(() => {
    const countryKey = location?.country ?? null;
    return countryKey && Object.keys(countries).includes(countryKey) ? countries[countryKey] : null;
  }, [countries, location?.country]);

  const checkProtocol = (url: string) => {
    return url.includes("https") ? url : url.replace("http", "https");
  };

  const getSiteName = (url: string) => {
    return checkProtocol(url).split("https://")[1];
  };

  return (
    <>
      {metadata.length > 0 && (
        <div className="grid lg:grid-cols-4 sm:grid-cols-1 gap-4">
          {metadata.map((item) => (
            <>
              {item.value && item.type === "link" ? (
                <Info
                  {...{
                    label: item.label
                  }}>
                  <a
                    href={checkProtocol(item?.value)}
                    className="text-primary-900 break-words"
                    target="_blank"
                    rel="noreferrer">
                    {getSiteName(item.value)}
                  </a>
                </Info>
              ) : (
                <Info
                  {...{
                    label: item.label,
                    value: item.value
                  }}
                />
              )}
            </>
          ))}
        </div>
      )}
      {location && (
        <div className="grid lg:grid-cols-4 sm:grid-cols-1 gap-4">
          <Info
            {...{
              label: "Location",
              value: mapCountry ?? "N/A"
            }}
          />
          {location?.address1 && (
            <Info
              {...{
                label: "AddressLine1",
                value: location.address1 ?? "N/A"
              }}
            />
          )}
          {location?.address1 && (
            <Info
              {...{
                label: "AddressLine2",
                value: location.address2 ?? "N/A"
              }}
            />
          )}
        </div>
      )}
      {vat && (
        <div className="grid lg:grid-cols-4 sm:grid-cols-1 gap-4">
          <Info
            {...{
              label: "VAT Rate",
              value: vat?.vatRate?.toString() ?? "N/A"
            }}
          />
          <Info
            {...{
              label: "VAT Number",
              value: vat?.vatNumber ?? "N/A"
            }}
          />
        </div>
      )}
      {skills.length > 0 && (
        <div className="flex flex-row items-center flex-wrap gap-4">
          <Info {...{ label: "Skills" }}>
            <div className="flex flex-wrap gap-2">
              {skills.map((skill: string) => (
                <div
                  className="text-white text-sm rounded-md px-2.5 py-1 font-500 font-poppins bg-post-secondary-900"
                  key={skill}>
                  {skill}
                </div>
              ))}
            </div>
          </Info>
        </div>
      )}
      <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-4">
        <div className="flex flex-row justify-between items-center">
          <Info
            {...{
              label: "Wallet accounts",
              value: (
                <>
                  {wallets.map((wallet) => (
                    <WalletAccount
                      key={wallet.walletId}
                      wallet={wallet}
                      walletAccountId={walletAccount}
                    />
                  ))}
                </>
              )
            }}
          />
        </div>

        {/* {walletAccount && (
          <div className="flex flex-row justify-between items-center">
            <Info
              {...{
                label: "Wallet link",
                value: walletAccount
              }}
            />
          </div>
        )} */}
        {kycInfo && (
          <div className="flex flex-row justify-between items-start">
            <Info
              {...{
                label: "KYC"
              }}>
              {kycInfo?.fractalAnswer && (
                <div className="flex items-center gap-2">
                  <CompletedIcon className="w-5 h-5 fill-success" /> Fractal verified
                </div>
              )}
              {kycInfo?.reviewAnswer && (
                <div className="flex items-center gap-2">
                  <CompletedIcon className="w-5 h-5 fill-success" /> SumSub verified
                </div>
              )}
              {kycInfo?.isWhitelisted && !kycInfo?.fractalAnswer && !kycInfo?.reviewAnswer && (
                <div className="flex items-center gap-2">
                  <CompletedIcon className="w-5 h-5 fill-success" /> Kyc verified
                </div>
              )}
            </Info>
          </div>
        )}
      </div>
    </>
  );
};

export default MetadataInfo;
