import { useQuery } from "@tanstack/react-query";
import Api from "../services/Api";
import { BountyTransaction } from "../types/BountyTransaction";

export type Response = BountyTransaction[];

const useUserTransactions = (props: { userId: string }) => {
  const { userId } = props;

  const fetchData = Api.getUserTransactions;
  const {
    data = [],
    error,
    isFetching,
    status
  } = useQuery(
    ["items", userId],
    () => {
      return fetchData({
        userId
      }).then((res: Response) => {
        return res;
      });
    },
    {}
  );

  return {
    data,
    error,
    status,
    isFetching
  };
};

export default useUserTransactions;
