import Api from "../services/Api";
import { NextActionModel } from "../types/Bounty";
import useWalletAccountId from "./useWalletAccountId";
import { useQuery } from "@tanstack/react-query";

export type NextActionExpected = {
  nextActionExpected: {
    forBounties: boolean;
    forClaims: boolean;
  };
};

export type NextActionProps = NextActionExpected | Partial<NextActionModel>[] | null | undefined;

const useNextActions = (props: { bountyId?: string | number }) => {
  const { bountyId } = props;
  const { walletAccountId } = useWalletAccountId();

  const fetchData = Api.checkNextAction;
  const { data, error, isFetching, isInitialLoading, status, refetch } = useQuery(
    ["nextActions", walletAccountId, bountyId],
    () => {
      if (walletAccountId)
        return fetchData(walletAccountId, bountyId).then((res: NextActionProps) => {
          return res;
        });
      return null;
    },
    {}
  );

  return { data, error, isFetching, isInitialLoading, status, refetch };
};

export default useNextActions;
