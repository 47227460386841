import { useInfiniteQuery } from "@tanstack/react-query";
import Api from "../services/Api";
import { Message } from "../types";

type Response = {
  has_more: boolean;
  has_prev: boolean;
  item_count: number;
  messages: Message[];
  page: number;
  page_count: number;
};

const useMessages = (props: {
  bountyId: number;
  claimerAccountId: string;
  claimNumber?: number;
  page?: number;
  limit: number;
}) => {
  const { bountyId, claimerAccountId, claimNumber, limit = 5 } = props;
  const fetchData = Api.getMessages;
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
    status
  } = useInfiniteQuery(
    ["items", bountyId, claimerAccountId, limit],
    ({ pageParam = 1 }) => {
      return fetchData({
        page: pageParam,
        limit,
        bountyId,
        claimerAccountId,
        claimNumber
      }).then((res: Response) => {
        return res;
      });
    },
    {
      refetchInterval: 10000,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.page < lastPage.page_count ? lastPage.page + 1 : undefined;
        return nextPage;
      }
    }
  );

  return {
    data,
    error,
    fetchNextPage,
    status,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
    isFetchingNextPage
  };
};

export default useMessages;
