import { action, actionOn, computed, thunk, ThunkCreator, thunkOn } from "easy-peasy";
import utils from "../../services/utils";
import { NewBountyForm } from "../../types";
import { formatting } from "../../common/formatting";
import moment from "moment";

export const defaultSteps: Array<NewBountyForm.FormStep> = [
  {
    name: "description",
    title: "Description"
  },
  {
    name: "details",
    title: "Details"
  },
  {
    name: "duration",
    title: "Deadline",
    description: "Set a deadline for your bounty"
  },
  {
    name: "payment",
    title: "Payment"
  },
  {
    name: "additional_info",
    title: "Advanced settings"
  },
  {
    name: "review",
    title: "Final Review"
  }
];

let intervalId: ReturnType<typeof setInterval>;

function checkoutReceipt(callbaсk: ThunkCreator): NodeJS.Timer {
  const checkInterval: ReturnType<typeof setInterval> = setInterval(() => {
    callbaсk();
  }, 5000);
  return checkInterval;
}

export const newBountyFormModel: NewBountyForm.NewBountyFormModel = {
  receiver_id: null /** TODO: get from config */,
  createBountyStatus: "New",
  currentStep: defaultSteps[0],
  github_link: "",
  gitcoin_link: "",
  steps: defaultSteps,
  exchengeSwitcher: "CoinToUSD",
  switchDetails: "custom",
  amount: "0",
  amountInUSD: 0,
  payment: 0,
  offContractPayment: undefined,
  generateInvoice: true,
  useKYCVerification: false,
  asignValidatorDAO: false,
  withoutDeadline: true,
  submitAsNearAccount: true,
  selectedTokenAccount: null,
  deadlineTimeCommitment: "",
  contestOrHackathonTimeCommitment: "",
  account_dao: null,
  proposal_bond: "0",
  addAttachments: false,
  useHunterManualApproval: true,
  useRestrictByWhitelist: false,
  selectedWhiteList: { name: "-1", value: "Add new whitelist" },
  issueId: null,
  platformFeePercentage: computed(
    [(state) => state, (_, storeState) => storeState.app.config],
    (state, config) => {
      return (config?.platform_fee_percentage ?? 0) / 1000;
    }
  ),
  daoFeePercentage: computed(
    [(state) => state, (_, storeState) => storeState.app.config],
    (state, config) => {
      return (config?.validators_dao_fee_percentage ?? 0) / 1000;
    }
  ),
  platform_fee: computed((state) => {
    const platformFeePercentage = state.platformFeePercentage;

    return parseFloat(((state.payment / 100) * platformFeePercentage).toFixed(2));
  }),
  dao_fee: computed((state) => {
    const daoFeePercentage = state.daoFeePercentage;
    return state.asignValidatorDAO
      ? parseFloat(((state.payment / 100) * daoFeePercentage).toFixed(2))
      : 0;
  }),
  total: computed((state) => {
    // const platformFee = state.platform_fee;
    const platformFee = 0;
    const daoFee = state.dao_fee;
    // return parseFloat((state.payment + platformFee + daoFee).toFixed(2));
    return parseFloat((state.payment + platformFee + daoFee).toString());
  }),
  offContractTotal: computed((state) => {
    // const platformFee = state.platform_fee;
    const platformFee = 0;
    const daoFee = state.dao_fee;
    return parseFloat(
      (parseFloat(state.offContractPayment?.payment ?? "0") + platformFee + daoFee).toFixed(2)
    );
  })
};

const newBountyFormActions: NewBountyForm.NewBountyFormActions = {
  setSelectedWhitelist: action((state, payload) => {
    state.selectedWhiteList = payload;
  }),
  setProposalBond: action((state, payload) => {
    state.proposal_bond = payload;
  }),
  setAddAttachments: action((state, payload) => {
    state.addAttachments = payload;
  }),
  setDaoAccount: action((state, payload) => {
    state.account_dao = payload;
  }),
  setCreateBountyStatus: action((state, payload) => {
    state.createBountyStatus = payload;
  }),
  setPayment: action((state, payload) => {
    state.payment = payload;
  }),
  setOffContractPaymentPayment: action((state, payload) => {
    if (state.offContractPayment) {
      state.offContractPayment.payment = payload;
    }
  }),
  setOffContractPaymentAmount: action((state, payload) => {
    if (state.offContractPayment) {
      state.offContractPayment.amount = payload;
    }
  }),
  setOffContractToken: action((state, payload) => {
    if (state.offContractPayment) {
      state.offContractPayment.selectedTokenAccount = payload;
    }
  }),
  setDeadlineTimeCommitment: action((state, payload) => {
    state.deadlineTimeCommitment = payload;
  }),
  setSelectedTokenAccount: action((state, payload) => {
    state.selectedTokenAccount = payload;
  }),
  setSubmitAsNearAccount: action((state, payload) => {
    state.submitAsNearAccount = payload;
  }),
  setAsignValidatorDAO: action((state, payload) => {
    state.asignValidatorDAO = payload;
  }),
  setGenerateInvoice: action((state, payload) => {
    state.generateInvoice = payload;
  }),
  setAmount: action((state, payload) => {
    state.amount = payload;
  }),
  setAmountInUSD: action((state, payload) => {
    state.amountInUSD = payload;
  }),
  setPlatformFee: action((state, payload) => {
    state.platform_fee = payload;
  }),
  setWithoutDeadline: action((state, payload) => {
    state.withoutDeadline = payload;
  }),
  setCurrentStep: action((state, payload) => {
    state.currentStep = payload;
  }),
  setSwitchDetails: action((state, payload) => {
    state.switchDetails = payload;
  }),
  setExchengeSwitcher: action((state, payload) => {
    state.exchengeSwitcher = payload;
  }),
  setGithubLink: action((state, payload) => {
    state.github_link = payload;
  }),
  setGitCoinLink: action((state, payload) => {
    state.gitcoin_link = payload;
  }),
  setKYCVerification: action((state, payload) => {
    state.useKYCVerification = payload;
  }),
  setContestOrHackathonTimeCommitment: action((state, payload) => {
    state.contestOrHackathonTimeCommitment = payload;
  }),
  setReceiver: action((state, payload) => {
    state.receiver_id = payload;
  }),
  setHunterManualApproval: action((state, payload) => {
    state.useHunterManualApproval = payload;
  }),
  setRestrictByWhitelist: action((state, payload) => {
    state.useRestrictByWhitelist = payload;
  }),
  setIssueId: action((state, payload) => {
    state.issueId = payload;
  }),
  onSetAmount: actionOn(
    (_, actions) => actions.newBountyForm.setAmount,
    (state, { payload }) => {
      if (isNaN(parseInt(payload))) {
        state.amount = "0";
      }
    }
  ),
  onSetAmountInUSD: actionOn(
    (_, actions) => actions.newBountyForm.setAmountInUSD,
    (state, target) => {
      if (isNaN(target.payload)) {
        state.amountInUSD = 0;
      }
    }
  ),
  onSubmitAsNearAccount: actionOn(
    (_, actions) => actions.newBountyForm.setSubmitAsNearAccount,
    (state, target) => {
      if (target.payload === true) {
        state.account_dao = null;
      }
    }
  ),
  onSetOffContractPayment: actionOn(
    (_, actions) => actions.newBounty.setPostpaid,
    (state, target) => {
      if (target.payload && "PaymentOutsideContract" in { ...target.payload }) {
        state.offContractPayment = {
          payment: "0",
          amount: "0",
          selectedTokenAccount: { name: "", value: "" }
        };
      } else {
        state.offContractPayment = undefined;
      }
    }
  )
};

const newBountyFormThunks: NewBountyForm.NewBountyFormThunks = {
  next: thunk((state, _, helpers) => {
    const {
      newBountyForm: { currentStep }
    } = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const currentIndex = defaultSteps.findIndex((step) => step.name === currentStep.name);
    storeActions.newBountyForm.setCurrentStep(defaultSteps[currentIndex + 1]);
  }),
  prev: thunk((actions, payload, helpers) => {
    const {
      newBountyForm: { currentStep }
    } = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    if (!payload) {
      const currentIndex = defaultSteps.findIndex((step: NewBountyForm.FormStep) => {
        return step.name === currentStep.name;
      });
      storeActions.newBountyForm.setCurrentStep(defaultSteps[currentIndex - 1]);
    } else {
      const stepIndex = defaultSteps.findIndex((step) => step.name === payload);
      storeActions.newBountyForm.setCurrentStep(defaultSteps[stepIndex]);
    }
  }),
  importGithubLink: thunk(async (actions, payload, helpers) => {
    const importFromGithub = helpers.injections.apiService.Api.importFromGithub;
    const errorActions = helpers.getStoreActions();
    const setError = errorActions.error.setError;
    try {
      const response = await importFromGithub(payload);
      if (!response.message) return response;
      setError(response.message);
      return null;
    } catch (error) {
      console.log(error);
    }
  }),
  importGitcoinLink: thunk(async (actions, payload, helpers) => {
    const importFromGitcoin = helpers.injections.apiService.Api.importFromGitcoin;
    const errorActions = helpers.getStoreActions();
    const setError = errorActions.error.setError;
    try {
      const response = await importFromGitcoin(payload);
      if (!response.error) return response;
      setError(response.message);
      return null;
    } catch (error) {
      console.log(error);
    }
  }),
  onSetWithoutDeadline: thunkOn(
    (_, actions) => actions.newBountyForm.setWithoutDeadline,
    (_, target, helpers) => {
      const storeState = helpers.getStoreState().newBounty;
      if (target.payload) {
        storeState.deadline = "WithoutDeadline";
      } else {
        const timeStampInNano = formatting.formatDayToNanoSec(
          moment(Date.now()).utc(false).add(1, "d").toDate()
        );
        storeState.deadline = { DueDate: { due_date: timeStampInNano.toString() } };
      }
    }
  ),
  onSetUseKYCVerification: thunkOn(
    (_, actions) => actions.newBountyForm.setKYCVerification,
    (_, target, helpers) => {
      const storeState = helpers.getStoreState().newBounty;
      if (target.payload) {
        storeState.kyc_config = {
          KycRequired: {
            kyc_verification_method: "WhenCreatingClaim"
          }
        };
      } else {
        storeState.kyc_config = "KycNotRequired";
      }
    }
  ),
  recalcAmount: thunk((actions, _, helpers) => {
    const storeState = helpers.getStoreState();
    const {
      app: { tokens },
      newBountyForm: { total, selectedTokenAccount }
    } = storeState;
    const storeActions = helpers.getStoreActions();
    const {
      newBountyForm: { setAmount }
    } = storeActions;
    const tokenData = tokens.find((token) => token.tokenId === selectedTokenAccount?.name);
    if (tokenData && selectedTokenAccount) {
      const formatted = utils.getParsedTokenAmount(total.toString(), tokenData.decimals);
      setAmount(formatted.toString());
    }
  }),
  recalcOffContractAmount: thunk((actions, _, helpers) => {
    const storeState = helpers.getStoreState();
    const {
      app: { currencies },
      newBounty: { postpaid },
      newBountyForm: { offContractPayment }
    } = storeState;
    const storeActions = helpers.getStoreActions();
    const {
      error: { setError },
      newBountyForm: { setOffContractPaymentAmount }
    } = storeActions;
    if (offContractPayment?.payment) {
      const outsideContractCurrency = postpaid?.PaymentOutsideContract.currency;
      if (outsideContractCurrency) {
        const currencyInCurrencies = currencies?.find(
          (cuurencyItem) => cuurencyItem.symbol === outsideContractCurrency
        );
        if (currencyInCurrencies) {
          const formatted = utils.getParsedCurrencyAmount(
            offContractPayment.payment.toString(),
            currencyInCurrencies?.decimals ?? 2
          );
          setOffContractPaymentAmount(formatted.toString());
        } else {
          setError(`Decimal currency sign for "${outsideContractCurrency}" not found!`);
        }
      }
    }
  }),
  onSetPayment: thunkOn(
    (_, actions) => actions.newBountyForm.setPayment,
    (_, { payload }, helpers) => {
      const { newBountyForm } = helpers.getStoreState();
      const storeActions = helpers.getStoreActions();
      if (isNaN(payload)) {
        newBountyForm.payment = 0;
      }
      storeActions.newBountyForm.recalcAmount();
    }
  ),
  onOffContractSetPayment: thunkOn(
    (_, actions) => actions.newBountyForm.setOffContractPaymentPayment,
    (model, _, helpers) => {
      const storeActions = helpers.getStoreActions();
      storeActions.newBountyForm.recalcOffContractAmount();
    }
  ),
  onChangeCreateBountyStatus: thunkOn(
    (_, actions) => actions.newBountyForm.setCreateBountyStatus,
    (_, target, helpers) => {
      const getTtransactionReceipt = helpers.getStoreActions().nearApi.getTtransactionReceipt;
      const receiptStatus = target.payload;
      if (receiptStatus === "CheckReceipt") {
        intervalId = checkoutReceipt(getTtransactionReceipt);
      } else if (receiptStatus === "Failure" || receiptStatus === "Success") {
        clearInterval(intervalId);
      }
    }
  ),
  onAssignValidatorsDao: thunkOn(
    (_, actions) => actions.newBountyForm.setAsignValidatorDAO,
    (actions, target, helpers) => {
      const { setReviewers } = helpers.getStoreActions().newBounty;
      const storeState = helpers.getStoreState();
      const storeActions = helpers.getStoreActions();
      const config = storeState.app;

      if (target.payload && config.validatorDAO) {
        setReviewers({
          ValidatorsDao: {
            validators_dao: {
              account_id: config.validatorDAO.accountId,
              add_proposal_bond: config.validatorDAO.addProposalBond
            }
          }
        });
      } else {
        setReviewers(void 0);
      }
      storeActions.newBountyForm.recalcAmount();
    }
  )
};

const newBountyForm: NewBountyForm.NewBountyFormModel &
  NewBountyForm.NewBountyFormActions &
  NewBountyForm.NewBountyFormThunks = {
  ...newBountyFormModel,
  ...newBountyFormActions,
  ...newBountyFormThunks
};

export default newBountyForm;
