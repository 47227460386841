import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

const Chapter = (props: {
  number: string;
  title: string;
  className?: string;
  children: ReactNode | ReactNode[];
}) => {
  const { number, title, className, children } = props;

  return (
    <div>
      <h2
        className={twMerge(
          "text-xl font-bold my-4 text-neutral-100",
          className
        )}
      >
        {number}. {title}
      </h2>
      <div className="text-sm  font-normal">{children}</div>
    </div>
  );
};

export default Chapter;
