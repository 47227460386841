import utils from "../../../services/utils";
import { getTokenData } from "../general/helpers";

const TokenAmount = (props: { token: string; amount: string }) => {
  const { token, amount } = props;

  return (
    <span className="text-md">
      {token && amount && utils.formatToken(amount, getTokenData(token)?.decimals || 0)}{" "}
      {getTokenData(token)?.symbol || ""}
    </span>
  );
};

export default TokenAmount;
