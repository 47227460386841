import { BaseSection, BaseSectionProps } from "./BaseSection";

export default function BountySection() {
  const baseSectionProps = {
    id: 'bounty_section',
    title: 'Create tasks, receive offers, collaborate with Hero, end project and pay out bounty.',
    items: [
      {
        bgImageClass: 'bg-bounty-section__item-1',
        title: 'Describe task, set price, browse applications.',
      },
      {
        bgImageClass: 'bg-bounty-section__item-2',
        title: 'Accept offer, and start collaborating.',
      },
      {
        bgImageClass: 'bg-bounty-section__item-3',
        title: 'Pay-out bounty when task is finished.',
      },
    ],
  } as BaseSectionProps
  
  return (
    <BaseSection {...baseSectionProps} />
  )
}
