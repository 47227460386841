import React, { useCallback, useEffect, useRef } from "react";

const useClickOutside = (callback: () => void, additionalRef?: React.RefObject<any>) => {
  const ref = useRef<any>(null);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (ref?.current && !ref.current.contains(event.target)) {
        if(!additionalRef || (additionalRef?.current && !additionalRef.current.contains(event.target)))
        callback();
      }
    },
    [additionalRef, callback]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return ref;
};

export default useClickOutside;
