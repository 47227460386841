import classNames from "classnames";

export default function UserIcon(props: { className?: string }) {
  const { className } = props;
  return (
    <svg
      className={classNames("stroke-neutral-200", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.982 18.7252C17.2833 17.8001 16.3793 17.0498 15.3412 16.5336C14.3031 16.0173 13.1594 15.7491 12 15.7502C10.8407 15.7491 9.6969 16.0173 8.65883 16.5336C7.62077 17.0498 6.71675 17.8001 6.01801 18.7252M17.982 18.7252C19.3455 17.5124 20.3071 15.9137 20.7412 14.1413C21.1753 12.3688 21.0603 10.5063 20.4115 8.80067C19.7627 7.09506 18.6107 5.62697 17.1084 4.59109C15.6061 3.55521 13.8244 3.00049 11.9995 3.00049C10.1747 3.00049 8.39295 3.55521 6.89062 4.59109C5.38829 5.62697 4.23634 7.09506 3.58755 8.80067C2.93875 10.5063 2.82376 12.3688 3.25783 14.1413C3.6919 15.9137 4.65451 17.5124 6.01801 18.7252M17.982 18.7252C16.336 20.1934 14.2056 21.0033 12 21.0002C9.79404 21.0036 7.66425 20.1936 6.01801 18.7252M15 9.75019C15 10.5458 14.6839 11.3089 14.1213 11.8715C13.5587 12.4341 12.7957 12.7502 12 12.7502C11.2044 12.7502 10.4413 12.4341 9.87869 11.8715C9.31608 11.3089 9.00001 10.5458 9.00001 9.75019C9.00001 8.95454 9.31608 8.19148 9.87869 7.62887C10.4413 7.06626 11.2044 6.75019 12 6.75019C12.7957 6.75019 13.5587 7.06626 14.1213 7.62887C14.6839 8.19148 15 8.95454 15 9.75019Z"
        stroke="stroke-inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
