import { twMerge } from "tailwind-merge";
import classNames from "classnames";

export default function SmileArrow(props: { className?: string }) {
  const { className } = props;

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9999 1.61304C5.73368 1.61304 1.46497 5.88175 1.46497 11.148C1.46497 16.4142 5.73368 20.6829 10.9999 20.6829C16.2661 20.6829 20.5348 16.4142 20.5348 11.148C20.5348 5.88175 16.2661 1.61304 10.9999 1.61304ZM8.4328 7.48068C7.90471 7.48068 7.62306 7.89044 7.51744 8.1007C7.39197 8.36518 7.32874 8.65489 7.33261 8.9476C7.33261 9.23902 7.39031 9.53925 7.51744 9.79449C7.62306 10.0048 7.90373 10.4145 8.4328 10.4145C8.96088 10.4145 9.24253 10.0048 9.34815 9.79449C9.47528 9.54023 9.53298 9.23902 9.53298 8.9476C9.53298 8.65617 9.47528 8.35594 9.34815 8.1007C9.24253 7.89044 8.96186 7.48068 8.4328 7.48068ZM12.6516 8.1007C12.7573 7.89044 13.0379 7.48068 13.567 7.48068C14.0951 7.48068 14.3767 7.89044 14.4823 8.1007C14.6095 8.35496 14.6672 8.65617 14.6672 8.9476C14.6672 9.23902 14.6095 9.53925 14.4823 9.79449C14.3767 10.0048 14.0961 10.4145 13.567 10.4145C13.0389 10.4145 12.7573 10.0048 12.6516 9.79449C12.5262 9.53001 12.4629 9.2403 12.4668 8.9476C12.4668 8.65617 12.5245 8.35594 12.6516 8.1007ZM14.63 14.7781C14.7021 14.7109 14.7599 14.63 14.8 14.54C14.84 14.45 14.8616 14.3529 14.8633 14.2544C14.8651 14.1559 14.847 14.0581 14.8101 13.9668C14.7732 13.8755 14.7183 13.7925 14.6486 13.7228C14.579 13.6532 14.496 13.5983 14.4047 13.5614C14.3134 13.5245 14.2155 13.5064 14.1171 13.5081C14.0186 13.5099 13.9215 13.5314 13.8315 13.5715C13.7415 13.6116 13.6605 13.6694 13.5934 13.7415C13.2528 14.0821 12.8485 14.3523 12.4035 14.5367C11.9585 14.721 11.4816 14.8159 10.9999 14.8159C10.5182 14.8159 10.0413 14.721 9.59626 14.5367C9.15126 14.3523 8.74694 14.0821 8.40639 13.7415C8.26735 13.6119 8.08346 13.5414 7.89344 13.5447C7.70343 13.5481 7.52213 13.6251 7.38775 13.7594C7.25337 13.8938 7.17639 14.0751 7.17304 14.2651C7.16968 14.4551 7.24022 14.639 7.36977 14.7781C8.3326 15.7407 9.63837 16.2815 10.9999 16.2815C12.3614 16.2815 13.6672 15.7407 14.63 14.7781Z"
        fill="#C7C7C7"
      />
    </svg>
  );
}
