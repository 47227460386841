import { Subtask } from "../../../../../../../types/BountyCreate";

const formatPercents = (subtask: Subtask) => {
  return (subtask.subtask_percent / 1000).toString();
};

const calcPercentsLeft = (subtasks: Subtask[]) => {
  return (
    100 -
    subtasks.reduce(
      (accumulator, currentValue) => accumulator + currentValue.subtask_percent / 1000,
      0
    )
  );
};

export { calcPercentsLeft, formatPercents };
