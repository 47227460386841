import { useState, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type TProps = {
  handleToggle?: (value: number) => void;
  items: string[] | ReactNode[];
  onIndex: number;
};

const groupStyle = `
  button--primary_hover
  min-w-[295px] h-[44px]
  bg-opacity-60 flex flex-row items-center
  bg-neutral-800
  rounded-header
  border-sm border-primary-900
  md:min-w-[344px]
`;

const offStyle = `
  w-[60%] h-[44px]
  py-2 px-4
  bg-transparent
  border-none
  text-primary-900 text-sm uppercase
  md:text-base
  md:px-6
`;

const onStyle = twMerge(
  offStyle,
  `-m-[var(--border-width)]
  bg-toggle-button-on bg-cover bg-center bg-no-repeat
  rounded-full border-sm border-primary-900
  text-neutral-900`
);

function ToggleButton(props: TProps) {
  const { items, handleToggle, onIndex } = props;
  const defaultStates = Array.from(items, (_, index) => index === onIndex); // [true, false, false, ... , false]
  const [states, setStates] = useState<boolean[]>(defaultStates);

  const onClick = (index: number) => {
    const newStates = states.map((_, index_: number) => index_ === index);

    if (handleToggle) {
      handleToggle(index);
    }
    setStates(newStates);
  };

  const onStyleMargin = (index: number) => {
    switch (index) {
      case 0:
        return " -mr-4 lg:-mr-6";
      case states.length - 1:
        return " -ml-4 lg:-ml-6";
      default:
        return " -mx-4 lg:-mx-6";
    }
  };

  const buttons = states.map((state_: boolean, index: number) => {
    return (
      <button
        key={index}
        onClick={() => onClick(index)}
        className={state_ ? onStyle + onStyleMargin(index) : offStyle}>
        {items[index]}
      </button>
    );
  });

  return <div className={groupStyle}>{buttons}</div>;
}

export default ToggleButton;
