import { useStoreState } from "../hooks";
import { Navigate, Outlet } from "react-router-dom";
import { Path } from "history";

type RefferalRouteProps = {
  redirectPath: string | Partial<Path>;
  children?: React.ReactElement | null;
};

const RefferalRoute = (props: RefferalRouteProps) => {
  const { children, redirectPath } = props;
  const { refferalLinkCode } = useStoreState((state) => state.auth);
  const use_verification_code = useStoreState((state) => state.app.config?.use_verification_code);

  if (use_verification_code && !refferalLinkCode) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default RefferalRoute;
