import { actionOn, action } from "easy-peasy";
import { AppError } from "../../types";

const errorModel: AppError.ErrorModel = {
  isError: false,
  message: ""
};

const errorActions: AppError.Actions = {
  setError: action((state, payload) => {
    state.message = payload;
  }),
  clearError: action((state) => {
    state.message = "";
  }),
  onSetError: actionOn(
    (_, storeActions) => storeActions.error.setError,
    (state) => {
      state.isError = true;
    }
  ),
  onClearError: actionOn(
    (_, storeActions) => storeActions.error.clearError,
    (state) => {
      state.isError = false;
    }
  )
};

const error: AppError.ErrorModel & AppError.Actions = {
  ...errorModel,
  ...errorActions
};

export default error;
