import classNames from "classnames";

const AngleBracketsIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
      <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classNames("h-6", className)}
      >
        <g clipPath="url(#clip0_4092_2298)">
          <path
              d="M10.2813 6.43469L12.0625 8.21594L10.2813 9.99719M6.71875 9.99719L4.9375 8.21594L6.71875 6.43469M3.75 14.7472H13.25C13.7224 14.7472 14.1755 14.5595 14.5095 14.2255C14.8436 13.8914 15.0313 13.4384 15.0313 12.9659V3.46594C15.0313 2.99353 14.8436 2.54046 14.5095 2.20641C14.1755 1.87236 13.7224 1.68469 13.25 1.68469H3.75C3.27758 1.68469 2.82452 1.87236 2.49047 2.20641C2.15642 2.54046 1.96875 2.99353 1.96875 3.46594V12.9659C1.96875 13.4384 2.15642 13.8914 2.49047 14.2255C2.82452 14.5595 3.27758 14.7472 3.75 14.7472Z"
              stroke="#F8F8F8"
              strokeLinecap="round"
              strokeLinejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_4092_2298">
            <rect width="16" height="16" fill="white" transform="translate(0 0.215942)"/>
          </clipPath>
        </defs>
      </svg>
  );
};

export default AngleBracketsIcon;
