import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { formatting } from "../../../../../common/formatting";
import utils from "../../../../../services/utils";
import { BountyCreate } from "../../../../../types";
import { Bounty } from "../../../../../types/Bounty";
import { Deadline, DueDate } from "../../../../../types/BountyCreate";
import { DarkButton } from "../../../../general/Button";
import CheckBox from "../../../../general/CheckBox";
import DatePicker from "../../../../general/DatePicker";
import { ValidationLayout } from "../../../../Layout";
import Modal from "../../../../general/Modal";
import { FormRow } from "../../../../general/Form/FormElements";

export type DeadlineFormData = {
  deadline: Deadline;
  deadlineTimeCommitment: string;
};

const EditDeadlineForm = (props: {
  show: boolean;
  loading: boolean;
  handleClose: () => void;
  handleSubmit: (value: Deadline) => void;
  bounty: Bounty;
}) => {
  const { show, handleClose, handleSubmit, bounty, loading } = props;
  const methods = useForm<DeadlineFormData>({
    mode: "onChange"
  });
  const { register, formState, reset } = methods;
  const { errors, isSubmitted } = formState;

  const [deadline, setDeadline] = useState<Deadline>("WithoutDeadline");
  const [withoutDeadline, setWithoutDeadline] = useState(bounty.deadline === "WithoutDeadline");
  const [deadlineTimeCommitment, setDeadlineTimeCommitment] = useState("");

  const dueDateDefValue = (deadline as DueDate)?.DueDate?.due_date
    ? new Date(formatting.formatToDate((deadline as DueDate).DueDate.due_date))
    : new Date();

  const canEditDueDate = () => {
    return bounty.deadline === "WithoutDeadline" || bounty.deadline === "DueDate";
  };

  const handleSubmitClick = () => {
    handleSubmit(deadline);
  };

  const prepareDeadline = (bounty: Bounty) => {
    let deadline: Deadline = "WithoutDeadline";
    if (bounty.deadline) {
      if (bounty.deadline === "DueDate" && bounty.due_date) {
        deadline = {
          DueDate: {
            due_date: formatting.formatDayToNanoSec(new Date(bounty.due_date)).toString()
          }
        } as Deadline;
      }
      if (bounty.deadline === "MaxDeadline") {
        deadline = {
          MaxDeadline: { max_deadline: bounty.max_deadline }
        } as Deadline;
      }
      if (bounty.deadline === "WithoutDeadline") {
        deadline = "WithoutDeadline";
      }
    }
    return deadline;
  };

  useEffect(() => {
    setDeadline(prepareDeadline(bounty));
    if (bounty.deadline === "WithoutDeadline") {
      setWithoutDeadline(true);
    } else {
      setWithoutDeadline(false);
      if (bounty.deadline === "MaxDeadline" && bounty.max_deadline) {
        const timeCommitments = formatting.formatDurationToHuman(bounty.max_deadline);
        if (typeof timeCommitments !== "number") {
          for (const key in timeCommitments) {
            if (timeCommitments[key] !== 0) {
              setDeadlineTimeCommitment(utils.capitalize(key));
            }
          }
        }
      }
    }
  }, [bounty.deadline]);

  const handleWithoutDeadlineChange = (value: boolean) => {
    if (value) {
      reset({
        deadlineTimeCommitment: undefined,
        deadline: undefined
      });
      setDeadline("WithoutDeadline");
    } else {
      setDeadline(prepareDeadline(bounty));
    }
    setWithoutDeadline(value);
  };

  const handleDueDateChange = (value: Date) => {
    const timeStampInNano = formatting.formatDayToNanoSec(value);
    setDeadline({ DueDate: { due_date: timeStampInNano.toString() } });
  };

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                disabled: loading,
                onClick: () => methods.handleSubmit(handleSubmitClick)(),
                text: !loading ? "Update" : "Submitting..."
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white mb-2.5">Edit deadline</div>
        <div className="my-3.5">
          <div className="text-white">
            <FormRow className="flex items-center">
              <CheckBox
                {...{
                  active: !Boolean(withoutDeadline),
                  onClick: () => handleWithoutDeadlineChange(!Boolean(withoutDeadline)),
                  text: "Specify deadline"
                }}
              />
            </FormRow>
            {!withoutDeadline && canEditDueDate() && (
              <FormRow>
                <input
                  type="hidden"
                  defaultValue={dueDateDefValue.getUTCDate()}
                  {...register("deadline.DueDate.due_date", {
                    required: {
                      message: "Required field",
                      value:
                        !Boolean(withoutDeadline) &&
                        !(deadline as BountyCreate.MaxDeadline)?.MaxDeadline?.max_deadline
                    }
                  })}
                />

                <ValidationLayout
                  {...{
                    validate: () => {
                      return {
                        valid: !errors.deadline,
                        message: errors.deadline?.message,
                        started: isSubmitted
                      };
                    }
                  }}>
                  <DatePicker
                    {...{
                      disabled: Boolean(withoutDeadline),
                      date: dueDateDefValue,
                      onChange: (date: Date) => handleDueDateChange(date)
                    }}
                  />
                </ValidationLayout>
              </FormRow>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditDeadlineForm;
