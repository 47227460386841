import Button, { ButtonStyles } from "./Button";
import { useRef, ChangeEvent, ReactNode } from "react";

interface Props {
  children?: ReactNode | ReactNode[];
  onClick?: (file: File) => void;
  accept?: string;
  customStyles?: ButtonStyles;
}

export default function ChooseFileButton(props: Props) {
  const { children, onClick, accept, customStyles } = props;
  const fileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file && onClick) {
      onClick(file);
    }
  };

  return (
    <div>
      <Button
        {...{
          customStyles: {
            button: "w-full border-none bg-transparent !p-0",
            ...customStyles
          },
          onClick: handleClick
        }}>
        {children ? children : null}
      </Button>
      <input
        type="file"
        accept={accept}
        ref={fileInput}
        onChange={handleFile}
        style={{ display: "none" }}
      />
    </div>
  );
}
