import moment from "moment";
import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { formatting } from "../../../../../common/formatting";
import utils from "../../../../../services/utils";
import { Bounty } from "../../../../../types/Bounty";
import { Claim } from "../../../../../types/Claim";
import { DarkButton } from "../../../../general/Button";
import { Input } from "../../../../general/Input";
import { Area, ValidationLayout } from "../../../../Layout";
import Modal from "../../../../general/Modal";
import { defTimeCommitments } from "../../../../../constants";
import { FormControl } from "../../../../general/Form/FormElements";
import { NewSelect } from "../../../../general/Select";

export type DeadlineFormData = {
  deadline: string;
  deadlineTimeCommitment: string;
};

const ExtendDeadlineForm = (props: {
  show: boolean;
  loading: boolean;
  handleClose: () => void;
  handleSubmit: (...args: any[]) => void;
  bounty: Bounty;
  claim: Claim;
}) => {
  const { show, handleClose, handleSubmit, bounty, loading } = props;
  const methods = useForm<DeadlineFormData>({
    mode: "onChange"
  });
  const { control, register, setValue, formState } = methods;
  const { errors, isSubmitted, isValid } = formState;

  const [deadlineTimeCommitment, setDeadlineTimeCommitment] = useState("");
  const [deadline, setDeadline] = useState("");

  const isCorrectDeadline = useMemo(() => {
    if (bounty.deadline === "WithoutDeadline") {
      return true;
    }
    if (bounty.deadline === "DueDate") {
      const due_date = moment(bounty.due_date).valueOf();
      const curr = moment()
        .add(parseInt(deadline) / 1000000, "ms")
        .valueOf();
      return due_date >= curr;
    }
    if (bounty.deadline === "MaxDeadline") {
      const bountyDeadline = utils.getNumberDeadline(bounty) ?? 0;
      const currentDeadline = deadline ? parseInt(deadline) : 0;
      return bountyDeadline >= currentDeadline;
    }
    return false;
  }, [deadline]);

  const handleMaxDeadlineChange = (value: string) => {
    if (deadlineTimeCommitment) {
      const unit: any = deadlineTimeCommitment.toLowerCase();
      const duration = formatting.formatDurationToNanoSec(value, unit);
      setDeadline(duration.toString());
    }
  };

  const handleTimeCommitmentChange = (selected: { name: string; value: string }) => {
    setValue("deadline", "");
    setDeadlineTimeCommitment(selected.value);
  };

  const handleSubmitClick: SubmitHandler<DeadlineFormData> = async () => {
    if (isValid) {
      handleSubmit(deadline);
    }
  };

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                disabled: loading,
                onClick: () => methods.handleSubmit(handleSubmitClick)(),
                text: !loading ? "Update" : "Submitting..."
              }}
            />
          );
        }
      }}>
      <div className="font-poppins text-white pb-6">
        <div className="text-lg text-white mb-2.5">Extend deadline</div>
        <div className="py-2.5">
          <Area>
            <div className="py-4 flex items-center justify-between text-sm text-neutral-600">
              <div>Bounty deadline</div>
              <div className="text-success">{utils.getBountyDeadline(bounty)}</div>
            </div>
          </Area>
        </div>
        <div className="py-2.5">
          <div className="text-sm text-neutral-600">Set time commitments</div>
          <div className="flex gap-4 items-end">
            <input
              type="hidden"
              defaultValue={deadlineTimeCommitment}
              {...register("deadlineTimeCommitment", {
                required: "Required field"
              })}
            />
            <ValidationLayout
              {...{
                validate: () => {
                  return {
                    valid: !errors.deadlineTimeCommitment,
                    started: isSubmitted,
                    message: errors.deadlineTimeCommitment?.message
                  };
                }
              }}>
              <NewSelect
                {...{
                  placeholder: "Time commitment",
                  selected: {
                    name: deadlineTimeCommitment,
                    value: deadlineTimeCommitment
                  },
                  defaultOptionsList: defTimeCommitments,
                  defaultOption: {
                    name: "",
                    value: ""
                  },
                  onSelect: (selected: { name: string; value: string }) => {
                    setValue("deadlineTimeCommitment", selected.value, {
                      shouldValidate: true
                    });
                    handleTimeCommitmentChange(selected);
                  }
                }}
              />
            </ValidationLayout>
            <FormControl
              name="deadline"
              control={control}
              defaultValue={deadline}
              rules={{
                required: "Required field",
                validate: {
                  nonZero: (value: string) => parseInt(value) > 0 || "Non-zero value",
                  maxDeadline: () => isCorrectDeadline || "Must be less then bounty deadline"
                }
              }}
              render={({ field: { name, value, onChange } }) => {
                return (
                  <Input
                    {...{
                      customStyles: {
                        input: "px-[20px] py-[15px]"
                      },
                      placeholder: "Duration",
                      value: value,
                      name: name,
                      validation: {
                        valid: !errors.deadline,
                        message: errors.deadline?.message,
                        started: isSubmitted
                      },
                      onChange: (value: string) => {
                        onChange(value);
                        setValue("deadline", value, {
                          shouldValidate: true
                        });
                        handleMaxDeadlineChange(value);
                      },
                      disabled: !deadlineTimeCommitment
                    }}
                    {...{ name, value }}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExtendDeadlineForm;
