import useUserAccountWhitelists from "../../../hooks/useUserAccountWhitelists";
import CreateWhitelistAction from "./Actions/CreateWhitelistAction";
import WhitelistItem from "./WhitelistItem";

const Whitelist = () => {
  const { whitelists, loadWhitelists } = useUserAccountWhitelists();

  return (
    <div className="py-2.5">
      <CreateWhitelistAction reload={loadWhitelists} />

      {whitelists && whitelists?.length > 0 ? (
        <div className="grid lg:grid-cols-3 sm:grid-cols-1 grid-flow-row gap-2 w-full">
          {whitelists.map((list) => (
            <WhitelistItem key={list.name} item={list} onReload={loadWhitelists} />
          ))}
        </div>
      ) : (
        <>
          <div className="border border-dashed border-gray-800 rounded-sm p-14 text-white text-center my-4">
            {"Your account whitelists will appear here"}
          </div>
        </>
      )}
    </div>
  );
};

export default Whitelist;
