import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../hooks";
import utils from "../../../services/utils";
import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { DarkButton } from "../../general/Button";
import { UpdateBalanceForm } from "../Dialogues";

export type PaymentFormData = {
  selectedTokenAccount?: { name: string; value: string };
  payment: string;
};

const SendMoneyAction = (props: { claim?: Claim; bounty: Bounty }) => {
  const methods = useForm<PaymentFormData>();
  const { bounty } = props;
  const [showForm, setShowForm] = useState(false);
  const tokens = useStoreState((state) => state.app.tokens);
  const bounty_contract_id = useStoreState((state) => state.app.config?.bounty_contract_id);
  const ftTransferCallV2 = useStoreActions((actions) => actions.nearApi.ftTransferCallV2);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const getTokenDecimals = (tokenId: string) => {
    return tokens.find((token) => token.tokenId === tokenId)?.decimals || 0;
  };

  const handleSubmitForm: SubmitHandler<PaymentFormData> = async (formData: PaymentFormData) => {
    setShowForm(false);
    const formattedAmount = utils.getParsedTokenAmount(
      formData.payment,
      getTokenDecimals(bounty?.token ?? "")
    );
    if (bounty_contract_id) {
      await ftTransferCallV2({
        args: {
          receiver_id: bounty_contract_id,
          amount: formattedAmount.toString(),
          msg: bounty.id.toString()
        },
        tokenId: bounty?.token ?? ""
      });
    } else {
      console.log("bounty_contract_id is missing.");
    }
  };

  return (
    <>
      <DarkButton
        {...{
          customStyles: {
            button: "bg-transparent text-primary-900"
          },
          text: "Send money",
          onClick: () => handleButtonClick()
        }}
      />
      <FormProvider {...methods}>
        <UpdateBalanceForm
          bounty={bounty}
          show={showForm}
          handleClose={handleButtonClick}
          handleSubmit={methods.handleSubmit(handleSubmitForm)}
        />
      </FormProvider>
    </>
  );
};

export default SendMoneyAction;
