import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import { DarkButton } from "../../../../../general/Button";
import { FormControl, FormRow } from "../../../../../general/Form/FormElements";
import { Input } from "../../../../../general/Input";
import { LinkIcon } from "../../../../../general/SVGIcon";
import { DetailsFormData } from "../../Details";

const ImportFromGithub = () => {
  const methods = useFormContext<DetailsFormData>();
  const { trigger, control, formState, setValue } = methods;
  const { errors, isSubmitted } = formState;
  const state = useStoreState((state) => state);
  const actions = useStoreActions((actions) => actions);

  const {
    newBountyForm: { importGithubLink },
    newBountyForm: { setGithubLink },
    newBounty: {
      metadata: { setTitle, setDescription }
    }
  } = actions;

  const {
    newBountyForm: { github_link }
  } = state;

  const handleGitHubLinkChange = (value: string) => {
    setGithubLink(value);
  };

  const handleGitHubLinkImport = async (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    const triggered = await trigger("github_link");
    if (triggered) {
      const importParams = await importGithubLink(github_link);
      if (importParams) {
        setTitle(importParams.title);
        setDescription(importParams.description);
        setValue("title", importParams.title, { shouldValidate: true });
        setValue("description", importParams.description, {
          shouldValidate: true
        });
      }
    }
  };
  return (
    <FormRow className="flex items-end">
      <FormControl
        name="github_link"
        defaultValue={github_link}
        control={control}
        rules={{
          pattern: {
            value: new RegExp("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"),
            message: "invalid url"
          }
        }}
        render={({ field: { name, value, onChange } }) => {
          return (
            <Input
              {...{
                customStyles: {
                  input: "px-[20px] py-[15px]"
                },
                placeholder: "Github bounty url",
                name: name,
                label: { text: "Import Github issue" },
                value: value,
                svgIcon: <LinkIcon className="mx-1" />,
                validation: {
                  valid: !errors.github_link,
                  message: errors.github_link?.message,
                  started: isSubmitted
                },
                onChange: (value: string) => {
                  onChange(value);
                  handleGitHubLinkChange(value);
                }
              }}
              {...{ name, value }}
            />
          );
        }}
      />
      <div className="px-4 pt-4">
        <DarkButton
          {...{
            customStyles: {
              text: classNames({
                "text-white": github_link
              }),
              button: "border-neutral-700 py-[0.76em] hover:shadow-white"
            },
            disabled: !github_link,
            text: "Import",
            onClick: (e: React.MouseEvent<Element, MouseEvent>) => handleGitHubLinkImport(e)
          }}
        />
      </div>
    </FormRow>
  );
};

export default ImportFromGithub;
