import { useState } from "react";
import { useStoreActions } from "../../../../hooks";
import IconButton from "../../../general/Button/IconButton";
import { EditIcon } from "../../../general/SVGIcon";
import ViewWhitelistForm from "../Dialogues/ViewWhitelistForm";

const EditWhitelistAction = (props: { id: string | number; onReload: () => Promise<void> }) => {
  const { id, onReload: reload } = props;
  const [showForm, setShowForm] = useState(false);
  const updateAccountWhitelist = useStoreActions(
    (actions) => actions.accountWhitelists.updateAccountWhitelist
  );
  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleWhitelistSubmit = async ({ name, accounts }: { name: string; accounts: string }) => {
    setShowForm(false);
    await updateAccountWhitelist({ id, name, accounts });
    await reload();
  };

  return (
    <div>
      <IconButton
        {...{
          icon: <EditIcon className="stroke-neutral-200" />,
          onClick: () => handleButtonClick()
        }}
      />
      <ViewWhitelistForm
        id={id}
        show={showForm}
        handleClose={handleButtonClick}
        handleSubmit={handleWhitelistSubmit}
        mode={"edit"}
      />
    </div>
  );
};

export default EditWhitelistAction;
