import { CompanyMetadata, IndividualMetadata, PublicProfile } from "../../../types/Auth";
import { Claim, ClaimAdvancedStatus, ClaimStatus } from "../../../types/Claim";

const getStatusText = (status: ClaimStatus | ClaimAdvancedStatus) => {
  const text: { [key in ClaimStatus | ClaimAdvancedStatus]?: () => string } & {
    default: () => string;
  } = {
    NotHired: () => "Declined",
    CompetitionLost: () => "Lost",
    default: () => status
  };
  return (text[status] || text["default"])();
};

const showCountdown = (claim: Claim) => {
  return claim.status === "InProgress" || claim.advanced_status === "InProgress" ? true : false;
};

const showTask = (claim: Claim) => {
  return (claim?.slot ?? -1) >= 0;
};

const getUserDisplayName = (ownerDetails: PublicProfile<CompanyMetadata | IndividualMetadata>) => {
  if (ownerDetails.type === "Individual") {
    return (ownerDetails as IndividualMetadata).individual.name ?? ownerDetails.username;
  }
  if (ownerDetails.type === "Company") {
    return (ownerDetails as CompanyMetadata).company.companyName ?? ownerDetails.username;
  }
  return ownerDetails.username;
};

export { showCountdown, getStatusText, showTask, getUserDisplayName };
