import { useState } from "react";
import { useStoreActions } from "../../../../hooks";
import Api from "../../../../services/Api";
import CreateWhitelistForm from "../Dialogues/CreateWhitelistForm";

const CreateWhitelistAction = (props: { reload: () => void; whitelist?: string }) => {
  const { reload, whitelist } = props;
  const [showForm, setShowForm] = useState(false);
  const addAccountWhitelist = useStoreActions(
    (actions) => actions.accountWhitelists.addAccountWhitelist
  );
  const setError = useStoreActions((actions) => actions.error.setError);
  const handleButtonClick = async () => {
    setShowForm(!showForm);
  };

  const checkWhitelist = async (accounts: string) => {
    const response = await Api.checkAccountWhitelist(accounts);
    if (response?.error) {
      setError(response?.message ?? "Error whitelist validation");
      return false;
    }
    return true;
  };

  const handleWhitelistSubmit = async ({ name, accounts }: { name: string; accounts: string }) => {
    const validWhitelist = await checkWhitelist(accounts);
    if (validWhitelist) {
      setShowForm(false);
      await addAccountWhitelist({ name, accounts });
      reload();
    }
  };

  return (
    <div>
      <button
        className="underline outline-none text-primary-900"
        onClick={() => handleButtonClick()}>
        Create one
      </button>
      {showForm && (
        <CreateWhitelistForm
          show={showForm}
          handleClose={handleButtonClick}
          handleSubmit={handleWhitelistSubmit}
          whitelist={whitelist}
        />
      )}
    </div>
  );
};

export default CreateWhitelistAction;
