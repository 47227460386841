import { useState } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import { DEFAULT_STYLES } from "../../../helpers/Validation";
import { DarkButton } from "../../general/Button";
import { Input } from "../../general/Input";
import { Area, SignFormContainerLayout, ValidationLayout } from "../../Layout";

interface ResetPasswordProps {
  onSubmit: (value: ResetPasswordFormData) => void;
  searchParams: URLSearchParams;
  submitDisabled?: boolean;
  errorMessage?: string;
  successMessage?: boolean;
}

type ResetPasswordFormData = {
  token: string;
  id: string;
  password: string;
};

const ResetPasswordForm = (props: ResetPasswordProps) => {
  const navigate = useNavigate();
  const { onSubmit, searchParams, errorMessage, successMessage } = props;
  const methods = useFormContext<ResetPasswordFormData>();
  const { handleSubmit, register, formState, setValue } = methods;
  const { isValid, isSubmitted, errors } = formState;
  const [password, setPassword] = useState("");

  const handleSubmitClick: SubmitHandler<ResetPasswordFormData> = (form) => {
    if (isValid) {
      onSubmit(form);
    }
  };

  return (
    <SignFormContainerLayout
      {...{
        title: "Reset password"
      }}>
      <div className="flex flex-col gap-5 pb-10">
        <form className="w-full">
          <Area
            customStyles={{
              contentContainer: "px-[1rem] py-[2rem] bg-opacity-50"
            }}>
            {!successMessage ? (
              <div className="flex flex-col gap-[1rem]">
                <ValidationLayout
                  {...{
                    validate: () => {
                      return {
                        valid: !errors.password,
                        started: isSubmitted,
                        message: errors.password?.message
                      };
                    },
                    label: { text: password.length > 0 ? "New Password" : "" }
                  }}>
                  <input
                    type="hidden"
                    {...register("password", {
                      required: "Can't be empty"
                    })}
                  />
                  <input
                    type="hidden"
                    {...register("token", {
                      required: "Can't be empty"
                    })}
                    defaultValue={searchParams.get("token") ?? ""}
                  />
                  <input
                    type="hidden"
                    {...register("id", {
                      required: "Can't be empty"
                    })}
                    defaultValue={searchParams.get("id") ?? ""}
                  />
                  <Input
                    {...{
                      type: "password",
                      placeholder: "New password",
                      value: password,
                      onChange: (value: string) => {
                        setValue("password", value, { shouldValidate: true });
                        setPassword(value);
                      }
                    }}
                  />
                </ValidationLayout>
              </div>
            ) : (
              <div className="font-poppins text-neutral-200">
                <h2 className="text-xl pb-2">Congratulations!</h2>
                <p className="text-md">Your password has been changed successfully.</p>
              </div>
            )}
          </Area>
        </form>
        <p className={`min-h-[1em] text-base ${DEFAULT_STYLES.invalid.message}`}>
          {errorMessage ? errorMessage : ""}
        </p>
        <div className="flex flex-row justify-center items-center gap-5">
          {!successMessage ? (
            <DarkButton
              {...{
                text: "Reset",
                onClick: () => handleSubmit(handleSubmitClick)(),
                disabled: !!errors.password
              }}
            />
          ) : (
            <DarkButton
              {...{
                text: "Sign in",
                onClick: () => navigate("/sign-in")
              }}
            />
          )}
        </div>
      </div>
    </SignFormContainerLayout>
  );
};

export default ResetPasswordForm;
