import { BountyTransaction, TransactionMethod } from "../../../types/BountyTransaction";
import utils from "../../../services/utils";

const getHunterMethodDescription = (tx: BountyTransaction) => {
  const { methodName, args } = tx;
  const methodsDescription: { [key in TransactionMethod]: () => string } & {
    default: () => "Unknown method";
  } = {
    ft_transfer_call: () => "Bounty created",
    bounty_create: () => "Bounty created",
    bounty_update: () => {
      const parsedArgs = JSON.parse(args);
      const updated = parsedArgs.bounty_update;
      const { deadline, metadata } = updated || {};

      return deadline
        ? `Duration updated to ${utils.getDeadline(deadline)}`
        : metadata
        ? "Updated bounty metadata"
        : "Bounty updated";
    },
    bounty_claim: () => `Bounty claimed`,
    accept_claimant: () =>
      `${
        tx.affiliateUserFullName
          ? tx.affiliateUserFullName
          : tx.affiliateUserName
          ? tx.affiliateUserName
          : "claimer"
      } is accepted by the bounty owner`,
    decline_claimant: () =>
      `${
        tx.affiliateUserFullName
          ? tx.affiliateUserFullName
          : tx.affiliateUserName
          ? tx.affiliateUserName
          : "claimer"
      } is rejected by the bounty owner`,
    /** @deprecated */
    decision_on_claim: () => {
      const parsedArgs = JSON.parse(args);
      const approve = parsedArgs.approve;
      return approve === true
        ? `${
            tx.affiliateUserFullName
              ? tx.affiliateUserFullName
              : tx.affiliateUserName
              ? tx.affiliateUserName
              : "claimer"
          } is accepted by the bounty owner`
        : `${
            tx.affiliateUserFullName
              ? tx.affiliateUserFullName
              : tx.affiliateUserName
              ? tx.affiliateUserName
              : "claimer"
          } is rejected by the bounty owner`;
    },
    bounty_done: () => `Bounty solved`,
    bounty_give_up: () =>
      `${tx.userFullName ? tx.userFullName : tx.userName} has given up the bounty`,
    mark_as_paid: () => "Bounty paid",
    confirm_payment: () => "Payment confirmed",
    bounty_approve: () =>
      `${
        tx.affiliateUserFullName
          ? tx.affiliateUserFullName
          : tx.affiliateUserName
          ? tx.affiliateUserName
          : "claimer"
      }'s work has been approved - Fund released`,
    bounty_reject: () =>
      `${
        tx.affiliateUserFullName
          ? tx.affiliateUserFullName
          : tx.affiliateUserName
          ? tx.affiliateUserName
          : "claimer"
      }'s work has been rejected`,
    /** @deprecated */
    bounty_action: () => {
      const parsedArgs: {
        action: { ClaimApproved?: object } & { ClaimRejected?: object } & {
          Finalize?: object;
        } & "bountyApproveOfSeveral";
      } = JSON.parse(args);
      const { action } = parsedArgs;

      return action?.ClaimApproved
        ? `${
            tx.affiliateUserFullName
              ? tx.affiliateUserFullName
              : tx.affiliateUserName
              ? tx.affiliateUserName
              : "claimer"
          }'s work has been approved - Fund released`
        : action?.ClaimRejected
        ? `${
            tx.affiliateUserFullName
              ? tx.affiliateUserFullName
              : tx.affiliateUserName
              ? tx.affiliateUserName
              : "claimer"
          }'s work has been rejected`
        : action?.Finalize
        ? "claim finalized"
        : action === "bountyApproveOfSeveral"
        ? `All works has been approved - Fund released`
        : "Unknown method";
    },
    start_competition: () => "Work started!",
    withdraw: () =>
      `${
        tx?.affiliateUserFullName
          ? tx.affiliateUserFullName
          : tx.affiliateUserName
          ? tx.affiliateUserName
          : "claimer"
      }  withdraw funds.`,
    bounty_cancel: () => "Bounty canceled.",
    extend_claim_deadline: () => "Deadline has been extended.",
    default: () => "Unknown method"
  };
  return (methodsDescription[methodName] || methodsDescription["default"])();
};

export { getHunterMethodDescription };
