import { useState, useContext, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import {
  HeroSection,
  HowSection,
  BountySection,
  BenefitsSection,
  WhySection,
  ContributersSection
} from "../../components/Section";
import { ToggleButton } from "../../components/general/Button";
import { Layout } from "../../components/Layout";
import { MixpanelContext } from "../App";
import { DEV_MODE } from "../../constants";

const toggleSections = [
  {
    toggleButtonText: "find project",
    section: <HowSection />
  },
  {
    toggleButtonText: "find talent",
    section: <BountySection />
  }
];

const toggleAfterNumbersSections = [
  {
    section: <BenefitsSection />
  },
  {
    section: <WhySection />
  }
];

function Home() {
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const context = useContext(MixpanelContext);
  mixpanel.init(context.token, {
    debug: false
  });

  useEffect(() => {
    if (!DEV_MODE) {
      mixpanel.track("View Home page", {
        source: window.location.hostname
      });
    }
  }, []);

  const handleToggle = (index: number) => {
    setSelectedSectionIndex(index);
  };

  const toggleButton = (
    <ToggleButton
      {...{
        items: toggleSections.map((hs) => hs.toggleButtonText),
        onIndex: selectedSectionIndex,
        handleToggle
      }}
    />
  );

  return (
    <Layout>
      <HeroSection
        {...{
          toggleButton,
          bgImageStyle: "hero-section-bg-image",
          textBgStyle: ""
        }}
      />
      {toggleSections[selectedSectionIndex].section}
      {toggleAfterNumbersSections[selectedSectionIndex].section}
      <ContributersSection />
    </Layout>
  );
}

export default Home;
