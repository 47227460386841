import classNames from "classnames";
import { twMerge } from "tailwind-merge";

const WarningIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(classNames("w-6 h-6", className))}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.90118 3.00305C11.0562 1.00305 13.9442 1.00305 15.0982 3.00305L22.4532 15.7511C23.6072 17.7511 22.1632 20.2511 19.8542 20.2511H5.14518C2.83618 20.2511 1.39318 17.7511 2.54718 15.7511L9.90018 3.00305H9.90118ZM12.5002 8.25005C12.6991 8.25005 12.8899 8.32907 13.0305 8.46972C13.1712 8.61037 13.2502 8.80114 13.2502 9.00005V12.7501C13.2502 12.949 13.1712 13.1397 13.0305 13.2804C12.8899 13.421 12.6991 13.5001 12.5002 13.5001C12.3013 13.5001 12.1105 13.421 11.9698 13.2804C11.8292 13.1397 11.7502 12.949 11.7502 12.7501V9.00005C11.7502 8.80114 11.8292 8.61037 11.9698 8.46972C12.1105 8.32907 12.3013 8.25005 12.5002 8.25005ZM12.5002 16.5001C12.6991 16.5001 12.8899 16.421 13.0305 16.2804C13.1712 16.1397 13.2502 15.949 13.2502 15.7501C13.2502 15.5511 13.1712 15.3604 13.0305 15.2197C12.8899 15.0791 12.6991 15.0001 12.5002 15.0001C12.3013 15.0001 12.1105 15.0791 11.9698 15.2197C11.8292 15.3604 11.7502 15.5511 11.7502 15.7501C11.7502 15.949 11.8292 16.1397 11.9698 16.2804C12.1105 16.421 12.3013 16.5001 12.5002 16.5001Z"
        fill="fill-inherit"
        fillOpacity="0.8"
      />
    </svg>
  );
};
export default WarningIcon;
