import { useInfiniteQuery } from "@tanstack/react-query";
import Api from "../services/Api";
import { Bounty } from "../types/Bounty";
import { FilterParams } from "../types/BountyList";

export type Response = {
  has_more: boolean;
  has_prev: boolean;
  item_count: number;
  page: number;
  page_count: number;
  bounties: Bounty[];
};

const useInfiniteBounties = (props: {
  limit?: number;
  accountId?: string;
  filters?: FilterParams;
}) => {
  const { filters, accountId, limit = 5 } = props;
  const fetchData = Api.getBounties;
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isInitialLoading,
    isFetching,
    isFetchingNextPage,
    status
  } = useInfiniteQuery(
    ["items", filters, limit],
    ({ pageParam = 1 }) => {
      return fetchData({
        page: pageParam,
        limit,
        accountId,
        filters
      }).then((res: Response) => {
        return res;
      });
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.page < lastPage.page_count ? lastPage.page + 1 : undefined;
        return nextPage;
      }
    }
  );

  return {
    data,
    error,
    fetchNextPage,
    status,
    hasNextPage,
    isFetching,
    isInitialLoading,
    isFetchingNextPage
  };
};

export default useInfiniteBounties;
