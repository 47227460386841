import { LightButton } from "../../../../general/Button";
import SuccessSteps from "./SuccessSteps";

const ReceiptMessage = (props: {
  status: string | null;
  txHash: string;
  handleCloseModal: () => void;
}) => {
  const { status, txHash, handleCloseModal } = props;

  switch (status) {
    case "CheckReceipt":
      return (
        <div className="font-poppins flex-1 flex flex-col items-center justify-center text-white">
          <p className="text-lg md-2 text-center">Wait while the receipt is being checked.</p>
        </div>
      );
    case "Failure":
      return (
        <div className="font-poppins flex-1 flex flex-col justify-between text-white">
          <div>
            <span className="text-lg md-2">Receipt: Status `Failure`.</span>
            <p className=" break-words">
              View the transaction for more details:{" "}
              <a
                className="underline text-gray-700"
                href={`https://explorer.testnet.near.org/transactions/${txHash}`}
                target="_blank"
                rel="noreferrer noopener">
                {`https://explorer.testnet.near.org/transactions/${txHash}`}
              </a>
            </p>
          </div>
          <div className="flex gap-2  justify-center">
            <LightButton
              {...{
                text: "Close",
                onClick: () => handleCloseModal()
              }}
            />
          </div>
        </div>
      );
    case "Success":
      return (
        <div className="font-poppins flex-1 flex ite flex-col justify-between text-neutral-50 max-w-[315px] m-auto">
          <div className="flex-1 flex justify-center mb-[4rem]">
            <div className="flex flex-col">
              <h2 className="text-lg text-center text-primary-800 max-w-[315px] my-2.5">
                Application submitted successfully!
              </h2>
              <div className="text-center mt-2.5">
                <h2 className="mb-6">Next steps</h2>
                <div className="my-2.5">
                  <SuccessSteps />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <LightButton
              {...{
                customStyles: { button: "max-w-[156px]" },
                text: "Close",
                onClick: () => handleCloseModal()
              }}
            />
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default ReceiptMessage;
