import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { ClaimStatus } from "../../../types/Claim";
import { ReactNode } from "react";

const Amount = (props: {
  symbol: string;
  amount: string;
  className?: string;
  claimStatus?: ClaimStatus;
  icon?: ReactNode;
  additionalText?: string;
}) => {
  const { symbol, amount, claimStatus, className, icon, additionalText } = props;

  return (
    <span
      className={twMerge(
        classNames(
          "p-1 bg-green-700 text-sm text-success rounded-md",
          {
            "bg-secondary-800 text-secondary-900":
              claimStatus && ["Rejected", "NotHired"].includes(claimStatus)
          },
          className
        )
      )}>
      {icon && icon} {amount} {symbol} {additionalText && additionalText}
    </span>
  );
};

export default Amount;
