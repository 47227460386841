import classNames from "classnames";

const OpenIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
      <svg
          className={classNames("stroke-neutral-200", className)}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M13.5 6.47479H5.25C4.65326 6.47479 4.08097 6.71185 3.65901 7.1338C3.23705 7.55576 3 8.12806 3 8.72479V19.2248C3 19.8215 3.23705 20.3938 3.65901 20.8158C4.08097 21.2377 4.65326 21.4748 5.25 21.4748H15.75C16.3467 21.4748 16.919 21.2377 17.341 20.8158C17.7629 20.3938 18 19.8215 18 19.2248V10.9748M7.5 16.9748L21 3.47479M21 3.47479H15.75M21 3.47479V8.72479"
            stroke="#FFCF14"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
      </svg>
  );
};

export default OpenIcon;
