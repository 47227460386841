import IconButton from "../../../../../general/Button/IconButton";
import { FormRow } from "../../../../../general/Form/FormElements";
import { InfoIcon } from "../../../../../general/SVGIcon";
import ImportFromGithub from "./ImportFromGithub";

const ImportBounty = () => {
  return (
    <>
      <FormRow>
        <FormRow className="flex items-center mb-2">
          <h2>Import Bounty</h2>
          <div className="ml-2">
            <div className="group relative flex justify-center w-fit">
              <IconButton
                {...{
                  icon: <InfoIcon className="w-4 h-4" />
                }}
              />
              <span className="absolute bottom-5 w-max scale-0 rounded-2xl transition-all bg-neutral-800 border border-neutral-700 py-2 px-4 text-xs text-white group-hover:scale-100">
                Import information directly from Github!
                <br /> Note: Github issues need to be in a public repository
              </span>
            </div>
          </div>
        </FormRow>
        <ImportFromGithub />
      </FormRow>
    </>
  );
};

export default ImportBounty;
