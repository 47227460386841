import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Section } from "../../components/Layout";
import { resendCodeToEmail } from "../../services/newAuth";
import VerifyCodeForm from "../../components/Form/VerifyCodeForm";
import { useStoreState, useStoreActions } from "../../hooks";
import { CODE_TYPE } from "../../constants";

function VerifyCode() {
  const navigate = useNavigate();
  const { verifyCodeLoading, verifyCodeError, isVerified } = useStoreState((state) => state.auth);
  const { verifyCodeAsync } = useStoreActions((actions) => actions.auth);
  const [resendCodeMessage, setResentCodeMessage] = useState<{
    error: boolean;
    message: string;
  } | null>(null);

  const onResend = async () => {
    const result = await resendCodeToEmail();
    if (result.success) {
      setResentCodeMessage({
        error: false,
        message: "a code has been send to insert e-mail"
      });
    } else if (result.failure) {
      setResentCodeMessage({
        error: true,
        message: result.failure?.response?.data?.message ?? "Error resend code."
      });
    }
  };

  const onSubmit = async (code: number) => {
    await verifyCodeAsync({ type: CODE_TYPE, code });
  };

  useEffect(() => {
    if (isVerified) {
      navigate("/profile/edit", { replace: true });
    }
  }, [isVerified, navigate]);

  return (
    <Layout>
      <div className="p-3" />
      <Section
        {...{
          id: "verify-code_section"
        }}>
        <VerifyCodeForm
          {...{
            onSubmit,
            onResend,
            submitDisabled: verifyCodeLoading,
            errorMessage: verifyCodeError,
            resendCodeMessage: resendCodeMessage,
            clearResendCodeMessage: setResentCodeMessage
          }}
        />
      </Section>
    </Layout>
  );
}

export default VerifyCode;
