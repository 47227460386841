import useInfiniteBounties from "../../hooks/useInfiniteBounties";
import { FilterParams } from "../../types/BountyList";
import { ClaimStatus } from "../../types/Claim";
import { Bounty } from "../../types/Bounty";
import { BountyStatus, bountyStatus, claimStatuses, DEV_MODE } from "../../constants";
import BountyListItem from "../../components/Bounty/BountyListItem";
import { useEffect, useState } from "react";
import useNextActions, { NextActionExpected } from "../../hooks/useNextActions";
import useWalletAccountId from "../../hooks/useWalletAccountId";
import StatusGroup from "../../components/Bounty/StatusGroup";
import { Response } from "../../hooks/useInfiniteBounties";
import { RedCircleIcon } from "../../components/general/SVGIcon";
import mixpanel from "mixpanel-browser";
import { StringParam, useQueryParam } from "use-query-params";

const MyBounties = () => {
  const { walletAccountId } = useWalletAccountId();

  const { data: nextActions } = useNextActions({});
  const { nextActionExpected } = (nextActions as NextActionExpected) || {};

  const detailsOptions = [
    {
      name: "posted",
      title: "Posted",
      alert: nextActionExpected?.forBounties ?? false
    },
    {
      name: "claimed",
      title: "Claimed",
      alert: nextActionExpected?.forClaims ?? false
    }
  ];

  const [text] = useQueryParam("text", StringParam);

  const filters: FilterParams = {
    claimers: [{ name: walletAccountId, value: walletAccountId }],
    owner: walletAccountId,
    accountId: walletAccountId,
    text: text ?? undefined,
    reviewers: walletAccountId
  };

  const { data, error, status } = useInfiniteBounties({
    filters,
    limit: -1,
    accountId: walletAccountId
  });

  const needBountyAction = (bounty: Bounty) => {
    const bountyActions = [
      "decisionOnClaim",
      "bountyActionClaimApproved",
      "finalizeBounty",
      "startCompetition",
      "markAsPaid",
      "severalClaimsApproved",
      "bountyActionClaimRejected"
    ];
    const actions = bounty.pendingActions?.find((item) => item.accountId === walletAccountId);
    const isNextActions =
      (actions?.nextActions &&
        Object.keys(actions.nextActions).some((key) => bountyActions.indexOf(key) >= 0)) ??
      false;
    return isNextActions;
  };

  const needClaimAction = (bounty: Bounty) => {
    //  const claimActions = ["bountyDone", "isClaimExpired", "finalizeClaim", "bountyWithdraw"];
    const actions = bounty.pendingActions?.find((item) => item.accountId === walletAccountId)
      ?.forClaims;
    const isNextActions = (actions && actions?.length > 0) ?? false;
    return isNextActions;
  };

  const [switchOptions, setSwitchOptions] = useState(
    detailsOptions.find((option) => option.name === "posted")
  );

  const handleToggleOptions = (
    e: React.MouseEvent,
    value: { name: string; title: string; alert: boolean }
  ) => {
    e.preventDefault();
    setSwitchOptions(value);
  };

  useEffect(() => {
    if (!DEV_MODE) {
      mixpanel.track(`My ${switchOptions?.title}`, {
        source: window.location.hostname
      });
    }
  }, [switchOptions]);

  function filterClaimsByStatus(array: Bounty[], status: ClaimStatus) {
    return array.filter((bounty: Bounty) => {
      return bounty.claims.some((claim) => {
        return claim.status === status && claim.owner === walletAccountId;
      });
    });
  }

  function filterBountiesByStatus(array: Bounty[], status: BountyStatus) {
    return array.filter((bounty: Bounty) => {
      return bounty.status === status && bounty.owner === walletAccountId;
    });
  }

  if (status === "error") return <h4>Ups!, {`${error}`}</h4>;

  return (
    <div className="font-poppins">
      <h1 className=" text-3xl"> Your bounties</h1>
      <div className="font-poppins flex gap-6 py-2.5 justify-start">
        {detailsOptions.map((toggle) => (
          <div key={toggle.name} className="relative flex gap-2">
            {toggle.name === switchOptions?.name ? (
              <>
                <button
                  className="py-2 focus-visible:outline-none text-md text-primary-900 border-b-4 border-primary-900"
                  onClick={(e: React.MouseEvent) => handleToggleOptions(e, toggle)}>
                  {toggle.title}
                </button>
                {toggle.alert ? <RedCircleIcon /> : null}
              </>
            ) : (
              <>
                <button
                  className="py-2 focus-visible:outline-none text-md text-white"
                  onClick={(e: React.MouseEvent) => handleToggleOptions(e, toggle)}>
                  {toggle.title}
                </button>
                {toggle.alert ? <RedCircleIcon /> : null}
              </>
            )}
          </div>
        ))}
      </div>
      {status === "loading" ? (
        <div className="flex items-center justify-center py-2.5">
          <span className="dot-pulse" />
        </div>
      ) : (
        <>
          {switchOptions?.name === "posted" && (
            <div>
              {walletAccountId && (
                <>
                  {data?.pages[0] && data?.pages[0].bounties.length > 0 ? (
                    <>
                      {(Object.keys(bountyStatus) as Array<keyof typeof bountyStatus>).map(
                        (status: BountyStatus) => (
                          <>
                            {data?.pages.map((group: Response) => (
                              <>
                                <StatusGroup
                                  status={status}
                                  length={filterBountiesByStatus(group.bounties, status).length}
                                />
                                {filterBountiesByStatus(group.bounties, status).map(
                                  (bounty: Bounty, index: number) => (
                                    <>
                                      <BountyListItem
                                        key={`bounty-${index}`}
                                        bounty={bounty}
                                        needAction={needBountyAction(bounty)}
                                        showMark={false}
                                      />
                                    </>
                                  )
                                )}
                              </>
                            ))}
                          </>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <div className="border border-dashed border-gray-800 rounded-sm p-14 text-white text-center my-4">
                        {"Your bounties will appear here"}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {switchOptions?.name === "claimed" && (
            <div>
              {walletAccountId && (
                <>
                  {data?.pages[0] && data?.pages[0].bounties.length > 0 ? (
                    <>
                      {(Object.keys(claimStatuses) as Array<ClaimStatus>).map(
                        (status: ClaimStatus) => (
                          <>
                            {data?.pages.map((group: Response) => (
                              <>
                                <StatusGroup
                                  status={status}
                                  length={filterClaimsByStatus(group.bounties, status).length}
                                />
                                {filterClaimsByStatus(group.bounties, status).map(
                                  (bounty: Bounty, index: number) => (
                                    <>
                                      <BountyListItem
                                        key={`bounty-${index}`}
                                        bounty={bounty}
                                        claimStatus={status}
                                        needAction={needClaimAction(bounty)}
                                        showMark={false}
                                      />
                                    </>
                                  )
                                )}
                              </>
                            ))}
                          </>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <div className="border border-dashed border-gray-800 rounded-sm p-14 text-white text-center my-4">
                        {"Your claims will appear here"}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MyBounties;
