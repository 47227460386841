import classNames from "classnames";
import { AccountWhitelist } from "../../../../types";
import { Area } from "../../../Layout";
import Separator from "../../../general/Separator";
import DeleteWhitelistAction from "../Actions/DeleteWhitelistAction";
import EditWhitelistAction from "../Actions/EditWhitelistAction";
import ViewWhitelistAction from "../Actions/ViewWhitelistAction";
import { ListIcon } from "../../../general/SVGIcon";

const WhitelistItem = (props: {
  item: AccountWhitelist.AccountWhitelist;
  onReload: () => Promise<void>;
}) => {
  const { item, onReload: reload } = props;

  return item ? (
    <div className="mt-4 flex w-full max-w-[464px]" key={item.id}>
      <Area
        {...{
          customStyles: {
            borderContainer: classNames("border border-sm border-neutral-700")
          }
        }}>
        <div className="flex flex-col font-poppins">
          <div className="py-4 flex items-center flex-1">
            <ListIcon className="stroke-neutral-200 mx-4" />
            <div className="mx-4">
              <div className="py-1 text-ellipsis overflow-hidden">
                <span className="mb-2">{item.name}</span>
              </div>
            </div>
          </div>
          <div className="">
            <Separator />
            <div className="flex flex-wrap items-center justify-end gap-4 py-2 px-4">
              <ViewWhitelistAction id={item.id} />
              <EditWhitelistAction id={item.id} onReload={reload} />
              <DeleteWhitelistAction id={item.id} onReload={reload} />
            </div>
          </div>
        </div>
      </Area>
    </div>
  ) : null;
};

export default WhitelistItem;
