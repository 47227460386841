import { useState } from "react";
import { useStoreActions } from "../../../hooks";
import { Claim } from "../../../types/Claim";
import { Bounty } from "../../../types/Bounty";
import ActionButton from "./general/ActionButton";
import ApproveSelectedBountyForm from "../Dialogues/ApproveBountyForm/ApproveSelectedBountyForm";
import MaxAllowanceMessage from "../Dialogues/MaxAllowanceMessage";

const ApproveSelectedAction = (props: { claim: Claim[]; bounty: Bounty; text?: string }) => {
  const { claim, bounty, text = "Approve" } = props;
  const [showForm, setShowForm] = useState(false);
  const [showMaxAllowance, setShowMaxAllowance] = useState(false);
  const setBountyApprove = useStoreActions((actions) => actions.nearApi.setBountyApprove);

  const handleButtonClick = () => {
    if (claim.length <= 20) {
      setShowForm(!showForm);
    } else {
      setShowMaxAllowance(true);
    }
  };

  const handleCloseMaxAllowance = () => {
    setShowMaxAllowance(!showMaxAllowance);
  };

  const handleSubmitForm = async () => {
    await setBountyApprove(
      claim.map((item) => {
        return {
          id: bounty.id,
          token: bounty.token,
          receiver_id: item.owner,
          claim_number: item.claim_number
        };
      })
    );
  };

  return (
    <>
      <ActionButton disabled={false} text={text} handleClick={handleButtonClick} />
      <ApproveSelectedBountyForm
        show={showForm}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
      <MaxAllowanceMessage
        show={showMaxAllowance}
        handleClose={handleCloseMaxAllowance}
        count={"20"}
      />
    </>
  );
};

export default ApproveSelectedAction;
