import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../../../../../hooks";
import { OneForAll } from "../../../../../../../types/BountyCreate";
import { ValidationLayout } from "../../../../../../Layout";
import { Input } from "../../../../../../general/Input";

type FormData = {
  multitasking: {
    min_slots_to_start: number | null;
  };
};

const MinSlotsToStart = () => {
  const methods = useFormContext<FormData>();
  const { register, formState, setValue } = methods;
  const { errors, isSubmitted } = formState;
  const min_slots_to_start = useStoreState(
    (state) => (state.newBounty.multitasking as OneForAll)?.OneForAll.min_slots_to_start
  );

  const defValue = useMemo(() => {
    return !min_slots_to_start || min_slots_to_start < 0 ? null : min_slots_to_start;
  }, [min_slots_to_start]);

  const setMinSlotsToStart = useStoreActions((actions) => actions.newBounty.setMinSlotsToStart);

  const handleChange = (value: string) => {
    if (!isNaN(parseInt(value)) && parseInt(value) > 0) {
      setMinSlotsToStart(parseInt(value));
      setValue("multitasking.min_slots_to_start", parseInt(value), { shouldValidate: true });
    } else {
      setMinSlotsToStart(null);
      setValue("multitasking.min_slots_to_start", null, { shouldValidate: true });
    }
  };

  return (
    <ValidationLayout
      {...{
        validate: () => {
          return {
            valid: !errors.multitasking?.min_slots_to_start,
            message: errors.multitasking?.min_slots_to_start?.message,
            started: isSubmitted
          };
        }
      }}>
      <input
        defaultValue={defValue ?? ""}
        type="hidden"
        {...register("multitasking.min_slots_to_start", {
          validate: {
            minAmount: (value) => {
              const amount = !value ? null : value;
              return amount === null || amount > 1 || "Must be greater than 1";
            },
            maxAmount: (value) => {
              const amount = !value ? 0 : value;
              return amount <= 32 || "Must be less than 32";
            }
          }
        })}
      />
      <Input
        {...{
          placeholder: "min slots per start",
          value: defValue?.toString() ?? "",
          onChange: (value: string) => handleChange(value)
        }}
      />
    </ValidationLayout>
  );
};

export default MinSlotsToStart;
