import Api from "../../../../../../services/Api";
import { downloadFile } from "../../../../../../utils";

const DownloadButton = (props: { href: string; title: string }) => {
  const { href, title } = props;
  function hasId(url: string) {
    const fileId = new URL(url).searchParams.get("id");
    return fileId;
  }

  const handleDownloadFile = async (id: string) => {
    const response = await Api.getFile(id);
    if (response?.success) {
      const fileType = response.success.headers["content-type"];
      const file = new File([response.success.data], title);
      downloadFile(file, fileType);
    } else {
      alert("File download error");
    }
  };

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const fileId = hasId(href);
    if (fileId) {
      await handleDownloadFile(fileId);
    }
  };

  return (
    <button onClick={handleClick} className="outline-none underline">
      {title}
    </button>
  );
};

export default DownloadButton;
