import PhoneInput2 from "react-phone-input-2";

interface PhoneInputProps {
  value: string;
  onChange: (value: string) => void;
}

function PhoneInput(props: PhoneInputProps) {
  const { value, onChange } = props;
  return (
    <PhoneInput2
      {...{
        value,
        disableDropdown: true,
        placeholder: "Phone",
        onChange: (value: string) => {
          onChange(value);
        },
        specialLabel: "",
        containerClass: "!w-full",
        inputClass: `
        !h-fit !w-full
        !bg-neutral-800 !rounded-[10px]
        !p-[0.8em]
        !text-sm !text-neutral-400 !leading-normal
        !border-none
      `,
        buttonClass: "!hidden"
      }}
    />
  );
}

export default PhoneInput;
