import { LightButton } from "../../../general/Button";
import Modal from "../../../general/Modal";
import { WarningRedIcon } from "../../../general/SVGIcon";

const MaxAllowanceMessage = (props: { show: boolean; count: string; handleClose: () => void }) => {
  const { show, count, handleClose } = props;

  return (
    <Modal
      {...{
        customStyles: { content: "h-[226px]" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <LightButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                onClick: () => handleClose(),
                text: "Close"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins flex gap-4">
        <div className="text-lg text-neutral-50">
          <WarningRedIcon />
        </div>
        <div className="py-2.5 text-neutral-200">
          Max allowance is {count} applicants per transaction Please decrease and try again
        </div>
      </div>
    </Modal>
  );
};

export default MaxAllowanceMessage;
