import classNames from "classnames";

export default function UserBorderlessIcon(props: { className?: string }) {
  const { className } = props;
  return (
      <svg className={classNames("stroke-primary-900", className)}
           width="15"
           height="24"
           viewBox="0 0 15 16"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.84375 4.46594C9.84375 5.08754 9.59682 5.68369 9.15728 6.12322C8.71774 6.56276 8.1216 6.80969 7.5 6.80969C6.8784 6.80969 6.28226 6.56276 5.84272 6.12322C5.40318 5.68369 5.15625 5.08754 5.15625 4.46594C5.15625 3.84434 5.40318 3.2482 5.84272 2.80866C6.28226 2.36912 6.8784 2.12219 7.5 2.12219C8.1216 2.12219 8.71774 2.36912 9.15728 2.80866C9.59682 3.2482 9.84375 3.84434 9.84375 4.46594ZM2.81313 13.2897C2.83321 12.0599 3.33584 10.8873 4.21261 10.0247C5.08939 9.16215 6.27006 8.67874 7.5 8.67874C8.72995 8.67874 9.91062 9.16215 10.7874 10.0247C11.6642 10.8873 12.1668 12.0599 12.1869 13.2897C10.7165 13.9639 9.11759 14.3119 7.5 14.3097C5.8275 14.3097 4.24 13.9447 2.81313 13.2897Z"
            strokeLinecap="round"
            strokeLinejoin="round" />
      </svg>
  );
}
