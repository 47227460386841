import * as qs from "qs";
import { FilterParams } from "../types/BountyList";
import { del, get, post, put } from "./newAuth";
import { BountyTransaction } from "../types/BountyTransaction";
import { Transaction } from "@near-wallet-selector/core";

export type ServerError = {
  error: boolean;
  message: string;
};

export type Result<T = any> = {
  failure: ServerError | string | null | unknown;
  success: T;
  status?: number | undefined;
};

const getAppConfig = async () => {
  try {
    const response = await get(`/conf/config`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getTokens = async () => {
  try {
    const response = await get(`/conf/tokens`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getValidatorDAO = async () => {
  try {
    const response = await get(`/conf/validator-dao`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getSkills = async () => {
  try {
    const response = await get(`/conf/skills`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const checkUserName = async (
  userName: string
): Promise<{
  nameAlreadyExists?: boolean;
  nameIsValid?: boolean;
  error?: string;
}> => {
  try {
    const response = await get(`/profile/check-username?name=${userName}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
    return { error: "Can't verify username" };
  }
};

const getBounties = async ({
  page = 1,
  limit = 10,
  accountId,
  filters
}: {
  page: number;
  limit: number;
  accountId?: string;
  filters?: FilterParams;
}) => {
  try {
    const calcFilters: FilterParams = { ...filters };
    for (const key in calcFilters) {
      if (Array.isArray(calcFilters[key as keyof FilterParams])) {
        // @ts-ignore
        calcFilters[key] = calcFilters[key].map((el) =>
          typeof el === "string" || typeof el === "number" ? el : el.name
        );
      }
    }
    delete calcFilters.conditions;
    const query = qs.stringify(
      {
        order: "desc",
        page,
        limit,
        accountId,
        ...calcFilters
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );

    const response = await get(`/bounty?${query}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getFilteredBounties = async ({
  page = 1,
  limit = 10,
  searchParams
}: {
  page: number;
  limit: number;
  searchParams?: object;
}) => {
  try {
    const query = qs.stringify(
      {
        order: "desc",
        page,
        limit,
        ...searchParams
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );

    const response = await get(`/bounty?${query}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getBounty = async ({ id, accountId }: { id: string; accountId?: string }) => {
  try {
    const query = qs.stringify(
      {
        accountId
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );
    const response = await get(`/bounty/${id}?${query}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const importFromGithub = async (url: string) => {
  try {
    const response = await get(`/import/github?url=${url}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    return error;
  }
};

const importFromGitcoin = async (url: string) => {
  try {
    const response = await get(`/import/gitcoin?url=${url}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    return error;
  }
};

const addUserWallet = async (walletAccountId: string, signature: string) => {
  try {
    const response = await put(
      `/profile/add-wallet-id`,
      {},
      {
        "x-near-account-id": walletAccountId,
        "x-near-signature": signature
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
/**
 * @deprecated The method should not be used
 */
const addBountyOwner = async (bountyId: string, accountId: string, useInvoice: boolean) => {
  try {
    const response = await post(`/bounty/after-create-bounty`, {
      bountyId,
      accountId,
      useInvoice
    });
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const addClaimOwner = async (bountyId: string, accountId: string) => {
  try {
    const response = await post(`/bounty/after-create-claim`, {
      bountyId,
      accountId
    });
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const postAfterCreatingTx = async (txHash: string, useInvoice?: boolean) => {
  try {
    const response = await post(`/bounty/after-creating-tx`, {
      txHash,
      useInvoice
    });
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const postBeforeCreatingTx = async ({
  accountId,
  receiverId,
  methodName,
  args,
  useInvoice,
  claimDueDate,
  batch,
  linkToWork,
  attachments,
  reasonForRefusal,
  issueId
}: {
  accountId: string;
  receiverId: string;
  methodName?: string;
  args?: string;
  batch?: Array<{ methodName: string; args?: string }>;
  useInvoice?: boolean;
  claimDueDate?: string;
  attachments?: string[];
  linkToWork?: string;
  reasonForRefusal?: string;
  issueId?: string | null;
}) => {
  try {
    const response = await post(`/bounty/before-creating-tx`, {
      accountId,
      receiverId,
      methodName,
      args,
      useInvoice,
      claimDueDate,
      linkToWork,
      attachments,
      reasonForRefusal,
      batch,
      issueId
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const postBatchCreatingTx = async ({
  transactions,
  accountId
}: {
  accountId: string;
  transactions: {
    batch: Array<{
      methodName: string;
      args: string;
      gas: string;
      deposit: string;
    }>;
    privateKey: string;
    publicKey?: string | undefined;
  };
}) => {
  try {
    const response = await post(`/bounty/batch-transaction`, {
      transactions,
      accountId
    });
    console.log(response);
    return response.success ? response.success.data : response.failure.response;
  } catch (error) {
    console.log(error);
  }
};

const getBatchTxnsStatus = async ({ id }: { id: string }) => {
  try {
    const response = await get(`/bounty/batch-transaction-status?id=${id}`);
    console.log(response);
    return response.success ? response.success.data : response.failure;
  } catch (error) {
    console.log(error);
  }
};

/**
 * @deprecated The method should not be used
 */
const generateInvoice = async (bountyId: string, accountId: string, claimerAccountId: string) => {
  try {
    const response = await post(`/bounty/after-claim-approval`, {
      accountId,
      bountyId,
      claimerAccountId
    });
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getInvoice = async ({
  invoiceId,
  accountId,
  toPdf = true,
  isBountyId,
  claimerAccountId,
  claimNumber
}: {
  invoiceId: string | number;
  accountId: string;
  claimNumber: number | null;
  toPdf: boolean;
  isBountyId?: boolean;
  claimerAccountId?: string;
}) => {
  try {
    const query = qs.stringify(
      {
        accountId,
        toPdf,
        claimNumber,
        isBountyId,
        claimerAccountId
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );
    const response = await get(`/bounty/invoice/${invoiceId}?${query}`, "blob");
    return response;
  } catch (error) {
    console.log(error);
  }
};

const downloadInvoices = async ({
  ids,
  accountId,
  toPdf = true
}: {
  ids: Array<string | number>;
  accountId: string;
  toPdf: boolean;
}) => {
  try {
    const query = qs.stringify(
      {
        accountId,
        toPdf,
        ids
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );
    const response = await get(`/bounty/download-invoices?${query}`, "blob");
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getAccountWhitelist = async () => {
  try {
    const response = await get(`/bounty/whitelist`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getAccountWhitelistById = async (id: string | number) => {
  try {
    const response = await get(`/bounty/whitelist/${id}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const postAccountWhitelist = async (name: string, accounts: string) => {
  try {
    const response = await post(`/bounty/whitelist`, { name, accounts });
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const checkAccountWhitelist = async (accounts: string) => {
  try {
    const response = await post(`/bounty/whitelist/check`, { accounts });
    return response.success ? response.success.data : response.failure.response;
  } catch (error) {
    console.log(error);
  }
};

const putAccountWhitelist = async (
  id: string | number,
  { name, accounts }: { name: string; accounts?: string }
) => {
  try {
    const response = await put(`/bounty/whitelist/${id}`, { name, accounts });
    return response.success ? response.success.data : response.failure.response.data;
  } catch (error) {
    console.log(error);
  }
};

const deleteAccountWhitelist = async (id: string | number) => {
  try {
    return await del(`/bounty/whitelist/${id}`);
  } catch (error) {
    console.log(error);
  }
};

const getApprovedKYCApplicant = async (accountId: string) => {
  try {
    const response = await get(`/kyc/get-approved-kyc-applicant?accountId=${accountId}`);

    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const addKYCApplicant = async (applicantId: string, externalUserId: string, accountId: string) => {
  try {
    const response = await post(`/kyc/applicant`, {
      applicantId,
      externalUserId,
      accountId
    });
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getKYCAccessToken = async (externalUserId: string) => {
  try {
    const response = await post(`/kyc/access-token`, {
      externalUserId
    });
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const checkToUpdateStatus = async (
  bountyId: string | number,
  claimNumber?: number,
  claimerAccountId?: string
) => {
  try {
    const query = qs.stringify(
      {
        bountyId,
        claimerAccountId,
        claimNumber
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );
    const response = await get(`/bounty/need-to-update-status?${query}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const checkNextAction = async (accountId: string | number, bountyId?: string | number) => {
  try {
    const query = qs.stringify(
      {
        accountId,
        bountyId
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );
    const response = await get(`/bounty/check-pending-next-action?${query}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const uploadAttachments = async (files: FormData) => {
  try {
    const response = await post(`/upload/attachments`, files);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const uploadFiles = async (files: FormData, bountyId: number | string) => {
  try {
    const query = qs.stringify(
      {
        bountyId
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );
    const response = await post(`/upload/files?${query}`, files, {
      "Content-Type": "multipart/form-data"
    });
    return response.success ? response.success.data : response.failure.response;
  } catch (error) {
    console.log(error);
  }
};

const sendMessage = async ({
  message,
  accountId,
  bountyId,
  claimNumber,
  receiverAccountId
}: {
  message: string;
  accountId: string;
  bountyId: number;
  receiverAccountId: string;
  claimNumber?: number;
}) => {
  try {
    const response = await post(`/bounty/send-message`, {
      message,
      accountId,
      bountyId,
      receiverAccountId,
      claimNumber
    });
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getMessages = async ({
  bountyId,
  claimerAccountId,
  claimNumber,
  page = 1,
  limit = 50
}: {
  bountyId: number;
  claimerAccountId: string;
  claimNumber?: number;
  page?: number;
  limit?: number;
}) => {
  try {
    const query = qs.stringify(
      {
        order: "desc",
        page,
        limit,
        bountyId,
        claimerAccountId,
        claimNumber
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );
    const response = await get(`/bounty/messages?${query}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const postSubscriber = async (email: string, nearAddress: string) => {
  return await post("/profile/early-access-subscribe", {
    email,
    nearAddress
  });
};

const getCountries = async () => {
  const response = await get("/conf/countries");
  return response.success ? response.success.data : null;
};

const getCurrencies = async () => {
  const response = await get("/conf/currencies");
  return response.success ? response.success.data : null;
};

const getFractalUrl = async (type: string, idOS?: boolean) => {
  const query = qs.stringify(
    {
      type,
      idOS
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );
  const response = await get(`/kyc/fractal-url?${query}`);
  return response.success ? response.success.data : null;
};

const getBountyTransactions = async ({ bountyId }: { bountyId: string }) => {
  const query = qs.stringify(
    {
      bountyId
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );
  const response = await get(`/bounty/transactions?${query}`);
  return response.success ? response.success.data : [];
};

const getUserTransactions = async ({ userId }: { userId: string }) => {
  const query = qs.stringify(
    {
      userId
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );
  const response = await get(`/bounty/user-transactions?${query}`);
  return response.success ? response.success.data : [];
};

const getInvoices = async ({
  bountyId,
  accountId,
  dateFrom,
  dateTo,
  page = 1,
  limit = 10
}: {
  bountyId: string | number;
  accountId: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  limit?: number;
}) => {
  try {
    const query = qs.stringify(
      {
        bountyId,
        accountId,
        dateFrom,
        dateTo,
        page,
        limit
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );
    const response = await get(`/bounty/invoices?${query}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getAllInvoices = async ({
  page,
  limit = 10,
  accountId,
  dateFrom,
  dateTo,
  version = "1"
}: {
  page: number;
  limit: number;
  accountId?: string | null;
  dateFrom?: string;
  dateTo?: string;
  version?: string | null;
}) => {
  try {
    const query = qs.stringify(
      {
        page,
        limit,
        accountId,
        dateFrom,
        dateTo,
        version
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );

    const response = await get(`/bounty/my-invoices?${query}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log("getAllInvoices error", error);
  }
};

const getHunters = async ({
  page,
  limit,
  searchParams
}: {
  page: number;
  limit: number;
  searchParams?: object;
}) => {
  try {
    const query = qs.stringify(
      {
        order: "desc",
        page,
        limit,
        ...searchParams
      },
      {
        arrayFormat: "comma",
        encodeValuesOnly: true,
        skipNulls: true
      }
    );

    const response = await get(`/hunters?${query}`);

    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getHunter = async (id: string) => {
  try {
    const response = await get(`/hunters/${id}`);
    return response.success ? response.success.data : null;
  } catch (error) {
    console.log(error);
  }
};

const getFile = async (id: string) => {
  try {
    const response = await get(`/upload/file/${id}`, "blob");
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getTransactionByHash = async (hash: string) => {
  try {
    const response = await get(`/bounty/transaction/${hash}`);
    return response.success ? (response.success.data as BountyTransaction) : null;
  } catch (error) {
    console.log(error);
  }
};

export const Api = {
  getAppConfig,
  getTokens,
  getValidatorDAO,
  getBounties,
  getBounty,
  addUserWallet,
  importFromGithub,
  importFromGitcoin,
  uploadAttachments,
  addBountyOwner,
  addClaimOwner,
  sendMessage,
  getMessages,
  getKYCAccessToken,
  checkToUpdateStatus,
  postSubscriber,
  getSkills,
  addKYCApplicant,
  generateInvoice,
  getInvoice,
  checkUserName,
  getCountries,
  postAfterCreatingTx,
  checkNextAction,
  getAccountWhitelist,
  postAccountWhitelist,
  getAccountWhitelistById,
  putAccountWhitelist,
  deleteAccountWhitelist,
  checkAccountWhitelist,
  getFractalUrl,
  getCurrencies,
  getBountyTransactions,
  getUserTransactions,
  getInvoices,
  getApprovedKYCApplicant,
  getFilteredBounties,
  postBeforeCreatingTx,
  getHunters,
  getHunter,
  getAllInvoices,
  downloadInvoices,
  uploadFiles,
  getFile,
  getTransactionByHash,
  postBatchCreatingTx,
  getBatchTxnsStatus
};

export default Api;
