import classNames from "classnames";
import { twMerge } from "tailwind-merge";

const SearchIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      viewBox="0 0 20 20"
      className={twMerge(classNames("w-5 h-5 stroke-white", className))}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1258 19.0003L13.9288 13.8033M13.9288 13.8033C15.3354 12.3967 16.1256 10.489 16.1256 8.49979C16.1256 6.5106 15.3354 4.60287 13.9288 3.19629C12.5222 1.78972 10.6145 0.999512 8.62528 0.999512C6.63608 0.999512 4.72836 1.78972 3.32178 3.19629C1.91521 4.60287 1.125 6.5106 1.125 8.49979C1.125 10.489 1.91521 12.3967 3.32178 13.8033C4.72836 15.2099 6.63608 16.0001 8.62528 16.0001C10.6145 16.0001 12.5222 15.2099 13.9288 13.8033Z"
        stroke="stroke-inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
