import { useFormContext } from "react-hook-form";
import { CompanyMetadata, IndividualMetadata } from "../../../types/Auth";
import { ValidationLayout } from "../../Layout";
import CompanyProfileData from "./CompanyProfileData";
import IndividualProfileData from "./IndividualProfileData";

type InvoiceЬMetadataForm = {
  metadata?: CompanyMetadata | IndividualMetadata;
};

const InvoiceFormData = (props: {
  metadata?: CompanyMetadata | IndividualMetadata;
  useVat?: boolean;
}) => {
  const { metadata, useVat = false } = props;
  const methods = useFormContext<InvoiceЬMetadataForm>();
  const { errors, isSubmitted } = methods.formState;
  if (metadata?.profileType === "Individual")
    return <IndividualProfileData metadata={metadata as IndividualMetadata} useVAT={useVat} />;
  if (metadata?.profileType === "Company")
    return <CompanyProfileData metadata={metadata as CompanyMetadata} useVAT={useVat} />;
  return (
    <ValidationLayout
      {...{
        validate: () => {
          return {
            valid: !errors?.metadata,
            started: isSubmitted,
            message: errors.metadata?.message
          };
        }
      }}>
      You must complete a{" "}
      <a href="/profile/edit" className="text-primary-900 underline">
        user profile
      </a>{" "}
      to use the invoice!
    </ValidationLayout>
  );
};

export default InvoiceFormData;
