import { useState } from "react";
import { useStoreActions } from "../../../hooks";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import utils from "../../../services/utils";
import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { DefermentOfKYC } from "../../../types/Kyc";
import { AcceptHeroForm } from "../Dialogues";
import ActionButton from "./general/ActionButton";

export default function AcceptAction(props: {
  claim: Claim;
  bounty: Bounty;
  isWhitelisted: boolean;
}) {
  const { claim, bounty, isWhitelisted } = props;
  const [showForm, setShowForm] = useState(false);
  const { walletAccountId } = useWalletAccountId();

  const setAcceptClaimant = useStoreActions((actions) => actions.nearApi).setAcceptClaimant;

  const canPostponeKYC = () => {
    return !!(
      bounty?.reviewers !== "ValidatorsDao" &&
      bounty.kyc_config?.kyc_verification_method === "DuringClaimApproval"
    );
  };

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, claim, walletAccountId);
    return a.checkAccess([!a.isBountyOwner(), !a.isValidatorDAO(), !a.isReviewer()]);
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async (value?: DefermentOfKYC) => {
    await setAcceptClaimant([
      {
        id: bounty.id,
        receiver_id: claim.owner,
        token: bounty.token,
        kyc_postponed: value,
        claim_number: claim.claim_number
      }
    ]);
  };

  return (
    <>
      <ActionButton disabled={isDisabled()} handleClick={handleButtonClick} text={"Accept"} />
      <AcceptHeroForm
        bounty={bounty}
        claim={claim}
        show={showForm}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
        isClaimerWhitelisted={isWhitelisted}
        useKYC={bounty.kyc_config?.kyc_required ?? false}
        canPostponeKYC={canPostponeKYC()}
      />
    </>
  );
}
