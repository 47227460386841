import Big from "big.js";
import { BountyCreate } from "../types";
import { Bounty } from "../types/Bounty";
import { formatting } from "../common/formatting";
import { Claim } from "../types/Claim";
import moment from "moment";
import { DateOrPeriod } from "../types/BountyCreate";
import Decimal from "decimal.js";

const FRAC_DIGITS = 5;

function cleanupAmount(amount: string): string {
  return amount.replace(/,/g, "").trim();
}

function formatWithCommas(value: string, fractionDigits = 2): string {
  return removeTrailingZeros(
    Number(value).toLocaleString("en-US", {
      minimumFractionDigits: fractionDigits
    })
  );
}

const formatTokenAmount = (value: number | string | Big, decimals: number, precision = 5) =>
  value && Big(value).div(Big(10).pow(decimals)).toFixed(precision);

const parseTokenAmount = (value: number | string | Big, decimals: number, precision = 0) =>
  value && Big(value).times(Big(10).pow(decimals)).toFixed(precision);

const parseCurrencyAmount = (value: number | string, decimals: number) =>
  value && new Decimal(value).times(new Decimal(10).pow(decimals)).toFixed();

const removeTrailingZeros = (amount: string) => amount.replace(/\.?0*$/, "");

const amountWithCommas = (amount: string) => {
  const parts = amount.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

const formatToken = (amount: string, decimals: number) => {
  if (amount === "0") {
    return amount;
  }

  const formattedAmount = formatTokenAmount(amount, decimals, FRAC_DIGITS);

  if (formattedAmount === `0.${"0".repeat(FRAC_DIGITS)}`) {
    return `< ${!FRAC_DIGITS ? Big(0) : `0.${"0".repeat((FRAC_DIGITS || 1) - 1)}1`}`;
  }
  return amountWithCommas(removeTrailingZeros(formattedAmount.toString()));
};

function getParsedTokenAmount(amount: string, decimals: number, precision = 0) {
  return parseTokenAmount(amount, decimals, precision);
}

function getParsedCurrencyAmount(amount: string, decimals: number) {
  return parseCurrencyAmount(amount, decimals);
}

function getFormattedTokenAmount(amount: string, decimals: number, precision = 5) {
  return removeTrailingZeros(formatTokenAmount(amount, decimals, precision).toString());
}

const getDeadline = (value: BountyCreate.Deadline) => {
  if (value) {
    if (typeof value === "object") {
      if ("MaxDeadline" in value) {
        const duration = formatting.formatDurationToHuman(value.MaxDeadline.max_deadline);
        if (typeof duration === "object") {
          for (const key in duration) {
            if (duration[key as keyof typeof duration] !== 0) {
              return `${duration[key as keyof typeof duration]} (${key})`;
            }
          }
        }
        return duration.toString();
      }
      if ("DueDate" in value) {
        return formatting.formatToDateString(value.DueDate.due_date);
      }
    }
    return "Not Specified";
  }
  return null;
};

const getDeadlineInMultitasking = (value: DateOrPeriod | undefined) => {
  if (value) {
    if (typeof value === "object") {
      if ("Period" in value) {
        const duration = formatting.formatDurationToHuman(value.Period.period);
        if (typeof duration === "object") {
          for (const key in duration) {
            if (duration[key as keyof typeof duration] !== 0) {
              return `${duration[key as keyof typeof duration]} (${key})`;
            }
          }
        }
        return duration.toString();
      }
      if ("Date" in value) {
        return formatting.formatToDateString(value.Date.date);
      }
    }
    return "Not Specified";
  }
  return null;
};

const getClaimDeadline = (deadline: string) => {
  if (deadline) {
    const duration = formatting.formatDurationToHuman(deadline);
    if (typeof duration === "object") {
      for (const key in duration) {
        if (duration[key as keyof typeof duration] !== 0) {
          return `${duration[key as keyof typeof duration]} (${key})`;
        }
      }
      return "Not Specified";
    }

    return duration.toString();
  }
  return null;
};

const getBountyDeadline = (bounty: Bounty) => {
  if (bounty.deadline && bounty.deadline !== "WithoutDeadline") {
    if (bounty.deadline === "MaxDeadline") {
      if (bounty.max_deadline) {
        const duration = formatting.formatDurationToHuman(bounty.max_deadline);
        if (typeof duration === "object") {
          for (const key in duration) {
            if (duration[key as keyof typeof duration] !== 0) {
              return `${duration[key as keyof typeof duration]} (${key})`;
            }
          }
        }
        return duration.toString();
      }
    } else if (bounty.due_date) {
      return formatting.formatDateToString(new Date(bounty.due_date));
    }
  }
  return "Not Specified";
};

const getNumberDeadline = (bounty: Bounty) => {
  if (bounty.deadline && bounty.deadline !== "WithoutDeadline") {
    if (bounty.deadline === "MaxDeadline") {
      return Number(bounty.max_deadline);
    } else if (bounty.due_date) {
      return moment(bounty.due_date).valueOf();
    }
  }
  return null;
};

function grantAccess(bounty: Bounty, claim: Claim | null, walletAccountId: string) {
  const access = {
    isBountyOwner: () => {
      return bounty.owner === walletAccountId;
    },
    isValidatorDAO: () => {
      return bounty.validators_dao ? bounty.validators_dao.account_id === walletAccountId : false;
    },
    isReviewer: () => {
      return bounty.more_reviewers ? bounty.more_reviewers.includes(walletAccountId ?? "") : false;
    },
    isClaimer: () => {
      return claim?.owner ? claim.owner === walletAccountId : false;
    },
    isOneOfClaimers: () => {
      for (const claim of bounty.claims) {
        if (claim.owner === walletAccountId) {
          return true;
        }
      }
      return false;
    },
    isUserAlreadyClaimed: () => {
      return bounty.claims.map((claim) => claim.owner).includes(walletAccountId);
    },
    isWithoutDeadline: () => {
      return bounty.deadline === "WithoutDeadline";
    },
    hasValidatorDao: () => {
      return bounty.reviewers === "ValidatorsDao";
    },
    hasNewClaims: () => {
      return bounty.claims.map((claim) => claim.status).includes("New");
    },
    checkAccess: (arr: boolean[]) => {
      const even = (element: boolean) => element === true;
      return arr.every(even);
    }
  };
  return access;
}

const pluralize = (count: number, noun: string, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

function capitalize(s: string) {
  return s && s[0].toUpperCase() + s.slice(1);
}

const getBountyTimeLeft = (bounty: Bounty) => {
  if (bounty.deadline && bounty.deadline !== "WithoutDeadline") {
    if (bounty.deadline === "MaxDeadline" && bounty.max_deadline) {
      const maxDeadlineMs = moment.duration(parseInt(bounty.max_deadline) / 1000000);
      const timeLeft = moment.duration(moment(bounty.created_at).add(maxDeadlineMs).diff(moment()));
      const duration = moment.duration(timeLeft);
      const isPositive = duration > moment.duration(0);

      if (isPositive) {
        const daysLeft = duration.days();
        const hoursLeft = duration.hours();
        const minutesLeft = duration.minutes();

        return `${daysLeft > 0 ? daysLeft + "days" : ""} ${
          hoursLeft > 0 ? hoursLeft + "hours" : ""
        } ${minutesLeft > 0 ? minutesLeft + "min" : ""}`;
      } else {
        return "Expired";
      }
    } else if (bounty.due_date) {
      const currentDate = moment();
      const dueDate = moment(bounty.due_date);

      if (dueDate.isAfter(currentDate)) {
        const daysDiff = dueDate.diff(currentDate, "days");
        const hoursDiff = dueDate.diff(currentDate, "hours") % 24;
        const minutesDiff = dueDate.diff(currentDate, "minutes") % 60;

        return `${daysDiff > 0 ? daysDiff + "days" : ""} ${
          hoursDiff > 0 ? hoursDiff + "hours" : ""
        } ${minutesDiff > 0 ? minutesDiff + "min" : ""}`;
      } else {
        return "Expired";
      }
    }
  }
  return null;
};

const utils = {
  parseTokenAmount,
  formatTokenAmount,
  formatToken,
  getDeadline,
  getBountyDeadline,
  grantAccess,
  formatWithCommas,
  getFormattedTokenAmount,
  getParsedTokenAmount,
  cleanupAmount,
  pluralize,
  getNumberDeadline,
  getClaimDeadline,
  getDeadlineInMultitasking,
  capitalize,
  getParsedCurrencyAmount,
  getBountyTimeLeft
};

export default utils;
