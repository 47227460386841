import useCurrencyDecimalPrecision from "../../../hooks/useCurrencyDecimalPrecision";

const CurrencyAmount = (props: { currency: string; amount: string }) => {
  const { currency, amount } = props;
  const { currencyDecimalPrecision } = useCurrencyDecimalPrecision({
    currency,
    amount,
  });
  return (
    <span className="text-md text-success">
      {currency && amount && currencyDecimalPrecision} {currency}
    </span>
  );
};

export default CurrencyAmount;
