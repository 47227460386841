import { useState } from "react";
import ActionButton from "../general/ActionButton";
import AddTaskActionForm from "./AddTaskActionForm";
import { useStoreActions, useStoreState } from "../../../../../../../../hooks";
import { DifferentTasks, Subtask } from "../../../../../../../../types/BountyCreate";

const AddTaskAction = () => {
  const [showForm, setShowForm] = useState(false);
  const subtasks = useStoreState(
    (state) => (state.newBounty.multitasking as DifferentTasks).DifferentTasks.subtasks
  );
  const setSubtask = useStoreActions((actions) => actions.newBounty.setSubtask);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async (task: Subtask) => {
    const newSubtasks = [...subtasks];
    task.subtask_percent = task.subtask_percent * 1000;
    newSubtasks.push(task);
    setSubtask(newSubtasks);
    setShowForm(!showForm);
  };

  return (
    <>
      <ActionButton
        {...{
          customStyles: { button: "max-w-[96px]" },
          handleClick: handleButtonClick,
          text: "Add task"
        }}
      />
      <AddTaskActionForm
        show={showForm}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
    </>
  );
};

export default AddTaskAction;
