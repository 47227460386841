import utils from "../../../services/utils";
import { Area, MarksLayer } from "../../Layout";
import Avatar from "../../general/Avatar";
import { useStoreState } from "../../../hooks";
import { Claim } from "../../../types/Claim";
import { Bounty } from "../../../types/Bounty";
import React, { useRef, useState } from "react";
import ChatForm from "../ChatForm";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import DefUserIcon from "../../general/SVGIcon/defUserIcon";
import ClaimActions from "./ClaimActions";
import RightSidebar from "../../general/RightSidebar";
import DaysFromNow from "../../general/DaysFromNow";
import Mark from "../../general/Mark";
import classNames from "classnames";
import Countdown from "react-countdown";
import { getStatusText, getUserDisplayName, showCountdown, showTask } from "./helpers";
import { ExtendDeadline } from "../../Form/EditBountyForm/Actions";
import useClickOutside from "../../../hooks/useClickOutside";
import IconButton from "../../general/Button/IconButton";
import { ChatBubbleOvalIcon, CheckedYellowIcon } from "../../general/SVGIcon";
import { NextActionProps } from "../../../hooks/useNextActions";

export default function ApplicantItem(props: {
  bounty: Bounty;
  bountyClaim: Claim;
  checked: boolean;
  isWhitelisted: boolean;
  nextActions: NextActionProps;
  refetchActions: () => void;
  onClick?: (value: Claim) => void;
}) {
  const { bounty, bountyClaim, checked, onClick, nextActions, refetchActions, isWhitelisted } =
    props;
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);
  const [showChatForm, setShowChatForm] = useState(false);
  const { walletAccountId } = useWalletAccountId();

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, bountyClaim, walletAccountId);
    return a.checkAccess([!a.isBountyOwner(), !a.isReviewer(), !a.isClaimer()]);
  };

  const handleShowChatForm = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    setShowChatForm(true);
  };

  const handleCloseChatForm = () => {
    if (showChatForm) {
      setShowChatForm(false);
    }
  };

  const handleClick = () => {
    onClick && onClick(bountyClaim);
  };

  const buttonRef = useRef(null);
  const chatRef = useClickOutside(handleCloseChatForm, buttonRef);

  return (
    <>
      {" "}
      <div className="font-poppins py-2">
        <Area
          {...{
            customStyles: {
              borderContainer: classNames("rounded-lg", {
                "border border-primary-800": bountyClaim.owner === walletAccountId || checked
              }),
              contentContainer: "rounded-lg bg-neutral-825"
            }
          }}>
          <MarksLayer>
            {bounty.owner === walletAccountId && showCountdown(bountyClaim) && (
              <Mark
                {...{
                  text: <Countdown className="px-1" date={new Date(bountyClaim.end_time)} />,
                  customStyles: {
                    container: classNames(
                      "flex items-center px-0 justify-between text-neutral-800 bg-post-secondary-900"
                    ),
                    text: "min-w-[74px] px-2 capitalize"
                  }
                }}>
                <ExtendDeadline
                  bounty={bounty}
                  claim={bountyClaim}
                  refetchActions={refetchActions}
                />
              </Mark>
            )}
            {showTask(bountyClaim) && (
              <Mark
                {...{
                  text: `Task #${bountyClaim.slot}`,
                  customStyles: {
                    container: classNames(
                      "px-4 justify-between text-neutral-800 bg-post-secondary-900"
                    ),
                    text: "capitalize"
                  }
                }}
              />
            )}
          </MarksLayer>
          <div className="px-4">
            <div className="py-4">
              <div className="flex flex-col sm:flex-row flex-wrap lg:gap-0 sm:gap-4 items-center justify-between text-ellipsis whitespace-nowrap overflow-hidden">
                <div className="flex items-center gap-4 text-md text-neutral-50">
                  <button className="outline-none" onClick={handleClick}>
                    {!checked ? (
                      <>
                        {bountyClaim.ownerDetails?.picture ? (
                          <Avatar
                            picture={bountyClaim.ownerDetails.picture}
                            className="w-[45px] h-[45px] mt-0 my-0"
                            original={false}
                          />
                        ) : (
                          <DefUserIcon className="w-[45px] h-[45px] mt-0 my-0" />
                        )}
                      </>
                    ) : (
                      <CheckedYellowIcon className="w-[45px] h-[45px] mt-0 my-0" />
                    )}
                  </button>

                  <div className="flex flex-col text-md lg:max-w-full sm:max-w-[18ch] overflow-hidden text-ellipsis">
                    <div className="flex">
                      {bountyClaim.ownerDetails?.username ? (
                        <a
                          href={`/user-info/${bountyClaim.ownerDetails.username}?type=${bountyClaim.ownerDetails.profileType}`}
                          rel="noopener">
                          {getUserDisplayName(bountyClaim.ownerDetails)}
                        </a>
                      ) : (
                        <span className="lg:max-w-full sm:max-w-[18ch] overflow-hidden text-ellipsis">
                          {bountyClaim.owner}
                        </span>
                      )}
                    </div>

                    <div className="flex items-center">
                      <DaysFromNow date={bountyClaim.created_at} />
                      <>
                        {!bounty.multitasking && (
                          <span className="text-neutral-300 text-sm">
                            {", "}
                            {getStatusText(bountyClaim.status)}
                          </span>
                        )}
                        {bounty.multitasking && (
                          <>
                            {bountyClaim.advanced_status !== "Unknown" ? (
                              <span className="text-neutral-300 text-sm">
                                {", "}
                                {getStatusText(bountyClaim.advanced_status)}
                              </span>
                            ) : (
                              <span className="text-neutral-300 text-sm">
                                {", "}
                                {getStatusText(bountyClaim.status)}
                              </span>
                            )}
                          </>
                        )}
                      </>
                    </div>
                    <div className="flex">
                      <span className="text-sm text-neutral-500">
                        Application <span className="text-primary-800">#{bountyClaim.id}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 md:justify-end sm:justify-between">
                  {isAuthenticated && (
                    <ClaimActions
                      bounty={bounty}
                      claim={bountyClaim}
                      nextActions={nextActions}
                      isWhitelisted={isWhitelisted}
                      refetchActions={refetchActions}
                    />
                  )}
                  {isAuthenticated && !isDisabled() && (
                    <div className="flex items-center">
                      <div ref={buttonRef} className="flex items-center">
                        <IconButton
                          icon={<ChatBubbleOvalIcon className="stroke-neutral-300" />}
                          disabled={false}
                          customStyles={{ button: "bg-transparent" }}
                          onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                            handleShowChatForm(e)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Area>
      </div>
      {showChatForm && (
        <RightSidebar show={showChatForm}>
          <ChatForm
            onClose={handleCloseChatForm}
            bounty={bounty}
            claim={bountyClaim}
            chatRef={chatRef}
          />
        </RightSidebar>
      )}
    </>
  );
}
