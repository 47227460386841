import { useState, useContext } from "react";
import { useStoreActions } from "../../../../hooks";
import useWalletAccountId from "../../../../hooks/useWalletAccountId";
import utils from "../../../../services/utils";
import { Bounty, NextActionModel } from "../../../../types/Bounty";
import NextActionsContext from "../../../Bounty/general/context/NextActionsContext";
import EditDescriptionForm from "../Dialogues/EditDescriptionForm";
import EditIconButton from "./general/EditIconButton";
import { FinalExecutionOutcomeForm } from "../../../Bounty/Dialogues";
import { providers } from "near-api-js";

const EditDescription = (props: { bounty: Bounty; refetchActions: () => void }) => {
  const { bounty, refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<providers.FinalExecutionOutcome | null>(
    null
  );
  const updateBountyV2 = useStoreActions((actions) => actions.nearApi.updateBountyV2);
  const context = useContext(NextActionsContext);
  const nextActions = context as Partial<NextActionModel>[] | null;
  const { walletAccountId } = useWalletAccountId();
  const [loading, setLoading] = useState(false);
  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const isHidden = () => {
    const action = nextActions?.find((item) => item.accountId === walletAccountId);
    return !action?.nextActions?.bountyUpdateMetadata;
  };

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, null, walletAccountId);
    return a.checkAccess([!a.isBountyOwner(), !a.isReviewer()]);
  };

  const handleSubmitForm = async ({
    title,
    description
  }: {
    title: string;
    description: string;
  }) => {
    setLoading(true);
    const result = await updateBountyV2({
      id: bounty.id,
      bounty_update: {
        metadata: {
          title,
          description,
          category: bounty.metadata.category,
          experience: bounty.metadata.experience,
          tags: bounty.metadata.tags,
          acceptance_criteria: bounty.metadata.acceptance_criteria,
          contact_details: bounty.metadata.contact_details
        }
      }
    });
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
    }
  };

  return !isHidden() ? (
    <>
      <EditIconButton isDisabled={isDisabled()} handleSubmit={() => handleButtonClick()} />
      <EditDescriptionForm
        show={showForm}
        loading={loading}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
        bounty={bounty}
      />
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  ) : null;
};

export default EditDescription;
