import Link from "../../general/Link";
import LogoLink from "../../general/LogoLink";
import logo from "../../../assets/images/logo.png";

const styles = {
  footer: `
    md:max-w-screen-md
    lg:max-w-screen-lg 
    mx-auto pt-12
  `,
  contentContainer: `
    grid
    grid-cols-1
    md:grid-cols-6    
    w-full
  `,
  columnStyle: `
    flex flex-col items-start
    space-y-2
    mb-10 md:mb-16
  `,
  logoLink: `
    max-h-[3.5em] w-[90%]
  `,
  columnHeader: `
    text-base font-poppins text-neutral-100
  `,
  link: `
    text-sm font-poppins text-neutral-300
    hover:text-neutral-100
  `,
  customLink: `
    text-primary-900
    header-link--hover
    text-[1.2rem]
  `
};

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.contentContainer}>
        <div className="col-start-1 col-end-3 mb-10">
          <div className="flex flex-col gap-y-2 justify-start">
            <LogoLink {...{ imageSrc: logo, style: styles.logoLink }} />
          </div>
        </div>
        <div className={styles.columnStyle + "col-start-1 md:col-start-3"}>
          <p className={styles.columnHeader}>Resources</p>
          <Link
            {...{
              text: "Github",
              style: styles.link,
              link: "https://github.com/HeroesHQ"
            }}
          />
          <Link
            {...{
              text: "Gitbook",
              style: styles.link,
              link: "https://heroes-build.gitbook.io"
            }}
          />
          <Link
            {...{
              text: "Whitepaper",
              style: styles.link,
              link: "https://heroes-build.gitbook.io/docs/whitepaper"
            }}
          />
        </div>
        <div className={styles.columnStyle}>
          <p className={styles.columnHeader}>Community</p>
          <Link
            {...{
              text: "Blog",
              style: styles.link,
              link: "https://heroes-build.medium.com"
            }}
          />
          <Link
            {...{
              text: "Twitter",
              style: styles.link,
              link: "https://twitter.com/heroes_build"
            }}
          />
          <Link
            {...{
              text: "Telegram",
              style: styles.link,
              link: "https://t.me/+DYD6bL5sHclkMzRk"
            }}
          />
        </div>
      </div>
    </footer>
  );
}
