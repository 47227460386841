import { useEffect } from "react";
import { AdditionalInfo, Description, Details, Payment, Review } from "./FormSteps";
import Stepper from "./general/Stepper/Stepper";
import StatusMessage from "./FormSteps/components/StatusMessage";
import { FormProvider, useForm } from "react-hook-form";
import { GradientBorderV2 } from "../../Layout";
import gradientBg from "../../../assets/images/signin-flow__image--gradient-bg.jpg";
import { useStoreActions, useStoreState } from "../../../hooks";
import Duration from "./FormSteps/Duration";
import usePostpaidSubscriberWhitelisted from "../../../hooks/usePostpaidSubscriberWhitelisted";
import PostpaidSubscriberWhitelistedContext from "./general/context/PostpaidSubscriberWhitelistedContext";
import { defaultSteps } from "../../../store/models/NewBountyFormModel";

const CreateBountyForm = () => {
  const methods = useForm();
  const { setReceiver } = useStoreActions((actions) => actions.newBountyForm);
  const { isPostpaidSubscriberWhitelisted } = usePostpaidSubscriberWhitelisted();

  const config = useStoreState((state) => state.app.config);
  const { currentStep } = useStoreState((state) => state.newBountyForm);

  useEffect(() => {
    if (config?.bounty_contract_id) {
      setReceiver(config.bounty_contract_id);
    }
  }, [config?.bounty_contract_id]);

  return (
    <div className="z-0">
      <div className="flex gap-4 justify-center py-6 relative z-20">
        <Stepper steps={defaultSteps} />
      </div>
      <div className="absolute top-0 right-0 left-0 z-0">
        <img src={gradientBg} className="max-h-[400px] min-w-[80%] mx-auto" alt="" />
      </div>

      <GradientBorderV2
        {...{
          style: {
            image: "before:bg-signin-flow__item-border bg-[hsla(0,0%,6%,45%)]",
            radius: "before:rounded-t-[40px] rounded-t-[40px] bg-neutral-900/75"
          }
        }}>
        <div className="z-20 px-[1em] md:px-[4em] relative pb-8">
          <h1 className="container text-neutral-50 text-2xl font-bold -tracking-[0.02em] text-center mx-[auto] py-[0.75em] md:py-[1.25em]">
            {currentStep.title}
          </h1>
          {currentStep!.description && (
            <h2 className="text-center text-md text-neutral-50">{currentStep.description}</h2>
          )}
          <div className="w-full opacity-100">
            {defaultSteps.map((step) => (
              <div key={step.name}>
                {currentStep.name === step.name && (
                  <PostpaidSubscriberWhitelistedContext.Provider
                    value={{ isPostpaidSubscriberWhitelisted }}>
                    <FormProvider {...methods}>
                      {currentStep.name === "description" && <Description />}
                      {currentStep.name === "details" && <Details />}
                      {currentStep.name === "duration" && <Duration />}
                      {currentStep.name === "payment" && <Payment />}
                      {currentStep.name === "additional_info" && <AdditionalInfo />}
                      {currentStep.name === "review" && <Review />}
                    </FormProvider>
                  </PostpaidSubscriberWhitelistedContext.Provider>
                )}
              </div>
            ))}
          </div>
          <StatusMessage />
        </div>
      </GradientBorderV2>
    </div>
  );
};

export default CreateBountyForm;
