import { useState } from "react";
import utils from "../../../services/utils";
import { FinalExecutionOutcomeForm, RejectBountyForm } from "../Dialogues";
import { useStoreActions } from "../../../hooks";
import { Claim } from "../../../types/Claim";
import { Bounty } from "../../../types/Bounty";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import ActionButton from "./general/ActionButton";
import { FinalExecutionOutcome } from "@near-wallet-selector/core";

export default function RejectAction(props: {
  claim: Claim;
  bounty: Bounty;
  text?: string;
  refetchActions: () => void;
}) {
  const { claim, bounty, text = "Reject", refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<FinalExecutionOutcome | null>(null);
  const { walletAccountId } = useWalletAccountId();
  const setBountyReject = useStoreActions((actions) => actions.nearApi.setBountyReject);
  const [loading, setLoading] = useState(false);

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, null, walletAccountId);
    return a.checkAccess([!a.isBountyOwner(), !a.isReviewer()]);
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const result = (await setBountyReject(
      Array.isArray(claim)
        ? claim.map((item) => {
            return {
              id: bounty.id,
              receiver_id: item.owner,
              claim_number: item.claim_number
            };
          })
        : [
            {
              id: bounty.id,
              receiver_id: claim.owner,
              claim_number: claim.claim_number
            }
          ]
    )) as FinalExecutionOutcome;
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
    }
  };

  return (
    <>
      <ActionButton text={text} disabled={isDisabled()} handleClick={handleButtonClick} />
      <RejectBountyForm
        show={showForm}
        loading={loading}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  );
}
