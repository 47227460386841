import { ReactNode, isValidElement } from "react";
import { GradientBorderV2 } from "../../Layout";

import gradientBg from "../../../assets/images/signin-flow__image--gradient-bg.jpg";

interface TProps {
  title?: string | ReactNode;
  children: ReactNode[] | ReactNode;
  // onNext?: (...args: any[]) => void;
  // onBack?: (...args: any[]) => void;
  // nextData?: any;
  // backData?: any;
}

const styles = {
  section: `
    container block
    mb-[3em] mx-auto
    md:mb-[6em]
    max-w-[544px]
  `,
  title: `
    container
    text-neutral-50 text-2xl font-bold -tracking-[0.02em] text-center
    mx-[auto]
    py-[0.75em]
    md:py-[1.25em]
  `
};

const titleElement = (title: string | ReactNode) => {
  let result = undefined;

  if ((title as string).length) {
    result = <h5 className={styles.title}>{title}</h5>;
  } else if (isValidElement(title)) {
    result = title;
  } else {
    result = null;
  }

  return result;
};

const SignFormContainerLayout = function (props: TProps) {
  const { title, children } = props;

  return (
    <div className={styles.section}>
      <div className="absolute top-[4rem] right-0 left-0">
        <img src={gradientBg} className="max-h-[400px] min-w-[85%] mx-auto" alt="" />
      </div>
      <GradientBorderV2
        {...{
          style: {
            image: "before:bg-signin-flow__item-border bg-[hsla(0,0%,6%,45%)]",
            radius: "before:rounded-t-[40px] rounded-t-[40px]"
          }
        }}>
        <div className="px-[1em] md:px-[4em] flex flex-col items-center justify-center">
          {titleElement(title)}
          <div className="w-full opacity-100">{children}</div>
        </div>
      </GradientBorderV2>
    </div>
  );
};

export default SignFormContainerLayout;
