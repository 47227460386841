import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../../../../../hooks";
import { OneForAll } from "../../../../../../../types/BountyCreate";
import { ValidationLayout } from "../../../../../../Layout";
import { Input } from "../../../../../../general/Input";

type FormData = {
  multitasking: {
    number_of_slots: number | null;
  };
};

const NumberOfSlots = () => {
  const methods = useFormContext<FormData>();
  const { register, formState, setValue } = methods;
  const { errors, isSubmitted } = formState;
  const number_of_slots = useStoreState(
    (state) => (state.newBounty.multitasking as OneForAll)?.OneForAll.number_of_slots
  );

  const amount_per_slot = useStoreState(
    (state) => (state.newBounty.multitasking as OneForAll)?.OneForAll.amount_per_slot
  );

  const payment = useStoreState((state) => state.newBountyForm.payment);

  const defValue = useMemo(() => {
    return !number_of_slots || number_of_slots < 0 ? null : number_of_slots;
  }, [number_of_slots]);

  const setNumberOfSlots = useStoreActions((actions) => actions.newBounty.setNumberOfSlots);
  const setPayment = useStoreActions((actions) => actions.newBountyForm.setPayment);

  const handleChange = (value: string) => {
    if (!isNaN(parseInt(value))) {
      setNumberOfSlots(parseInt(value));
      setValue("multitasking.number_of_slots", parseInt(value), { shouldValidate: true });
      if (amount_per_slot) {
        setPayment(parseInt(amount_per_slot) * parseInt(value));
      }
    } else {
      setNumberOfSlots(0);
      setValue("multitasking.number_of_slots", null, { shouldValidate: true });
    }
  };

  return (
    <ValidationLayout
      {...{
        validate: () => {
          return {
            valid: !errors.multitasking?.number_of_slots,
            message: errors.multitasking?.number_of_slots?.message,
            started: isSubmitted
          };
        }
      }}>
      <input
        defaultValue={defValue ?? ""}
        type="hidden"
        {...register("multitasking.number_of_slots", {
          validate: {
            minAmount: (value) => {
              const amount = !value ? 0 : value;
              return amount > 1 || "Must be greater than 1";
            }
          }
        })}
      />
      <Input
        {...{
          placeholder: "number of slots",
          value: defValue?.toString() ?? "",
          onChange: (value: string) => handleChange(value)
        }}
      />
    </ValidationLayout>
  );
};

export default NumberOfSlots;
