import { ReactNode } from "react";
import Modal from "../../general/Modal";

const Applications = (props: { open: boolean; onClose: () => void; children: ReactNode }) => {
  const { open, onClose, children } = props;
  return open ? (
    <Modal
      {...{
        customStyles: {
          content: "w-full max-w-[625px] h-fit max-h-[516px] overflow-auto scrollbar"
        },
        disableOutsideClick: false,
        open,
        onClose
      }}>
      {children}
    </Modal>
  ) : (
    <>{children}</>
  );
};

export default Applications;
