import { format } from "date-fns";
import { Bounty } from "../../../../types/Bounty";
import { Claim } from "../../../../types/Claim";
import { getFileName } from "../../general/helpers";
const getInvoiceDate = (date?: string) => {
  return date ? format(new Date(date), "MMM. dd yyyy") : "InProgress";
};

const getSlotPercents = (bounty: Bounty, index: number) => {
  return bounty.multitasking?.subtasks
    ? bounty.multitasking?.subtasks[index].subtask_percent / 1000
    : 0;
};

const getSlotIdx = (claims: Claim[], invoiceAccountId: string) => {
  return claims.find((item) => item.owner === invoiceAccountId)?.slot ?? -1;
};

export { getInvoiceDate, getFileName, getSlotPercents, getSlotIdx };
