import VerificationInput from "react-verification-input";

interface VerificationCodeInputProps {
  onChange: (value: string) => void;
}

function VerificationCodeInput(props: VerificationCodeInputProps) {
  const { onChange } = props;

  return (
    <VerificationInput
      {...{
        length: 4,
        validChars: "0-9",
        placeholder: " ",
        onChange: (code: string) => {
          onChange(code);
        },
        classNames: {
          container: `
          w-full
          flex flex-row justify-between gap-4
          min-h-[100px]
        `,
          character: `
          w-full h-full
          p-[0.3em]
          border-none
          bg-neutral-800
          rounded-[10px]
          text-4xl font-bold text-neutral-50
          leading-normal
        `,
          characterInactive: "",
          characterSelected: ""
        }
      }}
    />
  );
}

export default VerificationCodeInput;
