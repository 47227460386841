import { useEffect, useState, useCallback } from "react";
import { useStoreActions } from ".";
import { ImageData } from "../utils";

const useUserAvatar = (props: {
  picture?: ImageData | string;
  className?: string;
  original?: boolean;
  publicProfile?: boolean;
}) => {
  const { picture = null, original = true, publicProfile = false } = props;
  const [avatar, setAvatar] = useState<ImageData | string | null>(picture ?? null);
  const getAvatar = useStoreActions((actions) => actions.auth.getAvatar);

  const fetchAvatar = useCallback(async () => {
    const avatarResult = await getAvatar(original);
    if (avatarResult.success?.data?.image?.data) {
      setAvatar(avatarResult.success.data.image);
    }
  }, [getAvatar, original]);

  useEffect(() => {
    if (!publicProfile && !avatar) {
      fetchAvatar();
    }
  }, [avatar, publicProfile, fetchAvatar]);

  useEffect(() => {
    if (picture) {
      setAvatar(picture);
    }
  }, [picture]);

  return {
    avatar
  };
};

export default useUserAvatar;
