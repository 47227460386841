import isURL from "validator/lib/isURL";

export type Validation = {
  valid: boolean;
  message?: string;
  started: boolean;
};

export const INPUT_BORDER_STYLES = {
  valid: `
    border-sm border-transparent
  `,
  invalid: `
    border-sm border-secondary-800
  `,
};

export const DEFAULT_STYLES = {
  valid: {
    input: `
      ${INPUT_BORDER_STYLES.valid}
      text-success
    `,
  },
  invalid: {
    input: `
      ${INPUT_BORDER_STYLES.invalid}
      text-secondary-600
    `,
    message: `
      text-secondary-900
    `,
  },
};

export const DEFAULT_POSITIVE_VALUE = {
  valid: true,
  message: "",
  started: false,
};

export const DEFAULT_VALUE = {
  valid: false,
  message: "",
  started: false,
};

export const isNotEmpty = (
  value: string,
  started: boolean = DEFAULT_VALUE.started
): Validation => {
  if (!started) {
    return DEFAULT_VALUE;
  }

  if (value === "") {
    return {
      valid: false,
      started,
      message: "Can't be empty",
    };
  }

  return DEFAULT_POSITIVE_VALUE;
};

const SPEC_CHARS_REGEXP = /\W/g;
export const validateUsername = (
  value: string,
  started: boolean = DEFAULT_VALUE.started
): Validation => {
  if (!started) {
    return DEFAULT_VALUE;
  }

  if (value === "") {
    return {
      valid: false,
      started,
      message: "Can't be empty",
    };
  }
  if (value.match(SPEC_CHARS_REGEXP)) {
    return {
      valid: false,
      started,
      message: "No special characters",
    };
  }
  return DEFAULT_POSITIVE_VALUE;
};

// eslint-disable-next-line no-useless-escape
const EMAIL_REGEXP = /[\w\.+?]+@([\w-]+\.)+[\w-]{2,}/g;
export const validateEmail = (
  value: string,
  started: boolean = DEFAULT_VALUE.started
): Validation => {
  if (!started) {
    return DEFAULT_VALUE;
  }

  if (value === "") {
    return {
      valid: false,
      started,
      message: "Can't be empty",
    };
  }

  if (!value.match(EMAIL_REGEXP)) {
    return {
      valid: false,
      started,
      message: "Invalid value",
    };
  }

  return DEFAULT_POSITIVE_VALUE;
};

export const validatePassword = (
  value: string,
  started: boolean = DEFAULT_VALUE.started
): Validation => {
  if (!started) {
    return DEFAULT_VALUE;
  }

  if (value === "") {
    return {
      valid: false,
      started,
      message: "Can't be empty",
    };
  }

  return DEFAULT_POSITIVE_VALUE;
};

export const validatePhone = (
  value: string,
  started: boolean = DEFAULT_VALUE.started
): Validation => {
  if (!started) {
    return DEFAULT_VALUE;
  }

  if (value === "") {
    return {
      valid: false,
      started,
      message: "Can't be empty",
    };
  }

  return DEFAULT_POSITIVE_VALUE;
};

export const validateUrl = (
  value: string,
  started: boolean = DEFAULT_VALUE.started
): Validation => {
  // if (value === "") {
  //   return {
  //     valid: false,
  //     started,
  //     message: "Can't be empty",
  //   };
  // }

  if (value && !isURL(value)) {
    return {
      valid: false,
      started,
      message: "Invalid url",
    };
  }

  return DEFAULT_POSITIVE_VALUE;
};
