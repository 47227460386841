import axios, { AxiosError, RawAxiosRequestHeaders, ResponseType } from "axios";
import { Result } from "./Api";
import store from "../store/store";
import { User } from "../types/Auth";

const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:5000/api" : "/api";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: API_URL
});

axiosInstance.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=UTF-8"
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const pathname = window.location.pathname;
      if (pathname === "/auth/github/callback") {
        return Promise.reject(error);
      }
      if (pathname !== "/sign-in") {
        store.getActions().auth.userSignOut();
        return (window.location.href = "/sign-in");
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

/** @deprecated */
export const _setAuthToken = (token: string | null) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

export const httpRequest = async (
  method: "post" | "put" | "get" | "delete",
  path: string,
  data = {},
  headers?: RawAxiosRequestHeaders,
  responseType?: ResponseType
) => {
  const result: Result = { success: null, failure: null };
  try {
    axiosInstance.defaults.responseType = responseType ?? "json";
    const storeState = store.getState();
    const token = storeState.auth.token;
    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${token}`
    };
    if (headers) {
      const common = axiosInstance.defaults.headers.common;
      axiosInstance.defaults.headers.common = {
        ...common,
        ...headers
      };
    }
    result.success = await axiosInstance[method](`${path}`, data);
  } catch (error) {
    const err = error as Error | AxiosError;
    console.log(err);
    if (axios.isAxiosError(err)) {
      result.failure = {
        error: err,
        response: err.response?.data,
        status: err.response?.status
      };
    } else {
      result.failure = {
        error: err,
        response: {
          statusText: err?.message
        }
      };
    }
  }
  return result;
};

export const post = async (
  path: string,
  data = {},
  headers?: RawAxiosRequestHeaders
): Promise<any> => {
  return httpRequest("post", path, data, headers);
};

export const del = async (path: string, data = {}): Promise<any> => {
  return httpRequest("delete", path, data);
};

export const put = async (path: string, data = {}, headers = {}): Promise<any> => {
  return httpRequest("put", path, data, headers);
};

export const get = async (path: string, responseType?: ResponseType) => {
  return httpRequest("get", path, undefined, undefined, responseType);
};

export const verifyRefferalLinkCodeService = async (code: string) => {
  return await post("/auth/check-verification-code", {
    verificationCode: code
  });
};

// verify refresh token to generate new access token if refresh token is present
export const verifyTokenService = async (): Promise<any> => {
  return await post(`/auth/verify-token`);
};

export const userSignUpService = async (form: any) => {
  return await post("/auth/sign-up", { ...form });
};

// user signIn API to validate the credential
export const userSignInService = async (email: string, password: string): Promise<any> => {
  return await post(`/auth/sign-in`, { email: email, password });
};

// manage user signOut
export const userSignOutService = async () => {
  return await post(`/auth/sign-out`);
};

export const profileConfirmEmail = async (confirmationCode: number) => {
  return await post(`/profile/confirm-email`, { confirmationCode });
};

export const profileConfirmPhone = async (confirmationCode: number) => {
  return await post(`/profile/confirm-phone`, { confirmationCode });
};

export const resendCodeToEmail = async () => {
  return await post(`/profile/resend-code-to-email`);
};

export const resendCodeToPhone = async () => {
  return await post(`/profile/resend-sms-with-code`);
};

export const forgotPassword = async (email: string) => {
  return await post(`/profile/forgot-password`, { email });
};

export const resetPassword = async (data: { id: string; token: string; password: string }) => {
  return await post(`/profile/reset-password`, data);
};

export const postFractalOauthdata = async (
  code: string | null,
  state: string | null,
  accountId: string,
  userData: User
) => {
  post(`/kyc/oauth/fractal?code=${code}&state=${state}`, {
    accountId,
    userData
  });
};

enum defAuthProviders {
  github,
  google,
  twitter
}

export type AuthProvider = keyof typeof defAuthProviders;

export const getAuthorizeUrl = async (provider: AuthProvider) => {
  const response = await get(`/auth/get-authorize-url?provider=${provider}`);
  return response.success ? response.success.data : null;
};

export const postAuthGithubCallback = async (data: { code: string; state?: string | null }) => {
  return await post("/auth/github/callback", data);
};

export const postAuthGoogleCallback = async (data: { code: string; state?: string | null }) => {
  return await post("/auth/google/callback", data);
};

export const postAuthTwitterCallback = async (data: {
  oauth_token: string;
  oauth_verifier: string;
}) => {
  return await post("/auth/twitter/callback", data);
};
