import { useStoreActions, useStoreState } from "../../../../hooks";
import { DarkButton } from "../../../general/Button";
import Modal from "../../../general/Modal";

export default function ErrorMessage() {
  const { message, isError } = useStoreState((state) => state.error);
  const clearError = useStoreActions((actions) => actions.error.clearError);

  return (
    <div className="font-poppin">
      <Modal
        {...{
          customStyles: { content: "min-h-[300px] h-fit" },
          open: isError,
          onClose: clearError,
          okButton: () => {
            return <DarkButton {...{ onClick: () => clearError(), text: "Got it" }} />;
          }
        }}>
        <div className="text-xl text-white">Something went wrong...</div>
        <div className="text-md break-all text-white overflow-hidden my-2">{message}</div>
      </Modal>
    </div>
  );
}
