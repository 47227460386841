import { useEffect, useMemo, useState } from "react";
import { useStoreState } from "../../../hooks";
import { Api } from "../../../services/profileApi";
import { CompanyMetadata, IndividualMetadata, PublicProfile } from "../../../types/Auth";
import { Bounty } from "../../../types/Bounty";
import Avatar from "../../general/Avatar";
import { Area } from "../../Layout";
import Info from "../../Section/Profile/Info";
import Separator from "../../general/Separator";
import { CoinIcon, LocationIcon, UserIcon, WebsiteIcon } from "../../general/SVGIcon";
import BountiesPostedOpenedIcon from "../../general/SVGIcon/bountiesPostedOpened";
import Link from "../../general/Link";
import { getPaidAmount } from "./helpers";

const OwnerDetails = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  const [details, setDetails] = useState<PublicProfile<
    IndividualMetadata | CompanyMetadata
  > | null>(null);
  const countries = useStoreState((state) => state.app.countries);
  const { posted, opened, paid } = bounty?.ownerDetails || {};
  const tokens = useStoreState((state) => state.app.tokens);

  const loadClientDetails = async (username: string, type?: string) => {
    const publicProfile = await Api.getPublicProfile(username, type);
    if (publicProfile?.success) {
      setDetails(publicProfile.success.data);
    }
  };

  useEffect(() => {
    if (bounty.ownerDetails?.username) {
      loadClientDetails(bounty.ownerDetails.username, bounty.ownerDetails.profileType);
    }
  }, [bounty.ownerDetails?.username]);

  const mapCountry = useMemo(() => {
    const countryKey = bounty.ownerDetails?.country ?? null;
    return countryKey && Object.keys(countries).includes(countryKey) ? countries[countryKey] : null;
  }, [countries, bounty.ownerDetails?.country]);

  const checkProtocol = (url: string) => {
    return url.includes("https") ? url : url.replace("http", "https");
  };

  const getSiteName = (url: string) => {
    return checkProtocol(url).split("https://")[1];
  };

  return (
    <div className="lg:flex-none sm:flex-1 min-w-[256px]">
      <Area
        {...{
          customStyles: {
            borderContainer: "border border-sm border-neutral-800",
            contentContainer: "bg-neutral-850"
          }
        }}>
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-col mr-4">
              <span className="uppercase break-words">
                {details &&
                ((details as IndividualMetadata)?.individual?.name ||
                  (details as CompanyMetadata)?.company?.companyName) ? (
                  <a
                    href={`/user-info/${details.username}?type=${details.profileType}`}
                    rel="noopener">
                    {(details as IndividualMetadata)?.individual?.name}
                    {(details as CompanyMetadata)?.company?.companyName}
                  </a>
                ) : (
                  <>{bounty?.ownerDetails?.username ?? bounty.owner}</>
                )}
              </span>
              <span className="text-md text-primary-800 break-all">{bounty.owner}</span>
              <span className="text-md text-neutral-700">Client</span>
            </div>
            <div>
              <Avatar
                picture={bounty.ownerDetails?.picture}
                publicProfile={true}
                className="w-12 h-12 mt-0 min-w-[48px]"
              />
            </div>
          </div>
        </div>
        <div className="break-words">
          <Separator />
          <div className="flex lg:flex-wrap lg:flex-row sm:flex-col gap-8 p-6">
            {details?.country && (
              <Info
                {...{
                  icon: <LocationIcon />,
                  customStyles: {
                    wrapper: "lg:flex gap-6 sm:block",
                    container: "flex-col-reverse"
                  },
                  label: "Location",
                  value: mapCountry
                }}
              />
            )}
            {posted !== undefined && opened !== undefined && (
              <Info
                {...{
                  icon: <BountiesPostedOpenedIcon />,
                  customStyles: {
                    wrapper: "lg:flex gap-6 sm:block",
                    container: "flex-col-reverse"
                  },
                  label: `${opened} open bounties`,
                  value: `${posted} bounties posted`
                }}
              />
            )}
            {Array.isArray(paid) && paid.length > 0 && (
              <Info
                {...{
                  icon: <CoinIcon />,
                  customStyles: {
                    wrapper: "lg:flex gap-6 sm:block",
                    container: "flex-col-reverse break-all"
                  },
                  label: `Bounties paid out`,
                  value: `${getPaidAmount(paid)}`.slice(0, -2)
                }}
              />
            )}
            {details && (
              <>
                {details.type === "Company" && (
                  <>
                    {(details as CompanyMetadata)?.company.companySite && (
                      <Info
                        {...{
                          icon: <WebsiteIcon />,
                          customStyles: {
                            wrapper: "lg:flex gap-6 sm:block break-all",
                            value: "underline"
                          },
                          label: ""
                        }}>
                        <a
                          href={checkProtocol(
                            (details as CompanyMetadata)?.company?.companySite ?? ""
                          )}
                          className="text-primary-900"
                          target="_blank"
                          rel="noreferrer">
                          {getSiteName((details as CompanyMetadata)?.company?.companySite ?? "")}
                        </a>
                      </Info>
                    )}
                  </>
                )}
                {details.type === "Individual" && (
                  <>
                    {(details as IndividualMetadata)?.individual?.github && (
                      <Info
                        {...{
                          icon: <WebsiteIcon />,
                          customStyles: {
                            wrapper: "lg:flex gap-6 sm:block",
                            value: "underline"
                          },
                          label: "Github"
                        }}>
                        <div className="block break-all">
                          <a
                            href={checkProtocol(
                              (details as IndividualMetadata)?.individual?.github
                            )}
                            className="text-primary-900"
                            target="_blank"
                            rel="noreferrer">
                            {getSiteName((details as IndividualMetadata)?.individual?.github)}
                          </a>
                        </div>
                      </Info>
                    )}
                    {details &&
                    ((details as IndividualMetadata)?.individual?.name ||
                      (details as CompanyMetadata)?.company?.companyName) ? (
                      <Info
                        {...{
                          customStyles: { wrapper: "lg:flex gap-6 sm:block" },
                          icon: <UserIcon className="stroke-neutral-200" />,
                          label: ""
                        }}>
                        <Link
                          link={`/user-info/${details.username}`}
                          text="Heroes profile"
                          target="_self"
                          rel="noopener"
                          style="underline text-primary-900"
                        />
                      </Info>
                    ) : null}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Area>
    </div>
  );
};

export default OwnerDetails;
