import { useQuery } from "@tanstack/react-query";
import Api from "../services/Api";
import { Bounty } from "../types/Bounty";
import { useQueryParams, StringParam } from "use-query-params";
import {
  encodeDelimitedArray,
  encodeString,
  decodeDelimitedArray,
  decodeQueryParams,
  decodeString
} from "serialize-query-params";
import { createSearchParams } from "react-router-dom";
import { stringify } from "query-string";
import { urlDefSearchParams } from "../store/models/BountyListModel";

export type Response = {
  has_more: boolean;
  has_prev: boolean;
  item_count: number;
  page: number;
  page_count: number;
  bounties: Bounty[];
};

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
const CommaArrayParam = {
  encode: (array: string[]): string | null | undefined => {
    return array?.length ? encodeDelimitedArray(array, ",") : undefined;
  },

  decode: (
    arrayStr: string | (string | null)[] | null | undefined
  ): (string | null)[] | null | undefined => {
    const decoded = decodeDelimitedArray(arrayStr, ",");
    return typeof decoded === "undefined" ? [] : decoded;
  }
};

const CustomStringParam = {
  encode: (
    value: string | (string | null)[] | null | undefined
  ): string | (string | null)[] | null | undefined => {
    return encodeString(value) ? encodeString(value) : undefined;
  },
  decode: (
    input: string | (string | null)[] | null | undefined
  ): string | (string | null)[] | null | undefined => {
    return decodeString(input) ? decodeString(input) : [];
  }
};

const useBountiesSearchParams = (props: { limit?: number; page?: number }) => {
  const { limit = 5, page = 1 } = props;
  const [query, setQuery] = useQueryParams({
    text: StringParam,
    status: StringParam,
    categories: CustomStringParam,
    tags: CustomStringParam,
    experience: CustomStringParam,
    noKyc: StringParam,
    whitelistDisabled: StringParam
  });

  const decodedQueryParams = decodeQueryParams(
    {
      text: StringParam,
      status: CommaArrayParam,
      categories: CommaArrayParam,
      tags: CommaArrayParam,
      experience: CommaArrayParam,
      noKyc: StringParam,
      whitelistDisabled: StringParam
    },
    query
  );

  const fetchData = Api.getFilteredBounties;
  const { data, error, isFetching, status } = useQuery(
    ["items", query, limit, page],
    () => {
      return fetchData({
        page,
        limit,
        searchParams: query
      }).then((res: Response) => {
        return res;
      });
    },
    {}
  );

  const getUrlParams = () =>
    createSearchParams(
      stringify(decodedQueryParams, {
        arrayFormat: "separator",
        arrayFormatSeparator: ",",
        skipNull: true
      })
    );

  const getUrlParamsOrDefault = () => {
    return getUrlParams().toString().length > 0 ? getUrlParams() : urlDefSearchParams();
  };

  return {
    data,
    error,
    status,
    isFetching,
    searchParams: decodedQueryParams,
    setQuery,
    getUrlParams,
    getUrlParamsOrDefault
  };
};

export default useBountiesSearchParams;
