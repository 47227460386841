import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../../hooks";
import { urlDefSearchParams } from "../../../store/models/BountyListModel";

type BreadcrumbProps = {
  label: string;
  category?: boolean;
  navigateFunc?: () => void;
};

const Breadcrumbs = (props: { links: BreadcrumbProps[] }) => {
  const { links } = props;
  const navigate = useNavigate();
  const filters = useStoreState((state) => state.bountyList.filters);
  const setFilters = useStoreActions((actions) => actions.bountyList.setFilters);

  const handleCategoryClick = (category: string) => {
    setFilters({
      ...filters,
      categories: [{ name: category, value: category }]
    });
    navigate(`/bounties?${urlDefSearchParams()}`);
  };

  return (
    <>
      {links.length > 0 &&
        links.map((link, index) => (
          <span
            key={link.label}
            className={classNames("font-poppins text-sm", {
              "text-neutral-500 hover:text-primary-900": index !== links.length - 1,
              "text-neutral-200": index === links.length - 1
            })}>
            {link.category ? (
              <button className="" onClick={() => handleCategoryClick(link.label)}>
                {link.label}
              </button>
            ) : (
              <>
                {typeof link?.navigateFunc !== "undefined" ? (
                  <>
                    <button className="" onClick={link.navigateFunc}>
                      {link.label}
                    </button>
                  </>
                ) : (
                  <>{link.label}</>
                )}
              </>
            )}
            <span className="text-neutral-500">{index !== links.length - 1 && " / "}</span>
          </span>
        ))}
    </>
  );
};

export default Breadcrumbs;
