import {
  ChangeEvent,
  ReactNode,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactElement
} from "react";

import Label from "./Label";
import { Validation, DEFAULT_STYLES, DEFAULT_VALUE } from "../../../helpers/Validation";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

type InputStyles = {
  container?: string;
  input?: string;
  label?: string;
  svgIcon?: string;
};

type TLabel = {
  view?: ReactNode;
  text: string;
  textInfo?: string;
};

interface InputProps {
  InputElement?: (...args: any) => any;
  readOnly?: boolean;
  customStyles?: InputStyles;
  label?: TLabel;
  placeholder?: string;
  className?: string;
  startValidation?: boolean;
  onChange?: (...args: any[]) => void;
  onClick?: (event: MouseEvent) => MouseEvent;
  onKeyDown?: (value: string) => void;
  onBlur?: () => void;
  type?: string;
  name?: string;
  value?: string;
  validation?: Validation;
  disabled?: boolean;
  autoFocus?: boolean;
  svgIcon?: ReactElement;
  autocomplete?: "on" | "off";
  rows?: number;
}
export { type InputProps };

export const buildStyles = (
  customStyles: InputStyles | undefined = undefined,
  validation: Validation = DEFAULT_VALUE,
  isSvgIconPresented: boolean = false,
  disabled: boolean = false
) => ({
  container: twMerge(`
    w-full
    ${customStyles?.container ? customStyles.container : ""}
    ${disabled ? "!cursor-not-allowed" : ""}
  `),
  input: twMerge(`
    w-full
    text-sm
    bg-neutral-800
    rounded-[10px]
    p-[0.8em]
    px-[20px] py-[15px]
    outline-none
    ${isSvgIconPresented ? "pl-[calc(var(--input-icon-size)+var(--input-icon-pl)*2)]" : ""}
    ${
      validation.started
        ? validation.valid
          ? DEFAULT_STYLES.valid.input
          : DEFAULT_STYLES.invalid.input
        : "text-neutral-400"
    }
    ${disabled ? "!cursor-not-allowed" : ""}
    ${customStyles?.input ? customStyles.input : ""}
  `),
  svgIcon: twMerge(
    classNames(
      "h-full w-[var(--input-icon-size)] flex flex-col justify-center",
      {
        "pl-[calc(var(--input-icon-pl))]": isSvgIconPresented,
        "!cursor-not-allowed": disabled,
        "cursor-pointer": !disabled
      },
      customStyles?.svgIcon
    )
  ),
  label: {
    labelText: `
      ${customStyles?.label ? customStyles.label : ""}
    `,
    validationText: `
      ${DEFAULT_STYLES.invalid.message}
    `
  }
});

const defaultProps = {
  type: "text",
  readOnly: false,
  autocomplete: "on",
  InputElement: (
    props: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  ) => <input {...props} />
};

export default function Input(props: InputProps) {
  const {
    readOnly,
    customStyles,
    label,
    value,
    InputElement,
    type,
    name,
    placeholder,
    onChange,
    onClick,
    onKeyDown,
    disabled,
    svgIcon,
    onBlur,
    autocomplete,
    rows = 2
  } = { ...defaultProps, ...props };
  const validation = props.validation || DEFAULT_VALUE;

  const styles = buildStyles(customStyles, validation, !!svgIcon, disabled);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (onChange) {
      onChange(value, name);
    }
  };

  const handleClick = (event: MouseEvent) => {
    onClick && onClick(event);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { key } = event;

    if (onKeyDown) {
      onKeyDown(key);
    }
  };

  return (
    <div className={styles.container}>
      <Label
        {...{
          customStyles: styles.label,
          labelText: label?.text || "",
          validationText: validation.message || "",
          textInfo: label?.textInfo ?? ""
        }}
      />
      <div className="flex relative">
        {!!svgIcon ? (
          <div
            className={`
              absolute h-full
            `}>
            <div className={styles.svgIcon}>{svgIcon}</div>
          </div>
        ) : null}
        <InputElement
          {...{
            id: name ? `form_input_${name}` : "",
            className: styles.input,
            placeholder,
            type,
            readOnly,
            spellCheck: "false",
            value: value,
            onChange: handleChange,
            onClick: handleClick,
            onKeyDown: handleKeyDown,
            onBlur: onBlur,
            name: name || "",
            disabled: disabled,
            autoComplete: autocomplete,
            rows: rows
          }}
        />
      </div>
    </div>
  );
}
