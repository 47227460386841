import type { AccountState, NetworkId, WalletSelector } from "@near-wallet-selector/core";
import { setupWalletSelector } from "@near-wallet-selector/core";
import type { WalletSelectorModal } from "@near-wallet-selector/modal-ui";
import { setupModal } from "@near-wallet-selector/modal-ui";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import * as buffer from "buffer";
import type { ReactNode } from "react";
import React, { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { distinctUntilChanged, map } from "rxjs";
import { nearConfig } from "../services/nearConfig";
import { useStoreActions, useStoreState } from "../hooks";
import { setupSender } from "@near-wallet-selector/sender";
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import { setupHereWallet } from "@near-wallet-selector/here-wallet";
import { setupWalletConnect } from "@near-wallet-selector/wallet-connect";
import { onhideModalHandler } from "../components/WalletSelector/helpers";

window.Buffer = buffer.Buffer;

declare global {
  interface Window {
    selector: WalletSelector;
    modal: WalletSelectorModal;
  }
}

interface WalletSelectorContextValue {
  selector: WalletSelector;
  modal: WalletSelectorModal;
  accounts: Array<AccountState>;
  accountId: string;
}

const WalletSelectorContext = React.createContext<WalletSelectorContextValue | null>(null);

export const WalletSelectorContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const walletSelector = useStoreState((state) => state.nearApi.walletSelector);
  const setWalletSelector = useStoreActions((actions) => actions.nearApi.setWalletSelector);
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [accounts, setAccounts] = useState<Array<AccountState>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const CONTRACT_ID = useStoreState((state) => state.app.config?.bounty_contract_id) ?? "";
  const NETWORK_ID = nearConfig.networkId;

  const init = useCallback(async () => {
    const _selector = await setupWalletSelector({
      network: NETWORK_ID as NetworkId,
      debug: false,
      allowMultipleSelectors: false,
      modules: [
        setupMyNearWallet(),
        setupSender(),
        setupMeteorWallet(),
        setupHereWallet(),
        setupWalletConnect({
          projectId: "heroes.build",
          metadata: {
            name: "HEROES Wallet Selector",
            description: "HEROES dApp used by NEAR Wallet Selector",
            url: "https://github.com/HeroesHQ/heroes-ui",
            icons: ["https://avatars.githubusercontent.com/u/37784886"]
          }
        })
      ]
    });
    const _modal = setupModal(_selector, {
      contractId: CONTRACT_ID
    });
    const state = _selector.store.getState();
    setAccounts(state.accounts);

    window.selector = _selector;
    window.modal = _modal;

    setWalletSelector(_selector);
    setModal(_modal);
    setLoading(false);
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
      alert("Failed to initialise wallet selector");
    });
  }, [init]);

  useEffect(() => {
    if (!walletSelector) {
      return;
    }

    const subscription = walletSelector.store.observable
      .pipe(
        map((state) => state.accounts),
        distinctUntilChanged()
      )
      .subscribe((nextAccounts) => {
        setAccounts(nextAccounts);
      });

    const onHideSubscription =
      modal &&
      modal.on("onHide", (event) => {
        onhideModalHandler(event, walletSelector);
      });

    return () => {
      subscription.unsubscribe();
      onHideSubscription?.remove();
    };
  }, [walletSelector, modal]);

  const walletSelectorContextValue = useMemo<WalletSelectorContextValue>(
    () => ({
      selector: walletSelector!,
      modal: modal!,
      accounts,
      accountId: accounts.find((account) => account.active)?.accountId || ""
    }),
    [walletSelector, modal, accounts]
  );

  if (loading) {
    return null;
  }

  return (
    <WalletSelectorContext.Provider value={walletSelectorContextValue}>
      {children}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  const context = useContext(WalletSelectorContext);

  if (!context) {
    throw new Error("useWalletSelector must be used within a WalletSelectorContextProvider");
  }

  return context;
}
