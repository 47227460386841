import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { DarkButton } from "../../../../../../../general/Button";

const ActionButton = (props: {
  text: string;
  disabled?: boolean;
  handleClick: () => void;
  customStyles?: {
    button?: string;
    text?: string;
  };
}) => {
  const { disabled = false, text, handleClick, customStyles } = props;
  return (
    <DarkButton
      {...{
        customStyles: {
          button: twMerge(
            classNames("max-w-[146px] border-neutral-50 hover:shadow-none", customStyles?.button)
          ),
          text: twMerge(classNames("!text-sm text-neutral-50", customStyles?.text))
        },
        disabled: disabled,
        text: text,
        onClick: () => handleClick()
      }}
    />
  );
};

export default ActionButton;
