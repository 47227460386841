import classNames from "classnames";

const Amount = (props: {
  symbol: string;
  amount: string;
  className?: string;
}) => {
  const { symbol, amount, className } = props;

  return (
    <div className={classNames(className)}>
      {amount} {symbol}
    </div>
  );
};

export default Amount;
