import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { defExperienceLevels } from "../../../../../constants";
import { useStoreState } from "../../../../../hooks";
import { Bounty } from "../../../../../types/Bounty";
import { DarkButton } from "../../../../general/Button";
import { ValidationLayout } from "../../../../Layout";
import Modal from "../../../../general/Modal";
import { FormRow } from "../../../../general/Form/FormElements";
import { Option } from "../../../../general/Select/Select";
import { MultiSelect, NewSelect } from "../../../../general/Select";

type SkillsAndExperienceFormData = {
  type: string;
  experience: string;
  tags: string;
};

const EditSkillsAndExperienceForm = (props: {
  show: boolean;
  loading: boolean;
  handleClose: () => void;
  handleSubmit: (value: { tags: string[]; experience: string; category: string }) => void;
  bounty: Bounty;
}) => {
  const { show, handleClose, handleSubmit, bounty, loading } = props;
  const methods = useForm<SkillsAndExperienceFormData>({
    mode: "onChange"
  });
  const { register, setValue, formState } = methods;
  const { errors, isSubmitted, isValid } = formState;
  const config = useStoreState((state) => state.app.config);
  const [category, setCategory] = useState(bounty.metadata.category);
  const [experience, setExperience] = useState(bounty.metadata.experience);
  const [tags, setTags] = useState(bounty.metadata.tags);

  const tagsList = useMemo(() => {
    return (
      config?.tags.map((tag) => {
        return { name: tag, value: tag };
      }) || []
    );
  }, [config?.tags]);

  const bountyTypes = useMemo(() => {
    return (
      config?.categories.map((category) => {
        return { name: category, value: category };
      }) || []
    );
  }, [config?.categories]);

  const handleTypeChange = (selected: { name: string; value: string }) => {
    setCategory(selected.value);
  };

  const handleMultiSelectTagsChange = (values: Option[]) => {
    setTags(values.map((value) => value.name));
  };

  const handleTagsChange = (value: Option) => {
    setTags([value.name]);
  };
  const handleExperienceChange = (selected: { name: string; value: string }) => {
    setExperience(selected.value);
  };

  const handleSubmitClick = () => {
    if (isValid) {
      handleSubmit({ tags, experience, category });
    }
  };

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                disabled: loading,
                onClick: () => methods.handleSubmit(handleSubmitClick)(),
                text: !loading ? "Update" : "Submitting..."
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white mb-2.5">Edit Skills and Experience</div>
        <div className="py-2.5">
          <FormRow>
            <input
              type="hidden"
              defaultValue={category}
              {...register("type", { required: "Required field" })}
            />
            <ValidationLayout
              {...{
                validate: () => {
                  return {
                    valid: !errors.type,
                    started: isSubmitted,
                    message: errors.type?.message
                  };
                }
              }}>
              <NewSelect
                {...{
                  placeholder: "Type",
                  selected: { name: category, value: category },
                  defaultOptionsList: bountyTypes,
                  defaultOption: { name: category, value: category },
                  onSelect: (selected: { name: string; value: string }) => {
                    setValue("type", selected.value, {
                      shouldValidate: true
                    });
                    handleTypeChange(selected);
                  }
                }}
              />
            </ValidationLayout>
          </FormRow>
          <FormRow>
            <input
              type="hidden"
              defaultValue={tags}
              {...register("tags", { required: "Required field" })}
            />
            <ValidationLayout
              {...{
                validate: () => {
                  return {
                    valid: !errors.tags,
                    started: isSubmitted,
                    message: errors.tags?.message
                  };
                }
              }}>
              <MultiSelect
                {...{
                  placeholder: "Tags",
                  defaultOption: { name: "default", value: "" },
                  defaultOptionsList: tagsList,
                  selected: { name: "", value: "" },
                  multiSelected: tags.map((tag) => {
                    return { name: tag, value: tag };
                  }),
                  onMultiSelect: (selected: Option[]) => {
                    handleMultiSelectTagsChange(selected);
                  },
                  onSelect: (selected: Option) => {
                    setValue("tags", selected.name, { shouldValidate: true });
                    handleTagsChange(selected);
                  }
                }}
              />
            </ValidationLayout>
          </FormRow>
          <FormRow>
            <input
              type="hidden"
              defaultValue={experience}
              {...register("experience", { required: "Required field" })}
            />
            <ValidationLayout
              {...{
                validate: () => {
                  return {
                    valid: !errors.experience,
                    started: isSubmitted,
                    message: errors.experience?.message
                  };
                }
              }}>
              <NewSelect
                {...{
                  placeholder: "Experience level",
                  selected: { name: experience, value: experience },
                  defaultOptionsList: defExperienceLevels,
                  defaultOption: { name: experience, value: experience },
                  onSelect: (selected: { name: string; value: string }) => {
                    setValue("experience", selected.value, {
                      shouldValidate: true
                    });
                    handleExperienceChange(selected);
                  }
                }}
              />
            </ValidationLayout>
          </FormRow>
        </div>
      </div>
    </Modal>
  );
};

export default EditSkillsAndExperienceForm;
