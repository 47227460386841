import { DarkButton } from "../../../general/Button";
import { FormRow } from "../../../general/Form/FormElements";
import Modal from "../../../general/Modal";

export default function ApproveSelectedBountyForm(props: {
  show: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  const { show, handleClose, handleSubmit } = props;

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit min-h-[300px] bg-neutral-900" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                onClick: () => handleSubmit(),
                text: "Approve"
              }}
            />
          );
        }
      }}>
      <FormRow className="font-poppins py-2.5">
        <FormRow>
          <h2 className="text-neutral-50 text-lg ">Approve Heroes!</h2>
        </FormRow>
        <FormRow className="py-2.5 text-neutral-200">
          You are about to approve these Heroes' work for your bounties and agree to release the
          rewards!
        </FormRow>
      </FormRow>
    </Modal>
  );
}
