import { useState, useContext } from "react";
import { useStoreActions, useStoreState } from "../../../../hooks";
import useCurrencies from "../../../../hooks/useCurrencies";
import utils from "../../../../services/utils";
import { Bounty, NextActionModel } from "../../../../types/Bounty";
import NextActionsContext from "../../../Bounty/general/context/NextActionsContext";
import EditAmountForm from "../Dialogues/EditAmountForm";
import EditIconButton from "./general/EditIconButton";
import useWalletAccountId from "../../../../hooks/useWalletAccountId";
import { FinalExecutionOutcomeForm } from "../../../Bounty/Dialogues";
import { providers } from "near-api-js";

const EditAmount = (props: { bounty: Bounty; refetchActions: () => void }) => {
  const { bounty, refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<providers.FinalExecutionOutcome | null>(
    null
  );
  const tokens = useStoreState((state) => state.app.tokens);
  const { currencies } = useCurrencies();
  const context = useContext(NextActionsContext);
  const nextActions = context as Partial<NextActionModel>[] | null;
  const { walletAccountId } = useWalletAccountId();
  const [loading, setLoading] = useState(false);

  const updateBountyV2 = useStoreActions((actions) => actions.nearApi.updateBountyV2);

  const isHidden = () => {
    const action = nextActions?.find((item) => item.accountId === walletAccountId);
    return !action?.nextActions?.bountyUpdateAmount;
  };

  const getTokenDecimals = (tokenId: string) => {
    return tokens.find((token) => token.tokenId === tokenId)?.decimals || 0;
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async ({ amount }: { amount: string }) => {
    setLoading(true);
    let formattedAmount = utils.getParsedTokenAmount(amount, getTokenDecimals(bounty?.token ?? ""));
    if (bounty.currency) {
      const currCurrency = currencies.find(
        (currencyItem) => currencyItem.symbol === bounty.currency
      );
      if (currCurrency) {
        formattedAmount = utils.getParsedTokenAmount(amount, currCurrency?.decimals ?? 2);
      }
    }
    const result = await updateBountyV2({
      id: bounty.id,
      bounty_update: {
        amount: formattedAmount
      }
    });
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
    }
  };

  return bounty?.postpaid ? (
    <>
      {!isHidden() && (
        <>
          <EditIconButton handleSubmit={() => handleButtonClick()} />

          <EditAmountForm
            show={showForm}
            loading={loading}
            handleClose={handleButtonClick}
            handleSubmit={handleSubmitForm}
            bounty={bounty}
          />
          <FinalExecutionOutcomeForm
            show={showFinalExecutionOutcomeForm}
            executionOutcome={executionOutcome}
            refetchActions={refetchActions}
            handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
          />
        </>
      )}
    </>
  ) : null;
};

export default EditAmount;
