import { useEffect } from "react";
import classNames from "classnames";
import React, { useState } from "react";
import { AppMessages } from "../../../common/app-messages";
import { bountyStatus, defExperienceLevels, DEV_MODE } from "../../../constants";
import { useStoreState } from "../../../hooks";
import { defaultUrlSearchParams } from "../../../store/models/BountyListModel";
import NewMultiSelect from "../../general/Select/NewMultiSelect";
import { Option } from "../../general/Select/Select";
import { WarningIcon } from "../../general/SVGIcon";
import { Area } from "../../Layout";
import WalletSelector from "../../WalletSelector";
import mixpanel from "mixpanel-browser";
import { useWalletSelector } from "../../../providers/WalletSelectorContext";

const SearchParamsFilterBounties = (props: { query: any; setQuery: (...args: any[]) => void }) => {
  const { query, setQuery } = props;
  type ConditionOption = Option & {
    active: boolean | undefined;
  };
  const conditionsOptions: ConditionOption[] = [
    {
      name: "noKyc",
      value: "No KYC",
      active: query?.noKyc === "true"
    },
    {
      name: "whitelistDisabled",
      value: "Whitelist Disabled",
      active: query?.whitelistDisabled === "true"
    }
  ];

  const selectedConditionsOptions = conditionsOptions.filter((opt) => opt.active === true);

  const { selector } = useWalletSelector();
  const isSignedIn = selector.isSignedIn();
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);
  const config = useStoreState((state) => state.app.config);
  const multiSelectStyles = {
    container: "w-full",
    selectedItem: "bg-neutral-900 p-0 radius-[10px]",
    optionsList: {
      container: "bg-neutral-900 border border-sm min-w-fit border-neutral-700"
    }
  };

  const detailsOptions = [
    {
      name: "active",
      title: "Active"
    },
    {
      name: "inActive",
      title: "Inactive"
    }
  ];

  const [switchOptions, setSwitchOptions] = useState(
    detailsOptions.find((option) =>
      query?.status?.includes(bountyStatus.Completed)
        ? option.name === "inActive"
        : option.name === "active"
    )
  );

  const multiselectChange = (props: { [key: string]: string[] }) => {
    Object.keys(props).forEach((key) => {
      setQuery({ [key]: props[key] });
    });
  };

  const handleConditionsChange = (conditions: string[]) => {
    const c: { [key: string]: string | boolean | undefined } = {};
    for (const opt of conditionsOptions) {
      if (!conditions.includes(opt.name)) {
        c[opt.name] = undefined;
      } else {
        c[opt.name] = "true";
      }
    }
    setQuery(c);
  };

  const handleRemoveFilter = (name: string, value?: string) => {
    setQuery((prev: any) => {
      if (Array.isArray(query[name])) {
        prev[name] = prev[name]
          .split(",")
          .filter((item: string) => item !== value)
          .join(",");
      } else {
        if (prev[name] === "true") {
          prev[name] = undefined;
        }
      }
      return prev;
    });
  };

  useEffect(() => {
    if (!DEV_MODE) {
      mixpanel.track(`All ${switchOptions?.title}`, {
        source: window.location.hostname
      });
    }
  }, [switchOptions]);

  const handleToggleOptions = (e: React.MouseEvent, value: { name: string; title: string }) => {
    e.preventDefault();
    setSwitchOptions(value);
    if (value.name === "active") {
      setQuery({
        status: [
          bountyStatus.New,
          bountyStatus.Claimed,
          bountyStatus.ManyClaimed,
          bountyStatus.AwaitingClaims
        ]
      });
    } else {
      setQuery({
        status: [bountyStatus.Completed, bountyStatus.Canceled, bountyStatus.PartiallyCompleted]
      });
    }
  };

  const handleResetClick = () => {
    setQuery(defaultUrlSearchParams);
  };

  return (
    <>
      <div className="font-poppins flex gap-6 py-2.5 justify-start">
        {detailsOptions.map((toggle) => (
          <div key={toggle.name} className="relative flex gap-2">
            {toggle.name === switchOptions?.name ? (
              <>
                <button
                  className="py-2 focus-visible:outline-none text-md text-primary-900 border-b-4 border-primary-900"
                  onClick={(e: React.MouseEvent) => handleToggleOptions(e, toggle)}>
                  {toggle.title}
                </button>
              </>
            ) : (
              <>
                <button
                  className="py-2 focus-visible:outline-none text-md text-white"
                  onClick={(e: React.MouseEvent) => handleToggleOptions(e, toggle)}>
                  {toggle.title}
                </button>
              </>
            )}
          </div>
        ))}
      </div>
      <div className="my-6">
        {isAuthenticated && (
          <div className="mt-6">
            {!isSignedIn && (
              <Area
                {...{
                  customStyles: {
                    borderContainer: classNames("border border-neutral-700", {
                      "border-md border-secondary-800": !isSignedIn
                    }),
                    contentContainer: classNames("", {
                      "bg-secondary-900/20": !isSignedIn
                    })
                  }
                }}>
                <div className="p-6 flex flex-col flex-wrap">
                  <div className="mb-4">
                    <div className="flex items-center gap-4 text-secondary-800">
                      <div className="w-[32px] h-[32px]">
                        <WarningIcon className="w-8 h-8 fill-secondary-800" />
                      </div>
                      <AppMessages.ConnectWalletMessage className="text-md" />
                    </div>
                  </div>
                  <>
                    <WalletSelector />
                  </>
                </div>
              </Area>
            )}
          </div>
        )}
      </div>
      <div>
        <div className="flex justify-between items-center flex-wrap gap-4">
          <div className="flex flex-wrap gap-4">
            <NewMultiSelect
              {...{
                placeholder: "Category",
                selected: { name: "", value: "" },
                onSelect: () => {},
                defaultOption: { name: "", value: "" },
                defaultOptionsList: config?.categories
                  ? config.categories.map((tag) => ({
                      name: tag.toLowerCase(),
                      value: tag
                    }))
                  : [],
                multiSelected:
                  query?.categories?.map((item: string) => {
                    return { name: item.toLowerCase(), value: item };
                  }) || [],
                onMultiSelect: (selected: Option[]) => {
                  multiselectChange({
                    categories: selected.map((item) => item.name.toLowerCase())
                  });
                },
                showCheckbox: true,
                customStyles: { ...multiSelectStyles, container: "w-[86px]" }
              }}
            />
            <NewMultiSelect
              {...{
                placeholder: "Tags",
                selected: { name: "", value: "" },
                onSelect: () => {},
                defaultOption: { name: "", value: "" },
                defaultOptionsList: config?.tags
                  ? config.tags.map((tag) => ({
                      name: tag.toLowerCase(),
                      value: tag
                    }))
                  : [],
                multiSelected:
                  query?.tags?.map((item: string) => {
                    return { name: item.toLowerCase(), value: item };
                  }) || [],
                onMultiSelect: (selected: Option[]) => {
                  multiselectChange({ tags: selected.map((item) => item.name.toLowerCase()) });
                },
                showCheckbox: true,
                customStyles: { ...multiSelectStyles, container: "w-14" }
              }}
            />
            <NewMultiSelect
              {...{
                placeholder: "Experience",
                selected: { name: "", value: "" },
                onSelect: () => {},
                defaultOption: { name: "", value: "" },
                defaultOptionsList: defExperienceLevels.map((item: Option) => {
                  return { name: item.name.toLowerCase(), value: item.value };
                }),
                multiSelected:
                  query?.experience?.map((item: string) => {
                    return { name: item.toLowerCase(), value: item };
                  }) || [],
                onMultiSelect: (selected: Option[]) => {
                  multiselectChange({
                    experience: selected.map((item) => item.name.toLowerCase())
                  });
                },
                showCheckbox: true,
                customStyles: { ...multiSelectStyles, container: "w-24" }
              }}
            />
            <NewMultiSelect
              {...{
                placeholder: "Additional conditions",
                selected: { name: "", value: "" },
                onSelect: () => {},
                defaultOption: { name: "", value: "" },
                defaultOptionsList: conditionsOptions,
                multiSelected: selectedConditionsOptions,
                onMultiSelect: (selected: Option[]) => {
                  handleConditionsChange(selected.map((item) => item.name));
                },
                showCheckbox: true,
                customStyles: { ...multiSelectStyles, container: "w-[166px]" }
              }}
            />
          </div>
          <button
            className="bg-transparent text-neutral-400 hover:text-primary-900"
            onClick={handleResetClick}>
            Reset
          </button>
        </div>
        {query && (
          <div className="flex flex-wrap gap-2 mt-4">
            {Object.keys(query)
              .filter((key) => key !== "status")
              .map((key) =>
                Array.isArray(query[key]) ? (
                  query[key]?.map((el: string) => (
                    <div
                      key={el}
                      className="flex gap-4 bg-[#2E2E2EBF] py-2 px-5 rounded-[30px] text-neutral-200">
                      <span className="flex items-center text-xs">{el}</span>
                      <button onClick={() => handleRemoveFilter(key, el)}>x</button>
                    </div>
                  ))
                ) : (
                  <>
                    {query[key] === "true" && (
                      <div
                        key={key}
                        className="flex gap-4 bg-[#2E2E2EBF] py-2 px-5 rounded-[30px] text-neutral-200">
                        <span className="flex items-center text-xs">{key}</span>
                        <button onClick={() => handleRemoveFilter(key)}>x</button>
                      </div>
                    )}
                  </>
                )
              )}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchParamsFilterBounties;
