import { useState, useContext } from "react";
import { useStoreActions } from "../../../../hooks";
import useWalletAccountId from "../../../../hooks/useWalletAccountId";
import utils from "../../../../services/utils";
import { Bounty, NextActionModel } from "../../../../types/Bounty";
import { ContactDetailsModel } from "../../../../types/BountyCreate";
import NextActionsContext from "../../../Bounty/general/context/NextActionsContext";
import EditContactDetailsForm from "../Dialogues/EditContactDetailsForm";
import EditIconButton from "./general/EditIconButton";

const EditContactDetails = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  const [showForm, setShowForm] = useState(false);
  const updateBountyV2 = useStoreActions((actions) => actions.nearApi.updateBountyV2);
  const context = useContext(NextActionsContext);
  const nextActions = context as Partial<NextActionModel>[] | null;
  const { walletAccountId } = useWalletAccountId();

  const handleButtonClick = () => {
    return setShowForm(!showForm);
  };

  const isHidden = () => {
    const action = nextActions?.find((item) => item.accountId === walletAccountId);
    return !action?.nextActions?.bountyUpdateMetadata;
  };

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, null, walletAccountId);
    return a.checkAccess([!a.isBountyOwner(), !a.isReviewer()]);
  };

  const _isDisabled = () => {
    return [bounty.status !== "New", bounty.claims.length !== 0].some((event) => event === true);
  };

  const handleSubmitForm = async ({
    contact_details
  }: {
    contact_details: ContactDetailsModel;
  }) => {
    await updateBountyV2({
      id: bounty.id,
      bounty_update: {
        metadata: {
          title: bounty.metadata.title,
          description: bounty.metadata.description,
          category: bounty.metadata.category,
          experience: bounty.metadata.experience,
          tags: bounty.metadata.tags,
          acceptance_criteria: bounty.metadata.acceptance_criteria,
          contact_details
        }
      }
    });
  };

  return !isHidden() ? (
    <>
      <EditIconButton isDisabled={isDisabled()} handleSubmit={() => handleButtonClick()} />
      <EditContactDetailsForm
        show={showForm}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
        bounty={bounty}
      />
    </>
  ) : null;
};

export default EditContactDetails;
