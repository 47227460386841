import classNames from "classnames";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import defaultCover from "../../../assets/images/default-cover-image--company.png";
import { useStoreActions } from "../../../hooks";
import { base64ImageUrl, imageDataToBase64Image, ImageData } from "../../../utils";

const CoverImage = (props: {
  picture?: ImageData | string;
  className?: string;
  original?: boolean;
  publicProfile?: boolean;
}) => {
  const { className, picture = null, original = true, publicProfile = false } = props;

  const [coverImage, setCoverImage] = useState<ImageData | string | null>(picture);
  const { getCoverImage } = useStoreActions((actions) => actions.auth);

  const fetchCoverImage = async () => {
    const result = await getCoverImage(original);
    if (result.success?.data?.image?.data) {
      setCoverImage(result.success.data.image);
    }
  };

  const formatToBase64ImageURL = (picture: ImageData | string) => {
    if (typeof picture !== "string") {
      return base64ImageUrl(imageDataToBase64Image(picture), picture?.contentType);
    }
    return picture;
  };

  useEffect(() => {
    if (!publicProfile && !coverImage) {
      fetchCoverImage();
    }
  }, [coverImage, publicProfile]);

  useEffect(() => {
    if (picture) {
      setCoverImage(picture);
    }
  }, [picture]);

  return (
    <img
      {...{
        src: coverImage ? formatToBase64ImageURL(coverImage) : defaultCover,
        className: twMerge(
          classNames(
            `
                    bg-secondary-900 bg-center bg-cover object-cover w-full
                    h-[180px]
                    md:h-[210px]
                  `,
            className
          )
        )
      }}
      alt=""
    />
  );
};

export default CoverImage;
