import classNames from "classnames";
import { Bounty } from "../../../../../types/Bounty";
import CurrencyAmount from "../../general/CurrencyAmount";
import TokenAmount from "../../general/TokenAmount";

const MultitaskingBountyAmount = ({
  bounty,
  className
}: {
  bounty: Bounty;
  className?: string;
}) => {
  const getAmountPerSlot = () => {
    return (
      parseInt(bounty.multitasking?.amount_per_slot ?? "0") -
      parseInt(bounty.platform_fee) / (bounty.multitasking?.number_of_slots ?? 1) -
      parseInt(bounty.dao_fee) / (bounty.multitasking?.number_of_slots ?? 1)
    ).toFixed();
  };
  return (
    <div>
      {bounty?.token && (
        <TokenAmount
          token={bounty.token}
          amount={getAmountPerSlot()}
          className={classNames("text-neutral-50", className)}
        />
      )}
      {bounty?.currency && (
        <CurrencyAmount
          currency={bounty.currency}
          amount={getAmountPerSlot()}
          className={classNames("text-neutral-50", className)}
        />
      )}
    </div>
  );
};

export default MultitaskingBountyAmount;
