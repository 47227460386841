import { createBrowserRouter } from "react-router-dom";
import {
  Home,
  Bounties,
  CreateBounty,
  SignUp,
  SignIn,
  KYCVerification,
  Profile,
  TermsOfUse,
  UserInfo
} from "../Page";
import { Layout } from "../../components/Layout";
import Experimental from "../../components/Section/Experimental";
import BountyDetails from "../Page/BountyDetails";
import BrowseBounty from "../Page/BrowseBounty";
import MyBounties from "../Page/MyBounties";
import PrivateRoute from "../../routes/PrivateRoute";
import RefferalRoute from "../../routes/RefferalRoute";
import VerifyCode from "../Page/VerifyCode";
import CreateProfile from "../Page/CreateProfile";
import PrivacyPolicy from "../Page/PrivacyPolicy";
import ForgotPassword from "../Page/ForgotPassword";
import ResetPassword from "../Page/ResetPassword";
import ConnectedRoute from "../../routes/ConnectedRoute";
import AccountWhitelists from "../Page/AccountWhitelists";
import KYCVerificationFractal from "../Page/KYCVerificaionFractal";
import Kyc from "../Page/Kyc";
import PageNotFound from "../Page/PageNotFound";
import LoginWithGithub from "../Page/LoginWithGithub";
import LoginWithGoogle from "../Page/LoginWithGoogle";
import KYCVerificationIdOS from "../Page/KYCVerificationIdOS";
import DisableNotifications from "../Page/DisableNotifications";
import ErrorBoundary from "../Page/ErrorBoundary";
import Hunters from "../Page/Hunters";
import BrowseHunters from "../Page/BrowseHunters";
import HunterDetails from "../Page/HunterDetails";
import MyInvoices from "../Page/MyInvoices";
import CreateBountyFromGithub from "../Page/CreateBountyFromGithub";
import HuntersCompletedBounties from "../Page/HuntersCompletedBounties";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/bounties",
    element: <Bounties />,
    children: [
      {
        path: "",
        element: <BrowseBounty />,
        errorElement: <ErrorBoundary />
      },
      {
        path: "bounty/:id",
        element: <BountyDetails />,
        errorElement: <ErrorBoundary />
      }
    ]
  },
  {
    path: "/hunters",
    element: <Hunters />,
    children: [
      {
        path: "",
        element: <BrowseHunters />,
        errorElement: <ErrorBoundary />
      },
      {
        path: "hunter/:id",
        element: <HunterDetails />,
        errorElement: <ErrorBoundary />
      },
      {
        path: "hunter/:id/completed-bounties",
        element: <HuntersCompletedBounties />,
        errorElement: <ErrorBoundary />
      }
    ]
  },
  {
    element: <PrivateRoute {...{ redirectPath: "/sign-in" }} />,
    children: [
      {
        path: "/bounties",
        element: <Bounties />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <ConnectedRoute {...{ redirectPath: "/bounties" }} />,
            children: [
              {
                path: "my",
                element: <MyBounties />,
                errorElement: <ErrorBoundary />
              }
            ]
          },
          {
            path: "new",
            element: <CreateBounty />
          },
          {
            path: "new/from-github-issue/:id",
            element: <CreateBountyFromGithub />
          },
          {
            path: "all-invoices",
            element: <MyInvoices />,
            errorElement: <ErrorBoundary />
          },
          {
            path: "whitelists",
            element: <AccountWhitelists />,
            errorElement: <ErrorBoundary />
          }
        ]
      },
      {
        path: "/kyc",
        element: <Kyc />
      },
      {
        path: "/kyc-idos",
        element: <KYCVerificationIdOS />
      },
      // {
      //   path: "/user-verification",
      //   element: <KYCVerification />
      // },
      {
        path: "/fractal-user-verification",
        element: <KYCVerificationFractal />
      },
      {
        path: "/verify-code",
        element: <VerifyCode />
      },
      {
        path: "/profile/edit",
        element: <CreateProfile />
      },
      {
        path: "/profile",
        element: <Profile />,
        errorElement: <ErrorBoundary />
      },
      {
        path: "/email-notifications",
        element: <DisableNotifications />
      }
    ]
  },
  {
    path: "/experimental",
    element: (
      <Layout>
        <Experimental />
      </Layout>
    )
  },
  {
    element: <RefferalRoute {...{ redirectPath: "/" }} />,
    children: [
      {
        path: "/sign-up",
        element: <SignUp />
      }
    ]
  },
  {
    path: "/sign-in",
    element: <SignIn />
  },
  {
    path: "/forgot-pass",
    element: <ForgotPassword />
  },
  {
    path: "/password-reset",
    element: <ResetPassword />
  },
  {
    path: "/terms",
    element: <TermsOfUse />
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />
  },
  {
    path: "/user-info/:userId",
    element: <UserInfo />,
    errorElement: <ErrorBoundary />
  },
  { path: "/auth/github/callback", element: <LoginWithGithub /> },
  { path: "/auth/google/callback", element: <LoginWithGoogle /> },
  { path: "*", element: <PageNotFound /> }
]);
