import { createTypedHooks } from "easy-peasy";
import { GeneralStore } from "../types";
import useOutsideClick from "./useOutsideClick";
const typedHooks = createTypedHooks<GeneralStore.StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export { useOutsideClick };
