import classNames from "classnames";
import { useMemo, useState } from "react";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import { KycVerificationMethod } from "../../../../../../types/Kyc";
import CheckBox from "../../../../../general/CheckBox";
import { Area } from "../../../../../Layout";
import { FormRow } from "../../../../../general/Form/FormElements";
import { SelectArrow } from "../../../../../general/SVGIcon";

const KYCVerification = () => {
  const useKYCVerification = useStoreState((state) => state.newBountyForm.useKYCVerification);

  const kyc_config = useStoreState((state) => state.newBounty.kyc_config);
  const bounty_flow = useStoreState((state) => state.newBounty.bounty_flow);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const kycVerificationMethod = useMemo(() => {
    if (kyc_config !== "KycNotRequired") {
      return kyc_config?.KycRequired?.kyc_verification_method;
    }
    return null;
  }, [kyc_config]);

  const setKYCVerification = useStoreActions((actions) => actions.newBountyForm.setKYCVerification);

  const setVerificationMethod = useStoreActions(
    (actions) => actions.newBounty.setVerificationMethod
  );

  // const handleAmountChange = (value: string) => {
  //   setValue("minAmountForKYC", value, { shouldValidate: true });
  //   const decimals =
  //     tokens.find((token) => token.tokenId === selectedTokenAccount)
  //       ?.decimals ?? 0;
  //   const kycAmount = utils.getParsedTokenAmount(value, decimals);
  //   setAmountForKYC(kycAmount);
  // };

  const handleSetVerificationMethod = (value: KycVerificationMethod) => {
    setVerificationMethod(value);
  };

  return (
    <FormRow className="mt-3.5">
      <Area>
        <CheckBox
          {...{
            active: useKYCVerification,
            onClick: () => setKYCVerification(!useKYCVerification),
            text: `Use KYC Verification`
          }}
        />
        <FormRow className="py-2.5">
          <p className=" text-sm text-neutral-500">
            Use HEROES' KYC Verification, only applicants who passed HEROES' KYC can apply and work
            on this bounty!
          </p>
          <br />
          <p className="text-md text-secondary-900/70">
            This cannot be changed once the bounty is live!
          </p>
        </FormRow>
        {useKYCVerification && (
          <div className="pb-2.5">
            <div className="px-2">
              <FormRow className="flex justify-start">
                <button
                  className="flex items-center gap-1 text-[12px] text-primary-900 outline-none"
                  onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}>
                  Advanced settings
                  <SelectArrow
                    className={classNames("w-3 h-3", {
                      "rotate-180": showAdvancedSettings
                    })}
                    {...{ color: "hsl(var(--color-primary-900))" }}
                  />
                </button>
              </FormRow>
              {showAdvancedSettings && (
                <FormRow className="flex flex-wrap gap-4 py-2.5">
                  <CheckBox
                    {...{
                      text: "KYC before work",
                      textInfo:
                        "Applicants will need to go through HEROES KYC to be able to claim & start working on the bounty",
                      active: kycVerificationMethod === "WhenCreatingClaim",
                      onClick: () => handleSetVerificationMethod("WhenCreatingClaim")
                    }}
                  />
                  <CheckBox
                    {...{
                      text: "KYC after work",
                      disabled: bounty_flow === "SimpleBounty",
                      textInfo:
                        "Applicants will be able to claim & start working on the bounty without KYC, but they will have to do KYC before their work get approved",
                      active: kycVerificationMethod === "DuringClaimApproval",
                      onClick: () => handleSetVerificationMethod("DuringClaimApproval")
                    }}
                  />
                </FormRow>
              )}
            </div>
          </div>
        )}
      </Area>
    </FormRow>
  );
};

export default KYCVerification;
