import { useStoreActions, useStoreState } from "../../../../../../../hooks";
import CheckBox from "../../../../../../general/CheckBox";
import { FormRow } from "../../../../../../general/Form/FormElements";

const CreatingManyClaimsOption = () => {
  const setAllowCreatingManyClaims = useStoreActions(
    (actions) => actions.newBounty.setAllowCreatingManyClaims
  );
  const allow_creating_many_claims = useStoreState(
    (state) => state.newBounty.allow_creating_many_claims
  );

  const handleCheckOption = () => {
    setAllowCreatingManyClaims(!allow_creating_many_claims);
  };

  return (
    <FormRow>
      <CheckBox
        {...{
          text: "Allow creating many claims",
          active: allow_creating_many_claims,
          onClick: () => handleCheckOption()
        }}
      />
    </FormRow>
  );
};

export default CreatingManyClaimsOption;
