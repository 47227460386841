import { Bounty } from "../../../../../../types/Bounty";
import MultitaskingBountyAmount from "../../Amount/MultitaskingBountyAmount";
import SimpleBountyAmount from "../../Amount/SimpleBountyAmount";

const Amount = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  return (
    <div className="flex justify-between text-2xl font-weight text-success">
      {!bounty.multitasking && <SimpleBountyAmount bounty={bounty} className="text-success" />}
      {bounty.multitasking && bounty.multitasking.multitasking_type === "DifferentTasks" && (
        <SimpleBountyAmount bounty={bounty} className="text-success" />
      )}
      {bounty.multitasking?.multitasking_type === "OneForAll" && (
        <MultitaskingBountyAmount bounty={bounty} className="text-success" />
      )}
    </div>
  );
};

export default Amount;
