import { useContext } from "react";
import mixpanel from "mixpanel-browser";
import { MixpanelContext } from "../App";
import useInfiniteBounties from "../../hooks/useInfiniteBounties";
import ScrollToTopButton from "../../components/general/Button/ScrollToTopButton";
import InfiniteBountyList from "../../components/Bounty/BountyList/InfiniteBountyList";
import { FilterParams } from "../../types/BountyList";
import { useParams } from "react-router-dom";
import useHunterDetails from "../../hooks/useHunterDetails";
import { createBrowserHistory } from "history";
import { base64ImageUrl } from "../../utils";
import HunterAvatar from "../../components/Hunters/HunterAvatar";
import defaultUserCoverImage from "../../assets/images/default-cover-image--company.png";
import { CompanyMetadata, IndividualMetadata } from "../../types/Auth";

const HuntersCompletedBounties = () => {
  const urlParams = useParams();
  const history = createBrowserHistory();

  const handleGoBack = () => {
    history.back();
  };
  const { hunter } = useHunterDetails({ hunterId: urlParams.id });

  const context = useContext(MixpanelContext);
  mixpanel.init(context.token, {
    debug: false
  });

  const filters: FilterParams = {
    claimers: hunter?.wallets.map((wallet) => {
      return { name: wallet.walletId, value: wallet.walletId };
    }),
    status: [{ name: "Completed", value: "Completed" }]
  };

  const { data, error, fetchNextPage, hasNextPage, isInitialLoading, status } = useInfiniteBounties(
    { filters, limit: 16 }
  );

  if (status === "error") return <h4>Ups!, {`${error}`}</h4>;

  return (
    <div className="font-poppins w-full md:w-2/3 lg:w-1/2 mx-auto flex flex-col gap-2.5">
      <div className="flex justify-start">
        <button className="text-sm text-neutral-400" onClick={handleGoBack}>
          Back
        </button>
      </div>
      <div className="border-sm border-neutral-800 rounded-[1.25rem]">
        <div>
          <img
            className="rounded-[1.25rem] w-full h-56 object-cover"
            src={
              hunter?.coverImage
                ? base64ImageUrl(hunter.coverImage.data, hunter.coverImage.contentType)
                : defaultUserCoverImage
            }
          />
          <HunterAvatar picture={hunter?.picture} className="relative left-5 w-24 h-24" />
        </div>
        <div className="flex justify-between px-5 pt-4 pb-8">
          <div>
            <p className="text-lg text-neutral-50">
              {(hunter as IndividualMetadata)?.individual?.name ||
                (hunter as CompanyMetadata)?.company?.companyName}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center my-4">
        <h1 className="text-2xl font-bold">Completed bounties</h1>
      </div>
      {isInitialLoading ? (
        <div className="flex items-center justify-center">
          <span className="dot-pulse" />
        </div>
      ) : (
        <>
          <InfiniteBountyList data={data} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} />
        </>
      )}
      <ScrollToTopButton />
    </div>
  );
};

export default HuntersCompletedBounties;
