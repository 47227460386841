import { compareDesc, startOfDay } from "date-fns";

import DatePicker from "../../general/DatePicker";
import SvgIcon from "../../general/DatePicker/SvgIcon";
import { Area, MarksLayer, ValidationLayout } from "../../Layout";
import Mark from "../../general/Mark";
import { LightButton, DarkButton } from "../../general/Button";
import { Input, TextArea } from "../../general/Input";
import Separator from "../../general/Separator";
import { Option } from "../../general/Select/Select";
import { Select, NewSelect, MultiSelect } from "../../general/Select";
import CheckBox from "../../general/CheckBox";
import Tag from "../../general/Tag";
import { useState } from "react";
import Modal from "../../general/Modal";
import { Validation, DEFAULT_VALUE } from "../../../helpers/Validation";

export default function Experimental() {
  //modal
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    console.log("close");
  };

  //checkbox
  const [active, setActive] = useState(true);

  //tag
  type TagData = { name: string; value: string };
  const [tags, setTags] = useState<TagData[]>([
    { name: "ruby", value: "Ruby" },
    { name: "html", value: "HTML" }
  ]);

  //select
  const defaultOption1 = { name: "default", value: "default text" };
  const optionsList1 = [
    { name: "australia", value: "Australia" },
    { name: "brazil", value: "Brazil" },
    { name: "china", value: "China" },
    { name: "denmark", value: "Denmark" },
    { name: "ghana", value: "Ghana" },
    { name: "spain", value: "Spain" }
  ];
  const [selected1, setSelected1] = useState<Option>(defaultOption1);

  //filtered select
  const defaultOption2 = { name: "default", value: "" };
  const defaultOptionsList2 = [
    { name: "australia", value: "Australia" },
    { name: "brazil", value: "Brazil" },
    { name: "china", value: "China" },
    { name: "chilie", value: "Chilie" },
    { name: "denmark", value: "Denmark" },
    { name: "ghana", value: "Ghana" },
    { name: "spain", value: "Spain" }
  ];
  const [selected2, setSelected2] = useState<Option>(defaultOption2);
  const started = true;
  const validate2 = (selected: Option): Validation => {
    if (started) {
      if (selected.value === "") {
        return {
          valid: false,
          started,
          message: "Invalid value"
        };
      }

      return {
        valid: true,
        started,
        message: ""
      };
    }

    return DEFAULT_VALUE;
  };

  //filtered multi select
  const defaultOption3 = { name: "default", value: "" };
  const defaultOptionsList3 = [
    { name: "ruby", value: "Ruby" },
    { name: "html", value: "HTML" },
    { name: "javascript", value: "JavaScript" },
    { name: "css", value: "CSS" },
    { name: "reactjs", value: "ReactJs" },
    { name: "typescript", value: "typescript" },
    { name: "fire", value: "🔥" }
  ];
  const [selected3, setSelected3] = useState<Option>(defaultOption3);
  const [multiSelected, setMultiSelected] = useState<Option[]>([]);
  const validate3 = (multiSelected: Option[]): Validation => {
    if (started) {
      if (multiSelected.length === 0) {
        return {
          valid: false,
          started,
          message: "Required field"
        };
      }

      return {
        valid: true,
        started,
        message: ""
      };
    }

    return DEFAULT_VALUE;
  };

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const validateDate = (date: Date, started: boolean = false) => {
    if (started) {
      if (compareDesc(date, startOfDay(new Date())) === 1) {
        return {
          valid: false,
          started,
          message: "Too early"
        };
      }
    }
    return DEFAULT_VALUE;
  };

  return (
    <>
      <div className="p-10 flex flex-col gap-4">
        <ValidationLayout
          {...{
            validate: () => validateDate(selectedDate, true)
          }}>
          <DatePicker
            {...{
              date: selectedDate,
              onChange: (date: Date) => setSelectedDate(date)
            }}
          />
        </ValidationLayout>
        <DatePicker
          {...{
            date: selectedDate,
            onChange: (date: Date) => setSelectedDate(date),
            disabled: true
          }}
        />
      </div>

      <div className="p-10">
        <LightButton {...{ onClick: () => setOpen(true), text: "Open modal" }} />
        <Modal
          {...{
            open,
            onClose: handleClose,
            okButton: () => {
              return <DarkButton {...{ onClick: () => setOpen(false), text: "Got it" }} />;
            }
          }}>
          <p className="text-white text-xl">Modal body blalbalba </p>
        </Modal>
      </div>

      <div className="p-10">
        {tags.map((tagData: TagData) => {
          return (
            <>
              <Tag
                {...{
                  ...tagData,
                  onClose: (name: string) => {
                    setTags(tags.filter((tag) => tag.name !== name));
                  }
                }}
              />
              <div className="p-1" />
            </>
          );
        })}
      </div>

      <div className="p-10">
        <CheckBox {...{ text: "Abracadabra" }} />
        <div className="p-1" />
        <CheckBox {...{ active, onClick: () => setActive(!active) }} />
        <div className="p-1" />
        <CheckBox
          {...{
            text: "disabled",
            active,
            disabled: true,
            onClick: () => setActive(!active)
          }}
        />
      </div>

      <div className="p-10 flex flex-col gap-4">
        <ValidationLayout
          {...{
            validate: () => validate3(multiSelected)
          }}>
          <MultiSelect
            {...{
              placeholder: "Filter",
              defaultOption: defaultOption3,
              defaultOptionsList: defaultOptionsList3,
              selected: selected3,
              multiSelected,
              onMultiSelect: (multiSelected: Option[]) => {
                setMultiSelected(multiSelected);
              },
              onSelect: (selected: Option) => {
                setSelected3(selected);
              }
            }}
          />
        </ValidationLayout>
        <MultiSelect
          {...{
            placeholder: "Filter disabled",
            defaultOption: defaultOption3,
            defaultOptionsList: defaultOptionsList3,
            selected: selected3,
            multiSelected,
            onMultiSelect: (multiSelected: Option[]) => {
              setMultiSelected(multiSelected);
            },
            onSelect: (selected: Option) => {
              setSelected3(selected);
            },
            disabled: true
          }}
        />
      </div>

      <div className="p-10 flex flex-col gap-4">
        <ValidationLayout
          {...{
            validate: () => validate2(selected2)
          }}>
          <NewSelect
            {...{
              placeholder: "Filter",
              defaultOption: defaultOption2,
              defaultOptionsList: defaultOptionsList2,
              selected: selected2,
              validate: validate2,
              onSelect: (selected: Option) => {
                setSelected2(selected);
              }
            }}
          />
        </ValidationLayout>
        <NewSelect
          {...{
            placeholder: "Filter disabled",
            defaultOption: defaultOption2,
            defaultOptionsList: defaultOptionsList2,
            selected: selected2,
            validate: validate2,
            onSelect: (selected: Option) => {
              setSelected2(selected);
            },
            disabled: true
          }}
        />
      </div>

      <div className="p-10">
        <Select
          {...{
            defaultOption: defaultOption1,
            optionsList: optionsList1,
            selected: selected1,
            onSelect: (selected: Option) => {
              setSelected1(selected);
            }
          }}
        />
        <div className="p-10" />
      </div>

      <div className="p-10">
        <Area>
          <MarksLayer>
            <Mark {...{ text: "aaa", customStyles: { container: "bg-red-300" } }} />
            <Mark {...{ text: "bbb", customStyles: { container: "bg-success" } }} />
          </MarksLayer>
          <p className="p-4 text-white">
            asasdasdf
            <br />
            asasdasdf
            <br />
            asasdasdf
            <br />
          </p>
        </Area>
      </div>

      <div
        className="
        p-5
        flex flex-row justify-center space-x-4
        mt-[40px]
        md:justify-end
      ">
        <LightButton
          {...{
            text: "create bounty",
            onClick: () => console.log("create bounty click")
          }}
        />
        <DarkButton
          {...{
            text: "browse projects",
            onClick: () => console.log("create bounty click")
          }}
        />
        <DarkButton
          {...{
            text: "browse projects",
            onClick: () => console.log("create bounty click"),
            disabled: true
          }}
        />
      </div>
      <div
        className="
        p-5
        flex flex-row justify-center space-x-4
        mt-[40px]
        md:justify-end
      ">
        <LightButton
          {...{
            customStyles: { button: "rounded-[0.625rem]" },
            text: "create bounty",
            onClick: () => console.log("create bounty click")
          }}
        />
        <DarkButton
          {...{
            customStyles: { button: "rounded-[0.625rem]" },
            text: "browse projects",
            onClick: () => console.log("create bounty click")
          }}
        />
      </div>

      <div className="p-10">
        <Area {...{ customStyles: { contentContainer: "flex flex-col gap-4 p-4" } }}>
          <TextArea
            {...{
              customStyles: { container: "", input: "h-[100px]" },
              placeholder: "Simple placeholder",
              label: { text: "id" },
              name: "id",
              onChange: () => {}
            }}
          />
          <Input
            {...{
              placeholder: "user@email.com",
              label: { text: "Email*" },
              name: "email",
              value: "dsfs",
              validation: { valid: true, started: true },
              onChange: () => {}
            }}
          />
          <Input
            {...{
              placeholder: "",
              label: { text: "First name*" },
              name: "first name",
              value: "John",
              validation: { valid: false, started: false },
              onChange: () => {}
            }}
          />
          <ValidationLayout
            {...{
              validate: () => ({
                valid: false,
                started: true,
                message: "Required field"
              }),
              label: { text: "Last name*" }
            }}>
            <Input
              {...{
                placeholder: "",
                name: "last name",
                value: "Week",
                onChange: () => {},
                svgIcon: <SvgIcon {...{ name: "calendar" }} />
              }}
            />
          </ValidationLayout>
        </Area>
      </div>

      <div className="p-10">
        <Area>
          <div className="flex flex-col w-full px-4">
            <Separator customStyles={{ container: "py-4" }} />
            <Separator />
            <Separator customStyles={{ container: "py-4" }} />
          </div>
        </Area>
      </div>

      <div className="pb-10" />
    </>
  );
}
