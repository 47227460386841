import classNames from "classnames";
import { twMerge } from "tailwind-merge";

function CloseIcon(props: { className?: string }) {
  const { className } = props;
  return (
    <svg
      width="20"
      height="20"
      className={twMerge(classNames("fill-[#F8F8F8]", className))}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.12109"
        width="24"
        height="3"
        rx="1"
        transform="rotate(45 2.12109 0)"
        //fill="#F8F8F8"
        fill="inherit"
      />
      <rect
        y="16.9707"
        width="24"
        height="3"
        rx="1"
        transform="rotate(-45 0 16.9707)"
        //fill="#F8F8F8"
        fill="inherit"
      />
    </svg>
  );
}

export default CloseIcon;
