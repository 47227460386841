import classNames from "classnames";
import useInfiniteBounties, { Response } from "../../../hooks/useInfiniteBounties";
import { Bounty } from "../../../types/Bounty";
import { FilterParams } from "../../../types/BountyList";
import SimpleBountyItem from "./SimpleBountyItem";
import { twMerge } from "tailwind-merge";

const ViewMoreBounties = (props: {
  filters?: FilterParams;
  limit: number;
  title?: string;
  className?: string;
}) => {
  const { filters, limit = 10, title, className } = props;

  const { data, error, status } = useInfiniteBounties({ filters, limit });
  if (status === "loading")
    return (
      <div className="flex items-center justify-center">
        <span className="dot-pulse" />
      </div>
    );

  if (status === "error") return <h4>Ups!, {`${error}`}</h4>;

  return (
    <>
      {data &&
        data?.pages?.length > 0 &&
        data.pages.map((group: Response) => (
          <>
            {group && group?.bounties && group.bounties?.length > 0 && (
              <>
                <h2 className="font-poppins text-xl font-500">
                  {title ? title : "Other bounties by this profile"}
                </h2>
                <div
                  className={twMerge(
                    classNames(
                      "grid lg:grid-cols-2 md:grid-cols-1  sm:grid-cols-1  lg:gap-x-8 sm:gap-x-4  w-full",
                      className
                    )
                  )}>
                  {group.bounties.map((bounty: Bounty, index: number) => (
                    <SimpleBountyItem key={`bounty-${bounty.id}-item-${index}`} bounty={bounty} />
                  ))}
                </div>
              </>
            )}
          </>
        ))}
    </>
  );
};

export default ViewMoreBounties;
