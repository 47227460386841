import { Paid } from "../../../types/Auth";
import { getTokenSymbol } from "../general/helpers";

const getPaidAmount = (paid: Paid[]) => {
  return paid.reduce((str, el) => {
    str += parseFloat(el.amount).toFixed(2) + " " + getTokenSymbol(el.token) + ", ";
    return str;
  }, "");
};

export { getPaidAmount };
