import { useState } from "react";
import { Area, ValidationLayout, SignFormContainerLayout } from "../../Layout";
import { Input } from "../../general/Input";
import { LightButton } from "../../general/Button";
import CheckBox from "../../general/CheckBox";
import { DEFAULT_STYLES } from "../../../helpers/Validation";
import Api from "../../../services/Api";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { SignUpFormData } from "../../../containers/Page/SignUp";
import Separator from "../../general/Separator";
import GoogleLoginProvider from "../../AuthProviders/GoogleLoginProvider";
import GithubLoginProvider from "../../AuthProviders/GithubLoginProvider";
import { useStoreState } from "../../../hooks";
import TwitterLoginProvider from "../../AuthProviders/TwitterLoginProvider";

interface SignInProps {
  onSubmit: SubmitHandler<SignUpFormData>;
  submitDisabled: boolean;
  errorMessage?: string;
}

const MAX_LENGTH = 50;

const SignUpForm = (props: SignInProps) => {
  const { onSubmit, submitDisabled, errorMessage } = props;
  const useGithubLogin = useStoreState((state) => state.app.config?.use_github_login ?? false);
  const useGoogleLogin = useStoreState((state) => state.app.config?.use_google_login ?? false);
  const useTwitterLogin = useStoreState((state) => state.app.config?.use_twitter_login ?? false);
  const { handleSubmit, register, formState, setValue, setError } =
    useFormContext<SignUpFormData>();
  const { errors, isSubmitted, isValid } = formState;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [privacy, setPrivacy] = useState(false);

  const checkUserExist = async () => {
    const response = await Api.checkUserName(name);
    if (!response?.error) {
      if (response?.nameAlreadyExists) {
        setError("name", { message: "The name already exists" });
      }
      if (!response?.nameIsValid) {
        setError("name", { message: "Invalid value" });
      }
    }
    return response;
  };

  const handleSignUp = async () => {
    const check = await checkUserExist();
    if (!check.error && !check.nameAlreadyExists && check.nameIsValid) {
      if (isValid) {
        onSubmit({ name, email, password });
      }
    }
  };

  return (
    <SignFormContainerLayout
      {...{
        title: "Create account"
      }}>
      <div className="flex flex-col gap-5 pb-10">
        {" "}
        <Area
          customStyles={{
            borderContainer: "border border-neutral-700",
            contentContainer: "px-[1rem] py-[2rem] bg-neutral-850"
          }}>
          <form className="w-full">
            <div className="flex flex-col gap-[1rem]">
              <input
                type="hidden"
                {...register("name", {
                  required: "Can't be empty",
                  maxLength: {
                    value: MAX_LENGTH,
                    message: `Max length ${MAX_LENGTH} chars`
                  },
                  pattern: {
                    value: /[^\W]+$/gi,
                    message: "No special characters"
                  }
                })}
                defaultValue={name}
              />
              <ValidationLayout
                {...{
                  validate: () => {
                    return {
                      valid: !errors.name,
                      started: isSubmitted,
                      message: errors.name?.message
                    };
                  },
                  label: { text: name.length > 0 ? "Name" : "" }
                }}>
                <Input
                  {...{
                    placeholder: "Username",
                    name: "name",
                    value: name,
                    onChange: (value: string) => {
                      setValue("name", value, { shouldValidate: true });
                      setName(value);
                    }
                  }}
                />
              </ValidationLayout>
              <input
                type="hidden"
                {...register("email", {
                  required: "Can't be empty",
                  pattern: {
                    // eslint-disable-next-line no-useless-escape
                    value: /[\w\.+?]+@([\w-]+\.)+[\w-]{2,}/g,
                    message: "Invalid email address"
                  }
                })}
                defaultValue={email}
              />
              <ValidationLayout
                {...{
                  validate: () => {
                    return {
                      valid: !errors.email,
                      started: isSubmitted,
                      message: errors.email?.message
                    };
                  },
                  label: { text: email.length > 0 ? "Email" : "" }
                }}>
                <Input
                  {...{
                    placeholder: "Email",
                    name: "email",
                    value: email,
                    onChange: (value: string) => {
                      setValue("email", value, { shouldValidate: true });
                      setEmail(value);
                    }
                  }}
                />
              </ValidationLayout>
              <input
                type="hidden"
                {...register("password", {
                  required: "Can't be empty"
                })}
                defaultValue={password}
              />
              <ValidationLayout
                {...{
                  validate: () => {
                    return {
                      valid: !errors.password,
                      started: isSubmitted,
                      message: errors.password?.message
                    };
                  },
                  label: { text: password.length > 0 ? "Password" : "" }
                }}>
                <Input
                  {...{
                    type: "password",
                    placeholder: "Password",
                    name: "password",
                    value: password,
                    onChange: (value: string) => {
                      setValue("password", value, { shouldValidate: true });
                      setPassword(value);
                    }
                  }}
                />
              </ValidationLayout>
              <CheckBox
                {...{
                  customStyles: { container: "items-baseline" },
                  text: `I have read and accept the <a href="/terms" target="_blank" class="underline text-primary-900"> Terms of Use </a> & <a href="/privacy" target="_blank" class="underline text-primary-900">Privacy Policy</a>`,
                  active: privacy,
                  onClick: () => setPrivacy(!privacy)
                }}
              />
            </div>
          </form>
          <p className={`min-h-[1em] py-2.5 text-base ${DEFAULT_STYLES.invalid.message}`}>
            {errorMessage ? errorMessage : ""}
          </p>
          <div className="flex flex-row justify-center items-center gap-5">
            <LightButton
              {...{
                text: "Sign up",
                onClick: () => handleSubmit(handleSignUp)(),
                disabled: !privacy || submitDisabled
              }}
            />
          </div>
        </Area>
        {(useGithubLogin || useGoogleLogin || useTwitterLogin) && (
          <>
            <div className="flex flex-row flex-nowrap items-center justify-center mt-2">
              <div className="font-poppins font-500 text-sm text-neutral-400 flex justify-center">
                Or log in with
              </div>
            </div>
            <div className="mt-2 flex flex-col gap-3">
              <GoogleLoginProvider />
              <GithubLoginProvider />
              <TwitterLoginProvider />
            </div>
          </>
        )}
      </div>
    </SignFormContainerLayout>
  );
};

export default SignUpForm;
