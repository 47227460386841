import { useState, useContext } from "react";
import { useStoreActions } from "../../../hooks";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import utils from "../../../services/utils";
import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { WithdrawForm } from "../Dialogues";
import NextActionsContext from "../general/context/NextActionsContext";
import ActionButton from "./general/ActionButton";

const WithdrawAction = (props: { claim: Claim; bounty: Bounty }) => {
  const { bounty, claim } = props;
  const [showForm, setShowForm] = useState(false);
  const { walletAccountId } = useWalletAccountId();
  const withdraw = useStoreActions((actions) => actions.nearApi.withdraw);

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, claim, walletAccountId);
    return a.checkAccess([!a.isClaimer()]);
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    setShowForm(!showForm);
    await withdraw({
      id: bounty.id,
      claim_number: claim.claim_number
    });
  };

  return (
    <>
      <ActionButton text="Withdraw" disabled={isDisabled()} handleClick={handleButtonClick} />
      <WithdrawForm
        bounty={bounty}
        show={showForm}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
    </>
  );
};

export default WithdrawAction;
