import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import ResetPasswordForm from "../../components/Form/ResetPasswordForm";
import { Layout, Section } from "../../components/Layout";
import { useStoreActions } from "../../hooks";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const userResetPassword = useStoreActions((actions) => actions.auth.userResetPassword);
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const methods = useForm({
    mode: "all"
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setResetPasswordError("");
    }, 10000);

    return () => clearTimeout(timer);
  });

  const onSubmit = async (form: { password: string; token: string; id: string }) => {
    const result = await userResetPassword(form);
    if (result.error) {
      setResetPasswordError(result.error);
    }
    if (result.success) {
      setResetPasswordSuccess(true);
    }
  };

  return (
    <Layout>
      <div className="p-3" />
      <Section
        {...{
          id: "reset-pass_section"
        }}>
        <FormProvider {...methods}>
          <ResetPasswordForm
            {...{
              onSubmit,
              searchParams,
              errorMessage: resetPasswordError,
              successMessage: resetPasswordSuccess
            }}
          />
        </FormProvider>
      </Section>
    </Layout>
  );
};

export default ResetPassword;
