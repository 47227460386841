export default function LinkIcon(props: { className?: string }) {
  const { className } = props;
  return (
    <svg
      className={"" + className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0985 6.94278C11.6983 7.22917 12.2206 7.65533 12.6216 8.18546C13.0226 8.71559 13.2905 9.3342 13.4028 9.98934C13.5152 10.6445 13.4686 11.317 13.2671 11.9504C13.0657 12.5839 12.7151 13.1597 12.2449 13.6295L8.09108 17.7834C7.31208 18.5624 6.25553 19 5.15386 19C4.05219 19 2.99564 18.5624 2.21664 17.7834C1.43764 17.0044 1 15.9478 1 14.8461C1 13.7445 1.43764 12.6879 2.21664 11.9089L3.83848 10.2871M16.1615 9.71293L17.7834 8.09108C18.5624 7.31208 19 6.25553 19 5.15386C19 4.05219 18.5624 2.99564 17.7834 2.21664C17.0044 1.43764 15.9478 1 14.8461 1C13.7445 1 12.6879 1.43764 11.9089 2.21664L7.75508 6.37047C7.2849 6.84032 6.93435 7.41614 6.73285 8.04957C6.53136 8.68299 6.48483 9.35552 6.59715 10.0107C6.70948 10.6658 6.97738 11.2844 7.37837 11.8145C7.77935 12.3447 8.3017 12.7708 8.90154 13.0572"
        stroke="#FFCF14"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
