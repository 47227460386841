import classNames from "classnames";
import { twMerge } from "tailwind-merge";

const CheckGreenCircleIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(classNames("w-6 h-6", className))}>
      <path
        d="M7.5 11.0649L9.75 13.3149L13.5 8.06494M19.5 10.3149C19.5 11.4968 19.2672 12.6672 18.8149 13.7591C18.3626 14.851 17.6997 15.8432 16.864 16.6789C16.0282 17.5146 15.0361 18.1776 13.9442 18.6299C12.8522 19.0822 11.6819 19.3149 10.5 19.3149C9.3181 19.3149 8.14778 19.0822 7.05585 18.6299C5.96392 18.1776 4.97177 17.5146 4.13604 16.6789C3.30031 15.8432 2.63738 14.851 2.18508 13.7591C1.73279 12.6672 1.5 11.4968 1.5 10.3149C1.5 7.92799 2.44821 5.63881 4.13604 3.95098C5.82387 2.26315 8.11305 1.31494 10.5 1.31494C12.8869 1.31494 15.1761 2.26315 16.864 3.95098C18.5518 5.63881 19.5 7.92799 19.5 10.3149Z"
        stroke="#73EA64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckGreenCircleIcon;
