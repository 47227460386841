import classNames from "classnames";
import { ReactNode } from "react-markdown/lib/react-markdown";
import { twMerge } from "tailwind-merge";

type FormRowProps = {
  children: ReactNode[] | ReactNode;
  className?: string;
};

export default function FormRow(props: FormRowProps) {
  const { children, className } = props;
  return (
    <div className={twMerge(classNames("py-1.5", className))}>{children}</div>
  );
}
