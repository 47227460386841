import { useEffect } from "react";
import { DarkButton } from "../../../general/Button";
import Modal from "../../../general/Modal";
import { FinalExecutionOutcome } from "@near-wallet-selector/core";
import { useStoreActions } from "../../../../hooks";
import Api from "../../../../services/Api";

export default function FinalExecutionOutcomeForm(props: {
  show: boolean;
  refetchActions?: () => void;
  executionOutcome: FinalExecutionOutcome | null;
  handleClose: () => void;
}) {
  const postAfterCreatingTx = Api.postAfterCreatingTx;
  const { show, executionOutcome, handleClose, refetchActions } = props;

  useEffect(() => {
    if (executionOutcome) {
      const transactionHashes = executionOutcome.transaction.hash;
      postAfterCreatingTx(transactionHashes);
    }
  }, [executionOutcome]);

  const onCloseForm = () => {
    handleClose();
    refetchActions && refetchActions();
  };

  return (
    <Modal
      {...{
        customStyles: {
          modal: "min-h-[400px]",
          content: "h-fit"
        },
        open: show,
        onClose: handleClose
      }}>
      <div className="font-poppins py-2.5">
        <div className="text-lg text-white">Transaction was completed!</div>
        {executionOutcome && show && (
          <div className="flex flex-col gap-1 px-2 py-4 text-sm overflow-hidden break-all">
            <div className="text-neutral-500 text-sm">
              Execution status:{" "}
              {Object.keys(executionOutcome.status).includes("SuccessValue") ? (
                <span className="text-success">Success</span>
              ) : (
                <span className="text-warning">Failure</span>
              )}
            </div>
            <div className="text-neutral-500 text-sm">
              Receiver:{" "}
              <span className="text-neutral-50">{executionOutcome.transaction.receiver_id}</span>
            </div>
            <div className="text-neutral-500 text-sm">
              Transaction hash:{" "}
              <span className="text-neutral-50">{executionOutcome.transaction.hash}</span>
            </div>
          </div>
        )}
        <div className="flex gap-4 justify-end mt-8">
          <DarkButton
            {...{
              customStyles: { button: "max-w-[146px]" },
              onClick: () => onCloseForm(),
              text: "Close"
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
