import { Layout } from "../../components/Layout";

const LoginWithGithub = () => {
  return (
    <Layout>
      <div className="container mx-auto text-white w-full text-center">
        <p className="p-4">Login with github...</p>
      </div>
    </Layout>
  );
};

export default LoginWithGithub;
