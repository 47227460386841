import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import utils from "../../../../services/utils";
import { DarkButton, LightButton } from "../../../general/Button";
import { Input, TextArea } from "../../../general/Input";
import { Area, ValidationLayout } from "../../../Layout";
import Modal from "../../../general/Modal";
import FormControl from "../../../general/Form/FormElements/FormControl";
import { useStoreActions } from "../../../../hooks";
import { formatting, MomentDurationConstructor } from "../../../../common/formatting";
import { defTimeCommitments } from "../../../../constants";
import { Bounty } from "../../../../types/Bounty";
import moment from "moment";
import useWalletAccountId from "../../../../hooks/useWalletAccountId";
import { AppMessages } from "../../../../common/app-messages";
import { NewSelect } from "../../../general/Select";
import Amount from "./Amount";
import { FormRow } from "../../../general/Form/FormElements";
import UploadAttachments from "../general/UploadAttachments";

type ClaimBountyFormData = {
  attachments?: string[];
  deadline: string;
  description: string;
  deadlineTimeCommitment: string;
};

export default function ClaimBountyForm(props: {
  showForm: boolean;
  handleClose: () => void;
  bounty: Bounty;
  slot?: number;
}) {
  const { showForm, handleClose, bounty, slot } = props;

  const handleCloseForm = () => {
    handleClose();
  };

  const ClaimForm = () => {
    const params = useParams();
    const { handleSubmit, register, control, formState, setValue } = useForm<ClaimBountyFormData>();
    const { errors, isSubmitted, isValid } = formState;
    const [userWhitelisted, setUserWhitelisted] = useState(false);
    const [deadlineTimeCommitment, setDeadlineTimeCommitment] = useState("");
    const [deadline, setDeadline] = useState<string | undefined>();
    const [description, setDescription] = useState("");
    const [attachments, setAttachments] = useState<string[] | undefined>();

    const isCorrectDeadline = useMemo(() => {
      if (bounty.deadline === "WithoutDeadline") {
        return true;
      }
      if (bounty.deadline === "DueDate") {
        const due_date = moment(bounty.due_date).valueOf();
        const curr = moment()
          .add(parseInt(deadline ?? "") / 1000000, "ms")
          .valueOf();
        return due_date >= curr;
      }
      if (bounty.deadline === "MaxDeadline") {
        const bountyDeadline = utils.getNumberDeadline(bounty) ?? 0;
        const currentDeadline = deadline ? parseInt(deadline) : 0;
        return bountyDeadline >= currentDeadline;
      }
      return false;
    }, [deadline]);

    const handleMaxDeadlineChange = (value: string) => {
      if (deadlineTimeCommitment) {
        const unit = deadlineTimeCommitment.toLowerCase() as MomentDurationConstructor;
        const duration = formatting.formatDurationToNanoSec(value, unit);
        setDeadline(duration.toString());
      }
    };

    const handleTimeCommitmentChange = (selected: { name: string; value: string }) => {
      setValue("deadline", "");
      setDeadlineTimeCommitment(selected.value);
    };

    const handleSetAttachments = (value: string[] | undefined) => {
      setValue("attachments", value?.length ? value : undefined);
      setAttachments(value);
    };

    const { walletAccountId } = useWalletAccountId();

    const { claimBountyV2, getWhitelisted } = useStoreActions((actions) => actions.nearApi);

    const checkUserWhitelisted = async (account_id: string) => {
      try {
        const result = await getWhitelisted(account_id);
        setUserWhitelisted(result);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      if (walletAccountId) {
        checkUserWhitelisted(walletAccountId);
      }
    }, [walletAccountId]);

    const showCompleteKYCMessage = useMemo(() => {
      return (
        bounty.kyc_config?.kyc_required &&
        bounty.kyc_config?.kyc_verification_method === "DuringClaimApproval" &&
        !userWhitelisted
      );
    }, [userWhitelisted]);

    const handleClaimBounty: SubmitHandler<ClaimBountyFormData> = async () => {
      if (isValid) {
        if (params.id) {
          await claimBountyV2({
            id: parseInt(params.id),
            deadline: deadline,
            token: bounty.token,
            attachments,
            description,
            slot
          });
        }
      }
    };

    return (
      <div className="font-poppins text-white">
        {bounty.bounty_flow !== "SimpleBounty" ? (
          <FormRow>
            <h2>Apply to work on this bounty</h2>
            <p className="mt-2.5 text-sm text-neutral-400">
              Let the funder know why they should choose you!
            </p>
          </FormRow>
        ) : (
          <FormRow>
            <h2 className="text-center text-primary-800">Express bounty! </h2>
            <p className="mt-2.5 text-md text-neutral-50 text-center">
              Submit your work/submission directly!
            </p>
          </FormRow>
        )}
        <form
          id="claim-form"
          className="flex-1 flex flex-col justify-between "
          onSubmit={handleSubmit(handleClaimBounty)}>
          <div className="pt-2.5">
            <FormRow>
              <div className="flex gap-4 items-end">
                <FormControl
                  name="description"
                  control={control}
                  defaultValue={description}
                  rules={{
                    required: "Required"
                  }}
                  render={({ field: { name, value, onChange } }) => {
                    return (
                      <TextArea
                        {...{
                          placeholder:
                            "THIS IS A TEMPLATE \n*What skills of you are you offering to this bounty?*\n",
                          value: value,
                          name: name,
                          rows: 5,
                          validation: {
                            valid: !errors.description,
                            message: errors.description?.message,
                            started: isSubmitted
                          },
                          onChange: (value: string) => {
                            onChange(value);
                            setValue("description", value, {
                              shouldValidate: true
                            });
                            setDescription(value);
                          }
                        }}
                        {...{ name, value }}
                      />
                    );
                  }}
                />
              </div>
              {bounty.bounty_flow === "SimpleBounty" && (
                <UploadAttachments onSetAttachments={handleSetAttachments} bountyId={bounty.id} />
              )}
            </FormRow>
            <FormRow>
              <Area>
                <div className="p-4 flex items-center justify-between text-sm text-neutral-300">
                  <div>Bounty deadline</div>
                  <div className="text-success">{utils.getBountyDeadline(bounty)}</div>
                </div>
              </Area>
            </FormRow>
            {bounty.bounty_flow === "AdvancedFlow" && !bounty.allow_deadline_stretch && (
              <FormRow className="py-2.5">
                <div className="text-sm text-neutral-600">Set time commitments</div>
                <div className="flex gap-4 items-end">
                  <input
                    type="hidden"
                    defaultValue={deadlineTimeCommitment}
                    {...register("deadlineTimeCommitment", {
                      validate: (value) =>
                        Boolean(bounty.allow_deadline_stretch) || Boolean(value) || "Required"
                    })}
                  />
                  <ValidationLayout
                    {...{
                      validate: () => {
                        return {
                          valid: !errors.deadlineTimeCommitment,
                          started: isSubmitted,
                          message: errors.deadlineTimeCommitment?.message
                        };
                      }
                    }}>
                    <NewSelect
                      {...{
                        placeholder: "Time commitment",
                        selected: {
                          name: deadlineTimeCommitment,
                          value: deadlineTimeCommitment
                        },
                        defaultOptionsList: defTimeCommitments,
                        defaultOption: {
                          name: "",
                          value: ""
                        },
                        onSelect: (selected: { name: string; value: string }) => {
                          setValue("deadlineTimeCommitment", selected.value, {
                            shouldValidate: true
                          });
                          handleTimeCommitmentChange(selected);
                        }
                      }}
                    />
                  </ValidationLayout>
                  <FormControl
                    name="deadline"
                    control={control}
                    defaultValue={deadline}
                    rules={{
                      validate: {
                        nonZero: (value: string) =>
                          Boolean(bounty.allow_deadline_stretch) ||
                          parseInt(value) > 0 ||
                          "Required",
                        maxDeadline: () => isCorrectDeadline || "Must be less then bounty deadline"
                      }
                    }}
                    render={({ field: { name, value, onChange } }) => {
                      return (
                        <Input
                          {...{
                            customStyles: {
                              input: "px-[20px] py-[15px]"
                            },
                            placeholder: "Duration",
                            value: value,
                            name: name,
                            validation: {
                              valid: !errors.deadline,
                              message: errors.deadline?.message,
                              started: isSubmitted
                            },
                            onChange: (value: string) => {
                              onChange(value);
                              setValue("deadline", value, {
                                shouldValidate: true
                              });
                              handleMaxDeadlineChange(value);
                            },
                            disabled: !deadlineTimeCommitment
                          }}
                          {...{ name, value }}
                        />
                      );
                    }}
                  />
                </div>
              </FormRow>
            )}
          </div>
          <FormRow className="mb-2.5">
            <Amount bounty={bounty} slot={slot} />
          </FormRow>
          {showCompleteKYCMessage && <AppMessages.CompleteKYCMessage />}

          <div className="flex pt-4 justify-between">
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                text: "Cancel",
                onClick: () => handleCloseForm()
              }}
            />
            <LightButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                text: "Apply",
                onClick: () => handleSubmit(handleClaimBounty)(),
                type: "submit"
              }}
            />
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <Modal
        {...{
          customStyles: {
            modal: "min-h-[400px] overflow-auto",
            content: "border border-neutral-700 h-fit"
          },
          open: showForm,
          onClose: () => handleCloseForm()
        }}>
        <ClaimForm />
      </Modal>
    </>
  );
}
