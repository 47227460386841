import BaseSection from "./BaseSection";

export default function WhySection() {
  const items = [
    {
      number: '01',
      title: 'Proof of quality',
      text: 'Check any pro´s work samples, reviews and identity verification',
      illustrationCustomStyle: `
        bg-why-section__item-1-bg bg-contain
        md:bg-why-section__item-1-illu-md md:bg-contain
      `,
    },
    {
      number: '02',
      title: 'No cost until you hire',
      text: 'Interview potential fits for your bounty and only pay for work you approve.',
      illustrationCustomStyle: `
        bg-why-section__item-2-bg bg-contain
        md:bg-why-section__item-2-illu-md md:bg-contain
      `,
    },
    {
      number: '03',
      title: 'Safe and securee',
      text: 'We help protect your data and privacy.',
      illustrationCustomStyle: `
        bg-why-section__item-3-bg bg-contain
        md:bg-why-section__item-3-illu-md md:bg-contain
      `,
    },
  ]
  const id = 'why_section'
  const title = 'Why projects turn to Heroes for  Web3 talent'

  return (
    <BaseSection {...{id, title, items}}/>
  )
}
