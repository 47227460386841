import { createStore, createTypedHooks, persist } from "easy-peasy";
import model from "./models";
import * as nearApiServices from "../services/NearApiServices";
import * as apiService from "../services/Api";
import * as authService from "../services/newAuth";
import * as profileService from "../services/profileApi";
import { GeneralStore } from "../types";
import * as rpcApi from "../services/RpcApi";

const { useStoreActions, useStoreState, useStore, useStoreDispatch } =
  createTypedHooks<GeneralStore.StoreModel>();

export { useStoreActions, useStoreState, useStore, useStoreDispatch };

const store = createStore(
  persist(model, {
    allow: ["error", "newBountyForm", "newBounty", "bountyList", "app", "auth"],
    storage: localStorage,
    mergeStrategy: "mergeShallow"
  }),
  {
    injections: { nearApiServices, apiService, authService, profileService, rpcApi },
    name: "heroes"
  }
);

export default store;
