import classNames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreActions } from "../../../hooks";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import { Bounty } from "../../../types/Bounty";
import { ClaimBountyForm, NotWhitelistedForm } from "../Dialogues";
import { LightButton } from "../../general/Button";

export default function ClaimAction(props: {
  bounty: Bounty;
  text?: string;
  slot?: number;
  customStyles?: { button?: string };
}) {
  const { bounty, text = "Apply for bounty", slot, customStyles } = props;
  const navigate = useNavigate();
  const [showClaimForm, setShowClaimForm] = useState(false);
  const [showWhitelistForm, setShowWhitelistForm] = useState(false);
  const actions = useStoreActions((actions) => actions);
  const {
    nearApi: { getWhitelisted }
  } = actions;

  const { walletAccountId } = useWalletAccountId();

  const isKYCRequired = () => {
    return (
      bounty.kyc_config?.kyc_required &&
      bounty.kyc_config?.kyc_verification_method === "WhenCreatingClaim"
    );
  };

  const handleShowWhitelistForm = () => {
    setShowWhitelistForm(!showWhitelistForm);
  };

  const handleButtonClick = async () => {
    if (!showClaimForm) {
      const isWhitelisted = await getWhitelisted(walletAccountId);
      if (isKYCRequired() && !isWhitelisted) {
        setShowWhitelistForm(true);
      } else {
        setShowClaimForm(!showClaimForm);
      }
    } else {
      setShowClaimForm(!showClaimForm);
    }
  };

  const handleWhitelistSubmit = () => {
    setShowWhitelistForm(false);
    navigate("/kyc");
  };

  return (
    <>
      <LightButton
        {...{
          disabled: false,
          customStyles: {
            button: classNames("py-[0.25em]", customStyles?.button)
          },
          onClick: () => handleButtonClick(),
          text
        }}
      />
      <ClaimBountyForm
        showForm={showClaimForm}
        handleClose={handleButtonClick}
        bounty={bounty}
        slot={slot}
      />
      <NotWhitelistedForm
        show={showWhitelistForm}
        handleClose={handleShowWhitelistForm}
        handleSubmit={handleWhitelistSubmit}
      />
    </>
  );
}
