import { useStoreState } from "../../../../../hooks";
import { Bounty } from "../../../../../types/Bounty";
import Separator from "../../../../general/Separator";
import { calcPlatformFee } from "./helpers";
import MultitaskingBountyAmount from "./MultitaskingBountyAmount";
import MultitaskingDAOFee from "./MultitaskingDAOFee";
import MultitaskingPlatformFee from "./MultitaskingPlatformFee";
import MultitaskingTotalAmount from "./MultitaskingTotalAmount";
import SimpleBountyAmount from "./SimpleBountyAmount";
import SimpleDAOFee from "./SimpleDAOFee";
import SimplePlatformFee from "./SimplePlatformFee";
import SimpleTotalAmount from "./SimpleTotalAmount";
import SlotAmount from "./SlotAmount";

const Amount = (props: { bounty: Bounty; slot?: number }) => {
  const { bounty, slot } = props;
  const tokens = useStoreState((state) => state.app.tokens);

  return (
    <div className="px-[-1.25em]">
      <div className="p-2">
        {Array.isArray(tokens) && tokens.length > 0 && (
          <div className="text-sm w-full">
            <div className="flex justify-between flex-1">
              <div className="text-neutral-50">Bounty rewards:</div>
              {!bounty.multitasking ? (
                <SimpleTotalAmount bounty={bounty} />
              ) : (
                <>
                  {bounty.multitasking?.multitasking_type === "OneForAll" ? (
                    <MultitaskingTotalAmount bounty={bounty} />
                  ) : (
                    <SimpleTotalAmount bounty={bounty} />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="p-2 flex justify-between">
        <div className="text-neutral-50 text-sm">You will receive:</div>
        {!bounty.multitasking ? (
          <SimpleBountyAmount bounty={bounty} className="text-sm text-success" />
        ) : (
          <>
            {bounty.multitasking?.multitasking_type === "OneForAll" ? (
              <MultitaskingBountyAmount bounty={bounty} className="text-sm text-success" />
            ) : (
              <>
                {bounty.multitasking.multitasking_type === "DifferentTasks" ? (
                  <>
                    <SlotAmount bounty={bounty} slot={slot} className="text-sm text-success" />
                  </>
                ) : (
                  <SimpleBountyAmount bounty={bounty} className="text-sm text-success" />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Amount;
