import { Layout } from "../../components/Layout";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../../components/Bounty/Sidebar";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import mixpanel from "mixpanel-browser";
import { MixpanelContext } from "../App";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { parse, stringify } from "query-string";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const Bounties = () => {
  const location = useLocation();
  const context = useContext(MixpanelContext);
  mixpanel.init(context.token, {
    debug: false
  });

  const isSidebarVisible = useMemo(() => {
    return !location.pathname.includes(`/bounties/new`);
  }, [location.pathname]);

  return (
    <Layout>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify
        }}>
        <QueryClientProvider client={queryClient}>
          <div className="md:max-w-screen-md lg:max-w-screen-lg mx-auto text-white w-full ">
            <div className="grid grid-cols-4">
              <div className="col-span-1 lg:block xs:hidden sm:hidden max-w-[256px]">
                <Sidebar visible={isSidebarVisible} />
              </div>
              <div
                className={`${
                  location.pathname === "/bounties" ? "lg:col-span-2" : "lg:col-span-3"
                } md:col-span-4 sm:col-span-4 xs:col-span-4 px-2 sm:px-4`}>
                <Outlet />
              </div>
            </div>
          </div>
        </QueryClientProvider>
      </QueryParamProvider>
    </Layout>
  );
};

export default Bounties;
