import { ReactNode, useState } from "react";
import { SelectArrow } from "../../SVGIcon";
import { buildStyles as buildItemStyles } from "./Item";
import useClickOutside from "../../../../hooks/useClickOutside";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

export type Option = {
  name: string;
  value: string;
};

interface ProfileMenuItemProps {
  customStyles?: ProfileMenuItemStyles;
  onProfileMenuItem?: () => void;
  children?: ReactNode | ReactNode[];
  profileName?: string;
  forceExpanded?: boolean;
  iconAfter?: ReactNode | null;
}

type ProfileMenuItemStyles = {
  container?: string;
  selectedItem?: string;
  optionsList?: string;
};

const buildStyles = (customStyles: ProfileMenuItemStyles | undefined, showOptionList: boolean) => ({
  container: twMerge(`
    w-full h-fit
    ${customStyles?.container ? customStyles.container : ""}
  `),
  selectedItem: twMerge(`
    ${buildItemStyles().container}
    flex flex-row justify-around items-center gap-2.5
    rounded-[10px]
    ${showOptionList ? "rounded-b-none" : ""}
    ${customStyles?.selectedItem ? customStyles.selectedItem : ""}
  `),
  optionsList: twMerge(`
    w-full
    rounded-b-[10px]
    mr-[-0.5em]
    py-[0.5rem]
    ${customStyles?.optionsList ? customStyles.optionsList : ""}
  `)
});

function ProfileMenuItem(props: ProfileMenuItemProps) {
  const { forceExpanded, customStyles, children, profileName, iconAfter } = props;
  const [showOptionList, setShowOptionList] = useState(false);

  const styles = buildStyles(customStyles, showOptionList);

  const handleListDisplay = () => {
    setShowOptionList(!showOptionList);
  };
  const handleListClose = () => {
    if (showOptionList) {
      setShowOptionList(false);
    }
  };

  const listRef = useClickOutside(handleListClose);

  return (
    <div ref={listRef} className={styles.container}>
      <div
        className={classNames(styles.selectedItem, {
          active: showOptionList
        })}
        onClick={handleListDisplay}>
        <span className="max-w-[18ch] overflow-hidden text-ellipsis">{profileName || ""}</span>
        <div className="pl-[0.5rem] min-w-[0.3rem]">
          {forceExpanded ? null : (
            <SelectArrow
              {...{
                position: showOptionList ? "up" : "down",
                color: "hsl(var(--color-neutral-300))"
              }}
            />
          )}
        </div>
        {iconAfter}
      </div>
      {(showOptionList || forceExpanded) && (
        <div className="absolute right-0 min-w-[220px]">
          <div className="relative">
            <ul className={styles.optionsList}>{children ? children : null}</ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileMenuItem;
