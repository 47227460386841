import { twMerge } from "tailwind-merge";
import Button, { ButtonProps, ButtonStyles } from "./Button";

const buildStyles = (
  customStyles: ButtonStyles | undefined,
  disabled: boolean | undefined
) => ({
  button: twMerge(`
    w-full max-w-[208px]
    ${
      !disabled
        ? `
      bg-primary-900
      border-primary-800
      button--primary_hover
      hover:border-primary-900
      active:bg-primary-930
    `
        : ""
    }
    ${customStyles?.button ? customStyles.button : ""}
  `),
  text: twMerge(`
    text-sm text-neutral-900
    md:text-base
    ${customStyles?.text ? customStyles.text : ""}
  `),
});

function LightButton(props: ButtonProps) {
  const { customStyles, text, onClick, disabled, children } = props;

  const styles = buildStyles(customStyles, disabled);

  return (
    <Button
      {...{
        customStyles: styles,
        text,
        onClick,
        disabled,
        children,
      }}
    />
  );
}

export default LightButton;
