import { FunctionCallAction, setupWalletSelector, Transaction } from "@near-wallet-selector/core";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { setupNearWallet } from "@near-wallet-selector/near-wallet";
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import { setupSender } from "@near-wallet-selector/sender";
import { setupHereWallet } from "@near-wallet-selector/here-wallet";
import { setupWalletConnect } from "@near-wallet-selector/wallet-connect";
import { action, thunk, ThunkCreator, thunkOn } from "easy-peasy";
import { providers } from "near-api-js";
import { nearConfig } from "../../services/nearConfig";
import { NearApi } from "../../types";
import helpers from "./helpers";
import newBountyForm from "./NewBountyFormModel";
import { KeyPairEd25519 } from "near-api-js/lib/utils";

type ExecutionError = { index: number; kind?: { ExecutionError?: string } };

const NO_DEPOSIT = "0";

let intervalId: ReturnType<typeof setInterval>;

function checkoutReceipt(callbak: ThunkCreator): NodeJS.Timer {
  const checkInterval: ReturnType<typeof setInterval> = setInterval(() => {
    callbak();
  }, 5000);
  return checkInterval;
}

const nearApiModel: NearApi.NearApiModel = {
  walletConnection: null,
  walletSelector: null,
  transactionHashes: "",
  // receipt: null,
  receiptStatus: "unknown",
  claimStatus: null,
  claimReceiptStatus: "unknown",
  isSignedIn: false
};

const nearApiActions: NearApi.NearApiActions = {
  setClaimStatus: action((state, payload) => {
    state.claimStatus = payload;
  }),
  setTransactionHashes: action((state, payload) => {
    state.transactionHashes = payload;
  }),
  // setReceipt: action((state, payload) => {
  //   state.receipt = payload;
  // }),
  setClaimReceiptStatus: action((state, payload) => {
    state.claimReceiptStatus = payload;
  }),
  setReceiptStatus: action((state, payload) => {
    state.receiptStatus = payload;
  }),
  setWalletConnection: action((state, payload) => {
    state.walletConnection = payload;
  }),
  setWalletSelector: action((state, payload) => {
    state.walletSelector = payload;
  }),
  setSignedIn: action((state, payload) => {
    state.isSignedIn = payload;
  })
};

const nearApiV2Thunks: NearApi.NearApiV2Thunks = {
  dropWalletSelector: thunk(async (actions, _, helpers) => {
    const storeActions = helpers.getStoreActions();
    const storeState = helpers.getStoreState();
    const walletSelector = storeState.nearApi.walletSelector;
    if (walletSelector?.isSignedIn()) {
      const wallet = await walletSelector?.wallet();
      if (wallet) {
        await wallet?.signOut();
      }
    }
    storeActions.nearApi.setSignedIn(false);
    //storeActions.nearApi.setWalletSelector(null);
  }),
  addWalletSelector: thunk(async (actions, _, helpers) => {
    const storeActions = helpers.getStoreActions();
    try {
      const { networkId } = nearConfig;
      const wallets = [
        setupNearWallet(),
        setupMyNearWallet(),
        setupSender(),
        setupMeteorWallet(),
        setupHereWallet(),
        setupWalletConnect({
          projectId: "heroes.build",
          metadata: {
            name: "HEROES Wallet Selector",
            description: "HEROES dApp used by NEAR Wallet Selector",
            url: "https://github.com/HeroesHQ/heroes-ui",
            icons: ["https://avatars.githubusercontent.com/u/37784886"]
          }
        })
      ];
      const selector = await setupWalletSelector({
        network: networkId,
        debug: false,
        allowMultipleSelectors: true,
        modules: wallets
      });

      storeActions.nearApi.setWalletSelector(selector);
    } catch (error) {
      console.log(error);
      //setError(JSON.stringify(e));
    }
  }),
  createTxV2: thunk(async (_, payload) => {
    const { signerId, receiverId } = payload;
    const tx: Transaction = {
      signerId,
      receiverId,
      actions: []
    };
    return tx;
  }),
  viewMethod: thunk(async (actions, payload) => {
    const { walletSelector, contractId, method, args = {} } = payload;
    const { network } = walletSelector.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });
    const res = await provider.query<any>({
      request_type: "call_function",
      account_id: contractId,
      method_name: method,
      args_base64: Buffer.from(JSON.stringify(args)).toString("base64"),
      finality: "optimistic"
    });
    return JSON.parse(Buffer.from(res.result).toString());
  }),
  getTokenBalanceV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const viewMethod = storeActions.nearApi.viewMethod;
    const walletSelector = storeState.nearApi.walletSelector;
    try {
      if (walletSelector) {
        return await viewMethod({
          walletSelector: walletSelector,
          contractId: payload.tokenId,
          method: "ft_balance_of",
          args: {
            account_id: payload.accountId
          }
        });
      } else {
        setError("'walletSelector' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),
  getFtTokenBalance: thunk(async (_, payload, helpers) => {
    const { accountId, tokenId } = payload;
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const rpcApi = helpers.injections.rpcApi;
    const walletSelector = storeState.nearApi.walletSelector;
    try {
      if (walletSelector) {
        return await rpcApi.getFtBalance({ accountId, tokenId });
      } else {
        setError("'walletSelector' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),

  bountyCreateV2: thunk(async (_, payload, helpers) => {
    const { walletSelector } = helpers.getState();
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    const {
      newBountyForm: { selectedTokenAccount, offContractPayment, generateInvoice }
    } = storeState;
    try {
      const location: Location = window.location;
      if (walletSelector && bounty_contract_id) {
        const bountyAmount = !payload.postpaid
          ? newBountyForm.amount
          : "PaymentOutsideContract" in { ...payload.postpaid }
          ? offContractPayment?.amount ?? "0"
          : "0";

        const bountyToken = !payload.postpaid ? selectedTokenAccount?.name : undefined;

        const formattedParams = {
          bounty_create: {
            metadata: {
              title: payload.metadata.title,
              description: payload.metadata.description,
              category: payload.metadata.category,
              attachments: payload.metadata.attachments,
              experience: payload.metadata.experience,
              tags: payload.metadata.tags,
              acceptance_criteria: payload.metadata.acceptance_criteria,
              contact_details: Boolean(payload.metadata.contact_details?.contact)
                ? {
                    contact: payload.metadata?.contact_details?.contact,
                    contact_type: payload.metadata?.contact_details?.contact_type
                  }
                : undefined,
              time_commitment: payload.metadata.time_commitment
            },
            deadline: payload.deadline,
            claimant_approval: payload.claimant_approval,
            reviewers: payload.reviewers,
            kyc_config: payload.kyc_config,
            multitasking: payload.multitasking,
            postpaid: payload.postpaid
          },
          amount: bountyAmount,
          token_id: bountyToken
        };

        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "bounty_create",
          args: Buffer.from(JSON.stringify(formattedParams)).toString("base64"),
          useInvoice: generateInvoice === true ? generateInvoice : undefined
        });
        const result = await wallet.signAndSendTransaction({
          receiverId: bounty_contract_id,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "bounty_create",
                args: formattedParams,
                gas: "300000000000000",
                deposit: "1"
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=createdSuccessfully`
        });
        if (result) {
          const txHashes = result.transaction.hash;
          window.location.href = `${location.href}?signMeta=createdSuccessfully&transactionHashes=${txHashes}`;
        }
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      //setError(JSON.stringify(e));
    }
  }),
  addProposalV2: thunk(async (_, payload, helpers) => {
    const { walletSelector } = helpers.getState();
    const storeState = helpers.getStoreState();
    const {
      newBountyForm: { account_dao, proposal_bond, selectedTokenAccount }
    } = storeState;
    const storeActions = helpers.getStoreActions();
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    const setError = storeActions.error.setError;
    try {
      const location: Location = window.location;
      let requestTxs: Transaction[] | [] = [];

      if (walletSelector) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        if (account_dao) {
          const addProposalTx = await storeActions.nearApi.createTxV2({
            signerId: accounts[0].accountId,
            receiverId: account_dao
          });
          if (selectedTokenAccount) {
            addProposalTx.actions = [
              {
                type: "FunctionCall",
                params: {
                  methodName: "add_proposal",
                  args: Buffer.from(
                    JSON.stringify({
                      proposal: {
                        description: "Add proposal",
                        kind: {
                          FunctionCall: {
                            receiver_id: selectedTokenAccount.name,
                            actions: [
                              {
                                method_name: "ft_transfer_call",
                                args: Buffer.from(JSON.stringify(payload)).toString("base64"),
                                deposit: "1",
                                gas: "100000000000000"
                              }
                            ]
                          }
                        }
                      }
                    })
                  ),
                  gas: "30000000000000",
                  deposit: proposal_bond
                }
              }
            ];

            requestTxs = [addProposalTx];

            const storageBalance = await storeActions.nearApi.getFtTokenStorageBalanceBoundsV2({
              tokenId: selectedTokenAccount.name
            });
            if (!storageBalance?.total) {
              const storageDepositTx = await storeActions.nearApi.createStorageDepositTxV2({
                signerId: accounts[0].accountId,
                tokenId: selectedTokenAccount.name,
                receiverId: account_dao
              });

              requestTxs = [storageDepositTx, ...requestTxs];
            }
            postBeforeCreatingTx({
              accountId: accounts[0].accountId,
              receiverId: account_dao,
              methodName: "add_proposal",
              args: Buffer.from(JSON.stringify(payload)).toString("base64")
            });

            const result = await wallet.signAndSendTransactions({
              transactions: requestTxs,
              callbackUrl: `${location.href}?signMeta=proposalAddedSuccessfully`
            });
            if (result) {
              const txHashes = result.map((tx) => tx.transaction.hash).join(",");
              window.location.href = `${location.href}?signMeta=proposalAddedSuccessfully&transactionHashes=${txHashes}`;
            }
          } else {
            setError("Token account is not defined!");
          }
        } else {
          setError("Account DAO is not defined!");
        }
      } else {
        setError("WalletSelector is not defined!");
      }
    } catch (error) {
      console.log(error);
      //setError(JSON.stringify(e));
    }
  }),
  isWhitelistedV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const viewMethod = storeActions.nearApi.viewMethod;
    const walletSelector = storeState.nearApi.walletSelector;
    const config = storeState.app.config;
    if (walletSelector && config?.kyc_whitelist_contract_id) {
      return await viewMethod({
        walletSelector: walletSelector,
        contractId: config.kyc_whitelist_contract_id,
        method: "is_whitelisted",
        args: {
          account_id: payload
        }
      });
    } else {
      setError("'walletSelector' || 'kyc_whitelist_contract_id' is not defined!");
      return new Promise((resolve) => resolve(false));
    }
  }),
  getWhitelisted: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const rpcAppi = helpers.injections.rpcApi;
    const walletSelector = storeState.nearApi.walletSelector;
    if (walletSelector) {
      return await rpcAppi.getWhitelisted({
        accountId: payload
      });
    } else {
      setError("'walletSelector' is not defined!");
      return new Promise((resolve) => resolve(false));
    }
  }),
  checkTtransactionReceiptV2: thunk(async (actions, _, helpers) => {
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const storeState = helpers.getStoreState();
    const { walletSelector, transactionHashes } = helpers.getState();
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    try {
      if (walletSelector && bounty_contract_id) {
        const { network } = walletSelector.options;
        const provider = new providers.JsonRpcProvider({
          url: network.nodeUrl
        });
        const wallet = await walletSelector.wallet();
        const accoounts = await wallet.getAccounts();
        const resp = await provider.txStatus(transactionHashes, accoounts[0].accountId);
        if (resp) {
          const receipt = resp.receipts_outcome.find(
            (_rec) => (_rec.outcome as any).executor_id === bounty_contract_id
          );
          if (receipt) {
            const receiptStatus = Object.keys(receipt.outcome.status)[0] as NearApi.ReceiptStatus;
            storeActions.nearApi.setReceiptStatus(receiptStatus);
          }
        }
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      storeActions.nearApi.setReceiptStatus("Failure");
    }
  }),
  getTtransactionReceipt: thunk(async (actions, _, helpers) => {
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const storeState = helpers.getStoreState();
    const { walletSelector, transactionHashes } = helpers.getState();
    const rpcApi = helpers.injections.rpcApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    try {
      if (walletSelector) {
        const wallet = await walletSelector.wallet();
        const accoounts = await wallet.getAccounts();
        const resp = await rpcApi.getTxStatus({
          txHash: transactionHashes,
          accountId: accoounts[0].accountId
        });
        if (resp) {
          const receipt = resp.receipts_outcome.find(
            (_rec) => (_rec.outcome as any).executor_id === bounty_contract_id
          );
          if (receipt) {
            const receiptStatus = Object.keys(receipt.outcome.status)[0] as NearApi.ReceiptStatus;
            storeActions.nearApi.setReceiptStatus(receiptStatus);
          }
        }
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      storeActions.nearApi.setReceiptStatus("Failure");
    }
  }),
  checkClaimReceiptV2: thunk(async (actions, _, helpers) => {
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector, transactionHashes } = helpers.getState();
    const storeState = helpers.getStoreState();
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    try {
      if (walletSelector && bounty_contract_id) {
        const { network } = walletSelector.options;
        const provider = new providers.JsonRpcProvider({
          url: network.nodeUrl
        });
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const resp = await provider.txStatus(transactionHashes, accounts[0].accountId);
        if (resp) {
          const receipt = resp.receipts_outcome.find(
            (_rec) => (_rec.outcome as any).executor_id === bounty_contract_id
          );
          if (receipt) {
            const receiptStatus = Object.keys(receipt.outcome.status)[0] as NearApi.ReceiptStatus;
            console.log("receiptStatus", receiptStatus);
            storeActions.nearApi.setClaimReceiptStatus(receiptStatus);
          }
        }
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      storeActions.nearApi.setClaimReceiptStatus("Failure");
      //setError("Check Receipt failure!");
    }
  }),
  getClaimReceipt: thunk(async (actions, _, helpers) => {
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector, transactionHashes } = helpers.getState();
    const storeState = helpers.getStoreState();
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const rpcApi = helpers.injections.rpcApi;
    try {
      if (walletSelector) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const resp = await rpcApi.getTxStatus({
          txHash: transactionHashes,
          accountId: accounts[0].accountId
        });
        if (resp) {
          const receipt = resp.receipts_outcome.find(
            (_rec) => (_rec.outcome as any).executor_id === bounty_contract_id
          );
          if (receipt) {
            const receiptStatus = Object.keys(receipt.outcome.status)[0] as NearApi.ReceiptStatus;
            storeActions.nearApi.setClaimReceiptStatus(receiptStatus);
          }
        }
      } else {
        setError("'walletSelector' is not defined!");
      }
    } catch (error) {
      console.log(error);
      storeActions.nearApi.setClaimReceiptStatus("Failure");
      //setError("Check Receipt failure!");
    }
  }),
  getFtTokenStorageBalanceV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const viewMethod = storeActions.nearApi.viewMethod;
    const walletSelector = storeState.nearApi.walletSelector;
    try {
      if (walletSelector) {
        return await viewMethod({
          walletSelector: walletSelector,
          contractId: payload.tokenId,
          method: "storage_balance_of",
          args: {
            account_id: payload.accountId
          }
        });
      } else {
        setError("'walletSelector' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),
  getFtStorageBalance: thunk(async (_, payload, helpers) => {
    const { accountId, tokenId } = payload;
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const rpcApi = helpers.injections.rpcApi;
    const walletSelector = storeState.nearApi.walletSelector;
    try {
      if (walletSelector) {
        return await rpcApi.getFtStorageBalance({ accountId, tokenId });
      } else {
        setError("'walletSelector' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),
  getFtTokenStorageBalanceBoundsV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const walletSelector = storeState.nearApi.walletSelector;
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const viewMethod = storeActions.nearApi.viewMethod;
    try {
      if (walletSelector) {
        return await viewMethod({
          walletSelector: walletSelector,
          contractId: payload.tokenId,
          method: "storage_balance_bounds"
        });
      } else {
        setError("'walletSelector' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),
  getFtStorageBalanceBounds: thunk(async (_, payload, helpers) => {
    const { tokenId } = payload;
    const storeState = helpers.getStoreState();
    const walletSelector = storeState.nearApi.walletSelector;
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const rpcApi = helpers.injections.rpcApi;
    try {
      if (walletSelector) {
        return await rpcApi.getFtStorageBalanceBounds({ tokenId });
      } else {
        setError("'walletSelector' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),
  createStorageDepositTxV2: thunk(async (_, payload, helpers) => {
    const { signerId, tokenId, receiverId } = payload;
    const storeActions = helpers.getStoreActions();
    const storageBalanceBounds = await storeActions.nearApi.getFtStorageBalanceBounds({
      tokenId: tokenId
    });
    const amount = storageBalanceBounds.min;

    const storageDepositTx = await storeActions.nearApi.createTxV2({
      signerId,
      receiverId: tokenId
    });
    storageDepositTx.actions = [
      {
        type: "FunctionCall",
        params: {
          methodName: "storage_deposit",
          args: Buffer.from(
            JSON.stringify({
              account_id: receiverId,
              registration_only: true
            })
          ),
          gas: "30000000000000",
          deposit: amount.toString()
        }
      }
    ];
    return storageDepositTx;
  }),
  claimBountyV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const bounty_claim_bond = storeState.app.config?.bounty_claim_bond;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const { walletSelector } = helpers.getState();
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    const location: Location = window.location;
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const args = {
          id: payload.id,
          deadline: payload.deadline,
          description: payload.description,
          slot: payload.slot
        };

        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "bounty_claim",
          args: Buffer.from(JSON.stringify(args)).toString("base64"),
          attachments: payload.attachments,
          claimDueDate: payload.deadline
        });

        const result = await wallet.signAndSendTransaction({
          receiverId: bounty_contract_id,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "bounty_claim",
                args,
                gas: "300000000000000",
                deposit: bounty_claim_bond ?? "1000000000000000000000000"
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=claimedSuccessfully`
        });
        if (result) {
          const txHashes = result.transaction.hash;
          window.location.href = `${location.href}?signMeta=claimedSuccessfully&transactionHashes=${txHashes}`;
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),
  setDecisionOnClaimV2: thunk(async (_, payload, services) => {
    const storeState = services.getStoreState();
    const storeActions = services.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const location: Location = window.location;
    const postBeforeCreatingTx = services.injections.apiService.Api.postBeforeCreatingTx;
    let requestTxs: Transaction[] | [] = [];
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        const decisionOnClaimTxns: Transaction[] = payload.map((item) => {
          return {
            signerId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            actions: [
              {
                type: "FunctionCall",
                params: {
                  methodName: "decision_on_claim",
                  args: {
                    id: item.id,
                    claimer: item.claimer,
                    approve: item.approve,
                    claim_number: item.claim_number,
                    kyc_postponed: item.kyc_postponed
                  },
                  gas: "300000000000000",
                  deposit: NO_DEPOSIT
                }
              }
            ]
          };
        });

        requestTxs = decisionOnClaimTxns;

        for (const item of payload) {
          if (item.token && item.approve) {
            const storageBalance = await storeActions.nearApi.getFtStorageBalance({
              tokenId: item.token,
              accountId: item.claimer
            });

            if (!storageBalance?.total) {
              const storageDepositTx = await storeActions.nearApi.createStorageDepositTxV2({
                signerId: accounts[0].accountId,
                tokenId: item.token,
                receiverId: item.claimer
              });

              requestTxs = [storageDepositTx, ...requestTxs];
            }
          }
        }

        payload.length > 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            batch: payload.map((item) => {
              return {
                methodName: "decision_on_claim",
                args: Buffer.from(
                  JSON.stringify({
                    id: item.id,
                    claimer: item.claimer,
                    approve: item.approve,
                    claim_number: item.claim_number,
                    kyc_postponed: item.kyc_postponed
                  })
                ).toString("base64")
              };
            })
          });

        payload.length === 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            methodName: "decision_on_claim",
            args: Buffer.from(
              JSON.stringify({
                id: payload[0].id,
                claimer: payload[0].claimer,
                approve: payload[0].approve,
                claim_number: payload[0].claim_number,
                kyc_postponed: payload[0].kyc_postponed
              })
            ).toString("base64")
          });

        const result = await wallet.signAndSendTransactions({
          transactions: requestTxs,
          callbackUrl: `${location.href}?signMeta=${
            payload[0]?.approve ? "bountyAcceptedSuccessfully" : "bountyDeclinedSuccessfully"
          }`
        });

        if (result) {
          const txHashes = result.map((tx) => tx.transaction.hash).join(",");
          window.location.href = `${location.href}?signMeta=${
            payload[0].approve ? "bountyAcceptedSuccessfully" : "bountyDeclinedSuccessfully"
          }&transactionHashes=${txHashes}`;
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? JSON.stringify(error));
    }
  }),
  setAcceptClaimant: thunk(async (_, payload, services) => {
    const storeState = services.getStoreState();
    const storeActions = services.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const location: Location = window.location;
    const postBeforeCreatingTx = services.injections.apiService.Api.postBeforeCreatingTx;
    let requestTxs: Transaction[] | [] = [];
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        const decisionOnClaimTxns: Transaction[] = payload.map((item) => {
          return {
            signerId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            actions: [
              {
                type: "FunctionCall",
                params: {
                  methodName: "accept_claimant",
                  args: {
                    id: item.id,
                    receiver_id: item.receiver_id,
                    claim_number: item.claim_number,
                    kyc_postponed: item.kyc_postponed
                  },
                  gas: "300000000000000",
                  deposit: "1"
                }
              }
            ]
          };
        });

        requestTxs = decisionOnClaimTxns;

        for (const item of payload) {
          if (item.token) {
            const storageBalance = await storeActions.nearApi.getFtStorageBalance({
              tokenId: item.token,
              accountId: item.receiver_id
            });

            if (!storageBalance?.total) {
              const storageDepositTx = await storeActions.nearApi.createStorageDepositTxV2({
                signerId: accounts[0].accountId,
                tokenId: item.token,
                receiverId: item.receiver_id
              });

              requestTxs = [storageDepositTx, ...requestTxs];
            }
          }
        }

        payload.length > 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            batch: payload.map((item) => {
              return {
                methodName: "accept_claimant",
                args: Buffer.from(
                  JSON.stringify({
                    id: item.id,
                    claimer: item.receiver_id,
                    claim_number: item.claim_number,
                    kyc_postponed: item.kyc_postponed
                  })
                ).toString("base64")
              };
            })
          });

        payload.length === 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            methodName: "accept_claimant",
            args: Buffer.from(
              JSON.stringify({
                id: payload[0].id,
                claimer: payload[0].receiver_id,
                claim_number: payload[0].claim_number,
                kyc_postponed: payload[0].kyc_postponed
              })
            ).toString("base64")
          });

        const result = await wallet.signAndSendTransactions({
          transactions: requestTxs,
          callbackUrl: `${location.href}?signMeta="bountyAcceptedSuccessfully"`
        });

        if (result) {
          const txHashes = result.map((tx) => tx.transaction.hash).join(",");
          window.location.href = `${location.href}?signMeta="bountyAcceptedSuccessfully"&transactionHashes=${txHashes}`;
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? JSON.stringify(error));
    }
  }),
  setDeclineClaimant: thunk(async (_, payload, services) => {
    const storeState = services.getStoreState();
    const storeActions = services.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const postBeforeCreatingTx = services.injections.apiService.Api.postBeforeCreatingTx;
    const postBatchCreatingTx = services.injections.apiService.Api.postBatchCreatingTx;
    let requestTxs: Transaction[] | [] = [];
    const { getNear } = helpers;
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        const decisionOnClaimTxns: Transaction[] = payload.map((item) => {
          return {
            signerId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            actions: [
              {
                type: "FunctionCall",
                params: {
                  methodName: "decline_claimant",
                  args: {
                    id: item.id,
                    receiver_id: item.receiver_id,
                    claim_number: item.claim_number,
                    kyc_postponed: item.kyc_postponed
                  },
                  gas: "300000000000000",
                  deposit: NO_DEPOSIT
                }
              }
            ]
          };
        });

        requestTxs = decisionOnClaimTxns;

        payload.length === 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            methodName: "decline_claimant",
            args: Buffer.from(
              JSON.stringify({
                id: payload[0].id,
                receiver_id: payload[0].receiver_id,
                claim_number: payload[0].claim_number,
                kyc_postponed: payload[0].kyc_postponed
              })
            ).toString("base64")
          });

        if (requestTxs.length == 1) {
          return await wallet.signAndSendTransaction(requestTxs[0]);
        } else {
          const near = await getNear(wallet.id);

          const keyPair = (await near.config.keyStore.getKey(
            nearConfig.networkId,
            accounts[0].accountId
          )) as KeyPairEd25519;

          const keys = {
            ...accounts[0],
            secretKey: keyPair.secretKey
          };
          const result = await postBatchCreatingTx({
            accountId: keys.accountId,
            transactions: {
              batch: requestTxs.flatMap(({ actions }) =>
                (actions as FunctionCallAction[]).flatMap(({ params }) => {
                  return {
                    methodName: params.methodName,
                    args: Buffer.from(JSON.stringify(params.args)).toString("base64"),
                    gas: params.gas,
                    deposit: params.deposit
                  };
                })
              ),
              publicKey: keys.publicKey,
              privateKey: keys.secretKey
            }
          });
          if (!result.error) {
            return result;
          } else {
            setError(result.message);
          }
        }

        // const result = await wallet.signAndSendTransactions({
        //   transactions: requestTxs,
        //   callbackUrl: `${location.href}?signMeta=${
        //     payload[0]?.approve ? "bountyAcceptedSuccessfully" : "bountyDeclinedSuccessfully"
        //   }`
        // });

        // if (result) {
        //   const txHashes = result.map((tx) => tx.transaction.hash).join(",");
        //   window.location.href = `${location.href}?signMeta=${
        //     payload[0].approve ? "bountyAcceptedSuccessfully" : "bountyDeclinedSuccessfully"
        //   }&transactionHashes=${txHashes}`;
        // }
        // return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  setBountyGiveUpV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    try {
      const walletSelector = storeState.nearApi.walletSelector;
      const bounty_contract_id = storeState.app.config?.bounty_contract_id;
      const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
      const location: Location = window.location;
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const args = {
          id: payload.id,
          claim_number: payload.claim_number
        };

        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "bounty_give_up",
          args: Buffer.from(JSON.stringify(args)).toString("base64"),
          reasonForRefusal: payload.giveUpReason
        });

        const result = await wallet.signAndSendTransaction({
          receiverId: bounty_contract_id,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "bounty_give_up",
                args,
                gas: "300000000000000",
                deposit: NO_DEPOSIT
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=bountyGiveUpSuccessfully`
        });
        if (result) {
          const txHashes = result.transaction.hash;
          window.location.href = `${location.href}?signMeta=bountyGiveUpSuccessfully&transactionHashes=${txHashes}`;
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  setBountyDoneV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const walletSelector = storeState.nearApi.walletSelector;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    const location: Location = window.location;
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const args = {
          id: payload.id,
          description: payload.description,
          claim_number: payload.claim_number
        };

        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "bounty_done",
          args: Buffer.from(JSON.stringify(args)).toString("base64"),
          linkToWork: payload.linkToWork,
          attachments: payload.attachments
        });

        const result = await wallet.signAndSendTransaction({
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "bounty_done",
                args,
                gas: "130000000000000",
                deposit: NO_DEPOSIT
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=bountyDoneSuccessfully`
        });
        if (result) {
          const txHashes = result.transaction.hash;
          window.location.href = `${location.href}?signMeta=bountyDoneSuccessfully&transactionHashes=${txHashes}`;
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  /** @deprecated */
  setBountyActionV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const location: Location = window.location;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    let requestTxs: Transaction[] | [] = [];
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        const bountyActionTxns: Transaction[] = payload.map((item) => {
          return {
            signerId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            actions: [
              {
                type: "FunctionCall",
                params: {
                  methodName: "bounty_action",
                  args: Buffer.from(
                    JSON.stringify({
                      id: item.id,
                      action: item.action,
                      claim_number: item.claim_number
                    })
                  ),
                  gas: "300000000000000",
                  deposit: "1"
                }
              }
            ]
          };
        });

        requestTxs = bountyActionTxns;

        for (const item of payload) {
          if (item.token) {
            if (typeof item.action === "object" && "ClaimApproved" in item.action) {
              const storageBalance = await storeActions.nearApi.getFtStorageBalance({
                tokenId: item.token,
                accountId: item.action.ClaimApproved.receiver_id
              });
              if (!storageBalance?.total) {
                const storageDepositTx = await storeActions.nearApi.createStorageDepositTxV2({
                  signerId: accounts[0].accountId,
                  tokenId: item.token,
                  receiverId: item.action.ClaimApproved.receiver_id
                });

                requestTxs = [storageDepositTx, ...requestTxs];
              }
            }
          }
        }

        payload.length > 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            batch: payload.map((item) => {
              return {
                methodName: "bounty_action",
                args: Buffer.from(
                  JSON.stringify({
                    id: item.id,
                    action: item.action,
                    claim_number: item.claim_number
                  })
                ).toString("base64")
              };
            })
          });

        payload.length === 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            methodName: "bounty_action",
            args: Buffer.from(
              JSON.stringify({
                id: payload[0].id,
                action: payload[0].action,
                claim_number: payload[0].claim_number
              })
            ).toString("base64")
          });

        const callbackUrl =
          typeof payload[0]?.action === "object" && "ClaimApproved" in payload[0]?.action
            ? `${location.href}?signMeta=${
                "ClaimApproved" in payload[0]?.action ? "approvedSuccessfully" : "actionSucessfully"
              }`
            : `${location.href}?signMeta=${
                typeof payload[0]?.action === "object" && "ClaimApproved" in payload[0]?.action
                  ? "approvedSuccessfully"
                  : "actionSucessfully"
              }`;

        const result = await wallet.signAndSendTransactions({
          transactions: requestTxs,
          callbackUrl
        });
        if (result) {
          const txHashes = result.map((tx) => tx.transaction.hash).join(",");
          window.location.href =
            typeof payload[0]?.action === "object" && "ClaimApproved" in payload[0]?.action
              ? `${location.href}?signMeta=${
                  "ClaimApproved" in payload[0]?.action
                    ? "approvedSuccessfully"
                    : "actionSucessfully"
                }&transactionHashes=${txHashes}`
              : `${location.href}?signMeta=${
                  typeof payload[0]?.action === "object" && "ClaimApproved" in payload[0]?.action
                    ? "approvedSuccessfully"
                    : "actionSucessfully"
                }&transactionHashes=${txHashes}`;
        }
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),
  setBountyApprove: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const location: Location = window.location;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    let requestTxs: Transaction[] | [] = [];
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        const bountyActionTxns: Transaction[] = payload.map((item) => {
          return {
            signerId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            actions: [
              {
                type: "FunctionCall",
                params: {
                  methodName: "bounty_approve",
                  args: Buffer.from(
                    JSON.stringify({
                      id: item.id,
                      receiver_id: item.receiver_id,
                      claim_number: item.claim_number
                    })
                  ),
                  gas: "300000000000000",
                  deposit: "1"
                }
              }
            ]
          };
        });

        requestTxs = bountyActionTxns;

        for (const item of payload) {
          if (item.token) {
            const storageBalance = await storeActions.nearApi.getFtStorageBalance({
              tokenId: item.token,
              accountId: item.receiver_id
            });
            if (!storageBalance?.total) {
              const storageDepositTx = await storeActions.nearApi.createStorageDepositTxV2({
                signerId: accounts[0].accountId,
                tokenId: item.token,
                receiverId: item.receiver_id
              });

              requestTxs = [storageDepositTx, ...requestTxs];
            }
          }
        }

        payload.length > 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            batch: payload.map((item) => {
              return {
                methodName: "bounty_approve",
                args: Buffer.from(
                  JSON.stringify({
                    id: item.id,
                    receiver_id: item.receiver_id,
                    claim_number: item.claim_number
                  })
                ).toString("base64")
              };
            })
          });

        payload.length === 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            methodName: "bounty_approve",
            args: Buffer.from(
              JSON.stringify({
                id: payload[0].id,
                receiver_id: payload[0].receiver_id,
                claim_number: payload[0].claim_number
              })
            ).toString("base64")
          });

        const callbackUrl = `${location.href}?signMeta=approvedSuccessfully`;

        const result = await wallet.signAndSendTransactions({
          transactions: requestTxs,
          callbackUrl
        });
        if (result) {
          const txHashes = result.map((tx) => tx.transaction.hash).join(",");
          window.location.href = `${location.href}?signMeta=approvedSuccessfully&transactionHashes=${txHashes}`;
        }
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),
  setSeveralApprove: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const location: Location = window.location;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    let requestTxs: Transaction[] | [] = [];
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        const bountyActionTxns: Transaction[] = payload.map((item) => {
          return {
            signerId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            actions: [
              {
                type: "FunctionCall",
                params: {
                  methodName: "bounty_approve_of_several",
                  args: Buffer.from(
                    JSON.stringify({
                      id: item.id
                    })
                  ),
                  gas: "300000000000000",
                  deposit: "1"
                }
              }
            ]
          };
        });

        requestTxs = bountyActionTxns;

        payload.length > 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            batch: payload.map((item) => {
              return {
                methodName: "bounty_approve_of_several",
                args: Buffer.from(
                  JSON.stringify({
                    id: item.id
                  })
                ).toString("base64")
              };
            })
          });

        payload.length === 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            methodName: "bounty_approve_of_several",
            args: Buffer.from(
              JSON.stringify({
                id: payload[0].id
              })
            ).toString("base64")
          });

        const callbackUrl = `${location.href}?signMeta=severalApprovedSuccessfully`;

        const result = await wallet.signAndSendTransactions({
          transactions: requestTxs,
          callbackUrl
        });
        if (result) {
          const txHashes = result.map((tx) => tx.transaction.hash).join(",");
          window.location.href = `${location.href}?signMeta=severalApprovedSuccessfully&transactionHashes=${txHashes}`;
        }
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),
  setBountyReject: thunk(async (_, payload, services) => {
    const storeState = services.getStoreState();
    const storeActions = services.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const postBeforeCreatingTx = services.injections.apiService.Api.postBeforeCreatingTx;
    const postBatchCreatingTx = services.injections.apiService.Api.postBatchCreatingTx;
    let requestTxs: Transaction[] | [] = [];
    const { getNear } = helpers;
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        const bountyActionTxns: Transaction[] = payload.map((item) => {
          return {
            signerId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            actions: [
              {
                type: "FunctionCall",
                params: {
                  methodName: "bounty_reject",
                  args: {
                    id: item.id,
                    receiver_id: item.receiver_id,
                    claim_number: item.claim_number
                  },
                  gas: "300000000000000",
                  deposit: NO_DEPOSIT
                }
              }
            ]
          };
        });

        requestTxs = bountyActionTxns;

        payload.length === 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            methodName: "bounty_reject",
            args: Buffer.from(
              JSON.stringify({
                id: payload[0].id,
                receiver_id: payload[0].receiver_id,
                claim_number: payload[0].claim_number
              })
            ).toString("base64")
          });

        if (requestTxs.length == 1) {
          return await wallet.signAndSendTransaction(requestTxs[0]);
        } else {
          const near = await getNear(wallet.id);

          const keyPair = (await near.config.keyStore.getKey(
            nearConfig.networkId,
            accounts[0].accountId
          )) as KeyPairEd25519;

          const keys = {
            ...accounts[0],
            secretKey: keyPair.secretKey
          };

          const result = await postBatchCreatingTx({
            accountId: keys.accountId,
            transactions: {
              batch: requestTxs.flatMap(({ actions }) =>
                (actions as FunctionCallAction[]).flatMap(({ params }) => {
                  return {
                    methodName: params.methodName,
                    args: Buffer.from(JSON.stringify(params.args)).toString("base64"),
                    gas: params.gas,
                    deposit: params.deposit
                  };
                })
              ),
              publicKey: keys.publicKey,
              privateKey: keys.secretKey
            }
          });

          if (!result.error) {
            return result;
          } else {
            setError(result.message);
          }
        }
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  setBountyFinalize: thunk(async (_, payload, services) => {
    const storeState = services.getStoreState();
    const storeActions = services.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const postBeforeCreatingTx = services.injections.apiService.Api.postBeforeCreatingTx;
    const postBatchCreatingTx = services.injections.apiService.Api.postBatchCreatingTx;
    let requestTxs: Transaction[] | [] = [];
    const { getNear } = helpers;
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        const bountyActionTxns: Transaction[] = payload.map((item) => {
          return {
            signerId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            actions: [
              {
                type: "FunctionCall",
                params: {
                  methodName: "bounty_finalize",
                  args: {
                    id: item.id,
                    claimant: item.claimant
                  },
                  gas: "300000000000000",
                  deposit: NO_DEPOSIT
                }
              }
            ]
          };
        });

        requestTxs = bountyActionTxns;

        payload.length === 1 &&
          postBeforeCreatingTx({
            accountId: accounts[0].accountId,
            receiverId: bounty_contract_id,
            methodName: "bounty_finalize",
            args: Buffer.from(
              JSON.stringify({
                id: payload[0].id,
                claimant: payload[0].claimant
              })
            ).toString("base64")
          });

        if (requestTxs.length == 1) {
          return await wallet.signAndSendTransaction(requestTxs[0]);
        } else {
          const near = await getNear(wallet.id);

          const keyPair = (await near.config.keyStore.getKey(
            nearConfig.networkId,
            accounts[0].accountId
          )) as KeyPairEd25519;

          const keys = {
            ...accounts[0],
            secretKey: keyPair.secretKey
          };

          const result = await postBatchCreatingTx({
            accountId: keys.accountId,
            transactions: {
              batch: requestTxs.flatMap(({ actions }) =>
                (actions as FunctionCallAction[]).flatMap(({ params }) => {
                  return {
                    methodName: params.methodName,
                    args: Buffer.from(JSON.stringify(params.args)).toString("base64"),
                    gas: params.gas,
                    deposit: params.deposit
                  };
                })
              ),
              publicKey: keys.publicKey,
              privateKey: keys.secretKey
            }
          });

          if (!result.error) {
            return result;
          } else {
            setError(result.message);
          }
        }
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  cancelBountyV2: thunk(async (_, payload, helpers) => {
    const { walletSelector } = helpers.getState();
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    try {
      const bounty_contract_id = storeState.app.config?.bounty_contract_id;
      const location: Location = window.location;
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const args = { id: payload.id };

        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "bounty_cancel",
          args: Buffer.from(JSON.stringify(args)).toString("base64")
        });

        const result = await wallet.signAndSendTransaction({
          receiverId: bounty_contract_id,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "bounty_cancel",
                args,
                gas: "300000000000000",
                deposit: NO_DEPOSIT
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=canceledSuccessfully`
        });
        if (result) {
          const txHashes = result.transaction.hash;
          window.location.href = `${location.href}?signMeta=canceledSuccessfully&transactionHashes=${txHashes}`;
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  setPaymentConfirmedV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const walletSelector = storeState.nearApi.walletSelector;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    const location: Location = window.location;
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const args = {
          id: payload.id,
          claim_number: payload.claim_number
        };

        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "confirm_payment",
          args: Buffer.from(JSON.stringify(args)).toString("base64")
        });

        const result = await wallet.signAndSendTransaction({
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "confirm_payment",
                args,
                gas: "130000000000000",
                deposit: "1"
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=confirmedSuccessfully`
        });
        if (result) {
          const txHashes = result.transaction.hash;
          window.location.href = `${location.href}?signMeta=confirmedSuccessfully&transactionHashes=${txHashes}`;
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  setAsPaidV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    const location: Location = window.location;
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        const args = {
          id: payload.id,
          receiver_id: payload.receiver_id,
          claim_number: payload.claim_number
        };

        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "mark_as_paid",
          args: Buffer.from(JSON.stringify(args)).toString("base64")
        });

        const result = await wallet.signAndSendTransaction({
          signerId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "mark_as_paid",
                args: Buffer.from(JSON.stringify(args)),
                gas: "300000000000000",
                deposit: NO_DEPOSIT
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=setPaidSuccessfully`
        });

        if (result) {
          const txHashes = result.transaction.hash;
          window.location.href = `${location.href}?signMeta=setPaidSuccessfully&transactionHashes=${txHashes}`;
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  startСompetition: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const location: Location = window.location;
    const postAfterCreatingTx = helpers.injections.apiService.Api.postAfterCreatingTx;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        const args = {
          id: payload.id
        };

        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "start_competition",
          args: Buffer.from(JSON.stringify(args)).toString("base64")
        });

        const result = await wallet.signAndSendTransaction({
          signerId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "start_competition",
                args: Buffer.from(JSON.stringify(args)),
                gas: "300000000000000",
                deposit: NO_DEPOSIT
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=setPaidSuccessfully`
        });
        if (result) {
          const txHashes = result.transaction.hash;
          postAfterCreatingTx(txHashes);
          window.location.reload();
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  ftTransferCallV2: thunk(async (_, payload, helpers) => {
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = helpers.getState();
    const { args, tokenId } = payload;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    const storeState = helpers.getStoreState();
    const { generateInvoice, issueId } = storeState.newBountyForm;
    try {
      const location: Location = window.location;
      if (walletSelector) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();

        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: tokenId,
          methodName: "ft_transfer_call",
          args: Buffer.from(JSON.stringify(args)).toString("base64"),
          ...(generateInvoice && { useInvoice: generateInvoice }),
          ...(issueId && { issueId })
        });

        const result = await wallet.signAndSendTransaction({
          receiverId: tokenId,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer_call",
                args: args,
                gas: "300000000000000",
                deposit: "1"
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=createdSuccessfully`
        });
        if (result) {
          const txHashes = result.transaction.hash;
          window.location.href = `${location.href}?signMeta=createdSuccessfully&transactionHashes=${txHashes}`;
        }
      } else {
        setError("'walletSelector' is not defined!");
      }
    } catch (error) {
      console.log(error);
      //setError(JSON.stringify(e));
    }
  }),
  updateBountyV2: thunk(async (_, payload, helpers) => {
    const { walletSelector } = helpers.getState();
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    try {
      const location: Location = window.location;
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const args = {
          id: payload.id,
          bounty_update: payload.bounty_update
        };
        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "bounty_update",
          args: Buffer.from(JSON.stringify(args)).toString("base64")
        });

        const result = await wallet.signAndSendTransaction({
          receiverId: bounty_contract_id,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "bounty_update",
                args,
                gas: "300000000000000",
                deposit: NO_DEPOSIT
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=bountyUpdatedSuccessfully`
        });
        if (result) {
          const txHashes = result.transaction.hash;
          window.location.href = `${location.href}?signMeta=bountyUpdatedSuccessfully&transactionHashes=${txHashes}`;
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  checkAccountV2: thunk(async (actions, payload, helpers) => {
    const { near } = await helpers.injections.nearApiServices.getNearApi();
    try {
      return await near?.account(payload);
    } catch (error) {
      console.log(error);
    }
  }),
  /** @deprecated */
  checkPolicyV2: thunk(async (actions, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const { walletSelector } = storeState.nearApi;
    const storeActions = helpers.getStoreActions();
    const viewMethod = storeActions.nearApi.viewMethod;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    if (walletSelector && bounty_contract_id) {
      return await viewMethod({
        walletSelector: walletSelector,
        contractId: payload,
        method: "get_policy"
      });
    }
  }),
  getDaoPolicy: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const { walletSelector } = storeState.nearApi;
    const rpcApi = helpers.injections.rpcApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    if (walletSelector && bounty_contract_id) {
      return await rpcApi.getDaoPolicy({ daoId: payload });
    }
  }),
  checkIsOwnerWhitelisted: thunk(async (actions, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const { walletSelector } = storeState.nearApi;
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const viewMethod = storeActions.nearApi.viewMethod;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    try {
      if (walletSelector && bounty_contract_id) {
        return await viewMethod({
          walletSelector: walletSelector,
          contractId: bounty_contract_id,
          method: "is_owner_whitelisted",
          args: { account_id: payload.walletAccountId }
        });
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }),
  checkOwnerWhitelisted: thunk(async (_, payload, helpers) => {
    const { accountId } = payload;
    const storeState = helpers.getStoreState();
    const { walletSelector } = storeState.nearApi;
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const rpcApi = helpers.injections.rpcApi;
    try {
      if (walletSelector) {
        return await rpcApi.getOwnerWhitelisted({ accountId });
      } else {
        setError("'walletSelector' is not defined!");
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }),
  checkIsPostpaidSubscriberWhitelisted: thunk(async (actions, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const { walletSelector } = storeState.nearApi;
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const viewMethod = storeActions.nearApi.viewMethod;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    try {
      if (walletSelector && bounty_contract_id) {
        return await viewMethod({
          walletSelector: walletSelector,
          contractId: bounty_contract_id,
          method: "is_postpaid_subscriber_whitelisted",
          args: { account_id: payload.walletAccountId }
        });
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }),
  checkPostpaidSubscriberWhitelisted: thunk(async (_, payload, helpers) => {
    const { accountId } = payload;
    const storeState = helpers.getStoreState();
    const { walletSelector } = storeState.nearApi;
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const rpcApi = helpers.injections.rpcApi;
    try {
      if (walletSelector) {
        return await rpcApi.getPostpaidSubscriberWhitelisted({ accountId });
      } else {
        setError("'walletSelector' is not defined!");
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }),
  extendClaimDeadlineV2: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const walletSelector = storeState.nearApi.walletSelector;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    const location: Location = window.location;
    try {
      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const args = {
          id: payload.id,
          receiver_id: payload.receiver_id,
          deadline: payload.deadline,
          claim_number: payload.claim_number
        };
        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "bounty_update",
          args: Buffer.from(JSON.stringify(args)).toString("base64")
        });

        const result = await wallet.signAndSendTransaction({
          receiverId: bounty_contract_id,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "extend_claim_deadline",
                args,
                gas: "300000000000000",
                deposit: NO_DEPOSIT
              }
            }
          ],
          callbackUrl: `${location.href}?signMeta=deadlineChangedSuccessfully`
        });
        if (result) {
          const txHashes = result.transaction.hash;
          window.location.href = `${location.href}?signMeta=deadlineChangedSuccessfully&transactionHashes=${txHashes}`;
        }
        return result;
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
      const msg = (error as Error)?.message ? (error as Error).message : JSON.stringify(error);
      setError((error as ExecutionError)?.kind?.ExecutionError ?? msg);
    }
  }),
  withdraw: thunk(async (_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const setError = storeActions.error.setError;
    const { walletSelector } = storeState.nearApi;
    const bounty_contract_id = storeState.app.config?.bounty_contract_id;
    const postBeforeCreatingTx = helpers.injections.apiService.Api.postBeforeCreatingTx;
    const location: Location = window.location;
    try {
      let requestTxs: Transaction[] | [] = [];

      if (walletSelector && bounty_contract_id) {
        const wallet = await walletSelector.wallet();
        const accounts = await wallet.getAccounts();
        const args = {
          id: payload.id,
          claim_number: payload.claim_number
        };
        const withdrawTx = await storeActions.nearApi.createTxV2({
          signerId: accounts[0].accountId,
          receiverId: bounty_contract_id
        });

        withdrawTx.actions = [
          {
            type: "FunctionCall",
            params: {
              methodName: "withdraw",
              args: Buffer.from(JSON.stringify(args)),
              gas: "300000000000000",
              deposit: "1"
            }
          }
        ];

        requestTxs = [withdrawTx];

        postBeforeCreatingTx({
          accountId: accounts[0].accountId,
          receiverId: bounty_contract_id,
          methodName: "withdraw",
          args: Buffer.from(JSON.stringify(args)).toString("base64")
        });

        const result = await wallet.signAndSendTransactions({
          transactions: requestTxs,
          callbackUrl: `${location.href}?signMeta=withdrawSuccessfully`
        });
        if (result) {
          const txHashes = result.map((tx) => tx.transaction.hash).join(",");
          window.location.href = `${location.href}?signMeta=withdrawSuccessfully&transactionHashes=${txHashes}`;
        }
      } else {
        setError("'walletSelector' || 'bounty_contract_id' is not defined!");
      }
    } catch (error) {
      console.log(error);
    }
  }),
  txResultHandler: thunk((_, payload, helpers) => {
    const storeState = helpers.getStoreState();
    const storeActions = helpers.getStoreActions();
    const postAfterCreatingTx = helpers.injections.apiService.Api.postAfterCreatingTx;
    const {
      nearApi: { setTransactionHashes },
      newBountyForm: { setCreateBountyStatus }
    } = storeActions;
    const transactionHashes = payload.transaction.hash;
    setTransactionHashes(transactionHashes);
    setCreateBountyStatus("CheckReceipt");
    const generateInvoice = storeState.newBountyForm.generateInvoice;
    postAfterCreatingTx(transactionHashes, generateInvoice === true ? generateInvoice : undefined);
  }),
  claimResultHandler: thunk((_, payload, helpers) => {
    const storeActions = helpers.getStoreActions();
    const postAfterCreatingTx = helpers.injections.apiService.Api.postAfterCreatingTx;
    const {
      nearApi: { setTransactionHashes, setClaimStatus }
    } = storeActions;
    setTransactionHashes(payload.transaction.hash);
    setClaimStatus("CheckReceipt");
    postAfterCreatingTx(payload.transaction.hash);
  })
};

const nearApiThunksOn: NearApi.NearApiThunksOn = {
  onConnectWallet: thunkOn(
    (_, actions) => actions.nearApi.setWalletSelector,
    async (_, target, store) => {
      const walletSelector = target.payload;
      const storeState = store.getStoreState();
      const storeActions = store.getStoreActions();
      const setError = storeActions.error.setError;
      const { signStringMessage } = helpers;
      const { user } = storeState.auth;
      if (walletSelector) {
        const addUserWallet = store.injections.apiService.Api.addUserWallet;
        const isSignedIn = walletSelector.isSignedIn();
        if (user && isSignedIn) {
          const wallet = await walletSelector.wallet();
          const accounts = await wallet.getAccounts();
          const walletAccountId = accounts[0].accountId;
          const walletAccountIdExist = !!user?.wallets?.find(
            (wallet) => wallet.walletId === walletAccountId
          );
          if (!walletAccountIdExist) {
            const signature = await signStringMessage(walletSelector, walletAccountId);
            const response = await addUserWallet(
              walletAccountId,
              JSON.stringify(signature?.signature)
            );
            if (response.success && user) {
              storeActions.auth.setUser(response.success.data);
            } else if (response.failure) {
              setError(
                response.failure?.response?.data?.message ?? "WalletId not added due to error."
              );
            }
          }
        }
        storeActions.nearApi.setSignedIn(isSignedIn);
      }
    }
  ),
  onReceiptStatusChange: thunkOn(
    (_, actions) => actions.nearApi.setReceiptStatus,
    (_, target, helpers) => {
      const setCreateBountyStatus = helpers.getStoreActions().newBountyForm.setCreateBountyStatus;
      if (["SuccessValue", "SuccessReceiptId"].includes(target.payload)) {
        setCreateBountyStatus("Success");
      }
      if (target.payload === "Failure") setCreateBountyStatus("Failure");
    }
  ),
  onClaimReceiptStatusChange: thunkOn(
    (_, actions) => actions.nearApi.setClaimReceiptStatus,
    (_, target, helpers) => {
      const setClaimStatus = helpers.getStoreActions().nearApi.setClaimStatus;
      if (["SuccessValue", "SuccessReceiptId"].includes(target.payload)) {
        setClaimStatus("Success");
      }
      if (target.payload === "Failure") setClaimStatus("Failure");
    }
  ),
  onSetClaimStatus: thunkOn(
    (_, actions) => actions.nearApi.setClaimStatus,
    (_, target, helpers) => {
      const storeActions = helpers.getStoreActions();
      const getClaimReceipt = storeActions.nearApi.getClaimReceipt;
      const claimStatus = target.payload;
      if (claimStatus === "CheckReceipt") {
        intervalId = checkoutReceipt(getClaimReceipt);
      } else if (claimStatus === "Failure" || claimStatus === "Success") {
        clearInterval(intervalId);
      }
    }
  )
};

const nearApi = {
  ...nearApiModel,
  ...nearApiActions,
  ...nearApiV2Thunks,
  ...nearApiThunksOn
};

export default nearApi;
