import { useNavigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "../../../hooks";
import ListItem from "../../NavBar/ListItem";
import Separator from "../../general/Separator";
import Avatar from "../../general/Avatar";
import Api from "../../../services/Api";
import { ProfileMenuItem } from "../../general/Select";

export const goToKYCFractal = async (type: string, idOS?: boolean) => {
  const url = await Api.getFractalUrl(type, idOS);
  if (url?.uri) {
    window.open(url.uri, "_self");
  }
};

export default function WalletAccountV2() {
  const navigate = useNavigate();
  const nearApi = useStoreState((state) => state.nearApi);
  const auth = useStoreState((state) => state.auth);

  const { user } = auth;
  const { walletSelector } = nearApi;
  const actions = useStoreActions((actions) => actions);
  const {
    auth: { userSignOutAsync },
    nearApi: { dropWalletSelector }
  } = actions;

  const handleDisconnect = async () => {
    await dropWalletSelector();
    window.location.reload();
  };

  const userSignOut = async () => {
    await userSignOutAsync(null);
    navigate("/sign-in", { preventScrollReset: true });
  };

  const UserIcon = () => {
    return (
      <>
        {user && (user.isEmailVerified || user.isPhoneVerified) && (
          <div className="min-w-[39px] min-h-[36px] mt-0 mx-1">
            <Avatar className="w-[36px] h-[36px] mt-0 my-0" original={false} />
          </div>
        )}
      </>
    );
  };

  const goToProfile = () => navigate("/profile");
  const goToKYC = () => navigate("/kyc");

  return (
    <ProfileMenuItem
      {...{
        profileName: "",
        customStyles: {
          container: `
          w-full
          font-poppins
          text-md
          rounded-none
          relative          
        `,
          selectedItem: `
          rounded-none 
          px-[1rem]
          lg:py-0
          lg:min-h-[var(--header-height)]
          lg:justify-end
          lg:px-0
        `,
          optionsList: `
          lg:mt-[0.25rem]
          lg:bg-neutral-900
          lg:rounded-[15px]
          lg:border
          lg:border-sm
          lg:border-neutral-700
          lg:py-0
          lg:backdrop-blur-md
          lg:px-0
        `
        },
        iconAfter: <UserIcon />
      }}>
      <ListItem id={1} customStyle={"items-center justify-between"} needAction={!user?.metadata}>
        <button className="py-[0.65rem] text-left text-neutral-100 w-full" onClick={goToProfile}>
          Profile
        </button>
      </ListItem>
      {walletSelector?.isSignedIn() && (
        <>
          <Separator />
          <ListItem id={2} customStyle={"items-center justify-start"}>
            <button
              className="py-[0.65rem] text-left text-neutral-100 w-full"
              onClick={handleDisconnect}>
              Disconnect wallet
            </button>
          </ListItem>
        </>
      )}
      <Separator />
      <ListItem id={2} customStyle={"items-center justify-start"}>
        <button className="py-[0.65rem] text-left text-neutral-100 w-full" onClick={goToKYC}>
          KYC
        </button>
      </ListItem>
      <Separator />
      <Separator />
      <ListItem id={3} customStyle={"items-center justify-start"}>
        <button className="py-[0.65rem] text-left text-neutral-100 w-full" onClick={userSignOut}>
          Log out
        </button>
      </ListItem>
    </ProfileMenuItem>
  );
}
