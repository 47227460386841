import classNames from "classnames";
import IconButton from "../../general/Button/IconButton";
import { CloneIcon } from "../../general/SVGIcon";
import { Bounty } from "../../../types/Bounty";
import { formatting } from "../../../common/formatting";
import { useStoreActions, useStoreState } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import utils from "../../../services/utils";
import { isFuture } from "date-fns/esm";

const CloneAction = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  const walletSelector = useStoreState((state) => state.nearApi.walletSelector);
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);

  const actions = useStoreActions((actions) => actions);
  const navigate = useNavigate();

  const isHidden = () => {
    const even = (element: boolean) => element === false;
    return [walletSelector!.isSignedIn(), isAuthenticated].some(even);
  };

  const {
    resetState,
    newBountyForm: {
      setWithoutDeadline,
      setDeadlineTimeCommitment,
      setSelectedTokenAccount,
      setPayment
    },
    newBounty: {
      metadata: {
        setType,
        setTags,
        setExperience,
        setTitle,
        setDescription,
        setAcceptanceCriteria,
        setContactDetailsType,
        setContactDetailsContact
      },
      setDeadline,
      setMoreReviewers,
      clearReviewers,
      setClaimerApproval,
      setBountyFlow,
      setAllowDeadlineStretch
    }
  } = actions;

  const tokens = useStoreState((state) => state.app.tokens);

  const setMultitasking = useStoreActions((actions) => actions.newBounty.setMultitasking);
  const setStartConditions = useStoreActions((actions) => actions.newBounty.setStartConditions);
  const setGenerateInvoice = useStoreActions((actions) => actions.newBountyForm.setGenerateInvoice);
  const setAllowedCreateClaimToDate = useStoreActions(
    (actions) => actions.newBounty.setAllowedCreateClaimToDate
  );
  const setAllowedCreateClaimToPeriod = useStoreActions(
    (actions) => actions.newBounty.setAllowedCreateClaimToPeriod
  );
  const setContestOrHackathonTimeCommitment = useStoreActions(
    (actions) => actions.newBountyForm.setContestOrHackathonTimeCommitment
  );
  const setClaimersWhitelist = useStoreActions((actions) => actions.newBounty.setClaimersWhitelist);
  const setKYCVerification = useStoreActions((actions) => actions.newBountyForm.setKYCVerification);
  const setVerificationMethod = useStoreActions(
    (actions) => actions.newBounty.setVerificationMethod
  );

  function getDuration(period: string) {
    const duration = formatting.formatDurationToHuman(period);

    if (typeof duration === "object") {
      for (const key in duration) {
        if (duration[key as keyof typeof duration] !== 0) {
          return `${duration[key as keyof typeof duration]} (${key})`;
        }
      }
    }

    return duration.toString();
  }

  const isDisabled = () => {
    return false;
  };

  const cloneDescription = () => {
    setBountyFlow(bounty?.bounty_flow ?? "AdvancedFlow");
    setType(bounty.metadata.category);
    setTags(bounty.metadata.tags);
    setExperience(bounty.metadata.experience);
  };

  const cloneDetails = () => {
    setTitle(bounty.metadata.title);
    setDescription(bounty.metadata.description);
    bounty.metadata?.acceptance_criteria &&
      setAcceptanceCriteria(bounty.metadata.acceptance_criteria);
    bounty.metadata.contact_details &&
      setContactDetailsType(bounty.metadata.contact_details.contact_type);
    bounty.metadata.contact_details &&
      setContactDetailsContact(bounty.metadata.contact_details.contact);
  };

  const cloneDeadline = () => {
    if (bounty.deadline === "WithoutDeadline") {
      setWithoutDeadline(true);
      setDeadlineTimeCommitment("");
      setAllowDeadlineStretch(false);
    }
    if (bounty.deadline === "DueDate" && bounty.due_date) {
      setDeadlineTimeCommitment("");
      const dueDate = moment(bounty.due_date).valueOf();
      if (isFuture(dueDate)) {
        setWithoutDeadline(false);
        const timeStampInNano = formatting.formatDayToNanoSec(
          moment(bounty.due_date).utc(false).toDate()
        );
        setDeadline({ DueDate: { due_date: timeStampInNano.toString() } });
        if (bounty.bounty_flow && bounty.bounty_flow === "SimpleBounty") {
          setAllowDeadlineStretch(undefined);
        } else {
          setAllowDeadlineStretch(true);
        }
      } else {
        setWithoutDeadline(true);
        setAllowDeadlineStretch(false);
      }
    }

    if (bounty.deadline === "MaxDeadline" && bounty.max_deadline) {
      const deadline = utils.getBountyDeadline(bounty);
      setWithoutDeadline(false);

      const deadlineTimeCommitment = deadline.split(" ")[1].slice(1, -1);
      setDeadlineTimeCommitment(
        deadlineTimeCommitment[0].toUpperCase() + deadlineTimeCommitment.slice(1)
      );
      setDeadline({ MaxDeadline: { max_deadline: bounty.max_deadline } });
    }
  };

  const clonePayment = () => {
    const selectedToken = tokens.filter((token) => token.enabled && token.tokenId === bounty.token);

    if (selectedToken?.length > 0) {
      const token = selectedToken[0];
      setSelectedTokenAccount({ name: token.tokenId, value: token.symbol });

      const calcAmount = +bounty.amount / Math.pow(10, token.decimals);
      setPayment(calcAmount);
      setGenerateInvoice(bounty.use_invoice);
    }
  };

  const cloneAdvancedSettings = () => {
    if (!bounty.reviewers) {
      clearReviewers();
    } else if (bounty.reviewers === "MoreReviewers") {
      clearReviewers();
      bounty?.more_reviewers?.forEach((r) => setMoreReviewers(r));
    }

    if (bounty.multitasking) {
      if (bounty?.multitasking?.multitasking_type === "OneForAll") {
        const { number_of_slots, amount_per_slot, min_slots_to_start } = bounty.multitasking;

        if (number_of_slots && amount_per_slot) {
          setMultitasking({
            OneForAll: {
              number_of_slots,
              amount_per_slot,
              min_slots_to_start
            }
          });
        }
      }
      if (
        bounty?.multitasking?.multitasking_type === "ContestOrHackathon" &&
        bounty?.multitasking?.successful_claims_for_result !== undefined
      ) {
        setMultitasking({
          ContestOrHackathon: {
            successful_claims_for_result: bounty?.multitasking?.successful_claims_for_result
          }
        });

        if (bounty?.multitasking?.allowed_create_claim_to) {
          if (bounty?.multitasking?.allowed_create_claim_to?.value_type === "Date") {
            const timeStampInNano = formatting.formatDayToNanoSec(
              moment(bounty?.multitasking?.allowed_create_claim_to?.value)
                .utc(false)
                .toDate()
            );
            setAllowedCreateClaimToDate({ Date: { date: timeStampInNano.toString() } });
          }
          if (bounty?.multitasking?.allowed_create_claim_to?.value_type === "Period") {
            const duration = getDuration(bounty?.multitasking?.allowed_create_claim_to?.value);
            const deadlineTimeCommitment = duration.split(" ")[1].slice(1, -1);
            setAllowedCreateClaimToPeriod({
              Period: { period: bounty?.multitasking?.allowed_create_claim_to?.value }
            });
            setContestOrHackathonTimeCommitment(
              deadlineTimeCommitment[0].toUpperCase() + deadlineTimeCommitment.slice(1)
            );
          }
        }

        if (bounty?.multitasking?.start_conditions) {
          if (bounty?.multitasking?.start_conditions?.condition_type === "ManuallyStart") {
            setStartConditions("ManuallyStart");
          } else {
            setStartConditions({
              MinAmountToStart: { amount: bounty?.multitasking?.start_conditions?.amount }
            });
          }
        }
      }

      if (bounty?.multitasking?.multitasking_type === "DifferentTasks") {
        // @ts-ignore
        const { subtasks } = bounty.multitasking;
        if (subtasks) {
          setMultitasking({
            DifferentTasks: {
              subtasks
            }
          });
        }
      }
    } else {
      setMultitasking(undefined);
    }

    if (
      bounty.claimant_approval === "MultipleClaims" ||
      bounty.claimant_approval === "WithoutApproval"
    ) {
      setClaimerApproval(bounty.claimant_approval);
    }
    if (bounty.claimant_approval === "ApprovalByWhitelist" && bounty.claimants_whitelist) {
      setClaimerApproval({
        ApprovalByWhitelist: { claimants_whitelist: [] }
      });
      setClaimersWhitelist(bounty.claimants_whitelist.join(", "));
    }
    if (bounty.claimant_approval === "WhitelistWithApprovals" && bounty.claimants_whitelist) {
      setClaimerApproval({
        WhitelistWithApprovals: { claimants_whitelist: [] }
      });
      setClaimersWhitelist(bounty.claimants_whitelist.join(", "));
    }

    if (bounty?.kyc_config?.kyc_required) {
      setKYCVerification(true);
      setVerificationMethod(bounty.kyc_config.kyc_verification_method);
    } else {
      setKYCVerification(false);
    }
  };

  const handleButtonClick = () => {
    resetState();

    cloneDescription();
    cloneDetails();
    cloneDeadline();
    clonePayment();
    cloneAdvancedSettings();

    actions.newBountyForm.setCurrentStep({
      name: "description",
      title: "Description"
    });

    actions.newBountyForm.setCreateBountyStatus("New");

    navigate("/bounties/new");
  };

  return !isHidden() ? (
    <IconButton
      {...{
        disabled: isDisabled(),
        icon: (
          <CloneIcon
            className={classNames({
              "hover:stroke-neutral-400": isDisabled(),
              "hover:stroke-primary-900": !isDisabled()
            })}
          />
        ),
        onClick: () => handleButtonClick()
      }}
    />
  ) : null;
};

export default CloneAction;
