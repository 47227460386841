import classNames from "classnames";
import { Bounty } from "../../../../../types/Bounty";
import CurrencyAmount from "../../general/CurrencyAmount";
import TokenAmount from "../../general/TokenAmount";
import { getSlotPercents } from "./helpers";

const SlotAmount = ({
  bounty,
  slot,
  className
}: {
  bounty: Bounty;
  slot?: number;
  className?: string;
}) => {
  return (
    <div className="flex gap-2">
      {slot !== undefined && (
        <div className={classNames("text-neutral-50", className)}>
          {bounty.multitasking?.subtasks && getSlotPercents(bounty, slot)}% of{" "}
        </div>
      )}
      <div>
        {bounty?.token && (
          <TokenAmount
            token={bounty.token}
            amount={bounty.amount}
            className={classNames("text-neutral-50", className)}
          />
        )}
        {bounty?.currency && (
          <CurrencyAmount
            currency={bounty.currency}
            amount={bounty.amount}
            className={classNames("text-neutral-50", className)}
          />
        )}
      </div>
    </div>
  );
};

export default SlotAmount;
