import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import defaultUserAvatarImage from "../../../assets/images/default_avatar.jpg";
import useUserAvatar from "../../../hooks/useUserAvatar";
import { base64ImageUrl, imageDataToBase64Image, ImageData } from "../../../utils";

const Avatar = (props: {
  picture?: ImageData | string;
  className?: string;
  original?: boolean;
  publicProfile?: boolean;
}) => {
  const { className } = props;

  const { avatar } = useUserAvatar(props);

  const formatToBase64ImageURL = (avatar: ImageData | string) => {
    if (typeof avatar !== "string") {
      return base64ImageUrl(imageDataToBase64Image(avatar), avatar?.contentType);
    }
    return avatar;
  };

  return (
    <div className="w-fit">
      <img
        {...{
          src: avatar ? formatToBase64ImageURL(avatar) : defaultUserAvatarImage,
          className: twMerge(
            classNames("w-[100px] aspect-square rounded-full object-cover mt-[-50%]", className)
          )
        }}
        alt=""
      />
    </div>
  );
};

export default Avatar;
