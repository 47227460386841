import { useState } from "react";
import { useStoreActions } from "../../../hooks";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import utils from "../../../services/utils";
import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { DeclineHeroForm, FinalExecutionOutcomeForm } from "../Dialogues";
import ActionButton from "./general/ActionButton";
import { FinalExecutionOutcome } from "@near-wallet-selector/core";

const DeclineAction = (props: { claim: Claim; bounty: Bounty; refetchActions?: () => void }) => {
  const { claim, bounty, refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<FinalExecutionOutcome | null>(null);
  const { walletAccountId } = useWalletAccountId();
  const setDeclineClaimant = useStoreActions((actions) => actions.nearApi.setDeclineClaimant);
  const [loading, setLoading] = useState(false);

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, claim, walletAccountId);
    return a.checkAccess([!a.isBountyOwner(), !a.isValidatorDAO(), !a.isReviewer()]);
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const result = await setDeclineClaimant([
      {
        id: bounty.id,
        receiver_id: claim.owner,
        claim_number: claim.claim_number
      }
    ]);
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result as FinalExecutionOutcome);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
    }
  };

  return (
    <>
      <ActionButton disabled={isDisabled()} handleClick={handleButtonClick} text={"Decline"} />
      <DeclineHeroForm
        show={showForm}
        loading={loading}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  );
};

export default DeclineAction;
