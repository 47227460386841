import classNames from "classnames";

const HandIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("stroke-neutral-200", className)}
    >
      <path
        d="M7.05 4.575C7.05 4.36817 7.00926 4.16336 6.93011 3.97227C6.85096 3.78119 6.73495 3.60756 6.58869 3.46131C6.44244 3.31505 6.26881 3.19904 6.07773 3.11989C5.88664 3.04074 5.68183 3 5.475 3C5.26817 3 5.06336 3.04074 4.87227 3.11989C4.68119 3.19904 4.50756 3.31505 4.36131 3.46131C4.21505 3.60756 4.09904 3.78119 4.01989 3.97227C3.94074 4.16336 3.9 4.36817 3.9 4.575V7.575M7.05 4.575V3.075C7.05 2.65728 7.21594 2.25668 7.51131 1.96131C7.80668 1.66594 8.20728 1.5 8.625 1.5C9.04272 1.5 9.44332 1.66594 9.73869 1.96131C10.0341 2.25668 10.2 2.65728 10.2 3.075V4.575M7.05 4.575L7.125 10.5M3.9 7.575C3.9 7.15728 3.73406 6.75668 3.43869 6.46131C3.14332 6.16594 2.74272 6 2.325 6C1.90728 6 1.50668 6.16594 1.21131 6.46131C0.915937 6.75668 0.75 7.15728 0.75 7.575V15.75C0.75 17.5402 1.46116 19.2571 2.72703 20.523C3.9929 21.7888 5.70979 22.5 7.5 22.5H9.518C10.9103 22.4998 12.2456 21.9466 13.23 20.962L14.962 19.23C15.9466 18.2456 16.4998 16.9103 16.5 15.518L16.503 13.494C16.504 13.317 16.5752 13.1476 16.701 13.023C16.8473 12.8767 16.9633 12.703 17.0425 12.5119C17.1217 12.3208 17.1624 12.1159 17.1624 11.909C17.1624 11.7021 17.1217 11.4972 17.0425 11.3061C16.9633 11.115 16.8473 10.9413 16.701 10.795C16.5547 10.6487 16.381 10.5327 16.1899 10.4535C15.9988 10.3743 15.7939 10.3336 15.587 10.3336C15.3801 10.3336 15.1752 10.3743 14.9841 10.4535C14.793 10.5327 14.6193 10.6487 14.473 10.795C13.7595 11.5076 13.3568 12.4736 13.353 13.482M3.9 7.575V12M10.2 4.575V11.25M10.2 4.575C10.2 4.15728 10.3659 3.75668 10.6613 3.46131C10.9567 3.16594 11.3573 3 11.775 3C12.1927 3 12.5933 3.16594 12.8887 3.46131C13.1841 3.75668 13.35 4.15728 13.35 4.575V15M13.35 15C12.7593 14.9997 12.1744 15.116 11.6287 15.3421C11.083 15.5683 10.5873 15.8999 10.17 16.318M13.35 15H13.352"
        stroke="stroke-inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HandIcon;
