import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Bounty } from "../../../../../../types/Bounty";
import { FormControl, FormRow } from "../../../../../general/Form/FormElements";
import { Input } from "../../../../../general/Input";
import { Area } from "../../../../../Layout";
import { EditBountyPriceFormData } from "..";

const CurrencyPriceForm = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  const methods = useFormContext<EditBountyPriceFormData>();
  const {
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitted }
  } = methods;

  const [amount, setAmount] = useState(getValues("amount") ?? 0);

  const handleAmountChange = (value: string) => {
    setValue("amount", value, { shouldValidate: true, shouldDirty: true });
    setAmount(value);
  };

  return (
    <Area>
      <FormRow>
        <div className="flex text-neutral-200 flex-col flex-wrap gap-4">
          <div>
            Bounty price (paid in{" "}
            <span className="text-primary-900 underline">{bounty.currency}</span>)
          </div>
        </div>
      </FormRow>
      <FormRow className="w-full">
        <FormControl
          name="amount"
          control={control}
          defaultValue={amount}
          rules={{
            required: "Required field",
            validate: {
              minAmount: (value: number) => value > 0 || "is not equal to 0"
            }
          }}
          render={({ field: { name, value, onChange } }) => {
            return (
              <Input
                {...{
                  customStyles: {
                    input: "px-[20px] py-[15px] text-right"
                  },
                  name: name,
                  value: value,
                  validation: {
                    valid: !errors.amount,
                    message: errors.amount?.message,
                    started: isSubmitted
                  },
                  onChange: (value: string) => {
                    handleAmountChange(value);
                    onChange(value);
                  }
                }}
                {...{ name, value }}
              />
            );
          }}
        />
      </FormRow>
    </Area>
  );
};

export default CurrencyPriceForm;
