import { useState, useEffect } from "react";
import { Action } from "../../Actions";
import Api from "../../../../services/Api";
import { Claim } from "../../../../types/Claim";
import { Bounty } from "../../../../types/Bounty";
import useMessages from "../../../../hooks/useMessages";
import InfiniteScroll from "react-infinite-scroll-component";
import { Message } from "../../../../types";
import { useQueryClient } from "@tanstack/react-query";
import useWalletAccountId from "../../../../hooks/useWalletAccountId";
import ChatMessages from "./ChatMessages";

export default function Chat({ bounty, bountyClaim }: { bounty: Bounty; bountyClaim: Claim }) {
  const queryClient = useQueryClient();
  const [showForm, setShowForm] = useState(false);
  const { walletAccountId } = useWalletAccountId();
  const { data, error, fetchNextPage, hasNextPage, status, refetch } = useMessages({
    bountyId: bounty.id,
    claimerAccountId: bountyClaim.owner,
    claimNumber: bountyClaim.claim_number,
    limit: 9999
  });

  const [messages, setMessages] = useState(data?.pages[0]?.messages || []);

  const handleRefetch = () => {
    refetch();
  };

  useEffect(() => {
    return () => {
      queryClient.clear(); // Clear all query data
    };
  }, [queryClient]);

  useEffect(() => {
    if (data && Array.isArray(data?.pages)) {
      const messagesArrays = data.pages.map((page) => page.messages);

      const allMessages = messagesArrays.reduce((acc, messagesArray) => {
        return acc.concat(messagesArray);
      }, []);

      if (allMessages?.length >= messages.length) {
        setMessages(allMessages);
      }
    }
  }, [data, messages.length]);

  useEffect(() => {
    const scroll_to_bottom = document.getElementById("messages");
    if (scroll_to_bottom) {
      scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
    }
  });

  if (status === "loading")
    return (
      <div className="flex flex-1 items-center justify-center">
        <span className="dot-pulse" />
      </div>
    );

  if (status === "error") return <h4>Ups!, {`${error}`}</h4>;

  const handleCloseForm = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async (message: string) => {
    setShowForm(false);
    const msg = await Api.sendMessage({
      message: message,
      bountyId: bounty.id,
      accountId: walletAccountId,
      receiverAccountId: bountyClaim.owner,
      claimNumber: bountyClaim.claim_number
    });
    setMessages([...messages, msg]);
  };

  return (
    <div className="w-full flex flex-col flex-1">
      <div id="messages" className="flex flex-col mt-5 overflow-y-auto scrollbar relative px-4">
        <InfiniteScroll
          dataLength={data?.pages ? data.pages.length : 0}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          scrollThreshold={1}
          loader={
            <div className="flex items-center justify-center">
              <h6>Loading!!!</h6>
            </div>
          }>
          <>
            {messages.map((item: Message, index: number) => (
              <ChatMessages
                key={`bounty-${bounty.id}-item-${index}`}
                item={item}
                bounty={bounty}
                claim={bountyClaim}
              />
            ))}
          </>
        </InfiniteScroll>
      </div>

      <div className="py-2.5 flex justify-end bg-neutral-820 mt-auto  px-4">
        <Action.ReplyAction
          showForm={showForm}
          setShowForm={handleCloseForm}
          bounty={bounty}
          claim={bountyClaim}
          walletAccountId={walletAccountId}
          onClose={handleCloseForm}
          onSubmit={handleSubmitForm}
          refetch={handleRefetch}
        />
      </div>
    </div>
  );
}
