import { useEffect, useState } from "react";
import { DarkButton, LightButton } from "../../../general/Button";
import { Area } from "../../../Layout";
import { SubmitHandler, useFormContext } from "react-hook-form";
import CustomDetails from "./CustomDetails";
import CheckBox from "../../../general/CheckBox";
import { Input } from "../../../general/Input";
import FormControl from "../../../general/Form/FormElements/FormControl";
import { FormRow } from "../../../general/Form/FormElements";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { ContactDetailsModel } from "../../../../types/BountyCreate";
import { CloseIcon } from "../../../general/SVGIcon";

export type DetailsFormData = {
  title: string;
  description: string;
  acceptance_criteria: string;
  attachments: string[];
  contact_details: ContactDetailsModel;
  github_link: string;
  gitcoin_link: string;
  account_dao?: string;
  account?: string;
  files: string;
};

const Details = () => {
  const methods = useFormContext<DetailsFormData>();
  const { handleSubmit, control, register, unregister, formState, setValue, setError } = methods;
  const allowSubmitAsDao = useStoreState(
    (state) => state.app.config?.allowed_submit_as_dao ?? false
  );
  const { isValid, errors, isSubmitted } = formState;

  const [account, setAccount] = useState("");

  const actions = useStoreActions((actions) => actions);

  const {
    nearApi: { getDaoPolicy },
    newBountyForm: { next, prev, setSubmitAsNearAccount, setDaoAccount, setProposalBond }
  } = actions;

  const { submitAsNearAccount, account_dao } = useStoreState((state) => state.newBountyForm);

  const handleBackClick = () => {
    prev();
  };

  const handleNextClick = () => {
    if (isValid) {
      next();
    }
  };

  const handleCheckAsNearAccount = () => {
    setSubmitAsNearAccount(true);
    if (submitAsNearAccount) {
      unregister("account_dao");
      unregister("account");
    }
  };

  const handleCheckAsDAO = () => {
    setSubmitAsNearAccount(false);
    if (!submitAsNearAccount) {
      register("account_dao");
      register("account");
    }
  };

  const checkDAOAccount = async () => {
    try {
      const daoPolicy = await getDaoPolicy(account);
      if (daoPolicy) {
        if ("proposal_bond" in daoPolicy) {
          return daoPolicy.proposal_bond;
        } else {
          setError("account", {
            message: "account DAO does not exist",
            type: "onCheck"
          });
        }
      }
    } catch (error) {
      setError("account", {
        message: "account DAO does not exist",
        type: "onCheck"
      });
    }
  };

  const handleAccountDAOAdd = async (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    const proposalBond = await checkDAOAccount();
    if (!errors.account) {
      setDaoAccount(account);
      setProposalBond(proposalBond);
      setValue("account", "");
      setAccount("");
    }
  };

  const handleRemoveAccountDao = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    setDaoAccount(null);
  };

  useEffect(() => {
    if (!allowSubmitAsDao) {
      handleCheckAsNearAccount();
    }
  }, [allowSubmitAsDao]);

  const onSubmit: SubmitHandler<DetailsFormData> = () => handleNextClick();

  return (
    <div id="details-form" className="font-poppins" onSubmit={handleSubmit(onSubmit)}>
      {allowSubmitAsDao && (
        <div className="my-4">
          <Area>
            <div className="py-4">
              <div className="px-6 text-neutral-400">
                <FormRow className="flex gap-6">
                  <CheckBox
                    {...{
                      active: submitAsNearAccount,
                      onClick: () => handleCheckAsNearAccount(),
                      text: "Submit as NEAR account"
                    }}
                  />
                  <CheckBox
                    {...{
                      active: !submitAsNearAccount,
                      onClick: () => handleCheckAsDAO(),
                      text: "Submit as DAO"
                    }}
                  />
                </FormRow>
              </div>
              {account_dao && (
                <FormRow>
                  <div className="px-4">
                    <div className="flex p-3.5 items-center justify-between text-sm rounded-[10px] bg-neutral-800">
                      <div>{account_dao}</div>

                      <button
                        onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                          handleRemoveAccountDao(e)
                        }>
                        <CloseIcon className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </FormRow>
              )}
              {!submitAsNearAccount && (
                <FormRow>
                  <div className="px-4 flex items-end">
                    <FormControl
                      name="account"
                      defaultValue={account}
                      control={control}
                      rules={{
                        required: {
                          value: !account_dao,
                          message: "Required field"
                        }
                      }}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <Input
                            {...{
                              customStyles: {
                                input: "px-[20px] py-[13px]"
                              },
                              placeholder: "Account DAO",
                              name: name,
                              value: value,
                              validation: {
                                valid: !errors.account,
                                message: errors.account?.message,
                                started: isSubmitted
                              },
                              onChange: (value: string) => {
                                onChange(value);
                                setValue("account", value, {
                                  shouldValidate: true
                                });
                                setAccount(value);
                              }
                            }}
                            {...{ name, value, ref }}
                          />
                        );
                      }}
                    />
                    <div className="px-4 pt-4">
                      <DarkButton
                        {...{
                          customStyles: {
                            button: "border border-neutral-700 text-white min-w-[96px]"
                          },
                          disabled: !account,
                          text: "Add",
                          onClick: (e: React.MouseEvent<Element, MouseEvent>) =>
                            handleAccountDAOAdd(e)
                        }}
                      />
                    </div>
                  </div>
                </FormRow>
              )}
            </div>
          </Area>
        </div>
      )}
      <CustomDetails />
      <div className="my-4">
        <div className="flex gap-4 justify-center">
          <DarkButton
            {...{
              text: "Back",
              onClick: () => handleBackClick()
            }}
          />
          <LightButton
            {...{
              text: "Next",
              onClick: () => handleSubmit(onSubmit)(),
              type: "submit"
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
