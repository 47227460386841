import { NextActionProps } from "../../../hooks/useNextActions";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import { Bounty, NextActionModel } from "../../../types/Bounty";
import SubtaskItem from "../../general/SubtaskItem";
import { ClockIcon } from "../../general/SVGIcon";
import ClaimAction from "../Actions/ClaimAction";
import { formatPercents, taskClaimedByUser } from "./helpers";

const Subtasks = (props: { bounty: Bounty; nextActions: NextActionProps }) => {
  const { bounty, nextActions } = props;
  const { walletAccountId } = useWalletAccountId();

  const getAvailableUserActions = () => {
    return (nextActions as Partial<NextActionModel>[] | null)?.find(
      (item) => item.accountId === walletAccountId
    )?.nextActions;
  };

  return (
    <div>
      <h2>Subtasks</h2>
      <div>
        {bounty?.multitasking?.subtasks &&
          bounty.multitasking.subtasks.map((subtask, index) => (
            <SubtaskItem
              key={`subtask-${index}`}
              description={subtask.subtask_description}
              percents={formatPercents(subtask.subtask_percent)}>
              {bounty.multitasking?.runtime_env &&
              !taskClaimedByUser(bounty.multitasking.runtime_env.participants_list, index) ? (
                <>
                  {nextActions &&
                    Object.entries(getAvailableUserActions() ?? {}).map(([key, _]) => (
                      <>
                        {key === "bountyClaim" && (
                          <ClaimAction
                            bounty={bounty}
                            text={"Apply for task"}
                            slot={index}
                            customStyles={{ button: "min-w-[156px]" }}
                          />
                        )}
                      </>
                    ))}
                </>
              ) : (
                <ClockIcon />
              )}
            </SubtaskItem>
          ))}
      </div>
    </div>
  );
};

export default Subtasks;
