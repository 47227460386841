import { MouseEventHandler, MouseEvent, useId } from "react";
import { twMerge } from "tailwind-merge";
import IconButton from "../Button/IconButton";
import { InfoIcon } from "../SVGIcon";
import classNames from "classnames";

type CheckBoxStyles = {
  container?: string;
  text?: string;
  checkBox?: string;
  checkMark?: string;
  activeCheckMark?: string;
};

interface CheckBoxProps {
  customStyles?: CheckBoxStyles;
  text?: string;
  textInfo?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  active?: boolean;
  disabled?: boolean;
  name?: string;
}

const buildStyles = (customStyles: CheckBoxStyles | undefined, disabled: boolean) => ({
  container: twMerge(classNames("flex items-center gap-2", customStyles?.container)),
  checkBox: twMerge(
    classNames(
      "border-2 flex max-w-[1rem] max-h-[1rem] min-w-[1rem] min-h-[1rem] aspect-ratio-1 rounded-[0.25rem] p-[1.5px] hover:cursor-pointer",
      {
        "border-neutral-600": disabled,
        "border-neutral-200": !disabled
      },
      customStyles?.checkBox
    )
  ),
  checkMark: classNames("bg-transparent"),
  activeCheckMark: classNames("w-full bg-primary-800 rounded-[0.1rem]"),
  text: classNames(
    "flex items-center text-sm text-left font-poppins",
    { "text-neutral-600": disabled, "text-neutral-200": !disabled },
    customStyles?.text
  )
});

const CheckBox = function (props: CheckBoxProps) {
  const id = useId();
  const { customStyles, text, onClick, active, disabled, name, textInfo } = props;

  const styles = buildStyles(customStyles, !!disabled);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    !disabled && onClick && onClick(event);
  };

  return (
    <div className={styles.container}>
      <div id={name} aria-checked={active} className={styles.checkBox} onClick={handleClick}>
        <div className={active && !disabled ? styles.activeCheckMark : styles.checkMark} />
      </div>
      <label className={styles.text} htmlFor={id}>
        <div
          id={id}
          className="hover:cursor-pointer"
          dangerouslySetInnerHTML={{ __html: text ?? "" }}
          onClick={handleClick}
        />
        {textInfo && (
          <div className="ml-2">
            <div className="group relative flex justify-center w-fit">
              <IconButton
                {...{
                  icon: <InfoIcon className="w-4 h-4" />
                }}
              />
              <span className="absolute bottom-10 w-max max-w-[206px] scale-0 rounded-3xl transition-all bg-neutral-800 border border-neutral-700 py-2 px-4 text-xs text-neutral-400 group-hover:scale-100">
                {textInfo}
              </span>
            </div>
          </div>
        )}
      </label>
    </div>
  );
};

export default CheckBox;
