import { useState } from "react";
import BountyList from "../../components/Bounty/BountyList";
import ScrollToTopButton from "../../components/general/Button/ScrollToTopButton";
import useBountiesSearchParams from "../../hooks/useBountiesSearchParams";
import SearchParamsFilterBounties from "../../components/Bounty/FilterBounties/SearchParamsFilterBounties";

export default function BrowseBounty() {
  const [page, setPage] = useState(1);
  const { data, error, status, searchParams, setQuery } = useBountiesSearchParams({
    page
  });

  if (status === "error") return <h4>Ups!, {`${error}`}</h4>;

  return (
    <div className="font-poppins">
      <div className="flex items-center justify-between">
        <h1 className=" text-3xl">Bounties</h1>
      </div>
      <SearchParamsFilterBounties query={searchParams} setQuery={setQuery} />
      {status === "loading" ? (
        <div className="flex items-center justify-center">
          <span className="dot-pulse" />
        </div>
      ) : (
        <>
          <div className="relative top-3 text-sm text-neutral-400">{data?.item_count} bounties</div>
          <BountyList data={data} page={page} setPage={setPage} />
        </>
      )}
      <ScrollToTopButton />
    </div>
  );
}
