import { Bounty } from "../../../../../types/Bounty";
import { DarkButton } from "../../../../general/Button";
import { ValidationLayout } from "../../../../Layout";
import Modal from "../../../../general/Modal";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Input } from "../../../../general/Input";
import { FormControl, FormRow } from "../../../../general/Form/FormElements";

// Register plugins if required
// MdEditor.use(YOUR_PLUGINS_HERE);

// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);

type DescriptionFormData = {
  title: string;
  description: string;
};

const EditDescriptionForm = (props: {
  show: boolean;
  loading: boolean;
  handleClose: () => void;
  handleSubmit: (value: { title: string; description: string }) => void;
  bounty: Bounty;
}) => {
  const { show, handleClose, handleSubmit, bounty, loading } = props;
  const methods = useForm<DescriptionFormData>({
    mode: "onChange"
  });
  const { control, setValue, formState } = methods;
  const { errors, isSubmitted, isValid } = formState;
  const [title, setTitle] = useState(bounty.metadata.title);
  const [description, setDescription] = useState(bounty.metadata.description);

  const handleTitleChange = (value: string) => {
    setTitle(value);
  };

  const handleDescriptionChange = (text: string) => {
    setDescription(text);
  };

  const handleSubmitClick = () => {
    if (isValid) {
      handleSubmit({ title, description });
    }
  };

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                disabled: loading,
                onClick: () => methods.handleSubmit(handleSubmitClick)(),
                text: !loading ? "Update" : "Submitting..."
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white">Edit description</div>
        <div className="py-2.5">
          <FormRow>
            <FormControl
              name="title"
              control={control}
              rules={{ required: "Required field" }}
              defaultValue={title}
              render={({ field: { name, value, onChange } }) => {
                return (
                  <Input
                    {...{
                      customStyles: {
                        input: "px-[20px] py-[15px]"
                      },
                      placeholder: "Name of your bounty",
                      label: { text: "Title" },
                      value: value,
                      name: name,
                      validation: {
                        valid: !errors.title,
                        message: errors.title?.message,
                        started: isSubmitted
                      },
                      onChange: (value: string) => {
                        onChange(value);
                        handleTitleChange(value);
                      }
                    }}
                    {...{ name, value }}
                  />
                );
              }}
            />
          </FormRow>
          <FormRow>
            <ValidationLayout
              {...{
                validate: () => {
                  return {
                    valid: !errors.description,
                    started: isSubmitted,
                    message: errors.description?.message
                  };
                }
              }}>
              <div data-color-mode="dark">
                <FormControl
                  name="description"
                  control={control}
                  defaultValue={description}
                  rules={{ required: "Required field" }}
                  render={({ field: { name, value, onChange } }) => {
                    return (
                      <MdEditor
                        className="resize-y overflow-auto"
                        markdownClass="scrollbar"
                        placeholder="Description"
                        style={{ height: "264px" }}
                        defaultValue={description}
                        view={{ menu: true, md: true, html: false }}
                        canView={{
                          menu: true,
                          md: true,
                          html: false,
                          fullScreen: false,
                          hideMenu: true,
                          both: false
                        }}
                        renderHTML={(text) => mdParser.render(text)}
                        onChange={({ text }) => {
                          onChange(text);
                          handleDescriptionChange(text);
                          setValue("description", text, {
                            shouldValidate: true
                          });
                        }}
                        {...{ name, value }}
                      />
                    );
                  }}></FormControl>
              </div>
            </ValidationLayout>
          </FormRow>
        </div>
      </div>
    </Modal>
  );
};

export default EditDescriptionForm;
