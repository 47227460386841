import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ContestOrHackathon, MinAmountToStart } from "../../../../../../../types/BountyCreate";
import { useStoreActions, useStoreState } from "../../../../../../../hooks";
import { FormRow } from "../../../../../../general/Form/FormElements";
import { ValidationLayout } from "../../../../../../Layout";
import { Input } from "../../../../../../general/Input";

type FormData = {
  multitasking: MinAmountToStart;
};

const MinAmountToStartForm = () => {
  const methods = useFormContext<FormData>();
  const { register, formState, setValue } = methods;
  const { errors, isSubmitted } = formState;

  const start_conditions = useStoreState(
    (state) =>
      (state.newBounty.multitasking as ContestOrHackathon)?.ContestOrHackathon.start_conditions
  );

  const setMinAmountToStart = useStoreActions((actions) => actions.newBounty.setMinAmountToStart);

  const defValue = useMemo(() => {
    return (start_conditions as MinAmountToStart)?.MinAmountToStart.amount ?? "";
  }, [start_conditions]);

  const handleChange = (value: string) => {
    const amount = isNaN(parseInt(value)) ? 0 : parseInt(value);
    setValue("multitasking.MinAmountToStart.amount", amount, { shouldValidate: true });
    setMinAmountToStart({ MinAmountToStart: { amount } });
  };

  return (
    <FormRow>
      <ValidationLayout
        {...{
          validate: () => {
            return {
              valid: !errors.multitasking?.MinAmountToStart?.amount,
              message: errors.multitasking?.MinAmountToStart?.amount?.message,
              started: isSubmitted
            };
          }
        }}>
        <input
          type="hidden"
          defaultValue={defValue}
          {...register("multitasking.MinAmountToStart.amount", {
            required: "Required field",
            validate: {
              minAmount: (value) => value > 1 || "Must be greater than 1"
            }
          })}
        />
        <Input
          {...{
            placeholder: "MinAmountToStart",
            value: defValue.toString(),
            onChange: (value: string) => handleChange(value)
          }}
        />
      </ValidationLayout>
    </FormRow>
  );
};

export default MinAmountToStartForm;
