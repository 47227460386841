import { Layout } from "../../components/Layout";
import FractalLogo from "../../assets/images/fractal-logo.png";
import SumSubLogo from "../../assets/images/sumSub-logo.png";
import IdOSLogo from "../../assets/images/idos-logo.svg";
import { OpenIcon, RightArrowIcon } from "../../components/general/SVGIcon";
import { useNavigate } from "react-router";
import { goToKYCFractal } from "../../components/Bounty/WalletAccountV2";

function Kyc() {
  const navigate = useNavigate();
  // const goToKYC = () => navigate("/user-verification");

  const goToKYCIdOS = () => navigate("/kyc-idos");

  return (
    <Layout>
      <div className="container text-white flex justify-center mt-8 mx-auto">
        <div className="max-w-[576px]">
          <h2 className="text-neutral-50 mb-4">Verification</h2>
          <div className="bg-[#1f1f1f] rounded-[1.25rem] p-5 border-sm border-neutral-700">
            <p className="text-sm text-neutral-300 mb-4">Choose method</p>
            <div>
              <div className="mb-4 flex items-center gap-5 p-5 bg-neutral-800 border-sm border-neutral-700 rounded-[1.25rem]">
                <div className="flex gap-4">
                  <img className="w-[85px] h-[85px]" src={FractalLogo} />
                  <div className="text-neutral-50">
                    <p>
                      Fractal <span className="text-primary-900">KYC</span>
                    </p>
                    <p className="text-xs mt-2">
                      A leading digital identity platform, Fractal streamlines user verification
                      with top-tier security. Choose Fractal for a seamless KYC.
                    </p>
                  </div>
                </div>
                <button onClick={() => goToKYCFractal("kyc")}>
                  <OpenIcon />
                </button>
              </div>
              <div className="mb-4 flex items-center gap-5 p-5 bg-neutral-800 border-sm border-neutral-700 rounded-[1.25rem]">
                <div className="flex gap-4">
                  <img className="w-[85px] h-[85px]" src={FractalLogo} />
                  <div className="text-neutral-50">
                    <p>
                      Fractal <span className="text-primary-900">KYB</span>
                    </p>
                    <p className="text-xs mt-2">
                      A leading digital identity platform, Fractal streamlines user verification
                      with top-tier security. Choose Fractal for a seamless KYB.
                    </p>
                  </div>
                </div>
                <button onClick={() => goToKYCFractal("kyb")}>
                  <OpenIcon />
                </button>
              </div>
              {/* <div className="mb-4 flex items-center gap-5 p-5 bg-neutral-800 border-sm border-neutral-700 rounded-[1.25rem]">
                <div className="flex gap-4">
                  <div className="relative min-w-[85px] w-[85px] h-[85px]">
                    <img className="absolute w-[85px] h-[85px] z-1" src={FractalLogo} />
                    <img className="absolute w-[50%] h-auto z-0 top-0" src={IdOSLogo} />
                  </div>
                  <div className="text-neutral-50">
                    <p>
                      Fractal + <span className="text-primary-900">idOS</span>
                    </p>
                    <p className="text-xs mt-2">
                      A leading digital identity platform, Fractal + idOS streamlines user
                      verification with top-tier security. Choose Fractal + IdOS for a seamless KYC.
                    </p>
                  </div>
                </div>
                <button onClick={() => goToKYCFractal("kyc", true)}>
                  <OpenIcon />
                </button>
              </div> */}
              {/* <div className="mb-4 flex items-center gap-5 p-5 bg-neutral-800 border-sm border-neutral-700 rounded-[1.25rem]">
                <div className="flex gap-4">
                  <img className="w-[85px] h-[85px]" src={IdOSLogo} />
                  <div className="text-neutral-50">
                    <p>IdOS</p>
                    <p className="text-xs mt-2">
                      A leading digital identity platform, Fractal streamlines user verification
                      with top-tier security. Choose Fractal for a seamless KYC.
                    </p>
                  </div>
                </div>
                <button onClick={goToKYCIdOS}>
                  <RightArrowIcon />
                </button>
              </div> */}
              {/*<div className='flex items-center gap-5 p-5 bg-neutral-800 border-sm border-neutral-700 rounded-[1.25rem]'>*/}
              {/*    <div className='flex gap-4'>*/}
              {/*        <img className='w-[85px] h-[85px]' src={SumSubLogo} />*/}
              {/*        <div className='text-neutral-50'>*/}
              {/*            <p>SumSub</p>*/}
              {/*            <p className='text-xs mt-2'>Known for its thorough identity verification and compliance, Sumsub's KYC solution offers users on Heroes marketplace a streamlined and robust process to confirm their identities.</p>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*    <button onClick={goToKYC}><RightArrowIcon /></button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Kyc;
