import { Path } from "history";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useStoreActions, useStoreState } from "../hooks";

type PrivateRouteProps = {
  redirectPath: string | Partial<Path>;
  children?: React.ReactElement | null;
};

const PrivateRoute = (props: PrivateRouteProps) => {
  const { children, redirectPath } = props;
  const location = useLocation();
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);
  const isVerified = useStoreState((state) => state.auth.isVerified);
  const setCallbackUrl = useStoreActions((actions) => actions.auth.setCallbackUrl);

  if (!isAuthenticated) {
    setCallbackUrl(location.pathname);
    return <Navigate to={redirectPath} replace />;
  }

  if (location.pathname !== "/verify-code" && !isVerified) {
    return <Navigate to="/verify-code" replace />;
  }

  return children ? children : <Outlet />;
};

export default PrivateRoute;
