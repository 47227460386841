import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../../../../../hooks";
import { OneForAll } from "../../../../../../../types/BountyCreate";
import utils from "../../../../../../../services/utils";
import { FormRow } from "../../../../../../general/Form/FormElements";
import { ValidationLayout } from "../../../../../../Layout";
import { Input } from "../../../../../../general/Input";

type FormData = {
  multitasking: {
    amount_per_slot: number | null;
  };
};

const CurrencyAmountPerSlot = () => {
  const methods = useFormContext<FormData>();
  const { register, formState, setValue } = methods;
  const { errors, isSubmitted } = formState;
  const number_of_slots = useStoreState(
    (state) => (state.newBounty.multitasking as OneForAll).OneForAll.number_of_slots
  );
  const currency = useStoreState(
    (state) => state.newBounty.postpaid?.PaymentOutsideContract.currency
  );
  const currencies = useStoreState((state) => state.app.currencies);

  const amount_per_slot = useStoreState(
    (state) => (state.newBounty.multitasking as OneForAll)?.OneForAll.amount_per_slot
  );

  const currencyDecimals = useMemo(() => {
    return currencies.find((item) => item.symbol === currency)?.decimals || 0;
  }, [currencies, currency]);

  const parsedAmountPerSlot = useMemo(() => {
    return amount_per_slot ? utils.formatTokenAmount(amount_per_slot, currencyDecimals, 0) : 0;
  }, [amount_per_slot, currencyDecimals]);

  console.log(currencyDecimals);

  const setAmountPerSlot = useStoreActions((actions) => actions.newBounty.setAmountPerSlot);
  const setOffContractPaymentPayment = useStoreActions(
    (actions) => actions.newBountyForm.setOffContractPaymentPayment
  );

  const formatAmountToDecimal = (amount: string) => {
    if (currency && currencyDecimals) {
      const formatted = utils.getParsedCurrencyAmount(amount, currencyDecimals);
      return formatted.toString();
    }
    return amount;
  };

  const checkPaymentIsRequired = useMemo(() => {
    return Boolean(!amount_per_slot);
  }, [amount_per_slot]);

  const handleChange = (value: string) => {
    if (!isNaN(parseInt(value))) {
      const formattedAmount = formatAmountToDecimal(value);
      setAmountPerSlot(parseInt(formattedAmount));
      setValue("multitasking.amount_per_slot", parseInt(value), { shouldValidate: true });
      setOffContractPaymentPayment((parseInt(value) * number_of_slots).toString());
    } else {
      setAmountPerSlot(0);
      setOffContractPaymentPayment("0");
      setValue("multitasking.amount_per_slot", null, { shouldValidate: true });
    }
  };

  return (
    <div>
      <div className="flex flex-col flex-wrap gap-4">
        <div>
          Slot price (paid in <span className="text-primary-900 underline">{currency}</span>)
        </div>
      </div>
      <FormRow>
        <ValidationLayout
          {...{
            validate: () => {
              return {
                valid: !errors.multitasking?.amount_per_slot,
                message: errors.multitasking?.amount_per_slot?.message,
                started: isSubmitted
              };
            }
          }}>
          <input
            defaultValue={parsedAmountPerSlot}
            type="hidden"
            {...register("multitasking.amount_per_slot", {
              validate: {
                required: () => !checkPaymentIsRequired || "Required field"
              }
            })}
          />
          <Input
            {...{
              placeholder: "amount per slot",
              value: parsedAmountPerSlot.toString(),
              onChange: (value: string) => handleChange(value)
            }}
          />
        </ValidationLayout>
      </FormRow>
    </div>
  );
};

export default CurrencyAmountPerSlot;
