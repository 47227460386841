import { useStoreState } from "../../../hooks";
import {
  AddIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  GlobeIcon,
  ListIcon
} from "../../general/SVGIcon";
import Menu from "./Menu";
import MenuItem from "./Menu/MenuItem";
import { useMemo, useState } from "react";
import { Area } from "../../Layout";
import { AppMessages } from "../../../common/app-messages";
import { useLocation, useNavigate } from "react-router-dom";
import { LightButton } from "../../general/Button";
import useNextActions, { NextActionExpected } from "../../../hooks/useNextActions";
import { useWalletSelector } from "../../../providers/WalletSelectorContext";
import useBountiesSearchParams from "../../../hooks/useBountiesSearchParams";
import { useAllowCreateBounty } from "../../../providers/AllowCreateBountyContext";
import ActionItem from "./Menu/ActionItem";
import { Action } from "./Actions";

type SidebarProps = {
  visible?: boolean;
};

const Sidebar = (props: SidebarProps) => {
  const { visible = true } = props;
  const { getUrlParams, getUrlParamsOrDefault } = useBountiesSearchParams({});
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useStoreState((state) => state.auth);
  const { data: nextActions } = useNextActions({});
  const { nextActionExpected } = (nextActions as NextActionExpected) || {};
  const { selector } = useWalletSelector();
  const { isAllowedCreateBounty } = useAllowCreateBounty();
  const isSignedIn = selector?.isSignedIn();
  const [showExportTxns, setShowExportTxns] = useState(false);

  const showAlert = useMemo(() => {
    return nextActionExpected
      ? nextActionExpected?.forBounties || nextActionExpected?.forClaims
      : false;
  }, [nextActions]);

  const handleCreateBounty = () => {
    navigate("/bounties/new");
  };

  return (
    <>
      {visible && (
        <>
          <div className="border border-neutral-700 rounded-xl">
            <Menu>
              <MenuItem
                linkProps={{
                  to: `/bounties?${getUrlParamsOrDefault()}`
                }}
                text="Browse Bounties"
                Icon={<GlobeIcon />}
              />
              {isAuthenticated && isSignedIn && (
                <MenuItem
                  linkProps={{ to: `/bounties/my?${getUrlParams()}` }}
                  text="Your Bounties"
                  Icon={<CheckCircleIcon className="!stroke-primary-900" />}
                  alert={showAlert}
                />
              )}
              {isAuthenticated && isSignedIn && (
                <MenuItem
                  linkProps={{ to: "/bounties/whitelists" }}
                  text="Account Whitelists"
                  Icon={<AddIcon className="h-[18px] stroke-primary-900" />}
                />
              )}
              {isAuthenticated && isSignedIn && (
                <MenuItem
                  linkProps={{ to: "/bounties/all-invoices" }}
                  text="My Invoices"
                  Icon={<DocumentTextIcon className="h-[18px] stroke-primary-900" />}
                />
              )}
              {isAuthenticated && isSignedIn && (
                <ActionItem
                  text="Export Transactions"
                  Icon={<ListIcon className="h-[18px] stroke-primary-900" />}
                  buttonProps={{ onClick: () => setShowExportTxns(!showExportTxns) }}
                />
              )}
              <Action.ExportTxns
                show={showExportTxns}
                handleClose={() => setShowExportTxns(!showExportTxns)}
                disabled={false}
              />
            </Menu>
          </div>
          {isAuthenticated && isSignedIn && (
            <div className="flex flex-col items-center justify-center mt-4">
              <LightButton
                {...{
                  text: "Create bounty",
                  disabled: !isAllowedCreateBounty,
                  onClick: () => handleCreateBounty()
                }}
              />
              {!isAllowedCreateBounty && (
                <div className="py-2.5">
                  <Area>
                    <div className="flex items-center px-4">
                      <AppMessages.CreateBountyIsNotAllowedMessage />
                    </div>
                  </Area>
                </div>
              )}
            </div>
          )}
          {isAuthenticated && !user?.metadata && location.pathname !== "/profile" && (
            <div className="mt-3.5">
              <Area>
                <div className="flex items-center px-4">
                  <AppMessages.ProfileNotCreatedMessage />
                </div>
              </Area>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Sidebar;
