import { Layout } from "../../components/Layout";
import { useNavigate } from "react-router";
import {useStoreActions, useStoreState} from "../../hooks";
import { useEffect, useState } from "react";
import { Api } from "../../services/profileApi";

function DisableNotifications() {
  const { user } = useStoreState((state) => state.auth);
  const setError = useStoreActions((actions) => actions.error.setError);
  const [result, setResult] = useState<null | boolean>(null);

  async function updateNotificationsStatus(value: boolean) {
      const res = await Api.updateNotificationsStatus(value);

      if(res.error) {
          return setError(res?.message ?? 'An error occurred while trying to unsubscribe from notifications, please try again');
      }

      setResult(res?.success?.data);
  }

  useEffect(() => {
      if(user) {
          if(user?.isNotificationsDisabled === false) {
              updateNotificationsStatus(true);
          }
          if(user?.isNotificationsDisabled === true) {
              setResult(true);
          }
      }
  }, [user]);

  return (
    <Layout>
      <div className="container text-white flex justify-center mt-8 mx-auto">
        {result === null ? 'loading...' : result ? "Notifications disabled" : "Notifications enabled"}
      </div>
    </Layout>
  );
}

export default DisableNotifications;
