import { useState } from "react";
import { ApproveBountyForm } from "../Dialogues";
import { useStoreActions } from "../../../hooks";
import { Claim } from "../../../types/Claim";
import { Bounty } from "../../../types/Bounty";
import ActionButton from "./general/ActionButton";

export default function ApproveAction(props: { claim: Claim; bounty: Bounty; text?: string }) {
  const { claim, bounty, text = "Approve" } = props;
  const [showForm, setShowForm] = useState(false);
  const setBountyApprove = useStoreActions((actions) => actions.nearApi.setBountyApprove);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    await setBountyApprove([
      {
        id: bounty.id,
        token: bounty.token,
        receiver_id: claim.owner,
        claim_number: claim.claim_number
      }
    ]);
  };

  return (
    <>
      <ActionButton disabled={false} text={text} handleClick={handleButtonClick} />
      <ApproveBountyForm
        bounty={bounty}
        show={showForm}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
    </>
  );
}
