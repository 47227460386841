import { SubmitHandler, useFormContext } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../../hooks";
import * as _ from "lodash";
import { DarkButton, LightButton } from "../../../general/Button";
import { Area } from "../../../Layout";
import CurrencyConverter from "./components/CurrencyConverter";
import Invoice from "./components/Invoice";
import ValidatorDao from "./components/ValidatorDao";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { FormRow } from "../../../general/Form/FormElements";
import OffContractForm from "./components/DefferedPayment/OffContractForm";
import { AppMessages } from "../../../../common/app-messages";
import { CompanyMetadata, IndividualMetadata } from "../../../../types/Auth";
import { SelectArrow } from "../../../general/SVGIcon";
import PostpaidSubscriberWhitelistedContext from "../general/context/PostpaidSubscriberWhitelistedContext";

export type PaymentFormData = {
  defaultPayment?: {
    selectedTokenAccount?: { name: string; value: string };
    payment: string;
  };
  offContractPayment?: { currency: string; payment: string };
  metadata?: CompanyMetadata | IndividualMetadata;
};

const Payment = () => {
  const methods = useFormContext<PaymentFormData>();
  const {
    handleSubmit,
    formState: { errors }
  } = methods;
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const { isPostpaidSubscriberWhitelisted } = useContext(PostpaidSubscriberWhitelistedContext);
  const actions = useStoreActions((actions) => actions);
  const bounty_flow = useStoreState((state) => state.newBounty.bounty_flow);
  const user = useStoreState((state) => state.auth.user);
  const postpaid = useStoreState((state) => state.newBounty.postpaid);
  const { generateInvoice } = useStoreState((state) => state.newBountyForm);

  const {
    newBounty: { setPostpaid },
    newBountyForm: { prev, next },
    auth: { userUpdateProfile }
  } = actions;

  const handleBackClick = () => {
    prev();
  };

  useEffect(() => {
    if (isPostpaidSubscriberWhitelisted && !postpaid) {
      setPostpaid({ PaymentOutsideContract: { currency: "" } });
    }

    if (!isPostpaidSubscriberWhitelisted && postpaid) {
      setPostpaid(undefined);
    }
  }, [isPostpaidSubscriberWhitelisted, postpaid]);

  const handleNextClick = (formData: PaymentFormData) => {
    if (generateInvoice && formData?.metadata) {
      const profileType = user?.metadata?.profileType;
      const metadata = _.merge(user?.metadata, formData.metadata);
      const newUser = {
        metadata: {
          ...{
            profileType
          },
          ...metadata
        }
      };
      userUpdateProfile({
        newUser
      });
    }
    if (typeof errors === "object" && Object.keys(errors).length === 0) {
      next();
    }
  };

  const onSubmit: SubmitHandler<PaymentFormData> = (data) => handleNextClick(data);

  return (
    <div id="payment-form" className="font-poppins" onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <Area
          {...{
            customStyles: {
              borderContainer: "border border-neutral-700",
              contentContainer: "pb-6"
            }
          }}>
          <FormRow className="p-6">
            {!isPostpaidSubscriberWhitelisted ? (
              <CurrencyConverter />
            ) : (
              <>
                <h2 className="text-white">Bounty price</h2>
                <OffContractForm />
                <div className="my-2.5">
                  <Area
                    {...{
                      customStyles: {
                        borderContainer: "border border-neutral-700"
                      }
                    }}>
                    <div className="p-4">
                      <AppMessages.OffcontractDisclaimer className="mt-0" />
                    </div>
                  </Area>
                </div>
              </>
            )}
          </FormRow>
          <FormRow>
            <div className="flex justify-end px-6">
              <button
                className="flex items-center gap-1 text-[12px] text-primary-900 outline-none"
                onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}>
                Advanced settings
                <SelectArrow
                  className={classNames("w-3 h-3", {
                    "rotate-180": showAdvancedSettings
                  })}
                  {...{ color: "hsl(var(--color-primary-900))" }}
                />
              </button>
            </div>
          </FormRow>
          {showAdvancedSettings && (
            <div className="px-6">
              <Invoice />
            </div>
          )}
        </Area>
      </FormRow>

      <div className="my-6">
        <div className="flex gap-4 justify-center">
          <DarkButton
            {...{
              text: "Back",
              onClick: () => handleBackClick()
            }}
          />
          <LightButton
            {...{
              text: "Next",
              onClick: () => handleSubmit(onSubmit)(),
              type: "submit"
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Payment;
