import classNames from "classnames";
import React from "react";
import { AppMessages } from "../../../common/app-messages";
import { useStoreState } from "../../../hooks";
import NewMultiSelect from "../../general/Select/NewMultiSelect";
import { Option } from "../../general/Select/Select";
import { WarningIcon } from "../../general/SVGIcon";
import { Area } from "../../Layout";
import WalletSelector from "../../WalletSelector";

const SearchParamsFilterHunters = (props: { query: any; setQuery: (...args: any[]) => void }) => {
  const { query, setQuery } = props;

  const nearApi = useStoreState((state) => state.nearApi);
  const { isSignedIn } = nearApi;
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);
  const config = useStoreState((state) => state.app.config);
  const multiSelectStyles = {
    container: "w-20 md:w-32 border-[1px] border-neutral-700 rounded-[10px] p-2 md:px-4 md:py-3",
    selectedItem: "bg-neutral-900 p-0 radius-[10px]",
    optionsList: {
      container: "bg-neutral-900 border border-sm min-w-fit border-neutral-700"
    }
  };

  const multiselectChange = (props: { [key: string]: string[] }) => {
    Object.keys(props).forEach((key) => {
      setQuery({ [key]: props[key] });
    });
  };

  const handleRemoveFilter = (name: string, value?: string) => {
    setQuery((prev: any) => {
      if (Array.isArray(query[name])) {
        prev[name] = prev[name]
          .split(",")
          .filter((item: string) => item !== value)
          .join(",");
      } else {
        if (prev[name] === "true") {
          prev[name] = undefined;
        }
      }
      return prev;
    });
  };

  const sortOptions = [
    { name: "New Profiles", value: "New Profiles" },
    { name: "Completed Bounties", value: "Completed Bounties" }
  ];

  type OtherOption = Option & {
    active: boolean | undefined;
  };

  const otherOptions: OtherOption[] = [
    {
      name: "kyc",
      value: "KYC",
      active: query?.kyc === "true"
    },
    {
      name: "completedProfiles",
      value: "Completed Profiles",
      active: query?.completedProfiles === "true"
    }
  ];

  const selectedOtherOptions = otherOptions.filter((opt) => opt.active === true);

  const handleOtherChange = (other: string[]) => {
    const c: { [key: string]: string | boolean | undefined } = {};
    for (const opt of otherOptions) {
      if (!other.includes(opt.name)) {
        c[opt.name] = undefined;
      } else {
        c[opt.name] = "true";
      }
    }
    setQuery(c);
  };

  return (
    <>
      <div className="my-6">
        {isAuthenticated && (
          <div className="mt-6">
            {!isSignedIn && (
              <Area
                {...{
                  customStyles: {
                    borderContainer: classNames("border border-neutral-700", {
                      "border-md border-secondary-800": !isSignedIn
                    }),
                    contentContainer: classNames("", {
                      "bg-secondary-900/20": !isSignedIn
                    })
                  }
                }}>
                <div className="p-6 flex flex-col flex-wrap">
                  <div className="mb-4">
                    <div className="flex items-center gap-4 text-secondary-800">
                      <div className="w-[32px] h-[32px]">
                        <WarningIcon className="w-8 h-8 fill-secondary-800" />
                      </div>
                      <AppMessages.ConnectWalletMessage className="text-md" />
                    </div>
                  </div>
                  <>
                    <WalletSelector />
                  </>
                </div>
              </Area>
            )}
          </div>
        )}
      </div>
      <div>
        <div className="flex justify-between items-center flex-wrap gap-4">
          <div className="flex flex-wrap gap-4">
            <NewMultiSelect
              {...{
                placeholder: "Skills",
                selected: { name: "", value: "" },
                onSelect: () => {},
                defaultOption: { name: "", value: "" },
                defaultOptionsList: config?.tags
                  ? config.tags.map((tag) => ({
                      name: tag,
                      value: tag
                    }))
                  : [],
                multiSelected:
                  query?.skills?.map((item: string) => {
                    return { name: item, value: item };
                  }) || [],
                onMultiSelect: (selected: Option[]) => {
                  multiselectChange({
                    skills: selected.map((item) => item.name)
                  });
                },
                showCheckbox: true,
                customStyles: { ...multiSelectStyles }
              }}
            />
            <NewMultiSelect
              {...{
                placeholder: "Sort",
                selected: { name: "", value: "" },
                onSelect: (selected) => {
                  if (Array.isArray(query.sort) && query.sort.includes(selected.name)) {
                    return setQuery({ sort: null });
                  }
                  setQuery({ sort: selected.name });
                },
                defaultOption: { name: "", value: "" },
                defaultOptionsList: [
                  { name: "New Profiles", value: "New Profiles" },
                  { name: "Completed Bounties", value: "Completed Bounties" }
                ],
                multiSelected:
                  query?.sort?.map((item: string) => {
                    return { name: item, value: item };
                  }) || [],
                onMultiSelect: (selected: Option[]) => {
                  // multiselectChange({ sort: selected.map((item) => item.name) });
                },
                showCheckbox: true,
                customStyles: { ...multiSelectStyles }
              }}
            />
            <NewMultiSelect
              {...{
                placeholder: "Other",
                selected: { name: "", value: "" },
                onSelect: () => {},
                defaultOption: { name: "", value: "" },
                defaultOptionsList: otherOptions,
                multiSelected: selectedOtherOptions,
                onMultiSelect: (selected: Option[]) => {
                  handleOtherChange(selected.map((item) => item.name));
                },
                showCheckbox: true,
                customStyles: { ...multiSelectStyles }
              }}
            />
          </div>
        </div>
        {query && (
          <div className="flex flex-wrap gap-2 mt-4">
            {Object.keys(query)
              .filter((key) => key !== "status")
              .map((key) =>
                Array.isArray(query[key]) ? (
                  query[key]?.map((el: string) => (
                    <div
                      key={el}
                      className="flex gap-4 bg-[#2E2E2EBF] py-2 px-5 rounded-[30px] text-neutral-200">
                      <span className="flex items-center text-xs">{el}</span>
                      <button onClick={() => handleRemoveFilter(key, el)}>x</button>
                    </div>
                  ))
                ) : (
                  <React.Fragment key={key}>
                    {query[key] === "true" && (
                      <div
                        key={key}
                        className="flex gap-4 bg-[#2E2E2EBF] py-2 px-5 rounded-[30px] text-neutral-200">
                        <span className="flex items-center text-xs">{key}</span>
                        <button onClick={() => handleRemoveFilter(key)}>x</button>
                      </div>
                    )}
                  </React.Fragment>
                )
              )}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchParamsFilterHunters;
