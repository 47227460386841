import CompanyForm from "./CompanyForm";
import IndividualForm from "./IndividualForm";
import CommonForm from "./CommonForm";
const CreateProfileForm = {
  CommonForm,
  IndividualForm,
  CompanyForm,
};

export default CreateProfileForm;
