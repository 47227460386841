import classNames from "classnames";
import { ReactNode } from "react";
import { Area } from "../../../../Layout";
import "./table.css";

const Table = (props: { className?: string; tableSummary?: ReactNode; children: ReactNode[] }) => {
  const { className, tableSummary, children } = props;

  return (
    <Area
      {...{
        customStyles: {
          contentContainer: "bg-neutral-900 p-6 border border-neutral-800 w-full"
        }
      }}>
      <table
        className={classNames(
          "border-collapse  scrollbar table-auto overflow-auto w-full text-sm",
          className
        )}>
        {children}
      </table>
      {tableSummary}
    </Area>
  );
};

export default Table;
