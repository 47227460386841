import { GradientBorder } from "../Layout";
import ListItem from "./ListItem";
import Link from "../general/Link";
import Separator from "../general/Separator";
import { ConnectWalletButtonV2 } from "../general/Button";
import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../hooks";
import { buildStyles as buildListItemStyles } from "../NavBar/ListItem";
import WalletAccountV2 from "../Bounty/WalletAccountV2";
import SearchForm from "../Form/SearchForm";
import Avatar from "../general/Avatar";
import { AddIcon, CloseIcon, GlobeIcon, HandIcon, UserIcon, WalletIcon } from "../general/SVGIcon";
import ConnectedWalletIcon from "../../assets/images/connected-wallet.png";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { useWalletSelector } from "../../providers/WalletSelectorContext";
import useBountiesSearchParams from "../../hooks/useBountiesSearchParams";
import useNextActions, { NextActionExpected } from "../../hooks/useNextActions";
import { useAllowCreateBounty } from "../../providers/AllowCreateBountyContext";
import useHuntersSearchParams from "../../hooks/useHuntersSearchParams";

type TProps = {
  expanded: boolean;
};

function NavBar(props: TProps) {
  const { expanded } = props;
  const { getUrlParams, getUrlParamsOrDefault } = useBountiesSearchParams({});
  const { getHuntersUrlParams, getHuntersUrlParamsOrDefault } = useHuntersSearchParams({});
  const use_verification_code = useStoreState((state) => state.app.config?.use_verification_code);
  const contractId = useStoreState((state) => state.app.config?.bounty_contract_id);
  const auth = useStoreState((state) => state.auth);
  const actions = useStoreActions((actions) => actions);
  const {
    auth: { userSignOutAsync },
    nearApi: { dropWalletSelector }
  } = actions;
  const handleDisconnect = async () => {
    await dropWalletSelector();
    window.location.reload();
  };
  const { data: nextActions } = useNextActions({});
  const { nextActionExpected } = (nextActions as NextActionExpected) || {};
  const { modal, selector } = useWalletSelector();
  const { isAllowedCreateBounty } = useAllowCreateBounty();
  const isSignedIn = selector?.isSignedIn();

  const { isAuthenticated, refferalLinkCode, user, isVerified } = auth;
  const navigate = useNavigate();

  const handleSignIn = (e: React.MouseEvent) => {
    e.preventDefault();
    if (selector && contractId) {
      modal.show();
    }
  };

  const goToSignUp = () => {
    navigate("/sign-up");
  };

  const goToSignIn = () => {
    navigate("/sign-in");
  };
  const goToKYC = () => navigate("/kyc");

  const userSignOut = async () => {
    await userSignOutAsync(null);
    navigate("/sign-in", { preventScrollReset: true });
  };

  const navStyle = `
    w-full
    rounded-header
    z-40
    translate-y-[-1000px]
    lg:translate-y-0
  `;

  const expandedNavStyle = twMerge(`
    ${navStyle}
    left-0 absolute
    backdrop-blur-md
    bg-neutral-800 bg-opacity-60
    translate-y-header-top
  `);

  const styles = {
    contentContainer: twMerge(`
      flex flex-col justify-between
      w-full h-full
      lg:ml-auto
      lg:flex-row lg:items-center
      ${expanded ? "pt-[var(--header-height)] lg:pt-0" : ""}
    `),
    linkStyle: `      
      hover:text-primary-900
      header-link--hover
    `,
    buttonStyle: `
      flex items-center gap-4 
      py-3 px-4 capitalize
      border border-transparent rounded-xl 
      hover:border-neutral-500 
    `,
    iconContainer: `
      flex justify-center items-center 
      w-6 h-6
    `
  };

  return (
    <div className="w-0 lg:w-full lg:h-full">
      <nav className={expanded ? expandedNavStyle : navStyle + " h-full hidden lg:block"}>
        <GradientBorder {...{ customStyles: " before:bg-header-border", hidden: !expanded }}>
          <ul className={styles.contentContainer}>
            <ListItem id={1} customStyle="lg:space-x-6 hidden lg:flex">
              <Link
                {...{
                  text: "bounties",
                  link: `/bounties?${getUrlParamsOrDefault()}`,
                  target: "_self",
                  style: styles.linkStyle
                }}
              />
              <Link
                {...{
                  text: "hunters",
                  link: `/hunters?${getHuntersUrlParamsOrDefault()}`,
                  target: "_self",
                  style: styles.linkStyle
                }}
              />
              <div className="hidden lg:block">
                <SearchForm />
              </div>
            </ListItem>
            <ListItem id={1} customStyle=" space-y-0 lg:space-x-6 lg:hidden">
              <div>
                <Link
                  {...{
                    text: "Browse bounties",
                    link: `/bounties?${getUrlParamsOrDefault()}`,
                    target: "_self",
                    style: styles.linkStyle,
                    icon: <GlobeIcon />
                  }}
                />
                <Link
                  {...{
                    text: "Browse hunters",
                    link: `/hunters?${getHuntersUrlParamsOrDefault()}`,
                    target: "_self",
                    style: styles.linkStyle,
                    icon: <GlobeIcon />
                  }}
                />
                {isAuthenticated && isVerified && isSignedIn && (
                  <>
                    <Link
                      {...{
                        text: "Your bounties",
                        link: `/bounties/my?${getUrlParams()}`,
                        target: "_self",
                        style: styles.linkStyle,
                        needAction:
                          nextActionExpected?.forBounties || nextActionExpected?.forClaims,
                        icon: <UserIcon className="!stroke-primary-900" />
                      }}
                    />
                    {isAuthenticated && isAllowedCreateBounty && (
                      <Link
                        {...{
                          text: "Create Bounty",
                          link: "/bounties/new",
                          target: "_self",
                          style: styles.linkStyle,
                          icon: <GlobeIcon />
                        }}
                      />
                    )}
                    <Link
                      {...{
                        text: "Account Whitelists",
                        link: "/bounties/whitelists",
                        target: "_self",
                        style: styles.linkStyle,
                        icon: <AddIcon className="h-[18px] stroke-primary-900" />
                      }}
                    />
                  </>
                )}
                {isAuthenticated && isSignedIn && (
                  <>
                    <button
                      className={`${styles.linkStyle} ${styles.buttonStyle}`}
                      onClick={goToKYC}>
                      <span className={styles.iconContainer}>
                        <HandIcon className="h-[18px] w-[18px] stroke-primary-900" />
                      </span>
                      <span>KYC</span>
                    </button>
                    <button
                      className={`${styles.linkStyle} ${styles.buttonStyle}`}
                      onClick={handleDisconnect}>
                      <span className={styles.iconContainer}>
                        <WalletIcon className="h-[18px] w-[18px]" bgColor={"#ffd012"} />
                      </span>
                      <span>Disconnect Wallet</span>
                    </button>
                  </>
                )}
                {isAuthenticated && (
                  <button
                    className={`${styles.linkStyle} ${styles.buttonStyle}`}
                    onClick={userSignOut}>
                    <span className={styles.iconContainer}>
                      <CloseIcon className="h-[18px] w-[18px] fill-primary-900" />
                    </span>
                    <span>Log Out</span>
                  </button>
                )}
              </div>
            </ListItem>
            {isAuthenticated ? (
              <ListItem
                {...{
                  customStyle: "!p-0 space-y-0 lg:!px-0 lg:gap-4 lg:items-center",
                  id: 2
                }}>
                {location?.pathname === "/" && isAuthenticated && isAllowedCreateBounty && (
                  <div className="w-full">
                    <Link
                      {...{
                        text: "Create Bounty",
                        link: "/bounties/new",
                        target: "_self",
                        style: "text-sm text-primary-900"
                      }}
                    />
                  </div>
                )}
                <div
                  {...{
                    className: buildListItemStyles("lg:hidden !mt-0 !p-0 w-full lg:w-auto")
                      .contentContainer
                  }}>
                  <Separator />
                </div>
                <div className="flex flex-1 !items-start justify-center !space-y-0 w-full h-full px-8 py-4 lg:p-0">
                  <div className={""}>
                    <div className="flex gap-4 lg:hidden">
                      <div className="w-[3.125rem] h-[3.125rem]">
                        {user && (user?.isEmailVerified || user?.isPhoneVerified) && (
                          <Avatar className="w-full h-full m-0" original={false} />
                        )}
                      </div>
                      <div className="capitalize">
                        {user && <p className="text-white">{user.name}</p>}
                        <a href="/profile" className="text-sm text-neutral-400">
                          View profile
                        </a>
                      </div>
                    </div>
                    <div className="hidden lg:block">
                      <WalletAccountV2 />
                    </div>
                  </div>
                </div>
                <div
                  {...{
                    className: buildListItemStyles("lg:hidden !p-0 mt-0 w-full lg:w-auto")
                      .contentContainer
                  }}>
                  <Separator />
                </div>
                <div
                  {...{
                    className: buildListItemStyles(
                      classNames("!p-0 w-full lg:w-auto lg:!px-0 lg:!mx-0 space-y-0", {
                        "lg:hidden": isSignedIn
                      })
                    ).contentContainer
                  }}>
                  {isSignedIn ? (
                    <>
                      <div className="w-full px-8 py-4 flex justify-start items-center gap-20 lg:hidden">
                        <p className="text-success capitalize">Wallet connected</p>
                        <img className="py-[1px]" src={ConnectedWalletIcon} alt="" />
                      </div>
                    </>
                  ) : (
                    <div className="lg:pr-2 w-full px-4">
                      <ConnectWalletButtonV2
                        {...{
                          text: "Connect Wallet",
                          onClick: (e: React.MouseEvent) => handleSignIn(e)
                        }}
                      />
                    </div>
                  )}
                </div>
              </ListItem>
            ) : (
              <ListItem
                {...{
                  customStyle: "lg:items-center",
                  id: 2
                }}>
                <div className="lg:space-x-6">
                  <button
                    className="uppercase text-primary-900 header-link--hover mr-4"
                    onClick={goToSignIn}>
                    sign in
                  </button>
                  {!use_verification_code ? (
                    <button
                      className="uppercase text-primary-900 header-link--hover"
                      onClick={goToSignUp}>
                      sign up
                    </button>
                  ) : (
                    <>
                      {refferalLinkCode ? (
                        <button
                          className="uppercase text-primary-900 header-link--hover"
                          onClick={goToSignUp}>
                          sign up
                        </button>
                      ) : null}
                    </>
                  )}
                </div>
              </ListItem>
            )}
          </ul>
        </GradientBorder>
      </nav>
    </div>
  );
}

export default NavBar;
