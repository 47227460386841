import CompanyProfile from "../../components/Section/Profile/CompanyProfile";
import { Area, Layout } from "../../components/Layout";
import Sidebar from "../../components/Bounty/Sidebar";
import ErrorMessage from "../../components/Bounty/general/ErrorMessage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { useStoreState } from "../../hooks";
import IndividualProfile from "../../components/Section/Profile/IndividualProfile";
import { InfoIcon } from "../../components/general/SVGIcon";
import { AppMessages } from "../../common/app-messages";
import { useEffect, useContext } from "react";
import { DEV_MODE } from "../../constants";
import mixpanel from "mixpanel-browser";
import { MixpanelContext } from "../App";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { parse, stringify } from "query-string";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const Profile = () => {
  const context = useContext(MixpanelContext);
  mixpanel.init(context.token, {
    debug: false
  });

  const { user } = useStoreState((state) => state.auth);

  useEffect(() => {
    if (!DEV_MODE) {
      mixpanel.track("Hero Profile Self", {
        source: window.location.hostname
      });
    }
  }, []);

  return (
    <Layout>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify
        }}>
        <QueryClientProvider client={queryClient}>
          <div className="container mx-auto text-white w-full ">
            <div className="grid grid-cols-4">
              <div className="col-span-1 lg:block sm:hidden max-w-[256px]">
                <Sidebar />
              </div>
              <div className="lg:col-span-3 sm:col-span-4 lg:px-12 sm:px-2">
                {user?.metadata?.profileType === "Company" && <CompanyProfile user={user} />}
                {user?.metadata?.profileType === "Individual" && <IndividualProfile user={user} />}
                {!user?.metadata && (
                  <div className="mt-3.5">
                    <Area>
                      <div className="flex items-center">
                        <div className="px-6 text-neutral-400">
                          <InfoIcon />
                        </div>
                        <AppMessages.ProfileNotCreatedMessage />
                      </div>
                    </Area>
                  </div>
                )}
              </div>
            </div>
          </div>
        </QueryClientProvider>
      </QueryParamProvider>
    </Layout>
  );
};

export default Profile;
