import ApproveAction from "./ApproveAction";
import DeclineAction from "./DeclineAction";
import DoneAction from "./DoneAction";
import GiveUpAction from "./GiveUpAction";
import AcceptAction from "./AcceptAction";
import RejectAction from "./RejectAction";
import FinalizeAction from "./FinalizeAction";
import ReplyAction from "./ReplyAction";
import ClaimAction from "./ClaimAction";
import CancelBountyAction from "./CancelBountyAction";
import SendMoneyAction from "./SendMoneyAction";
import MarkAsPaidAction from "./MarkAsPaidAction";
import ConfirmPaymentAction from "./ConfirmPaymentAction";
import StartCompetitionAction from "./StartCompetitionAction";
import SeveralApproveAction from "./SeveralApproveAction";
import WithdrawAction from "./WitdrawAction";
import CloneAction from "./CloneAction";
import FinalizeSelectedAction from "./FinalizeSelectedAction";
export const Action = {
  AcceptAction,
  RejectAction,
  ApproveAction,
  DeclineAction,
  DoneAction,
  GiveUpAction,
  FinalizeAction,
  ReplyAction,
  ClaimAction,
  CancelBountyAction,
  SendMoneyAction,
  MarkAsPaidAction,
  ConfirmPaymentAction,
  StartCompetitionAction,
  SeveralApproveAction,
  WithdrawAction,
  CloneAction,
  FinalizeSelectedAction
};
