import { useState, useEffect } from "react";

import { GradientBorder, Section } from "../../Layout";

import { Item } from "./Item";

interface IItem {
  number: string;
  title: string;
  text: string;
  illustrationCustomStyle: string;
}

interface BaseProps {
  id: string;
  title: string;
  items: IItem[];
}

const illustrationStyles = {
  borderContainer: `
    h-full w-full
    before:bg-benefits-section__item-illu-border
    before:rounded-[40px]
    md:bg-none md:before:bg-none
    md:flex md:flex-column md:items-center
  `,
  contentContainer: `
    h-[345px] w-full
    bg-contain bg-no-repeat bg-center
    lg:h-[600px] lg:w-[800px]
    lg:-mt-[5px]
  `,
};

const styles = {
  borderContainer: `
    before:bg-benefits-section__border
    before:bg-cover
    lg:before:bg-[size:105%_105%]
    before:rounded-[40px]
  `,
  contentContainer: `
    h-full w-full
    flex flex-col items-center justify-center
    rounded-[40px]
    bg-benefits-section__bg bg-center bg-no-repeat bg-[size:100%_calc(100%_+_3px)]
    md:flex-row
  `,
};

const rootStyles = `
  h-full
  md:h-[417px] lg:h-[561px]
`;

export default function BaseSection(props: BaseProps) {
  const { id, title, items } = props;
  const [selectedBenefitId, setSelectedBenefitId] = useState(0);

  const handleClick = (benefitNumber: string) => {
    setSelectedBenefitId(parseInt(benefitNumber, 10) - 1);
  };

  const selectedItemStyle = {
    borderContainer: {
      image: "before:bg-benefits-section__item-border",
      radius: "before:rounded-[24px]",
    },
    container: `
      bg-neutral-800
    `,
    text: `
      text-neutral-100
    `,
    number: `text-fill-animation`,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const id =
        items.length - ((Math.floor(Date.now() / 1000) % items.length) + 1);
      setSelectedBenefitId(id);
    }, 5000);
    return () => clearInterval(interval);
  }, [items.length]);

  return (
    <Section {...{ id, title }}>
      <div className={rootStyles}>
        <GradientBorder {...{ customStyles: styles.borderContainer }}>
          <div className={styles.contentContainer}>
            <div className="w-full md:w-[50%] lg:w-[60%] h-full flex items-center">
              <div
                className={
                  illustrationStyles.contentContainer +
                  items[selectedBenefitId].illustrationCustomStyle
                }
              />
            </div>
            <div className="md:w-[50%] h-full flex items-center justify-center">
              <ul className="flex flex-col z-10 py-6 md:py-0 md:w-[85%]">
                {items.map((item, index) => (
                  <li key={item.number}>
                    <button
                      className={
                        `w-full rounded-[24px] ` +
                        (index === selectedBenefitId
                          ? "base-benefits-section__item-button--shadow base-benefits-section__item--selected-animated"
                          : "shadow-none")
                      }
                      onClick={() => handleClick(`0${index + 1}`)}
                    >
                      <Item
                        {...{
                          title: item.title,
                          text: item.text,
                          imageId: index + 1,
                          customStyle:
                            index === selectedBenefitId
                              ? selectedItemStyle
                              : null,
                        }}
                      />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </GradientBorder>
      </div>
    </Section>
  );
}
