import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type TProps = {
  children: ReactNode[] | ReactNode;
  customStyles: string;
  hidden?: boolean;
};

const gradientBorderStyle = `
  w-full h-full
  gradient-border-mask
  before:absolute
  before:inset-[0]
  before:p-[var(--border-width)]
  before:bg-[image:var(--border-bg)]
  before:rounded-[var(--border-radius)]
  before:p-[theme(borderWidth.sm)]
  md:before:p-[theme(borderWidth.md)]
  lg:before:p-[theme(borderWidth.lg)]
  before:bg-cover
  before:bg-center
  before:bg-no-repeat
  before:pointer-events-none
  md:before:p-[theme(borderWidth.md)]
`;

const styles = {
  borderContainer: `
    relative w-full h-full
  `,
  contentContainer: `
    ${gradientBorderStyle}
  `
};

const GradientBorder = function (props: TProps) {
  const { children, customStyles, hidden } = props;

  return (
    <div className={styles.borderContainer}>
      <div
        className={twMerge(
          (hidden ? "bg-transparent h-full" : styles.contentContainer) + customStyles
        )}>
        {children}
      </div>
    </div>
  );
};

export default GradientBorder;
