import classNames from "classnames";
import { Bounty } from "../../../../../types/Bounty";
import CurrencyAmount from "../../general/CurrencyAmount";
import TokenAmount from "../../general/TokenAmount";

const MultitaskingTotalAmount = ({ bounty, className }: { bounty: Bounty; className?: string }) => {
  return (
    <div className="text-success">
      {bounty?.token && (
        <TokenAmount
          token={bounty.token}
          amount={bounty.multitasking?.amount_per_slot ?? "0"}
          className={classNames("text-neutral-50", className)}
        />
      )}
      {bounty?.currency && (
        <CurrencyAmount
          currency={bounty.currency}
          amount={bounty.multitasking?.amount_per_slot ?? "0"}
          className={classNames("text-neutral-50", className)}
        />
      )}
    </div>
  );
};

export default MultitaskingTotalAmount;
