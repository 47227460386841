import { FormEvent, useState } from "react";

import { Area, ValidationLayout, SignFormContainerLayout } from "../../Layout";
import { Input } from "../../general/Input";
import { DEFAULT_STYLES, validateEmail, validatePassword } from "../../../helpers/Validation";
import { DarkButton } from "../../general/Button";
import { useNavigate } from "react-router";
import GithubLoginProvider from "../../AuthProviders/GithubLoginProvider";
import { NavLink } from "react-router-dom";
import GoogleLoginProvider from "../../AuthProviders/GoogleLoginProvider";
import Separator from "../../general/Separator";
import { useStoreState } from "../../../hooks";
import TwitterLoginProvider from "../../AuthProviders/TwitterLoginProvider";

interface SignInProps {
  onSubmit: (value: { email: string; password: string }) => void;
  submitDisabled: boolean;
  errorMessage?: string;
}

const MAX_LENGTH = 50;

function SignInForm(props: SignInProps) {
  const { onSubmit, submitDisabled, errorMessage } = props;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const useGithubLogin = useStoreState((state) => state.app.config?.use_github_login ?? false);
  const useGoogleLogin = useStoreState((state) => state.app.config?.use_google_login ?? false);
  const useTwitterLogin = useStoreState((state) => state.app.config?.use_twitter_login ?? false);
  // handle button click of signIn form
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTriedToSubmit(true);

    const validateResults = [validateEmail(email, true), validatePassword(password, true)];

    const isFormValid = validateResults.reduce((res, v) => {
      return v.valid && res;
    }, true);

    if (isFormValid) {
      onSubmit({ email, password });
    }
  };

  return (
    <SignFormContainerLayout
      {...{
        title: "Sign in"
      }}>
      <div className="flex flex-col gap-5 pb-10">
        <Area
          customStyles={{
            borderContainer: "border border-neutral-700",
            contentContainer: "px-[1rem] py-[2rem] bg-neutral-850"
          }}>
          <div className="font-poppins flex justify-between items-center text-base">
            <h2 className="text-neutral-50">Sign in to continue</h2>
            <span className="text-neutral-500 text-sm">
              Or{" "}
              <NavLink to="/sign-up" className="text-primary-900">
                Create an account
              </NavLink>
            </span>
          </div>
          {(useGithubLogin || useGoogleLogin || useTwitterLogin) && (
            <>
              <div className="mt-12 flex flex-col gap-3">
                <GoogleLoginProvider />
                <GithubLoginProvider />
                <TwitterLoginProvider />
              </div>
              <div className="flex flex-row flex-nowrap items-center mt-6">
                <div className="flex flex-col w-full px-2">
                  <Separator
                    customStyles={{
                      container: "py-4",
                      separator: "bg-neutral-600"
                    }}
                  />
                </div>
                <div className="font-poppins font-500 text-sm text-neutral-400 flex justify-center w-[50%]">
                  Or
                </div>
                <div className="flex flex-col w-full px-2">
                  <Separator
                    customStyles={{
                      container: "py-2",
                      separator: "bg-neutral-600"
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <form className="w-full" onSubmit={(e) => handleSubmit(e)}>
            <div className="flex flex-col gap-[1rem] py-4">
              <ValidationLayout
                {...{
                  validate: () => validateEmail(email, triedToSubmit),
                  label: { text: email.length > 0 ? "Email" : "" }
                }}>
                <Input
                  {...{
                    placeholder: "Email",
                    name: "email",
                    value: email,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH && setEmail(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  validate: () => validatePassword(password, triedToSubmit),
                  label: { text: password.length > 0 ? "Password" : "" }
                }}>
                <Input
                  {...{
                    type: "password",
                    placeholder: "Password",
                    name: "password",
                    value: password,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH && setPassword(value);
                    }
                  }}
                />
              </ValidationLayout>
              <div className="flex items-center justify-end">
                <button
                  className="text-neutral-600"
                  type="button"
                  onClick={() => navigate("/forgot-pass")}>
                  Forgot passord?
                </button>
              </div>
            </div>
            <p className={`h-[1em] text-[1em] ${DEFAULT_STYLES.invalid.message}`}>
              {errorMessage ? errorMessage : ""}
            </p>
            <div className="flex justify-center mt-4">
              <DarkButton
                {...{
                  type: "submit",
                  text: "Sign in",
                  disabled: submitDisabled
                }}
              />
            </div>
          </form>{" "}
        </Area>
      </div>
    </SignFormContainerLayout>
  );
}

export default SignInForm;
