import { DEV_MODE } from "../../../../../constants";
import { useStoreActions } from "../../../../../hooks";
import Api from "../../../../../services/Api";
import { downloadZipFile } from "../../../../../utils";
import { getFileName } from "../helpers";
import ActionButton from "./general/ActionButton";
import mixpanel from "mixpanel-browser";

const DownloadSelectedInvoicesAction = (props: {
  ids: Array<string | number>;
  accountId: string;
  disabled: boolean;
  loading: boolean;
  handleSetLoading?: (value: boolean) => void;
  onFinish?: () => void;
}) => {
  const { ids, accountId, loading = false, disabled, handleSetLoading, onFinish } = props;
  const setError = useStoreActions((actions) => actions.error.setError);

  const handleLoadInvoices = async () => {
    handleSetLoading && handleSetLoading(true);
    const responseParams = {
      ids,
      accountId,
      toPdf: true
    };
    const response = await Api.downloadInvoices(responseParams);
    handleSetLoading && handleSetLoading(false);
    onFinish && onFinish();
    if (response?.success) {
      const fileName = response.success.headers["content-disposition"]
        ? getFileName(response.success.headers["content-disposition"])
        : "invoices.zip";
      const file = new File([response.success.data], fileName);
      downloadZipFile(file);
      if (!DEV_MODE) {
        mixpanel.track("Invoice Download", {
          source: window.location.hostname
        });
      }
    } else {
      setError("Invoice download error");
    }
  };

  return (
    <ActionButton
      text="Download selected invoices"
      disabled={loading || disabled}
      loading={loading}
      onClick={handleLoadInvoices}
    />
  );
};

export default DownloadSelectedInvoicesAction;
