import { AppMessages } from "../../../common/app-messages";
import { useStoreState } from "../../../hooks";
import { Bounty, NextActionModel } from "../../../types/Bounty";
import { Action } from "../Actions";
import CheckClaimReceipt from "../Dialogues/CheckClaimReceipt";
import useNextActions from "../../../hooks/useNextActions";

const BountyActions = (props: { bounty: Bounty; walletAccountId: string }) => {
  const { bounty, walletAccountId } = props;
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);
  const { data: nextActions, refetch } = useNextActions({ bountyId: bounty.id });
  const getAvailableBountyActions = () => {
    return (
      (nextActions as Partial<NextActionModel>[] | null)?.find(
        (item) => item.accountId === walletAccountId
      )?.nextActions || {}
    );
  };

  const showWhenWhitelistIsInUse = () => {
    return ["ApprovalByWhitelist", "WhitelistWithApprovals"].includes(bounty.claimant_approval)
      ? !!bounty.claimants_whitelist?.includes(walletAccountId)
      : true;
  };

  return (
    <>
      {isAuthenticated ? (
        <div>
          <div className="flex gap-2">
            {Object.entries(getAvailableBountyActions()).map(([key, _]) => (
              <>
                {bounty.multitasking?.multitasking_type !== "DifferentTasks" &&
                  key === "bountyClaim" && (
                    <div className="py-2.5">
                      <Action.ClaimAction
                        key={"ClaimAction"}
                        bounty={bounty}
                        text={
                          bounty.bounty_flow === "SimpleBounty" ? "Submit work" : "Apply for bounty"
                        }
                      />
                    </div>
                  )}
                {key === "markAsPaid" && (
                  <div className="py-2.5">
                    <Action.MarkAsPaidAction
                      key={"MarkAsPaidAction"}
                      bounty={bounty}
                      refetchActions={refetch}
                    />
                  </div>
                )}
                {key === "finalizeBounty" && (
                  <div className="py-2.5">
                    <Action.FinalizeAction
                      key={"FinalizeAction"}
                      bounty={bounty}
                      refetchActions={refetch}
                    />
                  </div>
                )}
                {key === "startCompetition" && (
                  <div className="py-2.5">
                    <Action.StartCompetitionAction
                      key={"StartCompetitionAction"}
                      bounty={bounty}
                      refetchActions={refetch}
                    />
                  </div>
                )}
                {key === "bountyApproveOfSeveral" && (
                  <div className="py-2.5">
                    <Action.SeveralApproveAction key={"bountyApproveOfSeveral"} bounty={bounty} />
                  </div>
                )}
              </>
            ))}
            <CheckClaimReceipt />
            {bounty.owner !== walletAccountId && !showWhenWhitelistIsInUse() && (
              <AppMessages.ProfileNotWhitelistedMessage />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BountyActions;
