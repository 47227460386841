import { WalletSelector } from "@near-wallet-selector/core";
import { defExperienceLevels } from "../../../constants";
import { TokenData } from "../../../types/App";
import store from "../../../store/store";
const mapExperienceValueByName = (selectedExperience: string) => {
  return (
    defExperienceLevels.find((item) => item.name === selectedExperience)?.value ??
    selectedExperience
  );
};

const txViewUrl = (txHash: string, walletSelector: WalletSelector | null) => {
  const { options } = walletSelector ?? {};
  const nearblocks =
    options?.network.networkId === "testnet"
      ? "https://testnet.nearblocks.io/txns/"
      : "https://nearblocks.io/txns/";
  return `${nearblocks}${txHash}`;
};

const getTokenData = (token: string) => {
  const state = store.getState();
  const tokens = state.app.tokens;
  return Array.isArray(tokens)
    ? tokens.find((item: TokenData) => item.tokenId === token)
    : undefined;
};

const getTokenSymbol = (tokenId: string) => {
  const state = store.getState();
  const tokens = state.app.tokens;
  return Array.isArray(tokens)
    ? tokens.find((token) => token.tokenId === tokenId)?.symbol
    : tokenId;
};

const getTokenDecimals = (tokenId: string) => {
  const state = store.getState();
  const tokens = state.app.tokens;
  return Array.isArray(tokens)
    ? tokens.find((token) => token.tokenId === tokenId)?.decimals ?? 0
    : 0;
};

const getFileName = (contentDisposition: string) => {
  let fileName = contentDisposition.split("filename=")[1];
  if (fileName.includes(";")) {
    fileName = fileName.split(";")[0];
  }
  return decodeURIComponent(JSON.parse(fileName));
};

export {
  mapExperienceValueByName,
  txViewUrl,
  getTokenData,
  getTokenSymbol,
  getTokenDecimals,
  getFileName
};
