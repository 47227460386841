import error from "./ErrorModel";
import nearApi from "./NearApiModel";
import newBountyForm, { newBountyFormModel } from "./NewBountyFormModel";
import newBounty, { bountyCreateModel, metadataModel } from "./CreateBountyModel";
import app from "./AppModel";
import bountyList from "./BountyListModel";
import auth from "./Auth";
import { GeneralStore } from "../../types";
import { action } from "easy-peasy";
import accountWhitelists from "./AccountWhitelists";

export const initialState = {
  app,
  error,
  nearApi,
  newBountyForm,
  newBounty,
  bountyList,
  accountWhitelists,
  auth
};

const model: GeneralStore.StoreModel = {
  ...initialState,
  resetState: action((state) => {
    state.newBounty = JSON.parse(
      JSON.stringify(Object.assign(bountyCreateModel, { metadata: metadataModel }))
    );
    state.newBountyForm = JSON.parse(JSON.stringify(newBountyFormModel));
    //state.editBounty = JSON.parse(JSON.stringify(editBountyModel));
    //state.editBountyForm = JSON.parse(JSON.stringify(editBountyFormModel));
  })
};

export default model;
