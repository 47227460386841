import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import IconButton from "../Button/IconButton";
import { InfoIcon } from "../SVGIcon";

type LabelStyles = {
  container?: string;
  labelText?: string;
  validationText?: string;
};

interface LabelProps {
  labelText: string;
  textInfo?: string;
  validationText?: string;
  customStyles?: LabelStyles;
}

const buildStyles = (customStyles: LabelStyles | undefined) => ({
  container: twMerge(`
    flex flex-row justify-between
    px-2
    text-neutral-400 text-xs
    ${customStyles?.container ? customStyles.container : ""} 
  `),
  labelText: `
    ${customStyles?.labelText ? customStyles.labelText : ""} 
  `,
  validationText: `
    ${customStyles?.validationText ? customStyles.validationText : ""} 
  `
});

const Label = (props: LabelProps) => {
  const { labelText, validationText, customStyles, textInfo } = props;
  const styles = buildStyles(customStyles);

  return validationText?.length ? (
    <div className={styles.container}>
      <p className={styles.labelText}>{labelText}</p>
      <p className={styles.validationText}>{validationText}</p>
    </div>
  ) : (
    <div className={twMerge(classNames(styles.container, "items-center justify-start"))}>
      <p className={styles.labelText}>{labelText}</p>
      {textInfo && (
        <div className="ml-2">
          <div className="group relative flex justify-center w-fit">
            <IconButton
              {...{
                icon: <InfoIcon className="w-3 h-3" />
              }}
            />
            <span className="absolute bottom-5 w-max scale-0 rounded-full transition-all bg-neutral-800 border border-neutral-700 py-2 px-4 text-xs text-white group-hover:scale-100">
              {textInfo}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Label;
