import { NavLink } from "react-router-dom";
import { RedCircleIcon } from "../SVGIcon";

interface LinkProps {
  link?: string;
  text?: string;
  target?: string;
  rel?: string;
  style?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  needAction?: boolean;
}

const defaultProps = {
  link: "",
  text: "",
  target: "_blank",
  rel: "noreferrer",
  style: "",
  disabled: false,
  needAction: false
};

export default function Link(props: LinkProps) {
  const { link, text, target, rel, style, disabled, icon, needAction } = {
    ...defaultProps,
    ...props
  };

  if (link?.startsWith("https://") || link?.startsWith("http://")) {
    return (
      <a
        href={link}
        target={target}
        rel={rel}
        className={`w-fit ${
          icon &&
          "w-full flex items-center justify-start gap-4 px-4 py-3 border border-transparent rounded-xl hover:border-neutral-500 capitalize"
        } ${style}`}>
        {icon && icon}
        <span>{text}</span>
      </a>
    );
  }

  if (!disabled) {
    return (
      <NavLink
        to={link}
        target={target}
        rel={rel}
        className={`w-fit ${
          icon &&
          "w-full flex items-center justify-start gap-4 px-4 py-3 border border-transparent rounded-xl hover:border-neutral-500 capitalize"
        } ${style}`}>
        {icon && icon}
        <span>{text}</span>
        {needAction && (
          <div className="">
            <RedCircleIcon />
          </div>
        )}
      </NavLink>
    );
  } else {
    return <p className={style}>{text}</p>;
  }
}
