import { useWalletSelector } from "../providers/WalletSelectorContext";

const useWalletAccountId = () => {
  const { accountId } = useWalletSelector();
  return {
    walletAccountId: accountId
  };
};

export default useWalletAccountId;
