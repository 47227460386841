import classNames from "classnames";

const BountiesPostedOpenedIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      className={classNames("w-6 h-6 stroke-neutral-200", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2065_5018)">
        <path
          d="M16.5 6V6.75M16.5 9.75V10.5M16.5 13.5V14.25M16.5 17.25V18M7.5 12.75H12.75M7.5 15H10.5M3.375 5.25C2.754 5.25 2.25 5.754 2.25 6.375V9.401C2.70675 9.66398 3.08612 10.0427 3.34992 10.4989C3.61371 10.9552 3.7526 11.473 3.7526 12C3.7526 12.527 3.61371 13.0448 3.34992 13.5011C3.08612 13.9573 2.70675 14.336 2.25 14.599V17.625C2.25 18.246 2.754 18.75 3.375 18.75H20.625C21.246 18.75 21.75 18.246 21.75 17.625V14.599C21.2933 14.336 20.9139 13.9573 20.6501 13.5011C20.3863 13.0448 20.2474 12.527 20.2474 12C20.2474 11.473 20.3863 10.9552 20.6501 10.4989C20.9139 10.0427 21.2933 9.66398 21.75 9.401V6.375C21.75 5.754 21.246 5.25 20.625 5.25H3.375Z"
          stroke="#F8F8F8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2065_5018">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BountiesPostedOpenedIcon;
