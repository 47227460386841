interface ItemCustomStyle {
  borderContainer?: object;
  container?: string;
  subContainer?: string;
  number?: string;
  text?: string;
}

interface ItemProps {
  imageId: number;
  title: string;
  text: string;
  customStyle: ItemCustomStyle | null;
}

function Item(props: ItemProps) {
  const { imageId, title, text, customStyle } = props;

  const styles = {
    borderContainer: customStyle ? customStyle.borderContainer : { image: "before:bg-none" },
    container: `
      h-[99px] w-full
      flex items-center justify-center
      py-[10%]
      rounded-[24px]
      ${customStyle ? customStyle.container : ""}
      base-benefits-section__item--selected-animated
    `,
    subContainer: `
      w-[80%]
      flex flex-row space-x-8 justify-center items-center
      ${customStyle ? customStyle.subContainer : ""}
    `,
    number: `
      text-[#616161] text-3xl font-bold leading-7
      ${customStyle ? customStyle.number : ""}
    `,
    text: `
      text-sm font-medium font-poppins text-start
      bg-benefits-section__item-text-gradient bg-clip-text bg-cover bg-no-repeat
      ${customStyle ? customStyle.text : "text-transparent"}
    `
  };
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <h6 className={styles.number}>{`0${imageId}`}</h6>
        <div className={`flex flex-col w-full`}>
          <p className={styles.text + " !text-primary-900 text-base mb-2"}>{title}</p>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
    </div>
  );
}

export { Item, type ItemProps };
