import classNames from "classnames";
import { useMemo } from "react";
import { useStoreState } from "../../../../../hooks";
import { FormStep } from "../../../../../types/NewBountyForm";

function Stepper(props: { steps: FormStep[] }) {
  const { steps } = props;
  const { currentStep } = useStoreState((state) => state.newBountyForm);

  const currentStepIndex = useMemo(() => {
    return steps.findIndex((step) => step.name === currentStep.name);
  }, [steps, currentStep]);

  return (
    <div className="flex gap-6 items-center justify-center">
      {steps
        .filter((step) => step.name !== "review")
        .map((step, index) => (
          <span
            key={step.name}
            className={classNames("w-5 h-5 bg-transparent rounded-[50%] bg-no-repeat bg-center", {
              "active scale-110": step.name === currentStep.name,
              "bg-flow-prev": index < currentStepIndex,
              "bg-flow-future": index > currentStepIndex,
              "bg-flow-current": index === currentStepIndex
            })}
          />
        ))}
    </div>
  );
}

export default Stepper;
