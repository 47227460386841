import { useState } from "react";

import { ValidationLayout } from "../../Layout";
import { Input, TextArea } from "../../general/Input";
import { DarkButton, LightButton } from "../../general/Button";
import { DEFAULT_STYLES, isNotEmpty, validateUrl } from "../../../helpers/Validation";
import { useStoreState } from "../../../hooks";
import { CompanyMetadata } from "../../../types/Auth";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../general/CheckBox";
import { NewSelect } from "../../general/Select";
import { Option } from "../../general/Select/Select";

interface SignInProps {
  onSubmit: (...args: any[]) => void;
  submitDisabled?: boolean;
  errorMessage?: string;
}

const MAX_LENGTH = 50;

const CompanyForm = (props: SignInProps) => {
  const navigate = useNavigate();
  const { onSubmit, errorMessage } = props;
  const submitDisabled = props?.submitDisabled || false;
  const metadata = useStoreState((state) => state.auth.user?.metadata as CompanyMetadata);
  const countries = useStoreState((state) => state.app.countries);
  const mapCountries = useStoreState((state) => state.app.mapCountries);

  const [name, setName] = useState(metadata?.company?.companyName ?? "");
  const [country, setCountry] = useState(metadata?.country ?? "");
  const [addressLine1, setAddressLine1] = useState(metadata?.company?.addressLine1 ?? "");
  const [addressLine2, setAddressLine2] = useState(metadata?.company?.addressLine2 ?? "");
  const [includeVat, setIncludeVat] = useState<boolean>(Boolean(metadata?.includeVat) ?? false);
  const [reverseCharge, setReverseCharge] = useState<boolean>(metadata?.reverseCharge ?? false);
  const [vatRate, setVatRate] = useState<string>(metadata?.vatRate?.toString() ?? "0");
  const [vatNumber, setVatNumber] = useState<string>(metadata?.vatNumber ?? "");
  const [profileHandle] = useState(metadata?.company?.profileHandle ?? "");
  const [websiteLink, setWebsiteLink] = useState(metadata?.company?.companySite ?? "");
  const [companyBio, setCompanyBio] = useState(metadata?.company?.companyAbout ?? "");
  const [investments] = useState(false);

  const [triedToSubmit, setTriedToSubmit] = useState(false);

  const metadataAvailability = {
    name: metadata?.availability ?? "",
    value: metadata?.availability ?? ""
  };
  const defaultOption = { name: "", value: "" };

  const [availability, setAvailability] = useState<Option>(metadataAvailability);

  const validators = {
    name: (started: boolean) => isNotEmpty(name, started),
    //    profileHandle: (started: boolean) => isNotEmpty(profileHandle, started),
    websiteLink: (started: boolean) => validateUrl(websiteLink, started),
    companyBio: (started: boolean) => isNotEmpty(companyBio, started),
    availability: (started: boolean) => isNotEmpty(availability.name, started),
    country: (started: boolean) => isNotEmpty(country, started)
  };

  const handleVatRate = (value: string) => {
    const vatValue = isNaN(parseFloat(value)) ? "" : value.replace(",", ".");
    setVatRate(vatValue);
  };

  const handleCreateProfile = async () => {
    let website = websiteLink;

    if (websiteLink) {
      const hasHTTPS = websiteLink.indexOf("https");
      const hasHTTP = websiteLink.indexOf("http");
      if (hasHTTPS === -1 && hasHTTP === -1) {
        website = `https://${websiteLink}`;
      }
    }
    setTriedToSubmit(true);

    const validateResults = Object.values(validators).map((v) => v(true));

    const isFormValid = validateResults.reduce((res, v) => {
      return v.valid && res;
    }, true);

    if (isFormValid) {
      const company = {
        companyName: name,
        addressLine1,
        addressLine2,
        profileHandle,
        companySite: website,
        companyAbout: companyBio,
        investments: investments
      };
      onSubmit({
        company,
        availability: availability.value,
        country,
        includeVat,
        reverseCharge,
        vatRate,
        vatNumber
      });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-5 pb-10">
        <form className="w-full">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-[1rem]">
              <ValidationLayout
                {...{
                  validate: () => validators.name(triedToSubmit),
                  label: { text: "Company name" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "name",
                    value: name,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH && setName(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  validate: () => validators.country(triedToSubmit),
                  label: { text: "Location" }
                }}>
                <NewSelect
                  {...{
                    placeholder: "",
                    customStyles: {
                      container: "px-[20px] py-0",
                      optionsList: { container: "text-sm" }
                    },
                    selected: {
                      name: country,
                      value: countries[country]
                    },
                    defaultOptionsList: mapCountries,
                    defaultOption: {
                      name: "",
                      value: ""
                    },
                    onSelect: (selected: { name: string; value: string }) => {
                      setCountry(selected.name);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  label: { text: "Address Line 1 (Optional)" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "addressLine1",
                    value: addressLine1,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH && setAddressLine1(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  label: { text: "Address Line 2 (Optional)" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "addressLine2",
                    value: addressLine2,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH && setAddressLine2(value);
                    }
                  }}
                />
              </ValidationLayout>
              <div className="flex justify-around">
                <CheckBox
                  {...{
                    text: "Include VAT",
                    active: includeVat,
                    onClick: () => setIncludeVat(!includeVat)
                  }}
                />
                <CheckBox
                  {...{
                    text: "Reverse charge",
                    active: reverseCharge,
                    onClick: () => setReverseCharge(!reverseCharge)
                  }}
                />
              </div>
              <ValidationLayout
                {...{
                  label: { text: "VAT Rate (Optional)" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "vatRate",
                    value: vatRate.toString(),
                    onChange: (value: string) => {
                      handleVatRate(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  label: { text: "VAT Number (Optional)" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "vatNumber",
                    value: vatNumber,
                    onChange: (value: string) => {
                      setVatNumber(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  validate: () => validators.websiteLink(triedToSubmit),
                  label: { text: "Site link" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "site_link",
                    value: websiteLink,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH * 3 && setWebsiteLink(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  validate: () => validators.availability(triedToSubmit),
                  label: { text: "Pick availability" }
                }}>
                <NewSelect
                  {...{
                    placeholder: "",
                    defaultOptionsList: [
                      { name: "full_time", value: "Full Time" },
                      { name: "part_time", value: "Part Time" }
                    ],
                    defaultOption: defaultOption,
                    selected: availability,
                    onSelect: (selected: Option) => setAvailability(selected)
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  validate: () => validators.companyBio(triedToSubmit),
                  label: { text: "What does your company do" }
                }}>
                <TextArea
                  {...{
                    placeholder:
                      "",
                    name: "Bio",
                    value: companyBio,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH * 6 && setCompanyBio(value);
                    }
                  }}
                />
              </ValidationLayout>
            </div>
          </div>
        </form>
        <p className={`min-h-[1em] text-base ${DEFAULT_STYLES.invalid.message}`}>
          {errorMessage ? errorMessage : ""}
        </p>
        <div className="flex flex-row justify-center items-center gap-5">
          <DarkButton {...{ text: "Back", onClick: () => navigate("/profile") }} />
          <LightButton
            {...{
              text: "Save",
              onClick: handleCreateProfile,
              disabled: submitDisabled
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyForm;
