import { DarkButton, LightButton } from "../../../general/Button";
import { Area, ValidationLayout } from "../../../Layout";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import { FormRow } from "../../../general/Form/FormElements";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { NewSelect } from "../../../general/Select";
import { urlDefSearchParams } from "../../../../store/models/BountyListModel";
import { BountyFlow } from "../../../../types/BountyCreate";
import { Tag } from "react-tag-autocomplete";
import InputTagsAutocomplete from "../../../general/Input/InputTagsAutocomplete";

export type DescriptionForm = {
  bounty_flow: BountyFlow;
  type: string;
  experience: string;
  time_commitment: string;
  tags: string;
};

function Description() {
  const methods = useFormContext<DescriptionForm>();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitted },
    setValue
  } = methods;

  const config = useStoreState((state) => state.app.config);
  const { experience, category, tags } = useStoreState((state) => state.newBounty.metadata);
  const actions = useStoreActions((actions) => actions);
  const {
    newBountyForm: { next },
    newBounty: {
      metadata: { setType, setTags, setExperience }
    }
  } = actions;

  const tagsList = useMemo(() => {
    return (
      config?.tags.map((tag) => {
        return { name: tag, value: tag };
      }) || []
    );
  }, [config?.tags]);

  const bountyTypes = useMemo(() => {
    return (
      config?.categories.map((category) => {
        return { name: category, value: category };
      }) || []
    );
  }, [config?.categories]);

  const navigate = useNavigate();

  const handleTypeChange = (selected: { name: string; value: string }) => {
    setType(selected.value);
  };

  const onAdd = useCallback(
    (newTag: Tag) => {
      if (newTag.name && typeof newTag.name === "string") {
        if (!tags.includes(newTag.name)) {
          setTags([...tags, newTag.name]);
          setValue("tags", [...tags, newTag.name].join(","));
        }
      }
    },
    [tags]
  );

  const onDelete = useCallback(
    (tagIndex: number) => {
      setTags(tags.filter((_, i) => i !== tagIndex));
      setValue("tags", tags.filter((_, i) => i !== tagIndex).join(","));
    },
    [tags]
  );

  const handleBackClick = () => {
    navigate(`/bounties?${urlDefSearchParams()}`);
  };

  const handleNextClick = () => {
    if (typeof errors === "object" && Object.keys(errors).length === 0) {
      next();
    }
  };

  useEffect(() => {
    if (experience === "") {
      setExperience("AnyExperience");
    }
  }, [experience]);

  const onSubmit: SubmitHandler<DescriptionForm> = () => handleNextClick();

  return (
    <div id="description-form" className="font-poppins">
      <Area {...{ customStyles: { borderContainer: "border border-neutral-700" } }}>
        <div className="px-6 py-7">
          <FormRow className="mt-2.5">
            <h1 className="text-base">What talent are you looking for?</h1>
          </FormRow>
          <FormRow>
            <input
              type="hidden"
              defaultValue={category}
              {...register("type", { required: "Required field" })}
            />
            <ValidationLayout
              {...{
                validate: () => {
                  return {
                    valid: !errors.type,
                    started: isSubmitted,
                    message: errors.type?.message
                  };
                }
              }}>
              <NewSelect
                {...{
                  placeholder: "Type",
                  selected: { name: category, value: category },
                  defaultOptionsList: bountyTypes,
                  defaultOption: { name: category, value: category },
                  onSelect: (selected: { name: string; value: string }) => {
                    setValue("type", selected.value, {
                      shouldValidate: true
                    });
                    handleTypeChange(selected);
                  }
                }}
              />
            </ValidationLayout>
          </FormRow>

          <FormRow>
            <input
              type="hidden"
              defaultValue={tags.join(",")}
              {...register("tags", { required: "Required" })}
            />
            <ValidationLayout
              {...{
                validate: () => {
                  return {
                    valid: !errors.tags,
                    started: isSubmitted,
                    message: errors.tags?.message
                  };
                }
              }}>
              <InputTagsAutocomplete
                selected={tags}
                suggestions={tagsList.map((item) => {
                  return { name: item.name, id: item.value, disabled: tags.includes(item.name) };
                })}
                onAdd={onAdd}
                onDelete={onDelete}
              />
            </ValidationLayout>
          </FormRow>
        </div>
      </Area>
      <div className="py-6">
        <div className="flex gap-4 justify-center">
          <DarkButton
            {...{
              text: "Back",
              onClick: () => handleBackClick()
            }}
          />
          <LightButton
            {...{
              text: "Next",
              type: "submit",
              onClick: () => handleSubmit(onSubmit)()
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default Description;
