import { Path } from "history";
import { Navigate, Outlet } from "react-router-dom";
import { useStoreState } from "../hooks";

type ConnectedRouteProps = {
  redirectPath: string | Partial<Path>;
  children?: React.ReactElement | null;
};

const ConnectedRoute = (props: ConnectedRouteProps) => {
  const { children, redirectPath } = props;
  const walletSelector = useStoreState((state) => state.nearApi.walletSelector);

  if (!walletSelector?.isSignedIn()) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ConnectedRoute;
