import { useEffect, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import { TClaimerApprovalDefValues } from "../../../../../../constants";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import { ClaimantApproval } from "../../../../../../types/BountyCreate";
import CreateWhitelistAction from "../../../../../Bounty/Whitelist/Actions/CreateWhitelistAction";
import { TextArea } from "../../../../../general/Input";
import { Area, ValidationLayout } from "../../../../../Layout";
import { RestrictionsFormData } from "../BountyRestrictions";
import { FormRow } from "../../../../../general/Form/FormElements";
import { NewSelect } from "../../../../../general/Select";
import Api from "../../../../../../services/Api";

const AccountWhitelists = (props: {
  whitelists: { name: string; value: string }[];
  selectedApproval: TClaimerApprovalDefValues;
  reload: () => void;
}) => {
  const { whitelists, reload, selectedApproval } = props;

  const methods = useFormContext<RestrictionsFormData>();
  const { setValue, register, setError } = methods;
  const { isSubmitted } = methods.formState;

  const claimant_approval = useStoreState((state) => state.newBounty.claimant_approval);

  const [selectedWhiteList, setSelectedWhitelist] = useState({
    name: "",
    value: ""
  });

  const { getAccountWhitelistById } = useStoreActions((actions) => actions.accountWhitelists);

  const setClaimersWhitelist = useStoreActions((actions) => actions.newBounty.setClaimersWhitelist);

  const loadWhitelistById = async (id: string) => {
    const result = await getAccountWhitelistById({ id });
    if (result?.accounts) {
      setValue(selectedApproval.formField, result.accounts, {
        shouldValidate: true
      });
      setClaimersWhitelist(result.accounts);
    }
  };

  useEffect(() => {
    if (selectedWhiteList?.name) {
      loadWhitelistById(selectedWhiteList.name);
    }
  }, [selectedWhiteList]);

  const joinWhitelistValue = () => {
    if (typeof claimant_approval === "object") {
      for (const key in claimant_approval) {
        const { claimants_whitelist } = claimant_approval[key as keyof ClaimantApproval];
        return [claimants_whitelist].join(",") ?? "";
      }
    }
    return "";
  };

  const error = get(methods.formState.errors, selectedApproval.formField);

  const handleWhitelistSelect = (selected: { name: string; value: string }) => {
    setSelectedWhitelist(selected);
  };

  const handleChangeClaimersWhitelist = async (value: string) => {
    setValue(selectedApproval.formField, value.split(","), {
      shouldValidate: true,
      shouldDirty: true
    });
    setClaimersWhitelist(value);
  };

  const checkWhitelist = async (accounts: string) => {
    const response = await Api.checkAccountWhitelist(accounts);
    if (response?.error) {
      setError(selectedApproval.formField, {
        message: response?.message ?? "Error whitelist validation"
      });
      return false;
    }
    return true;
  };

  const handleCheckWhitelist = async () => {
    await checkWhitelist(joinWhitelistValue());
  };

  return (
    <Area
      {...{
        customStyles: {
          borderContainer: "border border-neutral-700 px-4 py-2.5"
        }
      }}>
      <ValidationLayout>
        <NewSelect
          {...{
            placeholder: "Choose whitelist",
            selected: selectedWhiteList,
            defaultOption: {
              name: "",
              value: ""
            },
            defaultOptionsList: whitelists,
            onSelect: (selected: { name: string; value: string }) => {
              handleWhitelistSelect(selected);
            }
          }}
        />
      </ValidationLayout>
      <FormRow className="flex items-center">
        <input
          type="hidden"
          defaultValue={joinWhitelistValue()}
          {...register(selectedApproval.formField, {
            required: "Required"
          })}
        />
        <ValidationLayout
          {...{
            validate: () => {
              return {
                valid: !error?.message,
                started: isSubmitted,
                message: error?.message
              };
            }
          }}>
          <TextArea
            {...{
              placeholder: "Accounts",
              value: joinWhitelistValue(),
              onChange: (value: string) => {
                handleChangeClaimersWhitelist(value);
              },
              onBlur: () => handleCheckWhitelist()
            }}
          />
        </ValidationLayout>
      </FormRow>
      <p className="text-sm text-neutral-500">
        Comma-separated values(value1, value2, ..., valueN)
      </p>
      <FormRow className="font-poppins text-sm flex justify-start gap-1 pt-2.5">
        Don’t have a list yet?
        <CreateWhitelistAction reload={reload} whitelist={joinWhitelistValue()} />
      </FormRow>
    </Area>
  );
};

export default AccountWhitelists;
