const GoogleIconSVG = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1149 5.45704C13.0615 4.47232 11.6559 3.93587 10.2015 3.95791C7.54006 3.95791 5.27973 5.7179 4.4738 8.08782C4.04648 9.32973 4.04648 10.6746 4.4738 11.9165H4.47754C5.28721 14.2828 7.54379 16.0428 10.2052 16.0428C11.579 16.0428 12.7585 15.6983 13.6726 15.0899V15.0875C14.7484 14.3893 15.4831 13.2907 15.7117 12.0488H10.2015V8.19811H19.8238C19.9438 8.86684 20 9.55026 20 10.23C20 13.2714 18.8911 15.8428 16.9617 17.5844L16.9637 17.586C15.2731 19.1145 12.9528 20 10.2015 20C6.34428 20 2.81697 17.8689 1.08517 14.4922C-0.361728 11.6667 -0.361724 8.33772 1.08518 5.51218C2.81698 2.1318 6.34428 0.000673865 10.2015 0.000673865C12.7354 -0.0287207 15.1832 0.904556 17.0274 2.60209L14.1149 5.45704Z"
        fill="#2E2E2E"
      />
    </svg>
  );
};
export default GoogleIconSVG;
