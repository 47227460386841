import { action } from "easy-peasy";
import { BountyCreate } from "../../types";
import { ClaimantApproval } from "../../types/BountyCreate";
import { MetadataActions, MetadataModel } from "../../types/Metadata";
import { formatting } from "../../common/formatting";
import moment from "moment";

export const metadataModel: MetadataModel = {
  title: "",
  description: "",
  category: "",
  attachments: undefined,
  experience: "AnyExperience",
  tags: [],
  acceptance_criteria: undefined,
  contact_details: undefined,
  time_commitment: undefined
};

const metadataActions: MetadataActions = {
  setType: action((state, payload) => {
    state.category = payload;
  }),
  setTags: action((state, payload) => {
    state.tags = payload;
  }),
  setAcceptanceCriteria: action((state, payload) => {
    state.acceptance_criteria = payload;
  }),
  setTimeCommitment: action((state, payload) => {
    state.time_commitment = payload;
  }),
  setTitle: action((state, payload) => {
    state.title = payload;
  }),
  setDescription: action((state, payload) => {
    state.description = payload;
  }),
  setExperience: action((state, payload) => {
    state.experience = payload;
  }),
  setAttachments: action((state, payload) => {
    state.attachments = payload;
  }),
  setContactDetailsContact: action((state, payload) => {
    if (state.contact_details) {
      state.contact_details.contact = payload;
    }
  }),
  setContactDetailsType: action((state, payload) => {
    if (state.contact_details) {
      state.contact_details.contact_type = payload;
    }
  }),
  setContactDetails: action((state, payload) => {
    state.contact_details = payload;
  })
};

const metadata: MetadataModel & MetadataActions = {
  ...metadataModel,
  ...metadataActions
};

export const bountyCreateModel: BountyCreate.BountyCreateModel = {
  metadata: metadata,
  deadline: {
    DueDate: {
      due_date: formatting.formatDayToNanoSec(moment(new Date()).utc(false).toDate()).toString()
    }
  },
  claimant_approval: "WithoutApproval",
  reviewers: undefined,
  kyc_config: "KycNotRequired",
  multitasking: undefined,
  allow_deadline_stretch: undefined,
  bounty_flow: "SimpleBounty",
  allow_creating_many_claims: false
};

const bountyCreateActions: BountyCreate.BountyCreateActions = {
  setDeadline: action((state, payload) => {
    state.deadline = payload;
  }),
  setReviewers: action((state, payload) => {
    state.reviewers = payload;
  }),
  setMoreReviewers: action((state, payload) => {
    const reviwers =
      (state.reviewers as BountyCreate.MoreReviewers)?.MoreReviewers?.more_reviewers || [];
    reviwers.push(payload);
    state.reviewers = { MoreReviewers: { more_reviewers: reviwers } };
  }),
  setClaimerApproval: action((state, payload) => {
    state.claimant_approval = payload;
  }),
  setClaimersWhitelist: action((state, payload) => {
    if (typeof state.claimant_approval === "object") {
      for (const key in { ...state.claimant_approval }) {
        state.claimant_approval = {
          [key]: {
            claimants_whitelist: payload.split(",").map((item) => item.trim())
          }
        } as ClaimantApproval;
      }
    }
  }),
  deleteReviewer: action((state, payload) => {
    const reviwers =
      (state.reviewers as BountyCreate.MoreReviewers)?.MoreReviewers?.more_reviewers || [];
    reviwers.splice(payload, 1);
    state.reviewers = reviwers.length ? { MoreReviewers: { more_reviewers: reviwers } } : undefined;
  }),
  clearReviewers: action((state, _) => {
    state.reviewers = undefined;
  }),
  setVerificationMethod: action((state, payload) => {
    if (state.kyc_config && state.kyc_config !== "KycNotRequired") {
      state.kyc_config.KycRequired.kyc_verification_method = payload;
    }
  }),
  setMultitasking: action((state, payload) => {
    state.multitasking = payload;
  }),
  setStartConditions: action((state, payload) => {
    if (state.multitasking && "ContestOrHackathon" in state.multitasking) {
      state.multitasking.ContestOrHackathon.start_conditions = payload;
    }
  }),
  setAllowedCreateClaim: action((state, payload) => {
    if (state.multitasking && "ContestOrHackathon" in state.multitasking) {
      state.multitasking.ContestOrHackathon.allowed_create_claim_to = payload;
    }
  }),
  setAllowedCreateClaimToDate: action((state, payload) => {
    if (state.multitasking && "ContestOrHackathon" in state.multitasking) {
      state.multitasking.ContestOrHackathon.allowed_create_claim_to = payload;
    }
  }),
  setAllowedCreateClaimToPeriod: action((state, payload) => {
    if (state.multitasking && "ContestOrHackathon" in state.multitasking) {
      state.multitasking.ContestOrHackathon.allowed_create_claim_to = payload;
    }
  }),
  setSuccessfulClaimsForResult: action((state, payload) => {
    if (
      state.multitasking &&
      "ContestOrHackathon" in state.multitasking &&
      typeof state.multitasking?.ContestOrHackathon?.successful_claims_for_result !== "undefined"
    ) {
      state.multitasking.ContestOrHackathon.successful_claims_for_result = payload;
    }
  }),
  setMinAmountToStart: action((state, payload) => {
    if (state.multitasking && "ContestOrHackathon" in state.multitasking) {
      state.multitasking.ContestOrHackathon.start_conditions = payload;
    }
  }),
  setPostpaid: action((state, payload) => {
    state.postpaid = payload;
  }),
  setOffContractCurrency: action((state, payload) => {
    if (state.postpaid?.PaymentOutsideContract) {
      state.postpaid.PaymentOutsideContract.currency = payload;
    }
  }),
  setNumberOfSlots: action((state, payload) => {
    if (state.multitasking && "OneForAll" in state.multitasking) {
      state.multitasking.OneForAll.number_of_slots = payload;
    }
  }),
  setAmountPerSlot: action((state, payload) => {
    if (state.multitasking && "OneForAll" in state.multitasking) {
      state.multitasking.OneForAll.amount_per_slot = payload.toString();
    }
  }),
  setMinSlotsToStart: action((state, payload) => {
    if (state.multitasking && "OneForAll" in state.multitasking) {
      state.multitasking.OneForAll.min_slots_to_start = payload;
    }
  }),
  setSubtask: action((state, payload) => {
    if (state.multitasking && "DifferentTasks" in state.multitasking) {
      state.multitasking.DifferentTasks.subtasks = payload;
    }
  }),
  setAllowDeadlineStretch: action((state, payload) => {
    state.allow_deadline_stretch = payload;
  }),
  setBountyFlow: action((state, payload) => {
    state.bounty_flow = payload;
  }),
  setAllowCreatingManyClaims: action((state, payload) => {
    state.allow_creating_many_claims = payload;
  })
};

const newBounty = {
  ...bountyCreateModel,
  ...bountyCreateActions
};

export default newBounty;
