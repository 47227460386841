import { useState } from "react";
import { DoneBountyForm, FinalExecutionOutcomeForm, NotWhitelistedForm } from "../Dialogues";
import * as _ from "lodash";
import { useStoreActions, useStoreState } from "../../../hooks";
import { Claim } from "../../../types/Claim";
import { Bounty } from "../../../types/Bounty";
import { useNavigate } from "react-router-dom";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import ActionButton from "./general/ActionButton";
import { DEV_MODE } from "../../../constants";
import mixpanel from "mixpanel-browser";
import { CompanyMetadata, IndividualMetadata } from "../../../types/Auth";
import { FinalExecutionOutcome } from "@near-wallet-selector/core";

export default function DoneAction(props: {
  claim: Claim;
  bounty: Bounty;
  refetchActions: () => void;
}) {
  const { claim, bounty, refetchActions } = props;
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<FinalExecutionOutcome | null>(null);
  const [showWhitelistForm, setShowWhitelistForm] = useState(false);
  const { walletAccountId } = useWalletAccountId();
  const user = useStoreState((state) => state.auth.user);
  const actions = useStoreActions((actions) => actions);
  const {
    nearApi: { setBountyDoneV2, getWhitelisted },
    auth: { userUpdateProfile }
  } = actions;
  const [loading, setLoading] = useState(false);
  const isKYCRequired = () => {
    const even = (element: boolean) => element === true;
    return [
      bounty.kyc_config?.kyc_verification_method === "DuringClaimApproval",
      claim.is_kyc_delayed !== "AllowedToSkipKyc"
    ].every(even);
  };

  const handleCloseForm = () => {
    setShowForm(!showForm);
  };

  const handleCloseWhitelistForm = () => {
    setShowWhitelistForm(!showWhitelistForm);
  };

  const handleButtonClick = async () => {
    const whitelisted = await getWhitelisted(walletAccountId);
    if (isKYCRequired() && !whitelisted) {
      setShowWhitelistForm(true);
    } else {
      setShowForm(true);
    }
  };

  const handleSubmitForm = async (formData: {
    attachments?: string[];
    linkToWork?: string;
    description: string;
    metadata?: CompanyMetadata | IndividualMetadata;
  }) => {
    if (formData?.metadata) {
      setLoading(true);
      const profileType = user?.metadata?.profileType;
      const metadata = _.merge(user?.metadata, formData.metadata);
      const newUser = {
        ...user,
        metadata: {
          ...{
            profileType
          },
          ...metadata
        }
      };
      if (!DEV_MODE) {
        mixpanel.track("Submit Work", {
          source: window.location.hostname
        });
      }
      await userUpdateProfile({
        newUser
      });
    }

    const result = await setBountyDoneV2({
      id: bounty.id,
      claimer: claim.owner,
      attachments: formData.attachments,
      description: formData.description,
      linkToWork: formData.linkToWork,
      claim_number: claim.claim_number
    });
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
    }
  };

  const handleWhitelistSubmit = () => {
    setShowWhitelistForm(false);
    navigate("/kyc");
  };

  return (
    <>
      <ActionButton text={"Submit work"} disabled={loading} handleClick={handleButtonClick} />
      <DoneBountyForm
        invoice={bounty.use_invoice}
        show={showForm}
        loading={loading}
        bounty={bounty}
        handleClose={handleCloseForm}
        handleSubmit={handleSubmitForm}
      />
      <NotWhitelistedForm
        show={showWhitelistForm}
        handleClose={handleCloseWhitelistForm}
        handleSubmit={handleWhitelistSubmit}
      />
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  );
}
