import { TwitterShareButton } from "react-share";
import { BountyTransaction } from "../../../../../../../../types/BountyTransaction";
import { getTitle } from "./helpers";

const ShareButton = (props: { tx: BountyTransaction }) => {
  const { tx } = props;

  console.log(tx);

  return (
    <TwitterShareButton
      title={getTitle(tx)}
      url={`https://heroes.build/bounties/bounty/${tx.bountyId}`}>
      <span className="underline text-primary-900">Share on X</span>
    </TwitterShareButton>
  );
};

export default ShareButton;
