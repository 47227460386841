import BaseSection from "./BaseSection";

export default function BenefitsSection() {
  const items = [
    {
      number: '01',
      title: 'Take control',
      text: 'Find Web3 projects that hits your financial goals.',
      illustrationCustomStyle: `
        bg-benefits-section__item-1-bg bg-contain
        md:bg-benefits-section__item-1-illu-md md:bg-contain
      `,
    },
    {
      number: '02',
      title: 'Work and earn your way',
      text: 'Work anyway you like, search for bounties or have clients come to you.',
      illustrationCustomStyle: `
        bg-benefits-section__item-2-bg bg-contain
        md:bg-benefits-section__item-2-illu-md md:bg-contain
      `,
    },
    {
      number: '03',
      title: 'Connect & engage',
      text: 'Learn, share information, and build your Web3 community',
      illustrationCustomStyle: `
        bg-benefits-section__item-3-bg bg-contain
        md:bg-benefits-section__item-3-illu-md md:bg-contain
      `,
    },
  ]
  const id = 'benefits_section'
  const title = 'Find great projects, make your mark on Web 3 and build rewarding relationships'

  return (
    <BaseSection {...{id, title, items}}/>
  )
}
