import { useEffect } from "react";
import { BatchExecutionOutcome } from "../../../../types/NearApi";
import { DarkButton } from "../../../general/Button";
import Modal from "../../../general/Modal";

export default function FinalExecutionBatchTxnsForm(props: {
  show: boolean;
  executionOutcome: BatchExecutionOutcome | null;
  refetchActions: () => void;
  fetchStatus: () => Promise<void>;
  handleClose: () => void;
}) {
  const { show, executionOutcome, handleClose, fetchStatus, refetchActions } = props;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (executionOutcome?.status && executionOutcome.status === "pending") {
        fetchStatus();
      }
    }, 1000);
    if (executionOutcome?.status === "done") {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [executionOutcome?.status]);

  const onCloseForm = () => {
    handleClose();
    refetchActions && refetchActions();
  };

  return (
    <Modal
      {...{
        customStyles: {
          modal: "min-h-[400px]",
          content: "h-fit"
        },
        open: show,
        onClose: handleClose
      }}>
      <div className="font-poppins py-2.5">
        <div className="text-lg text-white">Transactions execution outcome</div>
        {executionOutcome && show && (
          <div className="font-poppins text-sm text-neutral-50 px-4 py-2.5 overflow-hidden break-all">
            <div>
              <span className="text-neutral-500"> Batch Id:</span> {executionOutcome.id}
            </div>
            <div className="flex items-center">
              <span className="text-neutral-500"> Batch Status:</span>
              <span className="px-2"> {executionOutcome.status}</span>
              {executionOutcome.status === "pending" ? (
                <div
                  className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status">
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <div className="my-2.5">
                Transactions in progress:{" "}
                {Array.isArray(executionOutcome?.batch) && executionOutcome.batch.length}{" "}
              </div>
              <div className="px-2">
                <div className="text-neutral-50">
                  <span className="text-neutral-500">Error:</span>{" "}
                  {executionOutcome.batch.filter((tx) => tx.status === "error").length ?? 0}{" "}
                </div>
                <div className="text-neutral-50">
                  <span className="text-neutral-500">Done:</span>{" "}
                  {executionOutcome.batch.filter((tx) => tx.status === "done").length ?? 0}{" "}
                </div>
                <div>
                  <span className="text-neutral-500"> Pending: </span>
                  {executionOutcome.batch.filter((tx) => tx.status === "pending").length ?? 0}{" "}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex gap-4 justify-end mt-8">
          <DarkButton
            {...{
              customStyles: { button: "max-w-[146px]" },
              onClick: () => onCloseForm(),
              text: "Close"
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
