import { useState, useRef, RefObject } from "react";
import { SelectArrow } from "../../SVGIcon";
import { buildStyles as buildItemStyles } from "../Item";
import OptionList, { OptionListStyles } from "../OptionList";
import { Input } from "../../../general/Input";
import { findIdByValue, findIdsByValues } from "../../../../utils";
import { useOutsideClick } from "../../../../hooks";
import Tag from "../../../general/Tag";
import { twMerge } from "tailwind-merge";

export type Option = {
  name: string;
  value: string;
};

const compare = (element: Option, item: Option) => {
  return element.name === item.name;
};

interface MultiSelectProps {
  placeholder?: string;
  defaultOption: Option;
  defaultOptionsList: Option[];
  selected: Option;
  onSelect: (value: Option) => void;
  multiSelected: Option[];
  onMultiSelect: (value: Option[]) => void;
  customStyles?: MultiSelectStyles;
  disabled?: boolean;
}

type MultiSelectStyles = {
  container?: string;
  selectedItem?: string;
  optionsList: OptionListStyles;
};

const buildStyles = (
  customStyles: MultiSelectStyles | undefined,
  showOptionList: boolean,
  disabled: boolean = false
) => ({
  container: twMerge(`
    m-width-[50px]
    relative
    ${customStyles?.container ? customStyles.container : ""}
    ${disabled ? "!cursor-not-allowed" : ""}
  `),
  selectedItem: twMerge(`
    ${buildItemStyles().container}
    py-2
    flex flex-row justify-between items-center
    rounded-[10px]
    ${showOptionList ? "rounded-b-none" : ""}
    ${customStyles?.selectedItem ? customStyles.selectedItem : ""}
    ${disabled ? "!cursor-not-allowed" : ""}
  `)
});

export const scroll = <RefType,>(
  key: "ArrowUp" | "ArrowDown",
  optionsCount: number,
  highlighted: number,
  ref: RefObject<RefType>
) => {
  const LAST_NO_SCROLL_ELEMENT = 4;
  const OPTION_ITEM_HEIGHT_PX = 55;
  const applyScroll = (amount: number, ref: RefObject<RefType>) => {
    const refer = ref?.current as Element;
    if (!refer) {
      return;
    }

    refer.scroll(0, amount);
  };

  if (key === "ArrowUp") {
    highlighted < optionsCount - LAST_NO_SCROLL_ELEMENT &&
      applyScroll((highlighted - 1) * OPTION_ITEM_HEIGHT_PX, ref);
  } else if (key === "ArrowDown") {
    highlighted > LAST_NO_SCROLL_ELEMENT - 1 &&
      applyScroll((highlighted - (LAST_NO_SCROLL_ELEMENT - 1)) * OPTION_ITEM_HEIGHT_PX, ref);
  }
};

function MultiSelect(props: MultiSelectProps) {
  const {
    placeholder,
    defaultOption,
    defaultOptionsList,
    selected,
    multiSelected,
    onSelect,
    onMultiSelect,
    customStyles,
    disabled
  } = props;
  const [showOptionList, setShowOptionList] = useState(false);
  const [optionsList, setOptionsList] = useState<Option[]>(defaultOptionsList);
  const [filterInputValue, setFilterInputValue] = useState(selected.value);
  const [highlighted, setHighlighted] = useState(0);

  const styles = buildStyles(undefined, showOptionList, disabled);
  const optionListRef = useRef<HTMLUListElement>(null);

  const onKeyDown = (key: string) => {
    if (key === "ArrowUp") {
      scroll(key, optionsList.length, highlighted, optionListRef);
      const newHighlightedId = highlighted ? highlighted - 1 : 0;
      setHighlighted(newHighlightedId);
      setShowOptionList(true);
    } else if (key === "ArrowDown") {
      scroll(key, optionsList.length, highlighted, optionListRef);
      const newHighlightedId =
        highlighted < optionsList.length - 1 ? highlighted + 1 : optionsList.length - 1;
      setHighlighted(newHighlightedId);
      setShowOptionList(true);
    } else if (key === "Enter") {
      const safeSelected = optionsList[highlighted];
      if(safeSelected) {
        processSelect(safeSelected, defaultOption.value, defaultOptionsList, multiSelected);
      }
    }
  };

  const onOptionClick = (selectedName: string) => {
    const selectedOption = optionsList.filter((opt: Option) => opt.name === selectedName)[0];
    processSelect(selectedOption, defaultOption.value, defaultOptionsList, multiSelected);
  };

  const onMouseOver = (highlightedName: string) => {
    const highlightedOption =
      optionsList.find((option: Option) => option.name === highlightedName) || optionsList[0];
    const highlightedId = findIdByValue<Option>(optionsList, highlightedOption, compare) || 0;
    setHighlighted(highlightedId);
  };

  const processSelect = (
    selectedOption: Option | undefined,
    filterInputValue: string,
    optionsList: Option[],
    multiSelectedOptions: Option[] = []
  ) => {
    selectedOption && onSelect && onSelect(selectedOption);

    const alreadySelected = multiSelectedOptions.find(
      (sel: Option) => sel.name === selectedOption?.name
    );

    selectedOption &&
      onMultiSelect &&
      !alreadySelected &&
      onMultiSelect(multiSelectedOptions.concat(selectedOption));
    selectedOption &&
      onMultiSelect &&
      alreadySelected &&
      onMultiSelect(
        multiSelectedOptions.filter((option: Option) => option.name !== selectedOption.name)
      );

    setOptionsList(optionsList);
    setFilterInputValue(filterInputValue);
  };

  const onFilterInputChange = (value: string) => {
    const filteredOptions = defaultOptionsList.filter((option) =>
      option.value.toLowerCase().match(value.toLowerCase())
    );

    processSelect(undefined, value, filteredOptions, multiSelected);
    setShowOptionList(true);
    value === "" && setHighlighted(0);
  };

  const handleListDisplay = () => {
    !disabled && setShowOptionList(!showOptionList);
  };

  const handleClickOutside = () => {
    setShowOptionList(false);
  };
  const outsideClickRef = useOutsideClick<HTMLDivElement>(handleClickOutside);

  return (
    <div ref={outsideClickRef} className={styles.container}>
      <div
        className={showOptionList ? styles.selectedItem + "active" : styles.selectedItem}
        onClick={handleListDisplay}>
        <div className="flex flex-column flex-wrap w-full gap-2">
          {multiSelected.map((tag: Option) => (
            <Tag
              key={tag.name}
              {...{
                ...tag,
                onClose: (name: string) => {
                  onMultiSelect(multiSelected.filter((tag: Option) => tag.name !== name));
                }
              }}
            />
          ))}
          <Input
            {...{
              customStyles: {
                input: "bg-transparent outline-none rounded-none px-0 py-[10px]"
              },
              placeholder,
              name: "tags",
              value: filterInputValue,
              validation: { valid: false, started: false },
              onChange: onFilterInputChange,
              autocomplete: "off",
              onKeyDown,
              disabled
            }}
          />
        </div>
        <div className="min-w-3">
          <SelectArrow {...{ position: showOptionList ? "up" : "down" }} />
        </div>
      </div>
      {showOptionList && (
        <OptionList
          {...{
            refer: optionListRef,
            customStyles: { ...(customStyles?.optionsList || {}) },
            optionsList,
            onOptionClick,
            onMouseOver,
            onKeyDown,
            highlightedId: highlighted,
            highlightedIds: findIdsByValues(defaultOptionsList, multiSelected, compare)
          }}
        />
      )}
    </div>
  );
}

export default MultiSelect;
