import { useMemo, useState } from "react";
import ContestOrHackathonForm from "./ContestOrHackathon";
import DifferentTasksForm from "./DifferentTasks";
import OneForAllForm from "./OneForAll";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import { FormRow } from "../../../../../general/Form/FormElements";
import CheckBox from "../../../../../general/CheckBox";

const MultitaskingOptions = () => {
  const multitasking = useStoreState((state) => state.newBounty.multitasking);
  const setMultitasking = useStoreActions((actions) => actions.newBounty.setMultitasking);
  const setClaimerApproval = useStoreActions((actions) => actions.newBounty.setClaimerApproval);
  const setAllowCreatingManyClaims = useStoreActions(
    (actions) => actions.newBounty.setAllowCreatingManyClaims
  );
  const bounty_flow = useStoreState((state) => state.newBounty.bounty_flow);

  const multitaskingDefValue = useMemo(() => {
    if (multitasking) {
      if ("ContestOrHackathon" in multitasking) {
        return "ContestOrHackathon";
      }
      if ("OneForAll" in multitasking) {
        return "OneForAll";
      }
      if ("DifferentTasks" in multitasking) {
        return "DifferentTasks";
      }
    }
    return undefined;
  }, [multitasking]);

  const [selectedOption, setSelectedOption] = useState<string | undefined>(multitaskingDefValue);

  const handleClickContestOrHackathon = () => {
    if (selectedOption === "ContestOrHackathon") {
      setSelectedOption("");
      setMultitasking(undefined);
      if (bounty_flow === "AdvancedFlow") {
        setClaimerApproval("MultipleClaims");
      } else {
        setClaimerApproval("WithoutApproval");
      }
      setAllowCreatingManyClaims(false);
    } else {
      setSelectedOption("ContestOrHackathon");
      setClaimerApproval(undefined);
      setMultitasking({
        ContestOrHackathon: {
          successful_claims_for_result: null
        }
      });
    }
  };

  const handleClickOneForAll = () => {
    if (selectedOption === "OneForAll") {
      setSelectedOption("");
      setMultitasking(undefined);
      setAllowCreatingManyClaims(false);
    } else {
      setSelectedOption("OneForAll");
      setMultitasking({
        OneForAll: {
          number_of_slots: 0,
          amount_per_slot: "0",
          min_slots_to_start: null
        }
      });
    }
  };

  const handleClickDifferentTasks = () => {
    if (selectedOption === "DifferentTasks") {
      setSelectedOption("");
      setMultitasking(undefined);
      setAllowCreatingManyClaims(false);
    } else {
      setSelectedOption("DifferentTasks");
      setMultitasking({
        DifferentTasks: {
          subtasks: []
        }
      });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <CheckBox
          {...{
            text: "Add more hunters",
            textInfo: "Allow more than one hunters can work on this bounty",
            active: selectedOption === "OneForAll",
            onClick: () => handleClickOneForAll()
          }}
        />
        <CheckBox
          {...{
            text: "Different tasks",
            textInfo: "Allow more than one hunters can work on this bounty",
            active: selectedOption === "DifferentTasks",
            onClick: () => handleClickDifferentTasks()
          }}
        />
      </div>
      {selectedOption === "ContestOrHackathon" && <ContestOrHackathonForm />}
      {selectedOption === "OneForAll" && <OneForAllForm />}
      {selectedOption === "DifferentTasks" && <DifferentTasksForm />}
    </>
  );
};

export default MultitaskingOptions;
