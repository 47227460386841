import { Area, MarksLayer } from "../../Layout";
import utils from "../../../services/utils";
import ReactMarkdown from "react-markdown";
import { Bounty } from "../../../types/Bounty";
import {
  BulbIcon,
  CalendarIcon,
  CoinIcon,
  EyeIcon,
  HandIcon,
  RocketIcon,
  UsersIcon,
  WarningIcon
} from "../../general/SVGIcon";
import {
  EditDeadline,
  EditDescription,
  EditSkillsAndExperience
} from "../../Form/EditBountyForm/Actions";
import { Action } from "../Actions";
import { decodeHtmlSymbols } from "../BountyListItem";
import EditContactDetails from "../../Form/EditBountyForm/Actions/EditContactDetails";
import BountyActions from "./BountyActions";
import EditAmount from "../../Form/EditBountyForm/Actions/EditAmount";
import TokenAmount from "./TokenAmount";
import CurrencyAmount from "./CurrencyAmount";
import Mark from "../../general/Mark";
import classNames from "classnames";
import DaysFromNow from "../../general/DaysFromNow";
import BountyTransactions from "../BountyTransactions";
import { useContext } from "react";
import WalletAccountContext from "../general/context/WalletAccountContext";
import Subtasks from "../Subtasks";
import { mapExperienceValueByName } from "../general/helpers";
import WhitelistAccounts from "./WhitelistAccounts";
import ApplicationList from "../ApplicationList";
import { calcTotalAmount } from "./helpers";
import AcceptSuccessMessage from "../Dialogues/AcceptHeroForm/AcceptSuccessMessage";
import ApproveSuccessMessage from "../Dialogues/ApproveBountyForm/ApproveSuccessMessage";
import { showCountdown } from "../ApplicantItem/helpers";
import Countdown from "react-countdown";
import { NextActionProps } from "../../../hooks/useNextActions";

const ViewBounty = (props: {
  bounty: Bounty;
  nextActions: NextActionProps;
  refetchActions: () => void;
}) => {
  const { bounty, nextActions, refetchActions } = props;
  const walletAccountId = useContext(WalletAccountContext);

  const { amount, platform_fee } = bounty;

  const showAcceptMessage = () => {
    return bounty.claims.find((item) => item.owner === walletAccountId && item.accepted)
      ? true
      : false;
  };

  const showApprovedMessage = () => {
    return bounty.claims.find((item) => item.owner === walletAccountId && item.approved)
      ? true
      : false;
  };

  const Badges = () => {
    return (
      <>
        {["ApprovalByWhitelist", "WhitelistWithApprovals"].includes(bounty.claimant_approval) && (
          <Mark
            {...{
              text: "Whitelisted Only",
              customStyles: {
                container: classNames("px-3 text-neutral-800 bg-secondary-500"),
                text: "capitalize"
              }
            }}
          />
        )}
        {bounty.bounty_flow === "SimpleBounty" && (
          <Mark
            {...{
              text: "Express",
              customStyles: {
                container: classNames("px-3 text-neutral-800 bg-primary-500"),
                text: "uppercase"
              }
            }}
          />
        )}
        {bounty.postpaid && (
          <Mark
            {...{
              text: "Deferred Payment",
              customStyles: {
                container: classNames("px-3 text-neutral-800 bg-post-secondary-900"),
                text: "capitalize"
              }
            }}
          />
        )}
        {bounty.kyc_config?.kyc_required && (
          <Mark
            {...{
              text: "KYC",
              customStyles: {
                container: classNames("px-3 text-neutral-800 bg-secondary-800"),
                text: "capitalize"
              }
            }}
          />
        )}
        <Mark
          {...{
            text: bounty.status === "ManyClaimed" ? "Claimed" : bounty.status,
            customStyles: {
              container: classNames("px-3 text-neutral-800 bg-neutral-400", {
                "bg-primary-900": ["AwaitingClaims", "New"].includes(bounty.status),
                "bg-post-secondary-900":
                  bounty.status === "Claimed" || bounty.status === "ManyClaimed",
                "bg-success": bounty.status === "Completed",
                "bg-neutral-400": bounty.status === "Canceled"
              })
            }
          }}
        />
      </>
    );
  };

  return (
    <>
      <MarksLayer
        {...{
          customStyles: {
            contentContainer: "lg:visible md:invisible sm:invisible xs:invisible",
            borderContainer: "relative top-0"
          }
        }}>
        <Badges />
      </MarksLayer>
      <Area
        {...{
          customStyles: {
            borderContainer: "border border-neutral-700",
            contentContainer: "flex-1 bg-neutral-850 p-6 font-poppins text-white"
          }
        }}>
        <div className="lg:invisible md:visible sm:visible xs:visible flex flex-wrap justify-end gap-1.5 lg:mb-0 sm:mb-4">
          <Badges />
        </div>
        <div className="flex gap-2">
          <Action.CloneAction bounty={bounty} />
          {bounty.owner === walletAccountId && <Action.CancelBountyAction bounty={bounty} />}
        </div>
        <div className="flex justify-between">
          <div className="flex flex-1 gap-2 items-start justify-between">
            <div className="flex-1">
              <h2 className="text-lg">{decodeHtmlSymbols(bounty.metadata.title)}</h2>
              <div className="my-2.5">
                <div className="flex flex-wrap items-center justify-between gap-4">
                  <div className="flex">
                    {
                      <span className="text-md text-neutral-600">
                        Posted{" "}
                        <DaysFromNow
                          date={bounty.created_at}
                          className={"text-md text-neutral-600"}
                        />
                      </span>
                    }
                  </div>
                  {walletAccountId && (
                    <div className="flex justify-end items-center gap-4">
                      <BountyActions bounty={bounty} walletAccountId={walletAccountId} />
                      {[bounty.claims.find((claim) => claim.owner === walletAccountId)].map(
                        (claim) => (
                          <>
                            {claim && (
                              <div>
                                {showCountdown(claim) && (
                                  <Mark
                                    {...{
                                      text: (
                                        <span>
                                          Remaining time
                                          <Countdown
                                            className="px-1"
                                            date={new Date(claim.end_time)}
                                          />
                                        </span>
                                      ),
                                      customStyles: {
                                        container: classNames(
                                          "flex items-center px-0 justify-between text-neutral-800 bg-post-secondary-900"
                                        ),
                                        text: "min-w-[74px] px-[1em] py-[0.45em] normal-case text-sm"
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <ApplicationList
            bounty={bounty}
            nextActions={nextActions}
            refetchActions={refetchActions}
          />
        </div>
        <div>
          <BountyTransactions bountyId={bounty.id.toString()} />
        </div>
        {bounty.multitasking && bounty.multitasking.multitasking_type === "DifferentTasks" && (
          <div className="my-4">
            <Subtasks bounty={bounty} nextActions={nextActions} />
          </div>
        )}
        {bounty.claimant_approval &&
          ["WhitelistWithApprovals", "ApprovalByWhitelist"].includes(bounty.claimant_approval) && (
            <div className="my-4">
              <WhitelistAccounts bounty={bounty} />
            </div>
          )}
        <div className="my-4">
          <Area
            {...{
              customStyles: {
                borderContainer: "border border-sm border-neutral-800",
                contentContainer:
                  "grid lg:grid-cols-2 sm:grid-cols-1 lg:gap-8 sm:gap-4 bg-neutral-850 p-8"
              }
            }}>
            <div className="flex items-center gap-4">
              <CalendarIcon />
              <div className="flex-1">
                <div className="text-md text-neutral-200">
                  {bounty?.deadline && (
                    <div className="flex justify-between">
                      {utils.getBountyDeadline(bounty)}
                      {bounty.owner === walletAccountId && (
                        <EditDeadline bounty={bounty} refetchActions={refetchActions} />
                      )}
                    </div>
                  )}
                </div>
                <div className="text-sm text-neutral-600">Deadline</div>
              </div>
            </div>
            {(bounty?.due_date || bounty?.max_deadline) && (
              <div className="flex items-center gap-4">
                <RocketIcon />
                <div>
                  <div className="text-md text-neutral-200">{utils.getBountyTimeLeft(bounty)}</div>
                  <div className="text-sm text-neutral-600">Time left</div>
                </div>
              </div>
            )}
            <div className="flex items-center gap-4">
              <CoinIcon />
              <div>
                <div className="text-md text-neutral-200">
                  {bounty?.token && (
                    <div className="flex items-center gap-2 justify-between">
                      <TokenAmount
                        token={bounty?.token ?? ""}
                        amount={calcTotalAmount(amount, platform_fee)}
                      />
                      {bounty.owner === walletAccountId && (
                        <EditAmount bounty={bounty} refetchActions={refetchActions} />
                      )}
                    </div>
                  )}
                  {bounty?.currency && (
                    <div className="flex items-center gap-2 justify-between">
                      <CurrencyAmount currency={bounty.currency} amount={bounty.amount} />
                      {bounty.owner === walletAccountId && (
                        <EditAmount bounty={bounty} refetchActions={refetchActions} />
                      )}
                    </div>
                  )}
                </div>
                <div className="text-sm text-neutral-600">Entire prize pool</div>
              </div>
            </div>
            {bounty?.multitasking?.number_of_slots && (
              <div className="flex items-center gap-4">
                <UsersIcon className="text-neutral-50 w-6" />
                <div className="text-md text-neutral-200">
                  <div>{bounty.multitasking.number_of_slots}</div>
                  <div className="text-sm text-neutral-600">Available slots</div>
                </div>
              </div>
            )}
            {bounty?.multitasking?.multitasking_type === "OneForAll" && (
              <div className="flex items-center gap-4">
                <CoinIcon />
                <div>
                  <div className="flex flex-col text-sm text-neutral-200">
                    {bounty.multitasking.multitasking_type === "OneForAll" && (
                      <>
                        <span>
                          {bounty.multitasking.amount_per_slot && (
                            <>
                              {bounty?.token && (
                                <div className="flex items-center gap-2 justify-between">
                                  <TokenAmount
                                    token={bounty?.token ?? ""}
                                    amount={bounty.multitasking.amount_per_slot}
                                  />
                                </div>
                              )}
                              {bounty?.currency && (
                                <div className="flex items-center gap-2 justify-between">
                                  <CurrencyAmount
                                    currency={bounty.multitasking.amount_per_slot}
                                    amount={bounty.amount}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="text-sm text-neutral-600">
                    {bounty?.multitasking?.multitasking_type === "OneForAll" && <>Per Hunter</>}
                    {/*{bounty?.multitasking?.multitasking_type === "DifferentTasks" && (*/}
                    {/*    <>Price Per Task</>*/}
                    {/*)}*/}
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center gap-4">
              <BulbIcon />
              <div className="flex-1">
                <div className="flex justify-between text-md text-neutral-200">
                  {mapExperienceValueByName(bounty.metadata.experience)}
                  {bounty.owner === walletAccountId && (
                    <EditSkillsAndExperience bounty={bounty} refetchActions={refetchActions} />
                  )}
                </div>
                <div className="text-sm text-neutral-600">Skill level</div>
              </div>
            </div>
          </Area>
        </div>
        <div className="my-4 mx-auto text-white w-full">
          {bounty?.metadata.description && (
            <Area
              {...{
                customStyles: {
                  borderContainer: "border border-sm border-neutral-800",
                  contentContainer: "bg-neutral-850 p-8 text-neutral-50 text-sm break-words"
                }
              }}>
              <div className="flex justify-between">
                <span className="text-neutral-200 text-sm">Description</span>
                {bounty.owner === walletAccountId && (
                  <EditDescription bounty={bounty} refetchActions={refetchActions} />
                )}
              </div>
              <ReactMarkdown className="text-neutral-50 markdown pb-2">
                {bounty?.metadata?.description}
              </ReactMarkdown>
            </Area>
          )}
        </div>
        <div className="my-4">
          <Area
            {...{
              customStyles: {
                borderContainer: "border border-sm border-neutral-800",
                contentContainer: "bg-neutral-850 p-8"
              }
            }}>
            <div className="flex items-center justify-between">
              <div className="text-neutral-200 mb-4 text-sm">Skill and expertise</div>
              {bounty.owner === walletAccountId && (
                <EditSkillsAndExperience bounty={bounty} refetchActions={refetchActions} />
              )}
            </div>
            {bounty?.metadata.tags && (
              <div className="flex flex-wrap gap-2">
                {bounty?.metadata.tags.map((tag) => (
                  <button
                    key={tag}
                    className="bg-neutral-800 text-sm px-4 py-1.5 outline-none rounded-3xl">
                    {tag}
                  </button>
                ))}
              </div>
            )}
          </Area>
        </div>
        {/*<div className="my-4">*/}
        {/*  <Area*/}
        {/*    {...{*/}
        {/*      customStyles: {*/}
        {/*        borderContainer: "border border-sm border-neutral-700",*/}
        {/*        contentContainer: "bg-neutral-800 p-8"*/}
        {/*      }*/}
        {/*    }}>*/}
        {/*    <div className="flex items-center justify-between">*/}
        {/*      <div className="text-neutral-200 mb-4 text-sm">Acceptance criteria</div>*/}
        {/*      {bounty.owner === walletAccountId && <EditAcceptanceCriteria bounty={bounty} />}*/}
        {/*    </div>*/}
        {/*    {bounty.metadata?.acceptance_criteria ?? ""}*/}
        {/*  </Area>*/}
        {/*</div>*/}
        <div className="my-4">
          <Area
            {...{
              customStyles: {
                borderContainer: "border border-sm border-neutral-800",
                contentContainer: "bg-neutral-850 p-8"
              }
            }}>
            <div className="flex items-center justify-between">
              <div className="text-neutral-200 mb-4 text-sm">Contact information</div>
              {bounty.owner === walletAccountId && <EditContactDetails bounty={bounty} />}
            </div>
            <div className="text-neutral-200 break-words">
              <span className="text-md pr-2">
                {bounty?.metadata?.contact_details?.contact_type ?? ""}
              </span>
              <span className="text-md">{bounty?.metadata?.contact_details?.contact ?? ""}</span>
            </div>
          </Area>
        </div>
        <div className="my-4">
          <Area
            {...{
              customStyles: {
                borderContainer: "border border-sm border-neutral-800",
                contentContainer: "bg-neutral-850 p-8"
              }
            }}>
            <div className="flex items-center justify-between">
              <div className="text-neutral-200 mb-4 text-sm">Activity on this bounty</div>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 items-center gap-4">
              <div>
                <div className="flex items-center gap-4">
                  <HandIcon />
                  <div>
                    <div className="text-md text-neutral-200">{bounty.claims.length}</div>
                    <div className="text-sm text-neutral-600">Bounty applicants</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center gap-4">
                  <EyeIcon />
                  <div>
                    <div className="text-md text-neutral-200">{bounty?.views ?? "0"}</div>
                    <div className="text-sm text-neutral-600">Total views</div>
                  </div>
                </div>
              </div>
            </div>
          </Area>
        </div>
        {bounty.postpaid && (
          <div className="text-neutral-200 text-sm my-4">
            <Area
              {...{
                customStyles: {
                  borderContainer: "border border-sm border-neutral-700",
                  contentContainer: "bg-neutral-800 p-8"
                }
              }}>
              <div className="flex items-center justify-between">
                <div className="flex gap-2 mb-4">
                  <WarningIcon className="stroke-neutral-50" /> Postpay bounty
                </div>
              </div>
              <div className="break-words">
                Be aware this bounty does not have funds locked in escrow, so make sure you trust
                the issuer of this bounty and expect the payment process to be slower
              </div>
            </Area>
          </div>
        )}
      </Area>
      <AcceptSuccessMessage show={showAcceptMessage()} />
      <ApproveSuccessMessage bounty={bounty} show={showApprovedMessage()} />
    </>
  );
};

export default ViewBounty;
