import moment from "moment";
import { useEffect, useMemo } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { formatting } from "../../../../common/formatting";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { BountyCreate } from "../../../../types";
import { DarkButton, LightButton } from "../../../general/Button";
import { FormRow } from "../../../general/Form/FormElements";
import SimpleDatePicker from "../../../general/DatePicker/SimpleExpandedDatePicker";

export type DurationFormData = {
  deadline: BountyCreate.Deadline;
  deadlineTimeCommitment?: string;
};

const Duration = () => {
  const methods = useFormContext<DurationFormData>();
  const { handleSubmit, formState, setValue, reset } = methods;
  const { errors } = formState;
  const actions = useStoreActions((actions) => actions);
  const { deadline } = useStoreState((state) => state.newBounty);
  const max_due_date = useStoreState((state) => state.app.config?.max_due_date);

  const {
    newBounty: { setDeadline },
    newBountyForm: { setDeadlineTimeCommitment, next, prev }
  } = actions;

  const dueDateDefValue = useMemo(() => {
    return deadline &&
      deadline !== "WithoutDeadline" &&
      (deadline as BountyCreate.DueDate)?.DueDate?.due_date
      ? new Date(formatting.formatToDate((deadline as BountyCreate.DueDate).DueDate.due_date))
      : moment(Date.now()).utc(false).add(1, "d").toDate();
  }, [deadline]);

  const handleDueDateChange = (value: Date) => {
    const timeStampInNano = formatting.formatDayToNanoSec(moment(value).utc(false).toDate());
    reset({
      deadlineTimeCommitment: undefined,
      deadline: undefined
    });
    setValue("deadlineTimeCommitment", "");
    setValue("deadline.MaxDeadline.max_deadline", "");
    setDeadlineTimeCommitment("");
    setDeadline({ DueDate: { due_date: timeStampInNano.toString() } });
  };

  const handleBackClick = () => {
    prev();
  };

  const handleNextClick = async () => {
    if (typeof errors === "object" && Object.keys(errors).length === 0) {
      next();
    }
  };

  useEffect(() => {
    if (deadline === "WithoutDeadline") {
      handleDueDateChange(dueDateDefValue);
    }
  }, [deadline]);

  const onSubmit: SubmitHandler<DurationFormData> = () => handleNextClick();

  return (
    <div className="duration-form">
      <div>
        <div className="p-6">
          <FormRow className="flex items-center justify-center">
            <SimpleDatePicker
              {...{ customStyles: { datePicker: "!relative mt-0 text-md w-[20rem] h-[20rem]" } }}
              date={dueDateDefValue}
              onChange={(date: Date) => handleDueDateChange(date)}
              onClose={() => void 0}
              allowToday={false}
              allowPastDays={false}
              allowFutureDays={true}
              maxDate={
                max_due_date
                  ? moment(
                      moment().valueOf() + (max_due_date ? parseInt(max_due_date) / 1000000 : 0)
                    ).toDate()
                  : undefined
              }
            />
          </FormRow>
        </div>
      </div>

      <div className="my-6">
        <div className="flex gap-4 justify-center">
          <DarkButton
            {...{
              text: "Back",
              onClick: () => handleBackClick()
            }}
          />
          <LightButton
            {...{
              text: "Next",
              onClick: () => handleSubmit(onSubmit)(),
              type: "submit"
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Duration;
