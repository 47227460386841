import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { DarkButton } from "../../../general/Button";
import React from "react";

const ActionButton = (props: {
  text: string;
  disabled: boolean;
  handleClick: () => void;
  customStyles?: {
    button?: string;
    text?: string;
  };
}) => {
  const { disabled, text, handleClick, customStyles } = props;

  const onButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleClick();
  };

  return (
    <DarkButton
      {...{
        customStyles: {
          button: twMerge(
            classNames(
              "w-fit border-neutral-300 text-neutral-300 py-1 px-3 hover:shadow-none",
              customStyles?.button
            )
          ),
          text: twMerge(classNames("md:text-[12px]", customStyles?.text))
        },
        text,
        disabled,
        onClick: (e) => onButtonClick(e)
      }}
    />
  );
};

export default ActionButton;
