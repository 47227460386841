import { useEffect, useRef } from "react";
import { Layout } from "../../components/Layout";
import { useStoreActions } from "../../hooks";
import helpers from "../../store/models/helpers";
import { router } from "../../containers/App/router";

function KYCVerificationFractal() {
  const wasCalled = useRef(false);
  const location: Location = window.location;
  const verifyFractalCode = useStoreActions((actions) => actions.auth.verifyFractalCode);
  const code = helpers.hasCode(location.href);
  const state = helpers.hasState(location.href);

  useEffect(() => {
    if (wasCalled.current) return;
    if (code && state) {
      verifyFractalCode({ code, state, router });
      wasCalled.current = true;
    }
  }, []);

  return (
    <Layout>
      <div className="container mx-auto text-white w-full text-center">
        <p className="p-4">Fractal user verification...</p>
      </div>
    </Layout>
  );
}

export default KYCVerificationFractal;
