import classNames from "classnames";
import { RedCircleIcon } from "../../../../general/SVGIcon";
import { MouseEventHandler, ReactNode } from "react";

type ActionItemProps = {
  text: string;
  buttonProps: {
    onClick?: MouseEventHandler;
    children?: ReactNode | ReactNode[];
    disabled?: boolean;
    type?: "submit" | "button";
  };
  Icon?: React.ReactNode;
  alert?: boolean;
  className?: string;
};

const ActionItem = (props: ActionItemProps) => {
  const { text, Icon, className, buttonProps, alert } = props;

  return (
    <li className="menu-item flex items-center hover:border hover:border-neutral-700 hover:rounded-lg hover:bg-neutral-800">
      {Icon && <span className="ml-4">{Icon}</span>}
      <button
        className={classNames(
          "mx-4 py-4 flex items-center justify-between text-md flex-1",
          className
        )}
        {...buttonProps}>
        {text}
        {alert && <RedCircleIcon />}
      </button>
    </li>
  );
};

export default ActionItem;
