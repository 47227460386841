import React from "react";
import { Option } from "../Select";
import Item from "../Item";

import "./styles.css";
import { RefObject } from "react";
import { twMerge } from "tailwind-merge";
import Separator from "../../Separator";
import CheckBox from "../../CheckBox";

interface OptionsListProps {
  customStyles?: OptionListStyles;
  optionsList: Option[];
  onOptionClick: (value: string) => void;
  onKeyDown: (value: string) => void;
  onMouseOver?: (value: string) => void;
  highlightedId: number;
  highlightedIds: number[];
  refer?: RefObject<HTMLUListElement> | null;
  showCheckBox?: boolean;
}

export type OptionListStyles = {
  container?: string;
  borderContainer?: string;
  item?: string;
  contentContainer?: string;
};

const buildStyles = (customStyles: OptionListStyles | undefined) => ({
  borderContainer: twMerge(`
    absolute w-full z-50 whitespace-nowrap ${
      customStyles?.borderContainer ? customStyles.borderContainer : ""
    }
  `),
  contentContainer: twMerge(`
    relative
    rounded-b-[10px]
    bg-neutral-800
    overflow-auto
    min-w-fit
    ${customStyles?.contentContainer ? customStyles.contentContainer : ""}
  `),
  list: twMerge(`
    base-benefits-section__item-button--shadow
    options-list
    max-h-[275px]
    w-full
    rounded-b-[10px]
    mr-[-0.5em]
    overflow-hidden
    overflow-y-auto
    ${customStyles?.container ? customStyles.container : ""}
  `),
  item: twMerge(`
    ${customStyles?.item ? customStyles.item : ""}
  `)
});

const OptionList = (props: OptionsListProps) => {
  const {
    refer,
    customStyles,
    optionsList,
    onOptionClick,
    onMouseOver,
    onKeyDown,
    highlightedId,
    highlightedIds,
    showCheckbox = false
  } = { ...{ highlightedId: 0, highlightedIds: [] }, ...props };

  const styles = buildStyles(customStyles);

  const handleClick = (event: React.MouseEvent) => {
    const name = (event?.target as HTMLInputElement)?.name || event?.currentTarget?.id;
    onOptionClick && onOptionClick(name);
  };

  return (
    <div className={styles.borderContainer}>
      <div className={styles.contentContainer}>
        <ul ref={refer} className={styles.list}>
          {optionsList.map((option: Option, id: number) => {
            const highlighted =
              id === highlightedId || !!highlightedIds.filter((el) => el === id).length;
            return (
              <React.Fragment key={id}>
                {id !== 0 && <Separator />}
                <div
                  className={`${showCheckbox && "flex justify-start items-center px-4"}`}
                  key={id}>
                  {showCheckbox && (
                    <CheckBox
                      customStyles={{ container: "gap-0" }}
                      active={highlighted}
                      onClick={handleClick}
                      name={option.name}
                    />
                  )}
                  <Item
                    {...{
                      ...option,
                      id,
                      onClick: onOptionClick,
                      onMouseOver,
                      onKeyDown,
                      customStyles: {
                        container: `${styles?.item && styles.item} ${
                          id === optionsList.length - 1 ? `rounded-b-[10px]` : ""
                        }`
                      },
                      highlighted: !showCheckbox && highlighted
                    }}
                  />
                </div>
              </React.Fragment>
            );
          })}
          {optionsList.length === 0 ? (
            <>
              <Separator />
              <Item
                {...{
                  id: -1,
                  name: "message",
                  value: "Sorry, no matching options",
                  onClick: onOptionClick,
                  onMouseOver,
                  onKeyDown,
                  customStyles: { container: `rounded-b-[10px] text-center` }
                }}
              />
            </>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default OptionList;
