import { DarkButton } from "../../../../general/Button";
import Modal from "../../../../general/Modal";

export default function DeleteWhitelistForm(props: {
  show: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  const { show, handleClose, handleSubmit } = props;

  return (
    <Modal
      {...{
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                onClick: () => handleSubmit(),
                text: "Agree"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white">Delete whitelist</div>
        <div className="py-2.5 text-neutral-200">Are you sure you want to delete whitelist?</div>
      </div>
    </Modal>
  );
}
