import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import Api from "../services/Api";
import { BountyTransaction } from "../types/BountyTransaction";

export type Response = BountyTransaction[];

const useBountyTransactions = (props: { bountyId: string }) => {
  const { bountyId } = props;

  const fetchData = Api.getBountyTransactions;
  const {
    data = [],
    error,
    isFetching,
    status
  } = useQuery(
    ["items", bountyId],
    () => {
      return fetchData({
        bountyId
      }).then((res: Response) => {
        return res;
      });
    },
    {}
  );

  return {
    data: [...data].sort((a, b) => moment(b.txDate).valueOf() - moment(a.txDate).valueOf()),
    error,
    status,
    isFetching
  };
};

export default useBountyTransactions;
