import { Section } from "../../Layout";
import { ItemProps, Item } from "./Item";

interface BaseSectionProps {
  id: string;
  title: string;
  items: ItemProps[];
}

function BaseSection(props: BaseSectionProps) {
  const { id, title, items } = props;

  return (
    <Section {...{ id, title }}>
      <div
        className="
        w-full
        flex flex-col justify-between
        md:flex-row
      ">
        {items.map((item, index) => (
          <Item key={item.title} {...({ ...item, imageId: index + 1 } as ItemProps)} />
        ))}
      </div>
    </Section>
  );
}

export { BaseSection, type BaseSectionProps };
