import { useState } from "react";
import { useStoreActions } from "../../../hooks";
import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import {
  DeclineHeroForm,
  FinalExecutionBatchTxnsForm,
  FinalExecutionOutcomeForm
} from "../Dialogues";
import ActionButton from "./general/ActionButton";
import { BatchExecutionOutcome } from "../../../types/NearApi";
import Api from "../../../services/Api";
import { FinalExecutionOutcome } from "@near-wallet-selector/core";

const DeclineSelectedAction = (props: {
  claim: Claim[];
  bounty: Bounty;
  text?: string;
  refetchActions: () => void;
}) => {
  const { claim, bounty, text = "Decline", refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [showBatchExecutionOutcomeForm, setShowBatchExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<
    BatchExecutionOutcome | FinalExecutionOutcome | null
  >(null);
  const setDeclineClaimant = useStoreActions((actions) => actions.nearApi.setDeclineClaimant);
  const [loading, setLoading] = useState(false);

  const getBatchTxnsStatus = Api.getBatchTxnsStatus;

  const fetchStatus = async () => {
    const status = await getBatchTxnsStatus({
      id: (executionOutcome as BatchExecutionOutcome)?.id ?? ""
    });
    if (status) {
      setExecutionOutcome(status);
    }
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const result = await setDeclineClaimant(
      claim.map((item) => {
        return {
          id: bounty.id,
          receiver_id: item.owner,
          claim_number: item.claim_number
        };
      })
    );
    setLoading(false);
    console.log(result);
    if (result) {
      if ("batch" in result) {
        setShowForm(!showForm);
        setExecutionOutcome(result as BatchExecutionOutcome);
        setShowBatchExecutionOutcomeForm(!showBatchExecutionOutcomeForm);
      } else {
        setShowForm(!showForm);
        setExecutionOutcome(result as FinalExecutionOutcome);
        setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
      }
    }
  };

  return (
    <>
      <ActionButton disabled={false} handleClick={handleButtonClick} text={text} />
      <DeclineHeroForm
        show={showForm}
        loading={loading}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome as FinalExecutionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
      <FinalExecutionBatchTxnsForm
        show={showBatchExecutionOutcomeForm}
        executionOutcome={executionOutcome as BatchExecutionOutcome}
        refetchActions={refetchActions}
        fetchStatus={fetchStatus}
        handleClose={() => setShowBatchExecutionOutcomeForm(!showBatchExecutionOutcomeForm)}
      />
    </>
  );
};

export default DeclineSelectedAction;
