import { useState } from "react";
import IconButton from "../../../../../general/Button/IconButton";
import {
  BigChatIcon,
  BigClockIcon,
  BigRunButton,
  ChevronLeftIcon
} from "../../../../../general/SVGIcon";

const SuccessSteps = () => {
  const [selectedStep, setSelectedStep] = useState<number>(0);

  const prevStep = () => {
    setSelectedStep(selectedStep - 1);
  };

  const nextStep = () => {
    setSelectedStep(selectedStep + 1);
  };

  switch (selectedStep) {
    case 0:
      return (
        <div className="flex flex-1 justify-between items-center">
          <div className="w-[24px]" />
          <div className="flex-1 flex flex-col items-center">
            <div className="my-2.5">
              <BigClockIcon />
            </div>
            <p className="tet-md text-neutral-50 mt-[2rem] px-2.5">
              Wait for your application to be accepted by bounty funder
            </p>
          </div>
          <div>
            <IconButton
              {...{
                icon: <ChevronLeftIcon className="rotate-180" />,
                onClick: () => nextStep()
              }}
            />
          </div>
        </div>
      );
    case 1:
      return (
        <div className="flex flex-1 justify-between items-center">
          <div className="w-[24px]">
            <IconButton
              {...{
                icon: <ChevronLeftIcon />,
                onClick: () => prevStep()
              }}
            />
          </div>
          <div className="flex-1 flex flex-col items-center">
            <div className="my-2.5">
              <BigChatIcon />
            </div>
            <p className="tet-md text-neutral-50 mt-[2rem] px-2.5">
              Ask the funder any question in the chat log if needed
            </p>
          </div>
          <div>
            <IconButton
              {...{
                icon: <ChevronLeftIcon className="rotate-180" />,
                onClick: () => nextStep()
              }}
            />
          </div>
        </div>
      );
    case 2:
      return (
        <div className="flex flex-1 justify-between items-center">
          <div className="w-[24px]">
            <IconButton
              {...{
                icon: <ChevronLeftIcon />,
                onClick: () => prevStep()
              }}
            />
          </div>
          <div className="flex-1 flex flex-col items-center">
            <div className="my-2.5">
              <BigRunButton />
            </div>
            <p className="tet-md text-neutral-50 mt-[2rem] px-2.5">
              Once accepted, you can start working!
            </p>
          </div>
          <div className="w-[24px]">
            <IconButton />
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default SuccessSteps;
