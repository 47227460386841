import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useStoreState } from "../../../../../hooks";
import useFtTokenBalance from "../../../../../hooks/useFtTokenBalance";
import utils from "../../../../../services/utils";
import { TokenData } from "../../../../../types/App";
import { Bounty } from "../../../../../types/Bounty";
import { FormControl, FormRow } from "../../../../general/Form/FormElements";
import { Input } from "../../../../general/Input";
import { Area } from "../../../../Layout";
import Separator from "../../../../general/Separator";
import { PaymentFormData } from "../../../Actions/SendMoneyAction";

const CurrencyForm = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  const methods = useFormContext<PaymentFormData>();
  const {
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitted }
  } = methods;

  const tokens = useStoreState((state) => state.app.tokens);

  const bountyToken = useMemo(() => {
    const token: TokenData | undefined = tokens.find((token) => token.tokenId === bounty.token);
    return {
      name: token?.tokenId ?? "",
      value: token?.symbol ?? "",
      symbol: token?.symbol ?? "",
      decimals: token?.decimals ?? 0
    };
  }, [tokens, bounty.token]);

  const { availableBalance } = useFtTokenBalance({ tokenId: bountyToken.name });
  const { walletSelector } = useStoreState((state) => state.nearApi);

  const nearblocksURL = () => {
    const { options } = walletSelector ?? {};
    return options?.network.networkId === "testnet"
      ? "https://testnet.nearblocks.io/address/"
      : "https://nearblocks.io/address/";
  };

  const [payment, setPayment] = useState(getValues("payment") ?? 0);

  const formattedBalance = useMemo(() => {
    return availableBalance ? utils.formatToken(availableBalance, bountyToken.decimals) : 0;
  }, [availableBalance, bountyToken]);

  const parsedBalance = useMemo(() => {
    return availableBalance
      ? utils.formatTokenAmount(availableBalance, bountyToken.decimals, 0)
      : 0;
  }, [availableBalance, bountyToken]);

  const parsedAmount = useMemo(() => {
    return payment ? utils.formatTokenAmount(payment, bountyToken.decimals, 0) : 0;
  }, [payment, bountyToken]);

  const validMaxAmount = useMemo(() => {
    return parseInt(parsedBalance.toString()) >= parseInt(parsedAmount.toString());
  }, [parsedBalance, parsedAmount]);

  const handlePaymentChange = (value: string) => {
    setValue("payment", value, { shouldValidate: true, shouldDirty: true });
    setPayment(value);
  };

  return (
    <Area>
      <FormRow>
        <div className="flex flex-col flex-wrap gap-4">
          <div>
            Bounty price (paid in{" "}
            <span className="text-primary-900 underline">
              <a
                href={`${nearblocksURL()}${bountyToken?.name}`}
                target="_blank"
                rel="noopener noreferrer">
                {bountyToken?.value}
              </a>
            </span>
            )
          </div>
          <div>
            {bountyToken?.value && (
              <span className="px-2 text-sm text-neutral-400">
                Available balance: {formattedBalance}
              </span>
            )}
          </div>
        </div>
      </FormRow>
      <FormRow className="w-full">
        <FormControl
          name="payment"
          control={control}
          defaultValue={payment}
          rules={{
            required: "Required field",
            validate: {
              minAmount: (value: number) => value > 0 || "is not equal to 0",
              maxAmount: () => validMaxAmount || "the balance is not enough"
            }
          }}
          render={({ field: { name, value, onChange } }) => {
            return (
              <Input
                {...{
                  customStyles: {
                    input: "px-[20px] py-[15px] text-right"
                  },
                  name: name,
                  value: value,
                  validation: {
                    valid: !errors.payment,
                    message: errors.payment?.message,
                    started: isSubmitted
                  },
                  onChange: (value: string) => {
                    handlePaymentChange(value);
                    onChange(value);
                  },
                  disabled: !bountyToken
                }}
                {...{ name, value }}
              />
            );
          }}
        />
      </FormRow>
      <div className="flex flex-col w-full">
        <Separator customStyles={{ container: "py-4" }} />
      </div>
      <FormRow>
        <div className="flex justify-between">
          <div className="text-sm text-neutral-200">Total</div>
          <div className="text-sm text-neutral-50 uppercase">
            {utils.formatWithCommas(payment.toString())} {bountyToken?.value}
          </div>
        </div>
      </FormRow>
    </Area>
  );
};

export default CurrencyForm;
