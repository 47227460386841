import BountyListItem from "../BountyListItem";
import { Response } from "../../../hooks/useBounties";
import { Bounty } from "../../../types/Bounty";
import InfiniteScroll from "react-infinite-scroll-component";
import { InfiniteData, InfiniteQueryObserverResult } from "@tanstack/react-query";

const InfiniteBountyList = ({
  data,
  fetchNextPage,
  hasNextPage
}: {
  data?: InfiniteData<Response>;
  fetchNextPage: () => Promise<InfiniteQueryObserverResult<Response, unknown>>;
  hasNextPage?: boolean;
}) => {
  return (
    <>
      <InfiniteScroll
        dataLength={data?.pages ? data.pages.length : 0}
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        scrollThreshold={1}
        loader={
          <div className="flex items-center justify-center py-2.5">
            <span className="dot-pulse" />
          </div>
        }
        endMessage={
          <p className="text-sm text-primary-900 p-4 text-center">
            {data && data.pages?.length && data.pages[0].page_count ? (
              <b>Yay! You have seen it all</b>
            ) : (
              <></>
            )}
          </p>
        }>
        {data &&
          data.pages.length > 0 &&
          data.pages.map((page) =>
            page.bounties.map((bounty: Bounty, index) => (
              <>
                <BountyListItem key={`bounty-${bounty.id}-item-${index}`} bounty={bounty} />
              </>
            ))
          )}
      </InfiniteScroll>
    </>
  );
};

export default InfiniteBountyList;
