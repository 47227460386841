import { DarkButton, LightButton } from "../../../general/Button";
import Modal from "../../../general/Modal";

export default function NotWhitelistedForm(props: {
  show: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  const { show, handleClose, handleSubmit } = props;

  return (
    <Modal
      {...{
        customStyles: {
          modal: "min-h-[400px]",
          content: "h-fit"
        },
        open: show,
        onClose: handleClose
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white">You have not completed KYC verification!</div>
        <div className="py-2.5 text-neutral-200">Please complete your KYC to continue.</div>
        <div className="flex gap-4 justify-between mt-8">
          <DarkButton
            {...{
              customStyles: { button: "max-w-[146px]" },
              onClick: () => handleClose(),
              text: "Close"
            }}
          />
          <LightButton
            {...{
              onClick: () => handleSubmit(),
              text: "Start verification"
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
