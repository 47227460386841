import { useRouteError } from "react-router-dom";

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <div className="container mx-auto text-white w-full text-center">
      <p className="p-4">
        The data was not loaded due to an error. Please check your internet connection and try
        again.
      </p>
    </div>
  );
};

export default ErrorBoundary;
