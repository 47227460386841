import { useState } from "react";
import { format } from "date-fns";

import { useOutsideClick } from "../../../hooks";
import SvgIcon from "./SvgIcon";
import { Input } from "../Input";
import { twMerge } from "tailwind-merge";
import ExpandedDatePicker from "./ExpandedDatePicker";
import classNames from "classnames";

interface DatePickerProps {
  date: Date;
  onChange: (value: Date) => void;
  disabled?: boolean;
  allowToday?: boolean;
  allowPastDays?: boolean;
  allowFutureDays?: boolean;
  useMaxDate?: Date | null;
}

export default function DatePicker(props: DatePickerProps) {
  const {
    date,
    onChange,
    disabled,
    allowToday = true,
    allowFutureDays = true,
    allowPastDays = false,
    useMaxDate
  } = props;
  const [showDatepicker, setShowDatepicker] = useState(false);

  const toggleDatepicker = () => {
    !disabled && setShowDatepicker(!showDatepicker);
  };

  const styles = {
    input: twMerge(
      classNames("focus:outline-none focus:shadow-outline pl-12", {
        "-cursor-pointer": disabled,
        "!cursor-pointer": !disabled
      })
    )
  };

  function handleClickOutside() {
    setShowDatepicker(false);
  }
  const outsideClickRef = useOutsideClick<HTMLDivElement>(handleClickOutside);

  return (
    <div ref={outsideClickRef} className="w-full font-poppins">
      <div className="relative" onClick={toggleDatepicker}>
        <Input
          placeholder="dd-mm-yyyy"
          customStyles={{ input: styles.input }}
          type="text"
          readOnly={true}
          value={format(date, "dd-MM-yyyy")}
          svgIcon={
            <SvgIcon
              {...{
                name: "calendar",
                customStyle: classNames("", {
                  "text-neutral-500 cursor-not-allowed": disabled,
                  "cursor-pointer": !disabled
                })
              }}
            />
          }
          disabled={disabled}
        />
        {showDatepicker && (
          <ExpandedDatePicker
            {...{
              date,
              onChange,
              allowToday,
              allowFutureDays,
              allowPastDays,
              useMaxDate,
              onClose: () => setShowDatepicker(false)
            }}
          />
        )}
      </div>
    </div>
  );
}
