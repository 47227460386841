import { useEffect, useState } from "react";
import { useStoreActions } from ".";
import useWalletAccountId from "./useWalletAccountId";

const useBountyOwnerWhitelisted = () => {
  const { walletAccountId: accountId } = useWalletAccountId();
  const [isOwnerWhitelisted, setIsOwnerWhitelisted] = useState(false);
  const checkIsOwnerWhitelisted = useStoreActions(
    (actions) => actions.nearApi.checkOwnerWhitelisted
  );
  useEffect(() => {
    (async () => {
      if (accountId) {
        const checkOwner = await checkIsOwnerWhitelisted({ accountId });
        setIsOwnerWhitelisted(checkOwner);
      }
    })();
  }, [accountId]);

  return { isOwnerWhitelisted };
};

export default useBountyOwnerWhitelisted;
