import moment from "moment";
import { useFormContext } from "react-hook-form";
import { AdditionalInfoFormData } from "../../../AdditionalInfo";
import { useStoreActions, useStoreState } from "../../../../../../../hooks";
import { AllowedDate, ContestOrHackathon, DueDate } from "../../../../../../../types/BountyCreate";
import { formatting } from "../../../../../../../common/formatting";
import { FormRow } from "../../../../../../general/Form/FormElements";
import { ValidationLayout } from "../../../../../../Layout";
import DatePicker from "../../../../../../general/DatePicker";

const AllowedCreateClaimToDate = () => {
  const methods = useFormContext<AdditionalInfoFormData>();
  const { register, formState, setValue } = methods;
  const { errors, isSubmitted } = formState;
  const setAllowedCreateClaimToDate = useStoreActions(
    (actions) => actions.newBounty.setAllowedCreateClaimToDate
  );

  const allowed_create_claim_to = useStoreState(
    (state) =>
      (state.newBounty.multitasking as ContestOrHackathon)?.ContestOrHackathon
        ?.allowed_create_claim_to
  );
  const deadline = useStoreState((state) => state.newBounty.deadline as DueDate);

  const dateDefValue = (allowed_create_claim_to as AllowedDate)?.Date?.date
    ? new Date(formatting.formatToDate((allowed_create_claim_to as AllowedDate)?.Date?.date))
    : new Date();

  const handleDateChange = (value: Date) => {
    const timeStampInNano = formatting.formatDayToNanoSec(moment(value).utc(false).toDate());
    setAllowedCreateClaimToDate({ Date: { date: timeStampInNano.toString() } });
    setValue(
      "multitasking.ContestOrHackathon.allowed_create_claim_to.Date.date",
      timeStampInNano.toString()
    );
  };

  const isCorrectDate = () => {
    return !deadline?.DueDate?.due_date
      ? true
      : parseInt((allowed_create_claim_to as AllowedDate)?.Date?.date) <
        parseInt(deadline?.DueDate.due_date)
      ? true
      : false;
  };

  return (
    <FormRow>
      <input
        type="hidden"
        defaultValue={dateDefValue.getUTCDate()}
        {...register("multitasking.ContestOrHackathon.allowed_create_claim_to.Date.date", {
          required: "Required field",
          validate: {
            maxDate: () => isCorrectDate() || "Must be less than the bounty deadline"
          }
        })}
      />
      <ValidationLayout
        {...{
          validate: () => {
            return {
              valid: !(errors.multitasking as any)?.ContestOrHackathon?.allowed_create_claim_to.Date
                .date,
              message: (errors.multitasking as any)?.ContestOrHackathon?.allowed_create_claim_to
                .Date.date?.message,
              started: isSubmitted
            };
          }
        }}>
        <DatePicker
          {...{
            date: dateDefValue,
            onChange: (date: Date) => handleDateChange(date)
          }}
        />
      </ValidationLayout>
    </FormRow>
  );
};

export default AllowedCreateClaimToDate;
