import { AuthProvider, getAuthorizeUrl } from "../../../services/newAuth";

const getAuthUrl = async (providerType: AuthProvider) => {
  const authorizeUrl = await getAuthorizeUrl(providerType);
  if (authorizeUrl?.url) {
    return authorizeUrl.url;
  }
  return null;
};

export { getAuthUrl };
