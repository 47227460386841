import { connect, WalletConnection } from "near-api-js";
import { nearConfig } from "./nearConfig";

export const getNearApi = async () => {
  const near = await connect({
    ...nearConfig
  });

  const walletConnection = new WalletConnection(near, "heroes");

  return {
    near,
    walletConnection
  };
};
