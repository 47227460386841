import { useState } from "react";
import { DarkButton } from "../../../general/Button";
import CheckBox from "../../../general/CheckBox";
import Modal from "../../../general/Modal";
import DatePicker from "../../../general/DatePicker";
import { downloadCSVFile } from "../../../../utils";
import { Api } from "../../../../services/profileApi";
import { getFileName } from "../../general/helpers";
import { useStoreActions } from "../../../../hooks";
import useWalletAccountId from "../../../../hooks/useWalletAccountId";

type MethodType =
  | "bountyOwnerOutgoingTransactions"
  | "bountyOwnerIngoingTransactions"
  | "claimerIngoingTransactions";

const ExportTxns = (props: { show: boolean; handleClose: () => void; disabled: boolean }) => {
  const { show, handleClose, disabled } = props;
  const setError = useStoreActions((actions) => actions.error.setError);
  const [methodType, setMethodType] = useState<MethodType>("bountyOwnerOutgoingTransactions");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const { walletAccountId } = useWalletAccountId();

  const handleSubmitClick = async () => {
    setLoading(true);
    const response = await Api.getTransactions(
      walletAccountId,
      methodType,
      startDate.valueOf().toString(),
      endDate.valueOf().toString()
    );
    setLoading(false);
    if (response?.success) {
      const fileName = response.success.headers["content-disposition"]
        ? getFileName(response.success.headers["content-disposition"])
        : "transactions.csv";
      const file = new File([response.success.data], fileName);
      downloadCSVFile(file);
      //   if (!DEV_MODE) {
      //     mixpanel.track("Invoice Download", {
      //       source: window.location.hostname
      //     });
      //   }
    } else {
      setError("Transactions download error!");
    }
  };

  const handleSetMethodType = (type: MethodType) => {
    setMethodType(type);
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                disabled: loading,
                onClick: () => handleSubmitClick(),
                text: "Submit"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white mb-2.5">Export transactions</div>
        <>
          <div className="py-2.5">
            <p className="text-sm text-neutral-400">
              If you wish to export transactions, you should choose a specific time period and
              specify the transaction type as either incoming or outgoing.
            </p>
            <div className="flex py-4 gap-2">
              <div>
                <p className="text-sm text-neutral-600">Date From</p>
                <DatePicker
                  {...{
                    label: { text: "Start date*" },
                    disabled: false,
                    date: startDate,
                    allowToday: true,
                    allowPastDays: true,
                    allowFutureDays: false,
                    onChange: (date: Date) => handleStartDateChange(date)
                  }}
                />
              </div>
              <div>
                <p className="text-sm text-neutral-600">Date To</p>
                <DatePicker
                  {...{
                    label: { text: "End date*" },
                    disabled: false,
                    date: endDate,
                    allowPastDays: true,
                    allowFutureDays: false,
                    onChange: (date: Date) => handleEndDateChange(date)
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4 py-2.5">
              <p className="text-neutral-50">Chose transaction type</p>
              <CheckBox
                {...{
                  text: "Bounty owner Outgoing",
                  active: methodType === "bountyOwnerOutgoingTransactions",
                  onClick: () => handleSetMethodType("bountyOwnerOutgoingTransactions")
                }}
              />
              <CheckBox
                {...{
                  text: "Bounty owner Incoming",
                  active: methodType === "bountyOwnerIngoingTransactions",
                  onClick: () => handleSetMethodType("bountyOwnerIngoingTransactions")
                }}
              />
              <CheckBox
                {...{
                  text: "Claimer Incoming",
                  active: methodType === "claimerIngoingTransactions",
                  onClick: () => handleSetMethodType("claimerIngoingTransactions")
                }}
              />
            </div>
          </div>
        </>
      </div>
    </Modal>
  );
};

export default ExportTxns;
