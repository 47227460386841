import { action, computed, thunk } from "easy-peasy";
import { AppStore } from "../../types";
import helpers from "./helpers";
import { AppMessages } from "../../common/app-messages";

const appModel: AppStore.Model = {
  config: null,
  validatorDAO: null,
  appLoading: false,
  tokens: [],
  currencies: [],
  skills: [],
  countries: {},
  messages: {
    doneSuccessMessage: false
  },
  mapCountries: computed((state) => {
    const countries = state.countries || {};
    return Object.keys(countries).length > 0
      ? Object.keys(countries).map((key) => {
          return { name: key, value: countries[key] };
        })
      : [];
  })
};

const appActions: AppStore.Actions = {
  setSkills: action((state, payload) => {
    state.skills = payload;
  }),
  startLoading: action((state) => {
    state.appLoading = true;
  }),
  stopLoading: action((state) => {
    state.appLoading = false;
  }),
  setAppConfig: action((state, payload) => {
    state.config = payload;
  }),
  setTokens: action((state, payload) => {
    state.tokens = payload;
  }),
  setValidatorDAO: action((state, payload) => {
    state.validatorDAO = payload;
  }),
  setCountries: action((state, payload) => {
    state.countries = payload;
  }),
  setCurrencies: action((state, payload) => {
    state.currencies = payload;
  }),
  setDoneSuccessMessage: action((state, payload) => {
    state.messages = payload;
  })
};

const appThunks: AppStore.Thunks = {
  getAppConfig: thunk(async (actions, _, services) => {
    try {
      const getAppConfig = services.injections.apiService.Api.getAppConfig;

      const config = await getAppConfig();
      if (config) {
        actions.setAppConfig(config);
      }
    } catch (error) {
      console.log(error);
    }
  }),
  getValidatorDAO: thunk(async (actions, _, services) => {
    try {
      const getValidatorDAO = services.injections.apiService.Api.getValidatorDAO;

      const daoConfig = await getValidatorDAO();
      if (!daoConfig?.error) {
        actions.setValidatorDAO(daoConfig);
      }
    } catch (error) {
      console.log(error);
    }
  }),
  getSkills: thunk(async (actions, _, services) => {
    try {
      const getSkills = services.injections.apiService.Api.getSkills;

      const skills = await getSkills();
      if (!skills?.error) {
        actions.setSkills(skills);
      }
    } catch (error) {
      console.log(error);
    }
  }),
  getCountries: thunk(async (actions, _, services) => {
    try {
      const getCountries = services.injections.apiService.Api.getCountries;

      const result = await getCountries();
      if (result) {
        actions.setCountries(result);
      }
    } catch (error) {
      console.log(error);
    }
  }),
  getTokens: thunk(async (actions, _, services) => {
    const getTokens = services.injections.apiService.Api.getTokens;
    try {
      const tokens = await getTokens();
      actions.setTokens(tokens);
    } catch (error) {
      console.log(error);
    }
  }),
  getCurrencies: thunk(async (actions, _, services) => {
    const getCurrencies = services.injections.apiService.Api.getCurrencies;
    try {
      const currencies = await getCurrencies();
      actions.setCurrencies(currencies);
    } catch (error) {
      console.log(error);
    }
  }),
  redirectHooks: thunk((actions, payload, services) => {
    const { history, location } = payload;
    const storeActions = services.getStoreActions();
    const storeState = services.getStoreState();
    const {
      app: { setDoneSuccessMessage },
      nearApi: { setTransactionHashes, setClaimStatus },
      newBountyForm: { setCreateBountyStatus }
    } = storeActions;
    const postAfterCreatingTx = services.injections.apiService.Api.postAfterCreatingTx;

    const redirectHasError = helpers.hasError(location.href);
    const userRejected = helpers.isRejected(location.href);
    const redirectHasMeta = helpers.hasMeta(location.href);
    const historyReplace = () => history.replace(`${location.origin}${location.pathname}`);

    if (redirectHasError) {
      if (userRejected) {
        historyReplace();
      } else if (helpers.isSomeError(location.href)) {
        const message = helpers.getErrorMessage(location.href);
        if (message) {
          const { options } = storeState.nearApi.walletSelector ?? {};
          const nearBlocksUrl =
            options?.network.networkId === "testnet"
              ? `https://testnet.nearblocks.io/address/${storeState.app.config?.bounty_contract_id}`
              : `https://nearblocks.io/address/${storeState.app.config?.bounty_contract_id}`;
          storeActions.error.setError(<AppMessages.VisitNearblocksSiteMessage />);
          historyReplace();
        }
      }
    } else if (redirectHasMeta) {
      const transactionHashes = helpers.getTxHashesFromUrl(location.href);
      if (transactionHashes) {
        let useInvoice: boolean | undefined = false;
        if (redirectHasMeta === "createdSuccessfully") {
          if (storeState.newBountyForm.createBountyStatus === "New") {
            const generateInvoice = storeState.newBountyForm.generateInvoice;
            useInvoice = generateInvoice === true ? generateInvoice : undefined;
            setTransactionHashes(transactionHashes);
            setCreateBountyStatus("CheckReceipt");
          }
        }
        if (redirectHasMeta === "bountyDoneSuccessfully") {
          setDoneSuccessMessage({ doneSuccessMessage: true });
        }
        if (redirectHasMeta === "claimedSuccessfully") {
          setTransactionHashes(transactionHashes);
          setClaimStatus("CheckReceipt");
        }

        if (["bountyUpdatedSuccessfully", "proposalAddedSuccessfully"].includes(redirectHasMeta)) {
          storeActions.resetState();
        }
        postAfterCreatingTx(transactionHashes, useInvoice === true ? useInvoice : undefined);
        historyReplace();
      } else {
        console.log("redirectHasMeta", redirectHasMeta);
      }
    }
  }),
  onInit: thunk(async (_, payload, services) => {
    const { history, setLoading, router } = payload;
    const storeActions = services.getStoreActions();
    const storeState = services.getStoreState();
    const {
      error: { clearError },
      app: { getTokens, getAppConfig, getValidatorDAO, getSkills, getCountries, getCurrencies },
      auth: {
        verifyTokenAsync,
        verifyRefferalLinkCodeAsync,
        loginWithGithubAsync,
        loginWithGoogleAsync,
        loginWithTwitterAsync
      }
    } = storeActions;
    const {
      auth: { isAuthenticated, callbackUrl },
      error: { isError, message }
    } = storeState;

    if (isError && message) {
      clearError();
    }

    if (isAuthenticated) {
      await verifyTokenAsync({ silentAuth: true });
    }

    getAppConfig();

    const location: Location = window.location;

    const urlOauthToken = helpers.hasOauthToken(location.href);
    const urlOauthVerifier = helpers.hasOauthVerifier(location.href);
    if (urlOauthToken && urlOauthVerifier) {
      if (location.pathname === "/auth/twitter/callback") {
        await loginWithTwitterAsync({
          oauth_token: urlOauthToken,
          oauth_verifier: urlOauthVerifier,
          router,
          callbackUrl
        });
      }
    }

    const urlQueryCodeParam = helpers.hasCode(location.href);
    if (urlQueryCodeParam) {
      if (location.pathname === "/auth/github/callback") {
        const state = helpers.hasState(location.href);
        await loginWithGithubAsync({ code: urlQueryCodeParam, state, router, callbackUrl });
      } else if (location.pathname === "/auth/google/callback") {
        const state = helpers.hasState(location.href);
        await loginWithGoogleAsync({ code: urlQueryCodeParam, state, router, callbackUrl });
      } else if (location.pathname === "/") {
        const refferalLinkCode = helpers.hasCode(location.href);
        if (storeState.app.config?.use_verification_code) {
          await verifyRefferalLinkCodeAsync({ refferalLinkCode });
          history.push(`${location.origin}${location.pathname}`);
        }
      } else if (location.pathname === "/fractal-user-verification") {
        //history.push(location.href);
      } else {
        history.push(`${location.origin}${location.pathname}`);
      }
    }

    storeActions.app.redirectHooks({ history, location });

    getValidatorDAO();
    getTokens();
    getSkills();
    getCountries();
    getCurrencies();
    setLoading(true);
  }),
  uploadAttachments: thunk(async (actions, payload, services) => {
    const uploadAttachments = services.injections.apiService.Api.uploadAttachments;
    const storeActions = services.getStoreActions();
    const setError = storeActions.error.setError;
    try {
      const response = await uploadAttachments(payload);
      if (!response.error) {
        return response;
      } else {
        setError(response.message);
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  })
};

const app: AppStore.Model & AppStore.Actions & AppStore.Thunks = {
  ...appModel,
  ...appActions,
  ...appThunks
};

export default app;
