import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { formatting } from "../../../common/formatting";

const DaysFromNow = (props: { date: string; className?: string }) => {
  const { date, className } = props;
  return (
    <span className={twMerge(classNames("text-sm text-neutral-500", className))}>
      {formatting.formatDayFromNow(date)}
    </span>
  );
};

export default DaysFromNow;
