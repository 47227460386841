import useWalletAccountId from "../../../hooks/useWalletAccountId";
import { Bounty, NextActionModel } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { Action } from "../Actions";
import { NextActionProps } from "../../../hooks/useNextActions";

const ClaimActions = (props: {
  bounty: Bounty;
  claim: Claim;
  isWhitelisted: boolean;
  nextActions: NextActionProps;
  refetchActions: () => void;
}) => {
  const { bounty, claim, nextActions, refetchActions, isWhitelisted } = props;
  const { walletAccountId } = useWalletAccountId();

  const getAvailableUserActions = () => {
    return (nextActions as Partial<NextActionModel>[] | null)?.find(
      (item) => item.accountId === walletAccountId
    )?.forClaims;
  };

  const getAvailableActionsForClaim = () => {
    return (
      getAvailableUserActions()?.find(
        (item) => item.accountId === claim.owner && item.claimNumber === claim.claim_number
      )?.nextActions ?? {}
    );
  };

  return (
    <>
      <div className="flex items-baseline justify-end gap-3">
        {Object.entries(getAvailableActionsForClaim()).map(([key, _]) => (
          <>
            {key === "acceptClaimant" && (
              <>
                <Action.AcceptAction
                  key={"AcceptAction"}
                  claim={claim}
                  bounty={bounty}
                  isWhitelisted={isWhitelisted}
                />
              </>
            )}
            {key === "declineClaimant" && (
              <>
                <Action.DeclineAction
                  key={"DeclineAction"}
                  claim={claim}
                  bounty={bounty}
                  refetchActions={refetchActions}
                />
              </>
            )}
            {key === "bountyDone" && (
              <>
                <Action.DoneAction
                  key={"AcceptAction"}
                  claim={claim}
                  bounty={bounty}
                  refetchActions={refetchActions}
                />
              </>
            )}
            {key === "finalizeClaim" && (
              <>
                <Action.FinalizeAction
                  key={"FinalizeAction"}
                  claim={claim}
                  bounty={bounty}
                  refetchActions={refetchActions}
                />
              </>
            )}
            {key === "markAsPaid" && (
              <>
                <Action.MarkAsPaidAction
                  key={"MarkAsPaidAction"}
                  claim={claim}
                  bounty={bounty}
                  refetchActions={refetchActions}
                />
              </>
            )}
            {key === "bountyApprove" && (
              <>
                <Action.ApproveAction key={"ApproveAction"} claim={claim} bounty={bounty} />
              </>
            )}
            {key === "bountyReject" && (
              <>
                <Action.RejectAction
                  key={"RejectAction"}
                  claim={claim}
                  bounty={bounty}
                  refetchActions={refetchActions}
                />
              </>
            )}
            {key === "bountyGiveUp" && (
              <>
                <Action.GiveUpAction
                  key={"GiveUpAction"}
                  claim={claim}
                  bounty={bounty}
                  refetchActions={refetchActions}
                />
              </>
            )}
            {key === "confirmPayment" && (
              <>
                <Action.ConfirmPaymentAction
                  key={"ConfirmPaymentAction"}
                  claim={claim}
                  bounty={bounty}
                  refetchActions={refetchActions}
                />
              </>
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default ClaimActions;
