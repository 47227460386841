import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ForgotPasswordForm from "../../components/Form/ForgotPasswordForm";
import { Layout, Section } from "../../components/Layout";
import { useStoreActions } from "../../hooks";

const ForgotPassword = () => {
  const methods = useForm({ mode: "all" });
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);

  const sendForgotPassword = useStoreActions(
    (actions) => actions.auth.sendForgotPassword
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setForgotPasswordError("");
    }, 10000);

    return () => clearTimeout(timer);
  });

  const onSubmit = async (form: { email: string }) => {
    const result = await sendForgotPassword(form);
    if (result.error) {
      setForgotPasswordError(result.error);
    }
    if (result.success) {
      setForgotPasswordSuccess(true);
    }
  };

  return (
    <Layout>
      <div className="p-3" />
      <Section
        {...{
          id: "reset-pass_section",
        }}
      >
        <FormProvider {...methods}>
          <ForgotPasswordForm
            {...{
              onSubmit,
              errorMessage: forgotPasswordError,
              successMessage: forgotPasswordSuccess,
            }}
          />
        </FormProvider>
      </Section>
    </Layout>
  );
};

export default ForgotPassword;
