import { DetailedHTMLProps, TextareaHTMLAttributes } from "react";
import Input, { InputProps } from './Input'

const InputElement = (props:DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>) => (<textarea {...props}></textarea>)

const TextArea = (props: InputProps) => (
  <Input {...{InputElement, ...props}}/>
)

export default TextArea
