import { useState } from "react";
import { DEV_MODE } from "../../../constants";
import { useStoreActions } from "../../../hooks";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import utils from "../../../services/utils";
import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { LightButton } from "../../general/Button";
import Modal from "../../general/Modal";
import ActionButton from "./general/ActionButton";
import mixpanel from "mixpanel-browser";
import { FormRow } from "../../general/Form/FormElements";
import { NewSelect } from "../../general/Select";
import { Option } from "../../general/Select/NewSelect";
import { FinalExecutionOutcomeForm } from "../Dialogues";
import { FinalExecutionOutcome } from "@near-wallet-selector/core";

export default function GiveUpAction(props: {
  claim: Claim;
  bounty: Bounty;
  refetchActions?: () => void;
}) {
  const { claim, bounty, refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<FinalExecutionOutcome | null>(null);
  const [giveUpReason, setGiveUpReason] = useState<string>();

  const { walletAccountId } = useWalletAccountId();
  const setBountyGiveUpV2 = useStoreActions((actions) => actions.nearApi.setBountyGiveUpV2);
  const [loading, setLoading] = useState(false);

  const giveupReasons = [
    { name: "I have another job", value: "I have another job" },
    { name: "I don`t have enough capacity", value: "I don`t have enough capacity" },
    {
      name: "This bounty is too hard for me to solve",
      value: "This bounty is too hard for me to solve"
    },
    { name: "I`m not interested anymore", value: "I`m not interested anymore" },
    { name: "Deadline is to close", value: "Deadline is to close" }
  ];

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, claim, walletAccountId);
    return a.checkAccess([!a.isClaimer()]);
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    if (!DEV_MODE) {
      mixpanel.track("Give Up", {
        source: window.location.hostname
      });
    }
    setLoading(true);
    const result = await setBountyGiveUpV2({
      id: bounty.id,
      claim_number: claim.claim_number,
      giveUpReason
    });
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
    }
  };

  return (
    <>
      <ActionButton disabled={isDisabled()} handleClick={handleButtonClick} text={"Give Up"} />
      <Modal
        {...{
          customStyles: { content: "h-fit" },
          open: showForm,
          onClose: handleButtonClick,
          okButton: () => {
            return (
              <LightButton
                {...{
                  disabled: loading,
                  customStyles: { button: "max-w-[146px]" },
                  onClick: () => handleSubmitForm(),
                  text: !loading ? "Give Up" : "Submitting..."
                }}
              />
            );
          }
        }}>
        <FormRow className="font-poppins">
          <h2 className="text-lg text-center text-neutral-50">
            Are you sure you want to give up to this bounty?
          </h2>
        </FormRow>
        <FormRow className="mb-[5rem]">
          <p className="text-neutral-400 my-2.5">Why do you want to give up?</p>
          <NewSelect
            {...{
              customStyles: { optionsList: { item: "text-sm" } },
              defaultOption: { name: "", value: "" },
              defaultOptionsList: giveupReasons,
              onSelect: (selected: Option) => setGiveUpReason(selected.value)
            }}
          />
        </FormRow>
      </Modal>
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  );
}
