import { action } from "easy-peasy";
import { BountyList } from "../../types";
import { FilterParams, UrlSearchParams } from "../../types/BountyList";
import { bountyStatus } from "../../constants";
import { createSearchParams } from "react-router-dom";
import { stringify } from "query-string";

export const defaultFilters: FilterParams = {
  text: undefined,
  owner: undefined,
  categories: [],
  tags: [],
  experience: [],
  status: [
    { name: bountyStatus.New, value: bountyStatus.New },
    { name: bountyStatus.Claimed, value: bountyStatus.Claimed },
    { name: bountyStatus.ManyClaimed, value: bountyStatus.ManyClaimed },
    { name: bountyStatus.AwaitingClaims, value: bountyStatus.AwaitingClaims }
  ],
  conditions: [],
  kyc: false,
  noKyc: false,
  whitelistEnabled: false,
  whitelistDisabled: false
};

export const defaultUrlSearchParams: UrlSearchParams = {
  text: undefined,
  owner: undefined,
  categories: undefined,
  tags: undefined,
  experience: undefined,
  status: [
    bountyStatus.New,
    bountyStatus.Claimed,
    bountyStatus.ManyClaimed,
    bountyStatus.AwaitingClaims
  ],
  conditions: undefined,
  noKyc: undefined,
  kyc: undefined,
  whitelistDisabled: undefined
};

export const urlDefSearchParams = () =>
  createSearchParams(
    stringify(defaultUrlSearchParams, {
      arrayFormat: "separator",
      arrayFormatSeparator: ",",
      skipNull: true
    })
  );

const bountyList: BountyList.BountyList = {
  filters: {
    ...defaultFilters
  },
  setFilters: action((state, payload) => {
    state.filters = payload;
  })
};

export default bountyList;
