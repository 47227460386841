import { useState } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { DEFAULT_STYLES } from "../../../helpers/Validation";
import { DarkButton } from "../../general/Button";
import { Input } from "../../general/Input";
import { Area, SignFormContainerLayout, ValidationLayout } from "../../Layout";

type ForgotPasswordFormData = {
  email: string;
};

interface ForgotPasswordProps {
  onSubmit: (value: ForgotPasswordFormData) => void;
  submitDisabled?: boolean;
  errorMessage?: string;
  successMessage?: boolean;
}

const ForgotPasswordForm = (props: ForgotPasswordProps) => {
  const { onSubmit, errorMessage, successMessage } = props;
  const methods = useFormContext<ForgotPasswordFormData>();
  const { handleSubmit, register, formState, setValue } = methods;
  const { isValid, isSubmitted, errors } = formState;
  const [email, setEmail] = useState("");

  const handleSubmitClick: SubmitHandler<ForgotPasswordFormData> = (form) => {
    if (isValid) {
      onSubmit(form);
    }
  };

  return (
    <SignFormContainerLayout
      {...{
        title: "Forgot password?"
      }}>
      <div className="flex flex-col gap-5 pb-10">
        <form className="w-full">
          <Area
            customStyles={{
              contentContainer: "px-[1rem] py-[2rem] bg-opacity-50"
            }}>
            {!successMessage ? (
              <>
                <p className="text-neutral-200">No worries, we`ll send you reset instructions.</p>
                <div className="flex flex-col gap-[1rem]">
                  <ValidationLayout
                    {...{
                      validate: () => {
                        return {
                          valid: !errors.email,
                          started: isSubmitted,
                          message: errors.email?.message
                        };
                      },
                      label: { text: email.length > 0 ? "Email" : "" }
                    }}>
                    <input
                      type="hidden"
                      {...register("email", {
                        required: "Can't be empty",
                        pattern: {
                          // eslint-disable-next-line no-useless-escape
                          value: /[\w\.+?]+@([\w-]+\.)+[\w-]{2,}/g,
                          message: "Invalid email address"
                        }
                      })}
                    />
                    <Input
                      {...{
                        placeholder: "Write Email",
                        value: email,
                        onChange: (value: string) => {
                          setValue("email", value, { shouldValidate: true });
                          setEmail(value);
                        }
                      }}
                    />
                  </ValidationLayout>
                </div>
              </>
            ) : (
              <>
                <p className="text-md text-neutral-200 p-6">
                  Password reset link has been sent to your email.
                </p>
              </>
            )}
          </Area>
        </form>
        <p className={`min-h-[1em] text-base ${DEFAULT_STYLES.invalid.message}`}>
          {errorMessage ? errorMessage : ""}
        </p>
        {!successMessage && (
          <div className="flex flex-row justify-center items-center gap-5">
            <DarkButton
              {...{
                text: "Send reset Email",
                onClick: () => handleSubmit(handleSubmitClick)(),
                disabled: !!errors.email
              }}
            />
          </div>
        )}
      </div>
    </SignFormContainerLayout>
  );
};

export default ForgotPasswordForm;
