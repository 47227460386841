import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Area, Layout, Section } from "../../components/Layout";
import { CreateProfileForm } from "../../components/Form";
import { useStoreActions, useStoreState } from "../../hooks";
import { DarkButton, LightButton } from "../../components/general/Button";
import { DEV_MODE } from "../../constants";
import mixpanel from "mixpanel-browser";
import { MixpanelContext } from "../App";

const CreateProfile = () => {
  const context = useContext(MixpanelContext);
  mixpanel.init(context.token, {
    debug: false
  });
  const navigate = useNavigate();
  const { userUpdateProfile } = useStoreActions((actions) => actions.auth);
  const { user } = useStoreState((state) => state.auth);
  const defProfileType = user?.metadata?.profileType || "Company";
  const [profileType, setProfileType] = useState(defProfileType);

  const [avatarImage, setAvatarImage] = useState<File | undefined>();
  const [coverImage, setCoverImage] = useState<File | undefined>();

  const bountyOwnerButtonProps = {
    text: "Company",
    onClick: () => {
      setProfileType("Company");
    }
  };

  const freelancerButtonProps = {
    text: "Individual",
    onClick: () => {
      setProfileType("Individual");
    }
  };

  const onSubmit = async (form: any) => {
    const { ...metadata } = form;
    const newUser = {
      ...user,
      metadata: {
        ...{
          profileType
        },
        ...metadata
      }
    };

    await userUpdateProfile({
      newUser,
      avatarImage,
      coverImage
    });
    navigate("/profile", { replace: true });
  };

  useEffect(() => {
    if (!DEV_MODE) {
      mixpanel.track("Hero Profile Edit", {
        source: window.location.hostname
      });
    }
  }, []);

  const Steps = () => {
    switch (profileType) {
      case "Company":
        return (
          <CreateProfileForm.CompanyForm
            {...{
              onSubmit
            }}
          />
        );
      case "Individual":
        return (
          <CreateProfileForm.IndividualForm
            {...{
              onSubmit
            }}
          />
        );
    }
  };

  return (
    <Layout>
      <div className="p-3" />
      <Section
        {...{
          id: "create-profile_section"
        }}>
        <div className="container block mb-[3em] mx-auto md:mb-[6em] max-w-[544px]">
          <h2 className="text-3xl text-center text-neutral-50 font-bold mb-[3rem]">Edit Profile</h2>
          <CreateProfileForm.CommonForm
            avatarImage={avatarImage}
            coverImage={coverImage}
            onSetAvatarImage={setAvatarImage}
            onSetCoverImage={setCoverImage}
          />
          <Area
            customStyles={{
              borderContainer: "border border-sm border-neutral-700",
              contentContainer: "px-[1rem] bg-opacity-50 py-[1em]"
            }}>
            <div className="flex flex-col gap-2 my-4">
              <p className="text-neutral-400 font-poppins text-sm my-2">{"What is your role?"}</p>
              {profileType === "Company" ? (
                <div className="flex flex-row gap-4 items-center justify-center">
                  <LightButton {...bountyOwnerButtonProps} />
                  <DarkButton {...freelancerButtonProps} />
                </div>
              ) : (
                <div className="flex flex-row gap-4 items-center justify-center">
                  <DarkButton {...bountyOwnerButtonProps} />
                  <LightButton {...freelancerButtonProps} />
                </div>
              )}
            </div>
            <Steps />
          </Area>
        </div>
      </Section>
    </Layout>
  );
};

export default CreateProfile;
