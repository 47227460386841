import { twMerge } from "tailwind-merge";

//export { type Separator };

interface SeparatorStyles {
  container?: string;
  separator?: string;
}

const buildStyles = (customStyles: SeparatorStyles | undefined) => ({
  container: twMerge(`
    w-full
    ${customStyles?.container ? customStyles.container : ""}
  `),
  separator: twMerge(`
    w-full min-h-[var(--border-width)] max-h-[var(--border-width)]
    bg-neutral-700
    ${customStyles?.separator ? customStyles.separator : ""}
  `),
});

function Separator({ customStyles }: { customStyles?: SeparatorStyles }) {
  const styles = buildStyles(customStyles);
  return (
    <div className={styles.container}>
      <div className={styles.separator}></div>
    </div>
  );
}

export default Separator;
