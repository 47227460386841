import classNames from "classnames";
import { formatting } from "../../../../common/formatting";
import { Message } from "../../../../types";
import { Bounty } from "../../../../types/Bounty";
import { Claim } from "../../../../types/Claim";
import Avatar from "../../../general/Avatar";
import DefUserIcon from "../../../general/SVGIcon/defUserIcon";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { fromPairs } from "lodash";
import DownloadButton from "./general/DownloadButton";

const ChatMessages = ({ bounty, claim, item }: { bounty: Bounty; claim: Claim; item: Message }) => {
  function LinkRenderer(props: any) {
    const splitTitle = props.title?.split(" ");
    const titleAttrEntries = splitTitle?.filter((t: any) => t.includes(":"));
    const titleAttrPairs = titleAttrEntries?.map((te: any) => te.split(":"));
    const titleAttrs = fromPairs(titleAttrPairs);

    if ("attr" in { ...titleAttrs }) {
      if (titleAttrs.attr === "download") {
        return <DownloadButton href={props.href} title={props.children} />;
      }
    }

    return (
      <a href={props.href} className="underline" target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  }

  return (
    <div
      key={item.bountyId}
      className={classNames("flex mb-4 pr-4", {
        "justify-start": !item.inbound,
        "justify-end": item.inbound
      })}>
      {!item.inbound && (
        <>
          {bounty.ownerDetails?.picture ? (
            <Avatar
              picture={bounty.ownerDetails.picture}
              className="w-[45px] h-[45px] mt-0 my-0"
              original={false}
            />
          ) : (
            <DefUserIcon className={"block w-[45px] h-[45px] mt-0"} />
          )}
        </>
      )}
      <div
        className={classNames("w-full max-w-fit py-2.5 px-4 text-sm", {
          "ml-2 bg-gray-600 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-neutral-100":
            !item.inbound,
          "mr-2 bg-primary-900 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-neutral-800":
            item.inbound
        })}>
        <div className="text-[10px]">
          {formatting.formatDateToString(new Date(item.created_at), "DD.MM.YYYY HH:mm")}
        </div>
        <div className="px-2 break-all">
          <ReactMarkdown
            className="markdown"
            linkTarget="_blank"
            remarkPlugins={[remarkBreaks, remarkGfm]}
            components={{ a: LinkRenderer }}>
            {item.message.replace(/\\n/gi, "&nbsp; \n")}
          </ReactMarkdown>
        </div>
      </div>
      {item.inbound && (
        <>
          {claim.ownerDetails?.picture ? (
            <Avatar
              picture={claim.ownerDetails.picture}
              className="w-[45px] h-[45px] mt-0 my-0"
              original={false}
            />
          ) : (
            <DefUserIcon className={"block w-[45px] h-[45px] mt-0"} />
          )}
        </>
      )}
    </div>
  );
};

export default ChatMessages;
