import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { CompanyMetadata } from "../../../types/Auth";
import CheckBox from "../../general/CheckBox";
import { Input } from "../../general/Input";
import { ValidationLayout } from "../../Layout";
import VatFormData, { VatData } from "./VatFormData";

export type CompanyFormData = {
  metadata: {
    company: {
      companyName: string;
      addressLine1: string;
      addressLine2: string;
    };
  } & VatData;
};

const CompanyProfileData = (props: { metadata: CompanyMetadata; useVAT?: boolean }) => {
  const {
    register,
    setValue,
    formState: { errors, isSubmitted }
  } = useFormContext<CompanyFormData>();
  const { metadata, useVAT = false } = props;
  const [companyName, setCompanyName] = useState(metadata?.company?.companyName ?? "");
  const [addressLine1, setAddressLine1] = useState(metadata?.company?.addressLine1 ?? "");
  const [addressLine2, setAddressLine2] = useState(metadata?.company?.addressLine2 ?? "");
  const [includeVat, setIncludeVat] = useState<boolean>(Boolean(metadata?.includeVat) ?? false);

  const handleChangeName = (value: string) => {
    setValue("metadata.company.companyName", value, { shouldValidate: true });
    setCompanyName(value);
  };
  const handleAddressLine1 = (value: string) => {
    setValue("metadata.company.addressLine1", value, { shouldValidate: true });
    setAddressLine1(value);
  };
  const handleAddressLine2 = (value: string) => {
    setValue("metadata.company.addressLine2", value, { shouldValidate: true });
    setAddressLine2(value);
  };

  const handleIncludeVat = () => {
    setIncludeVat(!includeVat);
    setValue("metadata.includeVat", !includeVat);
  };

  return (
    <>
      <input
        type="hidden"
        defaultValue={companyName}
        {...register("metadata.company.companyName", {
          required: "Required"
        })}
      />
      <ValidationLayout
        {...{
          validate: () => {
            return {
              valid: !errors.metadata?.company?.companyName,
              started: isSubmitted,
              message: errors.metadata?.company?.companyName?.message
            };
          }
        }}>
        <Input
          {...{
            placeholder: "Company Name",
            value: companyName,
            onChange: (value: string) => handleChangeName(value)
          }}
        />
      </ValidationLayout>
      <input
        type="hidden"
        defaultValue={addressLine1}
        {...register("metadata.company.addressLine1", {
          required: "Required"
        })}
      />
      <ValidationLayout
        {...{
          validate: () => {
            return {
              valid: !errors.metadata?.company?.addressLine1,
              started: isSubmitted,
              message: errors.metadata?.company?.addressLine1?.message
            };
          }
        }}>
        <Input
          {...{
            placeholder: "AddressLine1",
            value: addressLine1,
            onChange: (value: string) => handleAddressLine1(value)
          }}
        />
      </ValidationLayout>
      <input
        type="hidden"
        defaultValue={addressLine2}
        {...register("metadata.company.addressLine2")}
      />
      <ValidationLayout
        {...{
          validate: () => {
            return {
              valid: !errors.metadata?.company?.addressLine2,
              started: isSubmitted,
              message: errors.metadata?.company?.addressLine2?.message
            };
          }
        }}>
        <Input
          {...{
            placeholder: "AddressLine2",
            value: addressLine2,
            onChange: (value: string) => handleAddressLine2(value)
          }}
        />
      </ValidationLayout>
      {useVAT && (
        <div className="px-2 py-2.5">
          <CheckBox
            {...{
              text: "Include VAT",
              active: includeVat,
              onClick: () => handleIncludeVat()
            }}
          />
          {includeVat && <VatFormData metadata={metadata} />}
        </div>
      )}
    </>
  );
};

export default CompanyProfileData;
