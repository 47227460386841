import { WalletIcon } from "../SVGIcon";

function ConnectWalletButton() {
  return (
    <button
      className="
      w-full h-full
      flex flex-row justify-between items-center
      text-primary-900
      lg:p-0
    ">
      <p className="lg:hidden">+ Connect wallet</p>

      <WalletIcon />
    </button>
  );
}

export default ConnectWalletButton;
