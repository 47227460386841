import Amount from ".";
import utils from "../../../services/utils";
import { TokenData } from "../../../types/App";
import { ClaimStatus } from "../../../types/Claim";
import { ReactNode } from "react";
import { getTokenData } from "../general/helpers";

const TokenAmount = (props: {
  tokens: TokenData[];
  tokenId: string;
  amount: string;
  className?: string;
  claimStatus?: ClaimStatus;
  icon?: ReactNode;
  additionalText?: string;
}) => {
  const { tokenId, amount, claimStatus, className, icon, additionalText } = props;

  return (
    <Amount
      symbol={getTokenData(tokenId)?.symbol ?? ""}
      amount={utils.formatToken(amount, getTokenData(tokenId)?.decimals || 0)}
      claimStatus={claimStatus}
      className={className}
      icon={icon}
      additionalText={additionalText}
    />
  );
};

export default TokenAmount;
