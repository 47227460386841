import classNames from "classnames";
import { twMerge } from "tailwind-merge";

const TwitterXIconSVG = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(classNames("w-6 h-6 fill-neutral-900", className))}>
      <path
        d="M22.122 0H26.4139L16.9903 11.0332L28 26H19.3602L12.5958 16.9048L4.85172 26H0.559813L10.5432 14.1993L0 0H8.85438L14.9657 8.30849L22.122 0ZM20.6198 23.4096H22.999L7.60413 2.49447H5.04765L20.6198 23.4096Z"
        fill="inherit"
      />
    </svg>
  );
};

export default TwitterXIconSVG;
