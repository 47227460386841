import { useNavigate, useParams } from "react-router-dom";
import { createBrowserHistory } from "history";
import HunterAvatar from "../../components/Hunters/HunterAvatar";
import GithubIconSVG from "../../components/general/SVGIcon/Social/GithubIconSVG";
import useHunterDetails from "../../hooks/useHunterDetails";
import { base64ImageUrl } from "../../utils";
import SmileArrow from "../../components/general/SVGIcon/smileIcon";
import CompletedIcon from "../../components/general/SVGIcon/completedIcon";
import Info from "../../components/Section/Profile/Info";
import { useStoreState } from "../../hooks";
import { useMemo } from "react";
import HunterTransactions from "../../components/Hunters/HunterTransactions";
import { TwitterIconSVG } from "../../components/general/SVGIcon/Social";
import ViewMoreBounties from "../../components/Bounty/ViewMoreBounties";
import defaultUserCoverImage from "../../assets/images/default-cover-image--company.png";
import { CompanyMetadata, IndividualMetadata, UserWallet } from "../../types/Auth";

// const DEV_MODE = process.env.NODE_ENV === "development" || false;

export default function HunterDetails() {
  const urlParams = useParams();
  const history = createBrowserHistory();
  const navigate = useNavigate();
  const countries = useStoreState((state) => state.app.countries);

  const handleGoBack = () => {
    history.back();
  };

  const { hunter } = useHunterDetails({ hunterId: urlParams.id });

  const mapCountry = useMemo(() => {
    const countryKey = hunter?.country ?? null;
    return countryKey && Object.keys(countries).includes(countryKey) ? countries[countryKey] : null;
  }, [countries, hunter?.country]);

  return hunter ? (
    <div className="mt-4">
      <div className="w-full md:w-2/3 lg:w-1/2 mx-auto flex flex-col gap-2.5">
        <div className="flex justify-start">
          <button className="text-sm text-neutral-400" onClick={handleGoBack}>
            Back
          </button>
        </div>
        <div className="border-sm border-neutral-800 rounded-[1.25rem]">
          <div>
            <img
              className="rounded-[1.25rem] w-full h-56 object-cover"
              src={
                hunter?.coverImage
                  ? base64ImageUrl(hunter.coverImage.data, hunter.coverImage.contentType)
                  : defaultUserCoverImage
              }
            />
            <HunterAvatar picture={hunter?.picture} className="relative left-5 w-24 h-24" />
          </div>
          <div className="flex justify-between px-5 pt-4 pb-8">
            <div>
              <p className="text-lg text-neutral-50">
                {(hunter as IndividualMetadata)?.individual?.name ||
                  (hunter as CompanyMetadata)?.company?.companyName}
              </p>
            </div>
          </div>
        </div>
        <div className="px-5 py-4 border-sm border-neutral-800 rounded-[1.25rem] text-neutral-200">
          {hunter?.walletsKycApproved?.length > 0 &&
            hunter?.walletsKycApproved.map(({ walletId }: { walletId: string }) => {
              return (
                <div key={walletId} className="flex gap-2 mb-2">
                  <CompletedIcon className="w-[22px] h-[22px] fill-neutral-200" /> Fractal verified
                  - {walletId}
                </div>
              );
            })}
          <div className="flex gap-2">
            <SmileArrow /> {hunter.claimantDetails[0]?.completedBounties || 0} Completed bounties
          </div>
        </div>
        {hunter.type === "Individual" && (
          <>
            {(hunter as IndividualMetadata)?.individual.github && (
              <div className="flex flex-wrap gap-2 px-5 py-4 border-sm border-neutral-800 rounded-[1.25rem]">
                <a
                  href={(hunter as IndividualMetadata).individual.github}
                  target="_blank"
                  rel="noreferrer">
                  <GithubIconSVG className="w-[26px] h-[26px] fill-neutral-400" />
                </a>
              </div>
            )}
            {(hunter as IndividualMetadata)?.individual?.twitter && (
              <div className="flex flex-wrap gap-2 px-5 py-4 border-sm border-neutral-800 rounded-[1.25rem]">
                <a
                  href={(hunter as IndividualMetadata).individual.twitter}
                  target="_blank"
                  rel="noreferrer">
                  <TwitterIconSVG className="w-[26px] h-[26px] fill-neutral-400" />
                </a>
              </div>
            )}
          </>
        )}
        <div className="p-5 border-sm border-neutral-800 rounded-[1.25rem]">
          <HunterTransactions userId={hunter.id} />
        </div>
        <div className="px-5 pt-[30px] pb-10 text-sm border-sm border-neutral-800 rounded-[1.25rem]">
          <h5 className="text-neutral-400 mb-4">BIO</h5>
          <p className="text-neutral-100">
            {(hunter as IndividualMetadata)?.individual?.bio ||
              (hunter as CompanyMetadata)?.company?.companyAbout ||
              "N/A"}
          </p>
        </div>
        <div className="flex flex-wrap gap-x-16 gap-y-4 p-5 text-sm border-sm border-neutral-800 rounded-[1.25rem]">
          <Info
            {...{
              customStyles: {
                container: "gap-1",
                label: "text-neutral-600",
                value: "text-sm text-neutral-50"
              },
              label: "Availability",
              value: hunter?.availability ?? "N/A"
            }}
          />
          <Info
            {...{
              customStyles: {
                container: "gap-1",
                label: "text-neutral-600",
                value: "text-sm text-neutral-50"
              },
              label: "Website",
              value: (hunter as CompanyMetadata)?.company?.companySite ?? "N/A"
            }}
          />
          <Info
            {...{
              customStyles: {
                container: "gap-1",
                label: "text-neutral-600",
                value: "text-sm text-neutral-50"
              },
              label: "Location",
              value: mapCountry ?? "N/A"
            }}
          />
          {hunter?.skills?.length > 0 && (
            <Info
              {...{
                customStyles: { container: "gap-1", label: "text-neutral-600" },
                label: "Skills"
              }}>
              <div className="flex flex-wrap gap-2">
                {hunter.skills.map((s: string) => (
                  <div
                    key={s}
                    className="flex justify-center items-center px-2 rounded bg-post-secondary-700 text-sm text-neutral-800">
                    {s}
                  </div>
                ))}
              </div>
            </Info>
          )}
          {hunter?.wallets?.length > 0 && (
            <div className="flex flex-row justify-between items-center">
              <Info
                {...{
                  customStyles: {
                    container: "gap-1",
                    label: "text-neutral-600",
                    value: "text-sm text-neutral-50"
                  },
                  label: "Wallets",
                  value: (
                    <>
                      {hunter.wallets.map((wallet: UserWallet) => (
                        <div key={wallet.walletId}>{wallet.walletId}</div>
                      ))}
                    </>
                  )
                }}
              />
            </div>
          )}
        </div>
        <div className="p-5 border-sm border-neutral-800 rounded-[1.25rem]">
          <ViewMoreBounties
            className="lg:grid-cols-1"
            filters={{
              claimers: hunter.wallets.map((wallet) => {
                return { name: wallet.walletId, value: wallet.walletId };
              }),
              status: [{ name: "Completed", value: "Completed" }]
            }}
            limit={3}
            title="Completed bounties"
          />
          <div className="flex items-center justify-end py-2.5">
            <button
              className="text-sm text-neutral-400 outline-none"
              onClick={() => navigate("completed-bounties")}>
              Show all
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
