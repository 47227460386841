import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { CompanyMetadata, IndividualMetadata } from "../../../types/Auth";
import CheckBox from "../../general/CheckBox";
import { Input } from "../../general/Input";
import { ValidationLayout } from "../../Layout";
import { CompanyFormData } from "./CompanyProfileData";
import { IndividualFormData } from "./IndividualProfileData";

export type VatData = {
  includeVat: boolean;
  vatRate: number;
  vatNumber: string;
  reverseCharge: boolean;
};

const VatFormData = (props: { metadata: IndividualMetadata | CompanyMetadata }) => {
  const { metadata } = props;
  const {
    register,
    setValue,
    formState: { errors, isSubmitted }
  } = useFormContext<IndividualFormData | CompanyFormData>();

  const [vatRate, setVatRate] = useState<string>(metadata?.vatRate?.toString() ?? "0");
  const [vatNumber, setVatNumber] = useState<string>(metadata?.vatNumber ?? "");
  const [reverseCharge, setReverseCharge] = useState<boolean>(metadata?.reverseCharge ?? false);

  const handleVatRate = (value: string) => {
    const vatValue = isNaN(parseFloat(value)) ? "0" : value.replace(",", ".");
    setVatRate(vatValue);
    setValue("metadata.vatRate", parseFloat(vatValue), {
      shouldValidate: true
    });
  };
  const handleVatNumber = (value: string) => {
    setVatNumber(value);
    setValue("metadata.vatNumber", value, { shouldValidate: true });
  };

  const handleReverseCharge = () => {
    setReverseCharge(!reverseCharge);
    setValue("metadata.reverseCharge", !reverseCharge);
  };

  return (
    <div>
      <input
        type="hidden"
        defaultValue={vatRate}
        {...register("metadata.vatRate", {
          required: "Required field"
        })}
      />
      <ValidationLayout
        {...{
          validate: () => {
            return {
              valid: !errors.metadata?.vatRate,
              started: isSubmitted,
              message: errors.metadata?.vatRate?.message
            };
          }
        }}>
        <Input
          {...{
            customStyles: { input: "py-2" },
            placeholder: "Vat Rate",
            value: vatRate.toString(),
            label: { text: "VAT rate %" },
            onChange: (value: string) => handleVatRate(value)
          }}
        />
      </ValidationLayout>
      <input
        type="hidden"
        defaultValue={vatNumber}
        {...register("metadata.vatNumber", {
          required: "Required field"
        })}
      />
      <ValidationLayout
        {...{
          validate: () => {
            return {
              valid: !errors.metadata?.vatNumber,
              started: isSubmitted,
              message: errors.metadata?.vatNumber?.message
            };
          }
        }}>
        <Input
          {...{
            customStyles: { input: "py-2" },
            placeholder: "VAT number",
            value: vatNumber,
            label: { text: !errors.metadata?.vatNumber ? "VAT number" : "" },
            onChange: (value: string) => handleVatNumber(value)
          }}
        />
      </ValidationLayout>
      <div className="my-2.5">
        <CheckBox
          {...{
            text: "Reverse charge",
            active: reverseCharge,
            onClick: () => handleReverseCharge()
          }}
        />
      </div>
    </div>
  );
};

export default VatFormData;
