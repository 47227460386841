import classNames from "classnames";
import { NavLink, NavLinkProps } from "react-router-dom";
import { RedCircleIcon } from "../../../../general/SVGIcon";

type MenuItemProps = {
  text: string;
  linkProps: NavLinkProps;
  Icon?: React.ReactNode;
  alert?: boolean;
  className?: string;
};

const MenuItem = (props: MenuItemProps) => {
  const { text, Icon, className, linkProps, alert } = props;
  return (
    <li className="menu-item flex items-center hover:border hover:border-neutral-700 hover:rounded-lg hover:bg-neutral-800">
      {Icon && <span className="ml-4">{Icon}</span>}
      <NavLink
        className={({ isActive }) =>
          classNames(
            "mx-4 py-4 flex items-center justify-between text-md flex-1",
            {
              "text-primary-900": isActive
            },
            className
          )
        }
        end
        {...linkProps}>
        {text}
        {alert && <RedCircleIcon />}
      </NavLink>
    </li>
  );
};

export default MenuItem;
