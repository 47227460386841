import classNames from "classnames";

type TProps = {
  color?: string,
  bgColor?: string,
  className?: string,
}

const defaultProps = { 
  bgColor: "hsl(var(--color-primary-700))",
  color: "hsl(var(--color-neutral-900))"
}

export default function WalletIcon(props: TProps) {
  const { color, bgColor, className } = { ...defaultProps, ...props }
  
  return (
    <svg className={classNames("", className)} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={bgColor}/>
      <rect x="9" y="9" width="26" height="29" rx="2" fill={color}/>
      <path d="M10.3333 12C10.3333 11.4477 10.7811 11 11.3333 11H32.6667C33.219 11 33.6667 11.4477 33.6667 12V13.5H10.3333V12Z" fill={bgColor}/>
      <path d="M10.3333 16.1666C10.3333 15.6143 10.7811 15.1666 11.3333 15.1666H32.6667C33.219 15.1666 33.6667 15.6143 33.6667 16.1666V17.6666H10.3333V16.1666Z" fill={bgColor}/>
      <circle cx="22" cy="17.6666" r="2.5" fill={bgColor}/>
      <circle cx="33" cy="12" r="7" fill={bgColor}/>
      <circle cx="33" cy="12" r="5" fill={color}/>
      <path d="M33 9V15" stroke={bgColor}/>
      <path d="M30 12L36 12" stroke={bgColor}/>
      <rect x="19" y="23" width="2" height="3" fill={bgColor}/>
      <path d="M19 27L19 25L25 25C26.1046 25 27 25.8954 27 27L19 27Z" fill={bgColor}/>
      <path d="M19 33L19 31L27 31C27 32.1046 26.1046 33 25 33L19 33Z" fill={bgColor}/>
      <rect x="23" y="23" width="2" height="3" fill={bgColor}/>
      <rect x="19" y="32" width="2" height="3" fill={bgColor}/>
      <rect x="23" y="32" width="2" height="3" fill={bgColor}/>
      <path d="M19 33C17.8954 33 17 32.1046 17 31L17 27C17 25.8954 17.8954 25 19 25L19 33Z" fill={bgColor}/>
      <rect x="25" y="27" width="2" height="1" fill={bgColor}/>
      <rect x="25" y="30" width="2" height="1" fill={bgColor}/>
    </svg>
  )
}
