import { useEffect, useState } from "react";

import { Section, Area } from "../../Layout";
import { IndividualMetadata, PublicProfile, User } from "../../../types/Auth";

import Info from "./Info";

import defaultUserCoverImage from "../../../assets/images/default-cover-image--company.png";
import { getCoverImage } from "../../../services/profileApi";

import { base64ImageUrl, ImageData, imageDataToBase64Image } from "../../../utils";
import { DarkButton } from "../../general/Button";
import { useNavigate } from "react-router-dom";
import Avatar from "../../general/Avatar";
import MetadataInfo from "./MetadataInfo";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import API from "../../../services/Api";
import { KycInfo } from "../../../types/Kyc";
import { useStoreActions } from "../../../hooks";
import classNames from "classnames";

function IndividualProfile(props: {
  user?: User;
  publicProfile?: PublicProfile<IndividualMetadata> | null;
}) {
  const { user, publicProfile } = props;
  const navigate = useNavigate();
  const { walletAccountId } = useWalletAccountId();

  const metadata = user?.metadata as IndividualMetadata;
  const individual = metadata?.individual;
  const [coverImage, setCoverImage] = useState<ImageData | null>(null);
  const [kycInfo, setKycInfo] = useState<KycInfo>(null);

  const actions = useStoreActions((actions) => actions);
  const {
    nearApi: { getWhitelisted }
  } = actions;

  const userMetadata: Array<{
    label: string;
    type?: "link";
    value: string;
  }> = [
    {
      label: "Full Name",
      value: individual?.name ?? "N/A"
    },
    {
      label: "Username",
      value: user?.name ?? "N/A"
    },
    {
      label: "Availability",
      value: metadata?.availability ?? "N/A"
    },
    {
      label: "Github link",
      type: "link",
      value: individual?.github ?? "N/A"
    }
  ];

  const profileMetadata: Array<{
    label: string;
    type?: "link";
    value: string;
  }> = [
    {
      label: "Username",
      value: publicProfile?.username ?? "N/A"
    },
    {
      label: "Availability",
      value: publicProfile?.availability ?? "N/A"
    },
    {
      label: "Github link",
      type: "link",
      value: publicProfile?.individual?.github ?? "N/A"
    }
  ];

  const fetchCoverImage = async () => {
    const coverImageResult = await getCoverImage(true);
    if (coverImageResult.success?.data?.image?.data)
      setCoverImage(coverImageResult.success.data.image);
  };

  const fetchKycApplicant = async () => {
    if (walletAccountId) {
      const kycApplicantResult = await API.getApprovedKYCApplicant(walletAccountId);
      const isWhitelisted = await getWhitelisted(walletAccountId);

      if (kycApplicantResult?.fractalAnswer) {
        setKycInfo({ fractalAnswer: kycApplicantResult.fractalAnswer });
      } else if (kycApplicantResult?.reviewAnswer) {
        setKycInfo({ reviewAnswer: kycApplicantResult.reviewAnswer });
      } else if (isWhitelisted) {
        setKycInfo({ isWhitelisted });
      }
    }
  };

  useEffect(() => {
    if (walletAccountId) {
      fetchKycApplicant();
    }
  }, [walletAccountId]);

  useEffect(() => {
    // fetch data
    if (user && !coverImage) fetchCoverImage();
  }, [coverImage, user]);

  return (
    <Section
      {...{
        id: "individual_profile_section"
      }}>
      <div className="flex justify-between items-center">
        <p className="text-neutral-50 text-lg md:text-base py-4">{"Profile"}</p>
        {!publicProfile && (
          <DarkButton
            {...{
              customStyles: {
                button:
                  "border border-neutral-600 radius-[12px] text-white md:text-sm max-w-[104px]",
                text: "md:text-sm"
              },
              onClick: () => navigate("/profile/edit"),
              text: "Edit profile"
            }}
          />
        )}
      </div>
      <div className="flex flex-col gap-4 md:gap-6">
        <Area {...{ customStyles: { borderContainer: "overflow-hidden" } }}>
          {user && (
            <img
              {...{
                src: coverImage
                  ? base64ImageUrl(imageDataToBase64Image(coverImage), coverImage?.contentType)
                  : defaultUserCoverImage,
                className: `
              bg-secondary-900 bg-center bg-cover object-cover w-full
              h-[180px]
              md:h-[210px]
            `
              }}
              alt=""
            />
          )}
          {publicProfile && (
            <img
              {...{
                src: publicProfile?.coverImage
                  ? base64ImageUrl(
                      imageDataToBase64Image(publicProfile.coverImage),
                      publicProfile.coverImage.contentType
                    )
                  : defaultUserCoverImage,
                className: classNames(
                  "bg-secondary-900 bg-center bg-cover object-cover w-full h-[180px] md:h-[210px]"
                )
              }}
              alt=""
            />
          )}
          <div className="flex flex-col gap-4 p-5 !pt-0 md:gap-6 md:p-8">
            {user && (
              <>
                <Avatar />
                <MetadataInfo
                  metadata={userMetadata}
                  skills={user.metadata?.skills}
                  walletAccount={walletAccountId}
                  wallets={user.wallets}
                  location={{
                    country: metadata?.country,
                    address1: metadata?.individual?.addressLine1,
                    address2: metadata?.individual?.addressLine2
                  }}
                  vat={{
                    vatRate: metadata?.vatRate,
                    vatNumber: metadata?.vatNumber
                  }}
                  kycInfo={kycInfo}
                />
              </>
            )}
            {publicProfile && (
              <>
                <Avatar picture={publicProfile.picture} publicProfile={true} />
                <MetadataInfo
                  metadata={profileMetadata}
                  skills={publicProfile.skills}
                  location={{ country: publicProfile?.country }}
                />
              </>
            )}
          </div>
          <div className="flex flex-col gap-4 p-5 md:gap-6 md:p-8">
            <Info
              {...{
                customStyles: {
                  container: "flex-1"
                },
                label: "About"
              }}>
              <Area
                {...{
                  customStyles: {
                    borderContainer: "border-none",
                    contentContainer:
                      "flex flex-1 flex-col gap-4 p-[1.25rem] !bg-neutral-800 md:gap-6 md:p-[2rem] !text-sm"
                  }
                }}>
                {user && (
                  <Info
                    {...{
                      value: individual?.bio,
                      customStyles: {
                        container: "flex-1",
                        value: "!text-sm"
                      }
                    }}
                  />
                )}
                {publicProfile && (
                  <Info
                    {...{
                      value: publicProfile?.individual?.bio,
                      customStyles: {
                        container: "flex-1",
                        value: "!text-sm"
                      }
                    }}
                  />
                )}
              </Area>
            </Info>
          </div>
        </Area>
      </div>
    </Section>
  );
}

export default IndividualProfile;
