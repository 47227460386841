import { useContext } from "react";
import { Bounty } from "../../../types/Bounty";
import { Area } from "../../Layout";
import WalletAccountContext from "../general/context/WalletAccountContext";
import { EditWhitelist } from "../../Form/EditBountyForm/Actions";

const WhitelistAccounts = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  const walletAccountId = useContext(WalletAccountContext);

  return (
    <Area
      {...{
        customStyles: {
          borderContainer: "border border-sm border-neutral-800",
          contentContainer: "bg-neutral-850 p-8"
        }
      }}>
      <div className="flex justify-between">
        <span className="text-neutral-200 text-sm">Whitelist Accounts</span>
        {bounty.owner === walletAccountId && <EditWhitelist bounty={bounty} />}
      </div>
      {bounty.claimants_whitelist && (
        <div className="grid grid-cols-3 my-2.5">
          {bounty.claimants_whitelist.map((account) => (
            <div className="text-sm text-primary-900" key={account}>
              {account}
            </div>
          ))}
        </div>
      )}
    </Area>
  );
};

export default WhitelistAccounts;
