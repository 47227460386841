import { useState } from "react";
import { useStoreActions } from "../../../hooks";
import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { FinalExecutionOutcomeForm, FinalizeBountyForm } from "../Dialogues";
import ActionButton from "./general/ActionButton";
import { FinalExecutionOutcome } from "@near-wallet-selector/core";

const FinalizeAction = (props: { claim?: Claim; bounty: Bounty; refetchActions?: () => void }) => {
  const { bounty, claim, refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<FinalExecutionOutcome | null>(null);
  const setBountyFinalize = useStoreActions((actions) => actions.nearApi.setBountyFinalize);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const result = (await setBountyFinalize([
      {
        id: bounty.id,
        claimant: claim && [claim.owner, claim.claim_number]
      }
    ])) as FinalExecutionOutcome;
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
    }
  };

  return (
    <>
      {claim ? (
        <ActionButton text="Finalize" disabled={false} handleClick={handleButtonClick} />
      ) : (
        <ActionButton
          text="Update bounty status"
          disabled={false}
          handleClick={handleButtonClick}
          customStyles={{ button: "max-w-none" }}
        />
      )}
      <FinalizeBountyForm
        show={showForm}
        loading={loading}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  );
};

export default FinalizeAction;
