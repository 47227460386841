import { useState, useContext } from "react";
import { useStoreActions } from "../../../../hooks";
import useWalletAccountId from "../../../../hooks/useWalletAccountId";
import utils from "../../../../services/utils";
import { Bounty, NextActionModel } from "../../../../types/Bounty";
import NextActionsContext from "../../../Bounty/general/context/NextActionsContext";
import { EditWhitelistAccountsForm } from "../Dialogues";
import EditIconButton from "./general/EditIconButton";

const EditWhitelist = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  const [showForm, setShowForm] = useState(false);
  const updateBountyV2 = useStoreActions((actions) => actions.nearApi.updateBountyV2);
  const context = useContext(NextActionsContext);
  const nextActions = context as Partial<NextActionModel>[] | null;
  const { walletAccountId } = useWalletAccountId();

  const handleButtonClick = () => {
    return setShowForm(!showForm);
  };
  const isHidden = () => {
    const action = nextActions?.find((item) => item.accountId === walletAccountId);
    return !action?.nextActions?.bountyUpdateClaimerApproval;
  };

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, null, walletAccountId);
    return a.checkAccess([!a.isBountyOwner(), !a.isReviewer()]);
  };

  const handleSubmitForm = async ({ accounts }: { accounts: string }) => {
    const obj =
      bounty.claimant_approval === "ApprovalByWhitelist"
        ? {
            claimant_approval: {
              ApprovalByWhitelist: {
                claimants_whitelist: accounts
                  .split(",")
                  .map((item) => item.trim())
                  .filter((value) => Boolean(value))
              }
            }
          }
        : bounty.claimant_approval === "WhitelistWithApprovals"
        ? {
            claimant_approval: {
              WhitelistWithApprovals: {
                claimants_whitelist: accounts
                  .split(",")
                  .map((item) => item.trim())
                  .filter((value) => Boolean(value))
              }
            }
          }
        : {};
    await updateBountyV2({
      id: bounty.id,
      bounty_update: {
        ...obj
      }
    });
  };

  return !isHidden() ? (
    <>
      <EditIconButton isDisabled={isDisabled()} handleSubmit={() => handleButtonClick()} />

      <EditWhitelistAccountsForm
        show={showForm}
        handleClose={handleButtonClick}
        whitelist={bounty.claimants_whitelist?.join(",")}
        handleSubmit={handleSubmitForm}
      />
    </>
  ) : null;
};

export default EditWhitelist;
