import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useStoreActions, useStoreState } from "../../../../../../../hooks";
import { ContestOrHackathon } from "../../../../../../../types/BountyCreate";
import { FormRow } from "../../../../../../general/Form/FormElements";
import { ValidationLayout } from "../../../../../../Layout";
import { Input } from "../../../../../../general/Input";

type FormData = {
  multitasking: {
    successful_claims_for_result: number | null;
  };
};

const SuccessfulClaimsForResult = () => {
  const methods = useFormContext<FormData>();
  const { register, formState, setValue } = methods;
  const { errors, isSubmitted } = formState;
  const successful_claims_for_result = useStoreState(
    (state) =>
      (state.newBounty.multitasking as ContestOrHackathon)?.ContestOrHackathon
        .successful_claims_for_result
  );

  const defValue = useMemo(() => {
    return !successful_claims_for_result || successful_claims_for_result < 0
      ? null
      : successful_claims_for_result;
  }, [successful_claims_for_result]);

  const setSuccessfulClaimsForResult = useStoreActions(
    (actions) => actions.newBounty.setSuccessfulClaimsForResult
  );

  const handleChange = (value: string) => {
    if (!isNaN(parseInt(value))) {
      setSuccessfulClaimsForResult(parseInt(value));
      setValue("multitasking.successful_claims_for_result", parseInt(value), {
        shouldValidate: true
      });
    } else {
      setSuccessfulClaimsForResult(null);
      setValue("multitasking.successful_claims_for_result", null, { shouldValidate: true });
    }
  };

  return (
    <FormRow>
      <ValidationLayout
        {...{
          validate: () => {
            return {
              valid: !errors.multitasking?.successful_claims_for_result,
              message: errors.multitasking?.successful_claims_for_result?.message,
              started: isSubmitted
            };
          }
        }}>
        <input
          defaultValue={defValue ?? ""}
          type="hidden"
          {...register("multitasking.successful_claims_for_result", {
            validate: {
              minAmount: (value) => {
                const amount = !value ? null : value;
                return amount === null || amount > 1 || "Must be greater than 1";
              }
            }
          })}
        />
        <Input
          {...{
            placeholder: "number successful claims",
            value: defValue?.toString() ?? "",
            onChange: (value: string) => handleChange(value)
          }}
        />
      </ValidationLayout>
    </FormRow>
  );
};

export default SuccessfulClaimsForResult;
