import { MouseEventHandler } from "react";
import { RedCircleIcon } from "../SVGIcon";
import useNextActions, { NextActionExpected } from "../../../hooks/useNextActions";

type TProps = {
  toggled: boolean;
  handleToggle: MouseEventHandler;
};

function MenuButton(props: TProps) {
  const { toggled, handleToggle } = props;
  const { data: nextActions } = useNextActions({});
  const { nextActionExpected } = (nextActions as NextActionExpected) || {};
  const icon = () => (toggled ? "bg-menu-cross-icon" : "bg-menu-burger-icon");

  return (
    <div className="relative">
      <button
        onClick={handleToggle}
        className={`
        block
        relative z-50
        aspect-square min-h-[27px]
        mr-header
        bg-transparent bg-no-repeat bg-contain ${icon()}
        border-none
        cursor-pointer
        lg:hidden
      `}>
        {!toggled && (nextActionExpected?.forBounties || nextActionExpected?.forClaims) && (
          <div className="absolute z-0 top-0 right-0 mt-[-8px] mr-[-8px]">
            <RedCircleIcon />
          </div>
        )}
        <span className="sr-only">Menu</span>
      </button>
    </div>
  );
}

export default MenuButton;
