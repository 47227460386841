import classNames from "classnames";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import AddTaskAction from "./Actions/AddTaskAction";
import DeleteTaskAction from "./Actions/DeleteTaskAction";
import { calcPercentsLeft, formatPercents } from "./helpers";
import { useStoreState } from "../../../../../../../hooks";
import { DifferentTasks } from "../../../../../../../types/BountyCreate";
import { Area } from "../../../../../../Layout";
import { FormRow } from "../../../../../../general/Form/FormElements";
import Separator from "../../../../../../general/Separator";
import SubtaskItem from "../../../../general/SubtaskItem";
import CreatingManyClaimsOption from "../CreatingManyClaimsOption";

type FormData = {
  multitasking: { percents_left: number; tasks_count: number };
};

const DifferentTasksForm = () => {
  const multitasking = useStoreState((state) => state.newBounty.multitasking as DifferentTasks);
  const { subtasks } = multitasking.DifferentTasks;

  const percentsLeft = calcPercentsLeft(subtasks);

  const methods = useFormContext<FormData>();

  useEffect(() => {
    methods.setValue("multitasking.percents_left", percentsLeft, {
      shouldValidate: true
    });
  }, [percentsLeft]);

  useEffect(() => {
    methods.setValue("multitasking.tasks_count", subtasks.length, {
      shouldValidate: true
    });
  }, [subtasks.length]);

  useEffect(() => {
    if (subtasks?.length < 2) {
      methods.setError("multitasking.tasks_count", {
        message: "There must be more than or equal to 2 tasks."
      });
    }
    return () => methods.clearErrors();
  }, [subtasks]);

  return (
    <Area
      {...{ customStyles: { borderContainer: "border border-sm border-neutral-700 p-4 mt-2" } }}>
      <FormRow>
        <>
          <div className="mb-2.5">
            <CreatingManyClaimsOption />
          </div>
          <AddTaskAction />
          <FormRow>
            <Area {...{ customStyles: { contentContainer: "py-0" } }}>
              {subtasks.length > 0 ? (
                <>
                  <div className="py-2">
                    <div className="flex justify-between mt-6">
                      <span>Total</span>
                      <div className="flex gap-6">
                        <span className="px-6">100%</span>
                        <span />
                      </div>
                    </div>
                    <div className="py-2.5">
                      <Separator />
                    </div>
                    <div className="flex justify-between">
                      <span>Left</span>
                      <div className="flex gap-6">
                        <input
                          type="hidden"
                          defaultValue={percentsLeft}
                          {...methods.register("multitasking.percents_left", {
                            validate: {
                              percents: (value) => value === 0 || "Must equal 0%"
                            }
                          })}
                        />
                        <div
                          className={classNames("flex flex-col items-end px-6", {
                            "text-secondary-900": percentsLeft > 0 || percentsLeft < 0,
                            "text-success": percentsLeft === 0
                          })}>
                          <span>{percentsLeft}%</span>
                          <span className="px-2">
                            {methods.formState.errors.multitasking?.percents_left && (
                              <em className="text-sm text-secondary-900">
                                ( {methods.formState.errors.multitasking?.percents_left.message})
                              </em>
                            )}
                          </span>
                        </div>
                        <span>
                          <input
                            type="hidden"
                            defaultValue={subtasks.length}
                            {...methods.register("multitasking.tasks_count", {
                              validate: {
                                length: (value) =>
                                  value >= 2 || "There must be more than or equal to 2 tasks."
                              }
                            })}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <FormRow>
                    {subtasks.map((subtask, index) => (
                      <SubtaskItem
                        key={`task-${index}`}
                        description={subtask.subtask_description}
                        percents={formatPercents(subtask)}>
                        <DeleteTaskAction index={index} />
                      </SubtaskItem>
                    ))}
                  </FormRow>
                </>
              ) : (
                <Area>
                  <div className="text-center text-neutral-400">
                    <p> No new tasks added</p>
                    <em className="text-sm">At least 2 tasks</em>
                  </div>
                </Area>
              )}
            </Area>
            <div className="text-center">
              {methods.formState.errors.multitasking?.tasks_count && (
                <em className="text-sm text-secondary-900">
                  {methods.formState.errors.multitasking?.tasks_count.message}
                </em>
              )}
            </div>
          </FormRow>
        </>
      </FormRow>
    </Area>
  );
};

export default DifferentTasksForm;
