import classNames from "classnames";

const ActionButton = (props: {
  text: string;
  loading?: boolean;
  disabled: boolean;
  onClick: () => void;
}) => {
  const { text, loading, disabled, onClick } = props;
  return (
    <button
      className={classNames("flex items-center gap-2 font-poppins text-xs outline-none", {
        "text-primary-900": !disabled,
        "text-neutral-700": disabled
      })}
      disabled={disabled}
      onClick={onClick}>
      {loading && (
        <div
          className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status">
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      )}
      {text}
    </button>
  );
};

export default ActionButton;
