import { useStoreActions } from ".";
import useWalletAccountId from "./useWalletAccountId";
import { useQuery } from "@tanstack/react-query";

const useFtTokenBalance = ({ tokenId }: { tokenId: string | undefined }) => {
  const { walletAccountId } = useWalletAccountId();
  const getFtTokenBalance = useStoreActions((actions) => actions.nearApi.getFtTokenBalance);

  const { data, error, isInitialLoading, isError, isFetching, status, refetch } = useQuery(
    ["ft-balance", walletAccountId, tokenId],
    () => {
      return getFtTokenBalance({
        accountId: walletAccountId,
        tokenId: tokenId ?? ""
      }).then((res) => {
        return res;
      });
    },
    {}
  );

  return {
    availableBalance: data,
    error,
    status,
    isInitialLoading,
    isError,
    isFetching,
    refetch
  };
};

export default useFtTokenBalance;
