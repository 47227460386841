import { ReactNode, useState } from "react";
import IconButton from "../Button/IconButton";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type DropdownMenuProps = {
  icon?: JSX.Element;
  children?: ReactNode | ReactNode[];
  customStyles?: {
    content?: string;
    dropdownMenu?: string;
  };
};

const DropdownMenu = (props: DropdownMenuProps) => {
  const { icon, customStyles, children } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen((old) => !old);
  };

  const transClass = isOpen ? "flex" : "hidden";

  return (
    <>
      <div className={classNames("relative", customStyles?.content)}>
        <IconButton icon={icon} onClick={toggle} />
        <div
          className={twMerge(
            classNames(
              "border border-neutral-800 absolute top-8 right-0 z-30 w-[250px] min-h-fit flex flex-col py-4 bg-neutral-830 px-4 rounded-md",
              transClass,
              customStyles?.dropdownMenu
            )
          )}>
          {children && children}
        </div>
      </div>
      {isOpen ? (
        <div
          className={twMerge(classNames("fixed top-0 right-0 bottom-0 left-0 z-20"))}
          onClick={toggle}></div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DropdownMenu;
