import classNames from "classnames";
import { useState, useRef } from "react";
import { useStoreActions } from "../../../../../hooks";
import { FormRow } from "../../../../general/Form/FormElements";
import { LinkIcon, SelectArrow } from "../../../../general/SVGIcon";
import { DarkButton } from "../../../../general/Button";
import { twMerge } from "tailwind-merge";
import Api from "../../../../../services/Api";

type UploadResponse = {
  id: string;
  filename: string;
  contentType: string;
  size: number;
};

export default function UploadAttachments(props: {
  bountyId: number | string;
  onSetAttachments: (value: string[] | undefined) => void;
}) {
  const { onSetAttachments, bountyId } = props;
  const [loading, setLoading] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [uploaded, setUploaded] = useState<UploadResponse[] | undefined>();
  const [files, setFiles] = useState<File[]>([]);
  const setError = useStoreActions((actions) => actions.error.setError);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const getAploadedInfo = (name: string) => {
    return uploaded?.find((file) => file.filename === name);
  };

  const handleShowAttachments = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAttachments(!showAttachments);
  };

  const handleUpload = async (files: FormData) => {
    try {
      const response = await Api.uploadFiles(files, bountyId);

      if (!response.error) {
        return response;
      } else {
        setError(response.message);
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const newFiles = [...files];
    newFiles.push(e.target.files[0]);
    setFiles(newFiles);
  };

  const handleRemoveFile = (e: React.MouseEvent<Element, MouseEvent>, name: string) => {
    e.preventDefault();
    const newFiles = files.filter((delFile) => delFile.name !== name);
    const newUploaded = uploaded?.filter((delUploaded) => delUploaded.filename !== name);
    setFiles(newFiles);
    setUploaded(newUploaded);
    onSetAttachments(newUploaded?.map((item) => item.id));
  };

  const handleImportResources = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    files.forEach((file) => data.append("files", file));

    const uploadedFiles: UploadResponse[] = await handleUpload(data);
    if (uploadedFiles) {
      onSetAttachments(uploadedFiles.map((f) => f.id));
      setUploaded(uploadedFiles);
    }
    setLoading(false);
  };

  return (
    <div>
      <FormRow>
        <div className="flex justify-end">
          <button
            className="flex items-center gap-1 text-[12px] text-primary-900 outline-none"
            onClick={(e) => handleShowAttachments(e)}>
            Add attachments
            <SelectArrow
              className={classNames("w-3 h-3", {
                "rotate-180": showAttachments
              })}
              {...{ color: "hsl(var(--color-primary-900))" }}
            />
          </button>
        </div>
      </FormRow>
      {showAttachments && (
        <div>
          <input
            className="hidden"
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div className="my-2.5">
            <DarkButton
              {...{
                customStyles: {
                  button: twMerge(
                    classNames(
                      "max-w-[96px] border-neutral-300 text-neutral-300 py-1 px-3 hover:shadow-none"
                    )
                  ),
                  text: twMerge(classNames("md:text-[12px]"))
                },
                onClick: (e: React.MouseEvent<Element, MouseEvent>) => handleUploadClick(e),
                text: "Attach file"
              }}
            />
          </div>
          {files.length > 0 ? (
            <div className="border border-dashed border-gray-800 rounded-2xl p-4 text-white text-center my-4">
              {Array.from(files).map((file) => (
                <div key={file.name} className="my-2.5 flex items-center justify-between">
                  <span className="flex items-center text-sm text-neutral-700 truncate">
                    {getAploadedInfo(file.name)?.filename && (
                      <LinkIcon className="w-[12px] h-[12px] mx-1" />
                    )}{" "}
                    {file.name} {getAploadedInfo(file.name)?.size}
                  </span>
                  <button
                    className="border rounded-md px-2 border-neutral-700 hover:text-primary-900"
                    onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                      handleRemoveFile(e, file.name)
                    }>
                    x
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="border border-dashed border-gray-800 rounded-sm p-14 text-neutral-500 text-sm text-center my-4">
              Attach files to upload
            </div>
          )}
          <div className="flex justify-end px-4">
            <DarkButton
              {...{
                customStyles: {
                  text: classNames({
                    "text-white": Boolean(files.length)
                  }),
                  button: "max-w-[96px] border-neutral-700  hover:shadow-white"
                },
                text: loading ? "Loading..." : "Upload",
                disabled: !Boolean(files.length),
                onClick: (e) => handleImportResources(e)
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
