import { Link } from "react-router-dom";
import classNames from "classnames";
import { Bounty } from "../../../../types/Bounty";
import { ClaimStatus } from "../../../../types/Claim";
import { useStoreActions, useStoreState } from "../../../../hooks";
import Mark from "../../../general/Mark";
import { Area, MarksLayer } from "../../../Layout";
import DaysFromNow from "../../../general/DaysFromNow";
import { AngleBracketsIcon, UserBorderlessIcon, UsersIcon } from "../../../general/SVGIcon";
import TokenAmount from "../../Amount/TokenAmount";
import CurrencyAmount from "../../Amount/CurrencyAmount";
import utils from "../../../../services/utils";
import { mapExperienceValueByName } from "../../general/helpers";

const SimpleBountyItem = (props: {
  bounty: Bounty;
  claimStatus?: ClaimStatus;
  showMark?: boolean;
  needAction?: boolean;
}) => {
  const { bounty, claimStatus, needAction, showMark = true } = props;
  const actions = useStoreActions((actions) => actions);
  const {
    bountyList: { setFilters }
  } = actions;
  const tokens = useStoreState((state) => state.app.tokens);
  const filters = useStoreState((state) => state.bountyList.filters);

  const calcTotalAmount = () => {
    const amount = parseInt(bounty.amount);
    const platformFee = parseInt(bounty.platform_fee);
    return (amount + platformFee).toString();
  };

  const handleClickTag = (e: React.MouseEvent, tag: string) => {
    e.preventDefault();
    setFilters({
      ...filters,
      tags: [{ name: tag, value: tag }, ...(filters?.tags || [])]
    });
  };

  return (
    <div className="mt-6 block flex-1">
      <Link to={`/bounties/bounty/${bounty.id}`}>
        <Area
          {...{
            customStyles: {
              borderContainer: classNames("border border-sm border-neutral-700"),
              contentContainer: classNames("hover:bg-neutral-700", {
                "bg-red-400/25": claimStatus && ["Rejected", "NotHired"].includes(claimStatus)
              })
            }
          }}>
          {showMark && (
            <MarksLayer>
              <Mark
                {...{
                  text: bounty.status === "ManyClaimed" ? "Claimed" : bounty.status,
                  customStyles: {
                    container: classNames("text-neutral-800", {
                      "bg-primary-900": bounty.status === "New",
                      "bg-post-secondary-900": bounty.status === "Claimed",
                      "bg-success": bounty.status === "Completed",
                      "bg-neutral-400": bounty.status === "Canceled"
                    })
                  }
                }}
              />
            </MarksLayer>
          )}
          <div className="flex lg:flex-row sm:flex-col justify-between py-4 font-poppins">
            <div className="flex flex-col justify-between flex-1">
              <div className="mx-4">
                <div className="py-1.5 text-ellipsis overflow-hidden">
                  <span className="mb-2">{bounty.metadata.title}</span>
                </div>
                <div className="flex flex-wrap gap-2 items-center justify-start text-md">
                  <span className="flex items-center gap-1 bg-[#DEDEDA80] text-sm text-neutral-50 rounded px-1.5">
                    <AngleBracketsIcon /> {bounty.metadata.category}
                  </span>
                  {bounty?.token && (
                    <>
                      <TokenAmount
                        tokens={tokens}
                        tokenId={bounty.token}
                        amount={calcTotalAmount()}
                        claimStatus={claimStatus}
                        className={"flex items-center gap-1 bg-[#73EA64]/20 rounded py-0 px-1.5"}
                        icon={
                          bounty?.multitasking?.multitasking_type === "OneForAll" ||
                          bounty?.multitasking?.multitasking_type === "DifferentTasks" ? (
                            <UsersIcon className="stroke-success" />
                          ) : (
                            <UserBorderlessIcon className="stroke-success" />
                          )
                        }
                      />
                    </>
                  )}
                  {bounty?.multitasking?.multitasking_type === "OneForAll" && bounty?.token && (
                    <>
                      <TokenAmount
                        tokens={tokens}
                        tokenId={bounty.token}
                        amount={bounty?.multitasking?.amount_per_slot || ""}
                        claimStatus={claimStatus}
                        className={
                          "flex items-center gap-1 bg-[#FFE890]/50 text-primary-900 rounded py-0 px-1.5"
                        }
                        icon={<UserBorderlessIcon />}
                        additionalText={"per Hunter"}
                      />
                    </>
                  )}
                  {bounty?.currency && (
                    <>
                      <CurrencyAmount
                        currency={bounty.currency}
                        amount={bounty.amount}
                        claimStatus={claimStatus}
                        className="flex items-center gap-1 rounded py-0 px-1.5"
                      />
                    </>
                  )}
                </div>
                <div className="flex flex-wrap gap-3.5 text-neutral-500 text-xs w-full my-2">
                  <div>
                    {!bounty.multitasking ? (
                      <>{!bounty?.postpaid ? "Fixed-price" : "Deferred payment"}</>
                    ) : (
                      <>
                        {bounty.multitasking.multitasking_type === "ContestOrHackathon" && (
                          <>Fixed-price</>
                        )}
                        {bounty.multitasking.multitasking_type === "OneForAll" && (
                          <>Price-Per-Slot</>
                        )}
                        {bounty.multitasking.multitasking_type === "DifferentTasks" && (
                          <>Price-Per-Task</>
                        )}
                      </>
                    )}
                  </div>
                  <div>Experience: {mapExperienceValueByName(bounty.metadata.experience)}</div>
                  <div>
                    <DaysFromNow className="text-xs" date={bounty.created_at} />
                  </div>
                  <div>{utils.pluralize(bounty.claims.length, "Applicant")}</div>
                  {bounty?.multitasking?.multitasking_type === "OneForAll" &&
                    bounty.multitasking?.number_of_slots &&
                    (bounty.multitasking?.runtime_env?.occupied_slots ||
                      bounty.multitasking?.runtime_env?.occupied_slots === 0) && (
                      <div>
                        {utils.pluralize(
                          bounty.multitasking.number_of_slots -
                            bounty.multitasking.runtime_env.occupied_slots,
                          "slot"
                        )}{" "}
                        available
                      </div>
                    )}
                </div>
              </div>
              <div className="py-1 mx-4 flex flex-wrap gap-1">
                {bounty.metadata.tags.map((tag) => (
                  <button
                    key={`${bounty.id}-tag-${tag}`}
                    disabled={!!filters?.tags?.find((el) => el.name === tag)}
                    className="text-sm text-white px-4 py-2 rounded-2xl bg-neutral-600 hover:bg-neutral-800"
                    onClick={(e: React.MouseEvent) => handleClickTag(e, tag)}>
                    {tag}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </Area>
      </Link>
    </div>
  );
};

export default SimpleBountyItem;
