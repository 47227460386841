import { forwardRef } from "react";

const BackdropUnstyled = forwardRef<HTMLDivElement, { open?: boolean; className: string }>(
  (props: any, ref: any) => {
    const { className, ...other } = props;

    return <div className={className} ref={ref} {...other} />;
  }
);

BackdropUnstyled.displayName = "BackdropUnstyled";

export default BackdropUnstyled;
