import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type ButtonStyles = {
  button?: string;
  text?: string;
  textWrap?: string;
};

interface ButtonProps {
  customStyles?: ButtonStyles;
  text?: string;
  onClick?: MouseEventHandler;
  children?: ReactNode | ReactNode[];
  disabled?: boolean;
  type?: "submit" | "button";
}

export { type ButtonProps };

const disabledStyles = {
  button: `
    bg-neutral-700
    border-neutral-400
    hover:shadow-initial
  `,
  text: `
    text-neutral-600
  `
};

const buildStyles = (customStyles: ButtonStyles | undefined) => ({
  button: twMerge(
    classNames("border-sm rounded-full px-[1em] py-[0.56em] outline-none"),
    customStyles?.button
  ),
  text: twMerge(classNames("font-poppins", customStyles?.text))
});

function Button(props: ButtonProps) {
  const { customStyles, text, onClick, children, disabled } = props;
  const type = props.type || "button";

  const styles = buildStyles({
    button: (customStyles?.button || "") + (!!disabled ? disabledStyles.button : ""),
    text: (customStyles?.text || "") + (!!disabled ? disabledStyles.text : "")
  });

  const disabledOnClick = () => {};

  return (
    <button
      className={styles.button}
      onClick={disabled ? disabledOnClick : onClick}
      disabled={disabled}
      type={type}>
      {children ? children : <p className={styles.text}>{text ? text : null}</p>}
    </button>
  );
}

export default Button;
