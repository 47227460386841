import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import {
  Validation,
  DEFAULT_STYLES,
  DEFAULT_VALUE,
  INPUT_BORDER_STYLES
} from "../../../helpers/Validation";
import Label from "../../general/Input/Label";

type InputStyles = {
  container?: string;
  input?: string;
  label?: string;
};

type TLabel = {
  view?: ReactNode;
  text: string;
};

interface ValidationLayoutProps {
  customStyles?: InputStyles;
  label?: TLabel;
  children?: ReactNode | ReactNode[];
  validate?: () => Validation;
}

const buildStyles = (customStyles: InputStyles | undefined, validation: Validation) => ({
  container: twMerge(`
    w-full
    rounded-[10px]
    ${customStyles?.container ? customStyles.container : ""}
  `),
  input: twMerge(`
    w-full
    rounded-[10px]
    ${
      validation.started
        ? validation.valid
          ? DEFAULT_STYLES.valid.input
          : DEFAULT_STYLES.invalid.input
        : `${INPUT_BORDER_STYLES.valid} text-neutral-400`
    }
    ${customStyles?.input ? customStyles.input : ""}
  `),
  label: {
    labelText: `
      ${customStyles?.label ? customStyles.label : ""}
    `,
    validationText: `
      ${DEFAULT_STYLES.invalid.message}
    `
  }
});

export default function ValidationLayout(props: ValidationLayoutProps) {
  const { customStyles, label, children } = { ...props };
  const validation = (props?.validate && props.validate()) || DEFAULT_VALUE;

  const styles = buildStyles(customStyles, validation);

  return (
    <div className={styles.container}>
      <div className="min-h-[var(--font-line-height-xs)]">
        <Label
          {...{
            customStyles: styles.label,
            labelText: label?.text || "",
            validationText: validation.message || ""
          }}
        />
      </div>
      <div className={styles.input}>{children}</div>
    </div>
  );
}
