import { useState } from "react";
import { useStoreActions } from "../../../hooks";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import utils from "../../../services/utils";
import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { ConfirmPaymentForm, FinalExecutionOutcomeForm } from "../Dialogues";
import ActionButton from "./general/ActionButton";
import { FinalExecutionOutcome } from "@near-wallet-selector/core";

const ConfirmPaymentAction = (props: {
  claim: Claim;
  bounty: Bounty;
  refetchActions?: () => void;
}) => {
  const { claim, bounty, refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<FinalExecutionOutcome | null>(null);
  const { walletAccountId } = useWalletAccountId();
  const setPaymentConfirmedV2 = useStoreActions((actions) => actions.nearApi.setPaymentConfirmedV2);
  const [loading, setLoading] = useState(false);

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, claim, walletAccountId);
    return a.checkAccess([!a.isClaimer()]);
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const result = await setPaymentConfirmedV2({
      id: bounty.id,
      claim_number: claim.claim_number
    });
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
      refetchActions && refetchActions();
    }
  };

  return (
    <>
      <ActionButton
        text="Confirm payment"
        disabled={isDisabled()}
        handleClick={handleButtonClick}
      />
      <ConfirmPaymentForm
        show={showForm}
        loading={loading}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  );
};

export default ConfirmPaymentAction;
