import { useState } from "react";
import { useStoreActions } from "../../../hooks";
import { Bounty } from "../../../types/Bounty";
import { Claim } from "../../../types/Claim";
import { FinalExecutionOutcomeForm, MarkAsPaidForm } from "../Dialogues";
import ActionButton from "./general/ActionButton";
import { providers } from "near-api-js";

const MarkAsPaidAction = (props: {
  claim?: Claim;
  bounty: Bounty;
  claimer?: string;
  refetchActions: () => void;
}) => {
  const { bounty, claim, claimer, refetchActions } = props;
  const [showForm, setShowForm] = useState(false);
  const [showFinalExecutionOutcomeForm, setShowFinalExecutionOutcomeForm] = useState(false);
  const [executionOutcome, setExecutionOutcome] = useState<providers.FinalExecutionOutcome | null>(
    null
  );
  const setAsPaidV2 = useStoreActions((actions) => actions.nearApi.setAsPaidV2);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const result = await setAsPaidV2({
      id: bounty.id,
      claim_number: claim?.claim_number,
      receiver_id: claimer
    });
    setLoading(false);
    console.log(result);
    if (result) {
      setShowForm(!showForm);
      setExecutionOutcome(result);
      setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm);
    }
  };

  return (
    <>
      <ActionButton text="Set As Paid" disabled={false} handleClick={handleButtonClick} />
      <MarkAsPaidForm
        bounty={bounty}
        show={showForm}
        loading={loading}
        handleClose={handleButtonClick}
        handleSubmit={handleSubmitForm}
      />
      <FinalExecutionOutcomeForm
        show={showFinalExecutionOutcomeForm}
        executionOutcome={executionOutcome}
        refetchActions={refetchActions}
        handleClose={() => setShowFinalExecutionOutcomeForm(!showFinalExecutionOutcomeForm)}
      />
    </>
  );
};

export default MarkAsPaidAction;
