import { Area } from "../../../Layout";
import { DarkButton, LightButton } from "../../../general/Button";
import utils from "../../../../services/utils";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { InfoIcon, PencilIcon } from "../../../general/SVGIcon";
import { ReactNode, useMemo } from "react";
import { claimerApprovalDefValues } from "../../../../constants";
import RightArrowLong from "../../../general/SVGIcon/rightArrowLong";
import {
  ClaimantApproval,
  ContestOrHackathon,
  MoreReviewers,
  OneForAll
} from "../../../../types/BountyCreate";
import SubtaskItem from "../general/SubtaskItem";
import ReactMarkdown from "react-markdown";
import { mapExperienceValueByName } from "../general/helpers";
import { formatPercents } from "./components/Multitasking/DifferentTasks/helpers";
import useFtTokenBalance from "../../../../hooks/useFtTokenBalance";
import { getTokenDecimals } from "../../../Bounty/general/helpers";
import moment from "moment";
import { dateCompare } from "./helpers";
import { FormRow } from "../../../general/Form/FormElements";

export type AreaContentContainerProps = {
  title: string;
  value: string;
};

const Review = () => {
  const actions = useStoreActions((actions) => actions);
  const newBountyForm = useStoreState((state) => state.newBountyForm);
  const newBounty = useStoreState((state) => state.newBounty);
  const config = useStoreState((state) => state.app.config);
  const {
    newBountyForm: { prev },
    nearApi: { addProposalV2, bountyCreateV2, ftTransferCallV2 },
    resetState
  } = actions;
  const max_due_date = useStoreState((state) => state.app.config?.max_due_date);

  const { availableBalance, isInitialLoading } = useFtTokenBalance({
    tokenId: newBountyForm.selectedTokenAccount?.name
  });

  const tokenDecimals = useMemo(() => {
    return getTokenDecimals(newBountyForm.selectedTokenAccount?.name ?? "");
  }, [newBountyForm.selectedTokenAccount]);

  const parsedBalance = useMemo(() => {
    return availableBalance
      ? utils.formatTokenAmount(availableBalance, tokenDecimals, tokenDecimals)
      : 0;
  }, [availableBalance, tokenDecimals]);

  const isValidMaxAmount = (value: string) => {
    return (
      parseFloat(parsedBalance.toString()) >= parseFloat(parseFloat(value).toFixed(tokenDecimals))
    );
  };

  const getMaxDueDate = () => {
    return max_due_date
      ? moment(moment().valueOf() + (max_due_date ? parseInt(max_due_date) / 1000000 : 0)).toDate()
      : undefined;
  };

  const isValidDeadline = () => {
    const deadline = newBounty.deadline && utils.getDeadline(newBounty.deadline);
    if (deadline) {
      const date = moment(deadline, "DD-MM-YYYY");
      if (!date.isValid()) {
        return false;
      }
      const maxDate = getMaxDueDate();
      return (
        !dateCompare.isToday(date.toDate()) &&
        !dateCompare.isPastDay(date.toDate()) &&
        !dateCompare.isAfterMaxDay(date.toDate(), maxDate)
      );
    }
    return false;
  };

  const handleBackClick = () => {
    prev();
  };

  const handleReset = () => {
    resetState();
  };

  const handleSubmit = async () => {
    const payload = {
      args: {
        receiver_id: config?.bounty_contract_id ?? "",
        amount: newBountyForm.amount,
        msg: JSON.stringify(newBounty)
      },
      tokenId: newBountyForm.selectedTokenAccount?.name ?? ""
    };
    if (newBountyForm.submitAsNearAccount) {
      if (!newBounty.postpaid) {
        await ftTransferCallV2(payload);
      } else {
        await bountyCreateV2(newBounty);
      }
    } else {
      await addProposalV2(payload);
    }
  };

  function CardContainer({
    title,
    btnEdit,
    children
  }: {
    title?: string;
    btnEdit?: () => void;
    children: ReactNode;
  }) {
    return (
      <div className="font-poppins w-full px-4 py-2.5 rounded-[10px] bg-neutral-800">
        <FormRow className="flex justify-between items-center">
          {title && <h2 className="text-xs text-neutral-500">{title}</h2>}
          {btnEdit && (
            <button
              onClick={btnEdit}
              className="flex gap-2 items-center text-xs mx-2 text-neutral-400 outline-none">
              Edit <PencilIcon className="w-3 h-3 stroke-neutral-700" />
            </button>
          )}
        </FormRow>
        {children}
      </div>
    );
  }

  function getClaimerApproval(value: ClaimantApproval) {
    if (value) {
      if (typeof value === "string") {
        return claimerApprovalDefValues.find((el) => el.name === newBounty.claimant_approval)
          ?.value;
      } else {
        return claimerApprovalDefValues.find((el) =>
          Object.prototype.hasOwnProperty.call(value, el.name)
        )?.value;
      }
    }
  }

  return (
    <div className="font-poppins">
      <Area
        {...{
          customStyles: {
            borderContainer: "border border-neutral-700",
            contentContainer: "py-8 px-2.5"
          }
        }}>
        <div className="text-md">
          <Area>
            <div className="flex flex-col gap-4 px-2">
              <div className="flex items-center gap-2">
                <div className="px-2 py-1 bg-[#34301D] text-primary-900 rounded">
                  {newBounty.metadata.category}
                </div>
                <h2 className="text-neutral-50">{newBounty.metadata.title}</h2>
              </div>
              <div className="grid grid-cols-2 items-center">
                <div className="text-xs text-neutral-500">
                  Experience Level: {mapExperienceValueByName(newBounty.metadata.experience)}
                </div>
                <div className="flex flex-wrap justify-end gap-2">
                  {Array.isArray(newBounty.metadata.tags) &&
                    newBounty.metadata.tags.map((tag) => (
                      <span
                        key={tag}
                        className="flex items-center px-1 py-0.5 bg-neutral-700 rounded text-xs text-neutral-400">
                        {tag}
                      </span>
                    ))}
                </div>
              </div>
              <div className="flex flex-col gap-2.5">
                <CardContainer title="Description" btnEdit={() => prev("details")}>
                  <ReactMarkdown className="markdown">
                    {newBounty.metadata.description}
                  </ReactMarkdown>
                </CardContainer>
                {newBounty.multitasking && "DifferentTasks" in newBounty.multitasking && (
                  <CardContainer title="Subtasks">
                    {newBounty.multitasking.DifferentTasks.subtasks.map((subtask, index) => (
                      <SubtaskItem
                        customStyles={{ wrapper: "border border-neutral-700", text: "text-[10px]" }}
                        key={`task-${index}`}
                        description={subtask.subtask_description}
                        percents={formatPercents(subtask)}
                      />
                    ))}
                  </CardContainer>
                )}
                {newBounty.metadata.acceptance_criteria && (
                  <CardContainer title="Acceptance Criteria">
                    <p className="text-xs text-neutral-50">
                      {newBounty.metadata.acceptance_criteria}
                    </p>
                  </CardContainer>
                )}
                <CardContainer title="Reviewers" btnEdit={() => prev("additional_info")}>
                  <p className="text-xs text-neutral-50">
                    {(newBounty?.reviewers as MoreReviewers)?.MoreReviewers?.more_reviewers
                      ? (
                          newBounty?.reviewers as MoreReviewers
                        )?.MoreReviewers?.more_reviewers?.join(", ")
                      : "None"}
                  </p>
                </CardContainer>
                <CardContainer
                  title="Application Customization"
                  btnEdit={() => prev("additional_info")}>
                  <p className="text-xs text-neutral-50">
                    {getClaimerApproval(newBounty.claimant_approval)}
                  </p>
                </CardContainer>
                <div className="flex gap-2">
                  <CardContainer title="KYC" btnEdit={() => prev("additional_info")}>
                    <div className="flex justify-between">
                      <div>
                        <p className="text-xs text-neutral-50">
                          {newBounty.kyc_config === "KycNotRequired" ? "No" : "Yes"}
                        </p>
                      </div>
                    </div>
                  </CardContainer>
                  <CardContainer title="Create an invoice" btnEdit={() => prev("payment")}>
                    <div className="flex justify-between">
                      <div className="text-center">
                        <p className="text-xs text-neutral-50">
                          {newBountyForm.generateInvoice ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                  </CardContainer>
                </div>
                <div className="flex gap-2">
                  <CardContainer title="Deadline" btnEdit={() => prev("duration")}>
                    <div className="flex gap-2 text-xs text-neutral-50">
                      <span>Now</span>
                      <RightArrowLong />
                      {newBounty.deadline
                        ? !(newBounty?.multitasking as ContestOrHackathon)?.ContestOrHackathon
                            ?.allowed_create_claim_to && (
                            <span>{utils.getDeadline(newBounty.deadline)}</span>
                          )
                        : "Not Specified"}
                      <span>
                        {(newBounty?.multitasking as ContestOrHackathon)?.ContestOrHackathon
                          ?.allowed_create_claim_to &&
                          utils.getDeadlineInMultitasking(
                            (newBounty?.multitasking as ContestOrHackathon)?.ContestOrHackathon
                              ?.allowed_create_claim_to
                          )}
                      </span>
                    </div>
                    {!isValidDeadline() && (
                      <div className="text-xs text-red-500 text-left">
                        The deadline is incorrect!
                      </div>
                    )}
                  </CardContainer>
                  {(newBounty?.multitasking as OneForAll)?.OneForAll?.number_of_slots && (
                    <CardContainer>
                      <h6 className="text-xs text-neutral-500 mb-1.5">Application slots</h6>
                      <p className="text-xs text-neutral-50">
                        {(newBounty?.multitasking as OneForAll)?.OneForAll?.number_of_slots}
                      </p>
                    </CardContainer>
                  )}
                </div>
                <CardContainer title="Bounty price" btnEdit={() => prev("payment")}>
                  <div className="text-xs">
                    <div className="flex justify-between text-neutral-50">
                      <span>Bounty Value</span>
                      <div>
                        {!newBounty?.postpaid && (
                          <span>
                            {utils.formatWithCommas(
                              newBountyForm.payment.toString(),
                              tokenDecimals
                            )}{" "}
                            {newBountyForm.selectedTokenAccount?.value}
                          </span>
                        )}
                        {newBounty?.postpaid &&
                          "PaymentOutsideContract" in { ...newBounty.postpaid } && (
                            <span>
                              {utils.formatWithCommas(
                                newBountyForm.offContractPayment?.payment?.toString() ?? "0"
                              )}{" "}
                              {newBounty.postpaid.PaymentOutsideContract.currency ?? ""}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="h-[1px] my-1.5 bg-neutral-600" />
                    <div className="flex justify-between text-neutral-50">
                      <span>Total</span>
                      {!newBounty?.postpaid && (
                        <>
                          <div className="text-primary-900">
                            {utils.formatWithCommas(newBountyForm.total.toString(), tokenDecimals)}{" "}
                            {newBountyForm.selectedTokenAccount?.value}
                          </div>
                        </>
                      )}
                      {newBounty?.postpaid &&
                        "PaymentOutsideContract" in { ...newBounty.postpaid } && (
                          <div className="text-primary-900">
                            {utils.formatWithCommas(newBountyForm.offContractTotal.toFixed(2))}{" "}
                            {newBounty.postpaid.PaymentOutsideContract.currency ?? ""}
                          </div>
                        )}
                    </div>
                    {!isInitialLoading && !isValidMaxAmount(newBountyForm.total.toString()) && (
                      <div className="text-xs text-red-500 text-right">
                        There are not enough funds on the token balance!
                      </div>
                    )}
                  </div>
                </CardContainer>
              </div>
            </div>
          </Area>
        </div>

        <div className="mt-3.5">
          <Area>
            <div className="flex items-center">
              <div className="pl-6 text-neutral-400">
                <InfoIcon />
              </div>
              <div className="py-5 px-6">
                <p className="text-sm text-neutral-400">
                  By submitting the form you are agreeing with the{" "}
                  <a
                    href="/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-900">
                    Terms of Use
                  </a>
                  .{" "}
                  {!newBounty.postpaid && (
                    <>
                      After clicking on Confirm you will be redirected to the NEAR Wallet to
                      immediately sign the transaction and transfer funds to the escrow contract.
                    </>
                  )}
                </p>
              </div>
            </div>
          </Area>
        </div>
      </Area>
      <div className="my-6">
        <div className="flex gap-4 justify-center">
          <DarkButton
            {...{
              text: "Back",
              onClick: () => handleBackClick()
            }}
          />

          {/* <DarkButton
            {...{
              text: "Reset",
              onClick: () => handleReset()
            }}
          /> */}

          <LightButton
            {...{
              disabled: !isValidMaxAmount(newBountyForm.total.toString()) || !isValidDeadline(),
              text: "Confirm",
              onClick: () => handleSubmit(),
              type: "submit"
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Review;
