import Separator from "../../general/Separator";

const GroupSeparator = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-row flex-nowrap items-center mt-6">
      <div className="flex flex-col w-full px-2">
        <Separator
          customStyles={{
            container: "py-4",
            separator: " bg-gradient-to-r from-neutral-900  to-neutral-700"
          }}
        />
      </div>
      <div className="text-sm text-neutral-400 flex justify-center w-full">{title}</div>
      <div className="flex flex-col w-full px-2">
        <Separator
          customStyles={{
            container: "py-2",
            separator: " bg-gradient-to-l from-neutral-900  to-neutral-700"
          }}
        />
      </div>
    </div>
  );
};

export default GroupSeparator;
