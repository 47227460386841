import { ReactNode } from "react"

type Style = {
  image?: string,
  imageSize?: string,
  radius?: string,
  width?: string,
}

interface GradientBorderProps {
  children: ReactNode[] | ReactNode,
  style?: Style,
  hidden?: boolean,
}

const gradientBorderStyle=`
  w-full h-full
  gradient-border-mask
  before:absolute
  before:inset-[0]
  before:bg-center
  before:bg-no-repeat
  before:pointer-events-none
  md:before:p-[theme(borderWidth.md)]
  lg:before:p-[theme(borderWidth.lg)]
`

const defaultStyle = {
  image: 'before:bg-benefits-section__item-border',
  imageSize: 'before:bg-cover',
  radius: 'before:rounded-header',
  width: 'before:p-[theme(borderWidth.sm)]',
}

const styles={
  borderContainer: `
    relative w-full h-full
  `,
  contentContainer: `
    ${gradientBorderStyle}
  `,
}

const GradientBorderV2 = function(props: GradientBorderProps) {
  const style = {...defaultStyle, ...(props.style ? props.style : {})}
  const { children, hidden } = props;

  return (
    <div className={styles.borderContainer}>
      <div className={(hidden ? 'bg-transparent h-full': styles.contentContainer) + Object.values(style).join("\n")}>
        { children }
      </div>
    </div>
  )
}

export default GradientBorderV2;

