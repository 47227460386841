import { isPast, isAfter, isToday } from "date-fns";

const isDayToday = (date: Date) => {
  return isToday(date);
};

const isPastDay = (date: Date) => {
  return isPast(date);
};

const isAfterMaxDay = (date: Date, maxDate?: Date) => {
  if (maxDate) {
    return isAfter(date, maxDate);
  }
  return false;
};

export const dateCompare = {
  isToday: isDayToday,
  isPastDay,
  isAfterMaxDay
};
