import { Bounty } from "../../../../types/Bounty";
import { DarkButton } from "../../../general/Button";
import Modal from "../../../general/Modal";
import CurrencyForm from "./CurrencyForm";

const UpdateBalanceForm = (props: {
  bounty: Bounty;
  show: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}) => {
  const { bounty, show, handleClose, handleSubmit } = props;

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                onClick: () => handleSubmit(),
                text: "Send"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white">Update bounty balance.</div>
        <div className="py-2.5 text-neutral-200">
          <CurrencyForm bounty={bounty} />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateBalanceForm;
