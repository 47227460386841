import { useStoreState } from "../../../hooks";
import { TwitterXIconSVG } from "../../general/SVGIcon/Social";
import { getAuthUrl } from "../general/helpers";
import LoginButton from "../general/LoginButton";

const TwitterLoginProvider = () => {
  const signInError = useStoreState((state) => state.auth.signInError);
  const useTwitterLogin = useStoreState((state) => state.app.config?.use_twitter_login ?? false);

  const handleButtonClick = async () => {
    const authUrl = await getAuthUrl("twitter");
    if (authUrl) {
      window.location.assign(authUrl);
    }
  };

  return (
    <>
      {useTwitterLogin && (
        <LoginButton
          handleButtonClick={handleButtonClick}
          icon={<TwitterXIconSVG className="w-6 h-6 fill-neutral-850 text-center" />}
        />
      )}
      {signInError ? (
        <div>
          <p className="text-sm text-secondary-900">{signInError}</p>
        </div>
      ) : null}
    </>
  );
};

export default TwitterLoginProvider;
