import { useState, useEffect } from "react";
import Api from "../services/Api";
import { HunterDetails } from "../types/Hunter";

const useHunterDetails = (props: { hunterId: string | undefined }) => {
  const { hunterId } = props;
  const [hunter, setHunter] = useState<HunterDetails | null>(null);

  const loadHunter = async (hunter_id: string) => {
    const hunter = await Api.getHunter(hunter_id);
    if (hunter) {
      setHunter(hunter);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (hunterId) {
      loadHunter(hunterId);
    }
  }, [hunterId]);

  return { hunter };
};

export default useHunterDetails;
