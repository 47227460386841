import { useState } from "react";
import { useStoreActions } from "../../../../hooks";
import IconButton from "../../../general/Button/IconButton";
import { TrashIcon } from "../../../general/SVGIcon";
import DeleteWhitelistForm from "../Dialogues/DeleteWhitelistForm";

const DeleteWhitelistAction = (props: { id: string | number; onReload: () => Promise<void> }) => {
  const { id, onReload: reload } = props;
  const [showForm, setShowForm] = useState(false);
  const deleteAccountWhitelist = useStoreActions(
    (actions) => actions.accountWhitelists.deleteAccountWhitelist
  );
  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleDeleteWhitelist = async () => {
    setShowForm(false);
    await deleteAccountWhitelist({ id });
    await reload();
  };

  return (
    <div>
      <IconButton
        {...{
          icon: <TrashIcon className="stroke-neutral-200" />,
          onClick: () => handleButtonClick()
        }}
      />
      <DeleteWhitelistForm
        show={showForm}
        handleClose={handleButtonClick}
        handleSubmit={handleDeleteWhitelist}
      />
    </div>
  );
};

export default DeleteWhitelistAction;
