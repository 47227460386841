import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { claimerApprovalDefValues } from "../../../../../../constants";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import useUserAccountWhitelists from "../../../../../../hooks/useUserAccountWhitelists";
import { ClaimantApproval } from "../../../../../../types/BountyCreate";
import AccountWhitelists from "../AccountWhitelists";
import { FormRow } from "../../../../../general/Form/FormElements";
import CheckBox from "../../../../../general/CheckBox";
import MultitaskingOptions from "../Multitasking";

export type RestrictionsFormData = {
  claimant_approval: ClaimantApproval;
};

const BountyRestrictions = () => {
  const methods = useFormContext<RestrictionsFormData>();
  const { formState, setValue } = methods;

  const { errors, isSubmitted } = formState;
  const actions = useStoreActions((actions) => actions);

  const { claimant_approval } = useStoreState((state) => state.newBounty);
  const useHunterManualApproval = useStoreState(
    (state) => state.newBountyForm.useHunterManualApproval
  );
  const useRestrictByWhitelist = useStoreState(
    (state) => state.newBountyForm.useRestrictByWhitelist
  );
  const {
    newBounty: { setClaimerApproval, setBountyFlow, setAllowDeadlineStretch },
    newBountyForm: { setHunterManualApproval, setRestrictByWhitelist }
  } = actions;
  const multitasking = useStoreState((state) => state.newBounty.multitasking);
  const bounty_flow = useStoreState((state) => state.newBounty.bounty_flow);
  const { accountWhitelists, loadWhitelists } = useUserAccountWhitelists();

  const mapClaimerApproval = (claimerApproval: ClaimantApproval) => {
    if (typeof claimerApproval === "string") {
      return claimerApproval;
    } else if (typeof claimerApproval === "object") {
      return Object.keys(claimerApproval)[0];
    }
    return "";
  };

  const handleBountyFlowChange = () => {
    const newValue = bounty_flow === "SimpleBounty" ? "AdvancedFlow" : "SimpleBounty";
    setBountyFlow(newValue);
    if (newValue === "SimpleBounty") {
      setClaimerApproval("WithoutApproval");
      setAllowDeadlineStretch(undefined);
    } else {
      setClaimerApproval("MultipleClaims");
      setAllowDeadlineStretch(true);
    }
  };

  const claimerApprovalSelectedValue = useMemo(() => {
    return claimerApprovalDefValues.find(
      (item) => item.name === mapClaimerApproval(claimant_approval)
    );
  }, [claimant_approval]);

  const handleSetMultipleClaims = () => {
    setClaimerApproval("MultipleClaims");
    setValue("claimant_approval", "MultipleClaims", {
      shouldValidate: true
    });
  };

  const handleSetWithoutApproval = () => {
    setClaimerApproval("WithoutApproval");
    setValue("claimant_approval", "WithoutApproval", {
      shouldValidate: true
    });
  };

  const handleSetApprovalByWhitelist = () => {
    const approval = { ApprovalByWhitelist: { claimants_whitelist: [] } };
    setClaimerApproval(approval);
    setValue("claimant_approval", approval, {
      shouldValidate: true
    });
  };

  const handleSetWhitelistWithApprovals = () => {
    const approval = { WhitelistWithApprovals: { claimants_whitelist: [] } };
    setClaimerApproval(approval);
    setValue("claimant_approval", approval, {
      shouldValidate: true
    });
  };

  const _handleCheckHunterManualApproval = () => {
    const value = !useHunterManualApproval;
    setHunterManualApproval(value);
    console.log("value", value);
    if (!value && !useRestrictByWhitelist) {
      handleSetWithoutApproval();
    } else if (useRestrictByWhitelist) {
      handleSetApprovalByWhitelist();
    } else {
      handleSetMultipleClaims();
    }
  };

  const handleCheckHunterManualApproval = () => {
    const checked = !useHunterManualApproval;
    setHunterManualApproval(checked);
    if (!checked) {
      if (!useRestrictByWhitelist) {
        handleSetWithoutApproval();
      } else {
        handleSetApprovalByWhitelist();
      }
    } else {
      if (useRestrictByWhitelist) {
        handleSetWhitelistWithApprovals();
      } else {
        handleSetMultipleClaims();
      }
    }
  };

  const handleRestrictByWhitelist = () => {
    const checked = !useRestrictByWhitelist;
    setRestrictByWhitelist(checked);
    if (!checked) {
      if (!useHunterManualApproval) {
        handleSetWithoutApproval();
      } else {
        handleSetMultipleClaims();
      }
    } else {
      if (useHunterManualApproval) {
        handleSetWhitelistWithApprovals();
      } else {
        handleSetApprovalByWhitelist();
      }
    }
  };

  useEffect(() => {
    setValue("claimant_approval", claimant_approval, {
      shouldValidate: true
    });
    if (
      claimant_approval === "MultipleClaims" ||
      (typeof claimant_approval === "object" && "WhitelistWithApprovals" in claimant_approval)
    ) {
      setHunterManualApproval(true);
    } else {
      setHunterManualApproval(false);
    }
  }, [claimant_approval]);

  return (
    <>
      <FormRow className="mt-3.5 flex flex-wrap justify-between gap-2">
        <CheckBox
          {...{
            active: bounty_flow !== "SimpleBounty",
            disabled: false,
            onClick: () => handleBountyFlowChange(),
            text: "Use Hunter`s Apply Process",
            textInfo:
              bounty_flow === "SimpleBounty"
                ? "To start working on this bounty a Hunter should first send a request."
                : "Hunter can submit his work instantly after bounty has been created."
          }}
        />
        <CheckBox
          {...{
            active: useHunterManualApproval || "WhitelistWithApprovals" in { claimant_approval },
            disabled:
              (multitasking && "ContestOrHackathon" in multitasking) ||
              bounty_flow === "SimpleBounty",
            onClick: () => handleCheckHunterManualApproval(),
            text: `Manually approve bounty applicants`,
            textInfo:
              useHunterManualApproval || "WhitelistWithApprovals" in { claimant_approval }
                ? "You will have to approve Hunter's candinature before he could start work on it."
                : "After an apply request a Hunter can start work immediately."
          }}
        />
        <CheckBox
          {...{
            active: useRestrictByWhitelist,
            onClick: () => handleRestrictByWhitelist(),
            text: `Use a whitelist`,
            textInfo: "Limit this bounty to people in your whitelist only"
          }}
        />
      </FormRow>
      {useRestrictByWhitelist && claimerApprovalSelectedValue && (
        <FormRow>
          <input
            type="hidden"
            {...methods.register("claimant_approval", { required: "Required" })}
          />

          <AccountWhitelists
            whitelists={accountWhitelists}
            selectedApproval={claimerApprovalSelectedValue}
            reload={loadWhitelists}
          />
        </FormRow>
      )}

      <MultitaskingOptions />
    </>
  );
};

export default BountyRestrictions;
