import { useState, useEffect } from "react";
import { NewBountyForm } from "../../../../../../types";
import Modal from "../../../../../general/Modal";
import { DarkButton, LightButton } from "../../../../../general/Button";
import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import { txViewUrl } from "../../../../../Bounty/general/helpers";
import { urlDefSearchParams } from "../../../../../../store/models/BountyListModel";
import Api from "../../../../../../services/Api";
import { BountyTransaction } from "../../../../../../types/BountyTransaction";
import Firework from "./general/firework.gif";
import ShareButton from "./general/ShareButton";

type MessageProps = {
  status: NewBountyForm.CreateBountyStatus;
};

function StatusMessage() {
  const navigate = useNavigate();
  const actions = useStoreActions((actions) => actions);
  const walletSelector = useStoreState((state) => state.nearApi.walletSelector);
  const createBountyStatus = useStoreState((state) => state.newBountyForm.createBountyStatus);
  const transactionHashes = useStoreState((state) => state.nearApi.transactionHashes);
  const [transaction, setTransaction] = useState<BountyTransaction | null | undefined>(null);

  const loadBounty = async () => {
    const response = await Api.getTransactionByHash(transactionHashes);
    setTransaction(response);
  };

  const {
    newBountyForm: { setCreateBountyStatus },
    resetState
  } = actions;

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (createBountyStatus === "New") return;
    if (createBountyStatus === "CheckReceipt") setShowMessage(true);
    if (createBountyStatus === "Success") {
      setShowMessage(true);
      loadBounty();
    }
    if (createBountyStatus === "Failure") setShowMessage(true);
  }, [createBountyStatus]);

  const handleReturnToEditing = () => {
    setCreateBountyStatus("New");
    setShowMessage(false);
  };

  const clearState = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    resetState();
    setShowMessage(!showMessage);
  };

  const handleCloseClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    resetState();
    navigate(`/bounties?${urlDefSearchParams()}`, { replace: true });
  };

  const handleViewBountyClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    resetState();
    navigate(`/bounties/bounty/${transaction?.bountyId}`, { replace: true });
  };

  const handleCloseModal = () => {
    setShowMessage(false);
    if (createBountyStatus === "Failure") {
      setCreateBountyStatus("New");
    }
    if (createBountyStatus === "Success") {
      resetState();
    }
  };

  const Message = (props: MessageProps) => {
    const { status } = props;
    switch (status) {
      case "CheckReceipt":
        return (
          <div className="font-poppins flex-1 flex flex-col items-center justify-center text-white">
            <p className="text-lg md-2 text-center">Wait while the receipt is being checked.</p>
          </div>
        );
      case "Failure":
        return (
          <div className="font-poppins flex-1 flex flex-col justify-between text-white">
            <div>
              <span className="text-lg md-2">Receipt: Status "Failure".</span>
              <p className=" break-words">
                View the transaction for more details:{" "}
                <a
                  className="underline text-gray-700"
                  href={txViewUrl(transactionHashes, walletSelector)}
                  target="_blank"
                  rel="noreferrer noopener">
                  {txViewUrl(transactionHashes, walletSelector)}
                </a>
              </p>
            </div>
            <div className="flex gap-2  justify-between">
              <DarkButton
                {...{
                  text: "Return to editing",
                  onClick: () => handleReturnToEditing()
                }}
              />
              <LightButton
                {...{
                  text: "Start again",
                  onClick: (e: React.MouseEvent<Element, MouseEvent>) => clearState(e)
                }}
              />
            </div>
          </div>
        );
      case "Success":
        return (
          <div className="font-poppins flex-1 flex flex-col justify-between text-white">
            <div className="flex-1 flex items-center justify-center">
              <p className="text-center">Congratz! Your bounty is live!</p>
            </div>
            <div className="flex items-center justify-center my-2.5">
              <img src={Firework} alt="" />
            </div>
            {transaction && (
              <div className="flex justify-center mt-4">
                <ShareButton tx={transaction} />
              </div>
            )}

            <div className="flex justify-between my-2.5 mt-12">
              <DarkButton
                {...{
                  customStyles: { button: "max-w-[150px]" },
                  text: "Close",
                  onClick: (e: React.MouseEvent<Element, MouseEvent>) => handleCloseClick(e)
                }}
              />
              <LightButton
                {...{
                  customStyles: { button: "max-w-[150px]" },
                  text: "See bounty",
                  onClick: (e: React.MouseEvent<Element, MouseEvent>) => handleViewBountyClick(e)
                }}
              />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Modal
        {...{
          customStyles: { content: "h-fit min-h-[300px]" },
          open: showMessage,
          onClose: () => handleCloseModal()
        }}>
        <Message status={createBountyStatus} />
      </Modal>
    </>
  );
}

export default StatusMessage;
