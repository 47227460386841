import classNames from "classnames";

export default function InfoIcon(props: { className?: string }) {
  const { className } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className)}
    >
      <path
        d="M9.25 9.25L9.291 9.23C9.41923 9.16594 9.56313 9.13997 9.70567 9.15516C9.8482 9.17035 9.9834 9.22606 10.0952 9.31571C10.2071 9.40536 10.2909 9.52518 10.3368 9.66099C10.3826 9.79679 10.3886 9.9429 10.354 10.082L9.646 12.918C9.61114 13.0572 9.61694 13.2034 9.66269 13.3394C9.70845 13.4754 9.79224 13.5954 9.90414 13.6852C10.016 13.775 10.1513 13.8308 10.294 13.846C10.4367 13.8612 10.5807 13.8352 10.709 13.771L10.75 13.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 6.25H10.008V6.258H10V6.25Z"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
