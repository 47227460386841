import { useState } from "react";

import { ValidationLayout } from "../../Layout";
import { Input, TextArea } from "../../general/Input";
import { DarkButton, LightButton } from "../../general/Button";
import { DEFAULT_STYLES, isNotEmpty, validateUrl } from "../../../helpers/Validation";
import { useStoreState } from "../../../hooks";
import { IndividualMetadata } from "../../../types/Auth";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../general/CheckBox";
import { Option } from "../../general/Select/Select";
import { NewSelect } from "../../general/Select";
import NewMultiSelect from "../../general/Select/NewMultiSelect";

interface SignInProps {
  onSubmit: (...args: any[]) => void;
  submitDisabled?: boolean;
  errorMessage?: string;
}

const MAX_LENGTH = 50;

function IndividualForm(props: SignInProps) {
  const navigate = useNavigate();
  const { onSubmit, errorMessage } = props;
  const submitDisabled = props?.submitDisabled || false;
  const metadata = useStoreState((state) => state.auth.user?.metadata as IndividualMetadata);
  const countries = useStoreState((state) => state.app.countries);
  const mapCountries = useStoreState((state) => state.app.mapCountries);

  const [name, setName] = useState(metadata?.individual?.name ?? "");
  const [country, setCountry] = useState(metadata?.country ?? "");
  const [addressLine1, setAddressLine1] = useState(metadata?.individual?.addressLine1 ?? "");
  const [addressLine2, setAddressLine2] = useState(metadata?.individual?.addressLine2 ?? "");
  const [profileHandle] = useState(metadata?.individual?.profileHandle ?? "");
  const [githubLink, setGithubLink] = useState(metadata?.individual?.github ?? "");
  const [experience, setExperience] = useState(metadata?.individual?.experience ?? "");
  const [bio, setBio] = useState(metadata?.individual?.bio ?? "");
  const defaultOption = { name: "", value: "" };
  const appSkills = useStoreState((state) => state.app.skills);
  const metadataSkills = metadata?.skills
    ? metadata?.skills.map((skill) => {
        return { name: skill, value: skill };
      })
    : [];

  const [skills, setSkills] = useState<Option[]>(metadataSkills || []);
  const [skill, setSkill] = useState<Option>(defaultOption);
  const [includeVat, setIncludeVat] = useState<boolean>(Boolean(metadata?.includeVat) ?? false);
  const [reverseCharge, setReverseCharge] = useState<boolean>(metadata?.reverseCharge ?? false);
  const [vatRate, setVatRate] = useState<string>(metadata?.vatRate?.toString() ?? "0");
  const [vatNumber, setVatNumber] = useState<string>(metadata?.vatNumber ?? "");

  const handleVatRate = (value: string) => {
    const vatValue = isNaN(parseFloat(value)) ? "" : value.replace(",", ".");
    setVatRate(vatValue);
  };

  const skillsList =
    appSkills.map((skill: any) => {
      return { name: skill.name, value: skill.name };
    }) || [];
  const metadataAvailability = {
    name: metadata?.availability ?? "",
    value: metadata?.availability ?? ""
  };

  const [availability, setAvailability] = useState<Option>(metadataAvailability);

  const handleMultiSelectTagsChange = (values: Option[]) => {
    setSkills(values);
  };
  const handleTagsChange = (value: Option) => {
    setSkill(value);
  };

  const [triedToSubmit, setTriedToSubmit] = useState(false);

  const validators = {
    name: (started: boolean) => isNotEmpty(name, started),
    //    profileHandle: (started: boolean) => isNotEmpty(profileHandle, started),
    experience: (started: boolean) => isNotEmpty(experience, started),
    isUrl: (started: boolean) => validateUrl(githubLink, started),
    bio: (started: boolean) => isNotEmpty(bio, started),
    availability: (started: boolean) => isNotEmpty(availability.name, started),
    country: (started: boolean) => isNotEmpty(country, started)
  };

  const handleCreateProfile = async () => {
    setTriedToSubmit(true);

    const validateResults = Object.values(validators).map((v) => v(true));

    const isFormValid = validateResults.reduce((res, v) => {
      return v.valid && res;
    }, true);

    if (isFormValid) {
      const individual = {
        name,
        addressLine1,
        addressLine2,
        profileHandle,
        github: githubLink,
        experience,
        bio
      };
      onSubmit({
        individual,
        country,
        includeVat,
        reverseCharge,
        vatRate,
        vatNumber,
        availability: availability.value,
        skills: skills.map((skill) => skill.value)
      });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-5 pb-10">
        <form className="w-full">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-[1rem]">
              <ValidationLayout
                {...{
                  validate: () => validators.name(triedToSubmit),
                  label: { text: "Full Name" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "name",
                    value: name,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH && setName(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  validate: () => validators.country(triedToSubmit),
                  label: { text: "Location" }
                }}>
                <NewSelect
                  {...{
                    placeholder: "",
                    customStyles: {
                      container: "px-[20px] py-0",
                      optionsList: { container: "text-sm" }
                    },
                    selected: {
                      name: country,
                      value: countries[country]
                    },
                    defaultOptionsList: mapCountries,
                    defaultOption: {
                      name: "",
                      value: ""
                    },
                    onSelect: (selected: { name: string; value: string }) => {
                      setCountry(selected.name);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  label: { text: "Address Line 1 (Optional)" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "addressLine1",
                    value: addressLine1,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH && setAddressLine1(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  label: { text: "Address Line 2 (Optional)" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "addressLine2",
                    value: addressLine2,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH && setAddressLine2(value);
                    }
                  }}
                />
              </ValidationLayout>
              <div className="flex justify-around">
                <CheckBox
                  {...{
                    text: "Include VAT",
                    active: includeVat,
                    onClick: () => setIncludeVat(!includeVat)
                  }}
                />
                <CheckBox
                  {...{
                    text: "Reverse charge",
                    active: reverseCharge,
                    onClick: () => setReverseCharge(!reverseCharge)
                  }}
                />
              </div>
              <ValidationLayout
                {...{
                  label: { text: "VAT Rate (Optional)" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "vatRate",
                    value: vatRate.toString(),
                    onChange: (value: string) => {
                      handleVatRate(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  label: { text: "VAT Number (Optional)" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "vatNumber",
                    value: vatNumber,
                    onChange: (value: string) => {
                      setVatNumber(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  validate: () => validators.isUrl(triedToSubmit),
                  label: { text: "Github link" }
                }}>
                <Input
                  {...{
                    placeholder: "",
                    name: "github_link",
                    value: githubLink ?? "",
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH * 3 && setGithubLink(value);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  validate: () => validators.availability(triedToSubmit),
                  label: { text: "Pick availability" }
                }}>
                <NewSelect
                  {...{
                    placeholder: "",
                    defaultOptionsList: [
                      { name: "full_time", value: "Full Time" },
                      { name: "part_time", value: "Part Time" }
                    ],
                    defaultOption: defaultOption,
                    selected: availability,
                    onSelect: (selected: Option) => setAvailability(selected)
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  label: { text: "Pick skills" }
                }}>
                <NewMultiSelect
                  {...{
                    placeholder: "",
                    defaultOption,
                    defaultOptionsList: skillsList,
                    selected: skill,
                    multiSelected: skills,
                    onMultiSelect: (selected: Option[]) => {
                      handleMultiSelectTagsChange(selected);
                    },
                    onSelect: (selected: Option) => {
                      handleTagsChange(selected);
                    }
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  validate: () => validators.experience(triedToSubmit),
                  label: { text: "Experience" }
                }}>
                <NewSelect
                  {...{
                    placeholder: "",
                    defaultOptionsList: [
                      { name: "Advanced", value: "Advanced" },
                      { name: "Beginner", value: "Beginner" },
                      { name: "Intermediate", value: "Intermediate" }
                    ],
                    defaultOption: { name: "", value: "" },
                    selected: { name: experience, value: experience },
                    onSelect: (selected: Option) => setExperience(selected.value)
                  }}
                />
              </ValidationLayout>
              <ValidationLayout
                {...{
                  validate: () => validators.bio(triedToSubmit),
                  label: { text: "Bio" }
                }}>
                <TextArea
                  {...{
                    placeholder:
                      "",
                    name: "Bio",
                    value: bio,
                    onChange: (value: string) => {
                      value.length < MAX_LENGTH * 6 && setBio(value);
                    }
                  }}
                />
              </ValidationLayout>
            </div>
          </div>
        </form>
        <p className={`min-h-[1em] text-base ${DEFAULT_STYLES.invalid.message}`}>
          {errorMessage ? errorMessage : ""}
        </p>
        <div className="flex flex-row justify-center items-center gap-5">
          <DarkButton {...{ text: "Back", onClick: () => navigate("/profile") }} />
          <LightButton
            {...{
              text: "Save",
              onClick: handleCreateProfile,
              disabled: submitDisabled
            }}
          />
        </div>
      </div>
    </>
  );
}

export default IndividualForm;
