import Button, { ButtonProps, ButtonStyles } from "./Button";

import nearIcon from "../../../assets/images/near-protocol-icon.png";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

const buildStyles = (customStyles: ButtonStyles | undefined, disabled: boolean | undefined) => ({
  button: twMerge(
    classNames(
      "w-full lg:max-w-[208px] mt-0 mb-[var(--border-width)] bg-transparent rounded-none px-[2rem] py-[1rem] lg:px-[1em] lg:py-[0.25em] lg:min-w-[13rem] lg:max-h-[3rem]",
      {
        "lg:bg-primary-900 lg:button--primary_hover hover:border-primary-900 active:bg-primary-930 lg:rounded-full border-none":
          !disabled
      },
      customStyles?.button
    )
  ),
  text: twMerge(
    classNames("text-sm text-neutral-100 md:text-base lg:text-neutral-900", customStyles?.text)
  ),
  textWrap: twMerge(
    classNames("flex flex-row justify-center items-center gap-3", customStyles?.textWrap)
  )
});

function ConnectWalletButtonV2(props: ButtonProps) {
  const { customStyles, text, onClick, disabled } = props;

  const styles = buildStyles(customStyles, disabled);

  return (
    <Button
      {...{
        customStyles: styles,
        text,
        onClick,
        disabled
      }}>
      <div className={styles.textWrap}>
        <img src={nearIcon} className="w-[1.5em]" alt="" />
        <p className={styles.text}>{text && text}</p>
      </div>
    </Button>
  );
}

export default ConnectWalletButtonV2;
