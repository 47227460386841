import classNames from "classnames";

const WalletIcon2 = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("w-6 h-6 fill-primary-800", className)}
    >
      <path
        d="M0.273 3.125C1.09435 2.39854 2.15348 1.9983 3.25 2H16.75C17.891 2 18.933 2.425 19.727 3.125C19.6357 2.39994 19.2828 1.73315 18.7346 1.24982C18.1865 0.766498 17.4808 0.499871 16.75 0.5H3.25C2.51921 0.499871 1.81349 0.766498 1.26536 1.24982C0.717217 1.73315 0.364349 2.39994 0.273 3.125ZM0.273 6.125C1.09435 5.39854 2.15348 4.9983 3.25 5H16.75C17.891 5 18.933 5.425 19.727 6.125C19.6357 5.39994 19.2828 4.73315 18.7346 4.24983C18.1865 3.7665 17.4808 3.49987 16.75 3.5H3.25C2.51921 3.49987 1.81349 3.7665 1.26536 4.24983C0.717217 4.73315 0.364349 5.39994 0.273 6.125ZM3.25 6.5C2.45435 6.5 1.69129 6.81607 1.12868 7.37868C0.566071 7.94129 0.25 8.70435 0.25 9.5V15.5C0.25 16.2956 0.566071 17.0587 1.12868 17.6213C1.69129 18.1839 2.45435 18.5 3.25 18.5H16.75C17.5456 18.5 18.3087 18.1839 18.8713 17.6213C19.4339 17.0587 19.75 16.2956 19.75 15.5V9.5C19.75 8.70435 19.4339 7.94129 18.8713 7.37868C18.3087 6.81607 17.5456 6.5 16.75 6.5H13C12.8011 6.5 12.6103 6.57902 12.4697 6.71967C12.329 6.86032 12.25 7.05109 12.25 7.25C12.25 7.84674 12.0129 8.41903 11.591 8.84099C11.169 9.26295 10.5967 9.5 10 9.5C9.40326 9.5 8.83097 9.26295 8.40901 8.84099C7.98705 8.41903 7.75 7.84674 7.75 7.25C7.75 7.05109 7.67098 6.86032 7.53033 6.71967C7.38968 6.57902 7.19891 6.5 7 6.5H3.25Z"
        fill="fill-inherit"
      />
    </svg>
  );
};

export default WalletIcon2;
