import { BountyStatus, ClaimStatus } from "../../../constants";
import GroupSeparator from "./GroupSeparator";

const StatusGroup = ({
  status,
  length
}: {
  status: ClaimStatus | BountyStatus;
  length: number;
}) => {
  if (length) {
    type KeyStatus = BountyStatus | ClaimStatus;
    const statusOrdered: { [key in KeyStatus]?: () => JSX.Element } & {
      default: () => JSX.Element;
    } = {
      New: () => <GroupSeparator title="Awaiting response" />,
      InProgress: () => <GroupSeparator title="In progress" />,
      Claimed: () => <GroupSeparator title="In progress" />,
      ManyClaimed: () => <GroupSeparator title="Competition in progress" />,
      Completed: () => <GroupSeparator title="Completed" />,
      Approved: () => <GroupSeparator title="Approved" />,
      NotHired: () => <GroupSeparator title="Declined" />,
      Canceled: () => <GroupSeparator title="Canceled" />,
      default: () => <GroupSeparator title={status} />
    };
    return (statusOrdered[status] || statusOrdered["default"])();
  }
  return null;
};

export default StatusGroup;
