import classNames from "classnames";
import { Bounty } from "../../../../../types/Bounty";
import CurrencyAmount from "../../general/CurrencyAmount";
import TokenAmount from "../../general/TokenAmount";

const SimpleTotalAmount = ({ bounty, className }: { bounty: Bounty; className?: string }) => {
  const getTotalAmount = () => {
    return (
      parseInt(bounty.amount) +
      parseInt(bounty.dao_fee ?? 0) +
      parseInt(bounty.platform_fee ?? 0)
    ).toFixed();
  };
  return (
    <div className="text-neutral-50">
      {bounty?.token && (
        <TokenAmount
          token={bounty.token}
          amount={getTotalAmount()}
          className={classNames("text-neutral-50", className)}
        />
      )}
      {bounty?.currency && (
        <CurrencyAmount
          currency={bounty.currency}
          amount={getTotalAmount()}
          className={classNames("text-neutral-50", className)}
        />
      )}
    </div>
  );
};

export default SimpleTotalAmount;
