import { useStoreState } from ".";

const useCurrencies = () => {
  const currencies = useStoreState((state) => state.app.currencies);

  return {
    currencies,
    //currencyCalculatedAmount,
  };
};

export default useCurrencies;
