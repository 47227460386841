import defaultUserAvatarImage from "../../../assets/images/default_avatar.jpg";
import { twMerge } from "tailwind-merge";

const DefUserIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <img
      {...{
        src: defaultUserAvatarImage,
        className: twMerge(
          ` w-[100px] aspect-square rounded-full
        object-cover
        mt-[-50%]
      `,
          className
        )
      }}
      alt=""
    />
  );
};

export default DefUserIcon;
