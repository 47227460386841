import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useStoreState } from "../../../../../../hooks";
import { TokenData } from "../../../../../../types/App";
import { Bounty } from "../../../../../../types/Bounty";
import { FormControl, FormRow } from "../../../../../general/Form/FormElements";
import { Input } from "../../../../../general/Input";
import { Area } from "../../../../../Layout";
import { EditBountyPriceFormData } from "..";

const TokenPriceForm = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  const methods = useFormContext<EditBountyPriceFormData>();
  const {
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitted }
  } = methods;

  const tokens = useStoreState((state) => state.app.tokens);

  const { walletSelector } = useStoreState((state) => state.nearApi);

  const nearblocksURL = () => {
    const { options } = walletSelector ?? {};
    return options?.network.networkId === "testnet"
      ? "https://testnet.nearblocks.io/address/"
      : "https://nearblocks.io/address/";
  };

  const [amount, setAmount] = useState(getValues("amount") ?? 0);

  const bountyToken = useMemo(() => {
    const token: TokenData | undefined = tokens.find((token) => token.tokenId === bounty.token);
    return {
      name: token?.tokenId ?? "",
      value: token?.symbol ?? "",
      symbol: token?.symbol ?? "",
      decimals: token?.decimals ?? 0
    };
  }, [tokens, bounty.token]);

  const handleAmountChange = (value: string) => {
    setValue("amount", value, { shouldValidate: true, shouldDirty: true });
    setAmount(value);
  };

  return (
    <Area>
      <FormRow>
        <div className="flex text-neutral-200 flex-col flex-wrap gap-4">
          <div>
            Bounty price (paid in{" "}
            <span className="text-primary-900 underline">
              <a
                href={`${nearblocksURL()}${bountyToken?.name}`}
                target="_blank"
                rel="noopener noreferrer">
                {bountyToken?.value}
              </a>
            </span>
            )
          </div>
        </div>
      </FormRow>
      <FormRow className="w-full">
        <FormControl
          name="amount"
          control={control}
          defaultValue={amount}
          rules={{
            required: "Required field",
            validate: {
              minAmount: (value: number) => value > 0 || "is not equal to 0"
            }
          }}
          render={({ field: { name, value, onChange } }) => {
            return (
              <Input
                {...{
                  customStyles: {
                    input: "px-[20px] py-[15px] text-right"
                  },
                  name: name,
                  value: value,
                  validation: {
                    valid: !errors.amount,
                    message: errors.amount?.message,
                    started: isSubmitted
                  },
                  onChange: (value: string) => {
                    handleAmountChange(value);
                    onChange(value);
                  },
                  disabled: !bountyToken
                }}
                {...{ name, value }}
              />
            );
          }}
        />
      </FormRow>
    </Area>
  );
};

export default TokenPriceForm;
