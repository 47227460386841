import * as qs from "qs";
import { User } from "../types/Auth";
import { put, get, post } from "./newAuth";

export const verifyEmail = async (): Promise<any> => {
  return await post(`/profile/verify-email`);
};

const updateAvatarImageMultipart = async (avatarImage: File) => {
  const form = new FormData();
  form.append("image", avatarImage);

  return await put("/profile/update-picture", form, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const updateCoverImageMultipart = async (coverImage: File) => {
  const form = new FormData();
  form.append("coverImage", coverImage);

  return await put("/profile/update-cover-image", form, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const getAvatarImage = async (isOriginal = false) => {
  return await get(`/profile/get-image?isOriginal=${isOriginal}`);
};

export const getCoverImage = async (isOriginal = false) => {
  return await get(`/profile/get-cover-image?isOriginal=${isOriginal}`);
};

const getPublicProfile = async (username: string, type?: string | null) => {
  const query = qs.stringify(
    {
      username,
      type
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );

  return await get(`/profile/get-public-profile?${query}`);
};

const getTransactions = async (
  accountId: string,
  methodType: string,
  startDate: string,
  endDate: string
) => {
  const query = qs.stringify(
    {
      startDate,
      endDate,
      accountId,
      methodType
    },
    {
      arrayFormat: "comma",
      encodeValuesOnly: true,
      skipNulls: true
    }
  );

  return await get(`/profile/get-transactions?${query}`, "blob");
};

const updateProfile = async (form: Partial<User>) => {
  return await put("/profile/update-profile", form);
};

const updateNotificationsStatus = async (value: boolean) => {
  return await put("/profile/update-notifications-status", { isNotificationsDisabled: value });
};

export const Api = {
  verifyEmail,
  updateProfile,
  updateAvatarImageMultipart,
  updateCoverImageMultipart,
  getPublicProfile,
  updateNotificationsStatus,
  getTransactions
};
