import { useState } from "react";
import { DEV_MODE } from "../../../../../constants";
import { useStoreActions } from "../../../../../hooks";
import Api from "../../../../../services/Api";
import { downloadCSVFile } from "../../../../../utils";
import { getFileName } from "../helpers";
import ActionButton from "./general/ActionButton";
import mixpanel from "mixpanel-browser";

const ExportCSVFile = (props: {
  accountId: string;
  ids: Array<string | number>;
  disabled: boolean;
}) => {
  const { accountId, ids, disabled } = props;
  const setError = useStoreActions((actions) => actions.error.setError);
  const [loading, setLoading] = useState(false);

  const handleLoadInvoices = async () => {
    setLoading(true);
    const responseParams = {
      ids,
      accountId,
      toPdf: false
    };
    const response = await Api.downloadInvoices(responseParams);
    setLoading(false);
    if (response?.success) {
      const fileName = response.success.headers["content-disposition"]
        ? getFileName(response.success.headers["content-disposition"])
        : "invoices.csv";
      const file = new File([response.success.data], fileName);
      downloadCSVFile(file);
      if (!DEV_MODE) {
        mixpanel.track("Invoice Download", {
          source: window.location.hostname
        });
      }
    } else {
      setError("Invoice download error");
    }
  };

  return (
    <ActionButton
      text="Export .csv file"
      disabled={loading || disabled}
      onClick={handleLoadInvoices}
    />
  );
};

export default ExportCSVFile;
