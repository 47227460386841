import classNames from "classnames";
import { Input } from "../../../../../general/Input";
import { Area, ValidationLayout } from "../../../../../Layout";
import Separator from "../../../../../general/Separator";
import FormControl from "../../../../../general/Form/FormElements/FormControl";
import { useFormContext } from "react-hook-form";
import { PaymentFormData } from "../../Payment";
import { FormRow } from "../../../../../general/Form/FormElements";
import utils from "../../../../../../services/utils";
import { useStoreActions, useStoreState } from "../../../../../../hooks";
import { useEffect, useMemo } from "react";
import useFtTokenBalance from "../../../../../../hooks/useFtTokenBalance";
import { NewSelect } from "../../../../../general/Select";
import { getTokenDecimals } from "../../../../../Bounty/general/helpers";

const CurrencyConverter = () => {
  const methods = useFormContext<PaymentFormData>();
  const {
    control,
    register,
    unregister,
    setValue,
    formState: { errors, isSubmitted }
  } = methods;

  const tokens = useStoreState((state) => state.app.tokens);
  const { payment, amount, selectedTokenAccount, total, exchengeSwitcher } = useStoreState(
    (state) => state.newBountyForm
  );

  const { availableBalance } = useFtTokenBalance({
    tokenId: selectedTokenAccount?.name
  });
  const { walletSelector } = useStoreState((state) => state.nearApi);
  const nearblocksURL = () => {
    const { options } = walletSelector ?? {};
    return options?.network.networkId === "testnet"
      ? "https://testnet.nearblocks.io/address/"
      : "https://nearblocks.io/address/";
  };

  const actions = useStoreActions((actions) => actions);

  const {
    newBountyForm: { setSelectedTokenAccount, setPayment }
  } = actions;
  const handleTokenAccountChange = async (selected: { name: string; value: string }) => {
    setValue("defaultPayment.selectedTokenAccount", selected);
    setValue("defaultPayment.payment", "0");
    handlePaymentChange("0");
    setSelectedTokenAccount(selected);
  };

  const tokenDecimals = useMemo(() => {
    return getTokenDecimals(selectedTokenAccount?.name ?? "");
  }, [selectedTokenAccount]);

  const formattedBalance = useMemo(() => {
    return availableBalance ? utils.formatToken(availableBalance, tokenDecimals) : 0;
  }, [availableBalance, tokenDecimals]);

  const parsedBalance = useMemo(() => {
    return availableBalance
      ? utils.formatTokenAmount(availableBalance, tokenDecimals, tokenDecimals)
      : 0;
  }, [availableBalance, tokenDecimals]);

  const validMaxAmount = (value: string) => {
    return (
      parseFloat(parsedBalance.toString()) >= parseFloat(parseFloat(value).toFixed(tokenDecimals))
    );
  };

  const checkTokenAccountIsRequired = useMemo(() => {
    return Boolean(!selectedTokenAccount?.name && !selectedTokenAccount?.value);
  }, [selectedTokenAccount]);

  useEffect(() => {
    return () => unregister("defaultPayment.payment");
  }, [unregister]);

  const handlePaymentChange = (value: string) => {
    value = value.replaceAll(",", ".");
    setValue("defaultPayment.payment", parseFloat(value).toFixed(tokenDecimals), {
      shouldValidate: true
    });
    setPayment(parseFloat(parseFloat(value).toFixed(tokenDecimals)));
  };

  return (
    <Area>
      <FormRow>
        <div className="flex flex-wrap gap-2">
          <div>
            Bounty price (paid in{" "}
            <span className="text-primary-900 underline">
              <a
                href={`${nearblocksURL()}${selectedTokenAccount?.name}`}
                target="_blank"
                rel="noopener noreferrer">
                {selectedTokenAccount?.value}
              </a>
            </span>
            )
          </div>
          <div>
            {selectedTokenAccount?.value && (
              <span className="px-2 text-sm text-neutral-400">Available: {formattedBalance}</span>
            )}
          </div>
        </div>
      </FormRow>
      <div className="flex max-w-fit">
        <div
          className={classNames("flex items-center gap-2 flex-nowrap", {
            "flex-row": exchengeSwitcher === "CoinToUSD",
            "flex-row-reverse": exchengeSwitcher === "USDToCoin"
          })}>
          <div className="flex gap-4 flex-nowrap items-end">
            <div>
              <input
                type="hidden"
                defaultValue={selectedTokenAccount?.value}
                {...register("defaultPayment.selectedTokenAccount", {
                  validate: {
                    required: () => !checkTokenAccountIsRequired || "Required field"
                  }
                })}
              />
              <ValidationLayout
                {...{
                  validate: () => {
                    return {
                      valid: !errors.defaultPayment?.selectedTokenAccount,
                      started: isSubmitted,
                      message: errors.defaultPayment?.selectedTokenAccount?.message
                    };
                  }
                }}>
                <NewSelect
                  {...{
                    placeholder: "Select token",
                    customStyles: {
                      container: "px-[20px] py-0",
                      optionsList: { container: "text-sm" }
                    },
                    selected: {
                      name: selectedTokenAccount?.name || "",
                      value: selectedTokenAccount?.value || ""
                    },
                    defaultOptionsList: Array.isArray(tokens)
                      ? tokens
                          .filter((token) => token.enabled === true)
                          .map((token) => {
                            return { name: token.tokenId, value: token.symbol };
                          })
                      : [],
                    defaultOption: {
                      name: selectedTokenAccount?.name || "",
                      value: selectedTokenAccount?.value || ""
                    },
                    onSelect: (selected: { name: string; value: string }) => {
                      handleTokenAccountChange(selected);
                    }
                  }}
                />
              </ValidationLayout>

              {/* <div className="pt-4 text-sm text-neutral-200">
                  +{platformFeePercentage}% Platform Fee
                </div> */}
            </div>
            <div>
              <FormControl
                name="defaultPayment.payment"
                control={control}
                defaultValue={payment}
                rules={{
                  validate: {
                    required: (value: string) =>
                      (value && parseFloat(value) > 0) || "Required field",
                    maxAmount: (value: string) =>
                      validMaxAmount(value) || "token balance is not enough"
                  }
                }}
                render={({ field: { name, value, onChange } }) => {
                  return (
                    <Input
                      {...{
                        id: "input-payment",
                        customStyles: {
                          input: "px-[20px] py-[15px] text-right"
                        },
                        name: name,
                        value: value,
                        validation: {
                          valid: !errors.defaultPayment?.payment,
                          message: errors.defaultPayment?.payment?.message,
                          started: isSubmitted
                        },
                        onChange: (value: string) => {
                          handlePaymentChange(value);
                          onChange(value);
                        },
                        disabled: !selectedTokenAccount
                      }}
                      {...{ name, value }}
                    />
                  );
                }}
              />
              {/* <div className="pt-4 flex justify-end text-sm text-neutral-200">
                  {utils.formatWithCommas(platform_fee.toString())}{" "}
                  {selectedTokenAccount?.value}
                </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <Separator customStyles={{ container: "py-4" }} />
      </div>
      <FormRow>
        <div className="flex justify-between">
          <div className="text-sm text-neutral-200">Total</div>
          <div className="text-sm text-neutral-50 uppercase">
            {utils.formatWithCommas(total.toString(), tokenDecimals)} {selectedTokenAccount?.value}
          </div>
        </div>
      </FormRow>
    </Area>
  );
};

export default CurrencyConverter;
