import classNames from "classnames";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

const buildStyles = (customStyles: {
  wrapper?: string | null;
  container?: string | null;
  label?: string | null;
  value?: string | null;
}) => ({
  wrapper: twMerge(classNames("block", customStyles?.wrapper)),
  container: twMerge(classNames("flex flex-1 flex-col gap-2", customStyles?.container)),
  label: twMerge(classNames("text-neutral-200 font-poppins text-sm", customStyles?.label)),
  value: twMerge(classNames("text-neutral-50 font-poppins text-base", customStyles?.value))
});

type InfoProps = {
  label?: string | null;
  value?: string | null | ReactNode;
  children?: ReactNode[] | ReactNode | null;
  customStyles?: {
    wrapper?: string | null;
    container?: string | null;
    label?: string | null;
    value?: string | null;
  };
  icon?: ReactNode;
};

export default function Info(props: InfoProps) {
  const { label, value, children, customStyles, icon } = props;

  const styles = buildStyles(customStyles ?? {});

  return (
    <div className={styles.wrapper}>
      {icon ? icon : null}
      <div className={styles.container}>
        {label ? <p className={styles.label}>{label}</p> : null}
        {value ? <div className={styles.value}>{value}</div> : children ? children : null}
      </div>
    </div>
  );
}
