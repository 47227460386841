interface ItemProps {
  imageSrc: string;
}

export default function Item(props: ItemProps) {
  const { imageSrc } = props;

  const styles = {
    container: `
    `,
    text: `
      text-lg font-poppins text-neutral-100 -tracking-[2%]
    `,
    value: `
      text-2xl font-bold text-neutral-100 -tracking-[2%]
    `,
  };

  return (
    <div className={styles.container}>
      <img
        src={imageSrc}
        className="w-[74px] contributers__icon--shadow"
        alt=""
      />
    </div>
  );
}
