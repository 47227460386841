import { ReactNode } from "react";

const CircleListItem = (props: { children: ReactNode | ReactNode[] }) => {
  const { children } = props;
  return (
    <li className="ml-8 mb-2">
      <span className="pr-4">●</span> {children}
    </li>
  );
};

export default CircleListItem;
