import { useEffect, useState } from "react";

import { MenuButton } from "../../general/Button";
import { GradientBorder } from "../index";
import NavBar from "../../NavBar";
import LogoLink from "../../general/LogoLink";
import logo from "../../../assets/images/logo.png";
import useMediaQuery from "../../../hooks/useMediaQuery";
import useClickOutside from "../../../hooks/useClickOutside";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { parse, stringify } from "query-string";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function Header() {
  const [toggled, setToggled] = useState<boolean>(false);
  const isLg = useMediaQuery("(min-width: 1248px)");

  const handleClickOutside = () => {
    if (toggled) {
      setToggled(false);
    }
  };

  useEffect(() => {
    if (isLg) {
      setToggled(false);
    }
  }, [isLg]);

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const menuRef = useClickOutside(handleClickOutside);

  const customBorderStyle = `
    flex justify-between items-center
    w-full h-full
    p-header mx-auto
    rounded-header
    ${toggled ? "bg-transparent" : "bg-neutral-800 bg-opacity-60 backdrop-blur-md"}
  `;

  return (
    <header
      className="
        container sticky top-6
        h-header
        mx-auto
        bg-transparent
        font-poppins font-medium
        border-0
        z-30
        lg:top-4
      ">
      <GradientBorder {...{ customStyles: customBorderStyle, hidden: toggled }}>
        <div className="flex items-center z-50 ml-[24px]">
          <LogoLink
            {...{
              imageSrc: logo,
              link: "/",
              target: "_self",
              style: "max-h-[1.5em]"
            }}
          />
        </div>
        <div ref={menuRef} className="lg:w-[85%] h-full flex items-center">
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{
              searchStringToObject: parse,
              objectToSearchString: stringify
            }}>
            <QueryClientProvider client={queryClient}>
              <MenuButton {...{ toggled, handleToggle }} />
              <NavBar {...{ expanded: toggled }} />
            </QueryClientProvider>
          </QueryParamProvider>
        </div>
      </GradientBorder>
    </header>
  );
}

export default Header;
