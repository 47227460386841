interface TProps {
  color?: string;
}

const defaultProps = {
  color: "hsl(var(--color-neutral-200))",
};

export default function TagCross(props: TProps) {
  const { color } = { ...defaultProps, ...props };

  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1.5L8 8.5" stroke={color} strokeWidth="1.5" />
      <path d="M1 8.5L8 1.5" stroke={color} strokeWidth="1.5" />
    </svg>
  );
}
