import { useStoreActions, useStoreState } from "../../../../../../../../hooks";
import { DifferentTasks } from "../../../../../../../../types/BountyCreate";
import IconButton from "../../../../../../../general/Button/IconButton";
import { TrashIcon } from "../../../../../../../general/SVGIcon";

const DeleteTaskAction = (props: { index: number }) => {
  const { index } = props;
  const subtasks = useStoreState(
    (state) => (state.newBounty.multitasking as DifferentTasks).DifferentTasks.subtasks
  );
  const setSubtask = useStoreActions((actions) => actions.newBounty.setSubtask);

  const handleSubmitForm = () => {
    const newSubtasks = [...subtasks];
    newSubtasks.splice(index, 1);
    setSubtask(newSubtasks);
  };

  return (
    <IconButton
      {...{
        icon: <TrashIcon className="stroke-neutral-400 hover:stroke-primary-900" />,
        onClick: () => handleSubmitForm()
      }}
    />
  );
};

export default DeleteTaskAction;
