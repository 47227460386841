import utils from "../../../services/utils";
import { Area, MarksLayer } from "../../Layout";
import Mark from "../../general/Mark";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ClaimStatus } from "../../../types/Claim";
import { useStoreActions, useStoreState } from "../../../hooks";
import Avatar from "../../general/Avatar";
import { Bounty } from "../../../types/Bounty";
import TokenAmount from "../Amount/TokenAmount";
import CurrencyAmount from "../Amount/CurrencyAmount";
import DefUserIcon from "../../general/SVGIcon/defUserIcon";
import { RedCircleIcon } from "../../general/SVGIcon";
import DaysFromNow from "../../general/DaysFromNow";
import { twMerge } from "tailwind-merge";
import AngleBracketsIcon from "../../general/SVGIcon/angleBracketsIcon";
import UsersIcon from "../../general/SVGIcon/usersIcon";
import UserBorderlessIcon from "../../general/SVGIcon/userBorderlessIcon";
import NearconSticker from "../../../assets/images/nearcon-sticker.png";
import NearweekBountySticker from "../../../assets/images/nw-bounty-sticker.png";
import { mapExperienceValueByName } from "../general/helpers";

export const decodeHtmlSymbols = (str: string) => {
  const elem = document.createElement("textarea");
  elem.innerHTML = str;
  return elem?.value;
};

const BountyListItem = (props: {
  bounty: Bounty;
  claimStatus?: ClaimStatus;
  showMark?: boolean;
  needAction?: boolean;
}) => {
  const { bounty, claimStatus, needAction, showMark = true } = props;
  const actions = useStoreActions((actions) => actions);
  const {
    bountyList: { setFilters }
  } = actions;

  const filters = useStoreState((state) => state.bountyList.filters);
  const tokens = useStoreState((state) => state.app.tokens);

  const calcTotalAmount = () => {
    const amount = parseInt(bounty.amount);
    const platformFee = parseInt(bounty.platform_fee);
    return (amount + platformFee).toString();
  };

  const handleClickTag = (e: React.MouseEvent, tag: string) => {
    e.preventDefault();
    setFilters({
      ...filters,
      tags: [{ name: tag, value: tag }, ...(filters?.tags || [])]
    });
  };

  const Badges = () => {
    return (
      <>
        {["ApprovalByWhitelist", "WhitelistWithApprovals"].includes(bounty.claimant_approval) && (
          <Mark
            {...{
              text: "Whitelisted Only",
              customStyles: {
                container: classNames("px-3 text-neutral-800 bg-secondary-500"),
                text: "capitalize"
              }
            }}
          />
        )}
        {bounty.bounty_flow === "SimpleBounty" && (
          <Mark
            {...{
              text: "Express",
              customStyles: {
                container: classNames("px-3 text-neutral-800 bg-primary-500"),
                text: "uppercase"
              }
            }}
          />
        )}
        {bounty.metadata.tags.includes("Hackathon") && (
          <Mark
            {...{
              text: "Hackathon",
              customStyles: {
                container: classNames(
                  "lg:visible sm:hidden xs:hidden px-3 text-neutral-800 bg-primary-600"
                )
              }
            }}
          />
        )}
        {bounty.postpaid && (
          <Mark
            {...{
              text: "Deferred Payment",
              customStyles: {
                container: classNames(
                  "lg:visible sm:hidden xs:hidden px-3 text-neutral-800 bg-post-secondary-900"
                ),
                text: "capitalize"
              }
            }}
          />
        )}
        {bounty.kyc_config?.kyc_required && (
          <Mark
            {...{
              text: "KYC",
              customStyles: {
                container: classNames("px-3 text-neutral-800 bg-secondary-800"),
                text: "capitalize"
              }
            }}
          />
        )}
        <Mark
          {...{
            text: bounty.status === "ManyClaimed" ? "Claimed" : bounty.status,
            customStyles: {
              container: twMerge(
                classNames("px-3 text-neutral-800 bg-neutral-900", {
                  "bg-primary-900": ["New", "AwaitingClaims"].includes(bounty.status),
                  "bg-post-secondary-900": ["Claimed", "ManyClaimed"].includes(bounty.status),
                  "bg-success": bounty.status === "Completed",
                  "bg-neutral-400": bounty.status === "Canceled"
                })
              )
            }
          }}
        />
      </>
    );
  };

  return (
    <div className="mt-6">
      <Link to={`/bounties/bounty/${bounty.id}`}>
        <Area
          {...{
            customStyles: {
              borderContainer: classNames("border", {
                "border-red-400/75": claimStatus && ["Rejected", "NotHired"].includes(claimStatus)
              }),
              contentContainer: classNames("bg-neutral-800 hover:bg-neutral-700", {
                "bg-red-400/25": claimStatus && ["Rejected", "NotHired"].includes(claimStatus)
              })
            }
          }}>
          {bounty.metadata.tags.includes("NEARCON2023") && (
            <div className="relative right-4 bottom-3">
              <img width="46" height="41" className="absolute" src={NearconSticker} />
            </div>
          )}
          {bounty.metadata.tags.includes("NEARWEEK") && (
            <div className="relative right-4 bottom-3">
              <img width="67" height="50" className="absolute" src={NearweekBountySticker} />
            </div>
          )}
          {showMark && (
            <MarksLayer
              {...{
                customStyles: {
                  contentContainer: "lg:visible md:invisible sm:invisible xs:invisible"
                }
              }}>
              <Badges />
            </MarksLayer>
          )}

          <div className="flex flex-col flex-1 p-5 font-poppins">
            <div className="lg:invisible lg:hidden md:visible sm:visible xs:visible flex flex-wrap justify-end gap-1.5 mb-1.5">
              <Badges />
            </div>
            <div className="flex items-center gap-4 min-w-fit mb-3">
              {bounty.ownerDetails?.picture ? (
                <Avatar
                  picture={bounty.ownerDetails.picture}
                  className="w-[56px] max-w-[56px] h-[56px] mt-0 my-0"
                  original={false}
                />
              ) : (
                <DefUserIcon className="w-[56px] h-[56px] mt-0 my-0" />
              )}
              <div className="py-1">
                <div className="flex items-center gap-3 text-neutral-50 text-lg mb-2">
                  {decodeHtmlSymbols(bounty.metadata.title)} {needAction && <RedCircleIcon />}
                </div>
                <div className="flex flex-wrap gap-2 items-center justify-start text-md">
                  <span className="flex items-center gap-1 bg-[#DEDEDA80] text-sm text-neutral-50 rounded px-1.5">
                    <AngleBracketsIcon /> {bounty.metadata.category}
                  </span>
                  {bounty?.token && (
                    <>
                      <TokenAmount
                        tokens={tokens}
                        tokenId={bounty.token}
                        amount={calcTotalAmount()}
                        claimStatus={claimStatus}
                        className={"flex items-center gap-1 bg-[#73EA64]/20 rounded py-0 px-1.5"}
                        icon={
                          bounty?.multitasking?.multitasking_type === "OneForAll" ||
                          bounty?.multitasking?.multitasking_type === "DifferentTasks" ? (
                            <UsersIcon className="stroke-success" />
                          ) : (
                            <UserBorderlessIcon className="stroke-success" />
                          )
                        }
                      />
                    </>
                  )}
                  {bounty?.multitasking?.multitasking_type === "OneForAll" && bounty?.token && (
                    <>
                      <TokenAmount
                        tokens={tokens}
                        tokenId={bounty.token}
                        amount={bounty?.multitasking?.amount_per_slot || ""}
                        claimStatus={claimStatus}
                        className={
                          "flex items-center gap-1 bg-[#FFE890]/50 text-primary-900 rounded py-0 px-1.5"
                        }
                        icon={<UserBorderlessIcon />}
                        additionalText={"per Hunter"}
                      />
                    </>
                  )}
                  {bounty?.currency && (
                    <>
                      <CurrencyAmount
                        currency={bounty.currency}
                        amount={bounty.amount}
                        claimStatus={claimStatus}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-3.5 text-neutral-500 text-xs w-full mb-2">
              {/*<div className="flex justify-between items-center">*/}
              {/*  <span className="text-sm text-neutral-700">#id-{bounty.id}</span>*/}
              {/*</div>*/}
              <div>
                {!bounty.multitasking ? (
                  <>{!bounty?.postpaid ? "Fixed-price" : "Deferred payment"}</>
                ) : (
                  <>
                    {bounty.multitasking.multitasking_type === "ContestOrHackathon" && (
                      <>Fixed-price</>
                    )}
                    {bounty.multitasking.multitasking_type === "OneForAll" && <>Price-Per-Slot</>}
                    {bounty.multitasking.multitasking_type === "DifferentTasks" && (
                      <>Price-Per-Task</>
                    )}
                  </>
                )}
              </div>
              <div>Experience: {mapExperienceValueByName(bounty.metadata.experience)}</div>
              <div>
                <DaysFromNow className="text-xs" date={bounty.created_at} />
              </div>
              <div>{utils.pluralize(bounty.claims.length, "Applicant")}</div>
              {bounty?.multitasking?.multitasking_type === "OneForAll" &&
                bounty.multitasking?.number_of_slots &&
                (bounty.multitasking?.runtime_env?.occupied_slots ||
                  bounty.multitasking?.runtime_env?.occupied_slots === 0) && (
                  <div>
                    {utils.pluralize(
                      bounty.multitasking.number_of_slots -
                        bounty.multitasking.runtime_env.occupied_slots,
                      "slot"
                    )}{" "}
                    available
                  </div>
                )}
            </div>
            {bounty?.metadata?.description && (
              <div className="text-sm text-neutral-300 break-all">
                <div>
                  {bounty.metadata.description.slice(0, 200) +
                    `${bounty.metadata.description.length > 200 ? "..." : ""}`}
                </div>
              </div>
            )}
            <div className="flex flex-wrap gap-1.5 mt-2">
              {bounty.metadata.tags.map((tag) => (
                <button
                  key={`${bounty.id}-tag-${tag}`}
                  disabled={!!filters?.tags?.find((el) => el.name === tag)}
                  className="text-xs text-neutral-200 px-1 py-0.5 rounded bg-neutral-600 hover:bg-neutral-800"
                  onClick={(e: React.MouseEvent) => handleClickTag(e, tag)}>
                  {tag}
                </button>
              ))}
            </div>
          </div>
        </Area>
      </Link>
    </div>
  );
};

export default BountyListItem;
