import classNames from "classnames";
import { twMerge } from "tailwind-merge";

const RoundedUpIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
  <svg className={twMerge(classNames("w-5 h-5 stroke-white", className))} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.8" cx="20" cy="20" r="20" fill="#2E2E2E"/>
    <path d="M20 29.257V10.743M20 10.743L11.6688 19.0743M20 10.743L28.3313 19.0743" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

)};

export default RoundedUpIcon;
