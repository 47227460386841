import utils from "../../../../../../../../services/utils";
import { BountyTransaction } from "../../../../../../../../types/BountyTransaction";
import { getTokenData } from "../../../../../../../Bounty/general/helpers";

const getHashTags = (args: string) => {
  const parsedArgs = JSON.parse(args);
  const parsedMsg = JSON.parse(parsedArgs.msg);
  return parsedMsg.metadata.tags;
};

const getTitle = (tx: BountyTransaction) => {
  const parsedArgs = JSON.parse(tx.args);
  const parsedMsg = JSON.parse(parsedArgs.msg);
  return `I just created a ${
    parsedMsg.metadata.category
  } bounty on @heroes_build with total of ${utils.formatToken(
    parsedArgs.amount,
    getTokenData(tx.receiverId ?? "")?.decimals || 1
  )} ${getTokenData(tx.receiverId ?? "")?.symbol} in rewards!"

  Skill required: ${getHashTags(tx.args).join(",")}
  
  Go check it out and apply if you got what it takes!
  
  `;
};

export { getTitle, getHashTags };
