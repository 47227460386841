import classNames from "classnames";
import IconButton from "../../../../general/Button/IconButton";
import { EditIcon } from "../../../../general/SVGIcon";

const EditIconButton = (props: {
  isDisabled?: boolean;
  handleSubmit: () => void;
  customStyles?: { button?: string; icon?: string };
}) => {
  const { handleSubmit, isDisabled = false, customStyles } = props;
  return (
    <IconButton
      {...{
        disabled: isDisabled,
        onClick: handleSubmit,
        customStyles: { button: customStyles?.button },
        icon: (
          <EditIcon
            className={classNames(
              "stroke-neutral-500",
              {
                "hover:stroke-neutral-400": isDisabled,
                "hover:stroke-primary-900": !isDisabled
              },
              customStyles?.icon
            )}
          />
        )
      }}
    />
  );
};

export default EditIconButton;
