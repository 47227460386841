import classNames from "classnames";
import { useRef } from "react";
import ReactTags, { ClassNames, Tag } from "react-tag-autocomplete";
import { matchSorter } from "match-sorter";

const InputTagsAutocomplete = (props: {
  labelText?: string;
  selected: string[];
  suggestions: Tag[];
  onAdd: (tag: Tag) => void;
  onDelete: (index: number) => void;
  allowNew?: boolean;
  customStyles?: ClassNames;
}) => {
  const {
    labelText = "",
    allowNew = true,
    selected,
    suggestions,
    customStyles,
    onAdd,
    onDelete
  } = props;

  const defaultStyles = {
    root: classNames("react-tags inline relative", customStyles?.root),
    rootFocused: classNames("is-focused", customStyles?.rootFocused),
    selected: classNames(
      "react-tags__selected inline [&>button]:mx-1 [&>button]:my-1 [&>button]:text-sm [&>button]:text-neutral-50 [&>button]:px-2 [&>button]:py-1 [&>button]:bg-neutral-600 [&>button]:rounded-md",
      customStyles?.selected
    ),
    selectedTag: classNames("react-tags__selected-tag inline-block", customStyles?.selectedTag),
    selectedTagName: classNames("react-tags__selected-tag-name", customStyles?.selectedTagName),
    search: classNames("react-tags__search inline-block", customStyles?.search),
    searchInput: classNames(
      "react-tags__search-input mx-2 my-1 outline-none bg-transparent text-sm text-neutral-50",
      customStyles?.searchInput
    ),
    suggestions: classNames(
      "react-tags__suggestions text-sm min-w-[20em] absolute top-[100%] left-0 w-full z-20 bg-neutral-700 rounded-md",
      "[&>ul]:p-0",
      "[&>ul>li]:border-b [&>ul>li]:border-neutral-800 [&>ul>li]:py-1 [&>ul>li]:px-2",
      " [&>ul>li]:hover:cursor-pointer",
      customStyles?.suggestions
    ),
    suggestionActive: classNames("is-active text-neutral-50", customStyles?.suggestionActive),
    suggestionDisabled: classNames("is-disabled text-neutral-800", customStyles?.suggestionDisabled)
  };

  const reactTags = useRef(null);

  function suggestionsFilter(query: string, suggestions: Tag[]) {
    return matchSorter(suggestions, query, { keys: ["name"] });
  }

  function TagComponent({
    tag,
    removeButtonText,
    onDelete
  }: {
    tag: Tag;
    removeButtonText: string;
    onDelete: (e: any) => void;
  }) {
    return (
      <button type="button" title={`${removeButtonText}: ${tag.name}`} onClick={onDelete}>
        {tag.name}
        <span className="ml-2 text-neutral-300">x</span>
      </button>
    );
  }

  return (
    <div className="bg-neutral-800 text-neutral-400 rounded-xl p-3">
      <ReactTags
        ref={reactTags}
        newTagText={labelText}
        tags={selected.map((item) => {
          return { id: item, name: item };
        })}
        classNames={{
          root: defaultStyles.root,
          rootFocused: defaultStyles.rootFocused,
          selected: defaultStyles.selected,
          selectedTag: defaultStyles.selectedTag,
          selectedTagName: defaultStyles.selectedTagName,
          search: defaultStyles.search,
          searchInput: defaultStyles.searchInput,
          suggestions: defaultStyles.suggestions,
          suggestionActive: defaultStyles.suggestionActive,
          suggestionDisabled: defaultStyles.suggestionDisabled
        }}
        minQueryLength={0}
        suggestions={suggestions}
        suggestionsTransform={suggestionsFilter}
        onAddition={onAdd}
        onDelete={onDelete}
        allowNew={allowNew}
        allowBackspace={false}
        tagComponent={TagComponent}
      />
    </div>
  );
};

export default InputTagsAutocomplete;
