import { useStoreState } from "../../../hooks";
import { getAuthorizeUrl } from "../../../services/newAuth";
import { GoogleIconSVG } from "../../general/SVGIcon/Social";
import { getAuthUrl } from "../general/helpers";
import LoginButton from "../general/LoginButton";

const GoogleLoginProvider = () => {
  const signInError = useStoreState((state) => state.auth.signInError);
  const useGoogleLogin = useStoreState((state) => state.app.config?.use_google_login ?? false);

  const handleButtonClick = async () => {
    const authUrl = await getAuthUrl("google");
    if (authUrl) {
      window.location.assign(authUrl);
    }
  };

  return (
    <>
      {useGoogleLogin && (
        <LoginButton
          handleButtonClick={handleButtonClick}
          icon={<GoogleIconSVG className="w-6 h-6 fill-neutral-850 text-center" />}
        />
      )}
      {signInError ? (
        <div>
          <p className="text-sm text-secondary-900">{signInError}</p>
        </div>
      ) : null}
    </>
  );
};

export default GoogleLoginProvider;
