import { thunk } from "easy-peasy";
import { AccountWhitelist } from "../../types";

const whitelistThunks: AccountWhitelist.Thunks = {
  getAccountWhitelists: thunk(async (_, payload, helpers) => {
    const getAccountWhitelist =
      helpers.injections.apiService.Api.getAccountWhitelist;

    const result = await getAccountWhitelist();
    return result;
  }),
  getAccountWhitelistById: thunk(async (_, { id }, helpers) => {
    const getAccountWhitelistById =
      helpers.injections.apiService.Api.getAccountWhitelistById;

    const result = await getAccountWhitelistById(id);
    return result;
  }),
  addAccountWhitelist: thunk(async (_, payload, helpers) => {
    const postAccountWhitelist =
      helpers.injections.apiService.Api.postAccountWhitelist;
    try {
      return await postAccountWhitelist(payload.name, payload.accounts);
    } catch (error) {
      console.log(error);
    }
  }),
  updateAccountWhitelist: thunk(async (_, payload, helpers) => {
    const { id, name, accounts } = payload;
    const actions = helpers.getStoreActions();
    const setError = actions.error.setError;
    const putAccountWhitelist =
      helpers.injections.apiService.Api.putAccountWhitelist;
    try {
      const result = await putAccountWhitelist(id, { name, accounts });
      if (result?.error) {
        setError(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }),
  deleteAccountWhitelist: thunk(async (_, payload, helpers) => {
    const { id } = payload;
    const deleteAccountWhitelist =
      helpers.injections.apiService.Api.deleteAccountWhitelist;
    try {
      await deleteAccountWhitelist(id);
    } catch (error) {
      console.log(error);
    }
  }),
  checkAccountWhitelist: thunk(async (_, payload, helpers) => {
    const { accounts } = payload;
    const checkAccountWhitelist =
      helpers.injections.apiService.Api.checkAccountWhitelist;
    try {
      return await checkAccountWhitelist(accounts);
    } catch (error) {
      console.log(error);
    }
  }),
};

const accountWhitelists = {
  ...whitelistThunks,
};

export default accountWhitelists;
