import { Bounty } from "../../../../../types/Bounty";

const calcPlatformFee = (bounty: Bounty) => {
  return (
    (100 * parseInt(bounty.platform_fee)) /
    (parseInt(bounty.amount) + parseInt(bounty.platform_fee))
  ).toFixed();
};

const getSlotPercents = (bounty: Bounty, index: number) => {
  return bounty.multitasking?.subtasks
    ? bounty.multitasking?.subtasks[index].subtask_percent / 1000
    : 0;
};

export { calcPlatformFee, getSlotPercents };
