import CompanyProfile from "../../components/Section/Profile/CompanyProfile";
import { Layout } from "../../components/Layout";
import Sidebar from "../../components/Bounty/Sidebar";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CompanyMetadata, IndividualMetadata, PublicProfile } from "../../types/Auth";
import { Api } from "../../services/profileApi";
import IndividualProfile from "../../components/Section/Profile/IndividualProfile";
import { DEV_MODE } from "../../constants";
import mixpanel from "mixpanel-browser";
import { MixpanelContext } from "../App";
import { parse, stringify } from "query-string";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const UserInfo = () => {
  const context = useContext(MixpanelContext);
  mixpanel.init(context.token, {
    debug: false
  });

  const urlParams = useParams();
  const type = new URL(window.location.href).searchParams.get("type");
  const [profile, setProfile] = useState<PublicProfile<
    CompanyMetadata | IndividualMetadata
  > | null>(null);

  const loadUserInfo = async (username: string) => {
    const publicProfile = await Api.getPublicProfile(username, type);
    if (publicProfile?.success) {
      setProfile(publicProfile.success.data);
    }
  };

  useEffect(() => {
    if (urlParams?.userId) {
      loadUserInfo(urlParams.userId);
    }
  }, [urlParams?.userId]);

  useEffect(() => {
    if (!DEV_MODE) {
      mixpanel.track("Hero Profile", {
        source: window.location.hostname
      });
    }
  }, []);

  return (
    <Layout>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify
        }}>
        <QueryClientProvider client={queryClient}>
          <div className="container mx-auto text-white w-full ">
            <div className="grid grid-cols-4">
              <div className="col-span-1 lg:block sm:hidden max-w-[256px]">
                <Sidebar />
              </div>
              <div className="lg:col-span-3 sm:col-span-4 lg:px-12 sm:px-2">
                {!profile ? (
                  <div>This user does not exist</div>
                ) : (
                  <>
                    {(profile as PublicProfile<CompanyMetadata>)?.type === "Company" && (
                      <CompanyProfile publicProfile={profile} />
                    )}
                    {(profile as PublicProfile<IndividualMetadata>)?.type === "Individual" && (
                      <IndividualProfile publicProfile={profile} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </QueryClientProvider>
      </QueryParamProvider>
    </Layout>
  );
};

export default UserInfo;
