import { Area } from "../../Layout";
import classNames from "classnames";
import GithubIconSVG from "../../general/SVGIcon/Social/GithubIconSVG";
import HunterAvatar from "../HunterAvatar";
import { Link } from "react-router-dom";
import { CompletedBountiesByTags, HuntersListItem } from "../../../types/Hunter";
import { Response } from "../../../hooks/useHuntersSearchParams";
import { CompanyMetadata, IndividualMetadata } from "../../../types/Auth";
import { TwitterXIconSVG } from "../../general/SVGIcon/Social";
import { InfiniteData, InfiniteQueryObserverResult } from "@tanstack/react-query";
import InfiniteScroll from "react-infinite-scroll-component";
const HuntersList = ({
  data,
  hasNextPage,
  fetchNextPage
}: {
  data?: InfiniteData<Response>;
  fetchNextPage: () => Promise<InfiniteQueryObserverResult<Response, unknown>>;
  hasNextPage?: boolean;
}) => {
  return (
    <>
      <InfiniteScroll
        dataLength={data?.pages ? data.pages.length : 0}
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        scrollThreshold={1}
        loader={
          <div className="flex items-center justify-center py-2.5">
            <span className="dot-pulse" />
          </div>
        }
        endMessage={
          <p className="text-sm text-primary-900 p-4 text-center">
            {data && data.pages?.length && data.pages[0].page_count ? (
              <b>Yay! You have seen it all</b>
            ) : (
              <></>
            )}
          </p>
        }>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-x-6 lg:gap-y-12">
          {data &&
            data.pages.length > 0 &&
            data.pages.map((page) =>
              page.hunters.map((hunter: HuntersListItem) => {
                const isCompanyProfile = hunter?.metadata?.profileType === "Company";
                return (
                  <Area
                    key={hunter.id}
                    customStyles={{
                      borderContainer: classNames(""),
                      contentContainer: classNames(
                        "bg-neutral-870 hover:bg-neutral-830 rounded-[2.5rem] !border-sm border-neutral-800"
                      )
                    }}>
                    <Link to={`/hunters/hunter/${hunter.id}`}>
                      <div className="h-full flex flex-col justify-between p-6">
                        <div>
                          <div className="flex gap-4 items-center mb-5">
                            <HunterAvatar
                              picture={hunter?.picture}
                              className="w-14 h-14 mt-0 my-0"
                            />
                            <p className="mb-1">
                              {isCompanyProfile
                                ? (hunter.metadata as CompanyMetadata)?.company.companyName
                                : hunter?.name}
                            </p>
                          </div>
                          <div className="flex flex-col gap-2.5">
                            <div className="flex justify-between border-sm border-neutral-800 p-4 rounded-[10px] text-sm">
                              <p className="text-neutral-100">Completed bounties</p>
                              <p className="text-primary-900">
                                {hunter?.claimantDetails?.length > 0
                                  ? hunter.claimantDetails[0]?.completedBounties
                                  : 0}
                              </p>
                            </div>
                            {isCompanyProfile ? (
                              <p className="border-sm border-neutral-800 p-4 rounded-[10px] text-sm text-neutral-200 truncate">
                                {(hunter.metadata as CompanyMetadata)?.company?.companyAbout?.slice(
                                  0,
                                  100
                                )}
                              </p>
                            ) : (hunter.metadata as IndividualMetadata)?.individual?.bio ? (
                              <p className="border-sm border-neutral-800 p-4 rounded-[10px] text-sm text-neutral-200">
                                {(hunter.metadata as IndividualMetadata)?.individual?.bio?.slice(
                                  0,
                                  100
                                )}
                              </p>
                            ) : (
                              <p className="border-sm border-neutral-800 p-4 rounded-[10px] text-sm text-neutral-200">
                                No description added
                              </p>
                            )}
                            <div className="flex flex-wrap gap-1.5">
                              {hunter?.claimantDetails?.length > 0 ? (
                                hunter?.claimantDetails[0]?.completedBountiesByTags.map(
                                  (el: CompletedBountiesByTags, i: number) => (
                                    <div
                                      key={i}
                                      className="flex gap-4 rounded py-1 px-2 bg-neutral-800 text-xs text-neutral-50">
                                      <span>{el?.tag}</span>
                                      <div className="flex justify-center items-center w-4 h-4 bg-neutral-600 rounded-full">
                                        {el?.quantity}
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <p className="border-sm border-neutral-800 p-2 rounded-[10px] text-sm text-neutral-500">
                                  No skills added
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-wrap gap-1.5 mt-4">
                          {(hunter.metadata as IndividualMetadata)?.individual?.github ||
                          (hunter.metadata as IndividualMetadata)?.individual?.twitter ? (
                            <>
                              {(hunter.metadata as IndividualMetadata)?.individual?.github && (
                                <a
                                  onClick={(e) => e.stopPropagation()}
                                  href={(hunter.metadata as IndividualMetadata).individual.github}
                                  target="_blank"
                                  rel="noreferrer">
                                  <GithubIconSVG className="w-[26px] h-[26px] fill-neutral-400" />
                                </a>
                              )}
                              {(hunter.metadata as IndividualMetadata)?.individual?.twitter && (
                                <a
                                  onClick={(e) => e.stopPropagation()}
                                  href={(hunter.metadata as IndividualMetadata).individual.twitter}
                                  target="_blank"
                                  rel="noreferrer">
                                  <TwitterXIconSVG className="w-[26px] h-[26px] fill-neutral-400" />
                                </a>
                              )}
                            </>
                          ) : (
                            <p className="border-sm border-neutral-800 p-2 rounded-[10px] text-sm text-neutral-500">
                              No Socials added
                            </p>
                          )}
                        </div>
                      </div>
                    </Link>
                  </Area>
                );
              })
            )}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default HuntersList;
