import BountyListItem from "../BountyListItem";
import { Response } from "../../../hooks/useBounties";
import { Bounty } from "../../../types/Bounty";
import ResponsivePagination from 'react-responsive-pagination';

const BountyList = ({
  data,
  page,
  setPage
}: {
  data?: Response;
  page?: number;
  setPage: (value: number) => void;
}) => {
  return (
    <div>
      {data &&
        data.bounties?.length > 0 &&
        data.bounties.map((bounty: Bounty, index: number) => (
          <>
            <BountyListItem key={`bounty-${bounty.id}-item-${index}`} bounty={bounty} />
          </>
        ))}
        <div className="w-10/12 m-auto">
            <ResponsivePagination
                extraClassName="flex justify-center items-center gap-2 mt-8"
                pageItemClassName="border-sm border-neutral-300 rounded hover:border-primary-700"
                activeItemClassName="border-primary-700"
                pageLinkClassName="inline-block px-3 py-2"
                previousClassName="text-xl !border-0"
                nextClassName="text-xl !border-0"
                previousLabel="<"
                nextLabel=">"
                current={page || 0}
                total={data?.page_count || 0}
                onPageChange={(page) => {
                    setPage(page);
                }}
            />
        </div>
    </div>
  );
};

export default BountyList;
