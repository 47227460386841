import { useState, useEffect, useMemo, useCallback } from "react";
import { useStoreActions } from ".";
import { AccountWhitelist } from "../types";

const useUserAccountWhitelists = () => {
  const [whitelists, setWhitelists] = useState<AccountWhitelist.AccountWhitelist[] | [] | null>();

  const getAccountWhitelists = useStoreActions(
    (actions) => actions.accountWhitelists.getAccountWhitelists
  );

  const loadWhitelists = useCallback(async () => {
    setWhitelists(null);
    const result = await getAccountWhitelists();
    if (result) {
      setWhitelists([...result]);
    }
  }, [getAccountWhitelists]);

  const mapOptionAccountWhitelists = useMemo(() => {
    return (
      whitelists?.map((item) => {
        return { name: item.id.toString(), value: item.name };
      }) ?? [{ name: "", value: "" }]
    );
  }, [whitelists]);

  useEffect(() => {
    loadWhitelists();
  }, [loadWhitelists]);

  return {
    whitelists,
    accountWhitelists: mapOptionAccountWhitelists,
    loadWhitelists
  };
};

export default useUserAccountWhitelists;
