import { Participant } from "../../../types/Bounty";

const formatPercents = (percents: number) => {
  return (percents / 1000).toString();
};

const taskClaimedByUser = (participants_list: Participant[], slot: number) => {
  return participants_list[slot] !== null;
};

export { taskClaimedByUser, formatPercents };
