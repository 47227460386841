import { DarkButton } from "../../../general/Button";
import Modal from "../../../general/Modal";

export default function RejectBountyForm(props: {
  show: boolean;
  loading: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  const { show, loading, handleClose, handleSubmit } = props;

  return (
    <Modal
      {...{
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                disabled: loading,
                customStyles: { button: "max-w-[146px]" },
                onClick: () => handleSubmit(),
                text: !loading ? "Reject" : "Submitting..."
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white">Reject application</div>
        <div className="py-2.5 text-neutral-200">Are you sure you want to reject the Hero?</div>
      </div>
    </Modal>
  );
}
