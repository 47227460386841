import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { GradientBorder, Section } from "../../Layout";

import "./styles.css";
import Button from "../../general/Button/Button";
import { useNavigate } from "react-router-dom";
import { urlDefSearchParams } from "../../../store/models/BountyListModel";

type TProps = {
  toggleButton: ReactNode;
  bgImageStyle: string;
  textBgStyle: string;
};

export default function HeroSection(props: TProps) {
  const { toggleButton, bgImageStyle, textBgStyle } = props;

  const navigate = useNavigate();

  const handleBrowseBounty = () => {
    navigate(`/bounties?${urlDefSearchParams()}`);
  };

  const customStyles = twMerge(`
    w-full h-[740px]
    ${bgImageStyle} bg-no-repeat bg-cover
    gradient-border rounded-hero-section
    md:rounded-hero-section-md
    lg:h-[644px]
  `);

  return (
    <Section {...{ id: "hero_section" }}>
      <GradientBorder {...{ customStyles }}>
        <div
          className="
          h-full w-full
          flex flex-col items-center justify-center content-center
          space-y-[41px]
          text-center
          md:space-y-[50px]
          lg:space-y-[75px]
          pt-8
        ">
          <h1
            className={twMerge(`
            text-3xl font-bold uppercase
            ${textBgStyle} bg-clip-text
            bg-cover bg-no-repeat
            leading-none
            md:text-6xl
            text-reveal
          `)}>
            connecting
            <br className="md:hidden" /> the best
            <br />
            of Open Web
            <br />
            projects & talents
            <br />
          </h1>
          <div className="flex flex-col text-base font-medium font-poppins">
            <Button
              onClick={handleBrowseBounty}
              customStyles={{
                button: "bg-primary-900 hover:bg-primary-800 px-8 py-[1.125rem] !border-0",
                text: "text-neutral-900"
              }}>
              BROWSE BOUNTIES
            </Button>
          </div>
        </div>
      </GradientBorder>
      <div className="flex justify-center mt-6 md:mt-12">
        <div className="max-w-[21.5rem]">{toggleButton}</div>
      </div>
    </Section>
  );
}
