import { SubmitHandler, useFormContext } from "react-hook-form";
import { DarkButton, LightButton } from "../../../general/Button";
import { Area } from "../../../Layout";
import { BountyCreate } from "../../../../types";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { ClaimantApproval, Multitasking } from "../../../../types/BountyCreate";
import KYCVerification from "./components/KYCValidation";
import Accordion from "../../../general/Accordion";
import BountyReviewers from "./components/BountyReviewers";
import BountyRestrictions from "./components/BountyRestrictions";
import { FormRow } from "../../../general/Form/FormElements";

export type AdditionalInfoFormData = {
  claimant_approval?: ClaimantApproval;
  reviewers?: BountyCreate.ReviewersParams;
  reviewer?: string;
  multitasking?: Multitasking;
};

function AdditionalInfo() {
  const methods = useFormContext<AdditionalInfoFormData>();
  const { handleSubmit, formState } = methods;

  const { errors } = formState;
  const actions = useStoreActions((actions) => actions);

  const asignValidatorDAO = useStoreState((state) => state.newBountyForm.asignValidatorDAO);
  const {
    newBountyForm: { next, prev }
  } = actions;

  const handleBackClick = () => {
    prev();
  };

  const handleNextClick = () => {
    if (typeof errors === "object" && Object.keys(errors).length === 0) {
      next();
    }
  };

  const onSubmit: SubmitHandler<AdditionalInfoFormData> = () => handleNextClick();

  return (
    <div id="additional-info-form">
      {!asignValidatorDAO && (
        <FormRow className="mt-2.5">
          <Area
            {...{
              customStyles: {
                borderContainer: "border border-neutral-700 rounded-3xl",
                contentContainer: "rounded-3xl"
              }
            }}>
            <Accordion title="Add reviewers" alert={!!errors?.reviewers}>
              <BountyReviewers />
            </Accordion>
          </Area>
        </FormRow>
      )}
      <FormRow className="mt-2.5">
        <Area
          {...{
            customStyles: {
              borderContainer: "border border-neutral-700 rounded-3xl",
              contentContainer: "rounded-3xl"
            }
          }}>
          <Accordion
            title="Bounty Customization"
            alert={!!errors?.claimant_approval || !!errors.multitasking}>
            <BountyRestrictions />
          </Accordion>
        </Area>
      </FormRow>
      <FormRow className="mt-2.5">
        <Area
          {...{
            customStyles: {
              borderContainer: "border border-neutral-700 rounded-3xl",
              contentContainer: "rounded-3xl"
            }
          }}>
          <Accordion title="KYC">
            <KYCVerification />
          </Accordion>
        </Area>
      </FormRow>
      <FormRow className="my-6 flex gap-4 justify-center">
        <DarkButton
          {...{
            text: "Back",
            onClick: () => handleBackClick()
          }}
        />
        <LightButton
          {...{
            text: "Review",
            onClick: () => handleSubmit(onSubmit)(),
            type: "submit"
          }}
        />
      </FormRow>
    </div>
  );
}

export default AdditionalInfo;
