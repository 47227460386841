import { ReactNode, isValidElement } from "react"

interface TProps {
  title?: string | ReactNode,
  id?: string,
  children: ReactNode[] | ReactNode,
}

const defaultProps:TProps = {
  title: '',
  id: '',
  children: null
}

const styles={
  section: `
    container block
    mb-[3em] mx-auto
    md:mb-[6em]
  `,
  title: `
    container
    text-neutral-50 text-2xl font-bold -tracking-[0.02em] text-center
    mx-[auto]
    mb-[2.5rem]
    md:w-[60%]
    md:text-3xl
    md:mb-[3.25rem]
  `,
}

const titleElement = (title: string | ReactNode) => {
  let result = undefined

  if ((title as string).length) {
    result = <h5 className={styles.title}>{title}</h5>
  } else if (isValidElement(title)) {
    result = title
  } else {
    result = null
  }

  return result
}

const Section = function(props:TProps) {
  const { title, id, children } = {...defaultProps, ...props};

  return (
    <section id={id ? id : ''} className={styles.section}>
      { titleElement(title) }
      <div>
        { children }
      </div>
    </section>
  )
}

export default Section;
