import { RefObject, useCallback, useEffect, useRef } from "react";

export const useOutsideClick = <E extends HTMLDivElement>(callback: () => void) => {
  const ref = useRef<E>() as RefObject<E>;

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (ref?.current && !ref.current?.contains(event?.target as Node)) {
        callback();
      }
    },
    [ref, callback]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [handleClick]);

  return ref;
};

export default useOutsideClick;
