import { Bounty } from "../../../../../types/Bounty";
import { DarkButton } from "../../../../general/Button";
import Modal from "../../../../general/Modal";
import { FormRow } from "../../../../general/Form/FormElements";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import TokenPriceForm from "./TokenPriceForm";
import CurrencyPriceForm from "./CurrencyPriceForm";

export type EditBountyPriceFormData = {
  selectedTokenAccount?: { name: string; value: string };
  amount: string;
};

const EditAmountForm = (props: {
  show: boolean;
  loading: boolean;
  handleClose: () => void;
  handleSubmit: (value: { amount: string }) => void;
  bounty: Bounty;
}) => {
  const { show, handleClose, handleSubmit, bounty, loading } = props;
  const methods = useForm<EditBountyPriceFormData>({
    mode: "onChange"
  });
  const { formState } = methods;
  const { isValid } = formState;

  const handleSubmitClick: SubmitHandler<EditBountyPriceFormData> = ({
    amount
  }: {
    amount: string;
  }) => {
    if (isValid) {
      handleSubmit({ amount });
    }
  };

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                disabled: loading,
                onClick: () => methods.handleSubmit(handleSubmitClick)(),
                text: !loading ? "Update" : "Submitting..."
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white">Edit bounty price</div>
        <div className="py-2.5">
          <FormRow>
            <FormProvider {...methods}>
              {!bounty.postpaid && <TokenPriceForm bounty={bounty} />}
              {bounty.postpaid && <CurrencyPriceForm bounty={bounty} />}
            </FormProvider>
          </FormRow>
        </div>
      </div>
    </Modal>
  );
};

export default EditAmountForm;
