import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import Button, { ButtonProps, ButtonStyles } from "./Button";

const buildStyles = (customStyles: ButtonStyles | undefined, disabled: boolean | undefined) => ({
  button: twMerge(
    classNames(
      "w-full max-w-[208px]",
      {
        "border bg-neutral-800 border-primary-900 button--primary_hover text-primary-800 hover:text-primary-900 active:bg-neutral-830":
          !disabled
      },
      customStyles?.button
    )
  ),
  text: twMerge(classNames("text-sm md:text-base", customStyles?.text))
});

function DarkButton(props: ButtonProps) {
  const { customStyles, text, onClick, disabled, type } = props;

  const styles = buildStyles(customStyles, disabled);

  return (
    <Button
      {...{
        customStyles: styles,
        text,
        onClick,
        disabled,
        type
      }}
    />
  );
}

export default DarkButton;
