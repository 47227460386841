import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { DarkButton } from "../../../../general/Button";
import { TextArea } from "../../../../general/Input";
import { ValidationLayout } from "../../../../Layout";
import Modal from "../../../../general/Modal";

type EditWhitelistData = {
  accounts: string;
};

export default function EditWhitelistAccountsForm(props: {
  show: boolean;
  whitelist?: string;
  handleClose: () => void;
  handleSubmit: (value: EditWhitelistData) => Promise<void>;
}) {
  const { show, whitelist, handleClose, handleSubmit } = props;
  const methods = useForm<EditWhitelistData>({
    defaultValues: { accounts: whitelist ?? "" }
  });
  const [accounts, setAccounts] = useState(whitelist ?? "");
  const { errors, isValid, isSubmitted } = methods.formState;
  const onSubmit: SubmitHandler<EditWhitelistData> = (data) => {
    if (isValid) {
      handleSubmit(data);
    }
  };

  const handleChangeAccounts = (value: string) => {
    methods.setValue("accounts", value, { shouldValidate: true });
    setAccounts(value);
  };

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                onClick: () => methods.handleSubmit(onSubmit)(),
                text: "Submit",
                type: "submit"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins my-2.5">
        <div className="text-lg text-white py-2.5">Edit whitelist</div>
        <div>
          <input type="hidden" {...methods.register("accounts", { required: "Required" })} />
          <ValidationLayout
            {...{
              validate: () => {
                return {
                  valid: !errors.accounts,
                  started: isSubmitted,
                  message: errors.accounts?.message
                };
              }
            }}>
            <TextArea
              {...{
                value: accounts,
                placeholder: "Accounts",
                onChange: (value: string) => handleChangeAccounts(value)
              }}
            />
          </ValidationLayout>
          <p className="text-[10px] text-neutral-700 my-1">
            Comma-separated values(value1, value2,...,valueN)
          </p>
        </div>
      </div>
    </Modal>
  );
}
