import { ReactNode } from "react";

const RightSidebar = (props: { show: boolean; children: ReactNode }) => {
  const { show, children } = props;
  return (
    <div>
      <div className=" z-50 fixed inset-0 opacity-[75%] bg-[#101010] MuiModal-backdrop" />
      <div
        className={`font-poppins top-0 lg:rounded-none 
        sm:rounded-tl-3xl sm:rounded-tr-3xl right-0 lg:w-[25vw]
        sm:w-full bg-neutral-800 fixed lg:h-full sm:h-[100vh] z-[60]  
        sm:MuiModal-root 
        ease-in-out duration-300 ${show ? "translate-x-0 " : "translate-x-full"}`}>
        {children}
      </div>
    </div>
  );
};

export default RightSidebar;
