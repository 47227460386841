import classNames from "classnames";
import { ReactNode } from "react";

const Paragraph = (props: {
  children: ReactNode | ReactNode[];
  className?: string;
}) => {
  const { children, className } = props;

  return (
    <p className={classNames("pb-2 text-neutral-100", className)}>{children}</p>
  );
};

export default Paragraph;
