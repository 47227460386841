import classNames from "classnames";
import { usePagination } from "../../hooks/usePagination";

const Pagination = (props: {
  onPageChange: (value: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
}) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1] ?? currentPage;

  return (
    <>
      {pageSize && pageSize > 0 ? (
        <span className="text-xs text-neutral-400">
          {currentPage} of {lastPage}
        </span>
      ) : (
        <span />
      )}
      <div className="flex gap-4 text-xs">
        <button
          disabled={currentPage === 1}
          className={classNames("outline-none", {
            "text-neutral-800": currentPage === 1
          })}
          onClick={onPrevious}>
          Back
        </button>
        <button
          disabled={currentPage === lastPage}
          className={classNames("outline-none", {
            "text-neutral-800": currentPage === lastPage
          })}
          onClick={onNext}>
          Next
        </button>
      </div>
    </>
  );
};

export default Pagination;
