import { useEffect, useState } from "react";
import { LightButton } from "../../../../general/Button";
import Modal from "../../../../general/Modal";
import SuccessSteps from "./SuccessSteps";
import { Claim } from "../../../../../types/Claim";
import useWalletAccountId from "../../../../../hooks/useWalletAccountId";

const AcceptSuccessMessage = (props: { show: boolean }) => {
  const { show } = props;
  const [showMessage, setShowMessage] = useState(show);

  const handleCloseModal = () => {
    setShowMessage(!showMessage);
  };

  return (
    <Modal
      {...{
        customStyles: { content: "min-h-[300px] h-fit" },
        open: showMessage,
        onClose: () => handleCloseModal()
      }}>
      <div className="font-poppins flex-1 flex ite flex-col justify-between text-neutral-50 max-w-[315px] m-auto">
        <div className="flex-1 flex justify-center mb-[4rem]">
          <div className="flex flex-col">
            <h2 className="text-lg text-center text-primary-800 max-w-[315px] my-2.5">
              Congratulations! Your application has been accepted!
            </h2>
            <div className="text-center mt-2.5">
              <h2 className="mb-6">Next steps</h2>
              <SuccessSteps />
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <LightButton
            {...{
              customStyles: { button: "max-w-[156px]" },
              text: "Close",
              onClick: () => handleCloseModal()
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AcceptSuccessMessage;
