import { useMemo } from "react";
import utils from "../services/utils";
import useCurrencies from "./useCurrencies";

const useCurrencyDecimalPrecision = (props: { currency?: string; amount?: string }) => {
  const { currency, amount } = props;
  const { currencies } = useCurrencies();

  const currencyDecimalPrecision = useMemo(() => {
    if (currency && amount) {
      const currCurrency = currencies.find((currencyItem) => currencyItem.symbol === currency);
      if (currCurrency) {
        return utils.formatToken(amount, currCurrency?.decimals ?? 2);
      }
      return null;
    }
    return null;
  }, [currency, amount, currencies]);

  return {
    currencyDecimalPrecision
  };
};

export default useCurrencyDecimalPrecision;
