import classNames from "classnames";

const RightArrowLong = (props: { className?: string }) => {
  const { className } = props;
  return (
      <svg className={classNames(className)} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5 5.74414L14 8.24414M14 8.24414L11.5 10.7441M14 8.24414H2" stroke="#949494" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  );
};

export default RightArrowLong;
