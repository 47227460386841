import useCurrencyDecimalPrecision from "../../../../hooks/useCurrencyDecimalPrecision";
import Amount from "./Amount";

const CurrencyAmount = (props: {
  currency: string;
  amount: string;
  className?: string;
}) => {
  const { currency, amount, className } = props;
  const { currencyDecimalPrecision } = useCurrencyDecimalPrecision({
    currency,
    amount,
  });
  return (
    <Amount
      symbol={currency}
      amount={currencyDecimalPrecision ?? ""}
      className={className}
    />
  );
};

export default CurrencyAmount;
