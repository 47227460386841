import { CreateBountyForm } from "../../components/Form";
import ErrorMessage from "../../components/Bounty/general/ErrorMessage";
import { useAllowCreateBounty } from "../../providers/AllowCreateBountyContext";
import { Area } from "../../components/Layout";
import { AppMessages } from "../../common/app-messages";
import Modal from "../../components/general/Modal";
import { useStoreState } from "../../hooks";
import { useWalletSelector } from "../../providers/WalletSelectorContext";
import { ConnectWalletButtonV2 } from "../../components/general/Button";
import ImageSrc from "../../assets/images/wallet-connect.png";

const CreateBounty = () => {
  const { isAllowedCreateBounty } = useAllowCreateBounty();
  const { selector, modal } = useWalletSelector();
  const isSignedIn = selector?.isSignedIn();
  const contractId = useStoreState((state) => state.app.config?.bounty_contract_id);

  const handleSignIn = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selector && contractId) {
      modal.show();
    }
  };

  return (
    <div>
      <div className="container text-white w-full max-w-[640px]">
        {isAllowedCreateBounty ? (
          <>
            <CreateBountyForm />
            <Modal
              {...{
                customStyles: { content: "h-fit min-h-[300px]" },
                open: !isSignedIn
              }}>
              <div className="font-poppins text-neutral-50 text-center">
                <h2 className="py-4">One final step!</h2>
                <p>You need to connect wallet to continue</p>
                <div className="flex justify-center py-[2.5rem]">
                  <img src={ImageSrc} alt="" />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <ConnectWalletButtonV2
                  text={"Connect Wallet"}
                  customStyles={{
                    text: "font-[600] text-neutral-850 text-lg",
                    textWrap: "p-1"
                  }}
                  onClick={(e) => handleSignIn(e)}
                />
              </div>
            </Modal>
          </>
        ) : (
          <div className="flex py-4">
            <Area>
              <div className="flex items-center px-4">
                <AppMessages.CreateBountyIsNotAllowedMessage />
              </div>
            </Area>
          </div>
        )}
      </div>

      <ErrorMessage />
    </div>
  );
};

export default CreateBounty;
