import { ReactNode } from "react";

import "./styles.css";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

type MarksLayerStyles = {
  borderContainer?: string;
  contentContainer?: string;
};

interface MarksLayerProps {
  children: ReactNode[] | ReactNode;
  customStyles?: MarksLayerStyles;
}

const buildStyles = (customStyles: MarksLayerStyles | undefined) => ({
  borderContainer: twMerge(classNames("relative", customStyles?.borderContainer)),
  contentContainer: twMerge(
    classNames(
      "absolute top-0 right-6 flex flex-row justify-end gap-1 left-[-6] translate_y--neg50",
      customStyles?.contentContainer
    )
  )
});

const MarksLayer = function (props: MarksLayerProps) {
  const { children, customStyles } = props;
  const styles = buildStyles(customStyles);

  return (
    <div className={styles.borderContainer}>
      <div className={styles.contentContainer}>{children ? children : null}</div>
    </div>
  );
};

export default MarksLayer;
