import classNames from "classnames";

const CheckedYellowIcon = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("w-6 h-6", className)}>
      <circle cx="14" cy="14" r="14" fill="#FFCF14" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.466 7.55088C23.1645 8.2998 23.1797 9.53084 22.5 10.3005L13.5707 20.4116C12.9292 21.138 11.8889 21.1996 11.1804 20.5532L5.63928 15.4977C4.8886 14.8128 4.78395 13.587 5.40553 12.7599C6.02711 11.9328 7.13954 11.8174 7.89022 12.5023L12.177 16.4134L19.9706 7.5884C20.6502 6.81876 21.7675 6.80196 22.466 7.55088Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};

export default CheckedYellowIcon;
