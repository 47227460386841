import { Layout } from "../../components/Layout";
import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useContext } from "react";
import mixpanel from "mixpanel-browser";
import { MixpanelContext } from "../App";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { parse, stringify } from "query-string";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const Hunters = () => {
  const context = useContext(MixpanelContext);
  mixpanel.init(context.token, {
    debug: false
  });

  return (
    <Layout>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify
        }}>
        <QueryClientProvider client={queryClient}>
          <div className="container mx-auto text-white w-full ">
            <Outlet />
          </div>
        </QueryClientProvider>
      </QueryParamProvider>
    </Layout>
  );
};

export default Hunters;
