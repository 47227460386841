import { twMerge } from "tailwind-merge";
import Button, { ButtonProps, ButtonStyles } from "./Button";

const buildStyles = (customStyles: ButtonStyles | undefined) => ({
  button: twMerge(`
    block
    relative z-50
    aspect-square min-h-[27px]
    bg-no-repeat bg-contain bg-menu-cross-icon bg-cover
    border-none
    cursor-pointer
    ${customStyles?.button ? customStyles.button : ""}
  `),
});

function CrossButton(props: ButtonProps) {
  const { customStyles, onClick } = props;

  const styles = buildStyles(customStyles);

  return (
    <Button
      {...{
        customStyles: styles,
        onClick,
      }}
    />
  );
}

export default CrossButton;
