import { Document, Page } from "react-pdf";
import { useState } from "react";
import { pdfjs } from "react-pdf";
import { CloseIcon } from "../../../general/SVGIcon";
import IconButton from "../../../general/Button/IconButton";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PreviewInvoiceForm = (props: {
  show: boolean;
  pdfFile?: File | null;
  handleClose: () => void;
  txHash?: string | null;
  handleDownloadPdf?: (value: File) => void;
  handleDownloadCSV?: () => void;
}) => {
  const { show, handleClose, pdfFile } = props;
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <>
      {show && (
        <div className="fixed inset-0 bg-neutral-900 bg-opacity-50 mx-auto overflow-y-auto h-full w-full max-w-[50vw] z-50">
          <div className="font-poppins text-neutral-50">
            <div className="px-2 py-2 bg-neutral-900 text-white">
              <div className="flex justify-between px-2">
                <p className="">
                  Page {pageNumber} of {numPages}
                </p>
                <div className="flex gap-4">
                  <IconButton
                    {...{
                      icon: <CloseIcon className="w-[18px] h-[18px] fill-neutral-100" />,
                      onClick: () => handleClose()
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="bg-white">
              <div className="px-4">
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                </Document>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviewInvoiceForm;
