import { useStoreActions, useStoreState } from "../../../../hooks";
import { DarkButton, LightButton } from "../../../general/Button";
import Modal from "../../../general/Modal";

const ErrorVerifyTokenMessage = () => {
  const verifyTokenFailure = useStoreState((state) => state.auth.verifyTokenFailure);
  const { verifyTokenAsync, setVerifyTokenFailure } = useStoreActions((actions) => actions.auth);

  const handleUpdateButtonClick = () => {
    verifyTokenAsync({ silent: true });
  };

  return (
    <div className="font-poppin">
      <Modal
        {...{
          open: verifyTokenFailure,
          onClose: () => setVerifyTokenFailure(false)
        }}>
        <div className="text-xl text-white">Something went wrong...</div>
        <div className="text-md break-word text-white">
          <p className="mb-6">
            Your session token was not updated due to an error. Please check your internet
            connection.
          </p>
        </div>
        <div className="flex gap-2  justify-between">
          <DarkButton
            {...{
              customStyles: { button: "max-w-[148px]" },
              text: "Close",
              onClick: () => setVerifyTokenFailure(false)
            }}
          />
          <LightButton
            {...{
              customStyles: { button: "max-w-[148px]" },
              text: "Retry",
              onClick: () => handleUpdateButtonClick()
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ErrorVerifyTokenMessage;
