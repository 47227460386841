import { Claim } from "../../../types/Claim";
import { Bounty } from "../../../types/Bounty";
import { Input } from "../../general/Input";
import IconButton from "../../general/Button/IconButton";
import { useState } from "react";
import classNames from "classnames";
import { AirplaneIcon } from "../../general/SVGIcon";

const ReplyAction = (props: {
  showForm: boolean;
  claim: Claim;
  bounty: Bounty;
  walletAccountId: string;
  setShowForm: () => void;
  onSubmit: (value: string) => void;
  onClose: () => void;
  refetch: () => void;
}) => {
  const { onSubmit, refetch } = props;
  const [message, setMessage] = useState("");

  const handleChangeMessage = (value: string) => {
    setMessage(value);
  };

  const handleKeyDown = (value: string) => {
    if (value === "Enter" && message) {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    onSubmit(message);
    setMessage("");
    refetch();
  };

  return (
    <div className="flex flex-1">
      <Input
        {...{
          customStyles: {
            input: "bg-neutral-700"
          },
          placeholder: "Enter message",
          value: message,
          onKeyDown: (e: string) => handleKeyDown(e),
          onChange: (value: string) => handleChangeMessage(value)
        }}
      />
      <div className="px-4">
        <IconButton
          customStyles={{ button: "w-full h-full" }}
          disabled={!message}
          icon={
            <AirplaneIcon
              className={classNames("h-6 w-6 stroke-primary-900", {
                "stroke-neutral-600": !message
              })}
            />
          }
          onClick={() => handleSendMessage()}
        />
      </div>
    </div>
  );
};

export default ReplyAction;
