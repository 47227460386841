import { useState } from "react";
import { DEV_MODE } from "../../../../../constants";
import { useStoreActions } from "../../../../../hooks";
import { Invoice } from "../../../../../hooks/useInvoices";
import Api from "../../../../../services/Api";
import { downloadPDFFile } from "../../../../../utils";
import { getFileName } from "../helpers";
import ActionButton from "./general/ActionButton";
import mixpanel from "mixpanel-browser";

const DownloadInvoiceAction = (props: { invoice: Invoice; accountId: string }) => {
  const { invoice, accountId } = props;
  const setError = useStoreActions((actions) => actions.error.setError);
  const [loading, setLoading] = useState(false);

  const handleLoadInvoice = async () => {
    setLoading(true);
    const responseParams = {
      invoiceId: invoice?.invoiceId ?? invoice.bountyId,
      accountId,
      toPdf: true,
      isBountyId: invoice?.invoiceId ? false : true,
      claimerAccountId: invoice.accountId,
      claimNumber: invoice.claimNumber
    };
    const response = await Api.getInvoice(responseParams);
    setLoading(false);
    if (response?.success) {
      const fileName = response.success.headers["content-disposition"]
        ? getFileName(response.success.headers["content-disposition"])
        : "invoice.pdf";
      const file = new File([response.success.data], fileName);
      downloadPDFFile(file);
      if (!DEV_MODE) {
        mixpanel.track("Invoice Download", {
          source: window.location.hostname
        });
      }
    } else {
      setError("Invoice download error");
    }
  };

  return <ActionButton text="Download" disabled={loading} onClick={handleLoadInvoice} />;
};

export default DownloadInvoiceAction;
