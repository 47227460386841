import { useState, useEffect } from "react";
import Modal from "../../../general/Modal";
import { useStoreActions, useStoreState } from "../../../../hooks";
import ReceiptMessage from "./ReceiptMessage";

function CheckClaimReceipt() {
  const actions = useStoreActions((actions) => actions);

  const {
    nearApi: { setClaimStatus }
  } = actions;

  const transactionHashes = useStoreState((state) => state.nearApi.transactionHashes);
  const claimStatus = useStoreState((state) => state.nearApi.claimStatus);

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (claimStatus === "CheckReceipt") setShowMessage(true);
    if (claimStatus === "Success") setShowMessage(true);
    if (claimStatus === "Failure") setShowMessage(true);
  }, [claimStatus]);

  const handleCloseModal = () => {
    setShowMessage(!showMessage);
    setClaimStatus(null);
    window.location.reload();
  };

  return (
    <>
      <Modal
        {...{
          customStyles: { content: "min-h-[300px] h-fit" },
          open: showMessage,
          onClose: () => handleCloseModal()
        }}>
        <ReceiptMessage
          status={claimStatus}
          txHash={transactionHashes}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </>
  );
}

export default CheckClaimReceipt;
