import { BaseSection, BaseSectionProps } from "./BaseSection";

export default function HowSection() {
  const baseSectionProps = {
    id: 'how_section',
    title: 'Identify bounties, connect with projects and get rewarded, all through the NEAR ecosystem',
    items: [
      {
        bgImageClass: 'bg-how-section__item-1',
        title: 'Browse work and apply to solve bounty',
      },
      {
        bgImageClass: 'bg-how-section__item-2',
        title: 'Submit proposal, get contract and collaborate',
      },
      {
        bgImageClass: 'bg-how-section__item-3',
        title: 'Complete your work and get paid securely',
      },
    ],
  } as BaseSectionProps
  
  return (
    <BaseSection {...baseSectionProps} />
  )
}
