import { useEffect, useState } from "react";
import { RoundedUpIcon } from "../SVGIcon";

const ScrollToTopButton = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScrollButtonVisible = () => {
      window.scrollY > 300 ? setShowScrollButton(true) : setShowScrollButton(false);
    };
    window.addEventListener("scroll", handleScrollButtonVisible);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisible);
    };
  }, []);

  return (
    <>
      {showScrollButton && (
          <button className="backToTopWidget" onClick={() => handleScrollToTop()}>
              <RoundedUpIcon className="w-12 h-12 stroke-neutral-900" />
          </button>
      )}
    </>
  );
};

export default ScrollToTopButton;
