import { ReactNode } from "react";
import { ModalUnstyled } from "@mui/base";

import BackdropUnstyled from "./BackdropUnstyled";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { CloseIcon } from "../SVGIcon";

type ModalStyles = {
  modal?: string;
  backdrop?: string;
  content?: string;
};

interface ModalProps {
  title?: string;
  customStyles?: ModalStyles;
  open: boolean;
  disableOutsideClick?: boolean;
  onClose?: () => void;
  children?: ReactNode | ReactNode[];
  okButton?: () => ReactNode | ReactNode[];
}

const buildStyles = (customStyles: ModalStyles | undefined) => ({
  modal: twMerge(
    classNames("inset-0 z-[60] fixed flex items-center justify-center", customStyles?.modal)
  ),
  backdrop: twMerge(
    classNames("z-[-1] fixed inset-0 opacity-[75%] bg-[#101010]", customStyles?.backdrop)
  ),
  content: twMerge(
    classNames(
      "w-[400px] h-[300px] flex flex-col justify-between rounded-[1rem] border border-neutral-700 bg-neutral-850 outline-none p-[1.25em]",
      customStyles?.content
    )
  )
});

const Modal = function (props: ModalProps) {
  const {
    title = "",
    customStyles,
    onClose,
    open = false,
    children,
    okButton,
    disableOutsideClick = true
  } = props;

  const styles = buildStyles(customStyles);

  const handleModalClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason && reason == "backdropClick" && disableOutsideClick) {
      return;
    } else {
      onClose && onClose();
    }
  };

  return (
    <ModalUnstyled
      {...{
        disableEscapeKeyDown: true,
        className: styles.modal,
        open,
        onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") =>
          handleModalClose(event, reason),
        slotProps: { backdrop: { className: styles.backdrop } },
        slots: { backdrop: BackdropUnstyled }
      }}>
      <div className={styles.content}>
        <div
          className={classNames("flex", {
            "justify-end": !title
          })}>
          {title}
          <button className="outline-none" onClick={() => onClose && onClose()}>
            <CloseIcon className="h-4 w-4 fill-neutral-400" />
          </button>
        </div>
        {children ? children : null}
        {okButton && <div className="flex justify-end">{okButton ? okButton() : null}</div>}
      </div>
    </ModalUnstyled>
  );
};

export default Modal;
