import { useState, useRef, useEffect } from "react";
import { findIdByValue } from "../../../../utils";
import { useOutsideClick } from "../../../../hooks";
import { buildStyles as buildItemStyles } from "../Item";
import { scroll } from "../MultiSelect";
import { Validation, DEFAULT_VALUE, DEFAULT_STYLES } from "../../../../helpers/Validation";

import { InfoIcon, SelectArrow } from "../../SVGIcon";
import OptionList, { OptionListStyles } from "../OptionList";
import { Input } from "../../../general/Input";
import { twMerge } from "tailwind-merge";
import IconButton from "../../../general/Button/IconButton";

export type Option = {
  name: string;
  value: string;
};

type SelectValidationFunction = (selected: Option) => Validation;

interface NewSelectProps {
  placeholder?: string;
  textInfo?: string;
  defaultOption: Option;
  defaultOptionsList: Option[];
  selected?: Option;
  onSelect: (...args: any[]) => void;
  customStyles?: NewSelectStyles;
  validate?: SelectValidationFunction;
  disabled?: boolean;
}

type NewSelectStyles = {
  container?: string;
  selectedItem?: string;
  optionsList: OptionListStyles;
  label?: string;
};

const buildStyles = (
  customStyles: NewSelectStyles | undefined,
  showOptionList: boolean,
  validation: Validation,
  disabled: boolean = false
) => ({
  container: twMerge(`
    m-width-[50px]
    relative
    ${customStyles?.container ? customStyles.container : ""}
    ${disabled ? "!cursor-not-allowed" : ""}
  `),
  selectedItem: twMerge(`
    ${buildItemStyles().container}
    flex flex-row justify-between items-center
    rounded-[10px]
    ${showOptionList ? "rounded-b-none" : ""}
    ${customStyles?.selectedItem ? customStyles.selectedItem : ""}
    ${disabled ? "!cursor-not-allowed" : ""}
  `),
  label: {
    labelText: `
      ${customStyles?.label ? customStyles.label : ""}
    `,
    validationText: `
      ${DEFAULT_STYLES.invalid.message}
    `
  }
});

function NewSelect(props: NewSelectProps) {
  const {
    placeholder,
    textInfo,
    defaultOption,
    defaultOptionsList,
    selected,
    onSelect,
    customStyles,
    validate,
    disabled
  } = props;
  const [showOptionList, setShowOptionList] = useState(false);
  const [optionsList, setOptionsList] = useState<Option[]>(defaultOptionsList);
  const [filterInputValue, setFilterInputValue] = useState(selected?.value);
  const [highlighted, setHighlighted] = useState(0);
  const validation = (validate && validate(selected || defaultOption)) || DEFAULT_VALUE;

  useEffect(() => {
    setOptionsList(defaultOptionsList);
  }, [defaultOptionsList]);

  useEffect(() => {
    setFilterInputValue(selected?.value);
  }, [selected]);

  const styles = buildStyles(undefined, showOptionList, validation, disabled);

  const compare = (element: Option, item: Option) => {
    return element.name === item.name;
  };
  const optionListRef = useRef<HTMLUListElement>(null);

  const onKeyDown = (key: string) => {
    if (key === "ArrowUp") {
      scroll(key, optionsList.length, highlighted, optionListRef);
      const newHighlightedId = highlighted ? highlighted - 1 : 0;
      setHighlighted(newHighlightedId);
      setShowOptionList(true);
    } else if (key === "ArrowDown") {
      scroll(key, optionsList.length, highlighted, optionListRef);
      const newHighlightedId =
        highlighted < optionsList.length - 1 ? highlighted + 1 : optionsList.length - 1;
      setHighlighted(newHighlightedId);
      setShowOptionList(true);
    } else if (key === "Enter") {
      const safeSelected = optionsList.length ? optionsList[highlighted] : defaultOption;
      processSelect(safeSelected, safeSelected.value, defaultOptionsList);
      setShowOptionList(false);
    }
  };

  const onOptionClick = (selectedName: string) => {
    const selectedOption = optionsList.filter((opt: Option) => opt.name === selectedName)[0];

    onSelect && onSelect(selectedOption);
    setFilterInputValue(selectedOption.value);
    setShowOptionList(false);
  };

  const onMouseOver = (highlightedName: string) => {
    const highlightedOption =
      optionsList.find((option: Option) => option.name === highlightedName) || optionsList[0];
    const highlightedId = findIdByValue<Option>(optionsList, highlightedOption, compare) || 0;
    setHighlighted(highlightedId);
  };

  const processSelect = (
    selectedOption: Option | undefined,
    filterInputValue: string,
    optionsList: Option[]
  ) => {
    onSelect && onSelect(selectedOption);
    setOptionsList(optionsList);
    setFilterInputValue(filterInputValue);
  };

  const onFilterInputChange = (value: string) => {
    const filteredOptions = defaultOptionsList.filter((option) =>
      option.value.toLowerCase().match(value.toLowerCase())
    );
    setShowOptionList(true);
    setFilterInputValue(value);
    setOptionsList(filteredOptions);
    if (value === "") {
      onSelect(defaultOption);
      setHighlighted(0);
    }
  };

  const handleListDisplay = () => {
    !disabled && setShowOptionList(!showOptionList);
  };

  function handleClickOutside() {
    setShowOptionList(false);
  }
  const outsideClickRef = useOutsideClick<HTMLDivElement>(handleClickOutside);

  return (
    <div ref={outsideClickRef} className={styles.container}>
      <div
        className={showOptionList ? styles.selectedItem + "active" : styles.selectedItem}
        onClick={handleListDisplay}>
        {textInfo && (
          <div className="mr-4">
            <div className="group relative flex justify-center w-fit">
              <IconButton
                {...{
                  icon: <InfoIcon />
                }}
              />
              <span className="absolute bottom-10 w-max scale-0 rounded-full transition-all bg-neutral-800 border border-neutral-700 p-2 text-xs text-white group-hover:scale-100">
                {textInfo}
              </span>
            </div>
          </div>
        )}
        <Input
          {...{
            customStyles: {
              input: "bg-transparent outline-none !p-0 rounded-none"
            },
            placeholder,
            name: "",
            value: filterInputValue,
            onChange: onFilterInputChange,
            onKeyDown,
            autocomplete: "off",
            disabled
          }}
        />
        <div className="ml-4">
          <SelectArrow {...{ position: showOptionList ? "up" : "down" }} />
        </div>
      </div>
      {showOptionList && (
        <OptionList
          {...{
            refer: optionListRef,
            customStyles: { ...(customStyles?.optionsList || {}) },
            optionsList,
            onOptionClick,
            onMouseOver,
            onKeyDown,
            highlightedId: highlighted,
            highlightedIds: []
          }}
        />
      )}
    </div>
  );
}

export default NewSelect;
