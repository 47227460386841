import TokenAmountPerSlot from "./TokenAmountPerSlot";
import MinSlotsToStart from "./MinSlotsToStart";
import NumberOfSlots from "./NumberOfSlots";
import CurrencyAmountPerSlot from "./CurrencyAmountPerSlot";
import { useStoreState } from "../../../../../../../hooks";
import { FormRow } from "../../../../../../general/Form/FormElements";
import { Area } from "../../../../../../Layout";
import CreatingManyClaimsOption from "../CreatingManyClaimsOption";

const OneForAllForm = () => {
  const postpaid = useStoreState((state) => state.newBounty.postpaid);
  return (
    <FormRow>
      <Area
        {...{
          customStyles: {
            borderContainer: "border border-neutral-700 px-4 py-2.5"
          }
        }}>
        <FormRow>
          <h6>Number of slots</h6>
          <NumberOfSlots />
        </FormRow>
        <FormRow>{!postpaid ? <TokenAmountPerSlot /> : <CurrencyAmountPerSlot />}</FormRow>
        <FormRow>
          <h6>Min slots to start (Optional)</h6>
          <MinSlotsToStart />
        </FormRow>
        <CreatingManyClaimsOption />
      </Area>
    </FormRow>
  );
};

export default OneForAllForm;
