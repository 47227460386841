import classNames from "classnames";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

const TableDataCell = (props: {
  className?: string;
  children: ReactNode;
  colSpan?: number;
  rowSpan?: number;
}) => {
  const { className, colSpan, rowSpan, children } = props;
  return (
    <td
      className={twMerge(
        classNames("font-poppins text-[10px] p-4 pl-8 text-neutral-200 ", className)
      )}
      rowSpan={rowSpan}
      colSpan={colSpan}>
      {children}
    </td>
  );
};

export default TableDataCell;
