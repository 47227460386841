import { useState } from "react";
import { format } from "date-fns";
import { useStoreState } from "../../../hooks";
import useBountyTransactions from "../../../hooks/useBountyTransactions";
import { Area } from "../../Layout";
import { CheckGreenCircleIcon, ClockIcon } from "../../general/SVGIcon";
import { getMethodDescription } from "./helpers";
import { txViewUrl } from "../general/helpers";

const BountyTransactions = (props: { bountyId: string }) => {
  const { bountyId } = props;
  const walletSelector = useStoreState((state) => state.nearApi.walletSelector);
  const { data } = useBountyTransactions({ bountyId });
  const [showAll, setShowAll] = useState(false);

  const dataForDisplay = showAll ? data : data.slice(0, 3);

  return dataForDisplay ? (
    <Area
      {...{
        customStyles: {
          borderContainer: "border border-neutral-800",
          contentContainer:
            "bg-neutral-850 p-8 text-neutral-50 text-sm break-words overflow-auto scrollbar"
        }
      }}>
      <h2 className="mb-6">Activity</h2>
      {dataForDisplay.map((tx) => (
        <div key={tx.hash} className="mb-2.5">
          <Area
            {...{
              customStyles: {
                contentContainer: "bg-neutral-825 p-4 text-neutral-50 text-[10px]"
              }
            }}>
            <div className="flex justify-between">
              <span className="flex items-center gap-2">
                {tx.methodName === "bounty_done" && <CheckGreenCircleIcon className="w-5 h-5" />}
                {tx.methodName === "start_competition" && <ClockIcon />}
                <a href={txViewUrl(tx.hash, walletSelector)} target="_blank" rel="noreferrer">
                  {getMethodDescription(tx)}
                </a>
              </span>
              <span className="text-neutral-100">
                {format(new Date(tx.txDate), "do MMM, yyyy hh:mm a")}
              </span>
            </div>
          </Area>
        </div>
      ))}
      {data.length > 3 && (
        <div className="flex items-center justify-center py-2.5">
          <button
            className="text-sm text-neutral-400 outline-none"
            onClick={() => setShowAll(!showAll)}>
            {showAll ? "show less" : "show all"}
          </button>
        </div>
      )}
    </Area>
  ) : null;
};

export default BountyTransactions;
