import { Bounty, NextActionModel } from "../../../types/Bounty";
import { Claim, ClaimStatus } from "../../../types/Claim";
import { Area } from "../../Layout";
import ApplicantItem from "../ApplicantItem";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import DropdownMenu from "../../general/DropdownMenu";
import { SelectArrow } from "../../general/SVGIcon";
import useWalletAccountId from "../../../hooks/useWalletAccountId";
import { FormRow } from "../../general/Form/FormElements";
import CheckBox from "../../general/CheckBox";
import classNames from "classnames";
import utils from "../../../services/utils";
import { dateCompare } from "./helpers";
import RejectSelectedAction from "../Actions/RejectSelectedAction";
import ApproveSelectedAction from "../Actions/ApproveSelectedAction";
import AcceptSelectedAction from "../Actions/AcceptSelectedAction";
import DeclineSelectedAction from "../Actions/DeclineSelectedAction";
import { useStoreActions } from "../../../hooks";
import Applications from "./Applications";
import FinalizeSelectedAction from "../Actions/FinalizeSelectedAction";
import { NextActionProps } from "../../../hooks/useNextActions";

const ApplicationList = (props: {
  bounty: Bounty;
  nextActions: NextActionProps;
  refetchActions: () => void;
}) => {
  const ITEMS_LIMIT = 4;
  const { bounty, nextActions, refetchActions } = props;
  const [showAll, setShowAll] = useState(false);
  const [statusFilters, setSatusFilters] = useState<ClaimStatus[] | []>([]);
  const [onlyOwnClaims, setOnlyOwnClaims] = useState(false);
  const { walletAccountId } = useWalletAccountId();
  const [checked, setChecked] = useState<Claim[] | []>([]);
  const getKYCWhitelisted = useStoreActions((actions) => actions.nearApi.getWhitelisted);
  const [whitelisted, setWhitelisted] = useState<string[] | []>([]);

  const getAvailableUserActions = () => {
    return (nextActions as Partial<NextActionModel>[] | null)?.find(
      (item) => item.accountId === walletAccountId
    )?.forClaims;
  };

  const isKYCRequired = () => {
    return Boolean(
      bounty.kyc_config?.kyc_required &&
        bounty.kyc_config.kyc_verification_method === "DuringClaimApproval"
    );
  };

  const isDisabled = () => {
    const a = utils.grantAccess(bounty, null, walletAccountId);
    return a.checkAccess([!a.isBountyOwner(), !a.isReviewer()]);
  };

  const isWhitelisted = (claim: Claim) => {
    return whitelisted.includes(claim.owner as never);
  };

  const isHidden = () => {
    return bounty.multitasking?.multitasking_type !== "OneForAll" || !showAll;
  };

  const canCheck = (claim: Claim) => {
    const availableForCheck = getAvailableUserActions() ?? [];
    return Boolean(
      availableForCheck.find(
        (item) =>
          item.accountId === claim.owner &&
          item.claimNumber === claim.claim_number &&
          item.nextActions &&
          !item.nextActions["extendClaimDeadline"]
      )
    );
  };

  const dataForDisplay = showAll
    ? bounty.claims.sort(dateCompare).sort((a, _) => (a.owner === walletAccountId ? -1 : 0))
    : bounty.claims
        .sort(dateCompare)
        .sort((a, _) => (a.owner === walletAccountId ? -1 : 0))
        .slice(0, ITEMS_LIMIT);

  const filteredClaims = dataForDisplay
    .filter((claim: Claim) => (onlyOwnClaims ? claim.owner === walletAccountId : claim))
    .filter((claim: Claim) =>
      statusFilters.length ? statusFilters.includes(claim.status as never) : claim
    );

  const handleSetStatusFilter = (value: ClaimStatus) => {
    const newFilters = !statusFilters.includes(value as never)
      ? [...statusFilters, value]
      : statusFilters.filter((item) => item !== value);
    setSatusFilters(newFilters);
  };

  const handleSetActive = () => {
    const newFilters = !["New", "Completed"].every((i) => statusFilters.includes(i as never))
      ? ([...statusFilters, "New", "Completed"] as ClaimStatus[])
      : statusFilters.filter((item) => item !== "New").filter((item) => item !== "Completed");
    setSatusFilters(newFilters);
  };

  const handleClose = useCallback(() => {
    setSatusFilters([]);
    setShowAll(!showAll);
  }, [showAll]);

  const isChecked = (claim: Claim) => {
    return Boolean(checked.find((item) => item.id === claim.id));
  };

  const handleCheck = (claim: Claim) => {
    if (canCheck(claim)) {
      const values = isChecked(claim as never)
        ? checked.filter((item) => item.id !== claim.id)
        : [...checked, claim];
      setChecked(values);
    }
  };

  const handleCheckAll = () => {
    if (checked.length !== 0) {
      setChecked([]);
    } else {
      setChecked(
        !showAll
          ? dataForDisplay.filter((item) => canCheck(item))
          : filteredClaims.filter((item) => canCheck(item))
      );
    }
  };

  const handleShowAll = () => {
    setSatusFilters([]);
  };

  const getClaimsForAcceptAll = () => {
    const claimUserActions = getAvailableUserActions() ?? [];
    const canAccept = claimUserActions.filter((claim) => claim.nextActions?.acceptClaimant);
    return checked.filter((el) => {
      return canAccept.some((f) => {
        return (
          f.accountId === el.owner &&
          f.claimNumber === el.claim_number &&
          whitelisted.includes(f.accountId as never)
        );
      });
    });
  };

  const getClaimsForDeclineAll = () => {
    const claimUserActions = getAvailableUserActions() ?? [];
    const canAccept = claimUserActions.filter((claim) => claim.nextActions?.declineClaimant);
    return checked.filter((el) => {
      return canAccept.some((f) => {
        return f.accountId === el.owner && f.claimNumber === el.claim_number;
      });
    });
  };

  const getClaimsForFinalizeAll = () => {
    const claimUserActions = getAvailableUserActions() ?? [];
    const canAccept = claimUserActions.filter((claim) => claim.nextActions?.finalizeClaim);
    return checked.filter((el) => {
      return canAccept.some((f) => {
        return f.accountId === el.owner && f.claimNumber === el.claim_number;
      });
    });
  };

  const getClaimsForApproveAll = () => {
    const claimUserActions = getAvailableUserActions() ?? [];
    const canAccept = claimUserActions.filter((claim) => claim.nextActions?.bountyApprove);
    return checked.filter((el) => {
      return canAccept.some((f) => {
        return f.accountId === el.owner && f.claimNumber === el.claim_number;
      });
    });
  };

  const getClaimsForRejectAll = () => {
    const claimUserActions = getAvailableUserActions() ?? [];
    const canAccept = claimUserActions.filter((claim) => claim.nextActions?.bountyReject);
    return checked.filter((el) => {
      return canAccept.some((f) => {
        return f.accountId === el.owner && f.claimNumber === el.claim_number;
      });
    });
  };

  const showApproveActions = () => {
    return getClaimsForApproveAll().length > 0;
  };

  const showRejectAllActions = () => {
    return getClaimsForRejectAll().length > 0;
  };

  const showAcceptActions = () => {
    return getClaimsForAcceptAll().length > 0;
  };

  const showFinalizeActions = () => {
    return getClaimsForFinalizeAll().length > 0;
  };

  const showDeclineActions = () => {
    return getClaimsForDeclineAll().length > 0;
  };

  const MenuButton = (props: { text: string; onClick: MouseEventHandler; selected: boolean }) => {
    const { text, onClick, selected } = props;
    return (
      <button
        className={classNames(
          "w-full flex gap-2 text-sm justify-end text-neutral-300 items-center my-1 break-normal whitespace-nowrap",
          {
            "text-primary-900": selected
          }
        )}
        onClick={onClick}>
        {text}
      </button>
    );
  };

  const checkClaimOwnerWhitelisted = async () => {
    const claimsOwner = Array.from(new Set(bounty.claims.map((claim) => claim.owner)));
    for (const item of claimsOwner) {
      const isWhitelisted = await getKYCWhitelisted(item);
      if (isWhitelisted) {
        setWhitelisted((prevData) => Array.from(new Set([...prevData, item])));
      }
    }
  };

  useEffect(() => {
    if (isKYCRequired()) {
      checkClaimOwnerWhitelisted();
    } else {
      setWhitelisted(bounty.claims.map((item) => item.owner));
    }
  }, []);

  return (
    <>
      {
        <Applications open={showAll} onClose={handleClose}>
          <div className="font-poppins my-2">
            <Area
              {...{
                customStyles: {
                  borderContainer: "border border-neutral-800",
                  contentContainer: "p-6 bg-neutral-850"
                }
              }}>
              <div className="flex items-start justify-between">
                <div className="flex items-start gap-1">
                  <h2 className="text-sm text-neutral-50">Applications</h2>
                  <span className="border text-neutral-50 bg-neutral-700 border-neutral-700 rounded-sm text-[10px] px-2">
                    {bounty.claims.length}
                  </span>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                {!isHidden() && !isDisabled() && (
                  <FormRow className="px-2 w-fit py-1 my-2.5 border border-neutral-700 bg-neutral-825 rounded-lg">
                    <div className="relative flex items-center gap-1">
                      <CheckBox
                        {...{
                          onClick: () => handleCheckAll(),
                          active:
                            checked.length > 0 &&
                            checked.length ===
                              dataForDisplay.filter((item) => canCheck(item)).length
                        }}
                      />
                      <DropdownMenu
                        {...{
                          customStyles: {
                            dropdownMenu:
                              "w-fit md:left-1/2 md:-translate-x-1/2 transform px-3 w-screen max-w-fit"
                          }
                        }}
                        icon={<SelectArrow className="w-3 h-3 stroke-neutral-400" />}>
                        <MenuButton
                          text="All"
                          onClick={() => handleShowAll()}
                          selected={statusFilters.length === 0}
                        />
                        <MenuButton
                          text="In Progress"
                          onClick={() => handleSetStatusFilter("InProgress")}
                          selected={statusFilters.includes("InProgress" as never)}
                        />
                        <MenuButton
                          text="Approved"
                          onClick={() => handleSetStatusFilter("Approved")}
                          selected={statusFilters.includes("Approved" as never)}
                        />
                        <MenuButton
                          text="Rejected"
                          onClick={() => handleSetStatusFilter("Rejected")}
                          selected={statusFilters.includes("Rejected" as never)}
                        />
                        <MenuButton
                          text="Active"
                          onClick={() => handleSetActive()}
                          selected={
                            statusFilters.includes("Completed" as never) &&
                            statusFilters.includes("New" as never)
                          }
                        />
                      </DropdownMenu>
                    </div>
                  </FormRow>
                )}
                {!isDisabled() && (
                  <>
                    <FormRow>
                      <div className="flex gap-2 items-center">
                        {showApproveActions() && (
                          <ApproveSelectedAction
                            bounty={bounty}
                            claim={getClaimsForApproveAll()}
                            text="Approve Selected"
                          />
                        )}
                        {showRejectAllActions() && (
                          <RejectSelectedAction
                            bounty={bounty}
                            refetchActions={refetchActions}
                            claim={getClaimsForRejectAll()}
                            text="Reject Selected"
                          />
                        )}
                      </div>
                    </FormRow>
                    <FormRow>
                      <div className="flex gap-2 items-center">
                        {showAcceptActions() && (
                          <AcceptSelectedAction
                            bounty={bounty}
                            claim={getClaimsForAcceptAll()}
                            text="Accept Selected"
                          />
                        )}
                        {showDeclineActions() && (
                          <DeclineSelectedAction
                            bounty={bounty}
                            refetchActions={refetchActions}
                            claim={getClaimsForDeclineAll()}
                            text="Decline Selected"
                          />
                        )}
                      </div>
                    </FormRow>
                    <FormRow>
                      <div className="flex gap-2 items-center">
                        {showFinalizeActions() && (
                          <FinalizeSelectedAction
                            bounty={bounty}
                            refetchActions={refetchActions}
                            claim={getClaimsForFinalizeAll()}
                            text="Finalize Selected"
                          />
                        )}
                      </div>
                    </FormRow>
                  </>
                )}
              </div>

              {dataForDisplay ? (
                filteredClaims.map((claim, index) => (
                  <>
                    <ApplicantItem
                      key={`claim-${bounty.id}-${index}`}
                      bountyClaim={claim}
                      bounty={bounty}
                      nextActions={nextActions}
                      refetchActions={refetchActions}
                      isWhitelisted={isWhitelisted(claim)}
                      onClick={(value: Claim) =>
                        !isDisabled() && isWhitelisted(claim) && handleCheck(value)
                      }
                      checked={isChecked(claim)}
                    />
                  </>
                ))
              ) : (
                <>
                  <div className="rounded-xl bg-neutral-800 p-14 text-sm text-neutral-200 text-center my-4">
                    {"Your applicants will appear here"}
                  </div>
                </>
              )}

              {bounty.claims.length > ITEMS_LIMIT && (
                <div className="flex items-center justify-center py-2.5">
                  <button
                    className="text-sm text-neutral-400 outline-none"
                    onClick={() => handleClose()}>
                    {showAll ? "close" : `show all (${bounty.claims.length})`}
                  </button>
                </div>
              )}
            </Area>
          </div>
        </Applications>
      }
    </>
  );
};

export default ApplicationList;
