import { useForm } from "react-hook-form";
import { DefermentOfKYC } from "../../../../types/Kyc";
import { DarkButton } from "../../../general/Button";
import CheckBox from "../../../general/CheckBox";
import Modal from "../../../general/Modal";
import { Bounty } from "../../../../types/Bounty";

type FormData = {
  kyc_postpone: undefined | DefermentOfKYC;
};

const AcceptSelectedHeroesForm = (props: {
  bounty: Bounty;
  //claim: Claim;
  show: boolean;
  handleClose: () => void;
  handleSubmit: (value?: DefermentOfKYC) => void;
  isClaimerWhitelisted: boolean;
  useKYC: boolean;
  canPostponeKYC: boolean;
}) => {
  const {
    bounty,
    //claim,
    show,
    handleClose,
    handleSubmit,
    canPostponeKYC,
    useKYC = false,
    isClaimerWhitelisted
  } = props;
  const methods = useForm<FormData>({
    mode: "onChange"
  });
  const { register, setValue, getValues, watch } = methods;

  const isDisabled = () => {
    if (!useKYC) {
      return false;
    }
    return !isClaimerWhitelisted && !watch("kyc_postpone");
  };

  const handleKycPostponeClick = (value: DefermentOfKYC) => {
    if (getValues("kyc_postpone") === value) {
      setValue("kyc_postpone", undefined, { shouldValidate: true });
    } else {
      setValue("kyc_postpone", value, { shouldValidate: true });
    }
  };

  const handleSubmitClick = () => {
    const value = getValues("kyc_postpone");
    if (value) {
      handleSubmit(value);
    } else {
      handleSubmit();
    }
  };

  return (
    <Modal
      {...{
        customStyles: { content: "min-h-[300px] h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                disabled: isDisabled(),
                onClick: () => handleSubmitClick(),
                text: "Accept"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white mb-2.5">Accept application</div>
        {useKYC && !isClaimerWhitelisted ? (
          <div className="my-2.5">
            <h2 className="text-white">This applicant has not completed KYC verification</h2>
            {canPostponeKYC && (
              <>
                <div className="py-2.5">
                  <p className="text-sm text-neutral-400">
                    If you want to accept the applicant, you need to skip or postpone KYC:
                  </p>
                  <div className="flex flex-wrap gap-4 py-2.5">
                    <input type="hidden" {...register("kyc_postpone")} />
                    <CheckBox
                      {...{
                        text: "Skip KYC",
                        active: watch("kyc_postpone") === "AllowedToSkipKyc",
                        onClick: () => handleKycPostponeClick("AllowedToSkipKyc")
                      }}
                    />
                    <CheckBox
                      {...{
                        text: "Postpone Before Deadline",
                        active: watch("kyc_postpone") === "BeforeDeadline",
                        onClick: () => handleKycPostponeClick("BeforeDeadline")
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="py-2.5 text-neutral-200">
              Are you sure you want to accept selected Heroes?
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AcceptSelectedHeroesForm;
