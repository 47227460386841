import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { formatValueToDecimals } from "./helpers";
import BN from "bn.js";
import { useStoreActions, useStoreState } from "../../../../../../../hooks";
import { OneForAll } from "../../../../../../../types/BountyCreate";
import useFtTokenBalance from "../../../../../../../hooks/useFtTokenBalance";
import utils from "../../../../../../../services/utils";
import { FormRow } from "../../../../../../general/Form/FormElements";
import { ValidationLayout } from "../../../../../../Layout";
import { Input } from "../../../../../../general/Input";

type FormData = {
  multitasking: {
    amount_per_slot: BN | number | null;
  };
};

const TokenAmountPerSlot = () => {
  const methods = useFormContext<FormData>();
  const { register, formState, setValue } = methods;
  const { errors, isSubmitted } = formState;
  const number_of_slots = useStoreState(
    (state) => (state.newBounty.multitasking as OneForAll).OneForAll.number_of_slots
  );
  const selectedTokenAccount = useStoreState((state) => state.newBountyForm.selectedTokenAccount);
  const tokens = useStoreState((state) => state.app.tokens);
  const amount_per_slot = useStoreState(
    (state) => (state.newBounty.multitasking as OneForAll)?.OneForAll.amount_per_slot
  );
  const { amount } = useStoreState((state) => state.newBountyForm);

  const { availableBalance } = useFtTokenBalance({
    tokenId: selectedTokenAccount?.name
  });

  const setAmountPerSlot = useStoreActions((actions) => actions.newBounty.setAmountPerSlot);
  const setPayment = useStoreActions((actions) => actions.newBountyForm.setPayment);

  const tokenDecimals = useMemo(() => {
    return tokens.find((token) => token.tokenId === selectedTokenAccount?.name)?.decimals || 0;
  }, [tokens, selectedTokenAccount]);

  const parsedBalance = useMemo(() => {
    return availableBalance ? utils.formatTokenAmount(availableBalance, tokenDecimals, 0) : 0;
  }, [availableBalance, tokenDecimals]);

  const parsedAmount = useMemo(() => {
    return amount ? utils.formatTokenAmount(amount, tokenDecimals, 0) : 0;
  }, [amount, tokenDecimals]);

  const parsedAmountPerSlot = useMemo(() => {
    return amount_per_slot
      ? utils.getFormattedTokenAmount(amount_per_slot, tokenDecimals, tokenDecimals)
      : "";
  }, [amount_per_slot, tokenDecimals]);

  const validMaxAmount = useMemo(() => {
    return parseFloat(parsedBalance.toString()) >= parseFloat(parsedAmount.toString());
  }, [parsedBalance, parsedAmount]);

  const formatAmountToDecimal = (amount: string) => {
    const tokenData = tokens.find((token) => token.tokenId === selectedTokenAccount?.name);
    if (tokenData && selectedTokenAccount) {
      const formatted = utils.getParsedTokenAmount(amount, tokenData.decimals);
      return formatted.toString();
    }
    return amount;
  };

  const [inputAmount, setInputAmount] = useState(parsedAmountPerSlot);

  const handleChange = (value: string) => {
    value = value.replaceAll(",", ".");
    if (!isNaN(parseFloat(value))) {
      value = formatValueToDecimals(value, tokenDecimals);
      const formattedAmount = formatAmountToDecimal(value);
      setInputAmount(value);
      setAmountPerSlot(new BN(formattedAmount));
      setValue("multitasking.amount_per_slot", parseFloat(value), { shouldValidate: true });
      setPayment(parseFloat((parseFloat(value) * number_of_slots).toFixed(tokenDecimals)));
    } else {
      setInputAmount("");
      setAmountPerSlot(0);
      setPayment(0);
      setValue("multitasking.amount_per_slot", null, { shouldValidate: true });
    }
  };

  return (
    <div>
      <div className="flex flex-col flex-wrap gap-4">
        <div>
          Slot price (paid in{" "}
          <span className="text-primary-900 underline">{selectedTokenAccount?.value}</span>)
        </div>
      </div>
      <FormRow>
        <ValidationLayout
          {...{
            validate: () => {
              return {
                valid: !errors.multitasking?.amount_per_slot,
                message: errors.multitasking?.amount_per_slot?.message,
                started: isSubmitted
              };
            }
          }}>
          <input
            defaultValue={parsedAmountPerSlot ?? 0}
            type="hidden"
            {...register("multitasking.amount_per_slot", {
              validate: {
                zeroValue: (value) => (value && Number(value) > 0) || "Required field",
                maxAmount: () => validMaxAmount || "Token balance is not enough"
              }
            })}
          />
          <Input
            {...{
              placeholder: "amount per slot",
              value: inputAmount,
              onChange: (value: string) => handleChange(value)
            }}
          />
        </ValidationLayout>
      </FormRow>
    </div>
  );
};

export default TokenAmountPerSlot;
