import { useEffect } from "react";
import Whitelist from "../../components/Bounty/Whitelist";
import { DEV_MODE } from "../../constants";
import mixpanel from "mixpanel-browser";

const AccountWhitelists = () => {
  useEffect(() => {
    if (!DEV_MODE) {
      mixpanel.track("Whitelists", {
        source: window.location.hostname
      });
    }
  }, []);
  return (
    <div className="container mx-auto text-white w-full ">
      <Whitelist />
    </div>
  );
};

export default AccountWhitelists;
