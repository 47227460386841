import { MouseEvent } from "react";
import { twMerge } from "tailwind-merge";
import { TagCross } from "../../general/SVGIcon";

type TagStyles = {
  container?: string;
  text?: string;
};

interface TagProps {
  customStyles?: TagStyles;
  name: string;
  value: string;
  onClose?: (value: string) => void;
}

const buildStyles = (customStyles: TagStyles | undefined) => ({
  container: twMerge(`
    w-fit
    flex flex-row justify-between items-center gap-4
    px-[1.25em]
    py-[0.25em]
    rounded-full border border-sm border-neutral-700
    ${customStyles?.container ? customStyles.container : ""}
  `),
  text: twMerge(`
    text-neutral-200 font-poppins text-sm 
    ${customStyles?.text ? customStyles.text : ""}
  `)
});

const Tag = function (props: TagProps) {
  const { customStyles, name, value, onClose } = {
    //  ...{ onClose: () => {} },
    ...props
  };

  const styles = buildStyles(customStyles);

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onClose && onClose(name);
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <p className={styles.text}>{value}</p>
      {onClose && (
        <button type='button' onClick={handleCloseClick}>
          <TagCross />
        </button>
      )}
    </div>
  );
};

export default Tag;
