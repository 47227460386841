import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  UseFormStateReturn
} from "react-hook-form";

export type FormControlProps = {
  name: string;
  control: Control<any>;
  render: ({
    field,
    fieldState,
    formState
  }: {
    field: ControllerRenderProps;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<any>;
  }) => React.ReactElement;
  rules?: object;
  defaultValue?: any;
};

function FormControl(props: FormControlProps) {
  const { name, control, rules, defaultValue, render } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={render}
    />
  );
}

export default FormControl;
