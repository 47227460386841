import moment from "moment";

function formatDateToString(date: Date, format?: string) {
  return moment(date).format(format ?? "DD.MM.YYYY - HH:mm");
}

function formatDayToNanoSec(value: Date) {
  const date = formatDateToString(value);
  return moment(date, "DD.MM.YYYY").valueOf() * 1000000;
}

function formatDurationToHuman(value: string, unit?: string): number | { [key: string]: number } {
  const tempTime = moment.duration(parseInt(value) / 1000000);
  const timeObj: { [key: string]: number } = {
    months: Number.isInteger(tempTime.asMonths()) ? tempTime.asMonths() : 0,
    weeks: Number.isInteger(tempTime.asMonths()) ? tempTime.asMonths() : 0,
    days: Number.isInteger(tempTime.asDays()) ? tempTime.asDays() : 0,
    hours: Number.isInteger(tempTime.asDays()) ? 0 : tempTime.asHours()
  };
  return unit ? timeObj[unit] : timeObj;
}

function formatToDate(value: string) {
  const date = moment(parseInt(value) / 1000000).toISOString();
  return new Date(date);
}

function formatToDateString(value: string) {
  return moment(parseInt(value) / 1000000).format("DD.MM.YYYY");
}

function formatDayFromNow(date: string) {
  return moment(date).fromNow();
}

type Base =
  | "year"
  | "years"
  | "y"
  | "month"
  | "months"
  | "M"
  | "week"
  | "weeks"
  | "w"
  | "day"
  | "days"
  | "d"
  | "hour"
  | "hours"
  | "h"
  | "minute"
  | "minutes"
  | "m"
  | "second"
  | "seconds"
  | "s"
  | "millisecond"
  | "milliseconds"
  | "ms";

type _quarter = "quarter" | "quarters" | "Q";
export type MomentDurationConstructor = Base | _quarter;

function formatDurationToNanoSec(value: string, unit: MomentDurationConstructor) {
  return moment.duration(value, unit).asMilliseconds() * 1000000;
}

export const formatting = {
  formatDateToString,
  formatToDate,
  formatToDateString,
  formatDayFromNow,
  formatDurationToNanoSec,
  formatDayToNanoSec,
  formatDurationToHuman,
};
