import { useStoreState } from "../../../../../hooks";
import { Bounty } from "../../../../../types/Bounty";
import Separator from "../../../../general/Separator";
import CurrencyAmount from "../../general/CurrencyAmount";
import TokenAmount from "../../general/TokenAmount";
import MultitaskingBountyAmount from "./MultitaskingBountyAmount";
import MultitaskingTotalAmount from "./MultitaskingTotalAmount";
import SimpleBountyAmount from "./SimpleBountyAmount";
import SimpleTotalAmount from "./SimpleTotalAmount";

const Amount = (props: { bounty: Bounty }) => {
  const { bounty } = props;
  const tokens = useStoreState((state) => state.app.tokens);

  return (
    <div className="px-[-1.25em]">
      <div className="p-[1.25em]">
        {tokens.length > 0 && (
          <div className="grid grid-cols-2 text-md gap-4 w-full">
            <div className="flex flex-col justify-between flex-1">
              <div className="text-neutral-600">Bounty</div>
              {!bounty.multitasking && <SimpleTotalAmount bounty={bounty} />}
              {bounty.multitasking &&
                bounty.multitasking.multitasking_type === "DifferentTasks" && (
                  <SimpleTotalAmount bounty={bounty} />
                )}
              {bounty.multitasking?.multitasking_type === "OneForAll" && (
                <MultitaskingTotalAmount bounty={bounty} />
              )}
            </div>
            <div className="flex flex-col justify-between flex-1">
              <div className="text-neutral-600">Service DAO Fee</div>
              <div className="text-white">
                {bounty.validators_dao ? (
                  <>
                    {bounty?.token && (
                      <TokenAmount
                        token={bounty.token}
                        amount={bounty.dao_fee}
                        className="text-white"
                      />
                    )}
                    {bounty?.currency && (
                      <CurrencyAmount
                        currency={bounty.currency}
                        amount={bounty.dao_fee}
                        className="text-white"
                      />
                    )}
                  </>
                ) : (
                  <span>NO</span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <Separator {...{ customStyles: { separator: "bg-neutral-500" } }} />
      </div>
      <div className="p-4 flex justify-between">
        <div className="text-neutral-600 text-base">Receiving amount</div>
        {!bounty.multitasking && <SimpleBountyAmount bounty={bounty} />}
        {bounty.multitasking && bounty.multitasking.multitasking_type === "DifferentTasks" && (
          <SimpleBountyAmount bounty={bounty} />
        )}
        {bounty.multitasking?.multitasking_type === "OneForAll" && (
          <MultitaskingBountyAmount bounty={bounty} />
        )}
      </div>
    </div>
  );
};

export default Amount;
