import * as nearAPI from "near-api-js";
import { Buffer } from "buffer";
import { WalletSelector } from "@near-wallet-selector/core";
import { getNearApi } from "../../services/NearApiServices";
import { nearConfig } from "../../services/nearConfig";

function isErrorCode(url: string) {
  return new URL(url).searchParams.get("errorCode");
}

function hasMeta(url: string) {
  const meta = new URL(url).searchParams.get("signMeta");
  return meta;
}

function hasCode(url: string) {
  const code = new URL(url).searchParams.get("code");
  return code;
}

function hasState(url: string) {
  const state = new URL(url).searchParams.get("state");
  return state;
}

function hasOauthToken(url: string) {
  const oAuthToken = new URL(url).searchParams.get("oauth_token");
  return oAuthToken;
}

function hasOauthVerifier(url: string) {
  const oAuthVerifier = new URL(url).searchParams.get("oauth_verifier");
  return oAuthVerifier;
}

function hasError(url: string) {
  const error = new URL(url).searchParams.get("errorCode");
  return error;
}

function hasParam(url: string, param: string) {
  const searchParam = new URL(url).searchParams.get(param);
  return searchParam;
}

function isSuccessfully(url: string) {
  const meta = new URL(url).searchParams.get("signMeta");
  return Boolean(meta);
}

function getTxHashesFromUrl(url: string) {
  const txHashes = new URL(url).searchParams.get("transactionHashes");
  // if (txHashes) {
  //   const hashesArr = txHashes.split(",");
  //   if (hashesArr.length > 1) {
  //     return hashesArr[1];
  //   }
  // }
  return txHashes;
}

function isRejected(url: string) {
  const errorCode = isErrorCode(url);
  return errorCode === "userRejected";
}

function isSomeError(url: string) {
  const errorCode = isErrorCode(url);
  return errorCode === "Error";
}

function getErrorMessage(url: string) {
  const decodeMessage = decodeURIComponent(new URL(url).searchParams.get("errorMessage") || "");
  return decodeMessage;
}

const getNear = async (walletId: string) => {
  let near;
  if (walletId === "meteor-wallet") {
    const keyStore = new nearAPI.keyStores.BrowserLocalStorageKeyStore(
      window.localStorage,
      "_meteor_wallet"
    );
    near = await nearAPI.connect({
      ...nearConfig,
      keyStore
    });
  } else {
    near = (await getNearApi()).near;
  }
  return near;
};

const signStringMessage = async (walletSelector: WalletSelector, stringMessage: string) => {
  const wallet = await walletSelector.wallet();
  const near = await getNear(wallet.id);
  const signer = near?.connection.signer;
  const accounts = await wallet.getAccounts();
  const accountId = walletSelector.isSignedIn() && accounts[0].accountId;
  const { options } = walletSelector;
  if (accountId) {
    const byteMessage = Buffer.from(stringMessage);
    const signature = await signer.signMessage(byteMessage, accountId, options.network.networkId);
    return signature
      ? {
          signature: signature.signature,
          accountId
        }
      : null;
  } else {
    return null;
  }
};

const helpers = {
  isErrorCode,
  hasMeta,
  hasCode,
  hasError,
  hasParam,
  isSuccessfully,
  isRejected,
  isSomeError,
  getTxHashesFromUrl,
  getErrorMessage,
  signStringMessage,
  hasState,
  hasOauthToken,
  hasOauthVerifier,
  getNear
};

export default helpers;
