import { Bounty } from "../../../../../types/Bounty";
import CurrencyAmount from "../../general/CurrencyAmount";
import TokenAmount from "../../general/TokenAmount";

const SimpleTotalAmount = ({ bounty }: { bounty: Bounty }) => {
  const getTotalAmount = () => {
    return (
      parseInt(bounty.amount) +
      parseInt(bounty.dao_fee ?? 0) +
      parseInt(bounty.platform_fee ?? 0)
    ).toFixed();
  };
  return (
    <div className="text-success">
      {bounty?.token && (
        <TokenAmount token={bounty.token} amount={getTotalAmount()} className="text-success" />
      )}
      {bounty?.currency && (
        <CurrencyAmount
          currency={bounty.currency}
          amount={getTotalAmount()}
          className="text-success"
        />
      )}
    </div>
  );
};

export default SimpleTotalAmount;
