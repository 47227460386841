import { useStoreState } from "../../../hooks";
import { GithubIconSVG } from "../../general/SVGIcon/Social";
import { getAuthUrl } from "../general/helpers";
import LoginButton from "../general/LoginButton";

const GithubLoginProvider = () => {
  const signInError = useStoreState((state) => state.auth.signInError);
  const useGithubLogin = useStoreState((state) => state.app.config?.use_github_login ?? false);

  const handleButtonClick = async () => {
    const authUrl = await getAuthUrl("github");
    if (authUrl) {
      window.location.assign(authUrl);
    }
  };

  return (
    <>
      {useGithubLogin && (
        <LoginButton
          handleButtonClick={handleButtonClick}
          icon={<GithubIconSVG className="w-6 h-6 fill-neutral-850 text-center" />}
        />
      )}
      {signInError ? (
        <div>
          <p className="text-sm text-secondary-900">{signInError}</p>
        </div>
      ) : null}
    </>
  );
};

export default GithubLoginProvider;
