import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewBounty from "../../components/Bounty/ViewBounty";
import Breadcrumbs from "../../components/general/Breadcrumbs";
import OwnerDetails from "../../components/Bounty/OwnerDetails";
import ViewMoreBounties from "../../components/Bounty/ViewMoreBounties";
import useBountyDetails from "../../hooks/useBountyDetails";
import mixpanel from "mixpanel-browser";
import { MixpanelContext } from "../App";
import useNextActions from "../../hooks/useNextActions";
import NextActionsContext from "../../components/Bounty/general/context/NextActionsContext";
import BountyInvoices from "../../components/Bounty/Invoices";
import useWalletAccountId from "../../hooks/useWalletAccountId";
import WalletAccountContext from "../../components/Bounty/general/context/WalletAccountContext";
import utils from "../../services/utils";
import { Bounty } from "../../types/Bounty";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const DEV_MODE = process.env.NODE_ENV === "development" || false;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function BountyDetails() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const { walletAccountId } = useWalletAccountId();
  const {
    data: bounty,
    isInitialLoading,
    isLoading,
    isFetching
  } = useBountyDetails({
    bountyId: urlParams.id,
    accountId: walletAccountId
  });

  const isHidden = (bounty: Bounty) => {
    const access = utils.grantAccess(bounty, null, walletAccountId ?? "");
    return access.checkAccess([!access.isBountyOwner(), !access.isOneOfClaimers()]);
  };

  const detailsOptions = [
    {
      name: "details",
      title: "Bounty details",
      visible: true
    },
    {
      name: "invoices",
      title: "Bounty Invoices",
      visible: bounty ? !isHidden(bounty) : true
    }
  ];

  const [switchOptions, setSwitchOptions] = useState(
    detailsOptions.find((option) => option.name === "details")
  );
  const { data: nextActions, refetch } = useNextActions({ bountyId: urlParams.id });

  const context = useContext(MixpanelContext);

  mixpanel.init(context.token, {
    debug: false
  });

  useEffect(() => {
    if (!DEV_MODE) {
      mixpanel.track("Bounty details", {
        source: window.location.hostname
      });
    }
  }, []);

  const handleToggleOptions = (
    e: React.MouseEvent,
    value: { name: string; title: string; visible: boolean }
  ) => {
    e.preventDefault();
    if (!DEV_MODE) {
      mixpanel.track(`Bounty ${value.title}`, {
        source: window.location.hostname
      });
    }
    setSwitchOptions(value);
  };

  console.log("isInitialLoading", isInitialLoading);
  console.log("isFetching", isFetching);

  if (isLoading || isInitialLoading || isFetching) {
    return (
      <div className="flex items-center justify-center py-2.5">
        <span className="dot-pulse" />
      </div>
    );
  }

  return bounty ? (
    <WalletAccountContext.Provider value={walletAccountId}>
      <NextActionsContext.Provider value={nextActions}>
        <QueryClientProvider client={queryClient}>
          <div className="mt-8">
            <div>
              <Breadcrumbs
                links={[
                  { label: "Browse bounties", navigateFunc: () => navigate(-1) },
                  { label: bounty.metadata.category, category: true },
                  { label: "Bounty Details" }
                ]}
              />
            </div>
            <div className="font-poppins flex gap-6 py-2.5 justify-start">
              {detailsOptions
                .filter((item) => item.visible === true)
                .map((toggle) => (
                  <div key={toggle.name}>
                    {toggle.name === switchOptions?.name ? (
                      <button
                        className="py-2 focus-visible:outline-none text-md text-primary-900 border-b-4 border-primary-900"
                        onClick={(e: React.MouseEvent) => handleToggleOptions(e, toggle)}>
                        {toggle.title}
                      </button>
                    ) : (
                      <button
                        className="py-2 focus-visible:outline-none text-md text-white"
                        onClick={(e: React.MouseEvent) => handleToggleOptions(e, toggle)}>
                        {toggle.title}
                      </button>
                    )}
                  </div>
                ))}
            </div>
            {switchOptions?.name === "details" && (
              <div>
                <div className="grid lg:grid-cols-4 sm:grid-cols-1  lg:gap-x-4 sm:gap-y-4">
                  <div className="col-span-3 w-full">
                    {bounty && (
                      <ViewBounty
                        key={bounty.metadata.title}
                        bounty={bounty}
                        nextActions={nextActions}
                        refetchActions={refetch}
                      />
                    )}
                  </div>
                  <div className="col-span-1 lg:w-full lg:max-w-[256px]">
                    {bounty.ownerDetails && <OwnerDetails bounty={bounty} />}
                  </div>
                </div>
                <div className="mt-12">
                  <ViewMoreBounties
                    filters={{
                      owner: bounty.owner,
                      status: [{ name: "New", value: "New" }],
                      exclude: [bounty.id]
                    }}
                    limit={10}
                  />
                </div>
              </div>
            )}
            {switchOptions?.name === "invoices" && <BountyInvoices bounty={bounty} />}
          </div>
        </QueryClientProvider>
      </NextActionsContext.Provider>
    </WalletAccountContext.Provider>
  ) : null;
}
