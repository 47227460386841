import { Layout } from "../../components/Layout";
import Chapter from "../../components/general/Text/Chapter";
import CircleListItem from "../../components/general/Text/CircleListItem";
import List from "../../components/general/Text/List";
import NumberedListItem from "../../components/general/Text/NumberListItem";
import Paragraph from "../../components/general/Text/Paragraph";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="max-w-3xl mx-auto w-full font-poppins text-neutral-100">
        <h1 className="text-left text-3xl font-bold py-12">Privacy Policy</h1>
        <Chapter number="I" title="Processor Details">
          <Paragraph>
            The NEARWEEK AG (hereinafter “NEAR” or “we” or “us”) is the responsible party for the
            processing of personal data collected through your use of our website
            https://nearweek.com/ as per the applicable data protection laws, in particular the
            Swiss Data Protection Act (DPA) and – if applicable to your personal data – the European
            General Data Protection Regulation (GDPR).
          </Paragraph>
          <Paragraph className="pt-2.5">
            We have our registered domicile at Aabachstrasse 4, 6300 Zug, Switzerland. For any
            queries regarding your personal data, please contact us at the following address:
            gus@nearweek.com.
          </Paragraph>
        </Chapter>
        <Chapter number="II" title="How we process your personal data">
          <List>
            <NumberedListItem number="1">
              Scope
              <Paragraph className="pt-2.5">
                We only process your personal data if this is necessary to provide a functional
                website or to provide you with our contents and services. The processing of personal
                data only takes place based on the appropriate legal basis and as permitted by law.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="2">
              Legal Basis for Processing under GDPR
              <Paragraph className="pt-2.5">
                Insofar as we are required to obtain the consent of the data subject for the
                processing of personal data, we will obtain your prior consent and Art. 6 para. 1
                lit. a GDPR serves as the legal basis for the processing of the corresponding data.
              </Paragraph>
              <Paragraph>
                If the processing of personal data required for the performance of a contract to
                which you are a party, Art. 6 para. 1 lit. b GDPR serves as the legal basis. This
                also applies to processing operations that are necessary to carry out
                pre-contractual services or measures.
              </Paragraph>
              <Paragraph>
                Insofar as the processing of personal data is required to fulfil a legal obligation
                to which our company is subject, Art. 6 para. 1 lit. c GDPR serves as the legal
                basis.
              </Paragraph>
              <Paragraph>
                In the event that the vital interests of the data subject or another natural person
                require the processing of personal data, Article 6 para 1lit. d GDPR serves as the
                legal basis.
              </Paragraph>
              <Paragraph>
                If processing is necessary to safeguard a legitimate interest of our company or a
                third party and if the interests, fundamental rights and freedoms of the data
                subject do not outweigh the first-mentioned interest, Art. 6 para. 1 lit. of GDPR
                serves as the legal basis.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="3">
              Duration of Processing
              <Paragraph className="pt-2.5">
                We only store your personal data for as long as necessary to serve the purpose of
                the processing and we delete personal data or block access to it as soon as such
                purpose ceases to apply.
              </Paragraph>
              <Paragraph>
                Furthermore, personal data may be stored if this has been provided for by the
                applicable law (for example for book keeping or mandatory archiving purposes). The
                data will also be blocked or deleted if a storage period prescribed by the
                applicable law expires, unless there is a need for further storage of the data for
                the conclusion or fulfilment of a contract.
              </Paragraph>
            </NumberedListItem>
          </List>
        </Chapter>
        <Chapter number="III" title="Website Access and Log Files">
          <List>
            <NumberedListItem number="1">
              Automated Data Processing
              <Paragraph className="pt-2.5">
                Every time you visit our website, our system automatically collects data and
                information about the computer system you used to access our website.
              </Paragraph>
              <Paragraph className="pt-2.5">
                The following data is collected:
                <List>
                  <CircleListItem>Browser information (type and version)</CircleListItem>
                  <CircleListItem>Operating system</CircleListItem>
                  <CircleListItem>Your internet service provider</CircleListItem>
                  <CircleListItem>Your IP address</CircleListItem>
                  <CircleListItem>Date and time of access</CircleListItem>
                  <CircleListItem>
                    Websites from which your system reaches our website
                  </CircleListItem>
                  <CircleListItem>
                    Websites accessed by the user’s system via our website
                  </CircleListItem>
                  <CircleListItem>Name, Last Name</CircleListItem>
                  <CircleListItem>Phone Number (if specified)</CircleListItem>
                  <CircleListItem>Email Address</CircleListItem>
                  <CircleListItem>Postal Address (for invoicing)</CircleListItem>
                  <CircleListItem>NEAR account wallet</CircleListItem>
                  <CircleListItem>Company Name, Address, VAT Number (when provided)</CircleListItem>
                </List>
              </Paragraph>
              <Paragraph>
                For data processing subject to GDPR, the legal basis for the temporary storage of
                data and log files is Art. 6 para. 1 lit. f GDPR.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="2">
              Cookies, tracking and technologies relating to the use of our website
              <Paragraph className="pt-2.5">
                We use cookies on our site. Cookies are small files that your browser automatically
                creates and that are stored on your device (laptop, tablet, smartphone, etc.) when
                you visit our site. Information is stored in the cookie that results in each case in
                connection with the specifically used terminal device. However, this does not mean
                that we immediately become aware of your identity. The use of cookies primarily
                serves the purpose of making your use of our websites more pleasant for you. For
                example, we use so-called session cookies to recognize that you have already visited
                individual pages of our website.
              </Paragraph>
              <Paragraph>
                In addition, we also use temporary cookies that are stored on your end device for a
                specified period of time to optimize user-friendliness. If you visit our site again
                to use our services, it will automatically recognize that you have already been with
                us and what entries and settings you have made so that you do not have to enter them
                again.
              </Paragraph>
              <Paragraph>
                Furthermore, we use cookies to statistically record the use of our website and to
                evaluate it for you for optimising our websites. These cookies enable us to
                automatically recognize when you return to our site that you have already been with
                us. These cookies are automatically deleted after a defined period of time.
              </Paragraph>
              <Paragraph>
                Within the scope of the GDPR, the data processed by cookies for the aforementioned
                purposes is justified in order to protect our legitimate interests and those of
                third parties pursuant to Art. 6 para. 1 sentence 1 letter f GDPR.
              </Paragraph>
              <Paragraph>
                Most browsers automatically accept cookies. However, you can configure your browser
                so that no cookies are stored on your computer or a message always appears before a
                new cookie is created. However, the complete deactivation of cookies can lead to the
                fact that you cannot use all functions of our website.
              </Paragraph>
              <Paragraph>
                We use Google Analytics and Mailchimp on our websites. These are services provided
                by third parties, which may be located in any country (in the case of Google
                Analytics, Google LLC is based in the US but Google Ireland Ltd. is the data
                controller for customers based in the EU and Switzerland, for more information visit
                https://policies.google.com/?hl=en) and allow us to measure and evaluate the use of
                our website (without identifying individuals). Permanent cookies, which are placed
                by the service provider, are also used for this purpose. Although such service
                providers do not receive personal data from us (and do not retain any IP addresses),
                they may track your use of the website, combine this information with data from
                other websites you have visited, which are also tracked by service providers, and
                use this information for their own purposes (e.g. to manage advertising). If you
                have registered with the service provider concerned, the service provider will also
                know your identity. The service provider concerned will then be responsible for
                processing your personal data in accordance with the applicable data protection
                provisions. Service providers only provide information on how a particular website
                is used (but not any personal details).
              </Paragraph>
              <Paragraph>
                We also use plugins from social networks such as Facebook, Twitter, YouTube,
                Google+, LinkedIn on our websites. This will be evident to you, as the relevant
                symbol will typically be displayed. We have configured these elements to be disabled
                by default. If you enable these (by clicking on them), the social network operators
                may register that you are on our website and where you are on our website, and may
                use this information for their own purposes. The operator concerned will then be
                responsible for processing your personal data in accordance with the applicable data
                protection provisions. We will not receive any information regarding you from the
                operator concerned.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="3">
              Purpose of Processing
              <Paragraph className="pt-2.5">
                The data is stored in log files to ensure the functionality of the website. In
                addition, the data serves us to optimise the website and to ensure the security of
                our information technology systems. An evaluation of the data for marketing purposes
                does not take place in this context.
              </Paragraph>
              <Paragraph>
                For data processing subject to GDPR, these purposes are our legitimate interest
                pursuant to Art. 6 para. 1 lit. f GDPR.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="4">
              Duration of Processing
              <Paragraph className="pt-2.5">
                The data will be deleted as soon as they are no longer necessary to achieve the
                purpose for which they were collected. In the case of the collection of data for the
                provision of the website, this is the case when the respective session has ended. If
                the data is stored in log files, deletion occurs after seven days at the latest.
                Further storage is possible. In this case, the IP addresses of the users are deleted
                or anonymized.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="5">
              No Objection
              <Paragraph className="pt-2.5">
                The collection of data for the provision of the website and the storage of data in
                log files is absolutely necessary for the operation of the website. Consequently,
                there is no possibility of objection on your part.
              </Paragraph>
            </NumberedListItem>
          </List>
        </Chapter>
        <Chapter number="IV" title="Data Storage on the Blockchain">
          <Paragraph>
            Blockchain is a type of distributed ledger technology that stores data across multiple
            systems in a way that allows multiple stakeholders to agree on the replication of a
            dataset. Due to its decentralised nature, the data stored on a blockchain is transparent
            and immutable, meaning it cannot be changed or deleted once recorded. The public
            availability of blockchain data is a fundamental feature of its design. All transaction
            data stored on the blockchain is visible to all participants in the network. However,
            this data is typically pseudonymous, meaning it`s associated with a unique digital
            signature rather than personal identifying information.
          </Paragraph>
          <Paragraph>
            Here are the examples of the data we store on the NEAR blockchain:
            <List>
              <CircleListItem>
                Transaction IDs: These are unique identifiers for each transaction processed on our
                platform.
              </CircleListItem>
              <CircleListItem>
                NEAR Wallet Addresses: These are the unique addresses associated with each user`s
                NEAR wallet.
              </CircleListItem>
              <CircleListItem>
                Transaction Amounts: This is the amount of cryptocurrency transferred in each
                transaction.
              </CircleListItem>
              <CircleListItem>
                Transaction Timestamps: This represents the date and time of each transaction.
              </CircleListItem>
            </List>
          </Paragraph>
          <Paragraph className="pt-2.5">
            Please note that while the data we store on the blockchain is publicly visible, it does
            not include personally identifiable information such as your name, contact information,
            or physical address. Instead, transactions are linked to your unique
          </Paragraph>
        </Chapter>
        <Chapter number="V" title="Your Rights">
          <Paragraph>
            You have the following rights under the applicable data protection laws:
          </Paragraph>
          <List>
            <NumberedListItem number="1">
              Right of Information
              <Paragraph className="pt-2.5">
                You can request us to confirm whether personal data concerning you is being
                processed by us
              </Paragraph>
              <Paragraph>
                If such processing has taken place, you can request the following information from
                us:
              </Paragraph>
              <Paragraph className="pt-2.5">
                <List>
                  <CircleListItem>
                    The purposes for which the personal data are processed;
                  </CircleListItem>
                  <CircleListItem>the categories of personal data being processed;</CircleListItem>
                  <CircleListItem>
                    the recipients or categories of recipients to whom the personal data concerning
                    you have been or are still being disclosed;
                  </CircleListItem>
                  <CircleListItem>
                    the planned duration of the storage of the personal data concerning you or, if
                    specific information on this is not possible, criteria for determining the
                    storage period;
                  </CircleListItem>
                  <CircleListItem>
                    the existence of a right to rectification or deletion of personal data
                    concerning you, a right to limitation of processing by the controller or a right
                    to object to such processing;
                  </CircleListItem>
                  <CircleListItem>
                    the existence of a right of appeal to a supervisory authority;
                  </CircleListItem>
                  <CircleListItem>
                    any available information on the origin of the data if the personal data are not
                    collected from the data subject;
                  </CircleListItem>
                  <CircleListItem>
                    if the processing is subject to GDPR, the existence of automated decision-making
                    including profiling in accordance with Art. 22 para. 1 and 4 GDPR and – at least
                    in these cases – meaningful information on the logic involved and the scope and
                    intended effects of such processing for the data subject.
                  </CircleListItem>
                </List>
              </Paragraph>
              <Paragraph className="pt-2.5">
                You have the right to request information as to whether the personal data concerning
                you is transferred to a third country or to an international organisation. In this
                context, you may request to be informed of the appropriate guarantees in connection
                with the transmission.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="2">
              Right to Rectification
              <Paragraph className="pt-2.5">
                You have a right of rectification and/or completion if the personal data processed
                concerning you are incorrect or incomplete. We shall make the correction without
                delay.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="3">
              Right of Restriction
              <Paragraph className="pt-2.5">
                Under the following conditions, you may request that the processing of your personal
                data be restricted:
              </Paragraph>
              <Paragraph className="pt-2.5">
                <List>
                  <CircleListItem>
                    if you dispute the accuracy of the personal data concerning you for a period
                    that enables us to verify the accuracy of the personal data;
                  </CircleListItem>
                  <CircleListItem>
                    the processing is unlawful and you refuse to delete the personal data and
                    instead request that the use of the personal data be restricted;
                  </CircleListItem>
                  <CircleListItem>
                    we no longer need the personal data for the purposes of the processing, but you
                    do need them to assert, exercise or defend legal claims; or
                  </CircleListItem>
                  <CircleListItem>
                    if you have filed an objection to the processing pursuant to Art. 21 para. 1
                    GDPR and it has not yet been determined whether the legitimate reasons of the
                    person responsible outweigh your reasons.
                  </CircleListItem>
                </List>
              </Paragraph>
              <Paragraph>
                If the processing of personal data concerning you has been restricted, such data may
                only be processed – apart from being stored – with your consent or for the purpose
                of asserting, exercising or defending rights or protecting the rights of another
                natural or legal person or on grounds of an important public interest.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="4">Right to Deletion</NumberedListItem>
            <NumberedListItem number="5">
              a) Obligation to Delete Personal Data
              <Paragraph className="pt-2.5">
                You may request us to delete the personal data relating to you without delay and we
                are obliged to delete this data without delay if one of the following reasons
                applies:
              </Paragraph>
              <Paragraph className="pt-2.5">
                <List>
                  <CircleListItem>
                    The personal data concerning you are no longer necessary for the purposes for
                    which they were collected or otherwise processed.
                  </CircleListItem>
                  <CircleListItem>
                    You revoke your consent, on which the processing was based pursuant to Art. 6
                    para. 1 lit. a or Art. 9 para. 2 lit. a GDPR, and there is no other legal basis
                    for the processing.
                  </CircleListItem>
                  <CircleListItem>
                    You file an objection against the processing pursuant to Art. 21 para. 1 GDPR
                    and there are no overriding legitimate reasons for the processing, or you file
                    an objection against the processing pursuant to Art. 21 para. 2 GDPR.
                  </CircleListItem>
                  <CircleListItem>
                    The personal data concerning you have been processed unlawfully.
                  </CircleListItem>
                  <CircleListItem>
                    The deletion of personal data relating to you is necessary to fulfil a legal
                    obligation to which we are subject.
                  </CircleListItem>
                  <CircleListItem>
                    The personal data concerning you were collected in relation to information
                    society services offered pursuant to Art. 8 para. 1 GDPR.
                  </CircleListItem>
                </List>
              </Paragraph>
              <Paragraph className="pt-2.5">
                If we have made your personal data public and are obliged to delete it pursuant to
                Art. 17 para. 1 GDPR, we shall take appropriate measures, including technical
                measures, taking into account the available technology and the implementation costs,
                to inform those responsible for data processing who process the personal data that
                you as the data subject have requested the deletion of all links to this personal
                data or of copies or replications of this personal data.
              </Paragraph>
              <Paragraph className="pt-2.5">
                <List>
                  <NumberedListItem number="1">
                    b) Exceptions
                    <Paragraph className="pt-2.5">
                      The right to deletion does not exist insofar as the processing is necessary
                    </Paragraph>
                    <Paragraph className="pt-2.5">
                      <List>
                        <CircleListItem>
                          to exercise freedom of expression and information;
                        </CircleListItem>
                        <CircleListItem>
                          for the performance of a legal obligation required for processing under
                          the applicable law or for the performance of a task in the public interest
                          or in the exercise of official authority conferred on the controller;
                        </CircleListItem>
                        <CircleListItem>
                          for reasons of public interest in the field of public health pursuant to
                          Art. 9 para. 2 lit. h and i and Art. 9 para. 3 GDPR;
                        </CircleListItem>
                        <CircleListItem>
                          for archiving purposes in the public interest, scientific or historical
                          research purposes or for statistical purposes pursuant to Art. 89 para. 1
                          GDPR, insofar as the right mentioned under a) is likely to make it
                          impossible or seriously impair the attainment of the objectives of such
                          processing, or
                        </CircleListItem>
                        <CircleListItem>to assert, exercise or defend legal claims.</CircleListItem>
                        <NumberedListItem number="5">Right to Notification</NumberedListItem>
                      </List>
                      <Paragraph className="pt-2.5">
                        If you have exercised your right to have us correct, delete or limit the
                        processing, we are obliged to inform all recipients to whom the personal
                        data concerning you have been disclosed of this correction or deletion of
                        the data or restriction on processing, unless this proves impossible or
                        involves a disproportionate effort.
                      </Paragraph>
                    </Paragraph>
                  </NumberedListItem>
                </List>
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="6">
              Data Portability
              <Paragraph className="pt-2.5">
                You have the right to receive the personal data concerning you that you have
                provided to us in a structured, common and machine-readable format. In addition, you
                have the right to pass this data on to another person in charge without obstruction
                by us, provided that
              </Paragraph>
              <List>
                <CircleListItem>
                  processing is based on consent pursuant to Art. 6 para. 1 lit. a GDPR or Art. 9
                  para. 2 lit. GDPR or on a contract pursuant to Art. 6 para. 1 lit. b GDPR and
                </CircleListItem>
                <CircleListItem>
                  processing is carried out by means of automated methods.
                </CircleListItem>
              </List>
              <Paragraph className="pt-2.5">
                In exercising this right, you also have the right to request that the personal data
                concerning you be transferred directly from us to another data controller, insofar
                as this is technically feasible. The freedoms and rights of other persons must not
                be affected by this.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="7">
              Right to Objection
              <Paragraph className="pt-2.5">
                You have the right to object at any time, for reasons arising from your particular
                situation, to the processing of your personal data based on Article 6 para 1 lit. e
                or f GDPR; this also applies to profiling based on these provisions.
              </Paragraph>
              <Paragraph>
                In such case we shall no longer process the personal data concerning you, unless we
                can prove compelling reasons worthy of protection for the processing, which outweigh
                your interests, rights and freedoms, or the processing serves to assert, exercise or
                defend legal claims.
              </Paragraph>
              <Paragraph>
                If the personal data concerning you are processed for direct marketing purposes, you
                have the right to object at any time to the processing of the personal data
                concerning you for the purpose of such advertising; this also applies to profiling,
                insofar as it is associated with such direct marketing. If you object to the
                processing for direct marketing purposes, the personal data concerning you will no
                longer be processed for these purposes.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="8">
              Right to Withdraw Consent
              <Paragraph className="pt-2.5">
                You have the right to revoke your declaration of consent at any time. The revocation
                of consent shall not affect the legality of the processing carried out on the basis
                of the consent until revocation.
              </Paragraph>
            </NumberedListItem>
            <NumberedListItem number="9">
              Right of Appeal to Supervisory Authority
              <Paragraph className="pt-2.5">
                Without prejudice to any other administrative or judicial remedy, you have the right
                of appeal to a supervisory authority, if you believe that the processing of personal
                data concerning you is contrary to the applicable law.
              </Paragraph>
              <Paragraph className="pt-2.5">
                <List>
                  <NumberedListItem number="1">Special Provisions</NumberedListItem>
                  <NumberedListItem number="2">Current Third Party Processors</NumberedListItem>
                </List>
              </Paragraph>
              <Paragraph>Web Analytics – Use of Google Analytics </Paragraph>
              <Paragraph className="pt-2.5">
                We use Google Analytics in order to better understand our users’ needs and to
                optimise this service and experience. Google Analytics is a technology service that
                helps us better understand our users’ experience (e.g. how much time they spend on
                which pages, which links they choose to click, what users do and don’t like, etc.)
                and this enables us to build and maintain our service with user feedback. Google
                Analytics uses cookies and other technologies to collect data on our users’
                behaviour and their devices. This includes a device’s IP address (processed during
                your session and stored in a de-identified form), device screen size, device type
                (unique device identifiers), browser information, geographic location (country
                only), and the preferred language used to display our website. Google Analytics
                stores this information on our behalf in a pseudonymized user profile.{" "}
              </Paragraph>
            </NumberedListItem>
          </List>
        </Chapter>
        <Paragraph className="pt-9">
          <span className="text-base italic">NEARWEEK AG – Privacy Notice – October 2022</span>
        </Paragraph>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
