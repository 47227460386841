import { useNavigate, useParams } from "react-router-dom";
import useBountyFromGithub from "../../hooks/useBountyFromGithub";
import { useStoreActions, useStoreState } from "../../hooks";
import { useEffect } from "react";
import { BountyCreate } from "../../types";
import { ServerError } from "../../services/Api";

const CreateBountyFromGithub = () => {
  const { id } = useParams();
  const { data, isError, error } = useBountyFromGithub({ id });
  const navigate = useNavigate();
  const metadata = useStoreActions((actions) => actions.newBounty.metadata);
  const newBounty = useStoreActions((actions) => actions.newBounty);
  const newBountyForm = useStoreActions((actions) => actions.newBountyForm);
  const setCurrentStep = useStoreActions((actions) => actions.newBountyForm.setCurrentStep);
  const tokens = useStoreState((state) => state.app.tokens);
  const resetState = useStoreActions((actions) => actions.resetState);

  useEffect(() => {
    if (!isError && data) {
      resetState();
      newBountyForm.setIssueId(id ?? null);
      const msg = JSON.parse(data.msg) as BountyCreate.BountyCreateModel;
      msg.bounty_flow && newBounty.setBountyFlow(msg.bounty_flow);
      msg.deadline && newBounty.setDeadline(msg.deadline);

      newBounty.setAllowDeadlineStretch(msg.allow_deadline_stretch);
      newBounty.setClaimerApproval(msg.claimant_approval);
      newBounty.setClaimerApproval(msg.claimant_approval);

      metadata.setTitle(msg.metadata.title);
      metadata.setDescription(msg.metadata.description);
      metadata.setType(msg.metadata.category);
      metadata.setTags(msg.metadata.tags);

      const selectedToken = tokens.filter(
        (token) => token.enabled && token.tokenId === data.tokenId
      );

      if (selectedToken?.length > 0) {
        const token = selectedToken[0];
        newBountyForm.setSelectedTokenAccount({ name: token.tokenId, value: token.symbol });
        const calcAmount = +parseInt(data.amount) / Math.pow(10, token.decimals);
        newBountyForm.setPayment(calcAmount);
      }

      if (msg.kyc_config === "KycNotRequired") {
        newBountyForm.setKYCVerification(false);
      } else {
        newBountyForm.setKYCVerification(true);
      }

      setCurrentStep({
        name: "review",
        title: "Final Review"
      });
      navigate("/bounties/new");
    }
  }, [data]);

  return (
    <>
      {isError ? (
        <>
          <div className="font-poppins p-4">
            {(error?.response?.data as ServerError)?.message ?? "Something went wrong."}
          </div>
        </>
      ) : null}
    </>
  );
};

export default CreateBountyFromGithub;
