import { Bounty } from "../../../../../types/Bounty";
import { DarkButton } from "../../../../general/Button";
import Modal from "../../../../general/Modal";
import { FormControl, FormRow } from "../../../../general/Form/FormElements";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Input } from "../../../../general/Input";
import { ContactDetailsModel } from "../../../../../types/BountyCreate";
import { ValidationLayout } from "../../../../Layout";
import { defContactTypes } from "../../../../../constants";
import { NewSelect } from "../../../../general/Select";

type ContactDetailsFormData = {
  contact_details: ContactDetailsModel;
};

const EditContactDetailsForm = (props: {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (...args: any[]) => void;
  bounty: Bounty;
}) => {
  const { show, handleClose, handleSubmit, bounty } = props;
  const methods = useForm<ContactDetailsFormData>({
    mode: "onChange"
  });
  const { control, formState, register, setValue } = methods;
  const { errors, isSubmitted, isValid } = formState;
  const [contact_details, setContactDetails] = useState(
    bounty.metadata.contact_details ?? { contact: "", contact_type: "" }
  );

  const handleContactDetailsType = (value: string) => {
    setContactDetails({
      ...contact_details,
      contact_type: value
    });
  };

  const handleContactDetailsContact = (value: string) => {
    setContactDetails({
      ...contact_details,
      contact: value
    });
  };

  const handleSubmitClick = () => {
    if (isValid) {
      handleSubmit({ contact_details });
    }
  };

  return (
    <Modal
      {...{
        customStyles: { content: "h-fit" },
        open: show,
        onClose: handleClose,
        okButton: () => {
          return (
            <DarkButton
              {...{
                customStyles: { button: "max-w-[146px]" },
                disabled: false,
                onClick: () => methods.handleSubmit(handleSubmitClick)(),
                text: "Update"
              }}
            />
          );
        }
      }}>
      <div className="font-poppins">
        <div className="text-lg text-white">Edit contact details</div>
        <div className="py-2.5">
          <FormRow>
            <div className="flex gap-4 justify-start items-end pb-5">
              <div className="min-w-[146px] max-w-[196px]">
                <>
                  <input
                    type="hidden"
                    defaultValue={contact_details?.contact_type ?? ""}
                    {...register("contact_details.contact_type", {
                      required: "Required field"
                    })}
                  />
                  <ValidationLayout
                    {...{
                      validate: () => {
                        return {
                          valid: !errors.contact_details?.contact_type,
                          started: isSubmitted,
                          message: errors.contact_details?.contact_type?.message
                        };
                      }
                    }}>
                    <NewSelect
                      {...{
                        customStyles: {
                          container: "py-[13px]",
                          optionsList: { container: "text-sm" }
                        },
                        selected: {
                          name: contact_details?.contact_type ?? "",
                          value: contact_details?.contact_type ?? ""
                        },
                        defaultOptionsList: defContactTypes,
                        defaultOption: {
                          name: contact_details?.contact_type ?? "",
                          value: contact_details?.contact_type ?? ""
                        },
                        onSelect: (selected: { name: string; value: string }) => {
                          setValue("contact_details.contact_type", selected.value, {
                            shouldValidate: true
                          });
                          handleContactDetailsType(selected.value);
                        }
                      }}
                    />
                  </ValidationLayout>
                </>
              </div>
              <div className="w-full">
                <FormControl
                  name="contact_details.contact"
                  control={control}
                  defaultValue={contact_details?.contact ?? ""}
                  rules={{ required: "Required field" }}
                  render={({ field: { name, value, onChange } }) => {
                    return (
                      <Input
                        {...{
                          customStyles: {
                            input: "px-[20px] py-[15px]"
                          },
                          placeholder: "Contact",
                          name: name,
                          label: { text: "" },
                          value: value,
                          validation: {
                            valid: !errors.contact_details?.contact,
                            message: errors.contact_details?.contact?.message,
                            started: isSubmitted
                          },
                          onChange: (value: string) => {
                            onChange(value);
                            handleContactDetailsContact(value);
                          }
                        }}
                        {...{ name, value }}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </FormRow>
        </div>
      </div>
    </Modal>
  );
};

export default EditContactDetailsForm;
