import AllInvoices from "../../components/Bounty/Invoices/AllInvoices";

const MyInvoices = () => {
  return (
    <div>
      <div className="font-poppins flex gap-6 py-2.5 justify-start">
        <button className="py-2 focus-visible:outline-none text-md text-primary-900 border-b-4 border-primary-900">
          All Invoices
        </button>
      </div>
      <AllInvoices />
    </div>
  );
};

export default MyInvoices;
