import { useState } from "react";

import { Area, SignFormContainerLayout, ValidationLayout } from "../../Layout";
import { Button, LightButton } from "../../general/Button";

import { VerificationCodeInput } from "../../general/Input";
import { DEFAULT_VALUE, DEFAULT_STYLES } from "../../../helpers/Validation";
import classNames from "classnames";
import Timer from "../../general/Timer";

const FIRSTS_ATTEMPT_TIMER_SECS = 60;

interface VerifyCodeFormProps {
  onSubmit: (value: number) => void;
  onResend: () => void;
  submitDisabled: boolean;
  errorMessage?: string;
  resendCodeMessage?: { error: boolean; message: string } | null;
  clearResendCodeMessage?: React.Dispatch<
    React.SetStateAction<{
      error: boolean;
      message: string;
    } | null>
  >;
}

function VerifyCodeForm(props: VerifyCodeFormProps) {
  const {
    onResend,
    onSubmit,
    submitDisabled,
    errorMessage,
    resendCodeMessage,
    clearResendCodeMessage
  } = props;

  const [startTimer, setStartTimer] = useState(false);
  const [code, setCode] = useState(0);
  const [resendEnabled, setResendEnabled] = useState(true);
  const [attemptsCount, setAttemptsCount] = useState(0);

  const handleResend = () => {
    if (resendEnabled) {
      setStartTimer(true);
      setResendEnabled(false);
      setAttemptsCount(attemptsCount + 1);
      clearResendCodeMessage && clearResendCodeMessage(null);
      onResend && onResend();
    }
  };

  const onCodeChange = (code: string) => {
    setCode(Number.parseInt(code, 10));
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(code);
  };

  return (
    <SignFormContainerLayout
      {...{
        title: "Verification"
      }}>
      <div className="flex flex-col gap-5 pb-10">
        <p className="text-neutral-200 font-poppins text-base text-center">
          Shortly you will recieve a code in your e-mail.
        </p>
        <Area
          customStyles={{
            contentContainer: "px-[1em] pt-[2em] pb-[0.8em] bg-opacity-50"
          }}>
          <div className="w-full h-full flex flex-col items-center justify-between gap-[1rem]">
            {startTimer ? (
              <Timer
                {...{
                  initSecs: attemptsCount * attemptsCount * FIRSTS_ATTEMPT_TIMER_SECS,
                  onEnd: () => {
                    setResendEnabled(true);
                    setStartTimer(false);
                    clearResendCodeMessage && clearResendCodeMessage(null);
                  }
                }}
              />
            ) : null}
            <ValidationLayout
              {...{
                validate: () => DEFAULT_VALUE,
                label: { text: "Code" }
              }}>
              <VerificationCodeInput {...{ onChange: onCodeChange }} />
            </ValidationLayout>
            {resendCodeMessage && (
              <p
                className={classNames(`min-h-[1em] text-base `, {
                  "text-white": !resendCodeMessage.error,
                  [`${DEFAULT_STYLES.invalid.message}`]: resendCodeMessage.error
                })}>
                {resendCodeMessage.message}
              </p>
            )}
            <Button
              {...{
                text: "Resend",
                customStyles: {
                  button: "border-none",
                  text: `
                  text-sm text-neutral-50
                  hover:text-neutral-100
                  active:text-neutral-50
                `
                },
                onClick: () => {
                  handleResend();
                }
              }}
            />
          </div>
        </Area>
        <p className={`min-h-[1em] text-base ${DEFAULT_STYLES.invalid.message}`}>
          {errorMessage ? errorMessage : ""}
        </p>
        <div className="flex flex-row justify-center items-center gap-5">
          <LightButton
            {...{
              text: "Submit",
              onClick: () => handleSubmit(),
              disabled: submitDisabled
            }}
          />
        </div>
      </div>
    </SignFormContainerLayout>
  );
}

export default VerifyCodeForm;
