import { useState } from "react";
import Modal from "../../../../general/Modal";
import { Bounty } from "../../../../../types/Bounty";
import Amount from "./Amount";
import Avatar from "../../../../general/Avatar";
import { useStoreState } from "../../../../../hooks";

const ApproveSuccessMessage = (props: { bounty: Bounty; show: boolean }) => {
  const { bounty, show } = props;
  const [showMessage, setShowMessage] = useState(show);
  const user = useStoreState((state) => state.auth.user);

  const handleCloseModal = () => {
    setShowMessage(!showMessage);
  };

  return (
    <Modal
      {...{
        customStyles: {
          content:
            "w-full max-w-[540px] h-[289px] justify-start bg-approve--sucess bg-no-repeat bg-contain"
        },
        open: showMessage,
        onClose: () => handleCloseModal()
      }}>
      <div className="font-poppins flex text-neutral-50">
        <div className="flex-[50%]">
          <div className="flex flex-col relative">
            <h2 className="text-2xl font-weight text-start text-primary-900 max-w-[315px]">
              YOUR WORK HAS BEEN APPROVED
            </h2>
            <div className="my-4">
              <h6>Total received amount:</h6>
              <Amount bounty={bounty} />
            </div>
          </div>
        </div>
        <div className="flex flex-[50%] items-center justify-center relative">
          <div className="flex flex-col">
            <Avatar className="w-[104px] h-[104px] relative mt-0 ml-0" />
            <div className="py-2.5">{user?.name}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApproveSuccessMessage;
