import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type IconButtonStyles = {
  button?: string;
  icon?: string;
};

interface IconButtonProps {
  customStyles?: IconButtonStyles;
  icon?: React.ReactElement;
  onClick?: MouseEventHandler;
  children?: ReactNode | ReactNode[];
  disabled?: boolean;
  type?: "submit" | "button";
  title?: string;
}

const disabledStyles = {
  button: `
      hover:shadow-initial
    `
};

const buildStyles = (customStyles: IconButtonStyles | undefined) => ({
  button: twMerge(`
      outline-none
      ${customStyles?.button ? customStyles.button : ""}
    `)
});

const IconButton = (props: IconButtonProps) => {
  const { customStyles, icon, onClick, disabled = false, title } = props;
  const type = props.type || "button";

  const styles = buildStyles({
    button: (customStyles?.button || "") + (!disabled ? disabledStyles.button : "")
  });

  const disabledOnClick = () => {};
  return (
    <button
      className={classNames(styles.button)}
      onClick={disabled ? disabledOnClick : onClick}
      disabled={disabled}
      type={type}
      title={title}>
      {icon}
    </button>
  );
};
export default IconButton;
