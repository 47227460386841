const BigChatIcon = () => {
  return (
    <svg width="69" height="67" viewBox="0 0 69 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9375 32.0866C21.9375 32.4614 21.7904 32.8208 21.5287 33.0858C21.2669 33.3508 20.9119 33.4997 20.5417 33.4997C20.1715 33.4997 19.8164 33.3508 19.5547 33.0858C19.2929 32.8208 19.1458 32.4614 19.1458 32.0866C19.1458 31.7119 19.2929 31.3525 19.5547 31.0875C19.8164 30.8225 20.1715 30.6736 20.5417 30.6736C20.9119 30.6736 21.2669 30.8225 21.5287 31.0875C21.7904 31.3525 21.9375 31.7119 21.9375 32.0866ZM21.9375 32.0866H20.5417M35.8958 32.0866C35.8958 32.4614 35.7488 32.8208 35.487 33.0858C35.2252 33.3508 34.8702 33.4997 34.5 33.4997C34.1298 33.4997 33.7748 33.3508 33.513 33.0858C33.2512 32.8208 33.1042 32.4614 33.1042 32.0866C33.1042 31.7119 33.2512 31.3525 33.513 31.0875C33.7748 30.8225 34.1298 30.6736 34.5 30.6736C34.8702 30.6736 35.2252 30.8225 35.487 31.0875C35.7488 31.3525 35.8958 31.7119 35.8958 32.0866ZM35.8958 32.0866H34.5M49.8542 32.0866C49.8542 32.4614 49.7071 32.8208 49.4453 33.0858C49.1836 33.3508 48.8285 33.4997 48.4583 33.4997C48.0881 33.4997 47.7331 33.3508 47.4713 33.0858C47.2096 32.8208 47.0625 32.4614 47.0625 32.0866C47.0625 31.7119 47.2096 31.3525 47.4713 31.0875C47.7331 30.8225 48.0881 30.6736 48.4583 30.6736C48.8285 30.6736 49.1836 30.8225 49.4453 31.0875C49.7071 31.3525 49.8542 31.7119 49.8542 32.0866ZM49.8542 32.0866H48.4583M68 32.0866C68 49.254 52.9994 63.1733 34.5 63.1733C31.2884 63.1772 28.0905 62.7502 24.9897 61.9035C20.6189 65.0153 15.2871 66.4293 9.97056 65.8863C9.37945 65.8286 8.79086 65.7469 8.20622 65.6414C10.0408 63.4526 11.2937 60.8264 11.8466 58.011C12.1816 56.289 11.3515 54.616 10.1083 53.3914C4.46167 47.8297 1 40.335 1 32.0866C1 14.9193 16.0006 1 34.5 1C52.9994 1 68 14.9193 68 32.0866Z"
        stroke="#F8F8F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BigChatIcon;
