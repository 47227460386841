import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { InfoIcon, ShieldIcon } from "../components/general/SVGIcon";
import { Area } from "../components/Layout";
import store from "../store/store";
import { utils } from "near-api-js";

const ProfileNotCreatedMessage = () => {
  return (
    <div className="font-poppins py-5">
      <p className="text-sm text-neutral-400">
        Your profile is not yet completed. Follow{" "}
        <a href={"/profile/edit"} className="font-bold text-primary-900">
          the link
        </a>{" "}
        to finish your profile setup.
      </p>
    </div>
  );
};

const CreateBountyIsNotAllowedMessage = () => {
  return (
    <div className="font-poppins py-5">
      <p className="text-sm text-neutral-400">
        <span className="text-primary-900"> HEROES</span> is in closed beta and only whitelisted
        accounts are authorised to create new bounties.
      </p>
    </div>
  );
};

const ProfileNotWhitelistedMessage = () => {
  return (
    <div className="flex gap-2 items-center text-neutral-500 text-sm mt-4">
      <InfoIcon />
      <span>This bounty is unavailable as your profile is non-whitelisted</span>
    </div>
  );
};

const ConnectWalletMessage = (props: { className?: string }) => {
  const { className } = props;
  return (
    <div className={twMerge(classNames("text-sm text-inherit py-2", className))}>
      To interact with bounties, please connect your NEAR Wallet.
    </div>
  );
};

const BondDisclaimer = () => {
  return (
    <div className="mt-3.5">
      <Area
        {...{
          customStyles: { borderContainer: "border border-neutral-700" }
        }}>
        <div className="flex items-center">
          <div className="pl-6 text-neutral-400">
            <InfoIcon />
          </div>
          <div className="py-5 px-6">
            <p className="text-sm text-neutral-400">
              1 NEAR will be paid as a deposit and will be promptly refunded upon the successful
              completion of the bounty. Exceptions apply: please view the{" "}
              <a
                href="/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-900">
                Terms of Use
              </a>{" "}
              for details.
            </p>
          </div>
        </div>
      </Area>
    </div>
  );
};

const OffcontractDisclaimer = (props: { className?: string }) => {
  const { className } = props;
  return (
    <p className={twMerge(classNames("text-sm text-neutral-500", className))}>
      Initiate the bounty without the need to deposit tokens upfront. You can settle the payment
      after the successful completion of the bounty.
    </p>
  );
};

const VisitNearblocksSiteMessage = (props: { className?: string }) => {
  const { className } = props;
  const state = store.getState();
  const { options } = state.nearApi.walletSelector ?? {};
  const nearBlocksUrl =
    options?.network.networkId === "testnet"
      ? `https://testnet.nearblocks.io/address/${state.app.config?.bounty_contract_id}`
      : `https://nearblocks.io/address/${state.app.config?.bounty_contract_id}`;

  return (
    <p className={classNames("text-sm text-neutral-200", className)}>
      For more information, please visit the site{" "}
      <a
        className="text-primary-900 underline"
        href={nearBlocksUrl}
        target="_blank"
        rel="noreferrer">
        nearblocks.io
      </a>
      {" ! "}
    </p>
  );
};
const CompleteKYCMessage = () => {
  const storeState = store.getState();
  const claimBond = storeState.app.config?.bounty_claim_bond
    ? storeState.app.config.bounty_claim_bond.toString()
    : "0";

  return (
    <Area>
      <div className="flex items-center">
        <div className="px-6 text-neutral-400">
          <ShieldIcon className="fill-neutral-700" />
        </div>
        <div className="py-5 px-1">
          <p className="text-sm text-neutral-400">
            You must complete{" "}
            <a href="/kyc" className="text-sm text-primary-900 underline">
              KYC verification
            </a>{" "}
            before your candidacy is approved.
          </p>
          <p className="text-sm text-neutral-400">
            This bonus claim will require {utils.format.formatNearAmount(claimBond, 5)} NEAR deposit
            to prevent spam, this deposit will be returned after you complete or give up the bounty
            or the owner rejects your claimant.
          </p>
        </div>
      </div>
    </Area>
  );
};

export const AppMessages = {
  ConnectWalletMessage,
  ProfileNotCreatedMessage,
  ProfileNotWhitelistedMessage,
  BondDisclaimer,
  OffcontractDisclaimer,
  CreateBountyIsNotAllowedMessage,
  VisitNearblocksSiteMessage,
  CompleteKYCMessage
};
